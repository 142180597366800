import React from 'react';
import './ExpandButtonPlusMinus.scss';
import cn from 'classnames';

interface ExpandButtonProps {
  ariaControls: string;
  isOpen: boolean;
  onClick: () => void;
  ariaLabel: string;
  className?: string;
}

const ExpandButtonPlusMinus = ({
  ariaControls,
  isOpen,
  onClick,
  ariaLabel,
  className,
}: ExpandButtonProps) => {
  return (
    <button
      className={cn(
        'expand-button-plus-minus-react',
        {
          'expand-button-plus-minus-react--open': isOpen,
        },
        className
      )}
      onClick={onClick}
      aria-controls={ariaControls}
      aria-label={ariaLabel}
      aria-expanded={isOpen ? 'true' : 'false'}
    ></button>
  );
};

export default ExpandButtonPlusMinus;
