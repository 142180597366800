import React from 'react';
import './MinutesPrevNext.scss';
import { LinkProps } from '../../../Models/Generated/LinkProps';

export interface Props {
  previousLink?: LinkProps;
  nextLink?: LinkProps;
}

const MinutesPrevNext = ({ nextLink, previousLink }: Props) => {
  if (!(nextLink || previousLink)) return null;

  return (
    <div className="prev-next-links">
      <div className="prev-next-links__inner">
        {previousLink && (
          <a
            href={previousLink.href}
            className="prev-next-links__anchor prev-next-links__anchor--prev"
          >
            <div className="prev-next-links__anchor__icon"></div>
            {previousLink.text}
          </a>
        )}
        {nextLink && (
          <a
            href={nextLink.href}
            className="prev-next-links__anchor prev-next-links__anchor--next"
          >
            {nextLink.text}{' '}
            <div className="prev-next-links__anchor__icon"></div>
          </a>
        )}
      </div>
    </div>
  );
};

export default MinutesPrevNext;
