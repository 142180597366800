import React, { useEffect, useState } from 'react';
import './LiveMeetingInformationBlockTypeTopTextBoxMainUrl.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import mixins from './mixins';
import lang from './lang';
import { StortingetLiveHearingActivityProps } from '/Models/Generated/StortingetLiveHearingActivityProps';
import { StortingetLiveAgendaItemProps } from '/Models/Generated/StortingetLiveAgendaItemProps';

const LiveMeetingInformationBlockTypeTopTextBoxMainUrl = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);
  const mainBlockIsHearing = () => mixins().mainBlockIsHearing(cmsData);
  const [hearing, setHearing] = useState(
    {} as StortingetLiveHearingActivityProps
  );
  const [agenda, setAgenda] = useState({} as StortingetLiveAgendaItemProps);
  const textHasHearingInCase = lang.hasHearingInCase[cmsData.lang];
  const textCaseNr = lang.caseNr[cmsData.lang];

  useEffect(() => {
    if (mainBlockIsHearing()) {
      setHearing(mixins().activeHearing(cmsData, liveData));
    } else {
      setAgenda(mixins().agendaItem(liveData));
    }
  }, []);
  return (
    <div className="live-meeting-information-block-type-top-text-box-main-url-react">
      {mainBlockIsHearing() && (
        <p>
          {hearing.committeeName} {textHasHearingInCase}:{' '}
          <a href={hearing.proceedingLinkUrl} title={hearing.proceedingTitle}>
            {hearing.proceedingTitle}
          </a>
        </p>
      )}
      {!mainBlockIsHearing() && (
        <p>
          {textCaseNr} {agenda.orderNumber}:{' '}
          <a href={agenda.linkUrl} title={agenda.titleText}>
            {agenda.titleText}
          </a>
        </p>
      )}
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopTextBoxMainUrlWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopTextBoxMainUrl />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopTextBoxMainUrlWrapper;
