import React, { FunctionComponent } from 'react';
import './Row.scss';
import { RowProps } from '/Models/Generated/RowProps';
import cn from 'classnames';

export enum HorizontalPadding {
  none = 'none',
  normal = 'normal',
  indent = 'indent',
  indentFromRight = 'indentfromright',
}

export enum VerticalPadding {
  none = 'none',
  small = 'small',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

interface Props extends RowProps {
  horizontalPadding?: HorizontalPadding;
  verticalPadding?: VerticalPadding;
  verticalPaddingBottom?: VerticalPadding;
  verticalPaddingTop?: VerticalPadding;
  className?: string;
  noPadding?: boolean;
  noPaddingOnMobile?: boolean;
  lineAbove?: boolean;
  lineBelow?: boolean;
  children?: React.ReactNode;
}

const Row: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={cn(
        'layout-row',
        {
          'layout-row--padding-horizontal-normal':
            props.horizontalPadding === HorizontalPadding.normal,
        },
        {
          'layout-row--padding-horizontal-indent-from-right':
            props.horizontalPadding === HorizontalPadding.indentFromRight,
        },
        {
          'layout-row--padding-horizontal-indent':
            props.horizontalPadding === HorizontalPadding.indent,
        },
        {
          'layout-row--padding-vertical-small':
            props.verticalPadding === VerticalPadding.small,
        },
        {
          'layout-row--padding-vertical-normal':
            props.verticalPadding === VerticalPadding.normal,
        },
        {
          'layout-row--padding-vertical-bottom-small':
            props.verticalPaddingBottom === VerticalPadding.small,
        },
        {
          'layout-row--padding-vertical-bottom-normal':
            props.verticalPaddingBottom === VerticalPadding.normal,
        },
        {
          'layout-row--padding-vertical-bottom-medium':
            props.verticalPaddingBottom === VerticalPadding.medium,
        },
        {
          'layout-row--padding-vertical-top-small':
            props.verticalPaddingTop === VerticalPadding.small,
        },
        {
          'layout-row--padding-vertical-top-medium':
            props.verticalPaddingTop === VerticalPadding.medium,
        },
        {
          'layout-row--padding-vertical-bottom-large':
            props.verticalPaddingBottom === VerticalPadding.large,
        },
        {
          'layout-row--padding-vertical-bottom-xlarge':
            props.verticalPaddingBottom === VerticalPadding.xlarge,
        },
        {
          'layout-row--padding-vertical-top-normal':
            props.verticalPaddingTop === VerticalPadding.normal,
        },
        {
          'layout-row--padding-vertical-top-large':
            props.verticalPaddingTop === VerticalPadding.large,
        },
        {
          'layout-row--padding-vertical-top-xlarge':
            props.verticalPaddingTop === VerticalPadding.xlarge,
        },
        {
          'layout-row--padding-collapse': props.noPadding,
        },
        {
          'layout-row--padding-collapse-small': props.noPaddingOnMobile,
        },
        {
          'layout-row--line-above': props.lineAbove,
        },
        {
          'layout-row--line-below': props.lineBelow,
        },
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default Row;
