import React from 'react';
import './LinkButton.scss';
import cn from 'classnames';

export enum LinkButtonTheme {
  none,
  serif,
  sans,
  block,
}

export interface Props {
  theme?: LinkButtonTheme;
  className?: string;
  text: string;
  onClick?: () => void;
  id?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const Link = ({ onClick, text, className, theme, id, type }: Props) => {
  return onClick && text ? (
    <button
      id={id}
      className={cn(
        'link-button-react',
        { 'link-button-serif': theme === LinkButtonTheme.serif },
        { 'link-button-sans': theme === LinkButtonTheme.sans },
        { 'link-button-block': theme === LinkButtonTheme.block },
        className
      )}
      onClick={onClick}
      type={type}
    >
      {text}
    </button>
  ) : (
    <></>
  );
};

export default Link;
