import React from 'react';
import { NewsListTwoColumnsProps } from '/Models/Generated/NewsListTwoColumnsProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import './NewsListTwoColumns.scss';

const NewsListTwoColumns = (newsListTwoColumns: NewsListTwoColumnsProps) => {
  return (
    <div className="news-list-two-columns-react">
      {newsListTwoColumns.newsItems &&
        newsListTwoColumns.newsItems.map((val, idx) => (
          <article key={idx} lang={val.langAttribute || null}>
            <a
              href={val.linkUrl}
              className="news-list-two-columns-react__image-and-link"
            >
              <div className="news-list-two-columns-react__image-wrapper-react">
                <img src={val.imageUrl} alt={val.getAltText}></img>
              </div>
              <h2>
                <span>{val.frontPageTitle}</span>
              </h2>
            </a>
            <div className="news-list-two-columns-react__block-content-react">
              <div
                dangerouslySetInnerHTML={{
                  __html: val.frontPageIntro,
                }}
              ></div>
            </div>
          </article>
        ))}
      <div className="news-list-two-columns-react__newslist">
        <h2>{newsListTwoColumns.moreNewsHeading}</h2>
        <ul>
          {newsListTwoColumns.moreNewsItems &&
            newsListTwoColumns.moreNewsItems.map((val, idx) => (
              <li key={idx}>
                <Link
                  text={val.text}
                  href={val.href}
                  theme={LinkTheme.standard}
                  langAttribute={val.langAttribute}
                />
              </li>
            ))}
        </ul>
        <Link
          href={newsListTwoColumns.linkNewsUrl}
          text={newsListTwoColumns.linkNewsText}
          theme={LinkTheme.blocklink}
        />
      </div>
    </div>
  );
};

export default NewsListTwoColumns;
