import React, { useEffect, useRef, useState } from 'react';
import './EPOSDagsordenliste.scss';
import { EPOSDagsordenlisteProps } from '/Models/Generated/EPOSDagsordenlisteProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { PageWrapper } from '/App.Components';
import EPOSDagsordenlisteFilter from '/Components/Molecules/EPOSDagsordenlisteFilter/EPOSDagsordenlisteFilter';
import EPOSDagsordenlisteList from '/Components/Molecules/EPOSDagsordenlisteList/EPOSDagsordenlisteList';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';
import EPOSDagsordenlisteClient from '/Shared/Code/EPOSDagsordenliste/EPOSDagsordenlisteClient';
import { EPOSDagsordenlisteAsyncDataProps } from '/Models/Generated/EPOSDagsordenlisteAsyncDataProps';

export interface EPOSDagsordenlisteState {
  pid: string;
  chid: string;
}

const getInitialState = (): EPOSDagsordenlisteState => ({
  pid: SSRQueryStringReader.Read('pid') ?? '',
  chid: SSRQueryStringReader.Read('chid') ?? '',
});

const initialState: EPOSDagsordenlisteState = getInitialState();

const EPOSDagsordenliste = ({
  threeColumnContentArea,
  threeColumnContentAreaInTop,
  stortingPeriodsDropDown,
  meetingTypesDropDown,
  subFwEposListProps,
  // old solution showed button when js was turned off. New solution won't work if js is turned off.
  // noJsButtonText,
  asyncData,
  apiEndpoint,
}: EPOSDagsordenlisteProps) => {
  const mountedRef = useRef(false);
  const [selectedSession, setSelectedSession] = useState(
    initialState.pid ||
      stortingPeriodsDropDown?.items?.find((item) => item.isSelected)?.value ||
      stortingPeriodsDropDown?.items?.[0]?.value
  );
  const [selectedMeetingType, setSelectedMeetingType] = useState(
    initialState.chid || meetingTypesDropDown?.items?.[0]?.value
  );
  const [tableData, setTableData] =
    useState<EPOSDagsordenlisteAsyncDataProps>(asyncData);
  const [shouldPushState, setShouldPushState] = useState<boolean>(true);

  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const client = new EPOSDagsordenlisteClient(apiEndpoint);

  const buildUrl = (meetingType) => {
    const url = client.BuildFilterUrl(selectedSession, meetingType);
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (!mountedRef.current) return;

    setIsLoading(true);
    setHasError(false);

    client
      .Get(selectedSession, selectedMeetingType)
      .then((res) => {
        setTableData(res);
        setHasError(false);

        // if previously chosen period had meeting type and currently chose perioed does not
        // we need to fetch data again without selected meeting type
        const needRefetching =
          !res.showMeetingTypesDropDown && selectedMeetingType !== 'all';

        if (needRefetching) setSelectedMeetingType('all');
        else setIsLoading(false);

        if (shouldPushState && !needRefetching)
          buildUrl(
            tableData?.showMeetingTypesDropDown ? selectedMeetingType : null
          );

        scrollToTarget('primaryfilter');
        setShouldPushState(true);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  }, [selectedSession, selectedMeetingType]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.onpopstate = () => {
      const state = getInitialState();
      setShouldPushState(false);
      setSelectedSession(
        state.pid || stortingPeriodsDropDown?.items?.[0]?.value
      );
      setSelectedMeetingType(
        state.chid || meetingTypesDropDown?.items?.[0]?.value
      );
    };
  }, []);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <PageWrapper>
      <div className="epos-dagsordenliste-react ">
        {/* Seems to not be in use (in Prod) */}
        <ContentArea {...threeColumnContentAreaInTop} />

        {subFwEposListProps.mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          </Row>
        )}

        {/* Seems to not be in use (in Prod) */}
        {subFwEposListProps.mainBody && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea {...subFwEposListProps.mainBody} />
          </Row>
        )}

        {/* Jump to this with # after API roundtrip */}
        <a id="primaryfilter"></a>
        {/* ----------Dropdown - Period--------------- */}
        {stortingPeriodsDropDown && (
          <Row
            horizontalPadding={HorizontalPadding.none}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <EPOSDagsordenlisteFilter
              filterTitle={subFwEposListProps.limitText}
              showMeetingTypesDropDown={tableData.showMeetingTypesDropDown}
              meetingTypesDropDown={meetingTypesDropDown}
              selectedSessionValue={selectedSession}
              selectedSessionFunction={setSelectedSession}
              selectedMeetingTypeValue={selectedMeetingType}
              selectedMeetingTypeFunction={setSelectedMeetingType}
              {...stortingPeriodsDropDown}
            />
            {/* <button>{noJsButtonText}</button> */}
          </Row>
        )}

        {/* Result table */}
        {tableData && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <EPOSDagsordenlisteList
              {...tableData}
              hasError={hasError}
              isLoading={isLoading}
            />
          </Row>
        )}

        {/* Seems to not be in use (in Prod) */}
        <ContentArea {...threeColumnContentArea} />
      </div>
    </PageWrapper>
  );
};

export default EPOSDagsordenliste;
