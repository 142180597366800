import React from 'react';
import './LiveMeetingInformationBlockTypeTopTextBoxLinkList.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';

const LiveMeetingInformationBlockTypeTopTextBoxLinkList = () => {
  const linkItems = useSelector(
    (s: RootState) => s.LiveBlock.cmsData.mainBlock.linkList
  );
  const shouldShow = () => linkItems && linkItems.length > 0;

  return (
    <>
      {shouldShow() && (
        <ul className="live-meeting-information-block-type-top-text-box-link-list-react">
          {linkItems.map((linkItem, idx) => (
            <li key={idx}>
              <a href={linkItem.href}>{linkItem.text}</a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const LiveMeetingInformationBlockTypeTopTextBoxLinkListWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopTextBoxLinkList />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopTextBoxLinkListWrapper;
