import { IsValidEmail } from './IsValidEmail';

const validators = {
  regExValidator: ({ inputVal, data }) => {
    if (data.allowEmpty && !inputVal) return true;
    if (new RegExp(data.regEx).test(inputVal)) return true;
    return false;
  },
  requiredValidator: ({ inputVal, data }) =>
    !data.isRequired ||
    !!(inputVal && (inputVal.length > 0 || inputVal === true)), // is input value always a string?
  emailValidator: ({ inputVal }) => IsValidEmail(inputVal),
  minDateValidator: ({ inputVal, data }) => inputVal >= data.value,
  maxDateValidator: ({ inputVal, data }) => inputVal <= data.value,
  minLengthValidator: ({ inputVal, data }) => inputVal.length >= data.length,
  maxLengthValidator: ({ inputVal, data }) => {
    return inputVal.length <= data.length;
  },
  minFileSizeValidator: ({ inputVal, data }) => inputVal[0]?.size >= data,
  maxFileSizeValidator: ({ inputVal, data }) => inputVal[0]?.size <= data,
  allowedValuesValidation: ({ inputVal, data }) => {
    if (data.allowEmpty && !inputVal) return true;
    return data.allowedValues?.indexOf(inputVal) >= 0;
  },
};

const GetFormInputErrorMessages = (props, frontend = true) => {
  const v = validators;
  const entries = [
    {
      data: props.requiredValidation,
      validator: v.requiredValidator,
    },
    { data: props.regExValidations, validator: v.regExValidator },
    { data: props.minLengthValidation, validator: v.minLengthValidator },
    { data: props.maxLengthValidation, validator: v.maxLengthValidator },
    { data: props.minDateValidation, validator: v.minDateValidator },
    { data: props.maxDateValidation, validator: v.maxDateValidator },
    { data: props.minFileSize, validator: v.minFileSizeValidator },
    { data: props.maxFileSize, validator: v.maxFileSizeValidator },
    {
      data: props.allowedValuesValidation,
      validator: v.allowedValuesValidation,
    },
  ].filter((entry) => entry.data);

  const errorMessages = frontend
    ? entries.map((entry) => {
        if (Array.isArray(entry.data)) {
          const messages = [];
          entry.data.forEach((el) => {
            if (
              !entry.validator({
                inputVal: props.value,
                data: el,
              })
            )
              messages.push(el.message);
          });
          return messages;
        }
        return entry.validator({
          inputVal: props.value,
          data: entry.data,
        }) === false
          ? entry.data.message
          : false;
      })
    : entries.map((entry) => {
        if (Array.isArray(entry.data)) {
          const messages = [];
          entry.data.forEach((el) => {
            if (el?.backendValidationOk === false)
              messages.push(entry.data.message);
          });
          return messages;
        }

        return entry.data?.backendValidationOk === false
          ? entry.data.message
          : false;
      });

  return errorMessages.flatMap((el) => el).filter((msg) => msg !== false); // remove 'false' values
};

export default GetFormInputErrorMessages;
