import React, { useEffect, useState } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './EPOSReprPartiliste.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { EPOSReprPartilisteProps } from '/Models/Generated/EPOSReprPartilisteProps';
import Dropdown, {
  DropdownProps as DropdownAtomProps,
} from '/Components/Atoms/Dropdown/Dropdown';
import { Emit, EventNames } from '/Shared/Code/PushEvent/PushEvent';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const primaryFilterId = 'primaryfilter';

const EPOSReprPartiliste = ({
  mainIntro,
  threeColumnContentAreaInTop,
  mainBody,
  parliamentPeriodDropDown,
  threeColumnContentAreaBottom,
  oneColumnContentArea,
  threeColumnContentArea,
  filterHeader,
}: EPOSReprPartilisteProps) => {
  const selectedPeriodFromProps = (
    parliamentPeriodDropDown.items.filter((i) => i.isSelected)?.[0] ??
    parliamentPeriodDropDown.items[0]
  )?.value;

  const [selectedPeriod, setSelectedPeriod] = useState(selectedPeriodFromProps);
  const [shouldPushState, setShouldPushState] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const eventListener = () => setShouldPushState(false);
      window.addEventListener('popstate', eventListener);
      return () => window.removeEventListener('popstate', eventListener);
    }
  });

  useEffect(() => {
    if (!shouldPushState) setShouldPushState(true);
    else {
      window.history.pushState(
        {},
        '',
        window.location.href.split('?')[0] + '?pid=' + selectedPeriod
      );
      Emit(EventNames.pushstate, null);
    }
    if (isMounted) scrollToTarget(primaryFilterId);
  }, [selectedPeriod]);

  const dropdownName =
    'epos-repr-partiliste-react__parliament-period-drop-down';
  const dropdownAtomProps: DropdownAtomProps = {
    id: dropdownName,
    name: dropdownName,
    selectedValue: selectedPeriod,
    setSelected: setSelectedPeriod,
    options: parliamentPeriodDropDown.items,
    labeledBy:
      'epos-repr-partiliste-react__parliament-period-drop-down-container',
  };

  useEffect(() => setIsMounted(true), []);

  return (
    <PageWrapper>
      <div className="epos-repr-partiliste-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <div className="epos-repr-partiliste-react__main-intro">
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </div>
        </Row>
        {HasContent(threeColumnContentAreaInTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaInTop} />
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
        {parliamentPeriodDropDown && (
          <Row verticalPaddingBottom={VerticalPadding.large}>
            <ExpandableFilterWrapper
              heading={filterHeader}
              initiallyExpanded={false}
              scrollId={primaryFilterId}
            >
              <label
                id={dropdownAtomProps.labeledBy}
                htmlFor={dropdownAtomProps.id}
                className="epos-repr-partiliste-react__parliament-period-drop-down-label"
              >
                {parliamentPeriodDropDown.labelText}
              </label>
              <Dropdown {...dropdownAtomProps} />
            </ExpandableFilterWrapper>
          </Row>
        )}
        {HasContent(oneColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.normal}
          >
            <ContentArea {...oneColumnContentArea} />
          </Row>
        )}
        {HasContent(threeColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentArea} />
          </Row>
        )}
        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaBottom} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSReprPartiliste;
