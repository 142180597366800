import React from 'react';
import './SearchPageFilters.scss';
import { SearchFilterProps } from '/Models/Generated/SearchFilterProps';
import { Row, SearchPageFilter } from '/App.Components';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { setFilter } from '/Shared/Code/Search/SearchSlice';
import { Filter } from '/Shared/Code/Search/SearchResult';

const SearchPageFilters = (props: SearchFilterProps) => {
  const dispatch = useDispatch();
  const results = useSelector((s: RootState) => s.Search.results);
  const isEnglish = useSelector((s: RootState) => s.Search.language) === 'en';

  const translateEnglishDateFilter = (filter: Filter[]): Filter[] => {
    const newFilter = filter ? [...filter] : [];
    newFilter.forEach((f) => {
      if (f.name)
        f.name = (f.name + '')
          .replace('DD', 'to date')
          .replace('Siste døgn', 'Last 24 hours')
          .replace('Siste uke', 'Last week')
          .replace('Siste måned', 'Last month')
          .replace('Siste år', 'Last year');
    });
    return newFilter;
  };

  const translateEnglishEditorialFilter = (filter: Filter[]): Filter[] => {
    const newFilter = filter ? [...filter] : [];
    newFilter.forEach((f) => {
      if (f.name)
        f.name = (f.name + '')
          .replace('Om representantene', 'Members')
          .replace('Om Stortinget', 'About the Storting');
    });
    return newFilter;
  };

  return (
    <Row className="search-page-filters-react">
      <legend className="search-page-filters-heading-react">
        {props.heading}
      </legend>
      <div className="search-page-filters-container">
        <div className="search-page-filters-column">
          <SearchPageFilter
            {...props.filterTime}
            id="filter-time"
            tab1Vals={
              isEnglish
                ? translateEnglishDateFilter(results.GetTimeFilters(props.all))
                : results.GetTimeFilters(props.all)
            }
            setFilter={(filter: string) => dispatch(setFilter(filter))}
          />
        </div>
        <div className="search-page-filters-column">
          <SearchPageFilter
            {...props.filterContent}
            id="filter-content"
            tab1Vals={results.GetContentPublicationsFilters(props.all)}
            tab2Vals={
              isEnglish
                ? translateEnglishEditorialFilter(
                    results.GetContentEditorialFilters(props.all)
                  )
                : results.GetContentEditorialFilters(props.all)
            }
            setFilter={(filter: string) => dispatch(setFilter(filter))}
          />
        </div>
        {!isEnglish && (
          <>
            <div className="search-page-filters-column">
              <SearchPageFilter
                {...props.filterCase}
                id="filter-case"
                tab1Vals={results.GetCaseFilters(props.all)}
                setFilter={(filter: string) => dispatch(setFilter(filter))}
              />
            </div>
            <div className="search-page-filters-column">
              <SearchPageFilter
                {...props.filterTopic}
                id="filter-topic"
                tab1Vals={results.GetTemaFilters(props.all)}
                tab1SubVals={results.GetSubtemaFilters(props.all)}
                setFilter={(filter: string) => dispatch(setFilter(filter))}
              />
            </div>
          </>
        )}
      </div>
    </Row>
  );
};

const SearchPageFiltersWrapper = (props: SearchFilterProps) => (
  <Provider store={SearchStore}>
    <SearchPageFilters {...props} />
  </Provider>
);

export default SearchPageFiltersWrapper;
