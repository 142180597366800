import React from 'react';
import './Radiobuttons.scss';
import cn from 'classnames';

export interface CheckboxOption {
  label: string;
  value;
  checked: boolean;
  name;
}

interface Props {
  id: string;
  options?: CheckboxOption[];
  disabled?: boolean;
  includeAllOption?: boolean;
  setOptions: (value) => void;
  label?: string;
}

const Radiobuttons = ({
  id,
  options,
  disabled,
  includeAllOption,
  setOptions,
  label,
}: Props) => {
  const clickOption = (value) => {
    const clone = JSON.parse(JSON.stringify(options));
    clone.forEach((element) => (element.checked = false));
    clone[value].checked = true;

    setOptions(clone);
  };

  const checkAll = () => {
    const clone = JSON.parse(JSON.stringify(options));
    clone.forEach((element) => (element.checked = false));
    setOptions(clone);
  };

  const makeItem = (option: CheckboxOption, idx: number) => (
    <li key={idx}>
      <label key={idx}>
        <input
          id={id + idx}
          type="radio"
          checked={option.checked}
          onChange={() => clickOption(idx)}
        />
        {option.label}
      </label>
    </li>
  );

  return (
    <div
      className={cn('radiobuttons-react', {
        'disabled-react': disabled,
      })}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <ul id={id} className={cn('radiobuttons-list-react')}>
        {includeAllOption && (
          <li>
            <label>
              <input
                type="radio"
                checked={!options.some((option) => option.checked)}
                onChange={() => checkAll()}
              />
              {'Alle'}
            </label>
          </li>
        )}
        {options && options.map((option, idx) => makeItem(option, idx))}
      </ul>
    </div>
  );
};

export default Radiobuttons;
