import React from 'react';
import { BlockButtonTheme } from '/Models/Generated/BlockButtonTheme';
import { AttendHearingsProps } from '/Models/Generated/AttendHearingsProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import BlockButton from '/Components/Blocks/BlockButton/BlockButton';
import './AttendHearings.scss';

const AttendHearings = (attendHearings: AttendHearingsProps) => {
  return (
    <div className="attended-hearings">
      {attendHearings.headerLink && (
        <BlockButton
          href={attendHearings.headerLink.href}
          text={attendHearings.headerLink.text}
          theme={BlockButtonTheme.ThemeGreyBackgorundWithArrow}
          isInRichTextArea={false}
          epiFullRefreshProperties={null}
        />
      )}
      <ul>
        {attendHearings.hoeringerList?.length > 0 ? (
          attendHearings.hoeringerList.map((val, idx) => (
            <li key={idx}>
              <span>{val.deadline}</span>
              <Link
                href={val.hearingLink.href}
                text={val.hearingLink.text}
                theme={LinkTheme.standard}
              />
            </li>
          ))
        ) : (
          <>
            {attendHearings.noHearingsText ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: attendHearings.noHearingsText,
                }}
              ></div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: attendHearings.hoeringerErrorHtml,
                }}
              ></div>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default AttendHearings;
