import React, { useState } from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './ExpandableInformationBlockForXhtmlBlockType.scss';
import { ExpandableInformationBlockForXhtmlBlockTypeProps } from '/Models/Generated/ExpandableInformationBlockForXhtmlBlockTypeProps';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';

const ExpandableInformationBlockForXhtmlBlockType = ({
  title,
  mainBody,
  mainBodyPropertyName,
  initiallyExpanded,
  uniqueId,
  isInRichTextArea,
}: ExpandableInformationBlockForXhtmlBlockTypeProps) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded || false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsExpanded((current) => !current);
  };

  return mainBody ? (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <div className="expandable-information-block-for-xhtml-block-type">
        <a
          data-toggle={uniqueId}
          className={`expandable-information-block-for-xhtml-block-type__header ${
            isExpanded ? 'expanded' : ''
          }`}
          href="#"
          aria-expanded={isExpanded}
          onClick={handleClick}
        >
          <h2>{title}</h2>
        </a>
        {mainBody && isExpanded && (
          <div className="expandable-information-block-for-xhtml-block-type__content">
            <div className="expandable-information-block-for-xhtml-block-type__spacer">
              <ContentArea {...mainBody} propertyName={mainBodyPropertyName} />
            </div>
          </div>
        )}
      </div>
    </BlockWrapper>
  ) : (
    <></>
  );
};

export default ExpandableInformationBlockForXhtmlBlockType;
