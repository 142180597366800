import React from 'react';
import './PortraitsCarousel.scss';
import CircleImage, {
  CircleImageSizes,
} from '../../Atoms/CircleImage/CircleImage';
import { CommitteeMembersProps } from '../../../Models/Generated/CommitteeMembersProps';

interface PortraitsCarouselProps {
  committeeMembersList: CommitteeMembersProps[];
}

const PortraitsCarousel = ({
  committeeMembersList,
}: PortraitsCarouselProps) => {
  return (
    <div className="portraits-carousel-react">
      <ul
        className="portraits-carousel-react__list"
        style={{ width: `${committeeMembersList.length * 152}px` }}
      >
        {committeeMembersList &&
          committeeMembersList.map((items, itemId) => (
            <li key={itemId}>
              <a href={items.memberUrl}>
                <CircleImage
                  src={items.memberImage.imageSrc}
                  alt={items.memberImage.altText}
                  size={CircleImageSizes.medium}
                  className="portraits-carousel-react__list__img"
                />
                <div>
                  <span>{items.fullName}</span>
                </div>
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PortraitsCarousel;
