import React from 'react';
import './TopMenu.scss';
import { TopMenuProps } from '/Models/Generated/TopMenuProps';
import TopMenuDesktop from './TopMenuDesktop';
import TopMenuMobile from './TopMenuMobile';
import TopMenuLogoPrint from './TopMenuLogoPrint';

const TopMenu = (props: TopMenuProps) => {
  if (props)
    return (
      <header className="top-menu-react">
        <TopMenuLogoPrint />
        <TopMenuDesktop {...props} />
        <TopMenuMobile {...props} />
      </header>
    );
  else return <></>;
};

export default TopMenu;
