import { useEffect, useState } from 'react';

const useUrlHash = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const urlHash = window.location.hash;
    setValue(urlHash?.length > 0 ? urlHash.substring(1) : initialValue);
  }, [initialValue]);

  useEffect(() => {
    const handlePopstate = () => {
      const urlHash = window.location.hash;
      setValue(urlHash?.length > 0 ? urlHash : initialValue);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);
    url.hash = value || '';
    window.history.replaceState(null, '', url.toString());
  }, [value]);

  const setUrlHash = (newValue) => {
    setValue(newValue);
    const url = new URL(window.location.href);
    url.hash = newValue || '';
    window.history.pushState(null, '', url.toString());
  };

  return [value, setUrlHash] as const;
};

export default useUrlHash;
