import React, { useState } from 'react';
import './TopMenuDesktop.scss';
import { TopMenuProps } from '/Models/Generated/TopMenuProps';
import TopMenuSection from './TopMenuSection';
import TopMenuSectionContent from './TopMenuSectionContent';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import cn from 'classnames';
import TopMenuLanguageSwitcher from './TopMenuLanguageSwitcher';
import TopMenuSearch from './TopMenuSearch';
import TopMenuGlobalSearchSection from './TopMenuGlobalSearchSection';
import TopMenuSearchSectionContent from './TopMenuSearchSectionContent';

const noOpenSection = -1;
const searchSection = -2;

const TopMenuDesktop = (props: TopMenuProps) => {
  const [openSection, setOpenSection] = useState(noOpenSection);

  const toggleOpenSection = (id: number) => {
    setOpenSection(openSection === id ? noOpenSection : id);
  };

  const toggleSearch = () => toggleOpenSection(searchSection);

  const idSearch = 'top-menu-desktop-search';
  const idMenuButton = 'top-menu-desktop-menu';

  const getBodyHeight = () => {
    const h1 = document?.body.offsetHeight ?? 0;
    const h2 =
      document?.getElementsByClassName('main-container')[0]?.offsetHeight ?? 0;
    return Math.max(h1, h2);
  };

  if (props)
    return (
      <div className="top-menu-desktop-react">
        <a href="#main-content" className="top-menu-desktop-skip">
          {props.jumpToMainContentText}
        </a>
        <div
          className={cn({
            'top-menu-desktop-overlay': openSection !== noOpenSection,
          })}
          style={{
            height: openSection === noOpenSection ? 0 : getBodyHeight() ?? 0,
          }}
        >
          <div className="top-bar-container-react">
            <Row horizontalPadding={HorizontalPadding.normal}>
              <div className="top-bar-react">
                <div className="logo-react">
                  <a
                    href={props.logoUrl}
                    className="top-menu-desktop-logo-link"
                    aria-label="logo"
                  >
                    {props.isGriefMode ? (
                      <img
                        src={require('/Assets/Images/logo-black.svg')}
                        alt={props.logoAltText}
                      />
                    ) : (
                      <img
                        src={require('/Assets/Images/logo.svg')}
                        alt={props.logoAltText}
                      />
                    )}
                  </a>
                </div>
                <div className="top-bar-links">
                  <nav
                    className="top-menu-sections"
                    aria-label="Hovednavigasjon"
                  >
                    {props.sectionsContainer.sections.map((s, idx) => (
                      <div key={idx}>
                        <TopMenuSection
                          {...s}
                          id={idx}
                          ariaControls={idMenuButton + idx}
                          isOpen={idx === openSection}
                          setOpenSection={setOpenSection}
                        />

                        <div className="top-bar-section-container">
                          <div className="top-bar-section-container-inner">
                            <TopMenuSectionContent
                              {...s}
                              id={idx}
                              containerId={idMenuButton + idx}
                              key={idx}
                              isOpen={idx === openSection}
                              toggleOpenSection={toggleOpenSection}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </nav>
                  <TopMenuLanguageSwitcher {...props.languageNodes} />
                  <TopMenuSearch
                    toggleSearch={toggleSearch}
                    {...props.search}
                    ariaControls={idSearch}
                  />
                  <div className="top-bar-section-container">
                    <div className="top-bar-section-container-inner">
                      {props.search.featureToggleNewSearch ? (
                        <TopMenuGlobalSearchSection
                          {...props.search}
                          toggleSearch={toggleSearch}
                          isOpen={openSection === searchSection}
                          containerId={idSearch}
                        />
                      ) : (
                        <TopMenuSearchSectionContent
                          {...props.search}
                          toggleSearch={toggleSearch}
                          isOpen={openSection === searchSection}
                          containerId={idSearch}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  else return <></>;
};

export default TopMenuDesktop;
