import React from 'react';
import './ActionBar.scss';
import { ActionBarProps } from 'Models/Generated/ActionBarProps';
import BreadCrumbs from './BreadCrumbs';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import PageActions from '/Components/Layout/PageActions/PageActions';
import cn from 'classnames';

const ActionBar = (props: ActionBarProps) => {
  if (props)
    return (
      <Row horizontalPadding={HorizontalPadding.normal}>
        <div
          className={cn('action-bar-react', {
            'with-top-border-react': props.showBorder,
          })}
        >
          <div className="flow-from-left">
            {props.breadCrumbs && <BreadCrumbs {...props.breadCrumbs} />}
          </div>
          <div className="flow-from-right">
            <PageActions {...props.pageActions} />
          </div>
        </div>
      </Row>
    );
  else return <></>;
};

export default ActionBar;
