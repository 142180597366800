import React from 'react';
import './LiveMeetingInformationBlockTypeSliderItem.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import consts from './consts';
import lang from './lang';
import LiveMeetingInformationBlockTypeSliderItemInformation from './LiveMeetingInformationBlockTypeSliderItemInformation';

const LiveMeetingInformationBlockTypeSliderItem = (item: SliderItem) => {
  const { cmsData } = useSelector((s: RootState) => s.LiveBlock);

  const textCanceled = lang.canceled[cmsData.lang];
  const videoTempText = item.isHearing
    ? cmsData.mainBlock.tempTextHearing
    : cmsData.mainBlock.tempTextStorting;

  const isCanceled = () => item.status === consts.status.canceled;

  return (
    <li className="live-meeting-information-block-type-slider-item-react">
      <div className="live-meeting-information-block-type-slider-item-react__split">
        <LiveMeetingInformationBlockTypeSliderItemInformation {...item} />
        <div className="live-meeting-information-block-type-slider-item-react__video-container">
          {isCanceled() && (
            <div className="live-meeting-information-block-type-slider-item-react__video-meta rec-react active-react">
              {textCanceled}
            </div>
          )}
          {!isCanceled() && (
            <div className="live-meeting-information-block-type-slider-item-react__video-ended">
              <span>{videoTempText}</span>
            </div>
          )}
          <img
            src={item.fallback}
            alt={item.altText}
            title={item.altText}
            draggable={false}
          />
        </div>
      </div>
      <div className="live-meeting-information-block-type-slider-item-react__gradient"></div>
    </li>
  );
};

const LiveMeetingInformationBlockTypeSliderItemWrapper = (
  props: SliderItem
) => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeSliderItem {...props} />
  </Provider>
);

export default LiveMeetingInformationBlockTypeSliderItemWrapper;
