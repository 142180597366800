import React from 'react';
import './EditableTextBlockType.scss';
import { EditableTextBlockTypeProps } from '/Models/Generated/EditableTextBlockTypeProps';
import ArticlePreview from '/Components/Organisms/ArticlePreview/ArticlePreview';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';

const EditableTextBlockType = (props: EditableTextBlockTypeProps) => (
  <BlockWrapper isInRichTextArea={props.isInRichTextArea}>
    {(props.imageUrl || props.videoUrl) && <ArticlePreview {...props} />}
    {!(props.imageUrl || props.videoUrl) && (
      <div className="editable-text-block-type-react">
        <ArticlePreview {...props} />
      </div>
    )}
  </BlockWrapper>
);

export default EditableTextBlockType;
