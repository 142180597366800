import React, { useEffect, useRef } from 'react';
import './EPOSRepresentantContactHeader.scss';
import { RepresentativeShortInfoProps } from '/Models/Generated/RepresentativeShortInfoProps';
import CircleImage, {
  CircleImageSizes,
} from '/Components/Atoms/CircleImage/CircleImage';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';

const reprVarslingDivId = 'essens-varsling';

const EPOSRepresentantContactHeader = ({
  biographyDetails,
  birthDate,
  governmentMemberTitle,
  image,
  name,
  showEmailSubscribe,
}: RepresentativeShortInfoProps) => {
  const formDivRef = useRef(null);

  useEffect(() => {
    if (!showEmailSubscribe) return;

    // modified version of old function
    (function () {
      const essensDomain =
        [
          'preprodepistortinget.stortinget.no',
          'dev.stortinget.no',
          'testwww.stortinget.no',
          'npostest.stortinget.no',
        ].indexOf(window.location.host) >= 0
          ? 'https://testsok.stortinget.no'
          : 'https://ssl.essens.no';

      const essensScript = document.createElement('script');

      essensScript.src = `
        ${essensDomain}/site/ialert
        ?c=${reprVarslingDivId}
        &t=${encodeURIComponent(document.title)}
        &ref=${encodeURIComponent(document.location.href)}`.replace(/\s+/g, '');

      formDivRef.current.appendChild(essensScript);
    })();
  }, []);

  return (
    <div className="epos-representant-contact-header-react">
      <div className="epos-representant-contact-header-react__img-container">
        <CircleImage
          src={image.imageSrc}
          alt={image.altText}
          size={CircleImageSizes.medium}
        />
      </div>
      <div className="epos-representant-contact-header-react__biography">
        <h1 className="epos-representant-contact-header-react__biography__title">
          <span>{name}</span>{' '}
          <span className="epos-representant-contact-header-react__biography__title__year">
            {birthDate}
          </span>
        </h1>
        {governmentMemberTitle && <span>{governmentMemberTitle}</span>}
        {biographyDetails && (
          <div className="epos-representant-contact-header-react__biography__details">
            <dl>
              {/* Political party */}
              {biographyDetails.politicalParty && (
                <>
                  <dt>{biographyDetails.politicalParty.prefix}</dt>
                  <dd>
                    {biographyDetails.politicalParty.isLink && (
                      <a href={biographyDetails.politicalParty.href}>
                        {biographyDetails.politicalParty.text}
                      </a>
                    )}
                    {!biographyDetails.politicalParty.isLink && (
                      <span>{biographyDetails.politicalParty.text}</span>
                    )}
                  </dd>
                </>
              )}
              {/* County */}
              {biographyDetails.county && (
                <>
                  <dt>{biographyDetails.county.prefix}</dt>
                  <dd>
                    {biographyDetails.county.isLink && (
                      <a href={biographyDetails.county.href}>
                        {biographyDetails.county.text}
                      </a>
                    )}
                    {!biographyDetails.county.isLink && (
                      <span>{biographyDetails.county.text}</span>
                    )}
                    &nbsp;
                    {biographyDetails.parliamentPeriod}
                  </dd>
                </>
              )}
              {/* Committees */}
              {biographyDetails.committeeRoles && (
                <>
                  {biographyDetails.committeeRoles.map((item, key) => (
                    <div
                      key={key}
                      className="epos-representant-contact-header-react__biography__details"
                    >
                      <dl>
                        <dt>{item.role}</dt>
                        {item.name && (
                          <dd>
                            {item.name.isLink && (
                              <a href={item.name.href}>{item.name.text}</a>
                            )}
                            {!item.name.isLink && <span>{item.name.text}</span>}
                          </dd>
                        )}
                      </dl>
                    </div>
                  ))}
                </>
              )}
              {/* Seniority */}
              {biographyDetails.seniority && (
                <>
                  <dt>{biographyDetails.seniorityPrefix}</dt>
                  <dd>{biographyDetails.seniority}</dd>
                </>
              )}
            </dl>
            {/* Go to Gallery */}
            {biographyDetails.gallery && (
              <p>
                <IconLinkGeneric
                  {...biographyDetails.gallery}
                  iconString="document"
                />
              </p>
            )}
            {/* Deputy (vararep.) */}
            {biographyDetails.deputyProps &&
              biographyDetails.deputyProps.links && (
                <>
                  {biographyDetails.deputyProps.links.map((item, _) => (
                    <>
                      {item.isLink && (
                        <>
                          <a href={item.href}>{item.text}</a>
                          {item.postfix}
                        </>
                      )}
                      {!item.isLink && <>{item.text}</>}
                    </>
                  ))}
                  <br />
                </>
              )}
          </div>
        )}
      </div>
      <div className="epos-representant-contact-header-react__email-subscription">
        <div id={reprVarslingDivId} ref={formDivRef}></div>
      </div>
    </div>
  );
};
export default EPOSRepresentantContactHeader;
