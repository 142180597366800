import React from 'react';
import { XmlImportSearchNavigationProps } from '/Models/Generated/XmlImportSearchNavigationProps';

type Props = XmlImportSearchNavigationProps & {
  currentMatch: number;
  handlePrevClick: () => void;
  handleNextClick: () => void;
  handleAbortClick: () => void;
};

const XmlImportNavigationSearchNavigation = ({
  searchMatchCount,
  searchMatchText,
  searchPrevLabel,
  searchNextLabel,
  searchHitsOfLabel,
  searchCancelLabel,
  currentMatch,
  handlePrevClick,
  handleNextClick,
  handleAbortClick,
}: Props) => {
  const currentMatchDisplay = currentMatch < 0 ? 1 : currentMatch;
  const disablePrevButton = currentMatch < 2;
  const disableNextButton = currentMatch >= searchMatchCount;

  return (
    <div className="xmlimport-navigation-react__search-navigation">
      <div className="xmlimport-navigation-react__search-navigation__inner">
        <div className="xmlimport-navigation-react__search-navigation__search-match-text">
          {searchMatchText}
        </div>
        {searchMatchCount > 0 && (
          <div className="xmlimport-navigation-react__search-navigation__search-match-container">
            <button
              className="xmlimport-navigation-react__search-navigation__button"
              disabled={disablePrevButton}
              onClick={handlePrevClick}
            >
              {searchPrevLabel}
            </button>
            <span>
              {currentMatchDisplay} {searchHitsOfLabel} {searchMatchCount}
            </span>
            <button
              className="xmlimport-navigation-react__search-navigation__button"
              disabled={disableNextButton}
              onClick={handleNextClick}
            >
              {searchNextLabel}
            </button>
            <button
              className="xmlimport-navigation-react__search-navigation__button xmlimport-navigation-react__search-navigation__button--cancel-search"
              onClick={handleAbortClick}
            >
              {searchCancelLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default XmlImportNavigationSearchNavigation;
