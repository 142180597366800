import React, { useEffect, useRef, useState } from 'react';
import './FlickrSlideshow.scss';
import { FlickrSlideShowProps } from '/Models/Generated/FlickrSlideShowProps';
import FlickrClient from '/Shared/Code/Flickr/FlickrClient';
import cn from 'classnames';

enum Direction {
  None,
  FromLeft,
  FromRight,
}

const FlickrSlideshow = ({
  flickrId,
  flickrAmount,
  flickrUrl,
  header,
  seeImageOnFlickr,
}: FlickrSlideShowProps) => {
  const [photos, setPhotos] = useState([]);
  const [scrollingTo, setScrollingTo] = useState(-1);
  const [initialDragX, setInitialDragX] = useState(-1);
  const [DragDiff, setDragDiff] = useState(-1);
  const [direction, setDirection] = useState(Direction.None);

  const scrollingToRef = useRef(-1);
  const imageRef = useRef(null);
  scrollingToRef.current = scrollingTo;

  const trackContainerRef = useRef(null);
  const trackRef = useRef(null);

  const getSafeIndex = (idx: number, max: number) => {
    const tmp = idx % max;
    return (tmp + max) % max;
  };

  const imageStyle =
    imageRef?.current?.clientHeight > imageRef?.current?.clientWidth
      ? {}
      : { width: '100%' };

  const slideInImage = (offset: number) => {
    if (offset === -1) setDirection(Direction.FromLeft);
    if (offset === 1) setDirection(Direction.FromRight);
    const newIdx = getSafeIndex(scrollingTo + offset, photos.length);
    setScrollingTo(newIdx);
  };

  const handleDragStart = (e) => {
    setInitialDragX(e.clientX);
  };

  const handleDragEnd = () => {
    const limit = 150;
    if (limit < Math.abs(DragDiff)) {
      slideInImage(DragDiff > 0 ? -1 : 1);
    }
    setInitialDragX(-1);
    setDragDiff(-1);
  };

  const handleDrag = (e) => {
    if (e.clientX !== 0) {
      setDragDiff(e.clientX - initialDragX);
    }
  };

  useEffect(() => {
    const apiKey = '01c936d201a26f72c32e02873a19b256';
    new FlickrClient(apiKey).Get(flickrId, flickrAmount).then((res) => {
      setPhotos(res);
      setScrollingTo(0);
    });
  }, []);

  return (
    <div className="image-carousel-react">
      {header && <h2 className="image-carousel-react__sr-only">{header}</h2>}
      {!!photos?.length && (
        <>
          <button
            onClick={() => slideInImage(-1)}
            aria-label="forrige bilde"
            className="image-carousel-react__btn image-carousel-react__btn--prev"
          />
          <button
            onClick={() => slideInImage(1)}
            aria-label="neste bilde"
            className="image-carousel-react__btn image-carousel-react__btn--next"
          />
        </>
      )}
      <div
        className="image-carousel-react__track-container"
        ref={trackContainerRef}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDrag={handleDrag}
        style={{ transform: `translateX(${DragDiff === -1 ? 0 : DragDiff}px)` }}
      >
        <div className="image-carousel-react__track" ref={trackRef}>
          {photos &&
            photos.map((p, idx) => (
              <figure
                key={idx}
                className={cn('image-carousel-react__track__item', {
                  'image-carousel-react__track__item--hidden':
                    scrollingTo !== idx,
                  'image-carousel-react__track__item--fromleft':
                    direction === Direction.FromLeft && scrollingTo === idx,
                  'image-carousel-react__track__item--fromright':
                    direction === Direction.FromRight && scrollingTo === idx,
                })}
                onAnimationEnd={() => setDirection(Direction.None)}
              >
                <a href={flickrUrl + p?.id}>
                  <img
                    src={p?.imgSrc}
                    style={imageStyle}
                    alt={seeImageOnFlickr}
                  />
                </a>
                <div className="image-carousel-react__track__item__text">
                  {!!p?.title && <h3>{p?.title}</h3>}
                  {!!p?.description && <p>{p?.description}</p>}
                </div>
              </figure>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FlickrSlideshow;
