import React from 'react';
import './LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees.scss';
import lang from './lang';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useSelector } from 'react-redux';

const LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);

  const title = lang.attendeesFromGovernment[cmsData.lang];
  const attendees = liveData.meetingActivity.participatingGovernmentMemberList;
  return (
    <div className="live-meeting-information-block-type-top-text-box-government-attendees-react">
      <span>{title}</span>
      <ul className="live-meeting-information-block-type-top-text-box-government-attendees-react__live-profiles">
        {attendees.map((attendee, idx) => (
          <li key={idx}>
            <div className="live-meeting-information-block-type-top-text-box-government-attendees-react__profile-image">
              <div className="live-meeting-information-block-type-top-text-box-government-attendees-react__image-wrapper">
                <img
                  src={attendee.pictureUrl}
                  alt={attendee.name}
                  title={attendee.name}
                />
              </div>
            </div>
            <div className="live-meeting-information-block-type-top-text-box-government-attendees-react__profile-content">
              {attendee.name}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendeesWrapper =
  () => (
    <Provider store={SearchStore}>
      <LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees />
    </Provider>
  );

export default LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendeesWrapper;
