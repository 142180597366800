import React from 'react';
import './Forsiden.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { ForsidenProps } from '/Models/Generated/ForsidenProps';
import Column, { Width } from '/Components/Layout/Column/Column';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import WhatsUp from './WhatsUp';
import NewsListTwoColumns from './NewsListTwoColumns';
import DecisionsInShort from './DecisionsInShort';
import AttendHearings from './AttendHearings';
import FindRepresentative from './FindRepresentative';

const Forsiden = ({
  decisionsInShort,
  attendHearings,
  findRepresentative,
  newsListTwoColumns,
  whatsUp,
  topContentArea,
  bottomContentArea,
}: ForsidenProps) => {
  return (
    <>
      {HasContent(topContentArea) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ContentArea {...topContentArea} />
        </Row>
      )}
      <Row horizontalPadding={HorizontalPadding.normal}>
        <div className="frontpage-react">
          <div className="newslist-wrapper">
            {whatsUp && (
              <Column desktop={Width.oneThird}>
                <WhatsUp {...whatsUp} />
              </Column>
            )}
            {newsListTwoColumns && (
              <Column desktop={Width.twoThirds}>
                <NewsListTwoColumns {...newsListTwoColumns} />
              </Column>
            )}
          </div>
          <div className="three-col">
            {decisionsInShort && (
              <Column desktop={Width.oneThird}>
                <DecisionsInShort {...decisionsInShort} />
              </Column>
            )}
            {attendHearings && (
              <Column desktop={Width.oneThird}>
                <AttendHearings {...attendHearings} />
              </Column>
            )}
            {findRepresentative && (
              <Column desktop={Width.oneThird}>
                <FindRepresentative {...findRepresentative} />
              </Column>
            )}
          </div>
          <ContentArea {...bottomContentArea} />
        </div>
      </Row>
    </>
  );
};

export default Forsiden;
