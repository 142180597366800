import React, { useEffect, useRef, useState } from 'react';
import './EPOSVedtakliste.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { EPOSVedtaklisteProps } from '/Models/Generated/EPOSVedtaklisteProps';
import { DropDownProps } from '/Models/Generated/DropDownProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import FocusItem2 from '/Components/Organisms/FocusItem2/FocusItem2';
import Pagination from '/Components/Atoms/Pagination/Pagination';
import EPOSVedtaklisteClient from '/Shared/Code/EPOSVedtakliste/EPOSVedtaklisteClient';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import Spinner from '/Components/Atoms/Spinner/Spinner';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

export interface EPOSVedtaklisteState {
  pid: string;
  m: string;
  dtid: string;
  page: number;
}

const selectedOrFirstVal = (list: DropDownProps) =>
  list?.items?.find((item) => item.isSelected)?.value ||
  list.items?.[0]?.value ||
  '';
const PAGE_SIZE = 20; // old solution page size

const getParamsState = (): EPOSVedtaklisteState => ({
  pid: SSRQueryStringReader.Read('pid'),
  m: SSRQueryStringReader.Read('m'),
  dtid: SSRQueryStringReader.Read('dtid'),
  page: +SSRQueryStringReader.Read('page') || 1,
});

const initState = getParamsState();

const filterId = 'primaryfilter';

const EPOSVedtakliste = ({
  mainIntro,
  threeColumnContentAreaTop,
  threeColumnContentAreaModules,
  mainBody,
  apiEndpoint,
  pageId,
  filterHeading,
  periodDropDown: periodDD,
  monthDropDown: monthDD,
  decisionTypeDropDown: dtDD,
  listItems: listItemsProp,
  noResultsErrorMessage,
  threeColumnContentArea,
  pagination,
}: EPOSVedtaklisteProps) => {
  const mountedRef = useRef(false);
  const client = new EPOSVedtaklisteClient(apiEndpoint, pageId, PAGE_SIZE);

  const [listItems, setListItems] = useState(listItemsProp);
  const [monthDropdownData, setMonthDropdownData] = useState(monthDD);
  const [selectedPeriod, setSelectedPeriod] = useState(
    initState.pid || selectedOrFirstVal(periodDD)
  );
  const [selectedMonth, setSelectedMonth] = useState(
    initState.m || selectedOrFirstVal(monthDD)
  );
  const [selectedDecType, setSelectedDecType] = useState(
    initState.dtid || selectedOrFirstVal(dtDD)
  );

  const [selectedPage, setSelectedPage] = useState(initState.page);
  const [totalPages, setTotalPages] = useState(pagination.totalPages || 0);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const listTitle =
    dtDD.items.find((el) => el.value === selectedDecType)?.text || '';

  useEffect(() => {
    if (loading) {
      client
        .Get(selectedPeriod, selectedMonth, selectedDecType, selectedPage)
        .then((res) => {
          setListItems(res.results || []);
          if (res.monthDropDown) setMonthDropdownData(res.monthDropDown);
          setTotalPages(res.totalPages || 0);
          setLoading(false);
          scrollToTarget(filterId);
        });
    }
  }, [loading]);

  useEffect(() => {
    if (mountedRef.current) {
      // We want the first page when changing filters
      setSelectedPage(1); // will trigger refreshData in other useEffect
      setLoading(true);
    }
  }, [selectedPeriod, selectedMonth, selectedDecType]);

  useEffect(() => {
    if (mountedRef.current) setLoading(true);
  }, [selectedPage]);

  useEffect(() => {
    if (mountedRef.current) {
      if (typeof window === undefined) return;
      if (!reload) {
        const newUrl = client.BuildFilterUrl(
          selectedPeriod,
          +selectedMonth,
          selectedDecType,
          selectedPage
        );
        window.history.pushState({}, '', newUrl);
      }
      setReload(false);
    }
  }, [selectedPeriod, selectedMonth, selectedDecType, selectedPage]);

  useEffect(() => {
    if (!reload) return;
    const paramState = getParamsState();

    if (paramState.pid) setSelectedPeriod(paramState.pid);
    setSelectedMonth(paramState.m ?? 'all');
    if (paramState.dtid) setSelectedDecType(paramState.dtid);
    if (paramState.page) setSelectedPage(+paramState.page);
  }, [reload]);

  // Fires once. Sets "mountedRef" to true and adds eventlistener for popstate
  useEffect(() => {
    mountedRef.current = true;
    if (typeof window !== 'undefined') {
      const eventListener = () => setReload(true);
      window.addEventListener('popstate', eventListener);
      return () => window.removeEventListener('popstate', eventListener);
    }
  }, []);

  const buildLinkFunction = (page: number) => {
    const url = window.location.href.split('?')[0].split('#')[0];
    const usp = new URLSearchParams(new URL(window.location.href).search);
    usp.set('page', page + '');
    return url + '?' + usp.toString() + '#list';
  };

  return (
    <PageWrapper>
      <div className="epos-vedtakliste-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainIntro} />
          <ContentArea {...threeColumnContentAreaTop} />
          <ContentArea {...threeColumnContentAreaModules} />
          <ContentArea {...mainBody} />
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.none}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ExpandableFilterWrapper heading={filterHeading}>
            <div id={filterId} className="epos-vedtakliste-react__filter">
              <div>
                <p>{periodDD.labelText}</p>
                <Dropdown
                  id='"epos_vedtakliste_filter_dropdown_1'
                  name='"epos_vedtakliste_filter_dropdown_1'
                  options={periodDD.items}
                  selectedValue={selectedPeriod}
                  setSelected={setSelectedPeriod}
                  disabled={loading}
                />
              </div>
              <div>
                <p>{monthDropdownData.labelText}</p>
                <Dropdown
                  id='"epos_vedtakliste_filter_dropdown_2'
                  name='"epos_vedtakliste_filter_dropdown_2'
                  options={monthDropdownData.items}
                  selectedValue={selectedMonth}
                  setSelected={setSelectedMonth}
                  disabled={loading}
                />
              </div>
              <div>
                <p>{dtDD.labelText}</p>
                <Dropdown
                  id='"epos_vedtakliste_filter_dropdown_3'
                  name='"epos_vedtakliste_filter_dropdown_3'
                  options={dtDD.items}
                  selectedValue={selectedDecType}
                  setSelected={setSelectedDecType}
                  disabled={loading}
                />
              </div>
            </div>
          </ExpandableFilterWrapper>
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <a name="list"></a>
          {loading && <Spinner />}
          {!loading && (
            <>
              <GreyBar text={listTitle} />
              {listItems?.length > 0 && (
                <ul className="epos-vedtakliste-react__list">
                  {listItems.map((item, idx) => (
                    <li key={idx}>
                      <FocusItem2 {...item} />
                    </li>
                  ))}
                </ul>
              )}
              {!(listItems?.length > 0) && (
                <div className="epos-vedtakliste-react__no-results">
                  {noResultsErrorMessage}
                </div>
              )}
              {totalPages > 1 && (
                <div className="epos-vedtakliste-react__pagination">
                  <Pagination
                    {...pagination}
                    currentPage={selectedPage}
                    totalPages={totalPages}
                    buildLinkFunction={buildLinkFunction}
                  />
                </div>
              )}
            </>
          )}
          <ContentArea {...threeColumnContentArea} />
        </Row>
      </div>
    </PageWrapper>
  );
};

export default EPOSVedtakliste;
