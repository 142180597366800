import React from 'react';
import './MinutesNavigationBar.scss';
import { MinutesNavigationBarProps } from '/Models/Generated/MinutesNavigationBarProps';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';

const MinutesNavigationBar = ({
  agendaLabel,
  frontpageLink,
  pdfVersionLabel,
  pdfLink,
  videoLinks,
}: MinutesNavigationBarProps) => {
  return (
    <div className="minutes-navigation-bar-react">
      {frontpageLink && (
        <IconLinkGeneric
          iconString="document"
          href={frontpageLink}
          text={agendaLabel}
        />
      )}
      {pdfLink && (
        <IconLinkGeneric
          iconString="download"
          href={pdfLink}
          text={pdfVersionLabel}
        />
      )}
      {videoLinks?.length > 0 &&
        videoLinks.map((video, idx) => (
          <IconLinkGeneric
            key={idx}
            iconString="video"
            href={video.href}
            text={video.text}
          />
        ))}
    </div>
  );
};

export default MinutesNavigationBar;
