import React from 'react';
import './IconLink.scss';
import { IconLinkProps } from '/Models/Generated/IconLinkProps';
import { Icon } from '/Models/Generated/Icon';
import cn from 'classnames';

const IconLink = (props: IconLinkProps) => {
  if (props.href && props.text)
    return (
      <a
        className={cn(
          'icon-link',
          props.icon !== Icon.None
            ? 'icon svgicon-' + new String(props.icon).toLowerCase()
            : ''
        )}
        href={props.href}
      >
        {props.text}
      </a>
    );
  else return <></>;
};

export default IconLink;
