import React from 'react';
import cn from 'classnames';
import './ResultReply.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import { Props } from '/Models/FrontendOnly/Search/Results';
import { ResultHeader } from '/App.Components';

const ResultReply = ({
  specifics,
  title,
  summary,
  url,
  relevance,
  highlighting,
  isChild,
}: Props) => {
  return (
    <article
      className={cn('search-result-reply-article-react', {
        'is-child-react': isChild,
      })}
    >
      <ResultHeader {...{ title, url, specifics, relevance }} />
      <ResultSummary summary={summary} />
      <ResultHighlighting highlighting={highlighting} />
    </article>
  );
};

export default ResultReply;
