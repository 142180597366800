import React, { useState } from 'react';
import './SearchPageFilter.scss';
import Dropdown, { DropdownOption } from '/Components/Atoms/Dropdown/Dropdown';
import cn from 'classnames';
import { SearchFilterContainerProps } from '/Models/Generated/SearchFilterContainerProps';
import { Filter } from '/Shared/Code/Search/SearchResult';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { setOpenFilter } from '/Shared/Code/Search/SearchSlice';
import { useDispatch, useSelector, Provider } from 'react-redux';

interface Props extends SearchFilterContainerProps {
  tab1Vals?: Filter[];
  tab1SubVals?: Filter[];
  tab2Vals?: Filter[];
  id: string;
  setFilter: (filter: string) => void;
}

const SearchPageFilter = ({
  id,
  setFilter,
  heading,
  tab1Label,
  tab1FilterLabel,
  tab1Vals,
  tab1SubFilterLabel,
  tab1SubVals,
  tab2Label,
  tab2FilterLabel,
  tab2Vals,
}: Props) => {
  const getSelectedValue = (filterResults?: Filter[]) =>
    filterResults?.filter((f) => f.selected)?.[0]?.refine_groups ?? '';

  const getSelectedLabel = (filterResults?: Filter[]) =>
    filterResults?.filter((f) => f.selected && f.refine_groups.length)?.[0]
      ?.name ?? '';

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  const openFilter = useSelector((state: RootState) => state.Search.openFilter);

  const mapFilterResults = (filterResults: Filter[]): DropdownOption[] =>
    filterResults?.map((fr) => ({
      text: fr.num_docs ? `${fr.name} (${fr.num_docs})` : fr.name,
      value: fr.refine_groups,
      selected: fr.selected,
    })) ?? [];

  const renderFilter = (
    filterIdPostfix: string,
    filters: Filter[],
    setSelected: (value: string) => void,
    filterLabel?: string,
    className?: string
  ) => {
    if (!filterLabel) return <></>;
    const filterResults = mapFilterResults(filters);
    const selected = getSelectedValue(filters);
    return (
      <>
        <label className="dropdown-label">{filterLabel}</label>
        <Dropdown
          id={id + filterIdPostfix}
          options={filterResults}
          selectedValue={selected}
          setSelected={setSelected}
          className={className}
          disableIfOnlyOneOption={true}
          selectedOnTop={false}
        />
      </>
    );
  };

  const getHeading = () => {
    const tab1 = getSelectedLabel(tab1Vals);
    const tab1Value = getSelectedValue(tab1Vals);
    const tab1Sub = getSelectedLabel(tab1SubVals);
    const tab1SubValue = getSelectedValue(tab1SubVals);
    const tab2 = getSelectedLabel(tab2Vals);
    let result = `${heading}: `;
    if (tab1) {
      if (tab1Label) result += `${tab1Label}, `;
      result += tab1;
      return tab1Sub && tab1Value !== tab1SubValue
        ? `${result}, ${tab1Sub}`
        : result;
    }
    if (tab2) return `${result}${tab2Label}, ${tab2}`;
    return heading;
  };

  return (
    <div className={cn('search-page-filter-react')}>
      <label
        className={cn('search-page-dropdown-filter-heading', {
          'is-open-react': id === openFilter,
        })}
        htmlFor={id}
        onClick={() => dispatch(setOpenFilter(id === openFilter ? '' : id))}
      >
        <button
          className="search-page-filter-button"
          onClick={(e) => e.preventDefault()}
        >
          {getHeading()}
        </button>
      </label>
      <div
        id={id}
        className={cn('search-page-dropdown-filter-container', {
          'visible-react': id === openFilter,
        })}
      >
        {tab1Label && tab2Label && (
          <div className="tab-container">
            {tab1Label && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(1);
                }}
                className={cn({ 'selected-react': selectedTab === 1 })}
              >
                {tab1Label}
              </button>
            )}
            {tab2Label && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(2);
                }}
                className={cn({ 'selected-react': selectedTab === 2 })}
              >
                {tab2Label}
              </button>
            )}
          </div>
        )}
        <div className="filters-container">
          {selectedTab === 1 && (
            <>
              {renderFilter(
                'tab1mainfilter',
                tab1Vals,
                setFilter,
                tab1FilterLabel,
                'filters-container-top-layer'
              )}
              {renderFilter(
                'tab1subfilter',
                tab1SubVals,
                setFilter,
                tab1SubFilterLabel
              )}
            </>
          )}
          {selectedTab === 2 &&
            tab2FilterLabel &&
            renderFilter('tab2filter', tab2Vals, setFilter, tab2FilterLabel)}
        </div>
      </div>
    </div>
  );
};

const SearchPageFilterWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <SearchPageFilter {...props} />
  </Provider>
);

export default SearchPageFilterWrapper;
