import { FormInputProps } from '/Models/Generated/FormInputProps';
import { DropdownProps } from '/Components/Atoms/Dropdown/Dropdown';
import Dropdown, { DropdownOption } from '/Components/Atoms/Dropdown/Dropdown';
import React from 'react';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';

interface Props extends FormInputProps {
  id: string;
  labeledBy?: string;
  value: string;
  options?: DropdownOption[];
  selectedOnTop?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (value) => void;
  disableIfOnlyOneOption?: boolean;
  setErrorMessage: (errorMessage: string) => void;
}

const DropdownWrapper = (props: Props) => {
  const {
    id,
    name,
    readonly,
    values,
    onChange,
    setErrorMessage,
    value,
    disabled,
  } = props;
  const mapped: DropdownProps = {
    id,
    name,
    setSelected: onChange,
    disabled: readonly || disabled,
    options: values,
    selectedValue:
      values.filter((val) => val.value === value)[0]?.value ?? values[0].value,
  };
  return (
    <Dropdown
      {...mapped}
      onBlur={(value) => {
        const errorMessages = GetFormInputErrorMessages({ ...props, value });
        if (errorMessages.length) setErrorMessage(errorMessages[0]);
        else setErrorMessage('');
      }}
    />
  );
};

export default DropdownWrapper;
