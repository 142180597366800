import React, { useEffect, useRef } from 'react';
import './DokumentKapittel.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { DokumentKapittelProps } from '/Models/Generated/DokumentKapittelProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import DokumentNavigasjon from '/Components/Organisms/DokumentNavigasjon/DokumentNavigasjon';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { NestedLinkProps } from '/Models/Generated/NestedLinkProps';
import useElementDimension from '/Shared/Code/Hooks/UseElementDimension';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';
interface TocProps {
  toc: NestedLinkProps[];
}
interface ItemProps {
  item: NestedLinkProps;
}

const DokumentKapittel = ({
  navigationProps,
  title,
  toc,
  mainBody,
  content,
  previousLink,
  nextLink,
}: DokumentKapittelProps) => {
  const navigationRef = useRef(null);
  const navHeight = useElementDimension(navigationRef, 'height');

  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      scrollToTarget(window.location.hash.slice(1), 0, true);
    }
  }, []);

  const handleLinkClick = (e) => {
    e.preventDefault();
    const href = e.target.href;
    const id = href.slice(href.indexOf('#') + 1);
    if (id) scrollToTarget(id, Number(navHeight), true);
  };

  const Item = ({ item }: ItemProps) => (
    <li>
      <a
        className="link-react link-react__serif"
        href={item.href}
        onClick={handleLinkClick}
      >
        {item.text}{' '}
      </a>
      {item.children?.length > 0 && (
        <ul className="dokumentkapittel-react__content__toc__ul">
          {item.children.map((child, idx) => (
            <Item key={idx} item={child} />
          ))}
        </ul>
      )}
    </li>
  );

  const Toc = ({ toc }: TocProps) => (
    <ul className="dokumentkapittel-react__content__toc">
      {toc?.map((item, idx) => (
        <Item key={idx} item={item} />
      ))}
    </ul>
  );
  return (
    <PageWrapper>
      <div className="dokumentkapittel-react">
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
          noPaddingOnMobile
        >
          <div ref={navigationRef}>
            <DokumentNavigasjon {...navigationProps} isSticky />
          </div>
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          className="dokumentkapittel-react__content-row"
        >
          <Row
            horizontalPadding={HorizontalPadding.indent}
            className="dokumentkapittel-react__content"
          >
            <h1>{title}</h1>
            {toc?.length > 0 && <Toc toc={toc} />}
            <div className="page-publication">
              <div className="bigdoc-content">
                {mainBody && (
                  <ContentArea theme={RichTextTheme.BigDoc} {...mainBody} />
                )}
                {content && (
                  <ContentArea theme={RichTextTheme.BigDoc} {...content} />
                )}
              </div>
            </div>
          </Row>
          <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
        </Row>
      </div>
    </PageWrapper>
  );
};

export default DokumentKapittel;
