import { configureStore } from '@reduxjs/toolkit';
import SearchReducer from './SearchSlice';
import EuEosNewsReducer from '../EuEosNews/EuEosNewsSlice';
import LiveBlockReducer from '../LiveBlock/LiveBlockSlice';

export const SearchStore = configureStore({
  reducer: {
    Search: SearchReducer,
    EuEosNewsList: EuEosNewsReducer,
    LiveBlock: LiveBlockReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof SearchStore.getState>;

export type AddDispatch = typeof SearchStore.dispatch;
