import React, { useEffect, useRef, useState } from 'react';
import DictionaryClient from '/Shared/Code/Dictionary/DictionaryClient';
import './DictionaryLookup.scss';
import cn from 'classnames';

export interface Props {
  dictionaryEndpoint: string;
}

const DictionaryLookup = ({ dictionaryEndpoint }: Props) => {
  const visibleRef = useRef(false);
  const [header, setHeader] = useState('');
  const [description, setDescription] = useState('');
  const [visible, setVisible] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  visibleRef.current = visible;

  const mouseOver = ({ target, pageX, pageY }) => {
    if (target.tagName.toLowerCase() !== 'a') {
      if (visibleRef.current) setVisible(false);
      return;
    }
    const client = new DictionaryClient(dictionaryEndpoint);
    client.Lookup(target.href as string)?.then((res) => {
      if (!visibleRef.current) setVisible(true);
      const offset = window.innerWidth - pageX < 315 ? -315 : 15;
      let pageLeft = pageX + offset;
      if (pageLeft < 0) pageLeft = 0;
      if (pageLeft + 300 > window.innerWidth)
        pageLeft = window.innerWidth - 300;
      setLeft(pageLeft);
      setTop(pageY);
      if (header !== res.word) setHeader(res.word);
      if (description !== res.description) setDescription(res.description);
    });
  };

  useEffect(() => {
    if (typeof document === 'undefined') return;
    document.onmouseover = mouseOver;
  }, []);
  return (
    <div
      className={cn('dictionary-lookup', { 'active-react': visible })}
      style={{ top: top, left: left }}
    >
      <strong>{header}:</strong>
      <br />
      <span>{description}</span>
    </div>
  );
};

export default DictionaryLookup;
