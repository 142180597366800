import React from 'react';
import './StatusMessage.scss';
import cn from 'classnames';
export enum StatusMessageTheme {
  success,
  warning,
  info,
}
interface Props {
  theme: StatusMessageTheme;
  text?: string;
  children?: React.ReactNode;
}
const Link = ({ theme, text, children }: Props) =>
  text || children ? (
    <div
      className={cn(
        'status-message-react',
        { 'warning-react': theme === StatusMessageTheme.warning },
        { 'success-react': theme === StatusMessageTheme.success },
        { 'info-react': theme === StatusMessageTheme.info }
      )}
    >
      {text || children}
    </div>
  ) : (
    <></>
  );
export default Link;
