import React, { useState, useRef } from 'react';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import './VideoClipMail.scss';
import Button from '/Components/Atoms/Button/Button';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import { VideoClipFormSubmitProps } from '/./Models/Generated/VideoClipFormSubmitProps';
import { ContentArea, ValidationError } from '../../../App.Components';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { VideoClipRequestProps } from '../../../Models/Generated/VideoClipRequestProps';

interface VideoClipMailProps extends VideoClipFormSubmitProps {
  videoDuration: number;
  clipStart: number;
  clipEnd: number;

  validClipStart: boolean;
  validClipEnd: boolean;
}

const VideoClipMail = (props: VideoClipMailProps) => {
  const [checked, setChecked] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [gdprErrorMessage, setGdprErrorMessage] = useState('');
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState('');
  const [genericErrorMessage, setGenericErrorMessage] = useState('');

  const inputRef = useRef(null);
  const recaptchaToken = useRef('');

  const [submitted, setSubmitted] = useState(false);

  const IsEmailCorrect = (email: string): boolean => {
    return (
      email !== null &&
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
    );
  };

  const handleSubmit = () => {
    const emailAddress = inputRef.current.value;
    const emailIsPresent = emailAddress && emailAddress.length !== 0;
    const emailIsValid = IsEmailCorrect(emailAddress);

    const recaptchaIsValid =
      !props.recaptchaEnabled || recaptchaToken.current !== null;

    const clipDuration = props.clipEnd - props.clipStart;
    const durationIsValid =
      clipDuration >= 1 && clipDuration <= props.videoDuration;

    setGdprErrorMessage('');
    setEmailErrorMessage('');
    setCaptchaErrorMessage('');
    setGenericErrorMessage('');

    if (!checked) setGdprErrorMessage(props.gdprConsent);
    if (!emailIsPresent) setEmailErrorMessage(props.errorMessageMissingEmail);
    else if (!emailIsValid) setEmailErrorMessage(props.errorMessageWrongEmail);
    if (!recaptchaIsValid)
      setCaptchaErrorMessage(props.errorMessageCaptchaInvalid);
    if (!durationIsValid)
      setGenericErrorMessage(props.errorMessageInvalidDuration);

    if (
      !checked ||
      !emailIsPresent ||
      !emailIsValid ||
      !recaptchaIsValid ||
      !props.validClipStart ||
      !props.validClipEnd ||
      !durationIsValid
    ) {
      return;
    }

    if (emailIsValid && checked) {
      const data: VideoClipRequestProps = {
        recaptchaToken: recaptchaToken.current,
        email: inputRef.current.value,
        pageId: props.pageId,
        pageLanguage: props.pageLanguage,
        start: Math.floor(props.clipStart),
        end: Math.floor(props.clipEnd),
        consentChecked: checked,
      };

      if (submitted) {
        return;
      }

      setSubmitted(true);

      fetch(props.postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((result) => {
          if (result.ok && window && props.confirmationUrl) {
            window.location.href = props.confirmationUrl;
          } else {
            setGenericErrorMessage(props.genericErrorMessage);
          }
        })
        .catch(() => {
          setGenericErrorMessage(props.genericErrorMessage);
        })
        .finally(() => {
          setChecked(false);
          setSubmitted(false);
        });
    } else {
      setChecked(false);
    }
  };

  const handleKeyPress = (e) => {
    //submit on enter if user has accepted terms
    if (e.key === 'Enter' && checked) handleSubmit();
  };

  return (
    <ColumnContentWrapper title={props.header}>
      {props.recaptchaEnabled && (
        <GoogleReCaptcha
          onVerify={(token: string) => {
            recaptchaToken.current = token;
          }}
          action="form"
        />
      )}
      <div className="video-clip-subscription-react">
        <div className="video-cip-form-section">
          <p className="video-clip-text">
            <ContentArea {...props.text} />
          </p>
        </div>

        {props.gdprConsentLabel && (
          <div className="video-clip-form-section">
            <label className="video-clip-checkbox-react">
              <Checkbox setChecked={setChecked} checked={checked} />

              <p className="text-label">
                {props.gdprConsentLabel}
                <a href={props.gdprLink}> {props.gdprLinkText}</a>
              </p>
            </label>
            {gdprErrorMessage && (
              <ValidationError text={gdprErrorMessage}></ValidationError>
            )}
          </div>
        )}

        {props.recaptchaEnabled && (
          <div className="video-clip-form-section">
            <p
              className="recaptcha-text"
              dangerouslySetInnerHTML={{
                __html: props.recaptchaV3PrivacyAndTermsText,
              }}
            ></p>
            {captchaErrorMessage && (
              <ValidationError text={captchaErrorMessage}></ValidationError>
            )}
          </div>
        )}

        <div className="video-clip-form-section">
          <label>{props.emailLabel}</label>
          <label className="input-wrapper-react">
            <input
              className="video-clip-email-input"
              ref={(input) => (inputRef.current = input)}
              type="email"
              name={props.emailLabel}
              placeholder={props.emailPlaceholder}
              onKeyPress={handleKeyPress}
            />
            <Button
              disabled={submitted}
              text={props.buttonText}
              onClick={handleSubmit}
            />
          </label>

          {emailErrorMessage && (
            <ValidationError text={emailErrorMessage}></ValidationError>
          )}
        </div>

        {genericErrorMessage && (
          <ValidationError text={genericErrorMessage}></ValidationError>
        )}
        <br />
      </div>
    </ColumnContentWrapper>
  );
};
const VideoClipMailWrapper = (props: VideoClipMailProps) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={props.recaptchaV3SiteKey}
    language="no"
  >
    <VideoClipMail {...props} />
  </GoogleReCaptchaProvider>
);

export default VideoClipMailWrapper;
