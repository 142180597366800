import React from 'react';
import './ListesideAntattEOSrelevanterettsakterFilter.scss';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import Button from '/Components/Atoms/Button/Button';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import { EosRelatedActDropDownContainerProps } from '/Models/Generated/EosRelatedActDropDownContainerProps';
import SearchInput from '/Components/Atoms/SearchInput/SearchInput';

interface Props extends EosRelatedActDropDownContainerProps {
  filterId: string;
  selectedYear: string;
  setSelectedYear: (year: string) => void;
  selectedMonth: string;
  setSelectedMonth: (month: string) => void;
  komNumberText: string;
  setKomNumberText: (komNumberText: string) => void;
  submit: () => void;
  loading: boolean;
}

const ListesideAntattEOSrelevanterettsakterFilter = ({
  filterId,
  monthDropDown,
  childPageDropDown,
  heading,
  komNumberTextBox,
  submitButtonText,
  submit,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
  komNumberText,
  setKomNumberText,
  loading,
}: Props) => {
  return (
    <ExpandableFilterWrapper heading={heading} scrollId={filterId}>
      <div className="listeside-antatt-eos-relevante-rettsakter-filter-react">
        <div className="listeside-antatt-eos-relevante-rettsakter-filter-react__dropdowns">
          <div>
            <label htmlFor="yeardropdown">{childPageDropDown.labelText}</label>
            <Dropdown
              id="yeardropdown"
              name="yeardropdown"
              options={childPageDropDown.pageLink}
              selectedValue={selectedYear}
              setSelected={(val) => {
                setSelectedYear(val);
                submit();
              }}
            />
          </div>
          <div>
            <label htmlFor="monthdropdown">{monthDropDown.labelText}</label>
            <Dropdown
              id="monthdropdown"
              name="monthdropdown"
              options={monthDropDown.items}
              selectedValue={selectedMonth}
              setSelected={(val) => {
                setSelectedMonth(val);
                submit();
              }}
            />
          </div>
        </div>
        <div className="listeside-antatt-eos-relevante-rettsakter-filter-react__textbox-and-button">
          <div className="listeside-antatt-eos-relevante-rettsakter-filter-react__textbox-and-button__textbox">
            <label htmlFor="komnumberinput">{komNumberTextBox.labelText}</label>
            <SearchInput
              id="komnumberinput"
              query={komNumberText}
              setQuery={setKomNumberText}
              doSearch={submit}
              loading={loading}
              maxLength={komNumberTextBox.textBoxMaxLength}
            />
          </div>
          <div className="listeside-antatt-eos-relevante-rettsakter-filter-react__textbox-and-button__button">
            <Button text={submitButtonText} onClick={() => submit()} />
          </div>
        </div>
      </div>
    </ExpandableFilterWrapper>
  );
};

export default ListesideAntattEOSrelevanterettsakterFilter;
