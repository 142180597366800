import React, { useState, useEffect } from 'react';
import './SocialSharing.scss';
import { SocialSharingProps } from '/Models/Generated/SocialSharingProps';
import cn from 'classnames';

interface Props extends SocialSharingProps {
  tag?: string;
  useLargerLabel?: boolean;
  asLink?: boolean;
  staticLink?: boolean;
}

const SocialSharing = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ul, setUl] = useState(null as HTMLUListElement);
  const [sharedUrl, setSharedUrl] = useState(props.url);

  useEffect(() => {
    if (props.staticLink) return;
    let lastUrl = location.href;
    new MutationObserver(() => {
      const url = location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        setSharedUrl(url);
      }
    }).observe(document, { subtree: true, childList: true });
  }, []);

  const buildSharingUrl = (sharer: string, shared: string) =>
    sharer + (shared ? encodeURIComponent(shared) : '');

  const Tag = props.tag ?? 'li';

  if (props)
    return (
      <Tag className="social-sharing-react">
        <button
          className={cn('social-sharing-react__toggle', {
            'social-sharing-react--is-open': isOpen,
            'social-sharing-react__toggle--use-larger-label':
              props.useLargerLabel,
            'social-sharing-react__toggle--as-link': props.asLink,
          })}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-pressed={isOpen ? 'true' : 'false'}
        >
          {props.shareLabel}
        </button>
        <div
          className={cn('social-sharing-react__links-expander', {
            'social-sharing-react--is-open': isOpen,
          })}
          style={{
            height: isOpen ? ul.clientHeight : 0,
          }}
        >
          <ul
            className="social-sharing-react__links"
            ref={(ulElement) => {
              setUl(ulElement);
            }}
          >
            <li>
              <a
                className="social-sharing-react__link social-sharing-react__link--facebook"
                href={buildSharingUrl(props.facebook.href, sharedUrl)}
              >
                {props.facebook.text}
              </a>
            </li>
            <li>
              <a
                className="social-sharing-react__link social-sharing-react__link--twitter"
                href={buildSharingUrl(props.twitter.href, sharedUrl)}
              >
                {props.twitter.text}
              </a>
            </li>
            <li>
              <a
                className="social-sharing-react__link social-sharing-react__link--email"
                href={buildSharingUrl(props.email.href, sharedUrl)}
              >
                {props.email.text}
              </a>
            </li>
          </ul>
        </div>
      </Tag>
    );
  else return <></>;
};

export default SocialSharing;
