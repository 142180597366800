import React, { useState, useRef } from 'react';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import './NewsletterSubscription.scss';
import { NewsletterSubscriptionProps } from '/Models/Generated/NewsletterSubscriptionProps';
import Button from '/Components/Atoms/Button/Button';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { NewsletterSubscriptionApiProps } from '/Models/Generated/NewsletterSubscriptionApiProps';

const NewsletterSubscription = ({
  headerText,
  subscribeText,
  gdprConsent,
  errorMessageMissingEmail,
  errorMessageWrongEmail,
  subscribeButtonText,
  aboutSubscription,
  newsletterListId,
  checkBoxLabel,
  checkBoxLinkUrl,
  checkBoxLinkText,
  recaptchaEnabled,
  endpoint,
  language,
  newsletterType,
  recaptchaV3PrivacyAndTermsText,
}: NewsletterSubscriptionProps) => {
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaToken = useRef('');
  const inputRef = useRef(null);

  const IsEmailCorrect = (email: string): boolean => {
    return (
      email !== null &&
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
    );
  };

  const handleSubmit = () => {
    let errorMessage = '';
    const emailAddress = inputRef.current.value;

    const emailIsMissing = emailAddress.length === 0;
    const emailIsValid = IsEmailCorrect(emailAddress);

    if (!checked) errorMessage = gdprConsent;
    else if (emailIsMissing) errorMessage = errorMessageMissingEmail;
    else if (!emailIsValid) errorMessage = errorMessageWrongEmail;

    setErrorMessage(errorMessage);

    if (emailIsValid && checked)
      fetch(
        endpoint +
          'email=' +
          inputRef.current.value +
          '&accept=' +
          checked +
          '&captcha=' +
          recaptchaToken.current +
          '&language=' +
          language +
          '&newsletterListId=' +
          newsletterListId +
          '&newslettertype=' +
          newsletterType
      )
        .then((unparsed) => unparsed.json())
        .then((result: NewsletterSubscriptionApiProps) => {
          if (result.url) window.location.href = result.url;
          else {
            setErrorMessage(result.errorMessage);
          }
        })
        .catch(() => {
          setErrorMessage('Det har oppstått en feil');
        })
        .finally(() => setChecked(false));
    else {
      setChecked(false);
    }
  };

  const handleKeyPress = (e) => {
    // submit on enter if user has accepted terms
    if (e.key === 'Enter' && checked) handleSubmit();
  };

  return (
    <ColumnContentWrapper title={headerText}>
      {recaptchaEnabled && (
        <GoogleReCaptcha
          onVerify={(token: string) => {
            recaptchaToken.current = token;
          }}
          action="form"
        />
      )}
      <div className="newsletter-subscription-react">
        <p className="newsletter-text">{subscribeText}</p>
        <label className="newsletter-checkbox-react">
          <Checkbox setChecked={setChecked} checked={checked} />
          <p className="text-label">
            {checkBoxLabel}
            &nbsp;
            {checkBoxLinkUrl && (
              <Link
                href={checkBoxLinkUrl}
                text={checkBoxLinkText}
                theme={LinkTheme.standard}
              />
            )}
          </p>
          {recaptchaEnabled && (
            <p
              dangerouslySetInnerHTML={{
                __html: recaptchaV3PrivacyAndTermsText,
              }}
            ></p>
          )}
        </label>
        <label className="input-wrapper-react">
          <input
            ref={(input) => (inputRef.current = input)}
            type="email"
            onKeyPress={handleKeyPress}
          />
          <Button text={subscribeButtonText} onClick={handleSubmit} />
        </label>
        {errorMessage && <p className="error-message-react">{errorMessage}</p>}
        <br />
        {aboutSubscription && (
          <Link
            href={aboutSubscription.href}
            text={aboutSubscription.text}
            theme={LinkTheme.standard}
          />
        )}
        <p hidden>{newsletterListId}</p>
      </div>
    </ColumnContentWrapper>
  );
};

const NewsletterSubscriptionWrapper = (props: NewsletterSubscriptionProps) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={props.recaptchaV3SiteKey}
    language="no"
  >
    <NewsletterSubscription {...props} />
  </GoogleReCaptchaProvider>
);

export default NewsletterSubscriptionWrapper;
