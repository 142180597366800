import React from 'react';
import './EPOSSakFlowListContentTemplate.scss';
import cn from 'classnames';
import { EposSakDetailsItemProps } from '/Models/Generated/EposSakDetailsItemProps';

// import { propsType } from '/Models/Generated/propsType';

const FlowListContentTemplate = ({
  isHandled,
  notHandledText,
  notHandledInfo,
  workflowElements,
}: EposSakDetailsItemProps) => {
  return (
    <ul
      className={cn('epos-sak-flow-list-content-template-react', {
        'epos-sak-flow-list-content-template-react--not-handled':
          workflowElements.shouldHaveNotHandledMarginTopClass,
      })}
    >
      {!isHandled && (
        <li className="epos-sak-flow-list-content-template-react__item">
          <div className="epos-sak-flow-list-content-template-react__content">
            <p>
              <span className="epos-sak-flow-list-content-template-react__not-handled-status">
                {notHandledText}
              </span>
            </p>
            <p>
              <span className="epos-sak-flow-list-content-template-react__not-handled-text">
                {notHandledInfo}
              </span>
            </p>
          </div>
        </li>
      )}

      {workflowElements?.elements?.map((item, key) => (
        <li
          className="epos-sak-flow-list-content-template-react__item"
          key={key}
        >
          {item.headingMarkup && (
            <h4
              className="epos-sak-flow-list-content-template-react__title"
              dangerouslySetInnerHTML={{
                __html: item.headingMarkup,
              }}
            ></h4>
          )}
          {item.bodyMarkup && (
            <div
              className="epos-sak-flow-list-content-template-react__sub-content"
              dangerouslySetInnerHTML={{
                __html: item.bodyMarkup,
              }}
            ></div>
          )}
        </li>
      ))}
    </ul>
  );
};
export default FlowListContentTemplate;
