import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { EosRelatedActProps } from '/Models/Generated/EosRelatedActProps';
import SSQQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';

export default class AntattEOSRelevanteRettsakterClient {
  constructor(private endpoint: string, private pageId: number) {}

  public Get(
    /** A year, e.g. 2021. 0 means no filtering. */
    year: number,
    /** A month. 1 is January, 2 is Febuary and so on. 0 means no filtering. */
    month: number,
    /** A Kom number, e.g.  */
    komNumber: string
  ): Promise<EosRelatedActProps[]> {
    if (typeof window === 'undefined') return Promise.resolve([]);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(year, month, komNumber).then((res) =>
        UseProdUrls(res)
      );
    } else {
      return fetch(
        this.endpoint +
          `pageId=${this.pageId}&year=${year ?? ''}&month=${
            month ?? ''
          }&komnumber=${komNumber ?? ''}`,
        { headers: { Accept: 'application/json' } }
      ).then((res) => res.json());
    }
  }

  public static GetYearFromQuery(fallback: number): number {
    return SSQQueryStringReader.ReadInt('pid', fallback);
  }

  public static GetMonthFromQuery(): string {
    return SSQQueryStringReader.Read('m');
  }

  public static GetKomNumberFromQuery(): string {
    return SSQQueryStringReader.Read('mid');
  }

  public static GetSortByFromQuery(): string {
    return SSQQueryStringReader.Read('sb');
  }

  public static GetSortAscendingFromQuery(): boolean {
    return SSQQueryStringReader.Read('sd')?.toLowerCase() === 'ascending';
  }

  public static LookupSortBy(key: string): string {
    return (
      { date: 'startPublish', title: 'link', kom: 'komNumbers' }[
        key?.toLocaleLowerCase()
      ] ?? 'startPublish'
    );
  }

  public BuildUrl(
    /** A year, e.g. 2021. 0 means no filtering. */
    year: number,
    /** A month. 1 is January, 2 is Febuary and so on. 0 means no filtering. */
    month: number,
    /** A Kom number, e.g.  */
    komNumber: string,
    /** A string, e.g. startPublish, link, komNumbers */
    sortBy: string,
    /** A boolean, true is Ascending, false is Descending */
    sortAscending: boolean
  ): string {
    // backend values to url parameters
    const sortByValues = {
      startPublish: 'Date',
      link: 'Title',
      komNumbers: 'Kom',
    };
    const fragmentSplit = window.location.href.split('#');
    const fragment = fragmentSplit.length === 2 ? '#' + fragmentSplit[1] : '';
    const params = [
      year ? 'pid=' + year : null,
      month ? 'm=' + month : null,
      komNumber ? 'mid=' + komNumber : null,
      sortBy ? 'sb=' + sortByValues[sortBy] : null,
      sortBy ? `sd=${sortAscending ? 'Ascending' : 'Descending'}` : null,
    ].filter((p) => p !== null);
    const query = params.length > 0 ? '?' + params.join('&') : '';
    return `${window.location.href.split('?')[0]}${query}${fragment}`;
  }

  private getMockData(
    year: number,
    month: number,
    komNumber: string
  ): Promise<EosRelatedActProps[]> {
    return fetch('/MockupAssets/MockData/AntattEOSRelevanteRettsakter.json')
      .then((res) => res.json())
      .then((result: EosRelatedActProps[]) => {
        if (year) result = result.filter((ip) => ip.year === year + '');
        if (month) result = result.filter((ip) => ip.month === month + '');
        if (komNumber)
          result = result.filter(
            (ip) =>
              ip.komNumbers.filter((kn) => kn.text.indexOf(komNumber) >= 0)
                .length > 0
          );
        return result;
      });
  }
}
