import React from 'react';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import './RelatedLinksBox.scss';
import { RelatedLinksBoxProps } from '/Models/Generated/RelatedLinksBoxProps';
import cn from 'classnames';

interface Props extends RelatedLinksBoxProps {
  className?: string;
}

const RelatedLinksBox = ({ title, links, button, className }: Props) => {
  return (
    <ColumnContentWrapper title={title}>
      {links?.length > 0 &&
        links.map((val, idx) => (
          <div className={cn('related-links-box-react', className)} key={idx}>
            <Link href={val.href} text={val.text} theme={LinkTheme.standard} />
          </div>
        ))}
      {button && (
        <div className="related-links-box-button-link-react">
          <Link
            href={button.href}
            text={button.text}
            theme={LinkTheme.blocklink}
          />
        </div>
      )}
    </ColumnContentWrapper>
  );
};

export default RelatedLinksBox;
