import React, { useState } from 'react';
import './CountyMapBlock.scss';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import { CountyMapBlockProps } from '/Models/Generated/CountyMapBlockProps';
import { SectionWrapper } from '/App.Components';
import { CountyLinkProps } from '/Models/Generated/CountyLinkProps';
import mapData from './countyMapData';

const CountyMapBlock = ({
  title,
  titlePropertyName,
  countiesLeft,
  countiesRight,
  isInRichTextArea,
}: CountyMapBlockProps) => {
  const [activeCounty, setActiveCounty] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({
    top: '1px',
    left: '1px',
  });

  const handleMouseEnter = (id, isOverMap = false) => {
    setActiveCounty(mapData.find((el) => el.id === id) || null);
    if (isOverMap) setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setActiveCounty(null);
    setShowPopup(false);
  };

  const updatePopupPosition = (e) => {
    const newPosition = {
      top: e.clientY - 50 + 'px',
      left: e.clientX + 'px',
    };
    setPopupPosition(newPosition);
  };

  const repsDisplayText = () => {
    if (!activeCounty) '';

    const countyItem =
      countiesLeft.find((el) => el.county === activeCounty.id) ||
      countiesRight.find((el) => el.county === activeCounty.id);

    return `${countyItem.numRepresentatives} ${countyItem.representativesText}`;
  };

  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <div className="county-map-block-react">
        <SectionWrapper title={title} titlePropertyName={titlePropertyName}>
          {activeCounty && (
            <div className="county-map-block-react__small-screen-info-box">
              <p>{activeCounty?.name || ''}</p>
              <p>{repsDisplayText()}</p>
            </div>
          )}
          <div className="county-map-block-react__container">
            <div className="county-map-block-react__container__map-container">
              {showPopup && activeCounty && (
                <div
                  className="county-map-block-react__container__map-container__popup"
                  style={popupPosition}
                >
                  {activeCounty?.name || ''}
                </div>
              )}
              <div className="county-map-block-react__container__map-container__map">
                {activeCounty && (
                  <div className="county-map-block-react__container__map-container__map__info-text">
                    <h3>{activeCounty?.name || ''}</h3>
                    <span>{repsDisplayText()}</span>
                  </div>
                )}
                <svg
                  id="county_map"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 304.7 426.4"
                >
                  <desc>Map of Norway - Norgeskart</desc>
                  <g transform="translate(10,10) scale(1 1)">
                    {mapData.map((county, index) => (
                      <path
                        {...county}
                        key={index}
                        className={
                          county.id === activeCounty?.id ? 'active' : ''
                        }
                        onMouseEnter={() => handleMouseEnter(county.id, true)}
                        onMouseLeave={handleMouseLeave}
                        onMouseMove={updatePopupPosition}
                      />
                    ))}
                  </g>
                </svg>
              </div>
            </div>
            <div className="county-map-block-react__container__counties-list-container">
              <div>
                {countiesLeft && (
                  <ul>
                    {countiesLeft.map((val: CountyLinkProps, idx) => (
                      <li key={idx}>
                        <a
                          href={val.href}
                          data-county={val.county}
                          onMouseEnter={() => handleMouseEnter(val.county)}
                          onFocus={() => handleMouseEnter(val.county)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {val.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div>
                {countiesRight && (
                  <ul>
                    {countiesRight.map((val: CountyLinkProps, idx) => (
                      <li key={idx}>
                        <a
                          href={val.href}
                          data-county={val.county}
                          onMouseEnter={() => handleMouseEnter(val.county)}
                          onFocus={() => handleMouseEnter(val.county)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {val.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </SectionWrapper>
      </div>
    </BlockWrapper>
  );
};

export default CountyMapBlock;
