import React from 'react';
import './Hovedtema.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import { HovedtemaProps } from '/Models/Generated/HovedtemaProps';
import FocusItem2 from '/Components/Organisms/FocusItem2/FocusItem2';
import QuestionListItem from './QuestionListItem';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';

const Hovedtema = ({
  mainIntro,
  mainBody,
  mainImageOld,
  threeColumnContentAreaInTop,
  threeColumnContentAreaBottom,
  topicsPqList,
}: HovedtemaProps) => {
  return (
    <PageWrapper>
      <div className="hovedtema-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainIntro && (
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          )}
        </Row>
        {HasContent(threeColumnContentAreaInTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaInTop} />
          </Row>
        )}
        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaBottom} />
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainImageOld && (
            <ImageWithCaption {...mainImageOld} maxWidthTablet={true} />
          )}
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <GreyBar text={topicsPqList.header} />
          {topicsPqList?.items && (
            <ul>
              {topicsPqList.items.map((li, idx) => (
                <li key={idx}>
                  {li.proceeding && <FocusItem2 {...li.proceeding} />}
                  {li.question && <QuestionListItem {...li.question} />}
                </li>
              ))}
            </ul>
          )}
        </Row>
      </div>
    </PageWrapper>
  );
};

export default Hovedtema;
