const KeyCodes = {
  Up: 'ArrowUp',
  Down: 'ArrowDown',
  Enter: 'Enter',
  NumpadEnter: 'NumpadEnter',
  Escape: 'Escape',
  Space: 'Space',
  Tab: 'Tab',
};

export default KeyCodes;
