import React from 'react';
import GreyRow, {
  GreyRowVerticalMarginBottom,
  GreyRowWidth,
} from '/Components/Layout/GreyRow/GreyRow';
import './GreyBar.scss';

interface Props {
  width?: GreyRowWidth;
  text: string;
  fullWidthText?: boolean;
  verticalMarginBottom?: GreyRowVerticalMarginBottom;
}

const GreyBar = ({
  text,
  width,
  fullWidthText,
  verticalMarginBottom,
}: Props) => (
  <GreyRow
    verticalMarginBottom={verticalMarginBottom}
    width={width ?? GreyRowWidth.indentedWide}
    fullWidthText={fullWidthText}
  >
    <h2 className="grey-bar__header">{text}</h2>
  </GreyRow>
);

export default GreyBar;
