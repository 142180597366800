import * as React from 'react';
import { QbrickVideoProps } from '/Models/Generated/QbrickVideoProps';

interface VideoClipEmbeddedProps extends QbrickVideoProps {
  setVideoDuration;
  playerPosition: number;
  setPlayerPosition;
  setVisualPlayerPosition;
  paused: boolean;
  setPaused;
}

class VideoClipEmbedded extends React.Component<VideoClipEmbeddedProps> {
  GoBrainPlayer = null;
  IsFirstTimePlay = true;

  UNSAFE_componentWillMount() {
    //render only on client
    if (typeof window === 'undefined') return;

    const script = document.createElement('script');
    script.src =
      'https://play2.qbrick.com/qbrick-player/framework/GoBrain.min.js';
    document.getElementsByTagName('head')[0].appendChild(script);

    script.onload = () => {
      const GoBrain = window['GoBrain'];

      const embedSettings = {
        config:
          '//video.qbrick.com/play2/api/v1/accounts/AccrjW9C7ikYk2xPM5xJ4Frag/configurations/qbrick-player',
        data:
          '//video.qbrick.com/api/v1/public/accounts/AccrjW9C7ikYk2xPM5xJ4Frag/medias/' +
          this.props.qbrickVideoId,
        widgetId: 'qbrickPlayerDiv',
        autoplay: false,
        volume: 1,
        timeline: false,
        language: this.props.qbrickLanguage,
      };

      this.GoBrainPlayer = GoBrain.initialize(embedSettings);

      const beginVideo = () => {
        if (this.IsFirstTimePlay) {
          setTimeout(beginVideo, 250);
          try {
            this.GoBrainPlayer.play();
          } catch (e) {}
        }
      };

      this.GoBrainPlayer.on('initialized', () => {
        beginVideo();
      });

      this.GoBrainPlayer.on('play', () => {
        if (this.IsFirstTimePlay) {
          this.GoBrainPlayer.pause();
          this.GoBrainPlayer.position(this.props.startPosition);
          this.props.setPlayerPosition(this.props.startPosition);
          this.props.setVisualPlayerPosition(this.props.startPosition);

          this.IsFirstTimePlay = false;
        }
        this.props.setPaused(false);
      });

      this.GoBrainPlayer.on('duration', (duration) => {
        this.props.setVideoDuration(duration);
      });

      this.GoBrainPlayer.on('position', (newPosition) => {
        if (!this.props.paused) {
          this.props.setVisualPlayerPosition(newPosition);
        }
      });
    };
  }

  componentDidUpdate(prevProps) {
    //dont position before player has loaded video, this is done when user first press play
    if (this.IsFirstTimePlay) {
      return;
    }

    if (prevProps.playerPosition !== this.props.playerPosition) {
      this.GoBrainPlayer.position(this.props.playerPosition);
      this.props.setVisualPlayerPosition(this.props.playerPosition);
    }

    if (prevProps.paused !== this.props.paused && this.props.paused) {
      this.GoBrainPlayer.pause();
    }
  }

  render() {
    return (
      <div id="qbrickPlayerWrapper">
        <div id="qbrickPlayerDiv"></div>
      </div>
    );
  }
}
export default VideoClipEmbedded;
