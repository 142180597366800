import React, { useEffect } from 'react';
import './QbrickPlayer.scss';
import { LinkProps } from '/Models/Generated/LinkProps';
import { QbrickPlayerProps } from '/Models/Generated/QbrickPlayerProps';

interface Props extends QbrickPlayerProps {
  setPosition: (val: string) => void;
  setPositionSec: (val: string) => void;
  setDownloadDescription: (val: string) => void;
  setDownloadLink: (val: LinkProps) => void;
  setVideoClipLink: (val: LinkProps) => void;
  setShareUrl: (val: string) => void;
}

const FocusItem = ({
  downloadDescription,
  downloadLink,
  shareUrl,
  qbrickAccountId,
  qbrickLanguage,
  qbrickVideoId,
  videoClipLink,
  startPosition,
  setPosition,
  setPositionSec,
  setVideoClipLink,
  setShareUrl,
  setDownloadDescription,
  setDownloadLink,
}: Props) => {
  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window['divQbrickPlayer'] === 'undefined' ||
      typeof window['GoBrain'] === 'undefined'
    ) {
      return;
    }

    setShareUrl(shareUrl);
    setDownloadLink(downloadLink);
    setDownloadDescription(downloadDescription);
    setVideoClipLink(videoClipLink);

    const embedSettings = {
      config:
        '//video.qbrick.com/play2/api/v1/accounts/' +
        qbrickAccountId +
        '/configurations/qbrick-player',
      data:
        '//video.qbrick.com/api/v1/public/accounts/' +
        qbrickAccountId +
        '/medias/' +
        qbrickVideoId,
      widgetId: 'divQbrickPlayer',
      autoplay: false,
      volume: 1,
      language: qbrickLanguage,
    };
    const GoBrain = window['GoBrain'];

    GoBrain.initialize(embedSettings);

    const updateTime = (timeInSeconds: number) => {
      const hours = parseInt(timeInSeconds / 3600 + '') % 24;
      const minutes = parseInt(timeInSeconds / 60 + '') % 60;
      let seconds = timeInSeconds % 60;
      seconds = Math.round(seconds);
      const result =
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds);
      setPosition(result);
      setPositionSec(Math.round(timeInSeconds) + '');
    };

    GoBrain.widgets('divQbrickPlayer').on('initialized', function () {
      if (startPosition > 0) {
        //Set position - when player is initialized, and has been started
        let isFirstTimePlay = true;
        this.on('play', function () {
          if (isFirstTimePlay) {
            this.position(startPosition);
            //Don't set position after first started
            isFirstTimePlay = false;
          }
        });
      }

      updateTime(Math.max(0, startPosition));
    });

    GoBrain.widgets('divQbrickPlayer').on('position', updateTime);
  }, []);

  return (
    <div className="qbrick-player-react">
      <div className="qbrick-player-react__player" id="divQbrickPlayer"></div>
    </div>
  );
};

export default FocusItem;
