import React, { useState, useEffect, useRef } from 'react';
import './RepresentativeSearchBlockType.scss';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import SearchInput from '/Components/Atoms/SearchInput/SearchInput';
import CircleImage, {
  CircleImageSizes,
} from '/Components/Atoms/CircleImage/CircleImage';
import Button, { ButtonTheme } from '/Components/Atoms/Button/Button';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import { RepresentativeSearchBlockTypeProps } from '/Models/Generated/RepresentativeSearchBlockTypeProps';
import representativesSearch from '/Shared/Code/RepresentativesSearch/RepresentativesSearch';
import useDebounce from '/Shared/Code/Hooks/UseDebounce';
import { emphasize } from '/Shared/Code/Helpers/Emphasize';

const RepresentativeSearchBlockType = ({
  isInRichTextArea,
  placementLink,
  placementPropertyName,
  representativeLinkBaseUrl,
  labelNext,
  labelPrevious,
  labelShowHistorical,
  searchBoxPlaceholderText,
  labelCountSuffix,
  labelCountSuffixSingular,
  noResults,
}: RepresentativeSearchBlockTypeProps) => {
  const [statusText, setStatusText] = useState('');
  const resultTotalRef = useRef(1);
  const [searchFrom, setSearchFrom] = useState(1);
  const [nextEnabled, setNextEnabled] = useState(true);
  const [previousEnabled, setPreviousEnabled] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  // search parameters
  const [searchText, setSearchText] = useState('');
  const [showHistorical, setShowHistorical] = useState(false);

  const [reps, setReps] = useState([]);
  const repsCount = useRef(0);
  repsCount.current = reps.length;
  const scrollContainer = useRef(null);
  const scrollContainerBottom = useRef(null);
  let observer;

  useEffect(() => {
    if (searchFrom <= resultTotalRef.current || searchFrom === 1)
      representativesSearch({
        index: searchFrom,
        resultLimit: 5,
        showHistorical: showHistorical,
        query: searchText,
      }).then((data) => {
        if (searchFrom === 1) {
          setReps(data.resultList);
        } else {
          setReps([...reps, ...data.resultList]);
        }
        resultTotalRef.current = data.numberOfHits;
        setStatusMessage(data?.numberOfHits ? '' : noResults);
        if (!data?.numberOfHits) {
          setStatusText('');
          setPreviousEnabled(false);
          setNextEnabled(false);
        } else if (data.numberOfHits === 1) {
          setStatusText(`${data.numberOfHits} ${labelCountSuffixSingular}`);
        } else {
          setStatusText(`${data.numberOfHits} ${labelCountSuffix}`);
        }
      });
  }, [searchFrom, searchText, showHistorical]);

  const getItemScrollHeight = (idx: number) => {
    const items = scrollContainer.current.childNodes;
    let itemScrollHeight = 0;
    if (items.length < 2) return;
    const gap = items[1].offsetTop - items[0].scrollHeight;
    for (let i = 0; i <= idx; ++i)
      itemScrollHeight += items[i].scrollHeight + gap;
    return itemScrollHeight;
  };

  const findClosestScrollItem = () => {
    const items = scrollContainer.current.childNodes;
    const scrollPos = scrollContainer.current.scrollTop;
    let previousScrollDiff = scrollPos;
    for (let i = 0; i < items.length; ++i) {
      const nextScrollHeight = getItemScrollHeight(i);
      const nextScrollDiff = Math.abs(nextScrollHeight - scrollPos);
      if (nextScrollHeight > scrollPos)
        return nextScrollDiff <= previousScrollDiff ? i : i - 1;
      previousScrollDiff = nextScrollDiff;
    }
    return items.length - 1;
  };

  const scrollToItem = (idx: number) =>
    scrollContainer.current.scrollTo(0, getItemScrollHeight(idx));

  const updateButtons = (idx: number) => {
    const length = scrollContainer.current.childNodes.length;
    if (length < 2) {
      setPreviousEnabled(false);
      setNextEnabled(false);
      return;
    }
    const isMobile =
      scrollContainer.current.clientHeight / 2 >=
      scrollContainer.current.childNodes[0].clientHeight;
    const lastScrollTo = isMobile ? length - 4 : length - 3;
    setPreviousEnabled(idx >= 0);
    setNextEnabled(idx < lastScrollTo);
  };

  const scrollUpDown = (down = true) => {
    const closestItem = findClosestScrollItem();
    const selectedItem = down ? closestItem + 1 : closestItem - 1;
    scrollToItem(selectedItem);
    updateButtons(selectedItem);
  };

  const debouncedScrollSnap = useDebounce(() => {
    const closestItem = findClosestScrollItem();
    scrollToItem(closestItem);
    updateButtons(closestItem);
  }, 300);

  useEffect(() => updateButtons(findClosestScrollItem()), [reps]);

  useEffect(() => {
    observer = new IntersectionObserver(
      (entries) => {
        const bottomElement = entries[0];
        // only on enter, not on leave
        if (bottomElement.isIntersecting) setSearchFrom(repsCount.current + 1);
      },
      {
        root: scrollContainer.current,
        rootMargin: '0px 0px 50px 0px',
        threshold: 1.0,
      }
    );
    observer.observe(scrollContainerBottom.current);
    scrollContainer.current.addEventListener('scroll', debouncedScrollSnap);
    window.addEventListener('resize', () =>
      updateButtons(findClosestScrollItem())
    );
  }, []);

  // cleanup on unmount
  useEffect(() => () => observer.disconnect(), []);

  const handleShowHistoricalChange = () => {
    setShowHistorical((oldValue) => !oldValue);
    setSearchFrom(1);
  };

  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <div className="representative-search-block-type-react">
        <div className="representative-search-block-type-react__reps-container">
          <div className="representative-search-block-type-react__reps-container__search">
            <SearchInput
              doSearch={() => {
                //eslint-disable @typescript-eslint/no-empty-function
              }}
              placeholder={searchBoxPlaceholderText}
              setQuery={(text) => {
                setSearchText(text);
                setSearchFrom(1);
              }}
              query={searchText}
              loading={false}
              enableClear={true}
            />
          </div>
          <div className="representative-search-block-type-react__controls-container">
            <span className="representative-search-block-type-react__status-text">
              {statusText}
            </span>
            <div>
              <Checkbox
                text={labelShowHistorical}
                checked={showHistorical}
                setChecked={handleShowHistoricalChange}
              />
            </div>
          </div>
          <div
            className="representative-search-block-type-react__reps-list-container"
            ref={scrollContainer}
          >
            {reps?.length > 0 &&
              reps.map((rep) => (
                <article
                  key={rep.id}
                  className="representative-search-block-type-react__reps-list-container__item"
                >
                  <CircleImage
                    size={CircleImageSizes.medium}
                    src={rep.image}
                    alt={rep}
                  />
                  <div className="representative-search-block-type-react__reps-list-container__item-details">
                    <div className="representative-search-block-type-react__reps-list-container__item-details-link">
                      <a href={`${representativeLinkBaseUrl}${rep.id}`}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: emphasize(rep.name, searchText),
                          }}
                        />
                      </a>
                    </div>
                    <div className="representative-search-block-type-react__reps-list-container__item-details-text">
                      <strong>{rep.party}</strong>
                      <div>{rep.parliamentPeriods}</div>
                      <div>{rep.committeeMembership}</div>
                    </div>
                  </div>
                </article>
              ))}
            <div ref={scrollContainerBottom}>{statusMessage}</div>
          </div>
          <div className="representative-search-block-type-react__buttons-container">
            <Button
              onClick={() => scrollUpDown(false)}
              text={labelPrevious}
              disabled={!previousEnabled}
              fullWidth={true}
              theme={ButtonTheme.Red}
            />
            <Button
              onClick={() => scrollUpDown(true)}
              text={labelNext}
              disabled={!nextEnabled}
              fullWidth={true}
              theme={ButtonTheme.Red}
            />
          </div>
        </div>
        <div className="representative-search-block-type-react__placement-container">
          {placementLink && (
            <a href={placementLink.href}>
              <div className="representative-search-block-type-react__placement-container__content">
                <img
                  src={require('/Assets/Images/representatives-placement.svg')}
                  alt={''}
                />
                <div className="representative-search-block-type-react__placement-container__title">
                  <span data-epi-edit={placementPropertyName}>
                    {placementLink.text}
                  </span>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default RepresentativeSearchBlockType;
