import React from 'react';
import './EPOSSakslisteResultsList.scss';

import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import FocusItem3 from '/Components/Organisms/FocusItem3/FocusItem3';

import { EposSakslisteAsyncDataProps } from '/Models/Generated/EposSakslisteAsyncDataProps';
import Pagination from '/Components/Atoms/Pagination/Pagination';
import Spinner from '/Components/Atoms/Spinner/Spinner';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';

interface EPOSSakslisteResultsListProps {
  asyncState: EposSakslisteAsyncDataProps;
  isLoading: boolean;
  id?: string;
}

const buildLinkFunction = (page: number) => {
  if (typeof window === 'undefined') return '';

  const url = window.location.href.split('?')[0].split('#')[0];
  const usp = new URLSearchParams(new URL(window.location.href).search);
  usp.set('page', page + '');
  return url + '?' + usp.toString() + '#list';
};

const EPOSSakslisteResultsList = ({
  asyncState,
  isLoading,
  id,
}: EPOSSakslisteResultsListProps) => {
  const isMounted = useComponentDidMount();

  return (
    <div className="epos-saksliste-results-list-react" id={id}>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {isLoading && <Spinner />}
        {!isLoading && asyncState.resultList?.pagerInfoText && (
          <p className="epos-saksliste-results-list-react__hits-text">
            {asyncState.resultList.pagerInfoText}
          </p>
        )}
        {!isLoading &&
          asyncState.resultList.groups &&
          asyncState.resultList.groups.map((group, groupId) => (
            // Group
            <div key={groupId}>
              <GreyBar text={group.title} />
              <ul className="epos-saksliste-results-list-react__list">
                {group.items &&
                  group.items.map((item, itemId) => (
                    // Item
                    <li key={itemId}>
                      <FocusItem3 {...item} />
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        {!isLoading && asyncState.resultList?.noResultsText && (
          <div
            className="epos-saksliste-results-list-react__no-results"
            dangerouslySetInnerHTML={{
              __html: asyncState.resultList.noResultsText,
            }}
          ></div>
        )}
        {!isLoading &&
          asyncState.resultList.pagination &&
          asyncState.resultList.pagination?.totalPages > 1 && (
            <div className="epos-saksliste-results-list-react__pagination-container">
              {isMounted && (
                <Pagination
                  {...asyncState.resultList.pagination}
                  buildLinkFunction={buildLinkFunction}
                />
              )}
            </div>
          )}
      </Row>
    </div>
  );
};
export default EPOSSakslisteResultsList;
