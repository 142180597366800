import React, { useEffect } from 'react';
import { LiveMeetingInformationBlockTypeProps } from '/Models/Generated/LiveMeetingInformationBlockTypeProps';
import './LiveMeetingInformationBlockType.scss';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import {
  setCmsData,
  setErrorNoData,
  setLiveData,
  setShouldShowSlider,
  setShouldShowTop,
} from '/Shared/Code/LiveBlock/LiveBlockSlice';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { StortingetLiveOverviewProps } from '/Models/Generated/StortingetLiveOverviewProps';
import mixins from './mixins';
import LiveMeetingInformationBlockTypeTop from './LiveMeetingInformationBlockTypeTop';
import LiveMeetingInformationBlockTypeSlider from './LiveMeetingInformationBlockTypeSlider';

const LiveMeetingInformationBlockType = (
  props: LiveMeetingInformationBlockTypeProps
) => {
  const dispatch = useDispatch();
  const { shouldShowSlider, shouldShowTop } = useSelector(
    (s: RootState) => s.LiveBlock
  );

  useEffect(() => {
    dispatch(setCmsData(props));
    fetch(props.serviceUrl)
      .then((res) => {
        if (!res.ok) throw 'Error';
        return res.json() as unknown as StortingetLiveOverviewProps;
      })
      .then((liveData) => {
        dispatch(setLiveData(liveData));
        dispatch(
          setShouldShowSlider(mixins().shouldShowSlider(props, liveData))
        );
      })
      .catch(() => {
        dispatch(setErrorNoData(true));
        dispatch(setShouldShowSlider(true));
      })
      .finally(() => {
        dispatch(setShouldShowTop(true));
      });
  }, []);

  return (
    <BlockWrapper isInRichTextArea={props.isInRichTextArea}>
      <div
        id="live-meeting-information-block-type-react"
        className="live-meeting-information-block-type-react"
      >
        {shouldShowTop && <LiveMeetingInformationBlockTypeTop />}
        {shouldShowSlider && <LiveMeetingInformationBlockTypeSlider />}
      </div>
    </BlockWrapper>
  );
};

const LiveMeetingInformationBlockTypeWrapper = (
  props: LiveMeetingInformationBlockTypeProps
) =>
  typeof window === 'undefined' ? (
    <></>
  ) : (
    <Provider store={SearchStore}>
      <LiveMeetingInformationBlockType {...props} />
    </Provider>
  );

export default LiveMeetingInformationBlockTypeWrapper;
