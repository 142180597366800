import React, { useEffect, useState } from 'react';
import './HoringProgram.scss';
import { HoringProgramProps } from '/Models/Generated/HoringProgramProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import Link from '/Components/Atoms/Link/Link';
import LinkWithIcon, {
  Icon,
} from '/Components/Atoms/LinkWithIcon/LinkWithIcon';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Masonry from 'react-responsive-masonry';

const HoringProgram = ({
  hearingsProgramFor,
  hearingsProgramTitle,
  hearingProgramMainIntro,
  hearingProgramMainBody,
  hearingProgramDocumentReference,
  hearingProgramSpeakerItem,
  hearingProgramRoomNote,
  hearingProgramMainFooter,
  hearingProgramVideoItem,
  proceedingTitle,
  proceedingLinksList,
  hearingTitle,
  hearingLink,
  hearingProgramListForOtherDays,
}: HoringProgramProps) => {
  const [masonryCols, setMasonryCols] = useState(3);
  const UpdateMasonryCols = () => {
    if (window.matchMedia('print').matches) setMasonryCols(1);
    else if (window.innerWidth < 641) setMasonryCols(1);
    else if (window.innerWidth < 1024) setMasonryCols(2);
    else setMasonryCols(3);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', UpdateMasonryCols);
      UpdateMasonryCols();
    }
  }, []);
  return (
    <PageWrapper>
      <div className="horing-program-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <div className="horing-program-react__pre">{hearingsProgramFor}</div>
          <h1 className="horing-program-react__title">
            {hearingsProgramTitle}
          </h1>
          <div className="horing-program-react__intro">
            {hearingProgramMainIntro}
          </div>
          <div className="horing-program-react__main">
            {hearingProgramMainBody}
          </div>
          <div className="horing-program-react__ref">
            {hearingProgramDocumentReference}
          </div>
          <div className="horing-program-react__date">
            {hearingProgramSpeakerItem.hearingProgramSpeakerDate}
          </div>
          {hearingProgramSpeakerItem.hearingProgramSpeakerUnderTitle && (
            <div className="horing-program-react__under-title">
              {hearingProgramSpeakerItem.hearingProgramSpeakerUnderTitle}
            </div>
          )}

          <ul className="horing-program-react__list">
            {hearingProgramSpeakerItem.hearingProgramSpeakerList.map(
              (items, idx) => (
                <li key={idx} className="horing-program-react__list__item">
                  <div
                    className="horing-program-react__list__item__time"
                    dangerouslySetInnerHTML={{
                      __html: items.timeText,
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{
                      __html: items.formattedSpeachersText,
                    }}
                  ></div>
                </li>
              )
            )}
          </ul>
          {hearingProgramMainFooter && (
            <div className="horing-program-react__footer">
              <p>{hearingProgramRoomNote}</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: hearingProgramMainFooter,
                }}
              />
            </div>
          )}
        </Row>
        <Row
          lineAbove
          lineBelow
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={VerticalPadding.normal}
        >
          <Masonry columnsCount={masonryCols} gutter="40px">
            <div className="editable-text-block-type-react horing-program-react-block">
              <h3 className="horing-program-react-block__title">
                {hearingTitle}
              </h3>
              <Link
                className="horing-program-react-block__link-items"
                {...hearingLink}
              />
              {hearingProgramListForOtherDays.map((items, idx) => (
                <div
                  className="horing-program-react-block__link-items"
                  key={idx}
                  dangerouslySetInnerHTML={{
                    __html: items,
                  }}
                />
              ))}
            </div>
            {hearingProgramVideoItem && (
              <div className="editable-text-block-type-react horing-program-react-block">
                <h3 className="horing-program-react-block__title">
                  {hearingProgramVideoItem.videoHeader}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: hearingProgramVideoItem.videoIntro,
                  }}
                />
                {hearingProgramVideoItem.videoLinks.map((items, idx) => (
                  <li
                    className="horing-program-react-block__link-items__video"
                    key={idx}
                  >
                    <LinkWithIcon {...items} icon={Icon.Video} />
                  </li>
                ))}
              </div>
            )}
            {proceedingTitle && (
              <div className="editable-text-block-type-react horing-program-react-block">
                <h3 className="horing-program-react-block__title">
                  {proceedingTitle}
                </h3>
                {proceedingLinksList.map((items, idx) => (
                  <li
                    className="horing-program-react-block__link-items"
                    key={idx}
                  >
                    <Link {...items} />
                  </li>
                ))}
              </div>
            )}
          </Masonry>
        </Row>
      </div>
    </PageWrapper>
  );
};

export default HoringProgram;
