import React from 'react';
import './SearchPageClearFilters.scss';
import { SearchFilterProps } from '/Models/Generated/SearchFilterProps';
import { Row } from '/App.Components';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { clearFilter, setFilter } from '/Shared/Code/Search/SearchSlice';
import IconButton, { Icon } from '/Components/Atoms/IconButton/IconButton';
import SearchClient from '/Shared/Code/Search/SearchClient';

const SearchPageClearFilters = (props: SearchFilterProps) => {
  const categoryId = useSelector((state: RootState) => state.Search.categoryId);
  const filter = useSelector((state: RootState) => state.Search.filter);
  const dispatch = useDispatch();
  return (
    <Row className="search-page-clear-filters-react">
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          if (categoryId !== null) {
            dispatch(setFilter(SearchClient.RemoveGeneralFilters(filter)));
          } else {
            dispatch(clearFilter());
          }
        }}
        text={props.clear}
        icon={Icon.Close}
      />
    </Row>
  );
};

const SearchPageClearFiltersWrapper = (props: SearchFilterProps) => (
  <Provider store={SearchStore}>
    <SearchPageClearFilters {...props} />
  </Provider>
);

export default SearchPageClearFiltersWrapper;
