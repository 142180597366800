import React from 'react';
import './EPOSSakslisteForRegjeringen.scss';
import { EPOSSakslisteForRegjeringenProps } from '/Models/Generated/EPOSSakslisteForRegjeringenProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const EPOSSakslisteForRegjeringen = (
  props: EPOSSakslisteForRegjeringenProps
) => (
  <div className="epos-saksliste-for-regjeringen">
    <Row horizontalPadding={HorizontalPadding.indent}>
      <div
        className="epos-saksliste-for-regjeringen-intro"
        dangerouslySetInnerHTML={{ __html: props.mainIntro }}
      ></div>
      {props.links && (
        <ul className="epos-saksliste-for-regjeringen-links">
          {props.links.map((link, idx) => (
            <li key={idx}>
              <Link {...link} theme={LinkTheme.standard} />
            </li>
          ))}
        </ul>
      )}
    </Row>
  </div>
);

export default EPOSSakslisteForRegjeringen;
