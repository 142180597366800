import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './ConfirmDoubleOptInPageIssueNotifications.scss';
import { ConfirmDoubleOptInPageIssueNotificationsProps } from '/Models/Generated/ConfirmDoubleOptInPageIssueNotificationsProps';
import Button from '/Components/Atoms/Button/Button';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const ConfirmDoubleOptInPageIssueNotifications = ({
  text,
  buttonLabel,
  buttonHref,
}: ConfirmDoubleOptInPageIssueNotificationsProps) => {
  return (
    <Row horizontalPadding={HorizontalPadding.normal}>
      <div className="confirm-double-optin-page-issue-notifications-react">
        {text && (
          <div className="confirm-double-optin-page-issue-notifications-react__text">
            <ContentArea {...text} theme={RichTextTheme.ArticlePreview} />
          </div>
        )}
        <Button text={buttonLabel} href={buttonHref} />
      </div>
    </Row>
  );
};

export default ConfirmDoubleOptInPageIssueNotifications;
