import React, { useEffect, useRef, useState } from 'react';
import './EPOSSaksliste.scss';
import cn from 'classnames';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import GreyRow, { GreyRowWidth } from '/Components/Layout/GreyRow/GreyRow';
import ExpandButton from '/Components/Atoms/ExpandButton/ExpandButton';
import PageTabsList, {
  PageTab,
} from '/Components/Molecules/PageTabsList/PageTabsList';
import EPOSSakslisteClient from '/Shared/Code/EPOSSaksliste/EPOSSakslisteClient';
import EPOSSakslisteFilter from './EPOSSakslisteFilter';
import EPOSSakslisteResultsList from './EPOSSakslisteResultsList';
import { DropDownProps } from '/Models/Generated/DropDownProps';
import { EPOSSakslisteProps } from '/Models/Generated/EPOSSakslisteProps';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const firstVal = (list: DropDownProps) => list?.items?.[0]?.value || '';

// index: 0 for finding period starting with year (e.g. 2010-....), 1 for finding period ending with year (e.g. ....-2020)
const findPeriod = (array: DropDownProps, period: string, index: 1 | 0) => {
  return array.items.find(
    (el) => el.value?.split('-')?.[index] === period?.split('-')?.[index]
  )?.value;
};

const isValueMissingInDropdown = (array: DropDownProps, value) =>
  !!array?.items &&
  (!value || array.items.filter((i) => i.value === value).length === 0);

const EPOSSaksliste = ({
  apiEndpoint,
  asyncData,
  documentNumberTextBoxProps,
  newerOlderTabProps,
  pageId,
  publicationSaksGrunnlagDropDown,
  sakStatusDropDown,
  saksTypeDropDown,
  stortingSesjonFromDropDown,
  stortingSesjonToDropDown,
  subFwEposListProps,
  submitButtonText,
  threeColumnContentArea,
  threeColumnContentAreaTop,
}: // validationMessageForStortingSesjonDropDowns,
EPOSSakslisteProps) => {
  const client = new EPOSSakslisteClient(apiEndpoint, pageId);
  // string is valid int and not empty
  const isValidIntStr = (val) =>
    !!(Number.isInteger(+val) && val.trim().length);

  const mountedRef = useRef(false);
  const [doPushState, setDoPushState] = useState(false); // only used for triggering pushState
  const [isLoading, setIsLoading] = useState(false);
  const [isTabsLoading, setIsTabsLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [urlUpdated, setUrlUpdated] = useState(false);

  const [asyncState, setAsyncState] = useState(asyncData);

  const [fromPeriod, setFromPeriod] = useState(
    (client.urlPid() &&
      findPeriod(stortingSesjonFromDropDown, client.urlPid(), 0)) ||
      firstVal(stortingSesjonFromDropDown)
  );
  const [toPeriod, setToPeriod] = useState(
    (client.urlPid() &&
      findPeriod(stortingSesjonToDropDown, client.urlPid(), 1)) ||
      firstVal(stortingSesjonToDropDown)
  );

  const [caseStatus, setCaseStatus] = useState(client.urlPsid());
  const [caseType, setCaseType] = useState(client.urlPtid());
  const [publication, setPublication] = useState(client.urlPgid());
  const [publicationNumber, setPublicationNumber] = useState(
    isValidIntStr(client.urlDtid()) ? client.urlDtid() : ''
  );
  const [secondaryPublicationNumber, setSecondaryPublicationNumber] = useState(
    (isValidIntStr(client.urlDtid()) || publication !== 'DOK') &&
      isValidIntStr(client.urlDnid())
      ? client.urlDnid()
      : ''
  );

  const [tab, setTab] = useState(client.urlTab() || getFirstTabValue());

  const [month, setMonth] = useState(client.urlM());

  const [topic, setTopic] = useState(client.urlMtid());
  const [subTopic, setSubTopic] = useState(client.urlStid());

  const [committee, setCommittee] = useState(client.urlCoid());

  const [party, setParty] = useState(client.urlPaid());
  const [district, setDistrict] = useState(client.urlCid());
  const [representative, setRepresentative] = useState(client.urlPerid());

  const [page, setPage] = useState(0);

  useEffect(() => {
    // update secondary filter dropdowns when switching between tabs
    if (isValueMissingInDropdown(asyncState.monthDropDown, month)) {
      setMonth(firstVal(asyncState.monthDropDown));
      setUrlUpdated(true);
    }
    if (!topic && asyncState.topicSelection?.mainTopicDropDown)
      setTopic(firstVal(asyncState.topicSelection?.mainTopicDropDown));
    if (
      isValueMissingInDropdown(
        asyncState.topicSelection?.subTopicDropDown,
        subTopic
      )
    ) {
      setSubTopic(firstVal(asyncState.topicSelection?.subTopicDropDown));
      setUrlUpdated(true);
    }
    if (!committee && asyncState.committeeDropDown)
      setCommittee(firstVal(asyncState.committeeDropDown));
    if (!party && asyncState.spokespersonSelection?.politicalPartyDropDown)
      setParty(
        firstVal(asyncState.spokespersonSelection?.politicalPartyDropDown)
      );
    if (!district && asyncState.spokespersonSelection?.districtDropDown)
      setDistrict(firstVal(asyncState.spokespersonSelection?.districtDropDown));
    if (
      !representative &&
      asyncState.spokespersonSelection?.representativeDropDown
    )
      setRepresentative(
        firstVal(asyncState.spokespersonSelection?.representativeDropDown)
      );
  }, [asyncState]);

  const getPeriod = () => {
    return [fromPeriod.split('-')[0], toPeriod.split('-')[1]].join('-');
  };

  const getParamsObj = () => {
    return {
      pid: getPeriod(),
      psid: caseStatus,
      ptid: caseType,
      pgid: publication,
      dtid: publicationNumber,
      dnid: secondaryPublicationNumber,
      tab: tab,
      m: month,
      mtid: topic,
      stid: subTopic,
      coid: committee,
      paid: party,
      cid: district,
      perid: representative,
      page: page,
    };
  };

  useEffect(() => {
    if (!urlUpdated) return;
    const url = client.GetBrowserUrl(getParamsObj());
    window.history.replaceState({}, '', url); // this updated the url when e.g. a month is missing in a dropdown
    setUrlUpdated(false);
  }, [urlUpdated]);

  const setWithPushState = (value, setFunction) => {
    setDoPushState(true); // triggers pushState in useEffect below
    setFunction(value);
  };

  // only used for period change, to check that "from" is before or equal to "to"
  const handleFromPeriodChange = (value) => {
    if (value <= toPeriod) {
      setWithPushState(value, setFromPeriod);
    }
  };

  // only used for period change, to check that "from" is before or equal to "to"
  const handleToPeriodChange = (value) => {
    if (fromPeriod <= value) {
      setWithPushState(value, setToPeriod);
    }
  };

  const handlePubNumEntry = (value, withPushState = true) => {
    // fix wrong value when going back in browser
    const publicationVal = withPushState ? publication : client.urlPgid();

    // value is string of numbers, or numbers and ':'
    const pubNum = value.split(':')[0]?.trim();
    const secondaryPubNum = value.split(':')[1]?.trim();
    if (!isValidIntStr(pubNum) || pubNum.length === 0) {
      setPublicationNumber('');
      setSecondaryPublicationNumber('');
    } else {
      if (publicationVal === 'DOK') {
        // "dokumentserien"

        setPublicationNumber(pubNum);
        if (isValidIntStr(secondaryPubNum))
          setSecondaryPublicationNumber(secondaryPubNum);
        else setSecondaryPublicationNumber('');
      } else {
        // not "dokumentserien"

        if (secondaryPubNum) {
          // if "x:y" and not dokumentserien, reset both
          setPublicationNumber('');
          setSecondaryPublicationNumber('');
        } else {
          // single number when publication is not "Dokumentserien"
          // is for some reason sent in as dnid and not dtid
          setPublicationNumber('');
          setSecondaryPublicationNumber(pubNum);
        }
      }
    }
    // trigger push state
    if (withPushState) setDoPushState(true);
  };

  useEffect(() => {
    if (!doPushState) return;
    const url = client.GetBrowserUrl(getParamsObj());
    window.history.pushState({}, '', url);
    setDoPushState(false);
  }, [doPushState]);

  function tabsData() {
    return [
      asyncState.findSakByTabProps.monthTab,
      asyncState.findSakByTabProps.topicTab,
      asyncState.findSakByTabProps.committeeTab,
      asyncState.findSakByTabProps.spokespersonTab,
    ]
      .filter((el) => el) // in case of missing data
      .map((el) => ({
        value: el.queryValue,
        text: el.label,
        isSelected: el.isSelected,
      }));
  }

  function getFirstTabValue() {
    return tabsData()[0]?.value || '';
  }

  const fetchData = (setTabsLoading = false, scrollTarget = '') => {
    setIsLoading(true);
    if (setTabsLoading) setIsTabsLoading(true);

    client
      .Get(getParamsObj())
      .then(setAsyncState)
      .catch((err) => console.error('Feil: ', err)) // eslint-disable-line no-console
      .finally(() => {
        setIsLoading(false);
        setIsTabsLoading(false);
        if (scrollTarget.length > 0) scrollToTarget(scrollTarget);
      });
  };

  // publication change: reset publication number and secondary publication number if changing to/from "DOK" (dokumentserien)
  const handlePublicationChange = (newValue) => {
    if (!mountedRef.current) return;

    const shouldResetPublicationNumber =
      publication === 'DOK' || newValue === 'DOK';

    if (shouldResetPublicationNumber) {
      setPublicationNumber('');
      setSecondaryPublicationNumber('');
    }
    setWithPushState(newValue, setPublication);
  };

  // fetch data without setting isTabsLoading, scroll to primaryfilter
  useEffect(
    () => mountedRef.current && fetchData(false, 'primaryfilter'),
    [
      fromPeriod,
      toPeriod,
      caseStatus,
      caseType,
      publication,
      publicationNumber,
      secondaryPublicationNumber,
    ]
  );

  // fetch data and set isTabsLoading, scroll to secondaryfilter
  useEffect(
    () => mountedRef.current && fetchData(true, 'secondaryfilter'),
    [tab]
  );

  // fetch data without setting isTabsLoading, scroll to secondaryfilter
  // (scroll to #list after pagination (page), but pagination triggers full page reload)
  useEffect(
    () => mountedRef.current && fetchData(false, 'secondaryfilter'),
    [month, topic, subTopic, committee, party, district, representative, page]
  );

  // Fires once. Sets "mountedRef" to true and adds eventlistener for popstate
  useEffect(() => {
    mountedRef.current = true;
    if (typeof window === 'undefined') return;

    const popstateHandler = () => {
      const period = client.urlPid();

      if (period) {
        setFromPeriod(findPeriod(stortingSesjonFromDropDown, period, 0));
        setToPeriod(findPeriod(stortingSesjonToDropDown, period, 1));
      } else {
        setFromPeriod(firstVal(stortingSesjonFromDropDown));
        setToPeriod(firstVal(stortingSesjonToDropDown));
      }

      const pubNum = client.urlDtid();
      const secondaryPubNum = client.urlDnid();

      const pubNumValue =
        !!pubNum && !!secondaryPubNum
          ? `${pubNum}:${secondaryPubNum}`
          : pubNum || secondaryPubNum || '';
      // handle publication number without push state
      handlePubNumEntry(pubNumValue, false);

      setCaseStatus(client.urlPsid());
      setCaseType(client.urlPtid());
      setPublication(client.urlPgid());
      setTab(client.urlTab() || getFirstTabValue());
      setMonth(client.urlM());
      setTopic(client.urlMtid());
      setSubTopic(client.urlStid());
      setCommittee(client.urlCoid());
      setParty(client.urlPaid());
      setDistrict(client.urlCid());
      setRepresentative(client.urlPerid());
      setPage(client.urlPage());
    };
    window.addEventListener('popstate', popstateHandler);
    return () => window.removeEventListener('popstate', popstateHandler);
  }, []);

  return (
    <PageWrapper>
      <div className="epos-saksliste-react">
        {subFwEposListProps.mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          </Row>
        )}

        {HasContent(threeColumnContentAreaTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.xlarge}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaTop} />
          </Row>
        )}

        {subFwEposListProps.mainBody && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.small}
          >
            <ContentArea {...subFwEposListProps.mainBody} />
          </Row>
        )}

        <div className="epos-saksliste-react__hide-desktop epos-saksliste-react__expand-button-container">
          <ExpandButton
            ariaControls="filter_container"
            text={subFwEposListProps.limitText}
            isOpen={!collapse}
            onClick={() => setCollapse((current) => !current)}
          />
        </div>

        <div
          id="filter_container"
          className={cn('epos-saksliste-react__filter-container', {
            'epos-saksliste-react__filter-container--collapse-on-mobile':
              collapse,
          })}
        >
          <Row horizontalPadding={HorizontalPadding.none}>
            <PageTabsList>
              <PageTab
                href="#nyere-dokumenter"
                text={newerOlderTabProps.tabNewerTitle}
                active
              />
              <PageTab
                href={newerOlderTabProps.linkHistoricalLink.href}
                text={newerOlderTabProps.linkHistoricalLink.text}
              />
            </PageTabsList>
            <GreyRow width={GreyRowWidth.indented}>
              <EPOSSakslisteFilter
                limitText={subFwEposListProps.limitText}
                id="primaryfilter"
                secondaryFilterId="secondaryfilter"
                {...{
                  stortingSesjonFromDropDown,
                  fromPeriod,
                  handleFromPeriodChange,
                  stortingSesjonToDropDown,
                  toPeriod,
                  handleToPeriodChange,
                  sakStatusDropDown,
                  caseStatus,
                  setCaseStatus,
                  saksTypeDropDown,
                  caseType,
                  setCaseType,
                  publicationSaksGrunnlagDropDown,
                  publication,
                  handlePublicationChange,
                  setWithPushState,
                  documentNumberTextBoxProps,
                  publicationNumber,
                  secondaryPublicationNumber,
                  handlePubNumEntry,
                  submitButtonText,
                  asyncState,
                  tabsData,
                  isTabsLoading,
                  tab,
                  setTab,
                  month,
                  setMonth,
                  topic,
                  setTopic,
                  subTopic,
                  setSubTopic,
                  committee,
                  setCommittee,
                  party,
                  setParty,
                  district,
                  setDistrict,
                  representative,
                  setRepresentative,
                }}
              />
            </GreyRow>
          </Row>
        </div>
        <EPOSSakslisteResultsList
          asyncState={asyncState}
          isLoading={isLoading}
          id="list"
        />
        {HasContent(threeColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.large}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentArea} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSSaksliste;
