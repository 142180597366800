import React, { useState } from 'react';
import Row, { HorizontalPadding } from '../../Layout/Row/Row';
import VideoClipBlock from './VideoClipBlock';
import VideoClipMail from './VideoClipMail';
import './VideoClipServicePage.scss';
import { VideoClipServicePageProps } from '/Models/Generated/VideoClipServicePageProps';

const VideoClipServicePage = ({
  videoTitle,
  videoClipEditor,
  videoClipFormSubmit,
  qbrickVideo,
}: VideoClipServicePageProps) => {
  const [playerPosition, setPlayerPosition] = useState(0.45);
  const [visualPlayerPosition, setVisualPlayerPosition] = useState(0.45);
  const [videoDuration, setVideoDuration] = useState(0.9);
  const [clipStart, setClipStart] = useState(0);
  const [clipEnd, setClipEnd] = useState(0.9);
  const [paused, setPaused] = useState(true);

  const [validClipStart, setValidClipStart] = useState(true);
  const [validClipEnd, setValidClipEnd] = useState(true);

  return (
    <div className="video-clip-service-page">
      <Row horizontalPadding={HorizontalPadding.normal}>
        <h2 className="video-clip-service-page__header">{videoTitle}</h2>
        <div className="video-clip-service-page__wrapper">
          <VideoClipBlock
            videoClipEditor={videoClipEditor}
            videoClipFormSubmit={videoClipFormSubmit}
            qbrickVideo={qbrickVideo}
            playerPosition={playerPosition}
            setPlayerPosition={setPlayerPosition}
            visualPlayerPosition={visualPlayerPosition}
            setVisualPlayerPosition={setVisualPlayerPosition}
            videoDuration={videoDuration}
            setVideoDuration={setVideoDuration}
            clipStart={clipStart}
            setClipStart={setClipStart}
            clipEnd={clipEnd}
            setClipEnd={setClipEnd}
            paused={paused}
            setPaused={setPaused}
            validClipStart={validClipStart}
            setValidClipStart={setValidClipStart}
            validClipEnd={validClipEnd}
            setValidClipEnd={setValidClipEnd}
          />
          <VideoClipMail
            {...videoClipFormSubmit}
            videoDuration={videoDuration}
            clipStart={clipStart}
            clipEnd={clipEnd}
            validClipStart={validClipStart}
            validClipEnd={validClipEnd}
          />
        </div>
      </Row>
    </div>
  );
};

export default VideoClipServicePage;
