import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { InfoListProps } from '/Models/Generated/InfoListProps';
import { NyhetsarkivApiProps } from '/Models/Generated/NyhetsarkivApiProps';

export default class NewsArchiveClient {
  constructor(private monthEndpoint: string, private topicEndpoint: string) {}

  public GetByMonth(
    /** The session,  */
    session: string,
    /** The month. 1 means january. */
    month: string,
    /** The episerver page id */
    episerverPageId: number
  ): Promise<NyhetsarkivApiProps | null> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMonthMockData(session).then(
        (res) => ({ results: UseProdUrls(res) } as NyhetsarkivApiProps)
      );
    } else {
      return fetch(
        `${this.monthEndpoint}?pid=${session}&m=${month}&pageId=${episerverPageId}`
      ).then((res) => res.json());
    }
  }

  public GetByTopic(
    session: string,
    topic: string
  ): Promise<NyhetsarkivApiProps | null> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getTopicMockData(session).then(
        (res) =>
          ({
            results: UseProdUrls(res),
          } as NyhetsarkivApiProps)
      );
    } else {
      return fetch(`${this.topicEndpoint}?pid=${session}&mtid=${topic}`).then(
        (res) => res.json()
      );
    }
  }

  BuildFilterUrl = (
    session: string,
    month: string,
    topic: string,
    selectedFilterMonth: boolean
  ): string => {
    const params = [
      session ? 'pid=' + session : null,
      selectedFilterMonth ? 'm=' + month : 'mtid=' + topic,
      'tab=' + (selectedFilterMonth ? 'Month' : 'Topic'),
    ]
      .filter((val) => val !== null)
      .join('&');
    return window.location.href.replace(/\?.*/, '') + '?' + params;
  };

  private getTopicMockData(session: string): Promise<InfoListProps[]> {
    if (
      session.indexOf('2020') === 0 ||
      session.indexOf('2021') === 0 ||
      session.indexOf('2022') === 0
    )
      return fetch('/MockupAssets/MockData/NewsArchiveTopic.json').then((res) =>
        res.json()
      );
    return fetch('/MockupAssets/MockData/NewsArchiveNone.json').then((res) =>
      res.json()
    );
  }

  private getMonthMockData(session: string): Promise<InfoListProps[]> {
    if (
      session.indexOf('2020') === 0 ||
      session.indexOf('2021') === 0 ||
      session.indexOf('2022') === 0
    )
      return fetch('/MockupAssets/MockData/NewsArchiveMonth.json').then((res) =>
        res.json()
      );
    return fetch('/MockupAssets/MockData/NewsArchiveNone.json').then((res) =>
      res.json()
    );
  }
}
