import React from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './AlarmBanner.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { AlarmBannerProps } from '/Models/Generated/AlarmBannerProps';

const AlarmBanner = ({ text, title }: AlarmBannerProps) => {
  return HasContent(text) || title ? (
    <Row
      horizontalPadding={HorizontalPadding.normal}
      verticalPaddingBottom={VerticalPadding.large}
    >
      <div className="alarm-banner-react">
        {title && <h1>{title}</h1>}
        {HasContent(text) && (
          <div className="alarm-banner-react__text">
            <ContentArea {...text} />
          </div>
        )}
      </div>
    </Row>
  ) : (
    <></>
  );
};

export default AlarmBanner;
