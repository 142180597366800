import React from 'react';
import './ParentPageLink.scss';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { LinkWithPrefix } from '/Models/Generated/LinkWithPrefix';

const ParentPageLink = ({ prefix, href, text }: LinkWithPrefix) => (
  <div className="parent-page-link-react">
    {href && (
      <div>
        <span>
          {prefix}
          {': '}
        </span>
        <Link href={href} text={text} theme={LinkTheme.standard} />
      </div>
    )}
  </div>
);
export default ParentPageLink;
