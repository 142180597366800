import React from 'react';
import { FormatDate } from '/Shared/Code/Search/FormatDate';
import './ResultPublished.scss';

interface Props {
  published?: string;
}

const ResultPublished = ({ published }: Props) => {
  return published ? (
    <time className="result-published">{FormatDate(published)}</time>
  ) : (
    <></>
  );
};

export default ResultPublished;
