import React, { useState } from 'react';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import './Statsbudsjett.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { StatsbudsjettProps } from '/Models/Generated/StatsbudsjettProps';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import classNames from 'classnames';
import PageInfo from '/Components/Organisms/PageInfo/PageInfo';
import ProceedingStatusNumber from '/Components/Atoms/ProceedingStatusNumber/ProceedingStatusNumber';
import ProceedingFlowList from '/Components/Atoms/ProceedingFlowList/ProceedingFlowList';
import ProceedingOverviewWrapper from '/Components/Molecules/ProceedingOverviewWrapper/ProceedingOverviewWrapper';
import ProceedingStatusWrapper, {
  ProceedingStatusWrapperTheme,
} from '/Components/Molecules/ProceedingStatusWrapper/ProceedingStatusWrapper';

const TemplateComp = (props) => (
  <div className="statsbudsjett-react__template-component">
    <ContentArea {...props} theme={RichTextTheme.InfoBox} />
  </div>
);

const Statsbudsjett = ({
  mainBody,
  mainImageOld,
  mainIntro,
  pageInfo,
  pageTitle,
  pageTitlePropertyName,
  stateBudgetStatus,
  stateBudgetFlow,
  threeColumnContentAreaBottom,
  toggleText,
}: StatsbudsjettProps) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <PageWrapper>
      <div className="statsbudsjett-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <h1
            data-epi-property-name={pageTitlePropertyName}
            className={classNames({
              'epi-editContainer': pageTitlePropertyName,
            })}
          >
            {pageTitle}
          </h1>
          <div className="statsbudsjett-react__main-intro">
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </div>
        </Row>
        {stateBudgetStatus && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.xlarge}
          >
            <ProceedingOverviewWrapper
              heading={stateBudgetStatus.proceedingHeading}
            >
              <ProceedingOverviewWrapper.Left>
                <ProceedingStatusNumber
                  list={stateBudgetStatus.stateBudgetSteps}
                  heading={stateBudgetStatus.stateBudgetHeading}
                />
                <ProceedingStatusNumber
                  list={stateBudgetStatus.revisedStateBudgetSteps}
                  heading={stateBudgetStatus.revisedStateBudgetHeading}
                />
              </ProceedingOverviewWrapper.Left>
              <ProceedingOverviewWrapper.Right>
                <ProceedingStatusWrapper
                  heading={stateBudgetStatus.statusHeading}
                >
                  {' '}
                  <ContentArea
                    {...stateBudgetStatus.statusText}
                    theme={RichTextTheme.ContactInfo}
                  />
                  {stateBudgetStatus.workPlanLink && (
                    <span className="statsbudsjett-react__calendar-link">
                      <a href={stateBudgetStatus.workPlanLink.href}>
                        {stateBudgetStatus.workPlanLink.text}
                      </a>
                    </span>
                  )}
                </ProceedingStatusWrapper>
              </ProceedingOverviewWrapper.Right>
            </ProceedingOverviewWrapper>
          </Row>
        )}

        <Row horizontalPadding={HorizontalPadding.normal}>
          <button
            className={classNames('toggle-button', {
              'toggle-button-close': isOpen,
            })}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>{toggleText}</span>
          </button>
        </Row>
        {isOpen && (
          <Row
            className="statsbudsjett-react__last-content-row"
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.xlarge}
          >
            {mainImageOld && (
              <ImageWithCaption {...mainImageOld} maxWidthTablet={true} />
            )}
            {mainBody && <ContentArea {...mainBody} />}
            {pageInfo && (
              <div className="statsbudsjett-react__page-info-wrapper">
                <PageInfo {...pageInfo} />
              </div>
            )}
          </Row>
        )}
        {stateBudgetFlow && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.xlarge}
          >
            <ProceedingFlowList
              heading={stateBudgetFlow.budgetFlowHeading}
              subHeading={stateBudgetFlow.stateBudgetHeading}
              list={[
                stateBudgetFlow.stateBudgetStep1,
                stateBudgetFlow.stateBudgetStep2,
                stateBudgetFlow.stateBudgetStep3,
                stateBudgetFlow.stateBudgetStep4,
                stateBudgetFlow.stateBudgetStep5,
              ]}
              contentTemplate={TemplateComp}
              contentKey="flowText"
            />
            <ProceedingFlowList
              subHeading={stateBudgetFlow.revisedBudgetHeading}
              list={[
                stateBudgetFlow.revisedStateBudgetStep1,
                stateBudgetFlow.revisedStateBudgetStep2,
                stateBudgetFlow.revisedStateBudgetStep3,
              ]}
              contentTemplate={TemplateComp}
              contentKey="flowText"
            />
            {HasContent(stateBudgetFlow.statusText) && (
              <ProceedingStatusWrapper
                heading={stateBudgetFlow.statusHeading}
                theme={ProceedingStatusWrapperTheme.standalone}
              >
                <ContentArea
                  {...stateBudgetFlow.statusText}
                  theme={RichTextTheme.ContactInfo}
                />
                {stateBudgetFlow.workPlanLink && (
                  <span className="statsbudsjett-react__calendar-link">
                    <a href={stateBudgetFlow.workPlanLink.href}>
                      {stateBudgetFlow.workPlanLink.text}
                    </a>
                  </span>
                )}
              </ProceedingStatusWrapper>
            )}
          </Row>
        )}
        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaBottom} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default Statsbudsjett;
