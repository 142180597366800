import React from 'react';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import './MainImage.scss';
import { ImageWithCaptionProps } from '/Models/Generated/ImageWithCaptionProps';

interface Props extends ImageWithCaptionProps {
  maxWidthTablet?: boolean;
}

const MainImage = (props: Props) =>
  props ? (
    <div className="main-image-react">
      <ImageWithCaption {...props} />
    </div>
  ) : (
    <></>
  );

export default MainImage;
