import UseProdUrls from '/Mockup/Helpers/UseProdUrls';

export interface Props {
  index: number;
  resultLimit: number;
  showHistorical?: boolean;
  dontIncludePeriods?: false;
  query?: string;
}

const getMockData = (index, resultLimit, showHistorical, query) => {
  const adjustedIndex = index - 1;
  const url = showHistorical
    ? '/MockupAssets/MockData/RepresentativesSearchWithHistorical.json'
    : '/MockupAssets/MockData/RepresentativesSearch.json';
  return fetch(url)
    .then((res) => res.json())
    .then((mockData) => {
      const newMock = { ...mockData };
      const tempList = [...mockData.resultList].filter(
        (item) => !query || item.name.toLowerCase().includes(query)
      );
      newMock.numberOfHits = tempList.length;
      newMock.showHistorical = showHistorical;
      newMock.statusText = 'Total number of hits: ' + newMock.numberOfHits;
      newMock.resultList = tempList.slice(
        adjustedIndex,
        adjustedIndex + resultLimit
      );
      return newMock;
    });
};

const representativesSearch = (args) => {
  const {
    index = 1,
    resultLimit = 5,
    showHistorical = false,
    dontIncludePeriods = false,
    query = '',
  } = { ...args };

  const endpointUrl = '/api/RepresentativeSearchApi/Get';

  if (window.location.host === 'localhost:8080') {
    console.log('Local mockdata index: ' + index); // eslint-disable-line no-console
    const promise = getMockData(index, resultLimit, showHistorical, query).then(
      (res) => UseProdUrls(res)
    );
    return Promise.resolve(promise);
  } else {
    return fetch(
      endpointUrl +
        `?index=${index}&limit=${resultLimit}${
          showHistorical ? `&showhistorical=${showHistorical}` : ''
        }&dontIncludePeriods=${dontIncludePeriods}${
          query.length ? `&query=${query}` : ''
        }`,
      { headers: { Accept: 'application/json' } }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      });
  }
};
export default representativesSearch;
