import React, { FunctionComponent } from 'react';
import './TabButtonStrip.scss';
import cn from 'classnames';

interface Props {
  children?: React.ReactNode;
  // wrapping items seems like reasonable default behavior, but adding
  // a prop to enable it for backwards compatibility just in case.
  wrap?: boolean;
}

const TabButtonStrip: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={cn('tab-button-strip-react', {
        'tab-button-strip-react--wrap': props.wrap,
      })}
    >
      {props.children}
    </div>
  );
};

export default TabButtonStrip;
