import React from 'react';
import './TopMenuSearch.scss';
import { TopMenuSearchProps } from '/Models/Generated/TopMenuSearchProps';
import cn from 'classnames';

interface TopMenuSearchPropsExtended extends TopMenuSearchProps {
  toggleSearch: () => void;
  ariaControls: string;
  isOpen: boolean;
}

const TopMenuSearch = (props: TopMenuSearchPropsExtended) => {
  if (props)
    return (
      <div className="top-menu-search-react">
        <button
          className={cn('top-menu-search-react-button', {
            'is-open-react': props.isOpen,
          })}
          onClick={(e) => {
            e.preventDefault();
            props.toggleSearch();
          }}
          aria-controls={props.ariaControls}
          aria-expanded={props.isOpen ? 'true' : 'false'}
          aria-pressed={props.isOpen ? 'true' : 'false'}
          role="button"
        >
          {props.isOpen ? props.closeLabel : props.buttonLabel}
        </button>
      </div>
    );
  else return <></>;
};

export default TopMenuSearch;
