import React, { useEffect, useState } from 'react';
import './PortalsideHvaskjerCalendar.scss';
import PortalsideHvaskjerCalendarClient from './PortalsideHvaskjerCalendarClient';
import Loader, { Theme } from '/Components/Atoms/Loader/Loader';
import Link from '/Components/Atoms/Link/Link';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import { PortalsideHvaskjerCalendarProps } from '/Models/Generated/PortalsideHvaskjerCalendarProps';
import { PortalsideHvaskjerApiCalendarPageProps } from '/Models/Generated/PortalsideHvaskjerApiCalendarPageProps';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import PortalsideHvaskjerCalendarDay from './PortalsideHvaskjerCalendarDay';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import cn from 'classnames';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import NextPrevRotator from '/Components/Atoms/NextPrevRotator/NextPrevRotator';
import Spinner from '../../Atoms/Spinner/Spinner';
import useUrlParam from '/Shared/Code/Hooks/UseUrlParam';

const intervalFromPid = (period: string) => {
  if (!period) return 'future';
  if (period.indexOf('W') >= 0) return 'week';
  if (period.indexOf('M') >= 0) return 'month';
  return 'future';
};

const PortalsideHvaSkjerCalendar = ({
  language,
  eventTypesFilter,
  header,
  // rememberSettingsCheckbox,
  endpoint,
  currentMonth,
  currentWeek,
  intervalDropdown,
  previousLabel,
  nextLabel,
}: PortalsideHvaskjerCalendarProps) => {
  const [data, setData] =
    useState<PortalsideHvaskjerApiCalendarPageProps>(null);
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const client = new PortalsideHvaskjerCalendarClient(endpoint, language);

  const [pid, setPid] = useUrlParam(
    'pid',
    SSRQueryStringReader.Read('pid') ?? currentWeek
  );
  const [filterParam, setFilterParam] = useUrlParam('eventTypeFlags', null);

  const interval = intervalFromPid(pid);
  const filters = filterParam ? filterParam.split(',') : [];

  const handleIntervalChange = (intrvl) => {
    if (intrvl === 'future') setPid('all');
    else if (intrvl === 'month') setPid(currentMonth);
    else if (intrvl === 'week') setPid(currentWeek);
  };

  // When the filter or pid changes, we should retrieve new results
  useEffect(() => {
    setLoading(true);
  }, [filterParam, pid]);

  //When loading is true, we fetch data and set loading to false
  useEffect(() => {
    if (loading) {
      client.GetEvents(pid, filters).then((res) => {
        setLoading(false);
        setData(res);
      });
    }
  }, [loading]);

  return (
    <div className="portalsidehvaskjer-calendar-react">
      <Row
        verticalPaddingTop={VerticalPadding.large}
        verticalPaddingBottom={VerticalPadding.large}
        horizontalPadding={HorizontalPadding.normal}
      >
        {!!data?.icalLink && <Link {...data.icalLink} />}
        {loading && <Spinner />}
      </Row>
      {eventTypesFilter && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          noPaddingOnMobile={true}
        >
          <div className="portalsidehvaskjer-calendar-react__filter-wrapper-mobile">
            <div>
              <h2>{header}</h2>
              <div
                tabIndex={0}
                className={cn(
                  'portalsidehvaskjer-calendar-react__filter-wrapper-mobile__button',
                  {
                    open: filterOpen,
                  }
                )}
                onClick={() => setFilterOpen(!filterOpen)}
              >
                Velg hendelse
              </div>
              <div
                className={cn(
                  'portalsidehvaskjer-calendar-react__filter-wrapper-mobile__filters',
                  { open: filterOpen }
                )}
              >
                {eventTypesFilter.map((filter, idx) => (
                  <Checkbox
                    {...filter.inputProps}
                    text={filter.inputProps.label}
                    key={idx}
                    checked={filters.includes(filter.inputProps.name)}
                    setChecked={() => {
                      if (filters.includes(filter.inputProps.name))
                        setFilterParam(
                          filters
                            .filter((i) => i !== filter.inputProps.name)
                            .join(',')
                        );
                      else
                        setFilterParam(
                          [...filters, filter.inputProps.name].join(',')
                        );
                    }}
                    disabled={loading}
                  />
                ))}
              </div>
            </div>
            {!!data && (
              <div className="portalsidehvaskjer-calendar-react__filter-wrapper-mobile__results-bar">
                <h2>Filtrer etter</h2>
                <div className="portalsidehvaskjer-calendar-react__filter-wrapper-mobile__period-wrapper">
                  <div className="portalsidehvaskjer-calendar-react__filter-wrapper-mobile__period-dropdown-wrapper">
                    <Dropdown
                      options={intervalDropdown?.items}
                      selectedValue={interval}
                      setSelected={handleIntervalChange}
                      id="portalsidehvaskjer_period_dropdown_mobile"
                      name="portalsidehvaskjer_period_dropdown_mobile"
                      disabled={loading}
                    />
                  </div>
                  {interval !== 'future' && (
                    <div>
                      <NextPrevRotator
                        centerText={data.thisPeriodText}
                        centerClick={() => setPid(data.currentPeriod)}
                        prevClick={() => setPid(data.prevPeriod)}
                        nextClick={() => setPid(data.nextPeriod)}
                        previousLabel={previousLabel}
                        nextLabel={nextLabel}
                        disabled={loading}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Row>
      )}
      <div className="portalsidehvaskjer-calendar-react__results-title-mobile">
        {data?.calendarTitle}
      </div>

      {!!data && (
        <Row horizontalPadding={HorizontalPadding.normal}>
          <div className="portalsidehvaskjer-calendar-react__filter-wrapper-desktop">
            <div className="portalsidehvaskjer-calendar-react__filter-wrapper-desktop__results-title">
              {data.calendarTitle}
            </div>
            <div className="portalsidehvaskjer-calendar-react__filter-wrapper-desktop__period-wrapper">
              <div className="portalsidehvaskjer-calendar-react__filter-wrapper-desktop__period-wrapper__period-dropdown-wrapper">
                <Dropdown
                  options={intervalDropdown?.items}
                  selectedValue={interval}
                  setSelected={handleIntervalChange}
                  id="portalsidehvaskjer_period_dropdown_desktop"
                  name="portalsidehvaskjer_period_dropdown_desktop"
                  disabled={loading}
                />
              </div>
              {interval !== 'future' && (
                <div>
                  <NextPrevRotator
                    centerText={data.thisPeriodText}
                    centerClick={() => setPid(data.currentPeriod)}
                    prevClick={() => setPid(data.prevPeriod)}
                    nextClick={() => setPid(data.nextPeriod)}
                    previousLabel={previousLabel}
                    nextLabel={nextLabel}
                    disabled={loading}
                  />
                </div>
              )}
            </div>
          </div>
        </Row>
      )}
      <br />
      {loading && <Loader theme={Theme.default} />}
      {!loading && !!data?.days && (
        <Row horizontalPadding={HorizontalPadding.normal}>
          <ul className="portalsidehvaskjer-calendar-react__results">
            {data.days.map((day, dayIdx) => (
              <li key={dayIdx}>
                <PortalsideHvaskjerCalendarDay {...day} />
              </li>
            ))}
          </ul>
        </Row>
      )}
    </div>
  );
};

export default PortalsideHvaSkjerCalendar;
