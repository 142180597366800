/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import './TextInput.scss';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';
import { ExtendedFormInputProps } from '/Models/FrontendOnly/FormInputs/ExtendedFormInputProps';

interface Props extends ExtendedFormInputProps {
  onKeyDown?: (e) => void;
}

const TextInput = (props: Props) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const onBlur = () => {
    const errorMessages = GetFormInputErrorMessages({
      ...props,
      value: inputEl.current.value,
    });

    if (props.setErrorMessage) {
      if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
      else props.setErrorMessage('');
    }
  };

  const getElement = () => (
    <input
      className="text-input-react"
      ref={inputEl}
      type="text"
      id={props.id}
      name={props.name}
      defaultValue={props.defaultValue}
      value={props.value}
      onChange={(e: React.SyntheticEvent) => props.onChange(e.target.value)}
      onBlur={onBlur}
      onKeyDown={props.onKeyDown}
      minLength={props.minLengthValidation?.value}
      maxLength={props.maxLengthValidation?.value}
      disabled={props.disabled}
      readOnly={props.readonly}
      aria-invalid={props.isInvalid}
      aria-describedby={props.errorMessageId}
    />
  );

  return (
    <>
      {props.disabled && getElement()}
      {!props.disabled && getElement()}
    </>
  );
};

export default TextInput;
