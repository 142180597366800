import React, { useEffect, useState } from 'react';
import './PublikasjonerReferaterliste.scss';
import { PublikasjonerReferaterlisteProps } from '/Models/Generated/PublikasjonerReferaterlisteProps';
import { LinkListGroupProps } from 'Models/Generated/LinkListGroupProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import PublikasjonerReferaterlisteFilter from '/Components/Molecules/PublikasjonerReferaterlisteFilter/PublikasjonerReferaterlisteFilter';
import PublikasjonerReferaterlisteList from '/Components/Molecules/PublikasjonerReferaterlisteList/PublikasjonerReferaterlisteList';
import PublikasjonerReferaterlisteClient from '/Shared/Code/PublikasjonerReferaterliste/PublikasjonerReferaterlisteClient';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

export interface PublikasjonerReferaterlisteState {
  pid: string; // period id
  m: string; // month
  mt: string; // minute type / "referattype"
}

const getInitialState = (): PublikasjonerReferaterlisteState => ({
  pid: SSRQueryStringReader.Read('pid') ?? '',
  m: SSRQueryStringReader.Read('m') ?? '',
  mt: SSRQueryStringReader.Read('mt') ?? '',
});

const initialState: PublikasjonerReferaterlisteState = getInitialState();

const filterId = 'primaryfilter';

const PublikasjonerReferaterliste = ({
  subFwEposListProps,
  asyncData,
  apiEndpoint,
  pageId,
}: // noJsButtonText,
PublikasjonerReferaterlisteProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [tableData, setTableData] = useState<LinkListGroupProps>(
    asyncData?.data || null
  );
  const [data, setData] = useState(asyncData);

  const [period, setPeriod] = useState(
    initialState.pid ||
      data?.stortingPeriodsDropDown.pageLink?.find((item) => item.isSelected)
        ?.value ||
      data?.stortingPeriodsDropDown.pageLink?.[0]?.value
  );

  const [month, setMonth] = useState(
    initialState.m || data?.monthsDropDown.items?.[0]?.value
  );

  const [minute, setMinute] = useState(
    initialState.mt || data?.minutesTypeDropDown.pageLink?.[0]?.value
  );

  const [shouldPushState, setShouldPushState] = useState<boolean>(true);

  const client = new PublikasjonerReferaterlisteClient(apiEndpoint, pageId);

  const buildUrl = () => {
    const url = client.BuildFilterUrl(period, month, minute);
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (isInitialized)
      client.Get(period, month, minute).then((res) => {
        setTableData(res.data);
        setData(res);
        if (shouldPushState) {
          buildUrl();
          scrollToTarget(filterId);
        }
        setShouldPushState(true);
      });
  }, [period, month, minute]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const popstateHandler = () => {
      const state = getInitialState();
      setShouldPushState(false);
      setPeriod(
        state.pid || data?.stortingPeriodsDropDown.pageLink?.[0]?.value
      );
      setMonth(state.m || data?.monthsDropDown.items?.[0]?.value);
      setMinute(state.mt || data?.minutesTypeDropDown.pageLink?.[0]?.value);
    };
    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, []);

  useEffect(() => setIsInitialized(true), []);

  return (
    <PageWrapper>
      <div className="publikasjoner-referaterliste-react">
        {subFwEposListProps.mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          </Row>
        )}
        {subFwEposListProps.mainBody && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainBody}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}
        {/* Jump to this with # after API roundtrip */}
        <a id="primaryfilter"></a>
        <Row
          horizontalPadding={HorizontalPadding.none}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <PublikasjonerReferaterlisteFilter
            filterTitle={subFwEposListProps.limitText}
            filterId={filterId}
            stortingPeriodsDropDown={data?.stortingPeriodsDropDown}
            monthsDropDown={data?.monthsDropDown}
            minutesTypeDropDown={data?.minutesTypeDropDown}
            usePeriod={[period, setPeriod]}
            useMonth={[month, setMonth]}
            useMinute={[minute, setMinute]}
          />
        </Row>
        {tableData && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <PublikasjonerReferaterlisteList {...tableData} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default PublikasjonerReferaterliste;
