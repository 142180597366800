import React from 'react';
import './SearchPageSorting.scss';
import { SearchPageSortingProps } from '/Models/Generated/SearchPageSortingProps';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import SearchClient from '/Shared/Code/Search/SearchClient';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { setSorting } from '/Shared/Code/Search/SearchSlice';

const SearchPageSorting = (props: SearchPageSortingProps) => {
  const dispatch = useDispatch();
  const sorting = useSelector((s: RootState) => s.Search.sorting);
  return (
    <div className="search-page-sorting">
      <label htmlFor="search-page-sorting" className="search-page-label">
        {props.label}
      </label>
      <div className="dropdown-container" id="search-page-sorting">
        <Dropdown
          selectedValue={sorting}
          setSelected={(sorting: string) => dispatch(setSorting(sorting))}
          options={[
            { text: props.relevance, value: SearchClient.sortingRelevance },
            { text: props.date, value: SearchClient.sortingDate },
          ]}
          id="search-page-sorting-dropdown"
          selectedOnTop={true}
        />
      </div>
    </div>
  );
};

const SearchPageSortingWrapper = (props: SearchPageSortingProps) => (
  <Provider store={SearchStore}>
    <SearchPageSorting {...props} />
  </Provider>
);

export default SearchPageSortingWrapper;
