import React, { useEffect, useState } from 'react';
import './PublikasjonerDokumenterliste.scss';
import { PublikasjonerDokumenterlisteProps } from '/Models/Generated/PublikasjonerDokumenterlisteProps';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import FocusItem2 from '/Components/Organisms/FocusItem2/FocusItem2';
import PublikasjonerDokumenterlisteClient from '/Shared/Code/PublikasjonerDokumenterliste/PublikasjonerDokumenterlisteClient';
import PublikasjonerDokumenterlisteFilter from './PublikasjonerDokumenterlisteFilter';
import { PublikasjonerDokumenterlisteAsyncDataProps } from '/Models/Generated/PublikasjonerDokumenterlisteAsyncDataProps';
import Spinner from '/Components/Atoms/Spinner/Spinner';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const primaryFilterId = 'primaryfilter';
const secondaryFilterId = 'secondaryfilter';

const PublikasjonerDokumenterliste = ({
  subFwEposListProps,
  sesjonDropDown,
  documentNumberTextBox,
  submitButtonText,
  asyncData,
  apiEndpoint,
  pageId,
  threeColumnContentArea,
  threeColumnContentAreaInTop,
}: PublikasjonerDokumenterlisteProps) => {
  const [scrollTarget, setScrollTarget] = useState('');

  const client = new PublikasjonerDokumenterlisteClient(apiEndpoint, pageId);
  const [asyncDataState, setAsyncDataState] =
    useState<PublikasjonerDokumenterlisteAsyncDataProps>(asyncData);
  const [doSearch, setDoSearch] = useState(false);
  const [tabChange, setTabChange] = useState(false);
  const [pushState, setPushState] = useState(true);
  const [reload, setReload] = useState(false);
  const [initialized, setInitialized] = useState(false);

  //Filters
  const [selectedTab, setSelectedTab] = useState(client.urlTab());
  const [session, setSession] = useState(
    sesjonDropDown?.pageLink[0]?.value ?? 'all'
  );
  const [docType, setDocType] = useState(client.urlDocType());
  const [docNumber, setDocNumber] = useState(
    documentNumberTextBox?.value ?? ''
  );
  const [selectedPartySingle, setSelectedPartySingle] = useState(
    client.urlPartySingle()
  );
  const [selectedPartySeveral, setSelectedPartySeveral] = useState(
    client.urlPartySeveral()
  );
  const [selectedDistrict, setSelectedDistrict] = useState(
    client.urlDistrict()
  );
  const [selectedRepresentative, setSelectedRepresentative] = useState(
    client.urlRepresentative()
  );
  const [selectedMonth, setSelectedMonth] = useState(client.urlMonth());
  const [selectedCommittee, setSelectedCommittee] = useState(
    client.urlCommittee()
  );

  const search = () => {
    const params = {
      pid: session,
      tab: selectedTab,
      paid:
        selectedTab === 'Representative'
          ? selectedPartySeveral
          : selectedPartySingle,
      cid: selectedDistrict,
      perid: selectedRepresentative,
      coid: selectedCommittee,
      m: selectedMonth,
      dnid: docNumber,
      dt: docType,
    };
    client.Get(params).then((res) => {
      const url = client.BuildFilterUrl(params);
      if (pushState) window.history.pushState({}, '', url);
      setPushState(true);
      setAsyncDataState(res);
      setDoSearch(false);
      setTabChange(false);
      scrollToTarget(scrollTarget);
    });
  };

  const initSearchWithScrollTarget = (target) => {
    if (initialized) {
      setScrollTarget(target);
      setDoSearch(true);
    }
  };

  useEffect(() => doSearch && search(), [doSearch]);

  useEffect(
    () => initSearchWithScrollTarget(primaryFilterId),
    [session, docType]
  );

  useEffect(() => {
    initSearchWithScrollTarget(secondaryFilterId);
  }, [
    selectedPartySeveral,
    selectedPartySingle,
    selectedDistrict,
    selectedRepresentative,
    selectedCommittee,
    selectedMonth,
  ]);

  useEffect(() => {
    setSelectedCommittee('all');
    setSelectedMonth('all');
    setSelectedRepresentative('all');
    setSelectedDistrict('all');
    setSelectedPartySeveral('all');
    setSelectedPartySingle('all');
    setTabChange(true);
    initSearchWithScrollTarget(secondaryFilterId);
  }, [selectedTab]);

  const loadParamsFromUrl = () => {
    setSelectedTab(client.urlTab());
    setDocNumber(client.urlDocNumber());
    setDocType(client.urlDocType());
    setSession(client.urlSession() ?? session);
    setSelectedCommittee(client.urlCommittee());
    setSelectedPartySeveral(client.urlPartySeveral());
    setSelectedPartySingle(client.urlPartySingle());
    setSelectedDistrict(client.urlDistrict());
    setSelectedRepresentative(client.urlRepresentative());
    setSelectedMonth(client.urlMonth());
  };

  useEffect(() => {
    if (!reload) return;
    loadParamsFromUrl();
    setReload(false);
    setPushState(false);
  }, [reload]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadParamsFromUrl();
      setInitialized(true);
      const eventListener = () => setReload(true);
      window.addEventListener('popstate', eventListener);
      return () => window.removeEventListener('popstate', eventListener);
    }
  }, []);

  return (
    <PageWrapper>
      <div className="publikasjoner-dokumenterliste-react">
        {subFwEposListProps.mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          </Row>
        )}
        {HasContent(threeColumnContentAreaInTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaInTop} />
          </Row>
        )}
        {subFwEposListProps.mainBody && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainBody}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}

        <div className="publikasjoner-dokumenterliste-react__filter">
          <PublikasjonerDokumenterlisteFilter
            filterText={subFwEposListProps.limitText}
            primaryFilterId={primaryFilterId}
            secondaryFilterId={secondaryFilterId}
            searchFunction={() => initSearchWithScrollTarget(primaryFilterId)}
            {...{
              sesjonDropDown,
              session,
              setSession,
              documentNumberTextBox,
              docNumber,
              docType,
              setDocType,
              setDocNumber,
              asyncDataState,
              pageId,
              submitButtonText,
              selectedTab,
              setSelectedTab,
              selectedCommittee,
              setSelectedCommittee,
              selectedPartySingle,
              setSelectedPartySingle,
              selectedPartySeveral,
              setSelectedPartySeveral,
              selectedDistrict,
              setSelectedDistrict,
              selectedRepresentative,
              setSelectedRepresentative,
              selectedMonth,
              setSelectedMonth,
              tabChange,
              initialized,
            }}
          />
        </div>
        <div className="publikasjoner-dokumenterliste-react__results">
          <Row horizontalPadding={HorizontalPadding.indent}>
            {!doSearch && !!asyncDataState?.resultList?.groups && (
              <div>
                {asyncDataState.resultList.groups.map((group, groupId) => (
                  <div key={groupId}>
                    <GreyBar text={group.groupPresentationTitle} />
                    {group?.items?.length > 0 && (
                      <ul className="publikasjoner-dokumenterliste-react__list">
                        {group?.items.map((item, idx) => (
                          <li key={idx}>
                            <FocusItem2 {...item} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )}
            {!doSearch &&
              !!asyncDataState?.resultList?.noResultsHeading &&
              !!asyncDataState?.resultList?.noResultsText && (
                <>
                  {asyncDataState?.resultList?.noResultsHeading && (
                    <GreyBar
                      text={asyncDataState?.resultList?.noResultsHeading}
                    />
                  )}
                  <div className="publikasjoner-dokumenterliste-react__no-results-text">
                    <p>{asyncDataState?.resultList?.noResultsText}</p>
                  </div>
                </>
              )}
            {doSearch && <Spinner />}
          </Row>
        </div>
        {HasContent(threeColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentArea} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default PublikasjonerDokumenterliste;
