import React from 'react';
import './KursAdminoversikt.scss';
import { KursAdminoversiktProps } from '/Models/Generated/KursAdminoversiktProps';
import { AdminEventListingProps } from '/Models/Generated/AdminEventListingProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import Link from '/Components/Atoms/Link/Link';

const KursAdminoversikt = ({
  mainIntro,
  mainBody,
  eventListing,
  tableHeaderDate,
  tableHeaderTime,
  tableHeaderLink,
  tableHeaderStatus,
}: KursAdminoversiktProps) => {
  return (
    <div className="kurs-adminoversikt-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        {mainIntro && <ContentArea {...mainIntro} />}
        {mainBody && <ContentArea {...mainBody} />}
      </Row>
      {eventListing && (
        <table>
          <tr>
            <th>{tableHeaderDate}</th>
            <th>{tableHeaderTime}</th>
            <th>{tableHeaderLink}</th>
            <th>{tableHeaderStatus}</th>
          </tr>

          {eventListing.map((val: AdminEventListingProps, idx) => (
            <tr key={idx}>
              <td>{val.date}</td>
              <td>{val.time}</td>
              <td>
                <Link href={val.link.href} text={val.link.text} />
              </td>
              <td>{val.statusText}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default KursAdminoversikt;
