import React from 'react';
import './CalendarComponent.scss';
import Calendar from 'react-calendar';

interface CalenderProps {
  setValue: (e) => void;
  value: string;
  minDate?: string;
  maxDate?: string;
  language: string;
}
const getDateString = (date) => {
  const formattedDate = `${date.getFullYear()}-${(
    '0' +
    (date.getMonth() + 1)
  ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  return formattedDate;
};

const CalendarComponent = ({
  value,
  setValue,
  minDate,
  maxDate,
  language,
}: CalenderProps) => {
  return (
    <Calendar
      onChange={(value: Date) => {
        setValue(getDateString(value));
      }}
      value={new Date(value)}
      locale={language}
      defaultView="decade"
      minDetail="decade"
      minDate={minDate ? new Date(minDate) : new Date('1986-10-01')}
      maxDate={maxDate ? new Date(maxDate) : new Date()}
      showNeighboringDecade={true}
      nextAriaLabel={language === 'no' ? 'Neste periode' : 'Next period'}
      next2AriaLabel={language === 'no' ? 'Hopp videre' : 'Jump forwards'}
      prevAriaLabel={language === 'no' ? 'Forrige periode' : 'Previous period'}
      prev2AriaLabel={language === 'no' ? 'Hopp tilbake' : 'Jump backwards'}
      navigationAriaLive={'polite'}
    />
  );
};

export default CalendarComponent;
