import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { InfoListProps } from '/Models/Generated/InfoListProps';

export interface EuEosNewsState {
  filterYear: number;
  filterMonth: number;
  filterCategory: number;
  showExpandButton: boolean;
  isLoading: boolean;
  shouldPushState: boolean;
  euEosNewsList: InfoListProps[];
}

const getInitialState = (): EuEosNewsState => ({
  filterYear: +SSRQueryStringReader.Read('y') ?? 0,
  filterMonth: +SSRQueryStringReader.Read('m') ?? 0,
  filterCategory: +SSRQueryStringReader.Read('dtid') ?? 0,
  showExpandButton: SSRQueryStringReader.ReadBool('exp', true),
  isLoading: false,
  shouldPushState: true,
  euEosNewsList: [],
});

const initialState: EuEosNewsState = getInitialState();

export const EuEosNewsSlice = createSlice({
  name: 'euEosNews',
  initialState,
  reducers: {
    setList(state, action: PayloadAction<InfoListProps[]>) {
      state.euEosNewsList = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setFilterYear(state, action: PayloadAction<string>) {
      state.filterYear = +action.payload;
    },
    setFilterMonth(state, action: PayloadAction<string>) {
      state.filterMonth = +action.payload;
    },
    setFilterCategory(state, action: PayloadAction<string>) {
      state.filterCategory = +action.payload;
    },
    setShouldPushState: (state, action: PayloadAction<boolean>) => {
      if (state.shouldPushState !== action.payload)
        state.shouldPushState = action.payload;
    },
    setShowExpandButton: (state, action: PayloadAction<boolean>) => {
      state.showExpandButton = action.payload;
    },
    reloadState: (state) => {
      const list = state.euEosNewsList;
      Object.assign(state, getInitialState());
      state.euEosNewsList = list;
      state.shouldPushState = false;
    },
  },
});

export const {
  setList,
  setIsLoading,
  setFilterYear,
  setFilterMonth,
  setFilterCategory,
  setShouldPushState,
  setShowExpandButton,
  reloadState,
} = EuEosNewsSlice.actions;
export default EuEosNewsSlice.reducer;
