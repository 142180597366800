import React from 'react';
import './LiveMeetingInformationBlockTypeSliderItemInformation.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import consts from './consts';
import lang from './lang';

const LiveMeetingInformationBlockTypeSliderItemInformation = (
  item: SliderItem
) => {
  const { cmsData } = useSelector((s: RootState) => s.LiveBlock);
  const typeText = item.isHearing
    ? lang.hearing[cmsData.lang]
    : lang.storting[cmsData.lang];
  const textClock = lang.clock[cmsData.lang].toLowerCase();
  const canceledText =
    item.status === consts.status.canceled
      ? `- ${lang.canceled[cmsData.lang]}`
      : '';
  const postponedText =
    item.status === consts.status.postponed
      ? `- ${lang.postponed[cmsData.lang]}`
      : '';

  return (
    <div className="live-meeting-information-block-type-slider-item-information-react">
      <div className="live-meeting-information-block-type-slider-item-information-react__text">
        {typeText} {textClock} {item.startTime} {postponedText} {canceledText}
        <br />
        <a href={item.textLink} title={item.title} draggable={false}>
          {item.title}
        </a>
      </div>
    </div>
  );
};

const LiveMeetingInformationBlockTypeSliderItemInformationWrapper = (
  props: SliderItem
) => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeSliderItemInformation {...props} />
  </Provider>
);

export default LiveMeetingInformationBlockTypeSliderItemInformationWrapper;
