import { RichTextTableExpander } from './RichText';

export const fixImageText = (ref) => {
  const nodes = ref.current?.querySelectorAll('.imagetext');
  nodes?.forEach((node: HTMLElement) => {
    const img = node.previousElementSibling?.querySelector('img');
    let width = img?.width;

    if (width) node.style.width = img?.width + 'px';
    else if (img) {
      img.addEventListener('load', () => {
        width = img?.width;
        if (width) node.style.width = img?.width + 'px';
      });
    }
  });
};

export const useEditorialTableExpander = (ref) => {
  const tables = ref.current?.querySelectorAll('table');
  const showFullLabel = 'Vis tabellen under i full bredde';
  const hideOverflowLabel = 'Skjul overflytende tabellinnhold';
  tables?.forEach((table: HTMLElement) => {
    const parent = table.parentNode;
    const overlay = document.createElement('div');
    overlay.classList.add('rich-text-react__table-wrapper');
    const message = document.createElement('button');
    message.innerText = showFullLabel;
    message.classList.add('rich-text-react__table-wrapper__message');
    message.addEventListener('click', () => {
      const fullWidthClass = 'rich-text-react__table-wrapper--full-width';
      if (message.parentElement.classList.contains(fullWidthClass)) {
        message.parentElement.classList.remove(fullWidthClass);
        message.innerText = showFullLabel;
      } else {
        message.parentElement.classList.add(fullWidthClass);
        message.innerText = hideOverflowLabel;
      }
    });
    const wrapper = document.createElement('div');
    wrapper.classList.add('rich-text-react__table-wrapper__scroll-area');
    parent.insertBefore(overlay, table);
    overlay.appendChild(message);
    overlay.appendChild(wrapper);
    wrapper.appendChild(table);
  });
};

export const fixEditorialTableScrollers = (ref) => {
  ref.current
    ?.querySelectorAll('.rich-text-react__table-wrapper')
    ?.forEach((tw) => {
      const scroller = tw.querySelector(
        '.rich-text-react__table-wrapper__scroll-area'
      );
      if (scroller.clientWidth < scroller.scrollWidth) {
        if (!tw.classList.contains('rich-text-react__table-wrapper--active'))
          tw.classList.add('rich-text-react__table-wrapper--active');
      } else {
        tw.classList.remove('rich-text-react__table-wrapper--active');
      }
    });
};

export const modalKeydownHandler = (e) => {
  const target = e.target;
  const shiftPressed = e.shiftKey;
  const keyCodeTab = 9;
  const keyCodeEsc = 27;
  const dialog = document.querySelector('.rich-text-react__modal');
  if (e.keyCode === keyCodeEsc) {
    dialog.querySelector('.rich-text-react__modal__close button').click();
    return;
  }
  if (e.keyCode !== keyCodeTab) return;
  if (dialog) {
    const tabElements = dialog.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    );
    const first = tabElements[0];
    const last = tabElements[tabElements.length - 1];
    if (target.closest('.rich-text-react__modal') !== dialog) first.focus();
    else if (!shiftPressed && target === last) first.focus();
    else if (shiftPressed && target === first) last.focus();
    else return;
    e.preventDefault();
  }
};

export const useBigDocTableExpander = (ref) => {
  const tables = ref.current?.querySelectorAll('table');
  const showFullLabel = 'Vis tabellen i full bredde';
  const closeButtonText = 'Lukk';
  tables?.forEach((table: HTMLElement) => {
    const parent = table.parentNode;
    const overlay = document.createElement('div');
    overlay.classList.add('rich-text-react__bigdoc-table-wrapper');
    const message = document.createElement('button');
    message.innerText = showFullLabel;
    message.classList.add(
      'rich-text-react__bigdoc-table-wrapper__expand-button'
    );
    const wrapper = document.createElement('div');
    wrapper.classList.add('rich-text-react__bigdoc-table-wrapper__scroll-area');
    message.addEventListener('click', () => {
      const body = document.body;
      const modal = document.createElement('div');
      modal.classList.add('rich-text-react__modal');
      modal.setAttribute('aria-modal', 'true');
      modal.setAttribute('role', 'dialog');
      const modalContent = document.createElement('div');
      modalContent.classList.add('rich-text-react__modal__content');
      body.insertBefore(modal, body.childNodes[0]);
      body.style.maxHeight = '100vh';
      body.style.overflow = 'hidden';
      const closeButtonContainer = document.createElement('div');
      closeButtonContainer.classList.add('rich-text-react__modal__close');
      const closeButton = document.createElement('button');
      closeButton.innerHTML = closeButtonText;
      closeButtonContainer.appendChild(closeButton);
      body.addEventListener('keydown', modalKeydownHandler);

      const closeHandler = (e) => {
        e.preventDefault();
        body.style.maxHeight = '';
        body.style.overflow = '';
        body.removeChild(modal);
        body.removeEventListener('keydown', modalKeydownHandler);
      };

      closeButton.addEventListener('click', closeHandler);

      //add esc handler
      modalContent.appendChild(table.cloneNode(true));
      modal.appendChild(closeButtonContainer);

      modal.appendChild(modalContent);
      closeButtonContainer.focus();
    });
    parent.insertBefore(overlay, table);
    overlay.appendChild(message);
    overlay.appendChild(wrapper);
    wrapper.appendChild(table);
  });
};

export const fixBigdocTableScrollers = (ref) => {
  ref.current
    ?.querySelectorAll('.rich-text-react__bigdoc-table-wrapper')
    ?.forEach((tw) => {
      const scroller = tw.querySelector(
        '.rich-text-react__bigdoc-table-wrapper__scroll-area'
      );
      if (scroller.clientWidth < scroller.scrollWidth) {
        if (
          !tw.classList.contains(
            'rich-text-react__bigdoc-table-wrapper--active'
          )
        )
          tw.classList.add('rich-text-react__bigdoc-table-wrapper--active');
      } else {
        tw.classList.remove('rich-text-react__bigdoc-table-wrapper--active');
      }
    });
};

export const fixLargeTables = (ref, tableExpander) => {
  if (tableExpander === RichTextTableExpander.None) return;
  else if (tableExpander === RichTextTableExpander.BigDocument)
    useBigDocTableExpander(ref);
  else useEditorialTableExpander(ref); //Default
};

export const fixIframes = (ref) => {
  ref.current
    ?.querySelectorAll('iframe')
    ?.forEach((iframe: HTMLIFrameElement) => {
      if (
        iframe.src?.toLowerCase()?.indexOf('youtube') ||
        iframe.src?.toLowerCase()?.indexOf('qbrick')
      ) {
        iframe.style.maxWidth = iframe.clientWidth + 'px';
        iframe.style.width = '100%';
        iframe.style.aspectRatio = '16 / 9';
        iframe.attributes.removeNamedItem('width');
        iframe.attributes.removeNamedItem('height');
      }
    });
};
