import React from 'react';
import './NextPrevRotator.scss';
import cn from 'classnames';

export interface Props {
  centerText: string;
  centerClick: () => void;
  prevClick: () => void;
  nextClick: () => void;
  previousLabel: string;
  nextLabel: string;
  disabled: boolean;
}

const NextPrevRotator = ({
  centerText,
  centerClick,
  prevClick,
  nextClick,
  previousLabel,
  nextLabel,
  disabled,
}: Props) => {
  return (
    <div
      className={cn('next-prev-rotator-react', {
        'next-prev-rotator-react--disabled': disabled,
      })}
    >
      <button
        className="next-prev-rotator-react__prev"
        onClick={prevClick}
        disabled={disabled}
      >
        <span className="sr-only">{previousLabel}</span>
      </button>
      <button
        className="next-prev-rotator-react__center"
        onClick={centerClick}
        disabled={disabled}
      >
        {centerText}
      </button>
      <button
        className="next-prev-rotator-react__next"
        onClick={nextClick}
        disabled={disabled}
      >
        <span className="sr-only">{nextLabel}</span>
      </button>
    </div>
  );
};

export default NextPrevRotator;
