import React, { FunctionComponent } from 'react';
import './BlockWrapper.scss';
import cn from 'classnames';

export enum BlockWrapperAlignment {
  None,
  Left,
  Right,
  LeftWithin,
  RightWithin,
}

interface Props {
  isInRichTextArea?: boolean;
  alignment?: BlockWrapperAlignment;
  children?: React.ReactNode;
  noSizeLimit?: boolean;
  isZoomed?: boolean;
}

const BlockWrapper: FunctionComponent<Props> = (props) => {
  return (
    <div
      className={cn('block-wrapper-react', {
        'block-wrapper-react--in-rich-text': props.isInRichTextArea,
        'block-wrapper-react--align-left':
          props.alignment === BlockWrapperAlignment.Left,
        'block-wrapper-react--align-right':
          props.alignment === BlockWrapperAlignment.Right,
        'block-wrapper-react--align-left-within':
          props.alignment === BlockWrapperAlignment.LeftWithin,
        'block-wrapper-react--align-right-within':
          props.alignment === BlockWrapperAlignment.RightWithin,
        'block-wrapper-react--no-size-limit': props.noSizeLimit,
        'block-wrapper-react--zoomed': props.isZoomed,
      })}
    >
      {props.children}
    </div>
  );
};

export default BlockWrapper;
