import React from 'react';
import './EuEosNewsFilter.scss';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import { ListItemProps } from '/Models/Generated/ListItemProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import {
  setFilterYear,
  setFilterMonth,
  setFilterCategory,
} from '/Shared/Code/EuEosNews/EuEosNewsSlice';
import { Provider, useDispatch, useSelector } from 'react-redux';

export interface Props {
  ddlYear: ListItemProps[];
  ddlYearTitle: string;
  ddlMonth: ListItemProps[];
  ddlMonthTitle: string;
  ddlCategory: ListItemProps[];
  ddlCategoryTitle: string;
  filterTitle: string;
  filterId: string;
}

const EuEosNewsFilter = ({
  ddlYear,
  ddlYearTitle,
  ddlMonth,
  ddlMonthTitle,
  ddlCategory,
  ddlCategoryTitle,
  filterTitle,
  filterId,
}: Props) => {
  const state = useSelector((s: RootState) => s.EuEosNewsList);
  const dispatch = useDispatch();

  const overrideFilterOpen =
    !!state.filterCategory || !!state.filterMonth || !!state.filterYear;

  return (
    <ExpandableFilterWrapper
      heading={filterTitle}
      initiallyExpanded={overrideFilterOpen}
      scrollId={filterId}
    >
      <div className="eu-eos-news-filter-react">
        <div>
          <p>{ddlYearTitle}</p>
          <Dropdown
            id="eu_eos_news_filter_dropdown_1"
            name="eu_eos_news_filter_dropdown_1"
            options={ddlYear}
            selectedValue={'' + state.filterYear}
            setSelected={(val) => dispatch(setFilterYear(val))}
          />
        </div>
        <div>
          <p>{ddlMonthTitle}</p>
          <Dropdown
            id="eu_eos_news_filter_dropdown_2"
            name="eu_eos_news_filter_dropdown_2"
            options={ddlMonth}
            selectedValue={'' + state.filterMonth}
            setSelected={(val) => {
              dispatch(setFilterMonth(val));
            }}
          />
        </div>
        <div>
          <p>{ddlCategoryTitle}</p>
          <Dropdown
            id="eu_eos_news_filter_dropdown_3"
            name="eu_eos_news_filter_dropdown_3"
            options={ddlCategory}
            selectedValue={'' + state.filterCategory}
            setSelected={(val) => {
              dispatch(setFilterCategory(val));
            }}
          />
        </div>
      </div>
    </ExpandableFilterWrapper>
  );
};

const EuEosNewsFilterWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <EuEosNewsFilter {...props} />
  </Provider>
);

export default EuEosNewsFilterWrapper;
