import classNames from 'classnames';
import React from 'react';
import './Button.scss';

export enum ButtonTheme {
  Blue,
  Red,
  Grey,
}

export interface Props {
  text: string;
  onClick?: (e) => void;
  href?: string;
  disabled?: boolean;
  theme?: ButtonTheme;
  fullWidth?: boolean;
  tabIndex?: number;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const Button = ({
  onClick,
  text,
  href,
  disabled,
  theme,
  fullWidth,
  tabIndex,
  type,
}: Props) => {
  if (!text) return <></>;
  if (href)
    return (
      <a
        className={classNames('button-react', {
          'button-react--red': theme === ButtonTheme.Red,
          'button-react--grey': theme === ButtonTheme.Grey,

          'button-react--full-width': fullWidth,
        })}
        href={href}
        tabIndex={tabIndex}
      >
        {text}
      </a>
    );
  if (onClick || type)
    return (
      <button
        className={classNames('button-react', {
          'button-react--red': theme === ButtonTheme.Red,
          'button-react--grey': theme === ButtonTheme.Grey,
          'button-react--full-width': fullWidth,
        })}
        onClick={onClick}
        disabled={disabled}
        tabIndex={tabIndex}
        type={type}
      >
        {text}
      </button>
    );
  return <></>;
};

export default Button;
