import React from 'react';
import './EPOSDagsordenlisteList.scss';
import Link from '/Components/Atoms/Link/Link';
import { CellStringProps } from '/Models/Generated/CellStringProps';
import { CellLinkProps } from '/Models/Generated/CellLinkProps';
import { EPOSDagsordenlisteAsyncDataProps } from '/Models/Generated/EPOSDagsordenlisteAsyncDataProps';
import Spinner from '/Components/Atoms/Spinner/Spinner';

interface props extends EPOSDagsordenlisteAsyncDataProps {
  hasError?: boolean;
  isLoading?: boolean;
}

const EPOSDagsordenlisteList = ({
  tableHeading,
  tableData,
  hasError,
  isLoading,
}: props) => {
  return (
    <div className="epos-dagsordenliste-list-react">
      {isLoading && <Spinner />}
      {!isLoading && hasError && <div>Det skjedde en feil.</div>}
      {!isLoading && !hasError && (
        <>
          <h2 className="epos-dagsordenliste-list-react__section-heading">
            {tableHeading}
          </h2>
          {tableData && (
            <div>
              {(!tableData.rows || tableData.rows.length === 0) && (
                <table>
                  <caption className="visuallyhidden">
                    {tableData.visuallyHiddenCaption}
                  </caption>
                  <tbody>
                    <tr>
                      <td colSpan={2}>{tableData.emptyDataSetText}</td>
                    </tr>
                  </tbody>
                </table>
              )}
              {tableData.rows && tableData.rows.length > 0 && (
                <table>
                  <caption className="visuallyhidden">
                    {tableData.visuallyHiddenCaption}
                  </caption>
                  <tbody>
                    <tr>
                      <th>{tableData.columns[0].name}</th>
                      <th>{tableData.columns[1].name}</th>
                    </tr>
                    {tableData.rows.map((rowData, idx) => (
                      <tr key={idx}>
                        <td>
                          <Link
                            {...(rowData.cells[0] as CellLinkProps).value}
                          />
                        </td>
                        <td>{(rowData.cells[1] as CellStringProps).value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EPOSDagsordenlisteList;
