import React, { useState } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './ArkivTVSide.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { ArkivTVSideProps } from '/Models/Generated/ArkivTVSideProps';
import ArkivTVSideVideoInfo from './ArkivTVSideVideoInfo';
import JwPlayer from '/Components/Organisms/JwPlayer/JwPlayer';
import { LinkProps } from '/Models/Generated/LinkProps';
import ArkivTVSideSocialSharing from './ArkivTVSideSocialSharing';
import QbrickPlayer from '/Components/Organisms/QbrickPlayer/QbrickPlayer';

const ArkivTVSide = ({
  modulesContentArea,
  videoInfo,
  jwPlayer,
  qbrickPlayer,
  socialSharing,
}: ArkivTVSideProps) => {
  const [position, setPosition] = useState('');
  const [positionSec, setPositionSec] = useState('');
  const [downloadLink, setDownloadLink] = useState<LinkProps>(null);
  const [videoClipLink, setVideoClipLink] = useState<LinkProps>(null);
  const [downloadDescription, setDownloadDescription] = useState<string>(null);
  const [shareUrl, setShareUrl] = useState('');
  return (
    <PageWrapper>
      <div className="arkiv-tv-side-react">
        {videoInfo && (!!qbrickPlayer || !!jwPlayer) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.normal}
          >
            <div className="arkiv-tv-side-react__video-area">
              <div className="arkiv-tv-side-react__video-area-left">
                <div className="arkiv-tv-side-react__video-area-aspect-ratio-container">
                  <div className="arkiv-tv-side-react__video-area-aspect-ratio-content">
                    {!!qbrickPlayer && (
                      <QbrickPlayer
                        {...{
                          ...qbrickPlayer,
                          setPosition,
                          setDownloadLink,
                          setDownloadDescription,
                          setShareUrl,
                          setPositionSec,
                          setVideoClipLink,
                        }}
                      />
                    )}
                    {!!jwPlayer && (
                      <JwPlayer
                        {...{
                          ...jwPlayer,
                          position,
                          setPosition,
                          setDownloadLink,
                          setDownloadDescription,
                          setShareUrl,
                          setPositionSec,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <ArkivTVSideVideoInfo {...videoInfo} />
            </div>
          </Row>
        )}
        {socialSharing && (!!qbrickPlayer || !!jwPlayer) && (
          <ArkivTVSideSocialSharing
            {...socialSharing}
            downloadLink={downloadLink}
            downloadDescription={downloadDescription}
            shareUrl={shareUrl}
            position={position}
            positionSec={positionSec}
            videoClipLink={videoClipLink}
          />
        )}
        {HasContent(modulesContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...modulesContentArea} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default ArkivTVSide;
