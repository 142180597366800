import React, { useRef, useState } from 'react';
import './DokumentNavigasjon.scss';
import { DokumentNavigasjonProps } from '/Models/Generated/DokumentNavigasjonProps';
import cn from 'classnames';
import GreyRow, {
  GreyRowTheme,
  GreyRowWidth,
} from '/Components/Layout/GreyRow/GreyRow';
import Link from '/Components/Atoms/Link/Link';
import useIsIntersectingViewport from '/Shared/Code/Hooks/UseIsIntersectingViewport';
import useElementDimension from '/Shared/Code/Hooks/UseElementDimension';

interface Props extends DokumentNavigasjonProps {
  isSticky?: boolean;
}

const DokumentNavigasjon = ({
  innholdLabel,
  tilToppenLabel,
  publicationId,
  menuItems,
  pdfLabel,
  pdf,
  wholePublicationLink,
  isSticky,
}: Props) => {
  const navigationRef = useRef(null);
  const navigationInnerRef = useRef(null);
  const navIsSticking = useIsIntersectingViewport(navigationRef);
  const navWidth = useElementDimension(navigationRef, 'width');
  const navHeight = useElementDimension(navigationInnerRef, 'height');

  const [open, setOpen] = useState(true);

  const [selectedItem, setSelectedItem] = useState(
    menuItems.find((val) => val.selected === true)?.text || ''
  );

  const handleSelectChange = (event) => {
    const selectedIndex = event.target.selectedIndex;

    const selectedHref = menuItems[selectedIndex]?.href;

    if (selectedHref) {
      window.location.href = selectedHref;
    }
    setSelectedItem(event.target.value);
  };

  return (
    <div
      className={cn('dokument-navigasjon-react', {
        'dokument-navigasjon-react--active': isSticky,
      })}
      style={{ height: isSticky && navHeight }}
      ref={navigationRef}
    >
      <div
        className={cn('dokument-navigasjon-react__inner', {
          'dokument-navigasjon-react__inner--sticky': navIsSticking && isSticky,
          'dokument-navigasjon-react__inner--active': isSticky,
        })}
        style={{ width: isSticky && navWidth }}
        ref={navigationInnerRef}
      >
        <GreyRow
          className="dokumentnavigasjon"
          theme={GreyRowTheme.darkGreyWithLeftBorder}
          width={GreyRowWidth.normal}
          fullWidthText
        >
          <div className="dokumentnavigasjon__top">
            <button
              className={cn('dokumentnavigasjon__top__button', {
                open: open,
              })}
              onClick={() => setOpen(!open)}
            >
              {innholdLabel}
            </button>
            {isSticky && navIsSticking && <a href="#">{tilToppenLabel}</a>}
          </div>
          <div
            className={cn('dokumentnavigasjon__wrapper', {
              dokumentnavigasjon__wrapper__open: open,
            })}
          >
            <div className="dokumentnavigasjon__wrapper__left">
              <label
                className="dokumentnavigasjon__wrapper__left__label"
                htmlFor={publicationId}
              >
                {publicationId}
              </label>
              <div className="dokumentnavigasjon__wrapper__left__select-wrapper">
                <select
                  id={publicationId}
                  className="dokumentnavigasjon__wrapper__left__select-wrapper__select"
                  onChange={handleSelectChange}
                  value={selectedItem}
                >
                  {menuItems?.length > 0 &&
                    menuItems.map((val, idx) => (
                      <option key={idx} value={val.text}>
                        {val.text}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            {(pdf || wholePublicationLink.href) && (
              <div className="dokumentnavigasjon__wrapper__right">
                <Link
                  className="dokumentnavigasjon__wrapper__right__pdf"
                  href={pdf}
                  text={pdfLabel}
                />
                <Link
                  className="dokumentnavigasjon__wrapper__right__doc"
                  href={wholePublicationLink.href}
                  text={wholePublicationLink.text}
                />
              </div>
            )}
          </div>
        </GreyRow>
      </div>
    </div>
  );
};

export default DokumentNavigasjon;
