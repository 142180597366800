import React, { useEffect, useRef, useState } from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './EditableTextFullWidthBlockType.scss';
import cn from 'classnames';
import BlockWrapper, {
  BlockWrapperAlignment,
} from '/Components/Layout/BlockWrapper/BlockWrapper';
import { EditableTextFullWidthBlockTypeProps } from '/Models/Generated/EditableTextFullWidthBlockTypeProps';
import { StortingetLiveOverviewProps } from '/Models/Generated/StortingetLiveOverviewProps';
import classNames from 'classnames';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const EditableTextFullWidthBlockType = ({
  isInRichTextArea,
  title,
  titlePropertyName,
  video,
  image,
  mainBody,
  showMeetingLinks,
  links,
  meetingAgendaPageLink,
  tvSpeakersListPageLink,
  tvArchivePageLink,
  serviceUrl,
  isInFullWidthArea,
  isGriefMode,
}: EditableTextFullWidthBlockTypeProps) => {
  const [activeMeeting, setActiveMeeting] = useState(false);
  const blockRef = useRef(null);

  useEffect(() => {
    fetch(serviceUrl)
      .then((res) => res.json())
      .then((data: StortingetLiveOverviewProps) => {
        if (data?.meetingActivity?.meetingActivityStatus === 'AKTIV')
          setActiveMeeting(true);
      });
  });

  return image ||
    video ||
    title ||
    mainBody?.items?.length > 0 ||
    links ||
    showMeetingLinks ? (
    <BlockWrapper
      isInRichTextArea={isInRichTextArea}
      alignment={BlockWrapperAlignment.None}
    >
      <article
        className={cn('editable-text-full-width-block-type', {
          'editable-text-full-width-block-type-row': isInFullWidthArea,
          'grief-mode': isGriefMode,
        })}
        ref={(block) => (blockRef.current = block)}
      >
        {video && (
          <div
            className={classNames('video-wrapper-react', {
              'video-wrapper-react-half': isInFullWidthArea,
            })}
          >
            <div className="video-wrapper-react__video">
              <iframe
                allowFullScreen={true}
                frameBorder="0"
                src={video.src}
                title={video.title}
                scrolling="no"
              />
            </div>
          </div>
        )}
        {!video && image && (
          <div
            className={classNames('image-wrapper-react', {
              'image-wrapper-react-half': isInFullWidthArea,
            })}
          >
            <div className="image-wrapper-react__image">
              {image.href && (
                <a href={image.href}>
                  <img src={image.src} alt={image.altTitle ?? ''} />
                </a>
              )}
              {!image.href && (
                <img src={image.src} alt={image.altTitle ?? ''} />
              )}
            </div>
          </div>
        )}
        {(title ||
          mainBody?.items?.length > 0 ||
          links ||
          showMeetingLinks) && (
          <div
            className={classNames(
              'editable-text-full-width-block-type__text-container',
              {
                'editable-text-full-width-block-type__text-container-half':
                  isInFullWidthArea,
              }
            )}
          >
            {(title || mainBody?.items?.length > 0) && (
              <div className="editable-text-full-width-block-type__content">
                {title && <h2 data-epi-edit={titlePropertyName}>{title}</h2>}
                {mainBody && (
                  <ContentArea
                    {...mainBody}
                    theme={isInFullWidthArea ? RichTextTheme.InfoBox : null}
                  />
                )}
              </div>
            )}
            {links && (
              <>
                <ul className="editable-text-full-width-block-type__links">
                  {links.map((val, idx) => (
                    <li key={idx}>
                      <a href={val.href}>{val.text}</a>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {showMeetingLinks && (
              <>
                <ul className="editable-text-full-width-block-type__meeting-link">
                  {activeMeeting && meetingAgendaPageLink?.href && (
                    <li>
                      <a
                        href={meetingAgendaPageLink.href}
                        className="editable-text-full-width-block-type__meeting-link__calendar"
                      >
                        {meetingAgendaPageLink.text}
                      </a>
                    </li>
                  )}
                  {activeMeeting && tvSpeakersListPageLink?.href && (
                    <li>
                      <a
                        href={tvSpeakersListPageLink.href}
                        className="editable-text-full-width-block-type__meeting-link__speechlist"
                      >
                        {tvSpeakersListPageLink.text}
                      </a>
                    </li>
                  )}
                  {tvArchivePageLink?.href && (
                    <li>
                      <a
                        href={tvArchivePageLink.href}
                        className="editable-text-full-width-block-type__meeting-link__video"
                      >
                        {tvArchivePageLink.text}
                      </a>
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
        )}
      </article>
    </BlockWrapper>
  ) : (
    <></>
  );
};

export default EditableTextFullWidthBlockType;
