import { FormInputProps } from '/Models/Generated/FormInputProps';
import React, { useRef, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';

interface Props extends FormInputProps {
  checked: boolean;
  onChange: (value) => void;
  setErrorMessage: (errorMessage: string) => void;
  disabled?: boolean;
}

const CheckboxWrapper = (props: Props) => {
  const [checkedState, setCheckedState] = useState(props.checked);
  const wrapperRef = useRef(null);

  const onBlur = (e) => {
    if (!e.relatedTarget || !wrapperRef.current.contains(e.relatedTarget)) {
      const errorMessages = GetFormInputErrorMessages({
        ...props,
        value: checkedState,
      });

      if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
      else props.setErrorMessage('');
    }
  };

  return (
    <div ref={wrapperRef}>
      <input type="hidden" name={props.name} value={checkedState + ''} />
      <Checkbox
        setChecked={() => {
          props.onChange(!checkedState + '');
          setCheckedState(!checkedState);
        }}
        checked={checkedState}
        text={props.text}
        onBlur={onBlur}
        disabled={props.disabled}
      />
    </div>
  );
};

export default CheckboxWrapper;
