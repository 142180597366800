import React from 'react';
import './Fylke.scss';
import { FylkeProps } from '/Models/Generated/FylkeProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import MainImage from '/Components/Organisms/MainImage/MainImage';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import RepresentativesListing from './RepresentativesListing';
import { ContentAreaType } from '/Models/Generated/ContentAreaType';

const Fylke = ({
  pageTitle,
  mainIntro,
  mainBody,
  mainImageOld,
  mainImage,
  representativesListTitle,
  countyRepresentativesList,
  representativesQuestionTitle,
  lastQuestionsFromRepresentatives,
  linkQuestionTimeQuestion,
  linkQuestionInterpellation,
  linkWrittenQuestion,
  representativesProposalTitle,
  emptyListMessage,
  representativeProposalList,
  linkToProceedings,
  rightImage,
  externalUrls,
}: FylkeProps) => {
  const linksList = [
    linkQuestionTimeQuestion,
    linkQuestionInterpellation,
    linkWrittenQuestion,
  ].filter((el) => !!el);

  const contentAreaContent = {
    items: [
      {
        component:
          rightImage?.imageSrc?.length > 0
            ? {
                title: rightImage.propertyHeader,
                imageUrl: rightImage?.imageSrc,
                imageAltText: rightImage?.imageAltText,
              }
            : null,
        name: 'ArticlePreview',
      },
      {
        component: externalUrls,
        name: 'ExternalUrls',
      },
    ].filter((el) => !!el.component),
    contentAreaType: ContentAreaType.ThreeColumnsMasonry,
    propertyName: '',
  };

  const hasContentAreaContent = contentAreaContent.items.length > 0;

  return (
    <PageWrapper>
      <div className="fylke-page-react">
        <Row horizontalPadding={HorizontalPadding.indent} noPaddingOnMobile>
          {mainImageOld && <MainImage {...mainImageOld} />}
          {mainImage && <MainImage {...mainImage} />}
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <h1>{pageTitle}</h1>
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          <ContentArea {...mainBody} />
        </Row>
        {hasContentAreaContent && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.xlarge}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove
            lineBelow
          >
            <ContentArea {...contentAreaContent} />
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.indent}>
          <h2 className="fylke-page-react__listing-title">
            {representativesListTitle}
          </h2>
          {countyRepresentativesList && (
            <RepresentativesListing
              representatives={countyRepresentativesList}
            />
          )}
          <h2 className="fylke-page-react__listing-group-title">
            {representativesQuestionTitle}
          </h2>
          <ul className="fylke-page-react__last-questions-list">
            {lastQuestionsFromRepresentatives.map((items, itemId) => (
              <li key={itemId}>
                {items.questionLink && (
                  <h3 className="fylke-page-react__listitem-title">
                    <Link theme={LinkTheme.dark} {...items.questionLink} />
                  </h3>
                )}
                <p>{items.presentationQuestionTitleText}</p>
              </li>
            ))}
          </ul>
          {linksList?.length > 1 && (
            <ul className="fylke-page-react__links-list">
              {linksList.map((item) => (
                <li key={item.text}>
                  <Link theme={LinkTheme.darkSerif} {...item} />
                </li>
              ))}
            </ul>
          )}
          <h2 className="fylke-page-react__listing-group-title">
            {representativesProposalTitle}
          </h2>
          {representativeProposalList?.length ? (
            <ul className="fylke-page-react__representative-proposal-list">
              {representativeProposalList.map((items, itemId) => (
                <li key={itemId}>
                  {items.proposalProceedingPageLink && (
                    <Link
                      theme={LinkTheme.darkSerif}
                      {...items.proposalProceedingPageLink}
                    />
                  )}
                  <p>{items.abstractText}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>{emptyListMessage}</p>
          )}
          {linkToProceedings && <Link {...linkToProceedings} />}
        </Row>
      </div>
    </PageWrapper>
  );
};

export default Fylke;
