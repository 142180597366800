import React from 'react';
import './ResultStatus.scss';

interface Props {
  status?: string;
}

const ResultStatus = ({ status }: Props) => {
  return status ? (
    <strong
      className="result-status"
      dangerouslySetInnerHTML={{ __html: status }}
    ></strong>
  ) : (
    <></>
  );
};

export default ResultStatus;
