import React, { useEffect } from 'react';
import './SearchFilterDesktop.scss';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import cn from 'classnames';
import { GlobalSearchFilterProps } from '/Models/Generated/GlobalSearchFilterProps';
import SelectedFilters from './SelectedFilters';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import Radiobuttons, {
  CheckboxOption,
} from '/Components/Atoms/Radiobuttons/Radiobuttons';
import CalendarWrapper from './Calendar/CalendarWrapper';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { LinkProps } from '/Models/Generated/LinkProps';
import { SearchCategoryProps } from '/Models/Generated/SearchCategoryProps';

interface Props extends GlobalSearchFilterProps {
  setSelectedDates: (e) => void;
  setSelectedFilters: (e) => void;
  selectedFilters: object;
  selectedDates: object;
  setActiveTab: (e) => void;
  activeTab: string;
  handleTabChange: (e) => void;
  setMobileSearchOpen: (e) => void;
  setIsHiddenAfterTransition: (e) => void;
  fromDate: string;
  setFromDate: (e) => void;
  toDate: string;
  setToDate: (e) => void;
  toSesjon: string;
  setToSesjon: (e) => void;
  fromSesjon: string;
  setFromSesjon: (e) => void;
  checkedView: CheckboxOption[];
  setCheckedView: (e) => void;
  language: string;
  todayLabel: string;
  searchTipLink: LinkProps;
  categories: SearchCategoryProps[];
}

const SearchFilterDesktop = ({
  label,
  categories,
  selectedFilters,
  setSelectedFilters,
  activeTab,
  setActiveTab,
  handleTabChange,
  setMobileSearchOpen,
  setIsHiddenAfterTransition,
  noChosenFiltersLabel,
  chosenFiltersLabel,
  clearFiltersLabel,
  setSelectedDates,
  selectedDates,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  toSesjon,
  setToSesjon,
  fromSesjon,
  setFromSesjon,
  checkedView,
  setCheckedView,
  todayLabel,
  language,
  searchTipLink,
}: Props) => {
  const tidsrom = categories[0];
  const fromLabel = tidsrom.items[0].subTitle;
  const toLabel = tidsrom.items[1].subTitle;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'ArrowRight' &&
      e.target.role === 'tab' &&
      e.target.nextSibling
    ) {
      e.target.nextSibling.focus();
    } else if (
      e.key === 'ArrowLeft' &&
      e.target.role === 'tab' &&
      e.target.previousSibling
    ) {
      e.target.previousSibling.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (activeTab !== '') {
      const wrappers = document.querySelectorAll(
        '.search-filter-desktop__items__content.active .search-filter-desktop__items__content__choices__wrapper'
      );
      wrappers.forEach((wrapperElemment: HTMLElement) => {
        if (wrapperElemment) {
          const items = wrapperElemment.children;
          const rows = Math.ceil(items.length / 3);
          wrapperElemment.style.gridTemplateRows = `repeat(${rows}, auto)`;
        }
      });
    }
  }, [activeTab]);
  return (
    <div
      className={cn('search-filter-desktop', {
        active: activeTab,
      })}
    >
      <div className="search-filter-desktop__title__wrapper">
        <h2 className="search-filter-desktop__title__wrapper__label">
          {label}
        </h2>
        {searchTipLink && (
          <div className="search-filter-desktop__title__wrapper__tip">
            <Link
              {...searchTipLink}
              theme={LinkTheme.sans}
              className="search-filter-desktop__title__wrapper__tip__link"
            />
          </div>
        )}
      </div>

      <div className="search-filter-desktop__wrapper">
        <div className="search-filter-desktop__wrapper__tabs" role="tablist">
          {categories.map((category, idx) => (
            <button
              aria-controls={`tab${idx}`}
              id={`tab${idx}-button`}
              role="tab"
              aria-selected={category.title === activeTab}
              key={idx}
              tabIndex={idx === 0 ? 0 : -1}
              className={cn('search-filter-desktop__wrapper__tabs__tab', {
                active: category.title === activeTab,
              })}
              onClick={() => {
                if (category.title === activeTab) {
                  setActiveTab('');
                  setMobileSearchOpen(false);
                  setIsHiddenAfterTransition(true);
                } else {
                  handleTabChange(category.title);
                  setIsHiddenAfterTransition(false);
                  setMobileSearchOpen(true);
                }
              }}
            >
              {category.items.length > 1 &&
                (() => {
                  if (category.queryKeyFromDate) {
                    //If category is datefilter
                    return Object.values(selectedDates).every(
                      (x) => x === null
                    ) ? (
                      category.title
                    ) : (
                      <b>{category.title}</b>
                    );
                  }

                  for (let i = 0; i < category.items.length; i++) {
                    if (
                      category.items[i].queryKey &&
                      selectedFilters[category.items[i].queryKey]?.length > 0
                    ) {
                      return <b>{category.title}</b>;
                    }
                  }
                  return category.title;
                })()}
              {category.items.length === 1 &&
                category.items.map((item, idx) => (
                  <React.Fragment key={idx}>
                    {item.queryKey &&
                    selectedFilters[item.queryKey]?.length > 0 ? (
                      <b>{category.title}</b>
                    ) : (
                      category.title
                    )}
                  </React.Fragment>
                ))}
            </button>
          ))}
          <button
            onClick={() => {
              if (activeTab === '') {
                setActiveTab(tidsrom.title);
                setMobileSearchOpen(true);
                setIsHiddenAfterTransition(false);
              } else {
                setActiveTab('');
                setMobileSearchOpen(false);
                setIsHiddenAfterTransition(true);
              }
            }}
            role="tab"
            tabIndex={-1}
            aria-label={
              language === 'no' ? 'Åpne filter meny' : 'Open filter menu'
            }
            aria-expanded={activeTab !== ''}
            className={cn('search-filter-desktop__wrapper__toggle', {
              active: activeTab !== '',
            })}
          ></button>
        </div>
      </div>
      <div className="search-filter-desktop__items">
        <div
          className={cn('search-filter-desktop__items__content', {
            active: tidsrom.title === activeTab,
          })}
          role="tabpanel"
          aria-labelledby={`tab0-button`}
          id={`tab0`}
          aria-hidden={!(tidsrom.title === activeTab)}
        >
          {language === 'no' && (
            <Radiobuttons
              id={tidsrom.title + '-desktop'}
              options={checkedView}
              setOptions={setCheckedView}
            />
          )}
          {checkedView[0].checked ? (
            <div className="search-filter-desktop__items__content__date-selector-wrapper">
              <DropDownFilterWrapper
                options={tidsrom.items[0].choicesDropDown}
                label={fromLabel || 'Fra'}
                id={'from-sesjon-dropdown'}
                name={
                  language === 'no'
                    ? `Åpne ${fromLabel} sesjon`
                    : `Open ${fromLabel} period`
                }
                selectedValue={fromSesjon}
                setSelected={setFromSesjon}
              />
              <DropDownFilterWrapper
                options={tidsrom.items[1].choicesDropDown}
                label={toLabel || 'Til'}
                id={'to-sesjon-dropdown'}
                name={
                  language === 'no'
                    ? `Åpne ${toLabel} sesjon`
                    : `Open ${toLabel} period`
                }
                selectedValue={toSesjon}
                setSelected={setToSesjon}
              />
            </div>
          ) : (
            <div className="search-filter-desktop__items__content__date-selector-wrapper">
              <CalendarWrapper
                label={fromLabel || 'Fra'}
                id={'to-date-button'}
                language={language}
                minDate={
                  tidsrom.items[0].choicesDropDown[
                    tidsrom.items[0].choicesDropDown.length - 1
                  ].value
                }
                maxDate={toDate}
                setValue={setFromDate}
                value={fromDate}
                todayLabel={todayLabel || 'I dag'}
              />
              <CalendarWrapper
                language={language}
                label={toLabel || 'Til'}
                id={'from-date-button'}
                minDate={fromDate}
                setValue={setToDate}
                value={toDate}
                todayLabel={todayLabel || 'I dag'}
              />
            </div>
          )}
        </div>

        {categories.slice(1).map((category, idx) => (
          <div
            role="tabpanel"
            aria-labelledby={`tab${idx + 1}-button`}
            id={`tab${idx + 1}`}
            aria-hidden={!(category.title === activeTab)}
            key={idx + 1}
            className={cn('search-filter-desktop__items__content', {
              active: category.title === activeTab,
            })}
          >
            {category.items.map((item, idx) => (
              <div
                className="search-filter-desktop__items__content__choices"
                key={idx}
              >
                {item.subTitle && (
                  <div className="search-filter-desktop__items__content__choices__subtitle">
                    {item.subTitle}
                  </div>
                )}
                <div className="search-filter-desktop__items__content__choices__wrapper">
                  {item.choices.map((choice, idx) => (
                    <Checkbox
                      key={idx}
                      id={`${category.title}-${choice.label}${idx}-desktop`}
                      greyedOut={choice.count === 0}
                      setChecked={() => {
                        setSelectedFilters((prevFilters) => {
                          const updatedFilters = { ...prevFilters };
                          const currentCategoryFilters =
                            updatedFilters[item.queryKey] || [];

                          if (currentCategoryFilters.includes(choice.value)) {
                            updatedFilters[item.queryKey] =
                              currentCategoryFilters.filter(
                                (item) => item !== choice.value
                              );
                          } else {
                            if (choice.isAllOption) {
                              //If selected choise is allOption, set that as the only value for that category
                              updatedFilters[item.queryKey] = [choice.value];
                            } else {
                              const allOption = item.choices[0].isAllOption
                                ? item.choices[0]
                                : null;

                              if (
                                //Check if allOption is selected for current category
                                allOption &&
                                currentCategoryFilters.includes(allOption.value)
                              ) {
                                //Remove allOption
                                currentCategoryFilters.splice(
                                  currentCategoryFilters.indexOf(
                                    allOption.value
                                  ),
                                  1
                                );
                              }
                              //Add selected choice
                              updatedFilters[item.queryKey] = [
                                ...currentCategoryFilters,
                                choice.value,
                              ];
                            }
                          }

                          return updatedFilters;
                        });
                      }}
                      checked={selectedFilters[item.queryKey]?.includes(
                        choice.value
                      )}
                      text={`${choice.label} ${
                        choice.count >= 0 ? `<b>(${choice.count})</b>` : ''
                      }`}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
        <SelectedFilters
          setActiveTab={setActiveTab}
          setMobileSearchOpen={setMobileSearchOpen}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          categories={categories}
          noChosenFiltersLabel={noChosenFiltersLabel}
          chosenFiltersLabel={chosenFiltersLabel}
          clearFiltersLabel={clearFiltersLabel}
        />
      </div>
    </div>
  );
};

export default SearchFilterDesktop;
