export default {
  liveFromMeeting: {
    no: 'Direkte fra stortingsmøtet',
    nn: 'Direkte frå stortingsmøtet',
  },
  liveFromHearing: {
    no: 'Direkte fra høring',
    nn: 'Direkte frå høyring',
  },
  hearingStartsAt: {
    no: 'Høringen starter kl. ',
    nn: 'Høyringa startar kl. ',
  },
  meetingStartsAt: {
    no: 'Stortingsmøtet starter kl. ',
    nn: 'Stortingsmøtet startar kl.',
  },
  canceledHearing: {
    no: 'er avlyst',
    nn: 'er avlyst',
  },
  postponedHearing: {
    no: 'er utsatt',
    nn: 'er utsatt',
  },
  timeOfHearing: {
    no: 'Høringen kl. ',
    nn: 'Høyringa kl. ',
  },
  videoArchive: {
    no: 'Videoarkiv',
    nn: 'Videoarkiv',
  },
  speakerList: {
    no: 'Talerliste',
    nn: 'Talarliste',
  },
  agenda: {
    no: 'Dagsorden',
    nn: 'Dagsorden',
  },
  hearingAgenda: {
    no: 'Høringsprogram',
    nn: 'Høyringsprogram',
  },
  attendeesFromGovernment: {
    no: 'Til stede fra regjeringen i muntlig spørretime: ',
    nn: 'Til stades frå regjeringa i munnleg spørjetime: ',
  },
  caseNr: {
    no: 'Sak nr.',
    nn: 'Sak nr.',
  },
  hasHearingInCase: {
    no: 'har høring i sak',
    nn: 'har høyring i sak',
  },
  clock: {
    no: 'KL.',
    nn: 'KL.',
  },
  showFirst: {
    no: 'Vis første',
    nn: 'Vis fyrste',
  },
  scrollLeft: {
    no: 'Bla til venstre',
    nn: 'Bla til venstre',
  },
  scrollRight: {
    no: 'Bla til høyre',
    nn: 'Bla til høgre',
  },
  hearing: {
    no: 'Høring',
    nn: 'Høyring',
  },
  stortinget: {
    no: 'Stortinget',
    nn: 'Stortinget',
  },
  storting: {
    no: 'Storting',
    nn: 'Storting',
  },
  live: {
    no: 'Direkte',
    nn: 'Direkte',
  },
  recording: {
    no: 'Opptak',
    nn: 'Opptak',
  },
  canceled: {
    no: 'Avlyst!',
    nn: 'Avlyst!',
  },
  postponed: {
    no: 'Utsatt!',
    nn: 'Utsatt!',
  },
};
