import React, { FunctionComponent } from 'react';
import Link from '/Components/Atoms/Link/Link';
import './RelatedBlock.scss';

interface Props {
  list?: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  heading?: string;
  children?: React.ReactNode;
}
const RelatedBlock: FunctionComponent<Props> = (props) => {
  return (
    <div className="related-block-wrapper-react">
      <h4>{props.heading}</h4>
      {props.list ? (
        <ul>
          {props.list.map((item, key) => (
            <li key={key}>
              {!!item.link ? (
                <>
                  <Link {...item.link} />{' '}
                  {!!item.presentationText && (
                    <span>{item.presentationText}</span>
                  )}
                </>
              ) : (
                <Link {...item} />
              )}
            </li>
          ))}
        </ul>
      ) : (
        props.children
      )}
    </div>
  );
};

export default RelatedBlock;
