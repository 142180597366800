import React, { useEffect } from 'react';
import './DokumentForside.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { DokumentForsideProps } from '/Models/Generated/DokumentForsideProps';
import Link from '/Components/Atoms/Link/Link';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import DokumentForsideHeader from '/Components/Organisms/DokumentForsideHeader/DokumentForsideHeader';
import DokumentNavigasjon from '/Components/Organisms/DokumentNavigasjon/DokumentNavigasjon';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { NestedLinkProps } from '/Models/Generated/NestedLinkProps';

interface TocProps {
  toc: NestedLinkProps[];
}
interface ItemProps {
  item: NestedLinkProps;
}

const DokumentForside = ({
  title,
  headerProps,
  navigationProps,
  contentLabel,
  toc,
  mainBodyBefore,
  content,
  mainBodyAfter,
  nextLink,
}: DokumentForsideProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);

  const Item = ({ item }: ItemProps) => (
    <li>
      <Link href={item.href} text={item.text} />
      {item.children?.length > 0 && (
        <ul className="dokumentforside-react__content-wrapper__content__toc__ul">
          {item.children.map((child, idx) => (
            <Item key={idx} item={child} />
          ))}
        </ul>
      )}
    </li>
  );

  const Toc = ({ toc }: TocProps) => (
    <ul className="dokumentforside-react__content-wrapper__content__toc">
      {toc?.map((item, idx) => (
        <Item key={idx} item={item} />
      ))}
    </ul>
  );

  return (
    <PageWrapper>
      <div className="dokumentforside-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <h1>{title}</h1>
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.medium}
        >
          <DokumentForsideHeader {...headerProps} />
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          noPaddingOnMobile
          verticalPaddingBottom={VerticalPadding.large}
        >
          <DokumentNavigasjon {...navigationProps} />
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          className="dokumentforside-react__content-wrapper"
        >
          <Row
            horizontalPadding={HorizontalPadding.indent}
            className="dokumentforside-react__content-wrapper__content"
          >
            <h2 className="dokumentforside-react__content-wrapper__content__heading">
              {contentLabel}
            </h2>
            {toc?.length > 0 && <Toc toc={toc} />}
            <div className="page-publication">
              <div className="bigdoc-content">
                {mainBodyBefore && (
                  <ContentArea
                    theme={RichTextTheme.BigDoc}
                    {...mainBodyBefore}
                  />
                )}
                {content && (
                  <ContentArea theme={RichTextTheme.BigDoc} {...content} />
                )}
                {mainBodyAfter && (
                  <ContentArea
                    theme={RichTextTheme.BigDoc}
                    {...mainBodyAfter}
                  />
                )}
              </div>
            </div>
          </Row>
          {nextLink && <MinutesPrevNext nextLink={nextLink} />}
        </Row>
      </div>
    </PageWrapper>
  );
};

export default DokumentForside;
