import React from 'react';
import './ProceedingStatusNumber.scss';
import cn from 'classnames';
import { ProcessStepTheme } from '/Models/Generated/ProcessStepTheme';

interface Props {
  list;
  heading?: string;
}
const ProceedingStatusNumber = ({ heading, list }: Props) => (
  <div className="proceeding-status-number-react">
    {heading && <h3>{heading}</h3>}
    <ol className="proceeding-status-number-react__list">
      {list &&
        list.map((val, idx) => (
          <li className="proceeding-status-number-react__list__item" key={idx}>
            <a
              className="proceeding-status-number-react__list__link"
              href={val.href}
            >
              <div className="proceeding-status-number-react__list__step">
                <span
                  // note: styling for "Future" and "Skipped" are default, so they
                  // can technically be removed, but keeping them for clarity
                  className={cn(
                    'proceeding-status-number-react__list__number',
                    {
                      'proceeding-status-number-react__list__number--past':
                        val.theme === ProcessStepTheme.Past,
                      'proceeding-status-number-react__list__number--current':
                        val.theme === ProcessStepTheme.Current,
                      'proceeding-status-number-react__list__number--future':
                        val.theme === ProcessStepTheme.Future,
                      'proceeding-status-number-react__list__number--skipped':
                        val.theme === ProcessStepTheme.Skipped,
                    }
                  )}
                >
                  {val.number}
                </span>
                <span className="proceeding-status-number-react__list__title">
                  <span>{val.title}</span>
                </span>
              </div>
            </a>
          </li>
        ))}
    </ol>
  </div>
);
export default ProceedingStatusNumber;
