import React, { useEffect, useRef, useState } from 'react';
import './Dropdown.scss';
import cn from 'classnames';
import Keys from '/Shared/Code/Constants/KeyCodes';
import { focusInCurrentTarget } from '/Shared/Code/Helpers/FocusInCurrentTarget';

export interface DropdownOption {
  text: string;
  value;
  isDisabled?: boolean;
}

export interface DropdownProps {
  id: string;
  name: string;
  labeledBy?: string;
  selectedValue;
  options?: DropdownOption[];
  selectedOnTop?: boolean;
  className?: string;
  disabled?: boolean;
  setSelected: (value) => void;
  disableIfOnlyOneOption?: boolean;
  onBlur?: (e) => void;
}

const SearchPageDropdown = ({
  id,
  name,
  labeledBy,
  selectedValue,
  options,
  selectedOnTop,
  className,
  disabled,
  setSelected,
  disableIfOnlyOneOption,
  onBlur,
}: DropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [focusSelected, setFocusSelected] = useState(true);

  const mainButton = useRef(null);
  const entriesContainer = useRef(null);
  const hiddenRef = useRef<HTMLInputElement>(null);

  if (hiddenRef.current !== null) hiddenRef.current.value = selectedValue;

  const isDisabled = () =>
    disabled || (disableIfOnlyOneOption && options.length <= 1);

  const optionsLocal =
    options && selectedOnTop
      ? options.sort((a, _) => (a.value === selectedValue ? -1 : 1))
      : options;

  const clickOption = (option) => {
    if (isDisabled()) return;
    if (option.isDisabled) return;
    if (isOpen && selectedValue !== option.value) setSelected(option.value);
    if (hiddenRef.current !== null) hiddenRef.current.value = option.value;
    setOpen(!isOpen);
    mainButton.current.focus();
  };

  const getEntries = () => {
    const entries = Array.from(
      entriesContainer.current.childNodes
    ) as HTMLElement[];
    const selected = entries.filter((e) =>
      (e.className as string).includes('selected-react')
    )[0];
    const count = entries.length;
    return { entries, selected, count };
  };

  const keyDownMainButton = (e) => {
    if (e.code === Keys.Tab) return;
    e.preventDefault();
    if (isDisabled()) return;
    const { code } = e;
    const { entries, count } = getEntries();
    if (isOpen) {
      if ([Keys.Down, Keys.Up].includes(code))
        entries[code === Keys.Up ? count - 1 : 0].focus();
      if (
        [Keys.NumpadEnter, Keys.Enter, Keys.Space, Keys.Escape].includes(code)
      )
        setOpen(false);
    } else {
      if (
        [Keys.NumpadEnter, Keys.Enter, Keys.Space, Keys.Down, Keys.Up].includes(
          code
        )
      ) {
        setOpen(true);
        setFocusSelected(true);
      }
    }
  };

  useEffect(() => {
    if (!focusSelected) return;
    const { selected } = getEntries();
    if (selected) selected.focus();
    setFocusSelected(false);
  }, [focusSelected]);

  const keyDown = (e) => {
    const { code, target } = e;
    if (isDisabled()) return;
    if (Keys.Escape === code) {
      e.preventDefault();
      setOpen(false);
      mainButton.current.focus();
    }
    if ([Keys.Down, Keys.Up].includes(code)) {
      e.preventDefault();
      if (!isOpen) return;
      const { entries, count } = getEntries();
      const selectedIdx = entries.indexOf(target);
      const direction = code === Keys.Down ? 1 : -1;
      let change = direction;
      if (options.every((v) => v.isDisabled)) return;
      while (options[(selectedIdx + change + count) % count].isDisabled)
        change += direction;
      entries[(selectedIdx + change + count) % count].focus();
    }
  };

  const GetSelectedText = (): string =>
    options?.filter((o) => o.value === selectedValue)?.[0]?.text ?? '';

  useEffect(() => {
    setInitialized(true);
    if (typeof document !== 'undefined') {
      const handler = (e) => {
        const container = mainButton.current?.parentElement;
        if (
          !focusInCurrentTarget({
            relatedTarget: e.target,
            currentTarget: container,
          })
        )
          setOpen(false);
      };
      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }
  }, []);

  return (
    <div
      className={cn(
        'dropdown-react',
        { 'disabled-react': isDisabled() },
        { 'is-open-react': isOpen },
        className
      )}
      role="button"
      aria-expanded={isOpen ? 'true' : 'false'}
      onBlur={(e) => {
        if (focusInCurrentTarget(e)) return;
        if (onBlur) onBlur(hiddenRef.current?.value);
        if (!e.relatedTarget) return; //this happens when something is focused by keyboard, but the user clicks somewhere
        setOpen(false);
      }}
    >
      <input
        type="text"
        className="dropdown-react__hidden-field"
        ref={hiddenRef}
        value={selectedValue}
        name={name}
        aria-label={name || id}
        aria-hidden="true"
        id={id}
        tabIndex={-1}
        onFocus={() => mainButton.current.focus({ focusVisible: true })}
        readOnly
      />
      <button
        className="dropdown-toggle-react"
        id={`${labeledBy ? labeledBy + '-' : ''}-dropdown-toggle`}
        aria-haspopup="listbox"
        aria-expanded="false"
        aria-controls={`sortBy-listbox`}
        onClick={(e) => {
          e.preventDefault();
          setOpen(!isOpen);
          if (!isOpen) setFocusSelected(true);
        }}
        ref={(button) => (mainButton.current = button)}
        onKeyDown={keyDownMainButton}
        disabled={isDisabled()}
      >
        <span className="dropdown-react__toggle-text">{GetSelectedText()}</span>
      </button>
      <div
        className="dropdown-list-react"
        ref={(ul) => (entriesContainer.current = ul)}
        role="listbox"
        id={`sortBy-listbox`}
        aria-labelledby={`${labeledBy ? labeledBy + '-' : ''}-dropdown-toggle`}
      >
        {optionsLocal &&
          initialized &&
          optionsLocal.map((option, idx) => (
            <button
              key={idx}
              onClick={(e) => {
                e.preventDefault();
                clickOption(option);
              }}
              className={cn('dropdown-react__item', {
                'selected-react': selectedValue === option.value,
                'dropdown-react__item--disabled': option.isDisabled,
              })}
              onKeyDown={keyDown}
              disabled={option.isDisabled}
              role="option"
              aria-selected={selectedValue === option.value}
            >
              {option.text}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SearchPageDropdown;
