import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, includeKeyup = false) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    if (includeKeyup) document.addEventListener('keyup', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      if (includeKeyup) document.removeEventListener('keyup', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
