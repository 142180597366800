import { EposSakslisteAsyncDataProps } from '/Models/Generated/EposSakslisteAsyncDataProps';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';

interface EPOSSakslisteState {
  /** Period (e.g. 1999-2022) */
  pid: string;
  /** Status (e.g. BEH (=BEHANDLET)) */
  psid: string;
  /** Type (e.g. B (=BUDSJETT)) */
  ptid: string;
  /** Publication (e.g. PRP (=Proposisjon)) */
  pgid: string;
  /** Publication number (e.g. 123). If user text input is 4:1, this will be 4 */
  /** NOTE: "x:y" is only valid if pgid is "DOK", otherwise integer */
  dtid: string;
  /** "Secondary" publication number. If user text input is 4:1, this will be 1 */
  /** NOTE: If pgid is NOT "DOK", dnid is used for single integer instead of dtid */
  dnid: string;
  /** Selected tab (e.g. Committee) */
  tab: string;
  /** Month - if selected tab is Date. Can be 'all' or numbers 1 - 12 */
  m: string;
  /** Main topic - if selected tab is Topic. */
  mtid: string;
  /** Sub topic - if selected tab is Topic. */
  stid: string;
  /** Committee - if selected tab is Committee. E.g. EOS. */
  coid: string;
  /** Party - if selected tab is Member (e.g. A (=Arbeiderpartiet)) */
  paid: string;
  /** County/district - if selected tab is Member (e.g. Te (=Telemark)) */
  cid: string;
  /** Person/name - if selected tab is Member (e.g. LVA (=Lene Vågslid)) */
  perid: string;
  /** Page number (pagination) */
  page: number;
}

export default class EPOSSakslistelisteClient {
  constructor(private endpoint: string, private pageId: number) {}
  public urlPid = () => SSRQueryStringReader.Read('pid');
  public urlPsid = () => SSRQueryStringReader.Read('psid') ?? 'all';
  public urlPtid = () => SSRQueryStringReader.Read('ptid') ?? 'all';
  public urlPgid = () => SSRQueryStringReader.Read('pgid') ?? 'all';
  public urlDtid = () => SSRQueryStringReader.Read('dtid')?.trim() ?? '';
  public urlDnid = () => SSRQueryStringReader.Read('dnid')?.trim() ?? '';
  public urlTab = () => SSRQueryStringReader.Read('tab');
  public urlM = () => SSRQueryStringReader.Read('m') ?? 'all';
  public urlMtid = () => SSRQueryStringReader.Read('mtid') ?? 'all';
  public urlStid = () => SSRQueryStringReader.Read('stid') ?? 'all';
  public urlCoid = () => SSRQueryStringReader.Read('coid') ?? 'all';
  public urlPaid = () => SSRQueryStringReader.Read('paid') ?? 'all';
  public urlCid = () => SSRQueryStringReader.Read('cid') ?? 'all';
  public urlPerid = () => SSRQueryStringReader.Read('perid') ?? 'all';
  public urlPage = () => +SSRQueryStringReader.Read('page');

  public GetBrowserUrl = (values: EPOSSakslisteState) => {
    const paramString = this.BuildParamsString(values);
    return (
      window.location.href.replace(/\?.*/, '') +
      (paramString.length ? '?' + paramString : '')
    );
  };

  BuildParamsString = (obj) => {
    const common = ['tab', 'pid', 'psid', 'ptid', 'pgid', 'dtid', 'dnid'];
    const tabs = {
      Date: ['m'],
      Topic: ['mtid', 'stid'],
      Committee: ['coid'],
      Member: ['paid', 'cid', 'perid'],
    };
    const tabValue = obj.tab;
    let params = [];

    for (const key in obj) {
      // only include if related tab is set
      if (common.includes(key) || (tabValue && tabs[tabValue].includes(key)))
        params.push(obj[key] && obj[key] !== '0' ? `${key}=${obj[key]}` : null);
    }

    params = params.filter((p) => p !== null);
    return params.length ? params.join('&') : '';
  };

  public Get(
    paramsObj: EPOSSakslisteState
  ): Promise<EposSakslisteAsyncDataProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);

    if (window.location.host === 'localhost:8080') {
      console.log('No mockdata for this client!'); // eslint-disable-line no-console
      // return this.getMockData(pid, chid).then((res) => UseProdUrls(res));
    } else {
      const params = this.BuildParamsString(paramsObj);
      const endpoint =
        window.location.host === 'localhost:62591'
          ? 'http://localhost:62591/api/EposSakslisteApi/Get/'
          : this.endpoint;

      return fetch(
        endpoint + `?pageId=${this.pageId}${params.length ? '&' + params : ''}`,
        {
          headers: { Accept: 'application/json' },
        }
      ).then((res) => res.json());
    }
  }
}
