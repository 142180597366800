import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './Nyhetsarkiv.scss';
import { NyhetsarkivProps } from '/Models/Generated/NyhetsarkivProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import SSQQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { FocusItemProps } from '/Models/Generated/FocusItemProps';
import FocusItemList from '/Components/Organisms/FocusItemList/FocusItemList';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import NewsArchiveClient from '/Shared/Code/NewsArchive/NewsArchiveClient';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const primaryFilterId = 'primaryfilter';
const secondaryFilterId = 'secondaryfilter';

const Nyhetsarkiv = ({
  mainIntro,
  mainBody,
  topFilterHeader,
  topFilter,
  threeColumnContentAreaBottom,
  monthFilter,
  topicMapFilter,
  subfilterHeader,
  tabButtons,
  monthEndpoint,
  topicEndpoint,
  pageId,
  noResultsText,
  allLabel,
}: NyhetsarkivProps) => {
  const client = new NewsArchiveClient(monthEndpoint, topicEndpoint);

  const [selectedTop, setSelectedTop] = useState(
    (topFilter.pageLink.filter((i) => i.isSelected)[0] ?? topFilter.pageLink[0])
      .value
  );

  const [selectedMonth, setSelectedMonth] = useState(
    (monthFilter.items.filter((i) => i.isSelected)[0] ?? monthFilter.items[0])
      .value
  );

  const [selectedTopic, setSelectedTopic] = useState(
    (
      topicMapFilter.items.filter((i) => i.isSelected)[0] ??
      topicMapFilter.items[0]
    ).value
  );

  const [monthFilterItems, setMonthFilterItems] = useState(monthFilter?.items);

  const [topicMapFilterItems, setTopicMapFilterItems] = useState(
    topicMapFilter?.items
  );

  const [reload, setReload] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(
    tabButtons?.buttons.findIndex((b) => b.isSelected) ?? 0
  );

  const [results, setResults] = useState<FocusItemProps[]>([]);

  const [initialized, setInitialized] = useState(false);

  const [filterHeader, setFilterHeader] = useState('');

  useEffect(() => {
    if (!reload) return;
    const pid = SSQQueryStringReader.Read('pid');
    const month = SSQQueryStringReader.Read('m') ?? 'all';
    const tab = SSQQueryStringReader.Read('tab');
    const mtid = SSQQueryStringReader.Read('mtid') ?? '';
    if (pid) setSelectedTop(pid);
    setSelectedMonth(month);
    const newSelectedFilter = ['Month', 'Topic'].indexOf(tab);
    setSelectedFilter(newSelectedFilter >= 0 ? newSelectedFilter : 0);
    setSelectedTopic(mtid);
  }, [reload]);

  const refreshData = (scrollTarget) => {
    if (!reload) {
      const newUrl = client.BuildFilterUrl(
        selectedTop,
        selectedMonth,
        selectedTopic,
        selectedFilter === 0
      );
      window.history.pushState({}, '', newUrl);
    }
    const data =
      selectedFilter === 0
        ? client.GetByMonth(selectedTop, selectedMonth, pageId)
        : client.GetByTopic(selectedTop, selectedTopic);
    data.then((res) => {
      setResults(res?.results);
      if (res?.monthFilter?.items) {
        setMonthFilterItems(res.monthFilter.items);
        const selectedMonthFromApi =
          res.monthFilter.items.filter((m) => m.isSelected)[0] ??
          res.monthFilter.items[0];
        if (selectedMonthFromApi) setSelectedMonth(selectedMonthFromApi.value);
      }
      if (res?.topicMapFilter?.items) {
        setTopicMapFilterItems(res.topicMapFilter.items);
        const selectedTopicMapFilterFromApi =
          res.topicMapFilter.items.filter((t) => t.isSelected)[0] ??
          res.topicMapFilter.items[0];
        if (selectedTopicMapFilterFromApi)
          setSelectedTopic(selectedTopicMapFilterFromApi.value);
      }

      if (initialized) scrollToTarget(scrollTarget);

      setInitialized(true);
    });
    setReload(false);
  };

  // -- load data with scrollTarget --
  useEffect(() => {
    refreshData(primaryFilterId);
  }, [selectedTop]);

  useEffect(() => {
    refreshData(secondaryFilterId);
  }, [selectedMonth, selectedTopic, selectedFilter]);
  // ---------------------------------

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onpopstate = () => setReload(true);
    }
  });

  useEffect(() => {
    if (selectedFilter === 0) {
      const header =
        monthFilter.items.filter((m) => m.value === selectedMonth)[0]?.text ??
        allLabel;
      setFilterHeader(header);
    } else {
      const text = selectedTopic
        ? topicMapFilter.items.filter((t) => t.value === selectedTopic)[0]?.text
        : allLabel;
      setFilterHeader(text ?? '');
    }
  }, [selectedMonth, selectedTopic, selectedFilter]);

  const changeFilter = (idx: number) => {
    setSelectedFilter(idx);
    setSelectedMonth(monthFilterItems[0].value);
    setSelectedTopic(topicMapFilterItems[0].value);
  };

  return (
    <div className="nyhetsarkiv-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        <ContentArea {...mainBody} />
      </Row>
      <Row>
        <ExpandableFilterWrapper
          heading={topFilterHeader}
          initiallyExpanded={false}
          scrollId={primaryFilterId}
        >
          {topFilter?.pageLink && (
            <div className="nyhetsarkiv-react__filter-container nyhetsarkiv-react__filter-container--active">
              <label htmlFor="nyhetsarkiv-react-top-filter">
                {topFilter.labelText}
              </label>
              <Dropdown
                selectedValue={selectedTop}
                options={topFilter.pageLink}
                setSelected={setSelectedTop}
                id="nyhetsarkiv-react-top-filter"
                name="nyhetsarkiv-react-top-filter"
              />
            </div>
          )}
          {tabButtons && (
            <div className="nyhetsarkiv-react__filter-selector">
              <hr
                id={secondaryFilterId}
                className="nyhetsarkiv-react__separator"
              />
              <h2>{subfilterHeader}</h2>
              <TabButtonStrip>
                {tabButtons.buttons?.map((val, idx) => (
                  <TabButton
                    key={idx}
                    selected={selectedFilter === idx}
                    onClick={() => changeFilter(idx)}
                    text={val.text}
                  />
                ))}
              </TabButtonStrip>
            </div>
          )}
          {!tabButtons && <div className="nyhetsarkiv-react__spacer"></div>}
          {monthFilter && (
            <div
              className={classNames('nyhetsarkiv-react__filter-container', {
                'nyhetsarkiv-react__filter-container--active':
                  selectedFilter === 0,
              })}
            >
              <label htmlFor="nyhetsarkiv-react-month-filter">
                {monthFilter.labelText}
              </label>
              <Dropdown
                selectedValue={selectedMonth}
                options={monthFilterItems}
                setSelected={setSelectedMonth}
                id="nyhetsarkiv-react-month-filter"
                name="nyhetsarkiv-react-month-filter"
              />
            </div>
          )}
          {topicMapFilter && (
            <div
              className={classNames('nyhetsarkiv-react__filter-container', {
                'nyhetsarkiv-react__filter-container--active':
                  selectedFilter === 1,
              })}
            >
              <label htmlFor="nyhetsarkiv-react-topic-map-filter">
                {topicMapFilter.labelText}
              </label>
              <Dropdown
                selectedValue={selectedTopic}
                options={topicMapFilterItems}
                setSelected={setSelectedTopic}
                id="nyhetsarkiv-react-topic-map-filter"
                name="nyhetsarkiv-react-topic-map-filter"
              />
            </div>
          )}
        </ExpandableFilterWrapper>
      </Row>
      <Row
        horizontalPadding={HorizontalPadding.indent}
        verticalPaddingTop={VerticalPadding.large}
        verticalPaddingBottom={VerticalPadding.large}
      >
        <GreyBar text={filterHeader} />
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {results?.length > 0 && (
          <FocusItemList items={results} headerTag="h3" />
        )}
        {initialized && !results?.length && (
          <p className="nyhetsarkiv-react__no-results">{noResultsText}</p>
        )}
      </Row>
      <ContentArea {...threeColumnContentAreaBottom} />
    </div>
  );
};

export default Nyhetsarkiv;
