import React from 'react';
import './ResultPage.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultKeywords from './Shared/ResultKeywords';
import ResultPublished from './Shared/ResultPublished';
import ResultStatus from './Shared/ResultStatus';
import ResultHeader from './Shared/ResultHeader';
import ResultChilds from './Shared/ResultChilds';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultPage = ({
  highlighting,
  title,
  summary,
  keywords,
  url,
  published,
  relevance,
  specifics,
  childs,
}: Props) => {
  return (
    <article className="search-result-page-article-react">
      <ResultHeader url={url} title={title} relevance={relevance} />
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      <ResultSummary summary={summary} />
      <ResultHighlighting highlighting={highlighting} />
      <ResultKeywords keywords={keywords} />
      <ResultChilds childs={childs} showChilds={true} />
    </article>
  );
};

export default ResultPage;
