import React, { useState } from 'react';
import './GlobalSearchTestPage.scss';
import { GlobalSearchTestPageProps } from '/Models/Generated/GlobalSearchTestPageProps';
import { Row, Spinner } from '../../../App.Components';
import { HorizontalPadding } from '../../Layout/Row/Row';
import cn from 'classnames';
import Button from '../../Atoms/Button/Button';

const GlobalSearchTestPage = ({
  globalSearchTestSampleModels,
}: GlobalSearchTestPageProps) => {
  const [score, setScore] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const baseUrl = window.location.origin;
    const endpoint = '/api/GlobalSearchApi/QueryScore';

    const responses = await Promise.all(
      globalSearchTestSampleModels.map((item) =>
        fetch(
          baseUrl +
            endpoint +
            '?query=' +
            item.queryString +
            '&language=nb' +
            '&hitId=' +
            item.hitId +
            '&hitPositionHigh=' +
            item.expectedPostionTo +
            '&hitPositionLow=' +
            item.expectedPostionFrom
        ).then((res) => res.json())
      )
    );

    setScore([...responses]);
    setLoading(false);
  };
  const totalScore = globalSearchTestSampleModels.map((val, i) => {
    return score[i]?.score;
  });

  const sumOfScores = totalScore.reduce(
    (acc, currentScore) => acc + (currentScore || 0),
    0
  );

  return (
    <Row
      horizontalPadding={HorizontalPadding.normal}
      className="global-search-test"
    >
      <h1>Referansesøk</h1>
      <div className="global-search-test__intro">
        <Button onClick={fetchData} text={'Kjør test'}></Button>
        {!!sumOfScores && (
          <>
            <h3>Total score: {sumOfScores}</h3>
            <h3>
              Average score: {sumOfScores / globalSearchTestSampleModels.length}
            </h3>
          </>
        )}
      </div>
      <table className="global-search-test__table">
        <thead>
          <tr>
            <td>Querystring </td>
            <td> HitId</td>
            <td> Forventet Posisjon</td>
            <td> Faktisk posisjon</td>
            <td> Score</td>
            <td> Treff</td>
          </tr>
        </thead>
        {!loading && (
          <tbody className="global-search-test__table__body">
            {globalSearchTestSampleModels.map((item, i) => {
              return [
                <tr key={i}>
                  <td>{item.queryString}</td>
                  <td>{item.hitId}</td>
                  <td>
                    {item.expectedPostionFrom === item.expectedPostionTo
                      ? item.expectedPostionTo
                      : item.expectedPostionFrom +
                        ' - ' +
                        item.expectedPostionTo}
                  </td>
                  <td>{score[i]?.actualPosition}</td>
                  <td
                    className={cn(
                      'score',
                      {
                        green: score[i]?.score === 0 || score[i]?.score === -1,
                      },
                      { red: score[i]?.score > 0 || score[i]?.score < -3 },
                      {
                        yellow:
                          score[i]?.score === -3 || score[i]?.score === -2,
                      }
                    )}
                  >
                    {score[i]?.score}
                  </td>

                  <td>
                    {score[i]?.url ? (
                      <a href={score[i].url}>{score[i]?.title}</a>
                    ) : (
                      <span style={{ textTransform: 'capitalize' }}>
                        {score[i]?.title}
                      </span>
                    )}
                  </td>
                </tr>,
              ];
            })}
          </tbody>
        )}
      </table>
      {loading && <Spinner />}
    </Row>
  );
};

export default GlobalSearchTestPage;
