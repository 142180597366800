import React from 'react';
import './CellCustomEposSakAvgivelsesfristerMiddleCell.scss';
import { CellCustomEposSakAvgivelsesfristerMiddleCellProps } from '/Models/Generated/CellCustomEposSakAvgivelsesfristerMiddleCellProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const CellCustomEposSakAvgivelsesfristerMiddleCell = ({
  heading,
  link,
  text,
}: CellCustomEposSakAvgivelsesfristerMiddleCellProps) => {
  return (
    <div className="cell-custom-epos-sak-avgivelsesfrister-middle-cell-react">
      {heading && (
        <h3 className="cell-custom-epos-sak-avgivelsesfrister-middle-cell-react__heading">
          {heading}
        </h3>
      )}
      <h3 className="cell-custom-epos-sak-avgivelsesfrister-middle-cell-react__link">
        <Link {...link} theme={LinkTheme.standard} />
      </h3>
      <span>{text}</span>
    </div>
  );
};

export default CellCustomEposSakAvgivelsesfristerMiddleCell;
