import { useEffect, useState } from 'react';

// eslint-disable-next-line
function useIsIntersectingViewport(elementRef: React.RefObject<any>) {
  const [isSticking, setIsSticking] = useState(false);

  useEffect(() => {
    const scrollHandler = (entry) => {
      setIsSticking(!entry[0].isIntersecting);
    };

    const observer = new IntersectionObserver(scrollHandler, {
      rootMargin: '0px 0px 999999px 0px',
      threshold: [1],
    });

    observer.observe(elementRef.current);

    return () => observer.unobserve(elementRef.current);
  }, []);

  return isSticking;
}

export default useIsIntersectingViewport;
