import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './RightText.scss';
import { RightTextProps } from '/Models/Generated/RightTextProps';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const RightText = ({
  header,
  headerPropertyName,
  text,
  textPropertyName,
}: RightTextProps) => {
  if (!header && !(text?.items?.length ?? 0 > 0) && !headerPropertyName)
    return null;
  return (
    <ColumnContentWrapper
      title={header}
      customTag="article"
      titlePropertyName={headerPropertyName}
    >
      <ContentArea
        {...text}
        propertyName={textPropertyName}
        theme={RichTextTheme.ArticlePreview}
      />
    </ColumnContentWrapper>
  );
};

export default RightText;
