import React from 'react';
import './ArkivTVSideVideoInfo.scss';
import Link from '/Components/Atoms/Link/Link';
import { VideoInfoProps } from '/Models/Generated/VideoInfoProps';
import LinkWithIcon, {
  Icon,
} from '/Components/Atoms/LinkWithIcon/LinkWithIcon';

const ArkivTVSide = ({
  preTitle,
  header,
  mediaMinutesLink,
  mediaLinks,
  eposTVStortingetNowLink,
  eposTVArchiveLink,
  hearingText,
}: VideoInfoProps) => {
  return (
    <aside className="arkiv-tv-side-video-info-react">
      {preTitle && (
        <p className="arkiv-tv-side-video-info-react__pretitle">{preTitle}</p>
      )}
      {header && (
        <h1 className="arkiv-tv-side-video-info-react__header">{header}</h1>
      )}
      {mediaMinutesLink && (
        <div className="arkiv-tv-side-video-info-react__minutes-link">
          {hearingText && <span>{hearingText}</span>}
          <Link {...mediaMinutesLink} />
        </div>
      )}
      <div className="arkiv-tv-side-video-info-react__links">
        {!!mediaLinks?.links?.length && (
          <div className="arkiv-tv-side-video-info-react__video-links">
            <ol>
              {mediaLinks.links.map((link, idx) => (
                <li key={idx}>
                  <LinkWithIcon {...link} icon={Icon.Video} />
                </li>
              ))}
            </ol>
          </div>
        )}
        {(!!eposTVStortingetNowLink || !!eposTVArchiveLink) && (
          <div className="arkiv-tv-side-video-info-react__tv-and-archive">
            {!!eposTVStortingetNowLink && (
              <LinkWithIcon {...eposTVStortingetNowLink} icon={Icon.Video} />
            )}
            {!!eposTVArchiveLink && (
              <LinkWithIcon {...eposTVArchiveLink} icon={Icon.Video} />
            )}
          </div>
        )}
      </div>
    </aside>
  );
};

export default ArkivTVSide;
