import React, { useState, useRef, useEffect } from 'react';
import './SkjemaAkkreditering.scss';
import { SkjemaAkkrediteringProps } from '/Models/Generated/SkjemaAkkrediteringProps';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import MainImage from '/Components/Organisms/MainImage/MainImage';
import InputWrapper from '/Components/Molecules/InputWrapper/InputWrapper';
import Form from '/Components/Atoms/Form/Form';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import GreyRow, {
  GreyRowWidth,
  GreyRowVerticalPadding,
} from '/Components/Layout/GreyRow/GreyRow';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { InputWrapperProps } from '/Components/Molecules/InputWrapper/InputWrapper';
import useInput from '/Shared/Code/Hooks/UseInput';
import GetErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';
import { FormStatus } from '/Models/Generated/FormStatus';
import classNames from 'classnames';
import SkjemaAkkrediteringApplicationAdminTable from './SkjemaAkkrediteringApplicationAdminTable';
import SkjemaAkkrediteringChangedBy from './SkjemaAkkrediteringChangedBy';
// import SkjemaAkkrediteringAttachments from './SkjemaAkkrediteringAttachments';
import Button from '/Components/Atoms/Button/Button';
import SkjemaAkkrediteringDeleteApplication from './SkjemaAkkrediteringDeleteApplication';
import Recaptcha from '/Components/Organisms/Recaptcha/Recaptcha';
import { SkjemaAkkrediteringMainFormProps } from '/Models/Generated/SkjemaAkkrediteringMainFormProps';
import SkjemaAkkrediteringUnsubscribe from './SkjemaAkkrediteringUnsubscribe';

const SkjemaAkkreditering = ({
  mainImage,
  rightContent,
  header,
  unsubscribeModeProps,
  form,
  adminTable,
  language,
}: SkjemaAkkrediteringProps) => {
  const recaptchaToken = useRef('');
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState(form?.status);
  const [adminTableState, setAdminTableState] = useState(adminTable);
  // const [attachmentFileNames, setAttachmentFilenames] = useState(
  //   form?.uploadedAttachments
  // );
  const [applicationDeleted, setApplicationDeleted] = useState(false);
  const [completedProps, setCompletedProps] = useState(form?.completedProps);
  const [adminMetaDataState, setAdminMetaDataState] = useState(
    form?.adminMetaDataProps
  );
  const f = form;
  const [firstName, setFirstName, setFirstNameVal] = useInput(f?.firstName);
  const [lastName, setLastName, setLastNameVal] = useInput(f?.lastName);
  const [dOfBirth, setDOfBirth, setDateOfBirthVal] = useInput(f?.dateOfBirth);
  const [email, setEmail, setEmailVal] = useInput(form?.email);
  const [phone, setPhone, setPhoneVal] = useInput(form?.phone);
  const [position, setPosition, setPositionVal] = useInput(form?.position);
  const [pOfWork, setPOfWork, setPOfWorkVal] = useInput(f?.placeOfWork);
  const [notesObj, setNotes, setNotesVal] = useInput(form?.notes);
  // const [attachment, setAttachment, setAttachmentVal] = useInput(f?.attachment);
  const [consent, setConsent, setConsentVal] = useInput(form?.consent);
  const inputEl = useRef<HTMLFormElement>(null);

  const buildWrapper = (
    inputWrapperProps: InputWrapperProps,
    value
  ): InputWrapperProps => {
    if (inputWrapperProps === null) return null;
    const backendErrorMessages = GetErrorMessages(
      { ...inputWrapperProps.inputProps, value: inputWrapperProps.value },
      false
    );
    const backendErrorMessage = backendErrorMessages.length
      ? backendErrorMessages[0]
      : null;
    return { ...inputWrapperProps, backendErrorMessage, value };
  };

  const ajaxHandler = (e, formElement) => {
    e.preventDefault();
    const formData = new FormData(formElement);
    setIsLoading(true);
    fetch(form?.postUrl, {
      // fetch('http://localhost:3000/formvalidationtest', {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((form: SkjemaAkkrediteringMainFormProps) => {
        // setAttachmentFilenames(form?.uploadedAttachments);
        setFormState(form?.status);
        setFirstName(buildWrapper(form?.firstName, firstName?.value));
        setLastName(buildWrapper(form?.lastName, lastName?.value));
        setDOfBirth(buildWrapper(form?.dateOfBirth, dOfBirth?.value));
        setEmail(buildWrapper(form?.email, email?.value));
        setPhone(buildWrapper(form?.phone, phone?.value));
        setPosition(buildWrapper(form?.position, position?.value));
        setPOfWork(buildWrapper(form?.placeOfWork, pOfWork?.value));
        setNotes(buildWrapper(form?.notes, notesObj?.value));
        // setAttachment(buildWrapper(form?.attachment, attachment?.value));
        setConsent(buildWrapper(form?.consent, consent?.value));
        setAdminMetaDataState(form?.adminMetaDataProps);
        if (form?.status === FormStatus.Completed) {
          setFormState(FormStatus.Completed);
          setCompletedProps(form.completedProps);
          const withHash = window.location.href.split('#');
          const hash = withHash.length > 1 ? '#' + withHash[1] : '';
          const withQuery = withHash[0].split('?');
          const newUrl = withQuery[0] + '?mbid=1&perid=' + form.formGuid + hash;
          window.history.pushState({}, '', newUrl);
        }
      })
      .catch((err) => console.error(err)) // eslint-disable-line no-console
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (
      !(formState === FormStatus.Completed || applicationDeleted) ||
      !adminTable?.adminTableApiEndpointUrl ||
      !adminTable?.pageId
    )
      return;
    const url =
      adminTable.adminTableApiEndpointUrl + '?pageId=' + adminTable.pageId;
    fetch(url)
      .then((res) => res.json())
      .then((res) => setAdminTableState(res));
  }, [formState, applicationDeleted]);

  return (
    <div className="skjema-akkreditering-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <MainImage {...mainImage} />
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        <h1
          className={classNames({
            'epi-editContainer': header.titlePropertyName,
          })}
          data-epi-property-name={header.titlePropertyName}
        >
          {header.title}
        </h1>
      </Row>

      {/* INITIAL MODE */}
      {/* TODO WTM-69 do not render this if form.Status === Completed */}
      {header && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...header.mainIntro} theme={RichTextTheme.MainIntro} />
          {header.registrationExpiredText && (
            <h2 style={{ color: 'red' }}>{header.registrationExpiredText}</h2>
          )}
          {header.mainBody && <ContentArea {...header.mainBody} />}
        </Row>
      )}

      {unsubscribeModeProps && (
        <SkjemaAkkrediteringUnsubscribe {...unsubscribeModeProps} />
      )}

      {HasContent(rightContent) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={
            form ? VerticalPadding.xlarge : VerticalPadding.none
          }
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...rightContent} />
        </Row>
      )}

      {form &&
        (formState === FormStatus.Initial || FormStatus.InvalidDataPosted) && (
          <Row>
            <GreyRow
              width={GreyRowWidth.indented}
              verticalPadding={GreyRowVerticalPadding.large}
            >
              <h2>{form.title}</h2>
              <Form
                isLoading={isLoading}
                action={form.postUrl}
                buttonText={form.buttonText}
                formRef={inputEl}
                hideButton={true}
                ajaxHandler={ajaxHandler}
              >
                <input type="hidden" value={language} name="Language" />
                <InputWrapper
                  {...firstName}
                  setValue={setFirstNameVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...lastName}
                  setValue={setLastNameVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...dOfBirth}
                  setValue={setDateOfBirthVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...email}
                  setValue={setEmailVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...phone}
                  setValue={setPhoneVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...position}
                  setValue={setPositionVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...pOfWork}
                  setValue={setPOfWorkVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                <InputWrapper
                  {...notesObj}
                  setValue={setNotesVal}
                  forceDisabled={formState === FormStatus.Completed}
                />
                {/* {!completedProps && formState !== FormStatus.Completed && (
                  <InputWrapper
                    {...attachment}
                    setValue={setAttachmentVal}
                    forceDisabled={formState === FormStatus.Completed}
                  />
                )} */}
                {/* {adminMetaDataState?.attachmentsProps && (
                  <SkjemaAkkrediteringAttachments {...adminMetaDataState} />
                )} */}
                {adminMetaDataState && (
                  <div>
                    <SkjemaAkkrediteringChangedBy {...adminMetaDataState} />
                    {form.messageAfterPost}
                    <input
                      type="hidden"
                      name="ApplicationId"
                      value={adminMetaDataState.applicationId}
                    />
                  </div>
                )}
                {/* {completedProps &&
                  formState === FormStatus.Completed &&
                  attachmentFileNames && (
                    <div className="skjema-akkreditering-react__uploaded-file">
                      <div>
                        <h3>{completedProps.uploadedAttachmentsHeading}</h3>
                        {}
                        {attachmentFileNames.map((name, idx) => (
                          <>
                            <span key={idx}>{name}</span>
                            <br />
                          </>
                        ))}
                      </div>
                    </div>
                  )} */}
                {form.recaptcha?.recaptchaEnabled && (
                  <Recaptcha
                    terms={form.recaptcha?.recaptchaV3PrivacyAndTermsText}
                    tokenRef={recaptchaToken}
                  />
                )}
                {form.consent && (
                  <InputWrapper
                    {...consent}
                    setValue={setConsentVal}
                    forceDisabled={formState === FormStatus.Completed}
                  />
                )}
                <input type="hidden" value={form.pageId} name="pageid" />
                <div className="skjema-akkreditering-react__buttons">
                  {formState !== FormStatus.Completed && (
                    <Button text={form.buttonText} type="submit" />
                  )}
                  {adminMetaDataState?.deleteProps && (
                    <SkjemaAkkrediteringDeleteApplication
                      {...adminMetaDataState}
                      setDone={setApplicationDeleted}
                    />
                  )}
                </div>

                {formState === FormStatus.Completed && completedProps && (
                  <Row>
                    <div aria-live="polite">
                      {completedProps.feedbackOnWebAfterRegistration && (
                        <ContentArea
                          {...completedProps.feedbackOnWebAfterRegistration}
                        />
                      )}
                    </div>
                  </Row>
                )}

                {applicationDeleted && (
                  <h2>
                    {adminMetaDataState?.deleteProps?.deletedInformationText}
                  </h2>
                )}
              </Form>
            </GreyRow>
          </Row>
        )}
      <SkjemaAkkrediteringApplicationAdminTable {...adminTableState} />
    </div>
  );
};

const SkjemaAkkrediteringWrapper = (props: SkjemaAkkrediteringProps) =>
  props.form?.recaptcha?.recaptchaEnabled &&
  props.form.recaptcha.recaptchaV3SiteKey ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={props.form.recaptcha.recaptchaV3SiteKey}
      language="no"
    >
      <SkjemaAkkreditering {...props} />
    </GoogleReCaptchaProvider>
  ) : (
    <SkjemaAkkreditering {...props} />
  );

export default SkjemaAkkrediteringWrapper;
