import React from 'react';
import './SiteLayout.scss';
import { SiteLayoutProps } from '/Models/Generated/SiteLayoutProps';
import SiteFooter from '/Components/Layout/SiteFooter/SiteFooter';
import * as Components from '/App.Components';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import TopMenu from '/Components/Layout/TopMenu/TopMenu';
import ActionBar from '/Components/Layout/ActionBar/ActionBar';
import cn from 'classnames';
import DictionaryLookup from '/Components/Atoms/DictionaryLookup/DictionaryLookup';
import classNames from 'classnames';
import AlarmBanner from '../AlarmBanner/AlarmBanner';

const SiteLayout = ({
  child,
  dictionaryEndpoint,
  topMenu,
  actionBar,
  pageHeader,
  pageHeaderPropertyName,
  pageHeaderIndented,
  footer,
  epiFullRefreshPropertyNames,
  alarmBanner,
}: SiteLayoutProps) => {
  const Comp = Components[child.name];
  return (
    <div
      className={cn('site-layout', {
        'with-dictionary': dictionaryEndpoint,
      })}
    >
      <h1 className="site-layout__visually-hidden-h1">Stortinget.no</h1>
      {topMenu && <TopMenu {...topMenu} />}
      <main id="main-content">
        {actionBar && <ActionBar {...actionBar} />}
        <AlarmBanner {...alarmBanner} />
        {pageHeader && (
          <Row
            horizontalPadding={
              pageHeaderIndented
                ? HorizontalPadding.indent
                : HorizontalPadding.normal
            }
          >
            <h1
              className={classNames({
                'epi-editContainer': pageHeaderPropertyName,
              })}
              data-epi-property-name={pageHeaderPropertyName}
            >
              {pageHeader}
            </h1>
          </Row>
        )}
        {child && <Comp {...child.component} />}
      </main>
      {footer && <SiteFooter {...footer} />}
      {epiFullRefreshPropertyNames && (
        <input
          type="hidden"
          data-epi-full-refresh-property-names={epiFullRefreshPropertyNames.join(
            ','
          )}
        />
      )}
      {dictionaryEndpoint && (
        <DictionaryLookup dictionaryEndpoint={dictionaryEndpoint} />
      )}
    </div>
  );
};

export default SiteLayout;
