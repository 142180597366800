import React, { useEffect, useState } from 'react';
import './KomitePresidentskapet.scss';
import { KomitePresidentskapetProps } from '/Models/Generated/KomitePresidentskapetProps';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import useUrlParam from '/Shared/Code/Hooks/UseUrlParam';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import GreyRow, {
  GreyRowVerticalMarginBottom,
} from '/Components/Layout/GreyRow/GreyRow';
import FocusItem4 from '/Components/Organisms/FocusItem4/FocusItem4';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import Radiobuttons from '/Components/Atoms/Radiobuttons/Radiobuttons';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import FocusItemList from '/Components/Organisms/FocusItemList/FocusItemList';
import { FocusItemProps } from '/Components/../Models/Generated/FocusItemProps';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import Link from '/Components/Atoms/Link/Link';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';

const KomitePresidentskapet = ({
  pageTitle,
  mainIntro,
  mainBody,
  actualNewsHeader,
  focusItems,
  threeColumnContentAreaModules,
  dropDownForMobile,
  proceedingInCommitteeTab,
  committeeMembersTab,
  requestParameters,
  committeeProceedings,
  committeeMembersViewChoices,
  committeeMembers,
  mainImageOld,
  mainImage,
  newsArchiveLink,
}: KomitePresidentskapetProps) => {
  const [vervRows, setVervRows] = useState(committeeMembers.rows);
  const [vervColumns, setVervColumns] = useState(committeeMembers.columns);
  const [vervSortColumn, setVervSortColumn] = useState(0);
  const [vervSortDirection, setVervSortDirection] = useState(
    SortDirectionEnum.None
  );
  const isMounted = useComponentDidMount();

  const [checked, setChecked] = useUrlParam(
    requestParameters.requestParameterNames.forceLevel,
    (window &&
      SSRQueryStringReader.Read(
        requestParameters.requestParameterNames.forceLevel
      )) ??
      null
  );
  const radioButtonsOptions = [
    {
      label: committeeMembersViewChoices.todaysCompoundLabel,
      value: 'todaysCompound',
      checked:
        SSRQueryStringReader.Read(
          requestParameters.requestParameterNames.forceLevel
        ) === 'true' || !checked
          ? true
          : false,
      name: '',
    },
    {
      label: committeeMembersViewChoices.chooseParliamentPeriodLabel,
      value: 'parliamentPeriod',
      checked:
        SSRQueryStringReader.Read(
          requestParameters.requestParameterNames.forceLevel
        ) === 'false'
          ? true
          : false,
      name: '',
    },
  ];

  const [checkedView, setCheckedView] = useState(radioButtonsOptions);

  const [pid, setPid] = useUrlParam(
    requestParameters.requestParameterNames.parliamentPeriodId,
    (window &&
      SSRQueryStringReader.Read(
        requestParameters.requestParameterNames.parliamentPeriodId
      )) ??
      null
  );
  const [tab, setTab] = useUrlParam(
    requestParameters.requestParameterNames.tab,
    (window &&
      SSRQueryStringReader.Read(requestParameters.requestParameterNames.tab)) ??
      null
  );

  useEffect(() => {
    if (!isMounted) return;
    setChecked(checkedView.find((n) => n.checked)?.value === 'todaysCompound');
    window.location.reload();
  }, [checkedView, pid, vervColumns]);

  return (
    <div className="komite-presidentskapet-react">
      {(mainImage || mainImageOld) && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.medium}
        >
          {mainImageOld && <ImageWithCaption {...mainImageOld} />}
          {mainImage && <ImageWithCaption {...mainImage} />}
        </Row>
      )}
      <Row
        horizontalPadding={HorizontalPadding.indent}
        verticalPaddingBottom={VerticalPadding.none}
      >
        <h1>{pageTitle}</h1>
        {mainIntro && (
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        )}
      </Row>
      {threeColumnContentAreaModules.items.length > 0 && (
        <Row
          lineAbove
          lineBelow
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ContentArea {...threeColumnContentAreaModules} />
        </Row>
      )}
      <Row horizontalPadding={HorizontalPadding.indent}>
        <ContentArea {...mainBody} />
      </Row>
      {focusItems?.length > 0 && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.medium}
          className="komite-presidentskapet-react__focus-items"
        >
          <div className="komite-presidentskapet-react__focus-items__top">
            <GreyBar text={actualNewsHeader} />
            <FocusItemList
              items={focusItems.filter(
                (val: FocusItemProps) => val?.href !== null
              )}
            />
          </div>
          <Link
            href={newsArchiveLink.href}
            text={newsArchiveLink.text}
            langAttribute={newsArchiveLink.langAttribute}
          />
        </Row>
      )}

      <Row verticalPaddingBottom={VerticalPadding.large}>
        <GreyRow>
          <span id="primaryfilter"></span>
          <div className="komite-presidentskapet-react__filters">
            <div className="komite-presidentskapet-react__filters__tabs">
              <TabButtonStrip>
                <TabButton
                  text={proceedingInCommitteeTab.text}
                  selected={
                    tab === proceedingInCommitteeTab.parameterValue || !tab
                  }
                  onClick={() =>
                    setTab(proceedingInCommitteeTab.parameterValue)
                  }
                />
                <TabButton
                  text={committeeMembersTab.text}
                  selected={tab === committeeMembersTab.parameterValue}
                  onClick={() => setTab(committeeMembersTab.parameterValue)}
                />
              </TabButtonStrip>
            </div>
            <div className="komite-presidentskapet-react__filters__dropdown">
              <DropDownFilterWrapper
                halfwidth={true}
                label=""
                options={dropDownForMobile}
                selectedValue={!tab ? dropDownForMobile[0].value : tab}
                setSelected={setTab}
                id={committeeMembersTab.text}
                name={committeeMembersTab.text}
              />
            </div>
            {tab === committeeMembersTab.parameterValue && (
              <>
                <Radiobuttons
                  id={committeeMembersTab.text}
                  options={checkedView}
                  setOptions={setCheckedView}
                  label={committeeMembersViewChoices.chooseToSeeLabel}
                />

                <DropDownFilterWrapper
                  halfwidth={true}
                  disableIfOnlyOneOption={checkedView[0].checked}
                  label={
                    committeeMembersViewChoices.parliamentPeriodDropDown
                      .labelText
                  }
                  options={
                    committeeMembersViewChoices.parliamentPeriodDropDown.items
                  }
                  selectedValue={
                    pid
                      ? pid
                      : committeeMembersViewChoices.parliamentPeriodDropDown
                          .items[0].value
                  }
                  disabled={checkedView[0].checked}
                  setSelected={setPid}
                  id={
                    committeeMembersViewChoices.parliamentPeriodDropDown
                      .labelText
                  }
                  name={
                    committeeMembersViewChoices.parliamentPeriodDropDown
                      .labelText
                  }
                />
              </>
            )}
          </div>
        </GreyRow>
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {(tab === proceedingInCommitteeTab.parameterValue || !tab) && (
          <GreyBar
            verticalMarginBottom={GreyRowVerticalMarginBottom.medium}
            text={committeeProceedings.toProcessTitle}
          />
        )}

        {(tab === proceedingInCommitteeTab.parameterValue || !tab) && (
          <>
            {!committeeProceedings.showCommitteeSubscriptionLink &&
              committeeProceedings.committeeSubscriptionLink?.href && (
                <Link
                  href={committeeProceedings.committeeSubscriptionLink.href}
                  text={committeeProceedings.committeeSubscriptionLink.text}
                  langAttribute={
                    committeeProceedings.committeeSubscriptionLink.langAttribute
                  }
                />
              )}
            {committeeProceedings.committeeProceedingOverview
              .committeeProceedingOverviewInProcessList.length ? (
              <ul className="komite-presidentskapet-react__list">
                {committeeProceedings.committeeProceedingOverview.committeeProceedingOverviewInProcessList.map(
                  (items, itemId) => (
                    <FocusItem4
                      key={itemId}
                      proccedingLink={items.proccedingLink}
                      formalTitle={items.formalTitle}
                      abstract={items.abstract}
                      department={items.department}
                      shortDateString={items.shortDateString}
                      beforeDateString={items.beforeDateString}
                      proceedingTypeName={items.proceedingTypeName}
                    />
                  )
                )}
              </ul>
            ) : (
              committeeProceedings.committeeProceedingOverview
                .emptyListMessageInprocess
            )}
            <Link
              className="komite-presidentskapet-react__link"
              href={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsInProcess.href
              }
              text={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsInProcess.text
              }
              langAttribute={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsInProcess.langAttribute
              }
            />
          </>
        )}

        {tab === committeeMembersTab.parameterValue && (
          <SortableTable
            {...committeeMembers}
            setColumns={setVervColumns}
            setRows={setVervRows}
            rows={vervRows}
            columns={vervColumns}
            setSortDirection={setVervSortDirection}
            sortDirection={vervSortDirection}
            setSortColumn={setVervSortColumn}
            sortColumn={vervSortColumn}
          />
        )}
        {(tab === proceedingInCommitteeTab.parameterValue || !tab) && (
          <Row>
            <GreyBar
              verticalMarginBottom={GreyRowVerticalMarginBottom.medium}
              text={committeeProceedings.handledTitle}
            />

            <Link
              href={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsProcessed?.href
              }
              text={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsProcessed?.text
              }
              langAttribute={
                committeeProceedings.committeeProceedingOverview
                  .linkToProceedingsProcessed?.langAttribute
              }
            />
          </Row>
        )}
      </Row>
    </div>
  );
};

export default KomitePresidentskapet;
