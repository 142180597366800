import React from 'react';
import './DokumentForsideHeader.scss';
import { DokumentForsideHeaderProps } from '/Models/Generated/DokumentForsideHeaderProps';
import RelatedLinksBox from '/Components/Organisms/RelatedLinksBox/RelatedLinksBox';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';

const DokumentForsideHeader = ({
  detteDokumentLabel,
  documentTitle,
  sourcedocLabel,
  sourceDocumentReference,
  documentDateLabel,
  documentDate,
  publisherLabel,
  publisher,
  pagecountLabel,
  pageCount,
  pdfLabel,
  pdf,
  relatedProceeding,
  relatedTopics,
}: DokumentForsideHeaderProps) => {
  return (
    <div className="dokumentforside-header">
      <ColumnContentWrapper title={detteDokumentLabel}>
        <ul className="dokumentforside-header__this-document">
          {documentTitle && <li>{documentTitle}</li>}
          {sourceDocumentReference && (
            <li>
              <b>{sourcedocLabel}:</b>{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: sourceDocumentReference,
                }}
              ></span>
            </li>
          )}
          {documentDate && (
            <li>
              <b>{documentDateLabel}:</b> {documentDate}
            </li>
          )}
          {publisher && (
            <li>
              <b>{publisherLabel}:</b> {publisher}
            </li>
          )}
          {pageCount && (
            <li>
              <b>{pagecountLabel}:</b> {pageCount}
            </li>
          )}
          {pdf && (
            <li className="dokumentforside-header__this-document__pdf">
              <a href={pdf}>{pdfLabel}</a>
            </li>
          )}
        </ul>
      </ColumnContentWrapper>
      <RelatedLinksBox
        className="dokumentforside-header__related-proceedings"
        {...relatedProceeding}
      />
      <RelatedLinksBox {...relatedTopics} />
    </div>
  );
};

export default DokumentForsideHeader;
