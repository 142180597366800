import React, { useState, useRef, useEffect } from 'react';
import './SearchInput.scss';
import cn from 'classnames';
import useDebounce from '/Shared/Code/Hooks/UseDebounce';

interface Props {
  doSearch: () => void;
  doAutocomplete?: (term: string, limit: number) => Promise<string[]>;
  placeholder: string;
  setQuery: (term: string) => void;
  query: string;
  loading: boolean;
  setFocus?: boolean;
  fillContainer?: boolean;
  enableClear?: boolean;
  searchIcon?: boolean;
  id?: string;
  maxLength?: number;
  ariaLabelClearButton?: string;
}

const SearchInput = (props: Props) => {
  const { doSearch, doAutocomplete, placeholder, setQuery, query, loading } =
    props;

  const [highlight, setHighlight] = useState('');
  const [autocomplete, setAutocomplete] = useState([]);
  const [autocompleteIdx, setAutocompleteIdx] = useState(-1);
  const autocompleteEnabled = useRef(false);
  const focusSet = useRef(false);
  const input = useRef(null);

  const clearAutocomplete = () => {
    autocompleteEnabled.current = false;
    if (autocompleteIdx !== -1) setAutocompleteIdx(-1);
    if (autocomplete.length) setAutocomplete([]);
  };

  if (loading) clearAutocomplete();

  const debouncedAutocomplete = useDebounce(
    (val) =>
      doAutocomplete &&
      doAutocomplete(val, 10).then((res) => {
        if (autocompleteEnabled.current) {
          setHighlight(val);
          setAutocomplete(res);
        }
      }),
    500
  );

  const handleChange = (event) => {
    autocompleteEnabled.current = true;
    const val = event.target.value;
    setAutocompleteIdx(-1);
    setQuery(val);
    debouncedAutocomplete(val);
  };

  const highlightSuggestion = (text: string) => {
    //fix - for site crashing when search with parentheses
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedHighlight, 'g');
    return text.replace(regex, `<b>${highlight}</b>`);
  };

  const getNewAutocompleteIdx = (key: string): number => {
    const length = autocomplete.length;
    if (!length) return null;
    return key === 'ArrowDown'
      ? (autocompleteIdx + 1) % length
      : key === 'ArrowUp'
      ? (Math.max(autocompleteIdx - 1, -1) + length) % length
      : null;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      e.target.setAttribute('readonly', 'readonly');
      setTimeout(function () {
        e.target.removeAttribute('readonly');
      }, 100);
      doSearch();
      clearAutocomplete();
    }
    const newIdx = getNewAutocompleteIdx(e.code);
    if (newIdx === null) return;
    setAutocompleteIdx(newIdx);
    setQuery(autocomplete[newIdx]);
  };

  const updateSearchField = (searchText) => {
    setQuery(searchText);
    doSearch();
    clearAutocomplete();
  };

  useEffect(() => {
    if (props.setFocus && !focusSet.current) input.current.focus();
    focusSet.current = props.setFocus;
  });

  return (
    <div
      className={cn('search-input-react', {
        'fill-container-react': props.fillContainer,
        icon: props.searchIcon,
      })}
      role="search"
    >
      {props.searchIcon && (
        <span className="search-input-react__search-icon"></span>
      )}
      <input
        name="search"
        type="search"
        enterKeyHint="search"
        autoComplete="off"
        placeholder={placeholder}
        value={query ?? ''}
        autoFocus
        onChange={handleChange}
        onKeyDown={(e) => handleKeyDown(e)}
        aria-label="search input"
        disabled={loading}
        role="searchbox"
        ref={(searchInput) => (input.current = searchInput)}
        className={cn('search-input-text-react', {
          icon: props.searchIcon,
        })}
        id={props.id}
        maxLength={props.maxLength}
      />
      {props.enableClear && query && (
        <button
          className="search-input-react__clear"
          aria-label={props.ariaLabelClearButton}
          onClick={() => {
            setQuery('');
            input.current.focus();
          }}
        ></button>
      )}
      {autocomplete?.length > 0 && (
        <ul className="suggestions-react">
          {autocomplete.map((searchText, idx) => (
            <li
              key={idx}
              className={cn('suggestion-react', {
                'selected-react': idx === autocompleteIdx,
              })}
            >
              <a
                onClick={() => updateSearchField(searchText)}
                dangerouslySetInnerHTML={{
                  __html: highlightSuggestion(searchText),
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchInput;
