import React from 'react';
import './SearchForm.scss';
import { SearchFormProps } from '/Models/Generated/SearchFormProps';
import SearchClient from '/Shared/Code/Search/SearchClient';
import SearchInput from '/Components/Atoms/SearchInput/SearchInput';
import Button from '/Components/Atoms/Button/Button';
import cn from 'classnames';

export interface Props extends SearchFormProps {
  client: SearchClient;
  setQuery: (term: string) => void;
  doSearch: () => void;
  query: string;
  loading: boolean;
  setFocus?: boolean;
}

const SearchForm = (props: Props) => {
  const {
    doSearch,
    setQuery,
    query,
    loading,
    client,
    helpLinkHref,
    helpLinkLabel,
    buttonLabel,
    setFocus,
  } = props;
  return (
    <div
      className={cn('search-form-react', {
        'with-search-hints-react': helpLinkHref,
      })}
    >
      <div className="search-input-container-react">
        {helpLinkHref && (
          <div className="search-form-hints">
            <a href={helpLinkHref}>{helpLinkLabel}</a>
          </div>
        )}
        <SearchInput
          {...props}
          doAutocomplete={(term: string, limit: number) =>
            client.Autocomplete(term, limit)
          }
          doSearch={doSearch}
          setQuery={setQuery}
          fillContainer={true}
          query={query}
          loading={loading}
          setFocus={setFocus}
        />
      </div>
      <div>
        <Button
          text={buttonLabel}
          onClick={(e) => {
            e.preventDefault();
            doSearch();
          }}
        />
      </div>
    </div>
  );
};

export default SearchForm;
