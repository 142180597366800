import React from 'react';
import './ResultBiographyRepresentative.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultPublished from './Shared/ResultPublished';
import ResultStatus from './Shared/ResultStatus';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useSelector } from 'react-redux';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultBiographyRepresentative = ({
  title,
  summary,
  published,
  url,
  specifics,
}: Props) => {
  const labels = useSelector((state: RootState) => state.Search.labels);
  return (
    <article className="search-result-biography-representative-article-react">
      <h2>
        {labels.biography}
        <a href={url}>
          <span dangerouslySetInnerHTML={{ __html: title }}></span>
        </a>
      </h2>
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      <ResultSummary summary={summary} />
    </article>
  );
};

const ResultBiographyRepresentativeWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultBiographyRepresentative {...props} />
  </Provider>
);

export default ResultBiographyRepresentativeWrapper;
