import React from 'react';
import './SearchByCategory.scss';
import { Row } from '/App.Components';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import SearchClient from '/Shared/Code/Search/SearchClient';
import IconButton, { Icon } from '/Components/Atoms/IconButton/IconButton';
import {
  setCategoryId,
  setDoSearch,
  setFilter,
} from '/Shared/Code/Search/SearchSlice';

const SearchByCategory = () => {
  const filter = useSelector((s: RootState) => s.Search.filter).split('+');
  const labels = useSelector((s: RootState) => s.Search.labels);
  const dispatch = useDispatch();
  const categorySearchLabel = filter.includes(SearchClient.groupPosts)
    ? labels.searchingForPosts
    : filter.includes(SearchClient.groupQuestions)
    ? labels.searchingForQuestions
    : filter.includes(SearchClient.groupSuggestions)
    ? labels.searchingForSuggestions
    : null;
  return categorySearchLabel ? (
    <Row className="search-by-category-react">
      <IconButton
        icon={Icon.Close}
        text={categorySearchLabel}
        onClick={(e) => {
          e.preventDefault();
          const newFilters = filter
            .filter(
              (f) =>
                ![
                  SearchClient.groupPosts,
                  SearchClient.groupQuestions,
                  SearchClient.groupSuggestions,
                ].includes(f)
            )
            .join('+');
          dispatch(setFilter(newFilters));
          dispatch(setCategoryId(null));
          dispatch(setDoSearch(true));
        }}
      />
    </Row>
  ) : (
    <></>
  );
};

const SearchByCategoryWrapper = () => (
  <Provider store={SearchStore}>
    <SearchByCategory />
  </Provider>
);

export default SearchByCategoryWrapper;
