import React, { useEffect } from 'react';
import './SearchFilterMobile.scss';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import cn from 'classnames';
import { GlobalSearchFilterProps } from '/Models/Generated/GlobalSearchFilterProps';
import Button, { ButtonTheme } from '/Components/Atoms/Button/Button';
import SelectedFilters from './SelectedFilters';
import Radiobuttons, {
  CheckboxOption,
} from '/Components/Atoms/Radiobuttons/Radiobuttons';
import CalendarWrapper from './Calendar/CalendarWrapper';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { LinkProps } from '/Models/Generated/LinkProps';
import { SearchCategoryProps } from '/Models/Generated/SearchCategoryProps';

interface Props extends GlobalSearchFilterProps {
  setSelectedFilters: (e) => void;
  selectedFilters: object;
  setActiveTab: (e) => void;
  activeTab: string;
  setSelectedDates: (e) => void;
  selectedDates: object;
  handleTabChange: (e) => void;
  setMobileSearchOpen: (e) => void;
  mobileSearchOpen: boolean;
  setIsHiddenAfterTransition: (e) => void;
  isHiddenAfterTransition: boolean;
  totalItems: number;
  fromDate: string;
  setFromDate: (e) => void;
  toDate: string;
  setToDate: (e) => void;
  toSesjon: string;
  setToSesjon: (e) => void;
  fromSesjon: string;
  setFromSesjon: (e) => void;
  checkedView: CheckboxOption[];
  setCheckedView: (e) => void;
  language: string;
  todayLabel: string;
  searchTipLink: LinkProps;
  categories: SearchCategoryProps[];
}

const SearchFilterMobile = ({
  label,
  categories,
  selectedFilters,
  setSelectedFilters,
  activeTab,
  setActiveTab,
  handleTabChange,
  setMobileSearchOpen,
  mobileSearchOpen,
  setIsHiddenAfterTransition,
  isHiddenAfterTransition,
  noChosenFiltersLabel,
  chosenFiltersLabel,
  clearFiltersLabel,
  backToSearchLabel,
  showResultsLabel,
  totalItems,
  selectedDates,
  setSelectedDates,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  toSesjon,
  setToSesjon,
  fromSesjon,
  setFromSesjon,
  checkedView,
  setCheckedView,
  todayLabel,
  language,
  searchTipLink,
}: Props) => {
  const tidsrom = categories[0];
  const fromLabel = tidsrom.items[0].subTitle;
  const toLabel = tidsrom.items[1].subTitle;

  const allArraysEmpty = Object.values(selectedFilters).every(
    (array) => Array.isArray(array) && array.length === 0
  );

  const onTransitionEnd = () => {
    if (!isHiddenAfterTransition) setIsHiddenAfterTransition(true);
  };

  const toggleOverflow = () => {
    const shouldActivate = mobileSearchOpen && window.innerWidth < 1024;
    document.body.style.maxHeight = shouldActivate ? '100vh' : '';
    document.body.style.overflow = shouldActivate ? 'hidden' : '';
    if (shouldActivate) {
      const currentScrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      if (currentScrollPosition > 0) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    toggleOverflow();

    const handleResize = () => {
      toggleOverflow();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mobileSearchOpen]);

  return (
    <div
      className={cn('search-filter-mobile', {
        open: mobileSearchOpen || activeTab,
      })}
    >
      {!mobileSearchOpen && !activeTab && (
        <div className="search-filter-mobile__open-button">
          <button
            onClick={() => {
              setMobileSearchOpen(!mobileSearchOpen);
            }}
          >
            <span className="search-filter-mobile__open-button__icon"></span>
            {label}
          </button>
          {searchTipLink && (
            <div className="search-filter-mobile__open-button__tip">
              <Link
                {...searchTipLink}
                theme={LinkTheme.sans}
                className="search-filter-mobile__open-button__tip__link"
              />
            </div>
          )}
        </div>
      )}
      <div
        className={cn('search-filter-mobile__overlay', {
          open: mobileSearchOpen || activeTab,
          'hide-links': isHiddenAfterTransition && !mobileSearchOpen,
          'show-links': isHiddenAfterTransition && mobileSearchOpen,
        })}
        onTransitionEnd={onTransitionEnd}
      >
        <div className={'search-filter-mobile__overlay__wrapper'}>
          <div className="search-filter-mobile__overlay__heading">
            <span className="search-filter-mobile__overlay__heading__title">
              {label}
            </span>
            <button
              aria-label={language === 'no' ? 'Lukk filter' : 'Close filter'}
              onClick={() => {
                setMobileSearchOpen(!mobileSearchOpen);
                setActiveTab('');
              }}
            ></button>
          </div>
          {!activeTab ? (
            <div className="search-filter-mobile__overlay__list">
              <ul className="search-filter-mobile__overlay__list__ul">
                {categories.map((category, idx) => (
                  <li
                    key={idx}
                    className="search-filter-mobile__overlay__list__ul__wrapper"
                  >
                    <button
                      className={cn(
                        'search-filter-mobile__overlay__list__ul__wrapper__button',
                        {
                          active: category.items.find(
                            (item) => selectedFilters[item.queryKey]?.length > 0
                          ),
                        }
                      )}
                      onClick={() => handleTabChange(category.title)}
                    >
                      {category.items.length > 1 &&
                        (() => {
                          if (category.queryKeyFromDate) {
                            //If category is datefilter
                            return Object.values(selectedDates).every(
                              (x) => x === null
                            ) ? (
                              category.title
                            ) : (
                              <b>{category.title}</b>
                            );
                          }

                          for (let i = 0; i < category.items.length; i++) {
                            if (
                              category.items[i].queryKey &&
                              selectedFilters[category.items[i].queryKey]
                                ?.length > 0
                            ) {
                              return <b>{category.title}</b>;
                            }
                          }
                          return category.title;
                        })()}
                      {category.items.length === 1 &&
                        category.items.map((item, idx) => (
                          <React.Fragment key={idx}>
                            {item.queryKey &&
                            selectedFilters[item.queryKey]?.length > 0 ? (
                              <b>{category.title}</b>
                            ) : (
                              category.title
                            )}
                          </React.Fragment>
                        ))}
                    </button>
                  </li>
                ))}
              </ul>
              <SelectedFilters
                setActiveTab={setActiveTab}
                setMobileSearchOpen={setMobileSearchOpen}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                categories={categories}
                noChosenFiltersLabel={noChosenFiltersLabel}
                chosenFiltersLabel={chosenFiltersLabel}
                clearFiltersLabel={clearFiltersLabel}
              />
            </div>
          ) : (
            <div className="search-filter-mobile__overlay__selected-cat">
              <button
                className="search-filter-mobile__overlay__selected-cat__button"
                onClick={() => handleTabChange(activeTab)}
              >
                {activeTab}
              </button>
              <div className="search-filter-mobile__overlay__selected-cat__shadow" />
            </div>
          )}
          <div
            className={cn('search-filter-mobile__items', {
              active: mobileSearchOpen && activeTab,
            })}
          >
            <div
              className={cn('search-filter-mobile__items__content', {
                active: tidsrom.title === activeTab,
              })}
            >
              {language === 'no' && (
                <Radiobuttons
                  id={tidsrom.title + '-mobile'}
                  options={checkedView}
                  setOptions={setCheckedView}
                />
              )}
              {checkedView[0].checked ? (
                <div className="search-filter-mobile__items__content__date-selector-wrapper">
                  <DropDownFilterWrapper
                    options={tidsrom.items[0].choicesDropDown}
                    label={fromLabel || 'Fra'}
                    id={'from-sesjon-dropdown-mobile'}
                    name={
                      language === 'no'
                        ? `Åpne ${fromLabel} sesjon`
                        : `Open ${fromLabel} period`
                    }
                    selectedValue={fromSesjon}
                    setSelected={setFromSesjon}
                  />
                  <DropDownFilterWrapper
                    options={tidsrom.items[1].choicesDropDown}
                    label={toLabel || 'Til'}
                    id={'to-sesjon-dropdown-mobile'}
                    name={
                      language === 'no'
                        ? `Åpne ${toLabel} sesjon`
                        : `Open ${toLabel} period`
                    }
                    selectedValue={toSesjon}
                    setSelected={setToSesjon}
                  />
                </div>
              ) : (
                <div className="search-filter-mobile__items__content__date-selector-wrapper">
                  <CalendarWrapper
                    label={fromLabel || 'Fra'}
                    id={''}
                    language={language}
                    minDate={
                      tidsrom.items[0].choicesDropDown[
                        tidsrom.items[0].choicesDropDown.length - 1
                      ].value
                    }
                    maxDate={toDate}
                    setValue={setFromDate}
                    value={fromDate}
                    todayLabel={todayLabel || 'I dag'}
                  />
                  <CalendarWrapper
                    label={toLabel || 'Til'}
                    id={''}
                    language={language}
                    minDate={fromDate}
                    setValue={setToDate}
                    value={toDate}
                    todayLabel={todayLabel || 'I dag'}
                  />
                </div>
              )}
            </div>
            {categories.slice(1).map((category, idx) => (
              <div
                key={idx}
                className={cn('search-filter-mobile__items__content', {
                  active: category.title === activeTab,
                })}
              >
                {category.items.map((item, idx) => (
                  <div
                    className="search-filter-mobile__items__content__choices"
                    key={idx}
                  >
                    {item.subTitle && (
                      <div className="search-filter-mobile__items__content__choices__subtitle">
                        {item.subTitle}
                      </div>
                    )}
                    <div className="search-filter-mobile__items__content__choices__wrapper">
                      {item.choices.map((choice, idx) => (
                        <Checkbox
                          key={idx}
                          id={`${category.title}-${choice.label}${idx}-mobile`}
                          greyedOut={choice.count === 0}
                          setChecked={() => {
                            setSelectedFilters((prevFilters) => {
                              const updatedFilters = { ...prevFilters };
                              const currentCategoryFilters =
                                updatedFilters[item.queryKey] || [];

                              if (
                                currentCategoryFilters.includes(choice.value)
                              ) {
                                updatedFilters[item.queryKey] =
                                  currentCategoryFilters.filter(
                                    (item) => item !== choice.value
                                  );
                              } else {
                                if (choice.isAllOption) {
                                  //If selected choise is allOption, set that as the only value for that category
                                  updatedFilters[item.queryKey] = [
                                    choice.value,
                                  ];
                                } else {
                                  const allOption = item.choices[0].isAllOption
                                    ? item.choices[0]
                                    : null;

                                  if (
                                    //Check if allOption is selected for current category
                                    allOption &&
                                    currentCategoryFilters.includes(
                                      allOption.value
                                    )
                                  ) {
                                    //Remove allOption
                                    currentCategoryFilters.splice(
                                      currentCategoryFilters.indexOf(
                                        allOption.value
                                      ),
                                      1
                                    );
                                  }
                                  //Add selected choice
                                  updatedFilters[item.queryKey] = [
                                    ...currentCategoryFilters,
                                    choice.value,
                                  ];
                                }
                              }

                              return updatedFilters;
                            });
                          }}
                          checked={selectedFilters[item.queryKey]?.includes(
                            choice.value
                          )}
                          text={`${choice.label} <b>(${choice.count})</b>`}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <SelectedFilters
              setActiveTab={setActiveTab}
              setMobileSearchOpen={setMobileSearchOpen}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              categories={categories}
              noChosenFiltersLabel={noChosenFiltersLabel}
              chosenFiltersLabel={chosenFiltersLabel}
              clearFiltersLabel={clearFiltersLabel}
            />
          </div>
          <div className="search-filter-mobile__overlay__wrapper__button">
            {mobileSearchOpen && activeTab && (
              <Button
                text={
                  allArraysEmpty
                    ? backToSearchLabel
                    : `${showResultsLabel} (${totalItems})`
                }
                onClick={() => {
                  setMobileSearchOpen(!mobileSearchOpen);
                  setActiveTab('');
                }}
                theme={ButtonTheme.Blue}
                fullWidth
              />
            )}
          </div>
        </div>
        <div className="search-filter-mobile__button">
          {mobileSearchOpen && !activeTab && (
            <Button
              text={
                allArraysEmpty
                  ? backToSearchLabel
                  : `${showResultsLabel} (${totalItems})`
              }
              onClick={() => {
                setMobileSearchOpen(!mobileSearchOpen);
                if (isHiddenAfterTransition) {
                  setIsHiddenAfterTransition(false);
                }
              }}
              theme={ButtonTheme.Blue}
              fullWidth
            />
          )}
        </div>
      </div>
      {!mobileSearchOpen && !activeTab && (
        <SelectedFilters
          setActiveTab={setActiveTab}
          setMobileSearchOpen={setMobileSearchOpen}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          categories={categories}
          noChosenFiltersLabel={noChosenFiltersLabel}
          chosenFiltersLabel={chosenFiltersLabel}
          clearFiltersLabel={clearFiltersLabel}
        />
      )}
    </div>
  );
};

export default SearchFilterMobile;
