import classNames from 'classnames';
import React from 'react';
import Button from '/Components/Atoms/Button/Button';
import './FocusItem.scss';
import { FocusItemProps } from '/Models/Generated/FocusItemProps';

interface Props extends FocusItemProps {
  headerTag?: undefined | null | 'h2' | 'h3';
}

const FocusItem = ({
  hasImage,
  imageHref,
  imageAltText,
  imageTitle,
  href,
  subtitle,
  introTextHtml,
  text,
  publishDate,
  deadlineLabel,
  deadlineText,
  registrationLink,
  headerTag,
}: Props) => {
  const HTag = (headerTag ?? 'h2') as keyof JSX.IntrinsicElements;
  return (
    <article className="focus-item-react">
      <a href={href} className="focus-item-react__link">
        {hasImage && (
          <img
            src={imageHref}
            alt={imageAltText}
            title={imageAltText === imageTitle ? '' : imageTitle}
            className="focus-item-react__image"
          />
        )}
        <div
          className={classNames('focus-item-react__header', {
            'focus-item-react__header--indented': hasImage,
          })}
        >
          <HTag className="focus-item-react__header__tag">
            <span>{text}</span>
          </HTag>
        </div>
      </a>
      <div
        className={classNames('focus-item-react__text', {
          'focus-item-react__text--indented': hasImage,
        })}
      >
        <p>{subtitle}</p>
        <div dangerouslySetInnerHTML={{ __html: introTextHtml }} />
        {publishDate && (
          <p>
            <time className="focus-item-react__publish-date">
              ({publishDate})
            </time>
          </p>
        )}
        {deadlineText && (
          <p className="focus-item-react__deadline">
            {deadlineLabel && <strong>{deadlineLabel}&nbsp;</strong>}
            <time>{deadlineText}</time>
          </p>
        )}
        {registrationLink && (
          <Button href={registrationLink.href} text={registrationLink.text} />
        )}
      </div>
    </article>
  );
};

export default FocusItem;
