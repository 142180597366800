import React, { useEffect, useRef, useState } from 'react';
import './InputWrapper.scss';
import { randomId } from '/Shared/Code/Helpers/RandomId';
import * as Components from '/App.Components';
import { FormInputProps } from '/Models/Generated/FormInputProps';
import { InputTypes } from '/Models/Generated/InputTypes';
const ValidationError = Components['ValidationError'];

export interface InputWrapperProps {
  inputType: InputTypes;
  inputProps: FormInputProps;
  setValue?: (val) => void;
  value?: string | undefined | null;
  backendErrorMessage?: string;
  forceDisabled?: boolean;
}

const InputWrapper = ({
  inputType,
  inputProps,
  setValue,
  value,
  backendErrorMessage,
  forceDisabled,
}: InputWrapperProps) => {
  const mapped = {
    TextInput: 'TextInput',
    EmailInput: 'EmailInput',
    DateInput: 'DateInput',
    FileInput: 'FileInput',
    TextArea: 'TextArea',
    Select: 'DropdownWrapper',
    Dropdown: 'DropdownWrapper',
    Checkbox: 'CheckboxWrapper',
  }[InputTypes[inputType]];
  if (!inputProps) return <></>;

  const InputComp = Components[mapped];
  const [errorMessage, setErrorMessage] = useState('');
  const label = inputProps?.label;

  const inputId = useRef((inputProps.name ?? randomId()) + '_forminput'); // id for label
  const errorMessageId = useRef((inputProps.name ?? randomId()) + '_formerror'); // id for error message for accessibility reasons (aria-describedBy)

  useEffect(() => {
    if (setValue) setValue(inputProps.defaultValue);
  }, []);

  useEffect(() => {
    if (backendErrorMessage) setErrorMessage(backendErrorMessage);
  }, [backendErrorMessage]);

  return inputType ? (
    <div className="input-wrapper-react">
      {label && <label htmlFor={inputId.current}>{label}</label>}
      <InputComp
        {...inputProps}
        defaultValue={setValue ? undefined : inputProps.defaultValue}
        id={inputId.current}
        setErrorMessage={setErrorMessage}
        isInvalid={!!errorMessage?.length}
        errorMessageId={!!errorMessage?.length ? errorMessageId.current : null}
        value={setValue ? value ?? inputProps?.selectedValue ?? '' : undefined}
        onChange={setValue}
        // for checkbox
        setChecked={setValue}
        readonly={inputProps?.readonly}
        disabled={forceDisabled}
      />
      {errorMessage && (
        <ValidationError text={errorMessage} id={errorMessageId.current} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default InputWrapper;
