import React, { useEffect } from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Link from '/Components/Atoms/Link/Link';
import './EPOSReferatsakliste.scss';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { EPOSReferatsaklisteProps } from '/Models/Generated/EPOSReferatsaklisteProps';

const EPOSReferatsakliste = ({
  mainIntro,
  mainBody,
  draftProceedingList,
}: EPOSReferatsaklisteProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);
  return (
    <PageWrapper>
      <div className="epos-referatsakliste-react">
        {mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <div className="epos-referatsakliste-react__main-intro">
              <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
            </div>
          </Row>
        )}
        {mainBody && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea {...mainBody} />
          </Row>
        )}
        {draftProceedingList && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <div>
              <div className="epos-referatsakliste-react__heading">
                {draftProceedingList.meetingTitleText &&
                  draftProceedingList.meetingTitleText}
                {draftProceedingList.meetingAgendaLink && (
                  <>
                    <br />
                    <Link
                      text={draftProceedingList.meetingAgendaLink.text}
                      href={draftProceedingList.meetingAgendaLink.href}
                    />
                  </>
                )}
              </div>
            </div>
            {draftProceedingList.items && (
              <ul className="epos-referatsakliste-react__list">
                {draftProceedingList.items.map((item, idx) => (
                  <li
                    key={idx}
                    className="epos-referatsakliste-react__list__item"
                  >
                    {item.draftItemNumberText && (
                      <h2 className="epos-referatsakliste-react__list__item__title">
                        {item.draftItemNumberText}
                      </h2>
                    )}
                    {item.draftItemPrefixText && (
                      <div className="epos-referatsakliste-react__list__item__text">
                        <h3 className="epos-referatsakliste-react__list__item__text__h3">
                          {item.draftItemPrefixText}
                        </h3>
                      </div>
                    )}
                    {item.subItems && (
                      <ul className="epos-referatsakliste-react__list__item__sublist">
                        {item.subItems.map((subitem, subidx) => (
                          <li key={subidx}>
                            {subitem.titleText && subitem.referenceText && (
                              <div className="epos-referatsakliste-react__list__item__text">
                                {subitem.titleText +
                                  ' ' +
                                  subitem.referenceText}
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                    {item.draftItemTitleText && (
                      <div className="epos-referatsakliste-react__list__item__text">
                        {item.draftItemTitleText}
                      </div>
                    )}
                    {item.draftItemReferenceText && (
                      <div className="epos-referatsakliste-react__list__item__text">
                        {item.draftItemReferenceText}
                      </div>
                    )}

                    {item.draftItemPostfixText && (
                      <div className="epos-referatsakliste-react__list__item__text">
                        <h3 className="epos-referatsakliste-react__list__item__text__h3__sans">
                          {item.draftItemPostfixText}
                        </h3>
                      </div>
                    )}
                    {item.draftItemDecisionText && (
                      <div className="epos-referatsakliste-react__list__item__subtitle">
                        {item.draftItemDecisionText}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSReferatsakliste;
