import React, { useEffect, useState } from 'react';
import VideoClipEmbedded from './VideoClipEmbedded';
import VideoClipTimePicker from './VideoClipTimePicker';
import './VideoClipBlock.scss';
import { ContentArea, ValidationError } from '../../../App.Components';
import { RichTextTheme } from '../../Organisms/RichText/RichText';
import { VideoClipEditorProps } from '../../../Models/Generated/VideoClipEditorProps';
import { VideoClipFormSubmitProps } from '../../../Models/Generated/VideoClipFormSubmitProps';

interface Props {
  videoClipEditor: VideoClipEditorProps;
  videoClipFormSubmit: VideoClipFormSubmitProps;
  qbrickVideo;

  playerPosition: number;
  setPlayerPosition;
  visualPlayerPosition: number;
  setVisualPlayerPosition;

  videoDuration: number;
  setVideoDuration;
  clipStart: number;
  setClipStart;
  clipEnd: number;
  setClipEnd;

  paused: boolean;
  setPaused;

  validClipStart: boolean;
  setValidClipStart;
  validClipEnd: boolean;
  setValidClipEnd;
}

const secondsToDisplayTime = (
  totalSeconds: number,
  rawInput: string
): string => {
  const hoursReminder = totalSeconds % 3600;
  const hoursSeconds = totalSeconds - hoursReminder;
  const hours = hoursSeconds / 3600;

  const minutesReminder = hoursReminder % 60;
  const minutesSeconds = hoursReminder - minutesReminder;
  const minutes = minutesSeconds / 60;

  const seconds = minutesReminder;

  let hoursString = hours ? (hours + ':').padStart(3, '0') : '';

  if (hours && hours < 10 && rawInput.length < '00:00:00'.length) {
    hoursString = (hours + ':').padStart(2, '0');
  }

  const minutesString = (minutes + ':').padStart(3, '0');
  const secondsString = ('' + Math.floor(seconds)).padStart(2, '0');

  return hoursString + minutesString + secondsString;
};

const displayTimeToSeconds = (displayTime: string): number => {
  const timeParts = displayTime.split(':');

  if (timeParts.length === 3) {
    return (
      parseInt(timeParts[0]) * 60 * 60 +
      parseInt(timeParts[1]) * 60 +
      parseInt(timeParts[2])
    );
  }

  return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
};

const isReallyNumber = (data) => {
  return typeof data === 'number' && !isNaN(data);
};

const validTimeInput = (timeInput: string): boolean => {
  const timeParts = timeInput.split(':');
  if (timeParts.length > 3 || timeParts.length < 2) {
    return false;
  }

  if (timeParts.length === 2) {
    if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
      return false;
    }
  }

  if (timeParts.length === 3) {
    if (timeParts[0].length !== 1 && timeParts[0].length !== 2) {
      return false;
    }
    if (timeParts[0].length === 1 && parseInt(timeParts[0]) === 0) {
      return false;
    }
    if (timeParts[1].length !== 2 || timeParts[2].length !== 2) {
      return false;
    }
  }

  for (let i = 0; i < timeParts.length; i++) {
    const element = timeParts[i];

    if (!isReallyNumber(parseInt(element))) {
      return false;
    }
  }

  return true;
};

const VideoClipBlock = (props: Props) => {
  const [clipStartInput, setClipStartInput] = useState(
    secondsToDisplayTime(props.clipStart, '')
  );
  const [clipEndInput, setClipEndInput] = useState(
    secondsToDisplayTime(props.clipStart, '')
  );

  const handleclipStartInput = (event) => {
    const input = event.target.value;
    setClipStartInput(input);
    if (!validTimeInput(input)) {
      props.setValidClipStart(false);
      return;
    }

    let seconds = displayTimeToSeconds(input);
    if (seconds < 0) {
      seconds = 0;
    }
    if (seconds > props.clipEnd) {
      seconds = props.clipEnd;
    }
    props.setClipStart(seconds);
    props.setValidClipStart(true);
  };

  const handleclipEndInput = (event) => {
    const input = event.target.value;
    setClipEndInput(input);
    if (!validTimeInput(input)) {
      props.setValidClipEnd(false);
      return;
    }

    let seconds = displayTimeToSeconds(input);
    if (seconds > props.videoDuration) {
      seconds = props.videoDuration;
    }
    if (seconds < props.clipStart) {
      seconds = props.clipStart;
    }
    props.setClipEnd(seconds);
    props.setValidClipEnd(true);
  };

  useEffect(() => {
    setClipStartInput(secondsToDisplayTime(props.clipStart, clipStartInput));
    props.setValidClipStart(true);
  }, [props.clipStart]);

  useEffect(() => {
    setClipEndInput(secondsToDisplayTime(props.clipEnd, clipEndInput));
    props.setValidClipEnd(true);
  }, [props.clipEnd]);

  return (
    <div className="video-clip-wrapper-react">
      <div className="video-clip-wrapper-react__wrapper">
        <div className="video-clip-wrapper-react__wrapper__video">
          <VideoClipEmbedded
            {...props.qbrickVideo}
            setVideoDuration={props.setVideoDuration}
            playerPosition={props.playerPosition}
            setPlayerPosition={props.setPlayerPosition}
            setVisualPlayerPosition={props.setVisualPlayerPosition}
            clipStart={props.clipStart}
            clipEnd={props.clipEnd}
            paused={props.paused}
            setPaused={props.setPaused}
          />
          <VideoClipTimePicker
            {...props.videoClipEditor}
            setPlayerPosition={props.setPlayerPosition}
            visualPlayerPosition={props.visualPlayerPosition}
            videoDuration={props.videoDuration}
            clipStart={props.clipStart}
            setClipStart={props.setClipStart}
            clipEnd={props.clipEnd}
            setClipEnd={props.setClipEnd}
            setPaused={props.setPaused}
          ></VideoClipTimePicker>
        </div>
      </div>
      <div className="video-clip-wrapper-react__cut">
        <h2>{props.videoClipEditor.header}</h2>
        <ContentArea
          {...props.videoClipEditor.helpText}
          theme={RichTextTheme.InfoBox}
        />
        <div className="video-clip-wrapper-react__cut__inputs">
          <div className="video-clip-wrapper-react__cut__inputs__start">
            <label htmlFor="start">
              {props.videoClipEditor.startTimeLabel}
            </label>
            <input
              value={clipStartInput}
              name="start"
              id="start"
              onChange={handleclipStartInput}
            ></input>
            {!props.validClipStart && (
              <ValidationError
                text={props.videoClipFormSubmit.errorMessageInvalidClipStart}
              ></ValidationError>
            )}
          </div>
          <div className="video-clip-wrapper-react__cut__inputs__end">
            <label htmlFor="end">{props.videoClipEditor.endTimeLabel}</label>
            <input
              value={clipEndInput}
              name="end"
              id="end"
              onChange={handleclipEndInput}
            ></input>
            {!props.validClipEnd && (
              <ValidationError
                text={props.videoClipFormSubmit.errorMessageInvalidClipEnd}
              ></ValidationError>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoClipBlock;
