import React from 'react';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import './EPOSSakLoseforslag.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { EPOSSakLoseforslagProps } from '/Models/Generated/EPOSSakLoseforslagProps';

const EPOSSakLoseforslag = ({
  formalTitle,
  groups,
  belongsToProceeding,
}: EPOSSakLoseforslagProps) => (
  <PageWrapper>
    <div className="epos-sak-lose-forslag-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <p className="epos-sak-lose-forslag-react__formal-title">
          {formalTitle}
        </p>
        {groups && (
          <ul className="epos-sak-lose-forslag-react__groups">
            {groups.map((val, idx) => (
              <li key={idx}>
                {val.title && <h2 id={val.titleId}>{val.title}</h2>}
                {val.items &&
                  val.items.map((item, itemIdx) => {
                    const H = !!val.title ? 'h3' : 'h2';
                    return (
                      <React.Fragment key={itemIdx}>
                        <H className="epos-sak-lose-forslag-react__group-header">
                          {item.header}
                        </H>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></div>
                      </React.Fragment>
                    );
                  })}
              </li>
            ))}
          </ul>
        )}
      </Row>
      <Row
        horizontalPadding={HorizontalPadding.normal}
        lineAbove={true}
        verticalPaddingTop={VerticalPadding.normal}
      >
        <ContentArea {...belongsToProceeding} />
      </Row>
    </div>
  </PageWrapper>
);

export default EPOSSakLoseforslag;
