import React from 'react';
import './PageCategories.scss';
import { EuEosPageCategoriesProps } from '/Models/Generated/EuEosPageCategoriesProps';

const PageCategories = ({ prefix, categories }: EuEosPageCategoriesProps) => (
  <div className="page-categories-list-react">
    {categories && (
      <div>
        <span>
          {prefix}
          {': '}
        </span>
        {categories &&
          categories.map((val, idx) => (
            <>
              <a key={idx} href={val.href}>
                {val.text}
              </a>
              {idx + 1 < categories.length && ', '}
            </>
          ))}
      </div>
    )}
  </div>
);

export default PageCategories;
