import React from 'react';
import './CardRecommendendHit.scss';

interface CardRecommendendHitProps {
  text: string;
}

const CardRecommendendHit = ({ text }: CardRecommendendHitProps) => {
  return <div className="card-recommended-hit">{text}</div>;
};

export default CardRecommendendHit;
