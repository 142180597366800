import React from 'react';
import './MinutesHeaderContainer.scss';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';

interface Props {
  children?: React.ReactNode;
}
const MinutesHeaderContainer = ({ children }: Props) => {
  return (
    <div className="minutes-header-container-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <div className="minutes-header-container-react__wrapper">
          {children}
        </div>
      </Row>
    </div>
  );
};

export default MinutesHeaderContainer;
