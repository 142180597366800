import React, { useEffect, useState } from 'react';
import './StottemenyTemaAA.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { StottemenyTemaAAProps } from '/Models/Generated/StottemenyTemaAAProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import GreyRow, { GreyRowWidth } from '/Components/Layout/GreyRow/GreyRow';
import Link from '/Components/Atoms/Link/Link';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import DictionaryLetterTabs from '/Components/Molecules/DictionaryLetterTabs/DictionaryLetterTabs';
import BuildUrlWithParams from '/Shared/Code/UrlBuilder/BuildUrlWithParams';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';

const StottemenyTemaAA = ({
  mainIntro,
  mainBody,
  primaryFilterId,
  primaryFilterHeading,
  primaryFilterSubHeading,
  primaryFilterAlphabeticalHeader,
  primaryFilterAlphabeticalList,
  primaryFilterThematicHeader,
  alphabeticalSelected,
  alphabeticalGroups,
  thematicGroups,
  threeColumnContentArea,
  threeColumnContentAreaModules,
  threeColumnContentAreaInTop,
}: StottemenyTemaAAProps) => {
  const [alphaSelected, setAlphaSelected] = useState(alphabeticalSelected);

  const changeTabType = (newAlphaSelected: boolean) => {
    if (alphaSelected === newAlphaSelected) return;
    const newUrl = BuildUrlWithParams(window.location.href, null, true, {
      tab: newAlphaSelected ? 'Alphabetical' : 'Thematic',
    });
    window.history.pushState({}, '', newUrl);
    setAlphaSelected(newAlphaSelected);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const handler = () => {
      const tabValue = SSRQueryStringReader.Read('tab');
      if (tabValue === 'Alphabetical') setAlphaSelected(true);
      if (tabValue === 'Thematic') setAlphaSelected(false);
    };
    handler(); // for page reload (runs once)
    window.addEventListener('popstate', handler);
    return () => window.removeEventListener('popstate', handler);
  }, []);

  return (
    <div className="stottemeny-tema-react">
      {HasContent(mainIntro) && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        </Row>
      )}
      {HasContent(threeColumnContentAreaModules) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={
            HasContent(threeColumnContentAreaInTop)
              ? VerticalPadding.none
              : VerticalPadding.large
          }
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaModules} />
        </Row>
      )}
      {HasContent(threeColumnContentAreaInTop) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={
            HasContent(threeColumnContentAreaModules)
              ? VerticalPadding.none
              : VerticalPadding.large
          }
          verticalPaddingBottom={VerticalPadding.large}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaInTop} />
        </Row>
      )}

      {HasContent(mainBody) && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainBody} />
        </Row>
      )}
      <Row verticalPaddingBottom={VerticalPadding.large}>
        <ExpandableFilterWrapper heading={primaryFilterHeading}>
          <div id={primaryFilterId}>
            <h2 id="stottemeny_tema_filter_title">{primaryFilterSubHeading}</h2>
            <TabButtonStrip>
              <TabButton
                text={primaryFilterAlphabeticalHeader}
                onClick={() => changeTabType(true)}
                selected={alphaSelected}
              />
              <TabButton
                text={primaryFilterThematicHeader}
                onClick={() => changeTabType(false)}
                selected={!alphaSelected}
              />
            </TabButtonStrip>
            {alphaSelected && !!primaryFilterAlphabeticalList?.length && (
              <div className="stottemeny-tema-react__letter-tabs">
                <DictionaryLetterTabs
                  tabItems={primaryFilterAlphabeticalList}
                  ariaLabelledBy="stottemeny_tema_filter_title"
                />
              </div>
            )}
          </div>
        </ExpandableFilterWrapper>
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {alphaSelected && (
          <div>
            {alphabeticalGroups && (
              <ul className="stottemeny-tema-react__list">
                {alphabeticalGroups.map((group, idx) => (
                  <li key={idx}>
                    <a id={group.groupId}></a>
                    <h3 className="stottemeny-tema-react__list__number">
                      {group.groupTitle}
                    </h3>
                    {group && (
                      <ul className="stottemeny-tema-react__sublist">
                        {group.links.map((link, idx2) => (
                          <li key={idx2}>
                            <Link {...link} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {!alphaSelected && (
          <div>
            {thematicGroups && (
              <ul className="stottemeny-tema-react__list">
                {thematicGroups.map((group, idx) => (
                  <li key={idx}>
                    {group.header && (
                      <GreyRow width={GreyRowWidth.indentedWide}>
                        <h3 className="stottemeny-tema-react__bar-heading">
                          <a href={group.header.href}>{group.header.text}</a>
                        </h3>
                      </GreyRow>
                    )}
                    {group.links && (
                      <ul className="stottemeny-tema-react__sublist">
                        {group.links.map((link, idx2) => (
                          <li key={idx2}>
                            <a href={link.href}>{link.text}</a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </Row>
      {HasContent(threeColumnContentArea) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={VerticalPadding.large}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentArea} />
        </Row>
      )}
    </div>
  );
};

export default StottemenyTemaAA;
