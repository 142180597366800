import React, { useEffect } from 'react';
import './SearchPage.scss';
import { SearchPageProps } from '/Models/Generated/SearchPageProps';
import SearchPageFilters from './SearchPageFilters';
import SearchPageFiltersMobile from './SearchPageFiltersMobile';
import SearchPageList from './SearchPageList';
import SearchForm from '/Components/Molecules/SearchForm/SearchForm';
import SearchResultCount from './SearchResultCount';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import SearchPageSorting from './SearchPageSorting';
import SearchPageLoader, { Theme } from './SearchPageLoader';
import SearchClient from '/Shared/Code/Search/SearchClient';
import SearchResult from '/Shared/Code/Search/SearchResult';
import SearchPagination from './SearchPagination';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useDispatch, useSelector } from 'react-redux';
import SearchPageClearFilters from './SearchPageClearFilters';
import StatusMessage, {
  StatusMessageTheme,
} from '/Components/Atoms/StatusMessage/StatusMessage';
import {
  setLoading,
  setDoSearch,
  setResults,
  setSearchClientProps,
  setSearchLanguage,
  setLabels,
  reloadState,
  setShouldPushState,
  setQuery,
  setFilter,
  setCustomerCode,
  setCategoryId,
} from '/Shared/Code/Search/SearchSlice';

const SearchPage = ({
  searchClient,
  language,
  filters,
  results,
  searchForm,
  sortingLabels,
  pagination,
  search,
  labels,
}: SearchPageProps) => {
  const client = new SearchClient(searchClient);
  const query = useSelector((state: RootState) => state.Search.query);
  const replaceFrom = searchClient.replaceFrom;
  const replaceTo = searchClient.replaceTo;
  const sorting = useSelector((state: RootState) => state.Search.sorting);
  const searchResults = useSelector((state: RootState) => state.Search.results);
  const loading = useSelector((state: RootState) => state.Search.loading);
  const page = useSelector((state: RootState) => state.Search.page);
  const doSearch = useSelector((state: RootState) => state.Search.doSearch);
  const categoryId = useSelector((state: RootState) => state.Search.categoryId);
  const filter = useSelector((state: RootState) => state.Search.filter);
  const customerCode = useSelector(
    (state: RootState) => state.Search.customerCode
  );
  const shouldPushState = useSelector(
    (state: RootState) => state.Search.shouldPushState
  );
  const hasActiveFilters = client.HasActiveFilters(filter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (doSearch) {
      if (customerCode !== null) {
        client
          .SearchCustomerCode(
            filter,
            customerCode,
            language,
            replaceFrom,
            replaceTo
          )
          .then((res) => {
            const newCategoryId = res.data.response.currentCategory;
            dispatch(setResults(new SearchResult(res)));
            dispatch(setFilter('G761'));
            dispatch(setCustomerCode(null));
            dispatch(setCategoryId(newCategoryId));
            const url = client.BuildSearchUrl(
              null,
              sorting,
              page,
              SearchClient.groupPosts,
              newCategoryId
            );
            if (shouldPushState) window.history.pushState({}, '', url);
            else dispatch(setShouldPushState(true));
            dispatch(setLoading(false));
          });
      } else if (query || (categoryId && filter)) {
        dispatch(setLoading(true));
        client
          .Search(
            query,
            sorting,
            page,
            filter,
            categoryId,
            language,
            replaceFrom,
            replaceTo
          )
          .then((res) => {
            dispatch(setResults(new SearchResult(res)));
            const url = client.BuildSearchUrl(
              query,
              sorting,
              page,
              filter,
              categoryId
            );
            if (shouldPushState) window.history.pushState({}, '', url);
            else dispatch(setShouldPushState(true));
            dispatch(setLoading(false));
          });
      }
      dispatch(setDoSearch(false));
    }
  });

  useEffect(() => {
    dispatch(setSearchClientProps(searchClient));
    dispatch(setSearchLanguage(language));
    dispatch(setLabels(labels));
    window.onpopstate = () => dispatch(reloadState());
  }, []);

  return (
    <div className="search-page-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <SearchForm
          {...searchForm}
          client={client}
          setQuery={(term: string) => dispatch(setQuery(term))}
          doSearch={() => dispatch(setDoSearch(true))}
          query={useSelector((state: RootState) => state.Search.query)}
          loading={useSelector((state: RootState) => state.Search.loading)}
        />
      </Row>
      {searchResults?.GetTotalCount() > 0 && (
        <>
          <SearchPageFiltersMobile {...filters} />
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPadding={VerticalPadding.normal}
          >
            <SearchPageFilters {...filters} />
            {hasActiveFilters && <SearchPageClearFilters {...filters} />}
          </Row>
        </>
      )}
      {loading && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPadding={VerticalPadding.normal}
        >
          <SearchPageLoader theme={Theme.default} />
        </Row>
      )}
      {!loading && searchResults?.GetTotalCount() > 0 && (
        <>
          <Row horizontalPadding={HorizontalPadding.indent}>
            <div className="search-page-react__count-and-sort">
              <SearchResultCount {...results} />
              <SearchPageSorting {...sortingLabels} />
            </div>
          </Row>
          <Row>
            <SearchPageList />
          </Row>
          <Row horizontalPadding={HorizontalPadding.indent}>
            <SearchResultCount {...results} />
            {searchResults?.GetPageCount() > 1 && (
              <SearchPagination {...pagination} />
            )}
          </Row>
        </>
      )}
      {!loading && !searchResults?.GetTotalCount() && (
        <Row
          className="search-results-spacing"
          horizontalPadding={HorizontalPadding.indent}
        >
          {searchResults && !(searchResults?.GetTotalCount() > 0) && (
            <StatusMessage theme={StatusMessageTheme.info}>
              <p>{search.empty}</p>
              <span>{search.suggestion}&#58;</span>
              <ul>
                {search.suggestions.map((val, idx) => (
                  <li key={idx}>{val}</li>
                ))}
              </ul>
            </StatusMessage>
          )}
        </Row>
      )}
    </div>
  );
};

const SearchPageWrapper = (props: SearchPageProps) => {
  return (
    <Provider store={SearchStore}>
      <SearchPage {...props} />
    </Provider>
  );
};

export default SearchPageWrapper;
