import React from 'react';
import './TopMenuSectionContent.scss';
import { TopMenuSectionProps } from '/Models/Generated/TopMenuSectionProps';
import TopMenuColumn from './TopMenuColumn';
import cn from 'classnames';
import TopMenuSectionContentCloseButton from './TopMenuSectionContentCloseButton';

interface TopMenuSectionPropsExtended extends TopMenuSectionProps {
  toggleOpenSection: (id: number) => void;
  containerId: string;
}

const TopMenuSection = (props: TopMenuSectionPropsExtended) => {
  if (props)
    return (
      <div
        className={cn('top-menu-section-content', {
          'is-open': props.isOpen,
        })}
        style={{ height: props.isOpen ? 'auto' : 0 }}
        id={props.containerId}
      >
        <div className="border-top-react"></div>
        {props.mainLink && (
          <div className="main-link">
            <a
              href={props.mainLink.url}
              target={props.mainLink.target}
              lang={props.mainLink.langAttribute || null}
            >
              {props.mainLink.text}
            </a>
          </div>
        )}
        <div className="top-menu-section-content-columns">
          {props.col1 && <TopMenuColumn {...props.col1} />}
          {props.col2 && <TopMenuColumn {...props.col2} />}
          {props.col3 && <TopMenuColumn {...props.col3} />}
          {props.col4 && <TopMenuColumn {...props.col4} />}
          {props.col5 && <TopMenuColumn {...props.col5} />}
        </div>
        <TopMenuSectionContentCloseButton
          closeSection={() => props.toggleOpenSection(-1)}
          closeLabel={props.closeLabel}
        />
      </div>
    );
  else return <></>;
};

export default TopMenuSection;
