import React from 'react';
import './PublikasjonerReferaterlisteList.scss';
import { LinkListGroupProps } from 'Models/Generated/LinkListGroupProps';
import Link from '/Components/Atoms/Link/Link';

const PublikasjonerReferaterlisteList = ({
  emptyDataSetHeading,
  emptyDataSetText,
  linkLists,
}: LinkListGroupProps) => {
  return (
    <div className="publikasjoner-referaterliste-list-react">
      {linkLists?.length > 0 ? (
        linkLists.map((item, i) => (
          <div
            className="publikasjoner-referaterliste-list-react__section"
            key={'idx-' + i}
          >
            <h2 className="publikasjoner-referaterliste-list-react__section-heading">
              {item.title}
            </h2>
            {!!item.items?.length && (
              <ul className="publikasjoner-referaterliste-list-react__list">
                {item.items.map((link, j) => (
                  <li
                    className="publikasjoner-referaterliste-list-react__list-item"
                    key={'idx' + j}
                  >
                    <Link {...link} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))
      ) : (
        <div className="publikasjoner-referaterliste-list-react__section">
          <h2 className="publikasjoner-referaterliste-list-react__section-heading">
            {emptyDataSetHeading}
          </h2>
          <p className="publikasjoner-referaterliste-list-react__empty-text">
            {emptyDataSetText}
          </p>
        </div>
      )}
    </div>
  );
};

export default PublikasjonerReferaterlisteList;
