import React, { FunctionComponent, useEffect, useState } from 'react';
import GreyRow, { GreyRowWidth } from '/Components/Layout/GreyRow/GreyRow';
import cn from 'classnames';
import './ExpandableFilterWrapper.scss';

interface Props {
  heading: string;
  scrollId?: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
}

const ExpandableFilterWrapper: FunctionComponent<Props> = ({
  heading,
  scrollId,
  children,
  initiallyExpanded,
}) => {
  const [isOpen, setIsOpen] = useState(initiallyExpanded ?? false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    // Default behavior: closed, unless filter values are set.
    // Can be overridden by: initiallyExpanded
    // Should be the case on all pages with filter:
    //  - there are url param only when filter values are set
    if (typeof initiallyExpanded === 'boolean') return; // already set in useState
    const urlHasParams = /\?.*=/.test(window.location.search);
    setIsOpen(urlHasParams);
  }, []);

  return (
    <GreyRow width={GreyRowWidth.indented}>
      <div className="expandable-filter-wrapper-react">
        <div className="expandable-filter-wrapper-react__heading-container">
          {scrollId && <a id={scrollId}></a>}
          <h2
            className={cn('expandable-filter-wrapper-react__heading', {
              'expandable-filter-wrapper-react__heading--open': isOpen,
            })}
          >
            {heading}
          </h2>
          <button
            tabIndex={0}
            className="expandable-filter-wrapper-react__btn-expand"
            onClick={() => setIsOpen(!isOpen)}
            aria-controls="expandable-filter-wrapper-react__expander"
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <span className="expandable-filter-wrapper-react__sr-only">
              {heading}
            </span>
          </button>
        </div>
        <div
          id="expandable-filter-wrapper-react__expander"
          className={cn('expandable-filter-wrapper-react__expander', {
            'expandable-filter-wrapper-react__expander--open': isOpen,
          })}
        >
          {children}
        </div>
      </div>
    </GreyRow>
  );
};

export default ExpandableFilterWrapper;
