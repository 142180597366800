import React, { useEffect } from 'react';
import './SortableTable.scss';
import { SortableTableProps } from '/Models/Generated/SortableTableProps';
import { CellTypeEnum } from '/Models/Generated/CellTypeEnum';
import Link from '/Components/Atoms/Link/Link';
import { CellStringProps } from '/Models/Generated/CellStringProps';
import { ExtendedCellLinkProps } from '/Models/Generated/ExtendedCellLinkProps';
import { CellDateProps } from '/Models/Generated/CellDateProps';
import { HTMLStringCellProps } from '/Models/Generated/HTMLStringCellProps';
import { CellLinkProps } from '/Models/Generated/CellLinkProps';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import * as Components from '/App.Components';
import classNames from 'classnames';
import { ColumnDataProps } from '/Models/Generated/ColumnDataProps';
import { RowDataProps } from '/Models/Generated/RowDataProps';

interface Props extends SortableTableProps {
  setRows: (rows: RowDataProps[]) => void;
  setColumns: (columns: ColumnDataProps[]) => void;
  doSort: boolean;
  setDoSort: (value: boolean) => void;
  sortColumn: number;
  setSortColumn: (value: number) => void;
  sortDirection: SortDirectionEnum;
  setSortDirection: (value: SortDirectionEnum) => void;
}

export const GetSortColumn = (columns: ColumnDataProps[]): number => {
  if (!columns?.length) return 0;
  for (let i = 0; i < columns.length; ++i)
    if (columns[i].sortDirection !== SortDirectionEnum.None) return i;
  return 0;
};

export const GetSortDirection = (
  columns: ColumnDataProps[]
): SortDirectionEnum => {
  if (!columns?.length) return SortDirectionEnum.None;
  for (let i = 0; i < columns.length; ++i)
    if (columns[i].sortDirection !== SortDirectionEnum.None)
      return columns[i].sortDirection;
  return SortDirectionEnum.None;
};

const SortableTable = ({
  columns,
  setColumns,
  rows,
  setRows,
  doSort,
  setDoSort,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
}: Props) => {
  const SortRows = (column: number, newDirection: SortDirectionEnum) => {
    const newRows = [...rows];
    newRows.sort((rowA, rowB) => {
      const a = rowA?.cells[column]?.dataAsSortableString ?? '';
      const b = rowB?.cells[column]?.dataAsSortableString ?? '';
      const comp = a < b ? 1 : -1;
      return newDirection === SortDirectionEnum.Descending ? comp : -comp;
    });
    const newCols = [...columns];
    newCols.forEach(
      (col) =>
        (col.sortDirection =
          col.columnNumber === column ? newDirection : SortDirectionEnum.None)
    );
    setRows(newRows);
    setColumns(newCols);
  };

  useEffect(() => {
    if (!doSort) return;
    SortRows(sortColumn, sortDirection);
    setDoSort(false);
  }, [doSort]);

  const renderComponent = (cellVal) => {
    const Comp = Components[cellVal.name];
    if (!Comp || !cellVal.component) return <span>{cellVal.name}</span>;
    return <Comp {...cellVal.component} />;
  };

  return (
    <table className="sortable-table-react">
      {columns && !!rows?.length && (
        <thead>
          <tr>
            {columns.map((val, idx) => (
              <React.Fragment key={idx}>
                {val.name ? (
                  <th>
                    {val.sortDirection === SortDirectionEnum.Disabled ? (
                      <span className="sortable-table-react__column-header">
                        {val.name}
                      </span>
                    ) : val.href ? (
                      <a
                        className={classNames(
                          'sortable-table-react__sort-link',
                          {
                            'sortable-table-react__sort-link--none':
                              val.sortDirection === SortDirectionEnum.None,
                            'sortable-table-react__sort-link--asc':
                              val.sortDirection === SortDirectionEnum.Ascending,
                            'sortable-table-react__sort-link--desc':
                              val.sortDirection ===
                              SortDirectionEnum.Descending,
                          }
                        )}
                        href={val.href}
                      >
                        <span className="sortable-table-react__column-header sortable-table-react__column-header__clickable">
                          {val.name}
                        </span>
                      </a>
                    ) : (
                      <button
                        className={classNames(
                          'sortable-table-react__sort-button',
                          {
                            'sortable-table-react__sort-button--none':
                              val.sortDirection === SortDirectionEnum.None,
                            'sortable-table-react__sort-button--asc':
                              val.sortDirection === SortDirectionEnum.Ascending,
                            'sortable-table-react__sort-button--desc':
                              val.sortDirection ===
                              SortDirectionEnum.Descending,
                          }
                        )}
                        onClick={() => {
                          const newDirection =
                            val.sortDirection === SortDirectionEnum.Ascending
                              ? SortDirectionEnum.Descending
                              : SortDirectionEnum.Ascending;
                          SortRows(val.columnNumber, newDirection);
                          setSortDirection(newDirection);
                          setSortColumn(val.columnNumber);
                        }}
                      >
                        <span className="sortable-table-react__column-header sortable-table-react__column-header__clickable">
                          {val.name}
                        </span>
                      </button>
                    )}
                  </th>
                ) : (
                  <td></td>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
      )}
      {rows && (
        <tbody>
          {rows.map((rowVal, RowKey) => (
            <tr key={RowKey}>
              {rowVal.cells &&
                rowVal.cells.map((cellVal, cellKey) => (
                  <React.Fragment key={cellKey}>
                    {cellVal.type !== CellTypeEnum.HTMLString ? (
                      <td>
                        {cellVal.type === CellTypeEnum.String &&
                          (cellVal as CellStringProps).value}
                        {cellVal.type === CellTypeEnum.Date &&
                          (cellVal as CellDateProps)?.value}
                        {cellVal.type === CellTypeEnum.Link && (
                          <Link {...(cellVal as CellLinkProps).value} />
                        )}
                        {cellVal.type === CellTypeEnum.ExtendedLink && (
                          <>
                            <Link
                              {...(cellVal as ExtendedCellLinkProps).value}
                            />{' '}
                            {(cellVal as ExtendedCellLinkProps)?.text}
                          </>
                        )}
                        {cellVal.type === CellTypeEnum.ComponentWrapper &&
                          renderComponent(cellVal)}
                      </td>
                    ) : (
                      <td
                        dangerouslySetInnerHTML={{
                          __html: (cellVal as HTMLStringCellProps).value,
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default SortableTable;
