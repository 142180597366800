import React from 'react';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import './ReferatVoteringer.scss';
import { ReferatVoteringerProps } from '/Models/Generated/ReferatVoteringerProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import MinutesNavigationBar from '/Components/Organisms/MinutesNavigationBar/MinutesNavigationBar';
import MinutesHeader from '/Components/Organisms/MinutesHeader/MinutesHeader';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const ReferatVoteringer = ({
  minutesNavigationBar,
  minutesHeader,
  title,
  mainBodyBefore,
  voteringerContent,
  mainBodyAfter,
  previousLink,
  nextLink,
  votesPagesLabel,
}: ReferatVoteringerProps) => {
  return (
    <PageWrapper>
      <div className="referat-voteringer-page-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <MinutesHeader {...minutesHeader} />
            <MinutesNavigationBar {...minutesNavigationBar} />
          </MinutesHeaderContainer>
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          className="referat-voteringer-page-react__row-containing-content-row"
        >
          <Row horizontalPadding={HorizontalPadding.indent}>
            {title && <h2>{title}</h2>}
            <div className="page-publication">
              <div className="bigdoc-content">
                {mainBodyBefore && (
                  <ContentArea
                    {...mainBodyBefore}
                    theme={RichTextTheme.BigDoc}
                  />
                )}
                {voteringerContent && (
                  <ContentArea
                    {...voteringerContent}
                    theme={RichTextTheme.BigDoc}
                  />
                )}
                {mainBodyAfter && (
                  <ContentArea
                    {...mainBodyAfter}
                    theme={RichTextTheme.BigDoc}
                  />
                )}
                <p>{votesPagesLabel}</p>
              </div>
            </div>
          </Row>
          <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
        </Row>
      </div>
    </PageWrapper>
  );
};

export default ReferatVoteringer;
