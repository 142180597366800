import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchClientProps } from '/Models/Generated/SearchClientProps';
import { SearchPageLabelsProps } from '/Models/Generated/SearchPageLabelsProps';
import SearchClient from './SearchClient';
import SearchResult from './SearchResult';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';

export interface SearchState {
  query: string;
  sorting: string;
  page: number;
  filter: string;
  results?: SearchResult;
  loading: boolean;
  doSearch: boolean;
  openFilter: string;
  language: string;
  searchClientProps: SearchClientProps;
  labels: SearchPageLabelsProps;
  categoryId: number;
  customerCode?: string;
  shouldPushState: boolean;
}

const getInitialState = (): SearchState => ({
  query: SSRQueryStringReader.Read('query') ?? '',
  sorting:
    SSRQueryStringReader.Read('sorting') === SearchClient.sortingDate
      ? SearchClient.sortingDate
      : SearchClient.sortingRelevance,
  page: SSRQueryStringReader.ReadInt('page', 1),
  filter: SSRQueryStringReader.Read('filter') ?? '',
  results: null,
  loading: false,
  doSearch:
    (SSRQueryStringReader.Read('query')?.length > 0 ?? false) ||
    SSRQueryStringReader.ReadInt('categoryid', 0) > 0 ||
    SSRQueryStringReader.Read('customercode') !== null,
  customerCode: SSRQueryStringReader.Read('customercode'),
  openFilter: '',
  language: '',
  searchClientProps: null,
  labels: {} as SearchPageLabelsProps,
  categoryId: SSRQueryStringReader.ReadInt('categoryid', null),
  shouldPushState: true,
});

const initialState: SearchState = getInitialState();

const clearSettingsFromState = (state) => {
  state.filter = '';
  state.page = 1;
  state.query = '';
  state.categoryId = null;
  state.openFilter = '';
  state.sorting = SearchClient.sortingRelevance;
};

const clearGroupSearchFromFilter = (filter: string): string =>
  (filter ?? '')
    .split('+')
    .filter(
      (f) =>
        ![
          SearchClient.groupPosts,
          SearchClient.groupQuestions,
          SearchClient.groupSuggestions,
        ].includes(f)
    )
    .join('+');

export const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setCustomerCode: (state, action: PayloadAction<string>) => {
      state.customerCode = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
      state.page = 1;
    },
    setSorting: (state, action: PayloadAction<string>) => {
      if (state.sorting === action.payload) return;
      state.page = 1;
      state.doSearch = true;
      state.sorting = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      if (state.page === action.payload) return;
      state.doSearch = true;
      state.page = action.payload;
    },
    setResults: (state, action: PayloadAction<SearchResult>) => {
      state.results = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      if (state.filter === action.payload) return;
      state.doSearch = true;
      state.filter = action.payload;
      state.openFilter = '';
      state.page = 1;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setDoSearch: (state, action: PayloadAction<boolean>) => {
      state.doSearch = action.payload;
      if (action.payload && state.query) {
        state.categoryId = null;
        state.filter = clearGroupSearchFromFilter(state.filter);
      }
    },
    setOpenFilter: (state, action: PayloadAction<string>) => {
      state.openFilter = action.payload;
    },
    setSearchClientProps: (state, action: PayloadAction<SearchClientProps>) => {
      state.searchClientProps = action.payload;
    },
    setSearchLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setLabels: (state, action: PayloadAction<SearchPageLabelsProps>) => {
      state.labels = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload;
      if (!action.payload && !state.query) {
        state.results = null;
        clearSettingsFromState(state);
      }
    },
    clearFilter: (state) => {
      state.filter = '';
      state.page = 1;
      state.doSearch = true;
    },
    clearSettings: (state) => {
      clearSettingsFromState(state);
    },
    reloadState: (state) => {
      const { labels, searchClientProps, language } = state;
      Object.assign(state, getInitialState());
      Object.assign(state, { labels, searchClientProps, language });
      state.shouldPushState = false;
    },
    setShouldPushState: (state, action: PayloadAction<boolean>) => {
      if (state.shouldPushState !== action.payload)
        state.shouldPushState = action.payload;
    },
  },
});

export const {
  setCustomerCode,
  setQuery,
  setSorting,
  setPage,
  setResults,
  setFilter,
  setLoading,
  setDoSearch,
  setOpenFilter,
  setSearchClientProps,
  setSearchLanguage,
  setLabels,
  setCategoryId,
  clearFilter,
  clearSettings,
  reloadState,
  setShouldPushState,
} = SearchSlice.actions;

export default SearchSlice.reducer;
