import React from 'react';
import './SearchPageLoader.scss';

export enum Theme {
  default,
  inner,
  autocomplete,
  pagination,
  subscription,
}

interface Props {
  theme: Theme;
}

const SearchPageLoader = (props: Props) => {
  const inner = () => (
    <p>
      <span className="heading"></span>
      <span className="line"></span>
      <span className="line-long"></span>
      <span className="line-long"></span>
      <span className="line-short"></span>
    </p>
  );

  const defaultLoader = () => (
    <>
      {inner()} {inner()}
    </>
  );

  const autocomplete = () => (
    <p>
      <span className="line-short"></span>
      <span className="line-short"></span>
      <span className="line-short"></span>
    </p>
  );

  const pagination = () => (
    <p>
      <span className="line-number"></span>
      <span className="line-number"></span>
      <span className="line-number"></span>
      <span className="line-number"></span>
    </p>
  );

  const subscription = () => (
    <p>
      <span className="line"></span>
      <span className="line-long"></span>
    </p>
  );

  return (
    <div className="search-page-loader">
      {props.theme === Theme.default && defaultLoader()}
      {props.theme === Theme.inner && inner()}
      {props.theme === Theme.autocomplete && autocomplete()}
      {props.theme === Theme.pagination && pagination()}
      {props.theme === Theme.subscription && subscription()}
    </div>
  );
};

export default SearchPageLoader;
