import React from 'react';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import './EPOSSakBebudedesaker.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { EPOSSakBebudedesakerProps } from '/Models/Generated/EPOSSakBebudedesakerProps';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';

const EPOSSakBebudedesaker = ({
  contentArea,
  mainBody,
  mainIntro,
  proceedingDepartmentGroups,
  proceedingNoticeCaption,
}: EPOSSakBebudedesakerProps) => (
  <PageWrapper>
    <div className="epos-sak-bebudede-saker-react">
      {HasContent(mainIntro) && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        </Row>
      )}
      {HasContent(contentArea) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ContentArea {...contentArea} />
        </Row>
      )}
      {HasContent(mainBody) && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainBody} />
        </Row>
      )}
      {proceedingDepartmentGroups?.proceedingDepartments && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <table className="epos-sak-bebudede-saker-react__table">
            <tbody>
              <caption className="sr-only">{proceedingNoticeCaption}</caption>
              {proceedingDepartmentGroups.proceedingDepartments.map(
                (val, idx) => (
                  <React.Fragment key={idx}>
                    <tr className="epos-sak-bebudede-saker-react__table__department-title">
                      <th colSpan={2}>
                        <GreyBar
                          text={val.departmentTitle}
                          fullWidthText={true}
                        />
                      </th>
                    </tr>
                    {!!val.proceedingTypeGroups &&
                      val.proceedingTypeGroups.map((ptg, idx2) => (
                        <React.Fragment key={idx2}>
                          <tr className="epos-sak-bebudede-saker-react__table__proceeding-type-title">
                            <td colSpan={2}>
                              <h3>{ptg.proceedingTypeTitle}</h3>
                            </td>
                          </tr>
                          {!!ptg.proceedings &&
                            ptg.proceedings.map((proc, idx3) => (
                              <tr
                                key={idx3}
                                className="epos-sak-bebudede-saker-react__table__proceeding"
                              >
                                <td>{proc.title}</td>
                                <td>{proc.month}</td>
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                )
              )}
            </tbody>
          </table>
        </Row>
      )}
    </div>
  </PageWrapper>
);

export default EPOSSakBebudedesaker;
