import React, { FunctionComponent } from 'react';
import './AlertWrapper.scss';

interface Props {
  children?: React.ReactNode;
}

const AlertWrapper: FunctionComponent<Props> = ({ children }) => (
  <div className="alert-wrapper-react">{children}</div>
);

export default AlertWrapper;
