import React from 'react';
import { StottemenyOrdbokProps } from '/Models/Generated/StottemenyOrdbokProps';
import './StottemenyOrdbok.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import GreyRow from '/Components/Layout/GreyRow/GreyRow';
import DictionaryLetterTabs from '/Components/Molecules/DictionaryLetterTabs/DictionaryLetterTabs';
import DictionaryResults from '/Components/Molecules/DictionaryResults/DictionaryResults';

const StottemenyOrdbok = ({
  subFwEposListProps,
  rightRegion,
  tabStripHeading,
  dictionaryTabStripProps,
  dictionaryResultsProps,
}: StottemenyOrdbokProps) => {
  return (
    <div className="stottemeny-ordbok-react">
      {subFwEposListProps.mainIntro && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea
            {...subFwEposListProps.mainIntro}
            theme={RichTextTheme.MainIntro}
          />
        </Row>
      )}

      {HasContent(rightRegion) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ContentArea {...rightRegion} />
        </Row>
      )}

      {subFwEposListProps.mainBody && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...subFwEposListProps.mainBody} />
        </Row>
      )}

      <Row verticalPaddingBottom={VerticalPadding.large}>
        <GreyRow>
          <div
            id="primaryfilter"
            className="stottemeny-ordbok-react__tabs-strip-container"
          >
            <h2 id="dictionary_tabs_strip_react_heading">{tabStripHeading}</h2>
            <DictionaryLetterTabs
              ariaLabelledBy="dictionary_tabs_strip_react_heading"
              tabItems={dictionaryTabStripProps.tabItems}
            />
          </div>
        </GreyRow>
      </Row>

      {dictionaryResultsProps && dictionaryResultsProps.letter && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          <DictionaryResults {...dictionaryResultsProps} />
        </Row>
      )}
    </div>
  );
};

export default StottemenyOrdbok;
