import React, { useEffect, useRef } from 'react';
import './RichText.scss';
import { RichTextProps } from '/Models/Generated/RichTextProps';
import cn from 'classnames';
import {
  fixImageText,
  fixEditorialTableScrollers,
  fixBigdocTableScrollers,
  fixLargeTables,
  fixIframes,
} from './RichTextHelpers';

export enum RichTextTheme {
  Standard,
  InfoBox,
  ContactInfo,
  ArticlePreview,
  MainIntro,
  BigDoc,
}

export enum RichTextTableExpander {
  Editorial,
  BigDocument,
  None,
}

interface Props extends RichTextProps {
  theme?: RichTextTheme;
  tableExpander?: RichTextTableExpander;
}

const RichText = ({ html, theme, tableExpander }: Props) => {
  const ref = useRef(null as HTMLElement);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        fixImageText(ref);
        fixEditorialTableScrollers(ref);
        fixBigdocTableScrollers(ref);
      };
      window.addEventListener('resize', handleResize);
      fixIframes(ref);
      fixImageText(ref);
      fixLargeTables(ref, tableExpander);
      fixEditorialTableScrollers(ref);
      fixBigdocTableScrollers(ref);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  return (
    <>
      <div
        ref={ref}
        className={cn({
          'rich-text-react': theme !== RichTextTheme.BigDoc,
          'rich-text-theme-big-doc': theme === RichTextTheme.BigDoc,
          'rich-text-theme-standard': theme === RichTextTheme.Standard,
          'rich-text-theme-info-box': theme === RichTextTheme.InfoBox,
          'rich-text-theme-contact-info': theme === RichTextTheme.ContactInfo,
          'rich-text-theme-article-preview':
            theme === RichTextTheme.ArticlePreview,
          'rich-text-theme-main-intro': theme === RichTextTheme.MainIntro,
        })}
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    </>
  );
};

export default RichText;
