import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './Document2Col.scss';
import { Document2ColProps } from '/Models/Generated/Document2ColProps';
import PageInfo from '/Components/Organisms/PageInfo/PageInfo';
import {
  RichTextTableExpander,
  RichTextTheme,
} from '/Components/Organisms/RichText/RichText';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import classNames from 'classnames';

const Document2Col = ({
  title,
  titlePropertyName,
  pdfVisible,
  pdfLink,
  pdfVersion,
  videoUrls,
  videoTitle,
  pageInfo,
  mainBody,
}: Document2ColProps) => {
  return (
    <div className="document-2-col-react page page-publication page-temporary">
      <Row horizontalPadding={HorizontalPadding.normal}>
        <nav className="bigdoc-navigation-react">
          <div className="bigdoc-navigation-react__header-wrapper">
            <h1
              data-epi-property-name={titlePropertyName}
              className={classNames('bigdoc-navigation-react__header', {
                'epi-editContainer': titlePropertyName,
              })}
            >
              {title}
            </h1>
          </div>
          {(pdfVisible || videoUrls?.length > 0) && (
            <ul className="bigdoc-navigation-react__actions">
              {pdfVisible && (
                <li>
                  <a
                    href={pdfLink ?? '#'}
                    className="bigdoc-navigation-react__actions__pdf-download-link"
                  >
                    {pdfVersion}{' '}
                  </a>
                </li>
              )}
              {videoUrls?.length > 0 &&
                videoUrls.map((vu, idx) => (
                  <li key={idx}>
                    <a
                      href={vu ?? '#'}
                      className="bigdoc-navigation-react__actions__pdf-download-link"
                    >
                      {videoTitle}{' '}
                      {idx > 0 ? 'del ' + (videoUrls.length - idx) : ''}
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </nav>
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        <div className="bigdoc-content">
          <ContentArea
            {...mainBody}
            theme={RichTextTheme.BigDoc}
            tableExpander={RichTextTableExpander.BigDocument}
          />
          {pageInfo && (
            <div className="document-2-col-react__page-info-wrapper">
              <PageInfo {...pageInfo} />
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default Document2Col;
