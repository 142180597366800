import React from 'react';
import './Kurs.scss';
import { KursProps } from '/Models/Generated/KursProps';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageInfo from '/Components/Organisms/PageInfo/PageInfo';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import MainImage from '/Components/Organisms/MainImage/MainImage';
import Button from '/Components/Atoms/Button/Button';

const Kurs = ({
  mainBody,
  mainIntro,
  mainImageOld,
  mainImage,
  pageInfo,
  threeColumnContentAreaBottom,
  registrationLink,
}: KursProps) => {
  return (
    <PageWrapper>
      <div className="kurs-react">
        <Row
          horizontalPadding={HorizontalPadding.indent}
          noPaddingOnMobile={true}
        >
          <MainImage {...mainImage} />
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainIntro && (
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          )}
          {mainImageOld && (
            <ImageWithCaption {...mainImageOld} maxWidthTablet={true} />
          )}
          {mainBody && <ContentArea {...mainBody} />}
          {registrationLink && <Button {...registrationLink} />}
          {pageInfo && (
            <div className="kurs-react__page-info-wrapper">
              <PageInfo {...pageInfo} />
            </div>
          )}
        </Row>
        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaBottom} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default Kurs;
