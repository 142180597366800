import React from 'react';
import './CommitteeBlock.scss';
import { CommitteeBlockProps } from '/Models/Generated/CommitteeBlockProps';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import SectionWrapper from '/Components/Layout/SectionWrapper/SectionWrapper';
import Committee from '/Components/Molecules/Committee/Committee';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const CommitteeBlock = ({
  title,
  titlePropertyName,
  committees,
  otherCommittees,
}: CommitteeBlockProps) => {
  return (
    <BlockWrapper>
      <SectionWrapper title={title} titlePropertyName={titlePropertyName}>
        {committees && (
          <div className="committee-block-react__list">
            {committees.map((val, idx) => (
              <Committee key={idx} {...val}></Committee>
            ))}
          </div>
        )}
        {otherCommittees && (
          <section className="committee-block-react__button-list">
            {otherCommittees.map((val, idx) => (
              <div key={idx}>
                <Link
                  href={val.href}
                  text={val.text}
                  theme={LinkTheme.blocklink}
                />
              </div>
            ))}
          </section>
        )}
      </SectionWrapper>
    </BlockWrapper>
  );
};

export default CommitteeBlock;
