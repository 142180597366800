import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './ConfirmNewsletterUnsubscriptionPage.scss';
import { ConfirmNewsletterUnsubscriptionPageProps } from '/Models/Generated/ConfirmNewsletterUnsubscriptionPageProps';
import Button from '/Components/Atoms/Button/Button';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const ConfirmNewsletterUnsubscriptionPage = ({
  text,
  buttonLabel,
  buttonHref,
}: ConfirmNewsletterUnsubscriptionPageProps) => {
  return (
    <Row horizontalPadding={HorizontalPadding.normal}>
      <div className="confirm-newsletter-unsubscription-page-react">
        {text && (
          <div className="confirm-newsletter-unsubscription-page-react__text">
            <ContentArea {...text} theme={RichTextTheme.ArticlePreview} />
          </div>
        )}
        <Button text={buttonLabel} href={buttonHref} />
      </div>
    </Row>
  );
};

export default ConfirmNewsletterUnsubscriptionPage;
