import React from 'react';
import './DokumenttypePage.scss';
import { DokumenttypePageProps } from '/Models/Generated/DokumenttypePageProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import Listing from '/Components/Organisms/Listing/Listing';

const DokumenttypePage = (props: DokumenttypePageProps) => {
  return (
    <div className="dokumenttype-page">
      <Row horizontalPadding={HorizontalPadding.indent}>
        {props.listing && <Listing {...props.listing} />}
      </Row>
    </div>
  );
};

export default DokumenttypePage;
