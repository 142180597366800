import React from 'react';
import './DocumentContentList.scss';
import { DocumentContentListProps } from '/Models/Generated/DocumentContentListProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';

const DocumentContentList = ({
  agendaLabel,
  contentItems,
}: DocumentContentListProps) => {
  return (
    <div className="document-content-list-react">
      {agendaLabel && (
        <h2 className="document-content-list-react__heading">{agendaLabel}</h2>
      )}
      {!!contentItems.length && (
        <ul className="document-content-list-react__list">
          {contentItems.map((proc, idx) => (
            <li key={idx} className="document-content-list-react__list-item">
              <h3 className="document-content-list-react__list-item__heading">
                <Link
                  theme={LinkTheme.dark}
                  href={proc.href}
                  text={proc.name}
                  className="document-content-list-react__list-item__heading-link"
                />
              </h3>

              <div className="document-content-list-react__list-item__content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: proc.introHtml,
                  }}
                  className="document-content-list-react__list-item__content__text"
                />

                {proc.videoLink && (
                  <div className="document-content-list-react__list-item__content__video-link">
                    <IconLinkGeneric
                      iconString="video"
                      href={proc.videoLink.href}
                      text={proc.videoLink.text}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DocumentContentList;
