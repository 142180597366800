import { PublikasjonerDokumenterlisteAsyncDataProps } from 'Models/Generated/PublikasjonerDokumenterlisteAsyncDataProps';
import SSQQueryStringReader from '../SSR/SSRQueryStringReader';

export interface Props {
  pid: string;
  tab: string;
  paid?: string;
  cid?: string;
  perid?: string;
  coid?: string;
  m?: string;
  dnid?: string;
  dt?: string;
}

export default class PublikasjonerDokumenterlisteClient {
  constructor(private endpoint: string, private pageId: number) {}
  public urlTab = () => SSQQueryStringReader.Read('tab');
  public urlDocNumber = () => SSQQueryStringReader.Read('dnid') ?? '';
  public urlDocType = () => SSQQueryStringReader.Read('dt') ?? 'all';
  public urlSession = () => SSQQueryStringReader.Read('pid');
  public urlPartySeveral = () => SSQQueryStringReader.Read('paid') ?? 'all';
  public urlPartySingle = () => SSQQueryStringReader.Read('paid') ?? 'all';
  public urlDistrict = () => SSQQueryStringReader.Read('cid') ?? 'all';
  public urlRepresentative = () => SSQQueryStringReader.Read('perid') ?? 'all';
  public urlCommittee = () => SSQQueryStringReader.Read('coid') ?? 'all';
  public urlMonth = () => SSQQueryStringReader.Read('m') ?? 'all';

  public Get(
    props: Props
  ): Promise<PublikasjonerDokumenterlisteAsyncDataProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    const params = this.BuildParameters(props);
    return fetch(
      this.endpoint + '?pageId=' + this.pageId + '&' + params.join('&'),
      {
        headers: { Accept: 'application/json' },
      }
    ).then((res) => res.json());
  }

  BuildFilterUrl = (props: Props): string => {
    const params = this.BuildParameters(props);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private BuildParameters = ({
    pid,
    tab,
    paid,
    cid,
    perid,
    coid,
    m,
    dnid,
    dt,
  }: Props) =>
    [
      tab ? `tab=${tab}` : null,
      pid ? `pid=${pid}` : null,
      paid && paid !== 'all' ? `paid=${paid}` : null,
      cid && cid !== 'all' ? `cid=${cid}` : null,
      perid && perid !== 'all' ? `perid=${perid}` : null,
      coid && coid !== 'all' ? `coid=${coid}` : null,
      m && m !== '0' ? `m=${m}` : null,
      dt && dt !== 'all' ? `dt=${dt}` : null,
      !isNaN(parseInt(dnid)) ? `dnid=${dnid}` : null,
    ].filter((p) => p !== null);
}
