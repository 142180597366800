import React from 'react';
import './EuEosNewsList.scss';
import { Provider, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { InfoListProps } from '/Models/Generated/InfoListProps';
import { InfoProps } from '/Models/Generated/InfoProps';
import LinkButton from '/Components/Atoms/LinkButton/LinkButton';
import classNames from 'classnames';

export interface Props {
  labelDisplaying: string;
  labelResults: string;
  labelResult: string;
  labelShowMore: string;
  showAllFunction: () => void;
}

const formatDate = (timestamp) => {
  if (!timestamp) return '';

  return timestamp.substr(0, 10).split('-').reverse().join('.');
};

const EuEosNewsList = ({
  labelDisplaying,
  labelResults,
  labelResult,
  labelShowMore,
  showAllFunction,
}: Props) => {
  const defaultArticleMaxCount = 10; // (10 is arbitrary)
  const state = useSelector((s: RootState) => s.EuEosNewsList);

  const totalArticleCount = +state.euEosNewsList.reduce(
    (prev, current) => prev + current.infoProps.length,
    0
  );

  const displayShowMoreButton = () =>
    state.showExpandButton && totalArticleCount > defaultArticleMaxCount;

  const articlesToShow = () => {
    if (!displayShowMoreButton()) return state.euEosNewsList;
    const articles = JSON.parse(JSON.stringify(state.euEosNewsList));
    let count = defaultArticleMaxCount;
    articles.forEach((article) => {
      article.infoProps = article.infoProps.slice(0, count);
      const infoPropsAfterSlice = article.infoProps.length;
      count -= infoPropsAfterSlice;
    });
    return articles.filter((a) => a.infoProps.length > 0);
  };

  const displayedArticleCount = +articlesToShow().reduce(
    (prev, current) => prev + current.infoProps.length,
    0
  );

  const resultsDisplayText = () =>
    `${labelDisplaying} ${displayedArticleCount} ${
      displayedArticleCount === 1 ? labelResult : labelResults
    }`;

  return (
    <div className="eu-eos-news-list-react">
      <p
        className={classNames('eu-eos-news-list-react__results-count', {
          'eu-eos-news-list-react__results-count--no-results':
            displayedArticleCount === 0,
        })}
      >
        {resultsDisplayText()}
      </p>
      {!!articlesToShow().length &&
        articlesToShow().map((item: InfoListProps, i) => (
          <div className="eu-eos-news-list-react__section" key={'idx-' + i}>
            <h2 className="eu-eos-news-list-react__section-heading">
              {item.headerTitle}
            </h2>
            {!!item.infoProps?.length && (
              <ul className="eu-eos-news-list-react__list">
                {item.infoProps.map((el: InfoProps, j) => (
                  <li
                    className="eu-eos-news-list-react__list-item"
                    key={'idx' + j}
                  >
                    <h3 className="eu-eos-news-list-react__list-item__sub-heading">
                      <a href={el.extUrl}>{el.pagename}</a>
                    </h3>
                    <p
                      className="eu-eos-news-list-react__list-item__intro"
                      dangerouslySetInnerHTML={{ __html: el.mainIntro }}
                    ></p>

                    {el.parentLink && (
                      <div className="eu-eos-news-list-react__list-item__parent-link">
                        {el.parentLink.prefix && (
                          <span>{el.parentLink.prefix}: </span>
                        )}
                        <a href={el.parentLink.parentLink.href}>
                          {el.parentLink.parentLink.text}
                        </a>
                      </div>
                    )}
                    <p className="eu-eos-news-list-react__list-item__published-date">
                      <time dateTime={formatDate(el.stringStartPublish)}>
                        ({formatDate(el.stringStartPublish)})
                      </time>
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      {displayShowMoreButton() && (
        <LinkButton
          onClick={showAllFunction}
          className="eu-eos-news-list-react__btn-show-all"
          text={labelShowMore}
        />
      )}
    </div>
  );
};

const EuEosNewsListWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <EuEosNewsList {...props} />
  </Provider>
);

export default EuEosNewsListWrapper;
