import React from 'react';
import './TopMenuSection.scss';
import { TopMenuSectionProps } from '/Models/Generated/TopMenuSectionProps';
import cn from 'classnames';

interface TopMenuSectionPropsExtended extends TopMenuSectionProps {
  setOpenSection: React.Dispatch<React.SetStateAction<number>>;
  ariaControls: string;
}

const TopMenuSection = (props: TopMenuSectionPropsExtended) => {
  if (props)
    return (
      <div className="top-menu-section">
        <button
          className={cn('top-menu-section-title', {
            'is-open-react': props.isOpen,
          })}
          onClick={(e) => {
            e.preventDefault();
            props.setOpenSection(props.isOpen ? -1 : props.id);
          }}
          aria-controls={props.ariaControls}
        >
          {props.title}
        </button>
      </div>
    );
  else return <></>;
};

export default TopMenuSection;
