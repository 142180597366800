import { GlobalSearchResultProps } from '/Models/Generated/GlobalSearchResultProps';
import 'url-search-params-polyfill';

const GetFacetFiltersFromUrlParams = (
  results: GlobalSearchResultProps,
  requestQueryString?: string
) => {
  const queryParameters = new URLSearchParams(
    typeof window === 'undefined' ? requestQueryString : window.location.search
  );
  return Object.fromEntries(
    (results?.filters?.filterQueryKeys ?? []).map((filterQueryKey) => [
      filterQueryKey,
      queryParameters.getAll(filterQueryKey),
    ])
  );
};

const GetFacetFilterQueryFromFacetFilters = (facetFilters) => {
  let updatedParam = '';
  Object.entries(facetFilters).forEach(([key, value]) => {
    if (Array.isArray(value) && value?.length > 0) {
      const arrayParams = value
        .map((item) => `${key.toLowerCase()}=${item}`)
        .join('&');
      updatedParam += '&' + arrayParams;
    }
  });
  return updatedParam;
};

const GetDateFiltersFromUrlParams = (
  results: GlobalSearchResultProps,
  requestQueryString?: string
) => {
  const queryParameters = new URLSearchParams(
    typeof window === 'undefined' ? requestQueryString : window.location.search
  );
  const entries = Object.fromEntries(
    (results?.filters?.dateFilterQueryKeys ?? []).map((dateFilterQueryKey) => [
      dateFilterQueryKey,
      queryParameters.get(dateFilterQueryKey.toLowerCase()),
    ])
  );
  return entries;
};

const GetDateFilterQueryFromDateFilters = (dateFilters) => {
  let updatedParam = '';
  Object.entries(dateFilters).forEach(([key, value]) => {
    if (value) {
      updatedParam += '&' + key.toLowerCase() + '=' + value;
    }
  });
  return updatedParam;
};

const CreateSearchQueryUrl = (
  pageId,
  query,
  language,
  sortBy,
  page,
  filterQuery,
  dateQuery,
  showHitScore
) => {
  const baseUrl = window.location.origin;
  const endpoint = '/api/GlobalSearchApi/Query';
  return (
    baseUrl +
    endpoint +
    '?pageId=' +
    pageId +
    '&query=' +
    encodeURIComponent(query) +
    '&language=' +
    language +
    '&sort=' +
    sortBy +
    '&page=' +
    page +
    filterQuery +
    dateQuery +
    (showHitScore ? '&score=true' : '')
  );
};

const CreateFacetQueryUrl = (
  pageId,
  query,
  language,
  filterQuery,
  dateQuery
) => {
  const baseUrl = window.location.origin;
  const endpoint = '/api/GlobalSearchApi/FacetQuery';
  return (
    baseUrl +
    endpoint +
    '?pageId=' +
    pageId +
    '&query=' +
    encodeURIComponent(query) +
    '&language=' +
    language +
    filterQuery +
    dateQuery
  );
};

const GlobalSearchUrlUtilities = {
  CreateSearchQueryUrl,
  CreateFacetQueryUrl,
  GetFacetFiltersFromUrlParams,
  GetFacetFilterQueryFromFacetFilters,
  GetDateFiltersFromUrlParams,
  GetDateFilterQueryFromDateFilters,
};

export default GlobalSearchUrlUtilities;
