import React from 'react';
import Dropdown, { DropdownProps } from '/Components/Atoms/Dropdown/Dropdown';
import './DropDownFilterWrapper.scss';
import cn from 'classnames';

export interface Props extends DropdownProps {
  label?: string;
  id: string;
  halfwidth?: boolean;
}

const InputWrapper = (props: Props) => (
  <div
    className={cn('dropdown-filter-wrapper-react', {
      'dropdown-filter-wrapper-react--half-width': props.halfwidth,
    })}
  >
    {props.label && (
      <label
        className="dropdown-filter-wrapper-react__label"
        htmlFor={props.id}
      >
        {props.label}
      </label>
    )}
    <Dropdown {...props} />
  </div>
);
export default InputWrapper;
