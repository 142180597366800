import React from 'react';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import TextInput from '/Components/Atoms/TextInput/TextInput';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import Button from '/Components/Atoms/Button/Button';
import { ChildPageDropDownProps } from '/Models/Generated/ChildPageDropDownProps';
import './PublikasjonerDokumenterlisteFilter.scss';
import { PublikasjonerDokumenterlisteAsyncDataProps } from '/Models/Generated/PublikasjonerDokumenterlisteAsyncDataProps';
import { EposSakslisteTabProps } from '/Models/Generated/EposSakslisteTabProps';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';

export interface Props {
  filterText: string;
  primaryFilterId?: string;
  secondaryFilterId?: string;
  sesjonDropDown: ChildPageDropDownProps;
  session;
  setSession;
  documentNumberTextBox;
  docNumber;
  setDocNumber;
  docType;
  setDocType;
  asyncDataState: PublikasjonerDokumenterlisteAsyncDataProps;
  pageId;
  submitButtonText;
  selectedTab;
  setSelectedTab;
  selectedCommittee;
  setSelectedCommittee;
  selectedPartySingle;
  setSelectedPartySingle;
  selectedPartySeveral;
  setSelectedPartySeveral;
  selectedDistrict;
  setSelectedDistrict;
  selectedRepresentative;
  setSelectedRepresentative;
  selectedMonth;
  setSelectedMonth;
  searchFunction;
  tabChange;
  initialized;
}

const PublikasjonerDokumenterlisteFilter = ({
  filterText,
  primaryFilterId,
  secondaryFilterId,
  sesjonDropDown,
  session,
  setSession,
  documentNumberTextBox,
  docNumber,
  setDocNumber,
  docType,
  setDocType,
  asyncDataState,
  pageId,
  submitButtonText,
  selectedTab,
  setSelectedTab,
  selectedCommittee,
  setSelectedCommittee,
  selectedPartySingle,
  setSelectedPartySingle,
  selectedPartySeveral,
  setSelectedPartySeveral,
  selectedDistrict,
  setSelectedDistrict,
  selectedRepresentative,
  setSelectedRepresentative,
  selectedMonth,
  setSelectedMonth,
  searchFunction,
  tabChange,
  initialized,
}: Props) => {
  const isSelected = (tab, isSelectedByDefault) =>
    isSelectedByDefault ||
    (!isSelectedByDefault &&
      (selectedTab ?? '').toLowerCase() ===
        (tab?.queryValue ?? '').toLowerCase());

  const buildTab = (
    tab: EposSakslisteTabProps,
    key: number,
    isSelectedByDefault = false
  ) => (
    <TabButton
      key={key}
      text={tab.label}
      selected={isSelected(tab, isSelectedByDefault)}
      onClick={() => setSelectedTab(tab.queryValue)}
    />
  );

  const isPublicationNumberSelected = () =>
    !selectedTab ||
    ['party', 'representative', 'date', 'committee'].indexOf(
      (selectedTab + '').toLowerCase()
    ) === -1;

  const tabs = [
    {
      tab: asyncDataState.findRepresentantForslagByTab?.publicationNumberTab,
      isSelected: isPublicationNumberSelected(),
    },
    { tab: asyncDataState.findRepresentantForslagByTab?.politicalPartyTab },
    { tab: asyncDataState.findRepresentantForslagByTab?.spokespersonTab },
    { tab: asyncDataState.findRepresentantForslagByTab?.monthTab },
    {
      tab: asyncDataState.findInnstillingByTab?.publicationNumberTab,
      isSelected: isPublicationNumberSelected(),
    },
    { tab: asyncDataState.findInnstillingByTab?.committeeTab },
    { tab: asyncDataState.findInnstillingByTab?.monthTab },
  ].filter((t) => t.tab);

  const mobileDropdown = {
    selectedValue: isPublicationNumberSelected()
      ? 'PublicationNumber'
      : selectedTab,
    setSelected: setSelectedTab,
    options: tabs.map((t) => ({
      text: t.tab.label,
      value: t.tab.queryValue,
    })),
  };

  return (
    <Row horizontalPadding={HorizontalPadding.none}>
      <ExpandableFilterWrapper scrollId={primaryFilterId} heading={filterText}>
        <div className="publikasjoner-dokumenterliste-filter-react">
          {session && sesjonDropDown && (
            <DropDownFilterWrapper
              id="sesjondropdown"
              label={sesjonDropDown.labelText}
              options={sesjonDropDown.pageLink}
              selectedValue={session}
              setSelected={setSession}
              name="pid"
            />
          )}

          {asyncDataState?.typeDropDown?.pageLink && (
            <DropDownFilterWrapper
              id="dokumentTypeDropDown"
              label={asyncDataState.typeDropDown.labelText}
              options={asyncDataState.typeDropDown.pageLink}
              selectedValue={docType}
              setSelected={setDocType}
              name="dt"
            />
          )}
          {documentNumberTextBox && (
            <div>
              <label
                className="publikasjoner-dokumenterliste-filter-react__pubnr-label"
                htmlFor="documentNumber"
              >
                {documentNumberTextBox.labelText}
              </label>
              <div className="publikasjoner-dokumenterliste-filter-react__pubnr">
                <TextInput
                  id="documentNumber"
                  name="dnid"
                  onChange={setDocNumber}
                  onKeyDown={(e) => {
                    if (e.key === 'NumpadEnter' || e.key === 'Enter')
                      searchFunction();
                  }}
                  value={docNumber}
                  setErrorMessage={() => {
                    //
                  }}
                />
                <Button
                  text={submitButtonText}
                  onClick={() => searchFunction()}
                />
              </div>
            </div>
          )}

          {!!asyncDataState?.findByText && (
            <hr
              id={secondaryFilterId}
              className="publikasjoner-dokumenterliste-filter-react__separator"
            />
          )}
          {initialized && !!tabs.length && (
            <div className="publikasjoner-dokumenterliste-filter-react__tabs">
              <h2>{asyncDataState?.findByText}</h2>
              <TabButtonStrip>
                {tabs.map((tab, idx) =>
                  buildTab(tab.tab, idx, tab.isSelected ?? false)
                )}
              </TabButtonStrip>
            </div>
          )}

          {initialized && !!tabs.length && (
            <div className="publikasjoner-dokumenterliste-filter-react__mobiledropdownfortabs">
              <label htmlFor="publikasjoner-dokumenterliste-filter-react__mobiledropdown">
                <h2>{asyncDataState?.findByText}</h2>
              </label>
              <Dropdown
                {...mobileDropdown}
                id="publikasjoner-dokumenterliste-filter-react__mobiledropdown"
                name="publikasjoner-dokumenterliste-filter-react__mobiledropdown"
              />
            </div>
          )}

          {!tabChange && !!tabs.length && (
            <div className="publikasjoner-dokumenterliste-filter-react__dropdowns">
              {asyncDataState?.committeeDropDown && (
                <DropDownFilterWrapper
                  id="commiteedropdown"
                  label={asyncDataState.committeeDropDown.labelText}
                  options={asyncDataState.committeeDropDown.items}
                  selectedValue={selectedCommittee}
                  setSelected={setSelectedCommittee}
                  name="coid"
                />
              )}

              {asyncDataState?.politicalPartyDropDown && (
                <DropDownFilterWrapper
                  id="partidropdown"
                  label={asyncDataState.politicalPartyDropDown.labelText}
                  options={asyncDataState.politicalPartyDropDown.items}
                  selectedValue={selectedPartySingle}
                  setSelected={setSelectedPartySingle}
                  name="paid"
                />
              )}

              {asyncDataState?.spokespersonSelection
                ?.politicalPartyDropDown && (
                <DropDownFilterWrapper
                  id="partidropdown"
                  label={
                    asyncDataState.spokespersonSelection.politicalPartyDropDown
                      .labelText
                  }
                  options={
                    asyncDataState.spokespersonSelection.politicalPartyDropDown
                      .items
                  }
                  selectedValue={selectedPartySeveral}
                  setSelected={setSelectedPartySeveral}
                  name="paid"
                />
              )}

              {asyncDataState?.spokespersonSelection?.districtDropDown && (
                <DropDownFilterWrapper
                  id="valgdistriktdropdown"
                  label={
                    asyncDataState.spokespersonSelection.districtDropDown
                      .labelText
                  }
                  options={
                    asyncDataState.spokespersonSelection.districtDropDown.items
                  }
                  selectedValue={selectedDistrict}
                  setSelected={setSelectedDistrict}
                  name="cid"
                />
              )}

              {asyncDataState?.spokespersonSelection
                ?.representativeDropDown && (
                <DropDownFilterWrapper
                  id="representativesdropdown"
                  label={
                    asyncDataState.spokespersonSelection.representativeDropDown
                      .labelText
                  }
                  options={
                    asyncDataState.spokespersonSelection.representativeDropDown
                      .items
                  }
                  selectedValue={selectedRepresentative}
                  setSelected={setSelectedRepresentative}
                  name="perid"
                />
              )}

              {asyncDataState?.monthDropDown && (
                <DropDownFilterWrapper
                  id="monthsdropdown"
                  label={asyncDataState.monthDropDown.labelText}
                  options={asyncDataState.monthDropDown.items}
                  selectedValue={selectedMonth}
                  setSelected={setSelectedMonth}
                  name="m"
                />
              )}
            </div>
          )}
          <input type="hidden" name="pageId" value={pageId} />
        </div>
      </ExpandableFilterWrapper>
    </Row>
  );
};

export default PublikasjonerDokumenterlisteFilter;
