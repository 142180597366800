import { useState } from 'react';
import { InputWrapperProps } from '/Components/Molecules/InputWrapper/InputWrapper';

export default (
  props: InputWrapperProps
): [
  InputWrapperProps,
  (val: InputWrapperProps) => void,
  (newVal: object) => void
] => {
  const [obj, setObj] = useState(props);
  const setVal = (newVal) => setObj({ ...obj, value: newVal });
  return [obj, setObj as (val: InputWrapperProps) => void, setVal];
};
