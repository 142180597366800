import React from 'react';
import './LiveMeetingInformationBlockTypeSlider.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import LiveMeetingInformationBlockTypeSliderContent from './LiveMeetingInformationBlockTypeSliderContent';

const LiveMeetingInformationBlockTypeSlider = () => {
  const { cmsData, errorNoData } = useSelector((s: RootState) => s.LiveBlock);
  const title = cmsData.mainBlock.videoBarTitle;
  const errorTitle = cmsData.mainBlock.hearingsUnavailableTitle;
  const errorText = cmsData.mainBlock.hearingsUnavailableMessage;
  const hasData = !errorNoData;
  const showError = cmsData.mainBlock.hearingErrorBottom;
  return (
    <div className="live-meeting-information-block-type-slider-react">
      {hasData && (
        <div className="live-meeting-information-block-type-slider-react__slider-container">
          <h3 className="live-meeting-information-block-type-slider-react__header">
            {title}
          </h3>
          <div className="live-meeting-information-block-type-slider-react__scroll-area">
            <LiveMeetingInformationBlockTypeSliderContent />
          </div>
        </div>
      )}
      {showError && (
        <div className="live-meeting-information-block-type-slider-react__alert-hearings">
          <h3>{errorTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: errorText }}></div>
        </div>
      )}
    </div>
  );
};

const LiveMeetingInformationBlockTypeSliderWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeSlider />
  </Provider>
);

export default LiveMeetingInformationBlockTypeSliderWrapper;
