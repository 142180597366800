import React, { useState } from 'react';
import { SporsmalogsvarFAQlisteProps } from '/Models/Generated/SporsmalogsvarFAQlisteProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import './SporsmalogsvarFAQliste.scss';
import { RichTextTheme } from '../../Organisms/RichText/RichText';
import classNames from 'classnames';

const SporsmalogsvarFAQliste = ({
  showAllAnswers,
  hideAllAnswers,
  faqList,
}: SporsmalogsvarFAQlisteProps) => {
  const [isOpen, setIsOpen] = useState(new Array(faqList.length).fill(false));
  const [showAll, setShowAll] = useState(true);

  const setIsOpenFor = (idx: number, newState: boolean) => {
    const tmp = [...isOpen];
    tmp[idx] = newState;
    setIsOpen(tmp);
    if (tmp.every((i) => i)) setShowAll(false);
    else if (tmp.every((i) => !i)) setShowAll(true);
  };

  const updateShowAll = (newState: boolean) => {
    setIsOpen(new Array(faqList.length).fill(!newState));
    setShowAll(newState);
  };

  return (
    <Row horizontalPadding={HorizontalPadding.indent}>
      <article className="sporsmal-og-svar-faq-liste-react">
        <button
          className="sporsmal-og-svar-faq-liste-react__toggle-all"
          onClick={() => updateShowAll(!showAll)}
        >
          {showAll ? showAllAnswers : hideAllAnswers}
        </button>
        <ul className="sporsmal-og-svar-faq-liste-react__items">
          {faqList &&
            faqList.map((item, idx) => (
              <li key={idx}>
                <h2 className="sporsmal-og-svar-faq-liste-react__item-header">
                  <button
                    onClick={() => setIsOpenFor(idx, !isOpen[idx])}
                    aria-expanded={isOpen[idx]}
                    aria-controls={'answer' + idx}
                  >
                    - {item.header}
                  </button>
                </h2>
                <div
                  id={'answer' + idx}
                  className={classNames(
                    'sporsmal-og-svar-faq-liste-react__item-answer',
                    {
                      'sporsmal-og-svar-faq-liste-react__item-answer--is-open':
                        isOpen[idx],
                    }
                  )}
                >
                  <ContentArea {...item.answer} theme={RichTextTheme.InfoBox} />
                </div>
              </li>
            ))}
        </ul>
      </article>
    </Row>
  );
};

export default SporsmalogsvarFAQliste;
