import React from 'react';
import './TopMenuMobileToggler.scss';
import cn from 'classnames';

interface TopMenuTogglerProps {
  toggle: () => void;
  openLabel: string;
  closeLabel: string;
  isOpen: boolean;
  ariaControls: string;
}

const TopMenuMobileToggler = (props: TopMenuTogglerProps) => {
  if (props)
    return (
      <div
        className={cn('top-menu-mobile-toggler-react', {
          'is-open-react': props.isOpen,
        })}
        aria-expanded={props.isOpen ? 'true' : 'false'}
        aria-pressed={props.isOpen ? 'true' : 'false'}
        aria-controls={props.ariaControls}
        role="button"
      >
        <button onClick={props.toggle}>
          {props.isOpen ? props.closeLabel : props.openLabel}
        </button>
      </div>
    );
  else return <></>;
};

export default TopMenuMobileToggler;
