import React from 'react';
import './Listing.scss';
import { ListingProps } from '/Models/Generated/ListingProps';
import Link from '/Components/Atoms/Link/Link';

const Listing = (props: ListingProps) => {
  return (
    <div className="listing-react">
      <ul className="document-links">
        {props.listingLinks.map((link, idx) => (
          <li key={idx} className="document-link">
            <a href={link.href}>
              {link.imageHref && (
                <img
                  src={link.imageHref}
                  alt={link.imageAltText}
                  title={link.imageTitle}
                />
              )}
              <h2>
                <span>{link.text}</span>
              </h2>
            </a>
            {link.introText && <div>{link.introText}</div>}
            {link.subtitle && <h3>{link.subtitle}</h3>}
            {link.publishDate && <div>{link.publishDate}</div>}
          </li>
        ))}
      </ul>
      {props.goBack && (
        <div className="filter-link">
          <Link {...props.goBack} />
        </div>
      )}
      {props.viewAll && (
        <div className="filter-link">
          <Link {...props.viewAll} />
        </div>
      )}
    </div>
  );
};

export default Listing;
