import React from 'react';
import './FocusItem2.scss';
import { FocusItem2Props } from '/Models/Generated/FocusItem2Props';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const FocusItem2 = ({
  title,
  subtitle,
  introTextHtml,
  link,
  dateSuffix,
  date,
  categories,
}: FocusItem2Props) => {
  return (
    <article className="focus-item-2-react">
      <h3 className="focus-item-2-react__heading">
        <a href={title.href} className="focus-item-2-react__heading__link">
          {title.text}
        </a>
      </h3>

      {subtitle && <h4 className="focus-item-2-react__subtitle">{subtitle}</h4>}
      {link && (
        <div className="focus-item-2-react__content-link">
          <Link {...link} theme={LinkTheme.serif} />
        </div>
      )}

      <p dangerouslySetInnerHTML={{ __html: introTextHtml }} />

      {date && (
        <div className="focus-item-2-react__date">
          <time>{date}</time> | <span>{dateSuffix}</span>
        </div>
      )}

      {categories && (
        <div className="focus-item-2-react__categories">
          <span>{categories}</span>
        </div>
      )}
    </article>
  );
};

export default FocusItem2;
