import React from 'react';
import './BlockPreview.scss';
import { BlockPreviewProps } from '/Models/Generated/BlockPreviewProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { ContentAreaType } from '/Models/Generated/ContentAreaType';
import { ComponentWrapperProps } from '/Models/Generated/ComponentWrapperProps';

const BuildContentArea = (
  type: ContentAreaType,
  ...items: ComponentWrapperProps[]
) => {
  const isInRichTextArea = type === ContentAreaType.RichText;
  items = items.map((wrapper) => ({
    name: wrapper.name,
    component: {
      ...wrapper.component,
    },
  }));
  items.forEach((wrapper) => {
    if (typeof wrapper?.component?.isInRichTextArea !== 'undefined')
      wrapper.component.isInRichTextArea = isInRichTextArea;
  });
  return (
    <ContentArea items={items} contentAreaType={type} propertyName={null} />
  );
};

const BuildRichText = (): ComponentWrapperProps => ({
  name: 'RichText',
  component: {
    html:
      '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ' +
      'quis suscipit tellus, quis tincidunt tellus. Ut at libero quam. Aliquam ' +
      'erat volutpat. Etiam elementum volutpat nunc vitae varius. Nullam dictum ' +
      'felis quis varius euismod. Mauris tempus nisi in gravida volutpat. Maecenas ' +
      'nisl lorem, ornare in vulputate a, eleifend a nunc. Nunc semper lacinia ' +
      'ipsum, non vulputate sem interdum eu. Pellentesque consequat ut elit at ' +
      'vestibulum. Etiam ac nunc nibh. Etiam enim urna, fringilla id sodales nec, ' +
      'euismod ac elit. Praesent tincidunt malesuada ligula et pellentesque. Duis ' +
      'ullamcorper aliquam leo quis consectetur.</p>',
  },
});

const BlockPreview = ({ block }: BlockPreviewProps) => {
  return (
    <>
      <Row
        verticalPadding={VerticalPadding.normal}
        horizontalPadding={HorizontalPadding.normal}
      >
        <h2>In full width content area:</h2>
        {BuildContentArea(ContentAreaType.FullWidth, block)}
        <br />
        <h2>In two columns content area:</h2>
        {BuildContentArea(ContentAreaType.TwoColumns, block, block)}
        <br />
        <h2>In three columns content area:</h2>
        {BuildContentArea(ContentAreaType.ThreeColumns, block, block, block)}
        <br />
        <h2>In four columns content area:</h2>
        {BuildContentArea(ContentAreaType.FourColumns, block, block, block)}
        <br />
        {block?.component?.epiFullRefreshProperties && (
          <input
            type="hidden"
            data-epi-full-refresh-property-names={block.component.epiFullRefreshProperties.join(
              ','
            )}
          />
        )}
      </Row>
      <br style={{ clear: 'both' }} />
      <Row
        verticalPadding={VerticalPadding.normal}
        horizontalPadding={HorizontalPadding.indent}
      >
        <h2>In an indented rich text field:</h2>
        {BuildContentArea(
          ContentAreaType.RichText,
          BuildRichText(),
          block,
          BuildRichText()
        )}
      </Row>
    </>
  );
};

export default BlockPreview;
