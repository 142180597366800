import { useEffect, useState } from 'react';

const useChapterInView = () => {
  const [idInView, setIdInView] = useState('');

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) setIdInView(entry.target.id);
    });
  };

  const observer = new IntersectionObserver(callback, {});

  useEffect(() => {
    document
      .querySelectorAll('[data-bigdoc-section]')
      .forEach((el) => observer.observe(el));

    return () =>
      document
        .querySelectorAll('[data-bigdoc-section]')
        .forEach((el) => observer.unobserve(el));
  }, []);

  return idInView;
};

export default useChapterInView;
