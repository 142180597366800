import { useEffect, useRef, useState } from 'react';

function useElementDimension(
  // eslint-disable-next-line
  elementRef: React.RefObject<any>,
  dimension = 'width'
) {
  const resizingDelayTimer = useRef(null);
  const [elementDimensionValue, setElementDimensionValue] = useState('');

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        if (elementRef.current)
          setElementDimensionValue(entries[0].contentRect[dimension]);
      }, 10);
    });

    observer.observe(elementRef.current);
    return () => observer.unobserve(elementRef.current);
  }, []);

  return elementDimensionValue;
}

export default useElementDimension;
