import React, { useEffect, useState } from 'react';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import './KortvedtakList.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { KortvedtakListProps } from '/Models/Generated/KortvedtakListProps';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import KortvedtakListShowMore from './KortvedtakListShowMore';
import SocialSharing from '/Components/Organisms/SocialSharing/SocialSharing';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import KortvedtakClient from './KortvedtakClient';
import Pagination from '/Components/Atoms/Pagination/Pagination';
import FilterExpander from '/Components/Molecules/FilterExpander/FilterExpander';
import Spinner from '../../Atoms/Spinner/Spinner';

const KortvedtakList = ({
  mainBody,
  mainIntro,
  primaryFilterHeading,
  //endpointByPeriod, //This might not be necessary
  endpointByYear,
  asyncData,
  pageTexts,
  languageCode,
}: KortvedtakListProps) => {
  const [initialized, setInitialized] = useState(false);

  const [doSearch, setDoSearch] = useState(false);
  const [page, setPage] = useState(asyncData?.pagination?.currentPage ?? 1);
  const [pushUrl, setPushUrl] = useState(true);

  const [committee, setCommittee] = useState(
    asyncData?.committeesDropDown?.items.filter((i) => i.isSelected)[0].value ??
      'all'
  );
  const [committeeDropdown, setCommitteeDropdown] = useState(
    asyncData.committeesDropDown?.items ?? []
  );
  const [year, setYear] = useState(
    asyncData?.yearDropDown?.items.filter((i) => i.isSelected)[0]?.value ??
      new Date().getFullYear() + ''
  );
  const [yearDropdown, setYearDropdown] = useState(
    asyncData.yearDropDown?.items ?? []
  );
  const [month, setMonth] = useState(
    asyncData?.yearMonthsDropDown?.items.filter((i) => i.isSelected)[0]
      ?.value ?? 'all'
  );
  const [monthDropdown, setMonthDropdown] = useState(
    asyncData.yearMonthsDropDown?.items ?? []
  );

  const [data, setData] = useState(asyncData);

  useEffect(() => {
    if (!doSearch) return;
    new KortvedtakClient(endpointByYear)
      .Get(languageCode, page - 1, committee, year, month)
      .then((data) => {
        setData(data);
        setYearDropdown(data.yearDropDown.items);
        setCommitteeDropdown(data.committeesDropDown.items);
        setMonthDropdown(data.yearMonthsDropDown.items);
        if (pushUrl) {
          window.history.pushState(
            {},
            null,
            new KortvedtakClient(endpointByYear).BuildFilterUrl(
              committee,
              year,
              month,
              page
            )
          );
        }
        setPushUrl(true);
        setDoSearch(false);
      });
  }, [doSearch]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    setPage(1);
    setDoSearch(true);
  }, [committee, year, month]);

  useEffect(() => setDoSearch(true), [page]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.onpopstate = () => {
      setPushUrl(false);
      const state = new KortvedtakClient(endpointByYear).GetStateFromUrl();
      setCommittee(state.coid);
      setYear(state.y);
      setMonth(state.m);
      setPage(state.p);
    };
  }, []);

  const committeeDropdownLabel =
    'Komité: ' + committeeDropdown.find((el) => el.value === committee)?.text;
  const periodDropdownLabel =
    'Tidsperiode: ' + yearDropdown.find((el) => el.value === year)?.text;

  return (
    <PageWrapper>
      <div className="kortvedtak-list-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainIntro && (
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          )}
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.none}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ExpandableFilterWrapper
            heading={primaryFilterHeading}
            initiallyExpanded={false}
          >
            <div className="kortvedtak-list-react__filter-container">
              <FilterExpander label={committeeDropdownLabel}>
                <DropDownFilterWrapper
                  id="committee"
                  label={asyncData.committeesDropDown?.labelText}
                  name="committee"
                  selectedValue={committee}
                  setSelected={setCommittee}
                  options={committeeDropdown}
                />
              </FilterExpander>
              <FilterExpander label={periodDropdownLabel}>
                <DropDownFilterWrapper
                  id="year"
                  label={asyncData.yearDropDown?.labelText}
                  name="year"
                  selectedValue={year}
                  setSelected={setYear}
                  options={yearDropdown}
                />
                <DropDownFilterWrapper
                  id="month"
                  label={asyncData.yearMonthsDropDown?.labelText}
                  name="month"
                  selectedValue={month}
                  setSelected={setMonth}
                  options={monthDropdown}
                />
              </FilterExpander>
            </div>
          </ExpandableFilterWrapper>
        </Row>
        {!!data?.kortvedtakList && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            {doSearch && <Spinner />}
            {!doSearch && !data.kortvedtakList.length && (
              <div>{pageTexts.didNotFindAnyKortvedtak}</div>
            )}
            {!doSearch && !!data.kortvedtakList.length && (
              <>
                <div className="kortvedtak-list-react__item-summary-top">
                  {data.itemsDisplayedSummary}
                </div>
                <ul className="kortvedtak-list-react__item-list">
                  {data.kortvedtakList.map((group, groupIdx) => (
                    <li key={groupIdx}>
                      <GreyBar text={group.title} />
                      {!!group.kortvedtakList && (
                        <ul className="kortvedtak-list-react__item-list">
                          {group.kortvedtakList.map((item, itemIdx) => (
                            <li key={itemIdx}>
                              <div className="kortvedtak-list-react__item-hidden-info">
                                <span data-type="id">{item.sakId}</span>
                                <span data-type="unix-time">
                                  {item.unixTime}
                                </span>
                                <span data-type="year">{item.yearNumber}</span>
                                <span data-type="month">
                                  {item.monthPadded}
                                </span>
                                <span data-type="year-month">
                                  {item.yearNumber}-{item.monthPadded}
                                </span>
                                <span data-type="entity">
                                  {item.komiteKode}
                                </span>
                              </div>
                              <h2 className="kortvedtak-list-react__item-header">
                                <a href={item.sakSideUrl}>
                                  {item.sakKortTittel}
                                </a>
                              </h2>
                              <div className="kortvedtak-list-react__item-docref">
                                {item.dokumentReferanse}
                              </div>

                              <div className="kortvedtak-list-react__item-text-container">
                                <KortvedtakListShowMore
                                  beforeCutoff={
                                    item.kortvedtakTekstBeforeCutoff
                                  }
                                  afterCutoff={item.kortvedtakTekstAfterCutoff}
                                  showLessText={pageTexts.showLessText}
                                  showMoreText={pageTexts.showMoreText}
                                />
                              </div>
                              <div>
                                <span className="kortvedtak-list-react__item-date">
                                  {item.vedtakDatoLocale}{' '}
                                </span>
                                <div className="kortvedtak-list-react__item-social-sharing">
                                  <SocialSharing
                                    {...item.socialSharing}
                                    tag="div"
                                    useLargerLabel={true}
                                    asLink={true}
                                    staticLink={true}
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="kortvedtak-list-react__item-summary-bottom">
                  {data.itemsDisplayedSummary}
                  <div className="kortvedtak-list-react__item-summary-bottom__pagination-container">
                    {!!data?.pagination && (
                      <Pagination
                        {...data.pagination}
                        onClickFunction={(pageNumber) => setPage(pageNumber)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default KortvedtakList;
