import React, { useState } from 'react';
import { HierarchyNodeProps } from '/Models/Generated/HierarchyNodeProps';
import './TopMenuMobileLink.scss';
import cn from 'classnames';

interface Props extends HierarchyNodeProps {
  isTopLevel: boolean;
  ariaControls?: string;
}

const TopMenuMobileLink = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  let subItemCount = 0;
  if (props)
    return (
      <div className="top-menu-mobile-link-react">
        {props.url && !(props.childNodes?.length > 0) && (
          <div className="no-child-links">
            <a href={props.url} target={props.target}>
              {props.text}
            </a>
          </div>
        )}
        {props.childNodes?.length > 0 && (
          <a
            className={cn(
              'has-child-links',
              { 'is-open-react': isOpen },
              { 'is-top-level': props.isTopLevel }
            )}
            onClick={() => setIsOpen(!isOpen)}
            aria-controls={props.ariaControls}
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-pressed={isOpen ? 'true' : 'false'}
          >
            {props.text}
          </a>
        )}
        {props.childNodes && (
          <div
            id={props.ariaControls}
            className={cn('child-links', { 'is-open-react': isOpen })}
          >
            {props.childNodes.map((node, idx) => (
              <TopMenuMobileLink
                {...node}
                key={idx}
                isTopLevel={false}
                ariaControls={
                  node.childNodes?.length > 0
                    ? props.ariaControls + 'sub-item' + subItemCount++
                    : null
                }
              />
            ))}
          </div>
        )}
      </div>
    );
  else return <></>;
};

export default TopMenuMobileLink;
