export default (
  url: string,
  retainKeys: string[],
  retainAll: boolean,
  setParams
): string => {
  const splitHash = url.split('#');
  const pathAndQuery = splitHash[0];
  const splitQuery = pathAndQuery.split('?');
  const hash = splitHash.length > 1 ? '#' + splitHash[1] : '';
  const path = splitQuery[0];
  const query = splitQuery.length > 1 ? splitQuery[1] : '';
  const searchParamsFrom = new URLSearchParams(query);
  let searchParamsTo = new URLSearchParams();
  (retainKeys ?? []).forEach((k) => {
    if (searchParamsFrom.has(k)) searchParamsTo.set(k, searchParamsFrom.get(k));
  });
  if (retainAll) searchParamsTo = searchParamsFrom;
  if (setParams) {
    Object.keys(setParams).forEach((k) => {
      if (typeof setParams[k] !== 'undefined')
        searchParamsTo.set(k, setParams[k]);
    });
  }
  return path + '?' + searchParamsTo.toString() + hash;
};
