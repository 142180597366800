import React from 'react';
import './ResultKeywords.scss';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useSelector } from 'react-redux';

interface Props {
  keywords?: string;
}

const ResultKeywords = ({ keywords }: Props) => {
  const labels = useSelector((state: RootState) => state.Search.labels);
  return keywords ? (
    <p className="results-keywords">
      <span className="label-react">{labels.keywords}</span>
      <span dangerouslySetInnerHTML={{ __html: keywords }}></span>
    </p>
  ) : (
    <></>
  );
};

const ResultKeywordsWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultKeywords {...props} />
  </Provider>
);

export default ResultKeywordsWrapper;
