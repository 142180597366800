import React, { useEffect, useState } from 'react';
import './LiveMeetingInformationBlockTypeTopTextBoxBottomButtons.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import lang from './lang';
import mixins from './mixins';
import classNames from 'classnames';

const LiveMeetingInformationBlockTypeTopTextBoxBottomButtons = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);

  const [urlAgenda, setUrlAgenda] = useState(undefined as string);
  const textAgenda = lang.agenda[cmsData.lang];
  const urlSpeakerList = cmsData.mainBlock.speakerListUrl;
  const textSpeakerList = lang.speakerList[cmsData.lang];
  const [urlHearingAgenda, setUrlHearingAgenda] = useState(undefined as string);
  const textHearingAgenda = lang.hearingAgenda[cmsData.lang];
  const urlVideoArchive = cmsData.mainBlock.tvArchiveUrl;
  const textVideoArchive = lang.videoArchive[cmsData.lang];
  const showButtonsForMeeting = () =>
    mixins().shouldShowMeetingButtons(cmsData, liveData);
  const showButtonsForHearing = () =>
    mixins().shouldShowHearingButtons(cmsData, liveData);

  useEffect(() => {
    if (showButtonsForMeeting()) {
      setUrlAgenda(mixins().agendaUrl(liveData));
    } else if (showButtonsForHearing()) {
      setUrlHearingAgenda(mixins().hearingProgramUrl(cmsData, liveData));
    }
  }, []);
  return (
    <ul
      className={classNames(
        'live-meeting-information-block-type-top-text-box-bottom-buttons-react',
        {
          'live-meeting-information-block-type-top-text-box-bottom-buttons-react__hearings':
            showButtonsForHearing(),
        }
      )}
    >
      {showButtonsForMeeting() && (
        <>
          <li className="live-meeting-information-block-type-top-text-box-bottom-buttons-react__list-item">
            <a
              href={urlAgenda}
              className={classNames(
                'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon',
                'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon--calendar'
              )}
            >
              {textAgenda}
            </a>
          </li>
          <li className="live-meeting-information-block-type-top-text-box-bottom-buttons-react__list-item">
            <a
              href={urlSpeakerList}
              className={classNames(
                'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon',
                'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon--speech'
              )}
            >
              {textSpeakerList}
            </a>
          </li>
        </>
      )}
      {showButtonsForHearing() && (
        <li className="live-meeting-information-block-type-top-text-box-bottom-buttons-react__list-item">
          <a
            href={urlHearingAgenda}
            className={classNames(
              'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon',
              'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon--calendar'
            )}
          >
            {textHearingAgenda}
          </a>
        </li>
      )}
      <li className="live-meeting-information-block-type-top-text-box-bottom-buttons-react__list-item">
        <a
          href={urlVideoArchive}
          className={classNames(
            'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon',
            'live-meeting-information-block-type-top-text-box-bottom-buttons-react__icon--video'
          )}
        >
          {textVideoArchive}
        </a>
      </li>
    </ul>
  );
};

const LiveMeetingInformationBlockTypeTopTextBoxBottomButtonsWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopTextBoxBottomButtons />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopTextBoxBottomButtonsWrapper;
