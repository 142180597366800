import React, { useEffect, useState } from 'react';
import './XmlImportReferat.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { XmlImportReferatProps } from '/Models/Generated/XmlImportReferatProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import Panel from '/Components/Atoms/Panel/Panel';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import XmlImportReferatHeader from '/Components/Organisms/XmlImportReferatHeader/XmlImportReferatHeader';
import XmlImportReferatNavigationBar from '/Components/Organisms/XmlImportReferatNavigationBar/XmlImportReferatNavigationBar';
import XmlImportNavigation from '/Components/Organisms/XmlImportNavigation/XmlImportNavigation';
import DocumentContentList from '/Components/Organisms/DocumentContentList/DocumentContentList';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import SpeakerList from '/Components/Organisms/SpeakerList/SpeakerList';
import XmlImportReferatClient from '/Shared/Code/XmlImportReferat/XmlImportReferatClient';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const XmlImportReferat = ({
  showFrontpage,
  showContent,
  showAll,
  headerProps,
  navigationBarProps,
  navigationProps,
  showNavigation,
  showSearchNavigation,
  searchNavigationProps,
  incompletePanelProps,
  tableOfContentsProps,
  title,
  intro,
  speakerList,
  mainBody,
  minutesUrlParameterEndSection,
  meetingConclusion,
  previousLink,
  nextLink,
  showContentAreaBottom,
  threeColumnContentAreaBottom,
}: XmlImportReferatProps) => {
  const [formattedMainBody, setFormattedMainBody] = useState(null);

  const client = new XmlImportReferatClient(
    navigationBarProps.hiddenVideoLinks?.map((el) => el.href)
  );

  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }

    if (mainBody?.items?.[0]?.component?.html) {
      mainBody.items[0].component.html = client.FormatTextWithLinks(
        mainBody.items[0].component.html
      );
    }

    setFormattedMainBody(mainBody);
  }, []);
  return (
    <PageWrapper>
      <div className="xmlimportreferat-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <XmlImportReferatHeader {...headerProps} />
            <XmlImportReferatNavigationBar {...navigationBarProps} />
          </MinutesHeaderContainer>
        </Row>
        {showFrontpage && showNavigation && (
          <Row horizontalPadding={HorizontalPadding.normal} noPaddingOnMobile>
            <XmlImportNavigation
              {...navigationProps}
              searchNavigationProps={searchNavigationProps}
              showSearchNavigation={showSearchNavigation}
            />
          </Row>
        )}
        {showFrontpage && incompletePanelProps && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <Panel title={incompletePanelProps.title}>
              <p>{incompletePanelProps.text}</p>
            </Panel>
          </Row>
        )}
        {showFrontpage && tableOfContentsProps && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingTop={VerticalPadding.xlarge}
          >
            <DocumentContentList {...tableOfContentsProps} />
          </Row>
        )}
        {showContent && !showAll && (
          <Row horizontalPadding={HorizontalPadding.normal} noPaddingOnMobile>
            <XmlImportNavigation
              {...navigationProps}
              searchNavigationProps={searchNavigationProps}
              showSearchNavigation={showSearchNavigation}
            />
          </Row>
        )}
        {showContent && !showAll && incompletePanelProps && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <Panel title={incompletePanelProps.title}>
              <p>{incompletePanelProps.text}</p>
            </Panel>
          </Row>
        )}
        {showContent && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingTop={VerticalPadding.large}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <h2>{title}</h2>
            {intro && (
              <p className="xmlimportreferat-react__main-intro">{intro}</p>
            )}
          </Row>
        )}
        {showContent && speakerList && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <SpeakerList {...speakerList} />
          </Row>
        )}
        {showContent && formattedMainBody && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.large}
          >
            {/* <div dangerouslySetInnerHTML={{ __html: content }}></div> */}
            <div className="page-publication">
              <div className="bigdoc-content">
                <ContentArea
                  theme={RichTextTheme.BigDoc}
                  {...formattedMainBody}
                />
              </div>
            </div>
          </Row>
        )}
        {showContent && showAll && (
          <div id={minutesUrlParameterEndSection}>
            <p dangerouslySetInnerHTML={{ __html: meetingConclusion }}></p>
          </div>
        )}
        <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
      </div>
      {showContentAreaBottom && HasContent(threeColumnContentAreaBottom) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaBottom} />
        </Row>
      )}
    </PageWrapper>
  );
};

export default XmlImportReferat;
