/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import './TextArea.scss';
import { ExtendedFormInputProps } from '/Models/FrontendOnly/FormInputs/ExtendedFormInputProps';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';

const TextArea = (props: ExtendedFormInputProps) => {
  const inputEl = useRef<HTMLTextAreaElement>(null);

  const onBlur = () => {
    const errorMessages = GetFormInputErrorMessages({
      ...props,
      value: inputEl.current.value,
    });

    if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
    else props.setErrorMessage('');
  };
  return (
    <textarea
      className="text-area-react"
      ref={inputEl}
      id={props.id}
      name={props.name}
      value={props.value}
      maxLength={props.maxLength}
      defaultValue={props.defaultValue}
      onChange={(e: React.SyntheticEvent) => props.onChange(e.target.value)}
      disabled={props.disabled}
      onBlur={onBlur}
    />
  );
};

export default TextArea;
