import React from 'react';
import './EventInformation.scss';
import { EventInformationProps } from '/Models/Generated/EventInformationProps';
import ColumnContentWrapper from '../../Layout/ColumnContentWrapper/ColumnContentWrapper';
import classNames from 'classnames';

const EventInformation = ({
  eventInformationTitle,
  eventInformationTitlePropertyName,
  eventTime,
  locationFull,
  locationFullPropertyName,
  openForPropertyName,
  pageName,
  registrationDeadline,
  registrationDeadlinePropertyName,
  registrationDeadlineText,
  rightColumnOpenForText,
  rightColumnTitleText,
  rightColumnWhenText,
  rightColumnWhereText,
  openFor,
}: EventInformationProps) => {
  return (
    <ColumnContentWrapper
      title={eventInformationTitle}
      titlePropertyName={eventInformationTitlePropertyName}
      customTag="article"
    >
      <div className="event-information-react">
        {pageName && (
          <>
            <strong>{rightColumnTitleText}</strong>
            <p>{pageName}</p>
          </>
        )}
        {eventTime && (
          <>
            <strong>{rightColumnWhenText}</strong>
            <p dangerouslySetInnerHTML={{ __html: eventTime }} />
          </>
        )}
        {(locationFull || locationFullPropertyName) && (
          <>
            <strong>{rightColumnWhereText}</strong>
            <p
              data-epi-property-name={locationFullPropertyName}
              className={classNames({
                'epi-editContainer': locationFullPropertyName,
              })}
            >
              {locationFull}
            </p>
          </>
        )}
        {(registrationDeadlineText || registrationDeadlinePropertyName) && (
          <>
            <strong>{registrationDeadlineText}</strong>
            <p
              data-epi-property-name={registrationDeadlinePropertyName}
              className={classNames({
                'epi-editContainer': registrationDeadlinePropertyName,
              })}
            >
              {registrationDeadline}
            </p>
          </>
        )}
        {(openFor || openForPropertyName) && (
          <>
            <strong>{rightColumnOpenForText}</strong>
            <p
              data-epi-property-name={openForPropertyName}
              className={classNames({
                'epi-editContainer': openForPropertyName,
              })}
              dangerouslySetInnerHTML={{ __html: openFor }}
            />
          </>
        )}
      </div>
    </ColumnContentWrapper>
  );
};

export default EventInformation;
