import React from 'react';
import './QuoteForXhtmlBlockType.scss';
import { QuoteForXhtmlBlockTypeProps } from '/Models/Generated/QuoteForXhtmlBlockTypeProps';
import BlockWrapper, {
  BlockWrapperAlignment,
} from '/Components/Layout/BlockWrapper/BlockWrapper';

const QuoteForXhtmlBlockType = ({
  isInRichTextArea,
  quote,
  quotePropertyName,
  quoteBy,
  quoteByPropertyName,
  rightAligned,
}: QuoteForXhtmlBlockTypeProps) => {
  const alignment = !isInRichTextArea
    ? BlockWrapperAlignment.None
    : rightAligned
    ? BlockWrapperAlignment.Right
    : BlockWrapperAlignment.Left;

  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea} alignment={alignment}>
      <blockquote className="quote-for-xhtml-block-type-react">
        <p
          className="quote-for-xhtml-block-type-react__quote"
          data-epi-edit={quotePropertyName}
        >
          {quote}
        </p>
        <footer>
          <cite
            className="quote-for-xhtml-block-type-react__quote-by"
            data-epi-edit={quoteByPropertyName}
          >
            {quoteBy}
          </cite>
        </footer>
      </blockquote>
    </BlockWrapper>
  );
};

export default QuoteForXhtmlBlockType;
