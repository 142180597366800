import React, { useEffect, useState, useRef } from 'react';
import { VideoClipEditorProps } from '../../../Models/Generated/VideoClipEditorProps';
import './VideoClipTimePicker.scss';

interface Props extends VideoClipEditorProps {
  setPlayerPosition;
  visualPlayerPosition;

  videoDuration: number;
  clipStart: number;
  setClipStart;
  clipEnd: number;
  setClipEnd;
  setPaused;
}

const incrementSize = 1;

const timeFromSeconds = (totalSeconds: number) => {
  const hoursReminder = totalSeconds % 3600;
  const hoursSeconds = totalSeconds - hoursReminder;

  const minutesReminder = hoursReminder % 60;
  const minutesSeconds = hoursReminder - minutesReminder;

  return {
    hours: hoursSeconds / 3600,
    minutes: minutesSeconds / 60,
    seconds: minutesReminder,
  };
};

const timeLabelFromSeconds = (totalSeconds: number): string => {
  const time = timeFromSeconds(totalSeconds);

  return (
    (time.hours ? (time.hours + '').padStart(2, '0') + ':' : '') +
    (time.minutes + ':').padStart(3, '0') +
    ('' + Math.floor(time.seconds)).padStart(2, '0')
  );
};

const fillWithTime = (stringToFill: string, totalSeconds: number) => {
  const time = timeFromSeconds(totalSeconds);

  return stringToFill
    .replace('{0}', '' + time.hours)
    .replace('{1}', '' + time.minutes)
    .replace('{2}', '' + Math.floor(time.seconds));
};

const VideoClipTimePicker = (props: Props) => {
  const playHandleRef = useRef(null);
  const timelineRef = useRef(null);

  const calculateTimeLinePixelPosition = (timelineSecondValue) => {
    return (timelineSecondValue / props.videoDuration) * 100;
  };

  const [playHandleSecondValue, setPlayHandleSecondValue] = useState(
    props.visualPlayerPosition
  );
  const [startHandleSecondValue, setStartHandleSecondValue] = useState(
    props.clipStart
  );
  const [endHandleSecondValue, setEndHandleSecondValue] = useState(
    props.clipEnd
  );

  const [grabbingStartHandle, setGrabbingStartHandle] = useState(false);
  const [grabbingEndHandle, setGrabbingEndHandle] = useState(false);
  const [grabbingPlayHandle, setGrabbingPlayHandle] = useState(false);

  const [playHandlePosition, setPlayHandlePosition] = useState(
    calculateTimeLinePixelPosition(playHandleSecondValue)
  );
  const [startHandlePosition, setStartHandlePosition] = useState(
    calculateTimeLinePixelPosition(startHandleSecondValue)
  );
  const [endHandlePosition, setEndHandlePosition] = useState(
    calculateTimeLinePixelPosition(endHandleSecondValue)
  );
  const [selectedAreaLeftPosition, setSelectedAreaLeftPosition] = useState(
    calculateTimeLinePixelPosition(startHandleSecondValue)
  );
  const [selectedAreaRightPosition, setSelectedAreaRightPosition] = useState(
    calculateTimeLinePixelPosition(props.videoDuration - endHandleSecondValue)
  );

  const setPlayerHandle = (seconds) => {
    seconds = Math.floor(seconds);

    if (seconds < 0) {
      seconds = 0;
    }
    if (seconds > props.videoDuration) {
      seconds = props.videoDuration;
    }
    setPlayHandleSecondValue(seconds);
    setPlayHandlePosition(calculateTimeLinePixelPosition(seconds));
    props.setPlayerPosition(seconds);
  };

  const setClipStart = (seconds) => {
    seconds = Math.floor(seconds);

    if (seconds < 0) {
      seconds = 0;
    }
    if (seconds > endHandleSecondValue) {
      seconds = endHandleSecondValue;
    }
    setStartHandleSecondValue(seconds);
    props.setClipStart(seconds);
    setStartHandlePosition(calculateTimeLinePixelPosition(seconds));
    setSelectedAreaLeftPosition(calculateTimeLinePixelPosition(seconds));
  };

  const setClipEnd = (seconds) => {
    seconds = Math.floor(seconds);

    if (seconds > props.videoDuration) {
      seconds = props.videoDuration;
    }
    if (seconds < startHandleSecondValue) {
      seconds = startHandleSecondValue;
    }
    setEndHandleSecondValue(seconds);
    props.setClipEnd(seconds);
    setEndHandlePosition(calculateTimeLinePixelPosition(seconds));
    setSelectedAreaRightPosition(
      calculateTimeLinePixelPosition(props.videoDuration - seconds)
    );
  };

  const handleKeyDownLeft = (event) => {
    const key = event.code;
    switch (key) {
      case 'ArrowLeft':
        setClipStart(startHandleSecondValue - incrementSize);
        break;
      case 'ArrowDown':
        setClipStart(startHandleSecondValue - incrementSize);
        break;
      case 'ArrowRight':
        setClipStart(startHandleSecondValue + incrementSize);
        break;
      case 'ArrowUp':
        setClipStart(startHandleSecondValue + incrementSize);
        break;
      case 'Home':
        setClipStart(0);
        break;
      case 'End':
        setClipStart(endHandleSecondValue);
        break;
    }
  };
  const handleKeyDownRight = (event) => {
    const key = event.code;
    switch (key) {
      case 'ArrowLeft':
        setClipEnd(endHandleSecondValue - incrementSize);
        break;
      case 'ArrowDown':
        setClipEnd(endHandleSecondValue - incrementSize);
        break;
      case 'ArrowRight':
        setClipEnd(endHandleSecondValue + incrementSize);
        break;
      case 'ArrowUp':
        setClipEnd(endHandleSecondValue + incrementSize);
        break;
      case 'Home':
        setClipEnd(startHandleSecondValue);
        break;
      case 'End':
        setClipEnd(props.videoDuration);
        break;
    }
  };
  const handleKeyDownPointer = (event) => {
    const key = event.code;
    switch (key) {
      case 'ArrowLeft':
        setPlayerHandle(playHandleSecondValue - incrementSize);
        break;
      case 'ArrowDown':
        setPlayerHandle(playHandleSecondValue - incrementSize);
        break;
      case 'ArrowRight':
        setPlayerHandle(playHandleSecondValue + incrementSize);
        break;
      case 'ArrowUp':
        setPlayerHandle(playHandleSecondValue + incrementSize);
        break;
      case 'Home':
        setPlayerHandle(0);
        break;
      case 'End':
        setPlayerHandle(props.videoDuration);
        break;
    }
  };

  const handlePointerMovement = (timelinePercent) => {
    let secondValue = timelinePercent * props.videoDuration;
    secondValue = Math.floor(secondValue);

    if (grabbingStartHandle) {
      if (secondValue > endHandleSecondValue) {
        secondValue = endHandleSecondValue;
      }
      setPlayHandleSecondValue(secondValue);
      setPlayHandlePosition(calculateTimeLinePixelPosition(secondValue));
      setClipStart(secondValue);
    }
    if (grabbingEndHandle) {
      if (secondValue < startHandleSecondValue) {
        secondValue = startHandleSecondValue;
      }
      setPlayHandleSecondValue(secondValue);
      setPlayHandlePosition(calculateTimeLinePixelPosition(secondValue));
      setClipEnd(secondValue);
    }
    if (grabbingPlayHandle) {
      setPlayHandleSecondValue(secondValue);
      setPlayHandlePosition(calculateTimeLinePixelPosition(secondValue));
      setTimeout(() => {
        playHandleRef.current.focus();
      });
    }
  };

  const handleTouchMovement = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const timelineBounds = timelineRef.current.getBoundingClientRect();
    const offsetX = event.targetTouches[0].clientX - timelineBounds.x;
    const timeLineMoveXPercent = offsetX / timelineWidth;

    handlePointerMovement(timeLineMoveXPercent);
  };

  const handleMouseMovement = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const offsetX = event.nativeEvent.offsetX;
    const timeLineMoveXPercent = offsetX / timelineWidth;

    handlePointerMovement(timeLineMoveXPercent);
  };

  const handlePlayHandleMouse = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const offsetX = event.nativeEvent.offsetX;
    const timeLineClickXPercent = offsetX / timelineWidth;
    const secondValue = timeLineClickXPercent * props.videoDuration;

    props.setPaused(true);
    setGrabbingPlayHandle(true);
    setPlayHandleSecondValue(secondValue);
    setPlayHandlePosition(calculateTimeLinePixelPosition(secondValue));

    setTimeout(() => {
      event.nativeEvent.target.focus();
    });
  };

  const handlePlayHandleTouch = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const timelineBounds = timelineRef.current.getBoundingClientRect();
    const offsetX = event.targetTouches[0].clientX - timelineBounds.x;
    const timeLineMoveXPercent = offsetX / timelineWidth;
    const secondValue = timeLineMoveXPercent * props.videoDuration;

    props.setPaused(true);
    setGrabbingPlayHandle(true);
    setPlayHandleSecondValue(secondValue);
    setPlayHandlePosition(calculateTimeLinePixelPosition(secondValue));

    setTimeout(() => {
      event.nativeEvent.target.focus();
    });
  };

  const handleStartHandleGrab = (event) => {
    event.stopPropagation();

    props.setPaused(true);
    setGrabbingStartHandle(true);

    setTimeout(() => {
      event.nativeEvent.target.focus();
    });
  };

  const handleEndHandleGrab = (event) => {
    event.stopPropagation();

    props.setPaused(true);
    setGrabbingEndHandle(true);

    setTimeout(() => {
      event.nativeEvent.target.focus();
    });
  };

  const globalGrabEnd = () => {
    setGrabbingStartHandle(false);
    setGrabbingEndHandle(false);
    setGrabbingPlayHandle(false);
  };

  useEffect(() => {
    if (grabbingPlayHandle === false) {
      setPlayerHandle(playHandleSecondValue);
      setTimeout(() => {
        playHandleRef.current.focus();
      });
    }
  }, [grabbingPlayHandle]);

  useEffect(() => {
    if (grabbingStartHandle === false) {
      setPlayerHandle(startHandleSecondValue);
    }
  }, [grabbingStartHandle]);

  useEffect(() => {
    if (grabbingEndHandle === false) {
      setPlayerHandle(endHandleSecondValue);
    }
  }, [grabbingEndHandle]);

  useEffect(() => {
    //render only on client
    if (typeof window === 'undefined') return;

    window.addEventListener('mouseup', () => {
      globalGrabEnd();
    });
    window.addEventListener('touchend', () => {
      globalGrabEnd();
    });
  }, []);

  useEffect(() => {
    setClipEnd(props.videoDuration);
    setPlayHandleSecondValue(props.visualPlayerPosition);
    setPlayHandlePosition(
      calculateTimeLinePixelPosition(props.visualPlayerPosition)
    );
  }, [props.videoDuration]);

  useEffect(() => {
    setPlayHandleSecondValue(props.visualPlayerPosition);
    setPlayHandlePosition(
      calculateTimeLinePixelPosition(props.visualPlayerPosition)
    );
  }, [props.visualPlayerPosition]);

  useEffect(() => {
    setStartHandlePosition(calculateTimeLinePixelPosition(props.clipStart));
    setSelectedAreaLeftPosition(
      calculateTimeLinePixelPosition(props.clipStart)
    );
  }, [props.clipStart]);

  useEffect(() => {
    setEndHandlePosition(calculateTimeLinePixelPosition(props.clipEnd));
    setSelectedAreaRightPosition(
      calculateTimeLinePixelPosition(props.videoDuration - props.clipEnd)
    );
  }, [props.clipEnd]);

  return (
    <div
      ref={timelineRef}
      className="video-clip-time-picker"
      // tabIndex={0}
      onMouseDown={handlePlayHandleMouse}
      onTouchStart={handlePlayHandleTouch}
      onMouseMove={handleMouseMovement}
      onTouchMove={handleTouchMovement}
    >
      <div
        className="selected-time"
        style={{
          left: selectedAreaLeftPosition + '%',
          right: selectedAreaRightPosition + '%',
        }}
      ></div>
      <div className="line-marker"></div>
      <div
        className="time-handle"
        tabIndex={0}
        role="slider"
        aria-label={props.startHandleTimeLabel}
        aria-valuenow={startHandleSecondValue}
        aria-valuetext={fillWithTime(
          props.startHandleTimeValue,
          startHandleSecondValue
        )}
        aria-valuemin={0}
        aria-valuemax={endHandleSecondValue}
        onKeyDown={handleKeyDownLeft}
        style={{
          left: startHandlePosition + '%',
          zIndex: grabbingStartHandle ? 3 : 2,
          pointerEvents:
            grabbingPlayHandle || grabbingStartHandle || grabbingEndHandle
              ? 'none'
              : 'auto',
          touchAction:
            grabbingPlayHandle || grabbingStartHandle || grabbingEndHandle
              ? 'none'
              : 'auto',
        }}
        onMouseDown={handleStartHandleGrab}
        onTouchStart={handleStartHandleGrab}
      >
        <div className="time-handle__tooltip">
          {timeLabelFromSeconds(startHandleSecondValue)}
        </div>
      </div>
      <div
        className="play-handle"
        ref={playHandleRef}
        role="slider"
        aria-label={props.playHandleTimeLabel}
        aria-valuenow={playHandleSecondValue}
        aria-valuetext={fillWithTime(
          props.playHandleTimeValue,
          playHandleSecondValue
        )}
        aria-valuemin={0}
        aria-valuemax={props.videoDuration}
        onKeyDown={handleKeyDownPointer}
        tabIndex={0}
        style={{
          left: playHandlePosition + '%',
          pointerEvents: 'none',
          touchAction: 'none',
          zIndex: grabbingPlayHandle ? 3 : 1,
        }}
      >
        <div className="play-handle__tooltip">
          {timeLabelFromSeconds(playHandleSecondValue)}
        </div>
      </div>
      <div
        className="time-handle"
        onKeyDown={handleKeyDownRight}
        role="slider"
        aria-label={props.endHandleTimeLabel}
        aria-valuenow={endHandleSecondValue}
        aria-valuetext={fillWithTime(
          props.endHandleTimeValue,
          endHandleSecondValue
        )}
        aria-valuemin={startHandleSecondValue}
        aria-valuemax={props.videoDuration}
        tabIndex={0}
        style={{
          left: endHandlePosition + '%',
          zIndex: grabbingEndHandle ? 3 : 2,
          pointerEvents:
            grabbingPlayHandle || grabbingStartHandle || grabbingEndHandle
              ? 'none'
              : 'auto',
          touchAction:
            grabbingPlayHandle || grabbingStartHandle || grabbingEndHandle
              ? 'none'
              : 'auto',
        }}
        onMouseDown={handleEndHandleGrab}
        onTouchStart={handleEndHandleGrab}
      >
        <div className="time-handle__tooltip">
          {timeLabelFromSeconds(endHandleSecondValue)}
        </div>
      </div>
    </div>
  );
};

export default VideoClipTimePicker;
