import React from 'react';
import './FocusItem4.scss';
import { LinkProps } from '../../../Models/Generated/LinkProps';

export interface FocusItem4Props {
  proccedingLink: LinkProps;
  formalTitle: string;
  abstract: string;
  department: string;
  beforeDateString: string;
  shortDateString: string;
  proceedingTypeName: string;
}

const FocusItem4 = ({
  proccedingLink,
  formalTitle,
  abstract,
  department,
  beforeDateString,
  shortDateString,
  proceedingTypeName,
}: FocusItem4Props) => {
  return (
    <article className="focus-item-4-react">
      <h3 className="focus-item-4-react__heading">
        <a
          href={proccedingLink.href}
          className="focus-item-4-react__heading__link"
        >
          {proccedingLink.text}
        </a>
      </h3>

      {formalTitle && (
        <h4 className="focus-item-4-react__subtitle">{formalTitle}</h4>
      )}

      <p dangerouslySetInnerHTML={{ __html: abstract }} />
      {department && (
        <div className="focus-item-4-react__department">
          <span>{department}</span>
        </div>
      )}
      {shortDateString && (
        <div className="focus-item-4-react__date">
          <span>{beforeDateString}</span>
          <span>{shortDateString}</span>
        </div>
      )}

      {proceedingTypeName && (
        <div className="focus-item-4-react__proceeding-type-name">
          <span>{proceedingTypeName}</span>
        </div>
      )}
    </article>
  );
};

export default FocusItem4;
