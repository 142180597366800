import React, { useState } from 'react';
import './ResultBiographyGoverment.scss';
import ResultHeader from './Shared/ResultHeader';
import ResultSubscription from './Shared/ResultSubscription';
import ResultSummary from './Shared/ResultSummary';
import cn from 'classnames';
import { Props } from '/Models/FrontendOnly/Search/Results';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import LinkButton, {
  LinkButtonTheme,
} from '/Components/Atoms/LinkButton/LinkButton';
import {
  clearSettings,
  setCategoryId,
  setDoSearch,
  setFilter,
} from '/Shared/Code/Search/SearchSlice';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const ResultBiographyGoverment = (props: Props) => {
  const { image, title, summary, relevance, url, id, specifics } = props;
  const [isOpen, setIsOpen] = useState(false);
  const labels = useSelector((state: RootState) => state.Search.labels);
  const dispatch = useDispatch();

  const doSpecificSearch = (filter: string, categoryId: number) => {
    dispatch(clearSettings());
    dispatch(setCategoryId(categoryId));
    dispatch(setFilter(filter));
    dispatch(setDoSearch(true));
  };

  return (
    <article className="search-result-biography-goverment-article-react">
      <div className="info-row-react">
        <div className="image-container-react">
          {image && (
            <figure>
              <img src={image} alt={title} />
            </figure>
          )}
        </div>
        <div className="info-react">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <ResultSummary summary={summary} />
        </div>
        <ul className="links-react">
          <li>
            <Link
              href={url}
              text={labels.biographyLink}
              theme={LinkTheme.sans}
              darkerPink={true}
            />
          </li>
          <li>
            <LinkButton
              text={labels.postsLink}
              theme={LinkButtonTheme.sans}
              onClick={() => doSpecificSearch('G761', specifics.sections.posts)}
            />
          </li>
          <li>
            <LinkButton
              text={labels.suggestionsLink}
              theme={LinkButtonTheme.sans}
              onClick={() =>
                doSpecificSearch('G763', specifics.sections.suggestions)
              }
            />
          </li>
          <li>
            <LinkButton
              text={labels.questionsLink}
              theme={LinkButtonTheme.sans}
              onClick={() =>
                doSpecificSearch('G762', specifics.sections.questions)
              }
            />
          </li>
        </ul>
        <ResultHeader relevance={relevance} />
      </div>

      <button
        className={cn('subscription-button', { 'is-open-react': isOpen })}
        aria-controls={id}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {labels.subscriptionOpen}
      </button>

      <div
        id={id}
        className={cn('subscription-dropdown-container', {
          'visible-react': isOpen,
        })}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <strong>
          <ResultSubscription {...props} />
        </strong>
      </div>
    </article>
  );
};

const ResultBiographyGovermentWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultBiographyGoverment {...props} />
  </Provider>
);
export default ResultBiographyGovermentWrapper;
