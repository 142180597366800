import React, { useState } from 'react';
import './MoreResults.scss';
import { Provider, useSelector } from 'react-redux';
import SearchClient from '/Shared/Code/Search/SearchClient';
import { Props } from '/Models/FrontendOnly/Search/Results';
import LinkButton from '/Components/Atoms/LinkButton/LinkButton';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import ResultChilds from '/Components/Pages/SearchPage/SearchResults/Shared/ResultChilds';

const MoreResults = ({ childs, specifics, udid }: Props) => {
  const [childState, setChildState] = useState(childs);
  const [fetchedChilds, setFetchedChilds] = useState<Props[]>(null);
  const language = useSelector((state: RootState) => state.Search.language);
  const labels = useSelector((state: RootState) => state.Search.labels);

  const searchClientProps = useSelector(
    (state: RootState) => state.Search.searchClientProps
  );

  const query = useSelector(
    (state: RootState) => state.Search.results
  ).GetQuery();

  const ExtractChilds = (results) => {
    if (!results) return null;
    const parent = results.filter((res) => res.udid === udid);
    if (parent.length === 1) return parent[0].childs;
    for (let i = 0; i < results.length; ++i) {
      const childs = ExtractChilds(results[i].childs);
      if (childs) return childs;
    }
    return null;
  };

  const ShowMoreResults = () => {
    if (fetchedChilds !== null) setChildState(fetchedChilds);
    else {
      new SearchClient(searchClientProps)
        .SubSearch(
          query,
          specifics.childsFilter,
          language,
          searchClientProps.replaceFrom,
          searchClientProps.replaceTo
        )
        .then((res) => {
          const tmp = ExtractChilds(res.data.results) ?? [];
          setChildState(tmp);
          setFetchedChilds(tmp);
        });
    }
  };

  return (
    <div>
      <ResultChilds childs={childState} showChilds={true} />
      {specifics.childsFilter && (
        <strong className="more-results-react">
          {childState === fetchedChilds && (
            <LinkButton
              onClick={() => {
                setChildState(childs);
              }}
              text={labels.toggleMoreResults}
            />
          )}
          {childState !== fetchedChilds && (
            <LinkButton
              onClick={() => ShowMoreResults()}
              text={labels.getMoreResults}
            />
          )}
        </strong>
      )}
    </div>
  );
};
const MoreResultsWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <MoreResults {...props} />
  </Provider>
);

export default MoreResultsWrapper;
