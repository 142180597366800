import React, { useState } from 'react';
import './EPOSSakVoteringResult.scss';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { VotingResultProps } from '/Models/Generated/VotingResultProps';
import cn from 'classnames';
import { VotingResultEnum } from '/Models/Generated/VotingResultEnum';
import { VotingAlternativeEnum } from '/Models/Generated/VotingAlternativeEnum';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import Panel from '/Components/Atoms/Panel/Panel';
import LinkButton, {
  LinkButtonTheme,
} from '/Components/Atoms/LinkButton/LinkButton';

const EPOSSakVoteringResult = ({
  // votingId,
  votingOrderNumberText,
  votingHeaderText,
  votingAlternative,
  votingDateTimeText,
  votingDetailsPageLink,
  votingRemarkText,
  votingRemarkTitle,
  votingResult,
  votingResultText,
  votingResultTitle,
  voteCountTitle,
  voteCountGraph,
  voteCountText,
  distributionTitle,
  distributionPartyTitle,
  distributionPartyTable,
  distributionPartyTableMobile,
  distributionCountyTitle,
  distributionCountyTable,
  distributionRepresentativeTitle,
  distributionRepresentativeTable,
  distributionRepresentativeTableMobile,
  noDistributionVotingInfoPageLink,
  showDetailedVotingResult,
}: VotingResultProps) => {
  const [openTable, setOpenTable] = useState('');
  // TODO: Why is row sorting not just handled within SortableTable?
  const [partyRows, setPartyRows] = useState(distributionPartyTable.rows);
  const [partyColumns, setPartyColumns] = useState(
    distributionPartyTable.columns
  );
  const [partyRowsMobile, setPartyRowsMobile] = useState(
    distributionPartyTableMobile.rows
  );
  const [partyColumnsMobile, setPartyColumnsMobile] = useState(
    distributionPartyTableMobile.columns
  );
  const [partySortColumn, setPartySortColumn] = useState(0);
  const [partySortDirection, setPartySortDirection] = useState(
    SortDirectionEnum.None
  );
  const [countyRows, setCountyRows] = useState(distributionCountyTable.rows);
  const [countyColumns, setCountyColumns] = useState(
    distributionCountyTable.columns
  );
  const [countySortColumn, setCountySortColumn] = useState(0);
  const [countySortDirection, setCountySortDirection] = useState(
    SortDirectionEnum.None
  );
  const [representativeRows, setRepresentativeRows] = useState(
    distributionRepresentativeTable.rows
  );
  const [representativeColumns, setRepresentativeColumns] = useState(
    distributionRepresentativeTable.columns
  );
  const [representativeRowsMobile, setRepresentativeRowsMobile] = useState(
    distributionRepresentativeTableMobile.rows
  );
  const [representativeColumnsMobile, setRepresentativeColumnsMobile] =
    useState(distributionRepresentativeTableMobile.columns);
  const [representativeSortColumn, setRepresentativeSortColumn] = useState(0);
  const [representativeSortDirection, setRepresentativeSortDirection] =
    useState(SortDirectionEnum.None);

  const filterButtons = [
    {
      title: distributionPartyTitle,
      tableName: 'party-vote',
    },
    {
      title: distributionCountyTitle,
      tableName: 'county-vote',
    },
    {
      title: distributionRepresentativeTitle,
      tableName: 'representatives-vote',
    },
  ];

  const getGraphHeight = (voteCount) => {
    if (!voteCount) return null;
    const votesTotal = voteCountGraph.voteYesCount + voteCountGraph.voteNoCount;
    const magicNumberToApproximateOldGraphHeights = 65;
    return (
      (voteCount / votesTotal) * magicNumberToApproximateOldGraphHeights + '%'
    );
  };

  return (
    <div
      className={cn('epos-sak-votering-result-react', {
        'epos-sak-votering-result-react__first':
          votingAlternative === VotingAlternativeEnum.First,
        'epos-sak-votering-result-react__second':
          votingAlternative === VotingAlternativeEnum.Second,
      })}
    >
      <div className="epos-sak-votering-result-react__heading-wrapper">
        <h2 className="epos-sak-votering-result-react__heading-wrapper__heading">
          <span className="epos-sak-votering-result-react__heading-wrapper__heading__number">
            {votingOrderNumberText}
          </span>
          <span>{votingHeaderText}</span>
        </h2>
        {votingDateTimeText && (
          <time className="epos-sak-votering-result-react__heading-wrapper__time">
            {votingDateTimeText}
          </time>
        )}
      </div>
      <div className="epos-sak-votering-result-react__content">
        <div className="epos-sak-votering-result-react__content__overview">
          <div
            className={cn(
              'epos-sak-votering-result-react__content__result-indicator',
              {
                'epos-sak-votering-result-react__content__result-indicator--for':
                  votingResult === VotingResultEnum.For,
                'epos-sak-votering-result-react__content__result-indicator--against':
                  votingResult === VotingResultEnum.Against,
              }
            )}
          >
            {votingResultText}
          </div>
          <div className="epos-sak-votering-result-react__content__case">
            <h3 className="epos-sak-votering-result-react__content__case__heading">
              {votingResultTitle}
            </h3>
            <div>
              <Link theme={LinkTheme.blocklink} {...votingDetailsPageLink} />
            </div>
            {votingRemarkText && votingRemarkTitle && (
              <Panel title={votingRemarkTitle} isSmallVersion>
                {votingRemarkText}
              </Panel>
            )}
          </div>
          <div className="epos-sak-votering-result-react__content__graph">
            {showDetailedVotingResult && (
              <>
                <h4 className="epos-sak-votering-result-react__content__graph__title">
                  {voteCountTitle}
                </h4>
                <div className="epos-sak-votering-result-react__content__graph__wrapper">
                  <div className="epos-sak-votering-result-react__content__graph__desktop">
                    <div
                      className="epos-sak-votering-result-react__content__graph__desktop__col for"
                      style={{
                        height: getGraphHeight(voteCountGraph.voteYesCount),
                      }}
                    >
                      <span className="epos-sak-votering-result-react__content__graph__desktop__col__text">
                        {voteCountGraph.voteYesCount}{' '}
                        {voteCountGraph.voteYesText}
                      </span>
                    </div>
                    <div
                      className="epos-sak-votering-result-react__content__graph__desktop__col against"
                      style={{
                        height: getGraphHeight(voteCountGraph.voteNoCount),
                      }}
                    >
                      <span className="epos-sak-votering-result-react__content__graph__desktop__col__text">
                        {voteCountGraph.voteNoCount} {voteCountGraph.voteNoText}
                      </span>
                    </div>
                  </div>
                  {/* New design has graph in mobile version as well as on desktop */}
                  {/* <div className="epos-sak-votering-result-react__content__graph__mobile">
                    <h4 className="epos-sak-votering-result-react__content__graph__title">
                      {voteCountTitle}
                    </h4>
                    <p>
                      <span>{voteCountGraph.voteYesCount}</span>
                      &nbsp;
                      <span>{voteCountGraph.voteYesText} </span>
                      &#47;
                      <span>{voteCountGraph.voteNoCount}</span>
                      &nbsp;
                      <span>{voteCountGraph.voteNoText} </span>
                    </p>
                  </div> */}
                </div>
              </>
            )}
            {voteCountText && (
              <>
                <h4>{voteCountTitle}</h4>
                <div className="epos-sak-votering-result-react__content__graph__vote-count">
                  <p>{voteCountText}</p>
                </div>
              </>
            )}
          </div>
          <div className="epos-sak-votering-result-react__content__distribution">
            <h4>{distributionTitle}</h4>
            {!showDetailedVotingResult && noDistributionVotingInfoPageLink && (
              <Link {...noDistributionVotingInfoPageLink} />
            )}
            {showDetailedVotingResult && (
              <div className="epos-sak-votering-result-react__content__distribution__buttons">
                {filterButtons.map((btn) =>
                  btn.title ? (
                    <React.Fragment key={btn.title}>
                      <LinkButton
                        theme={LinkButtonTheme.block}
                        onClick={() =>
                          setOpenTable((current) =>
                            current === btn.tableName ? '' : btn.tableName
                          )
                        }
                        className={cn({
                          'epos-sak-votering-result-react__content__filter-button-active':
                            openTable === btn.tableName,
                        })}
                        text={btn.title}
                      />
                    </React.Fragment>
                  ) : null
                )}
              </div>
            )}
          </div>
        </div>
        <div className="epos-sak-votering-result-react__content__result-tables">
          {openTable === 'party-vote' && (
            <>
              <div className="epos-sak-votering-result-react__content__result-tables__mobile">
                <SortableTable
                  {...distributionPartyTableMobile}
                  setColumns={setPartyColumnsMobile}
                  setRows={setPartyRowsMobile}
                  rows={partyRowsMobile}
                  columns={partyColumnsMobile}
                  setSortDirection={setPartySortDirection}
                  sortDirection={partySortDirection}
                  setSortColumn={setPartySortColumn}
                  sortColumn={partySortColumn}
                />
              </div>
              <div className="epos-sak-votering-result-react__content__result-tables__desktop">
                <SortableTable
                  {...distributionPartyTable}
                  setColumns={setPartyColumns}
                  setRows={setPartyRows}
                  rows={partyRows}
                  columns={partyColumns}
                  setSortDirection={setPartySortDirection}
                  sortDirection={partySortDirection}
                  setSortColumn={setPartySortColumn}
                  sortColumn={partySortColumn}
                />
              </div>
            </>
          )}
          {openTable === 'county-vote' && (
            <SortableTable
              {...distributionCountyTable}
              setColumns={setCountyColumns}
              setRows={setCountyRows}
              rows={countyRows}
              columns={countyColumns}
              setSortDirection={setCountySortDirection}
              sortDirection={countySortDirection}
              setSortColumn={setCountySortColumn}
              sortColumn={countySortColumn}
            />
          )}
          {openTable === 'representatives-vote' && (
            <>
              <div className="epos-sak-votering-result-react__content__result-tables__mobile epos-sak-votering-result-react__content__result-tables__mobile--has-scroll">
                {/* tabindex for horisontal scroll with keyboard */}
                <div
                  className="epos-sak-votering-result-react__content__result-tables__mobile__scroll-wrapper"
                  tabIndex={0}
                >
                  <SortableTable
                    {...distributionRepresentativeTableMobile}
                    setColumns={setRepresentativeColumnsMobile}
                    setRows={setRepresentativeRowsMobile}
                    rows={representativeRowsMobile}
                    columns={representativeColumnsMobile}
                    setSortDirection={setRepresentativeSortDirection}
                    sortDirection={representativeSortDirection}
                    setSortColumn={setRepresentativeSortColumn}
                    sortColumn={representativeSortColumn}
                  />
                </div>
              </div>
              <div className="epos-sak-votering-result-react__content__result-tables__desktop">
                <SortableTable
                  {...distributionRepresentativeTable}
                  setColumns={setRepresentativeColumns}
                  setRows={setRepresentativeRows}
                  rows={representativeRows}
                  columns={representativeColumns}
                  setSortDirection={setRepresentativeSortDirection}
                  sortDirection={representativeSortDirection}
                  setSortColumn={setRepresentativeSortColumn}
                  sortColumn={representativeSortColumn}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EPOSSakVoteringResult;
