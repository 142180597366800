import React, { FunctionComponent } from 'react';
import './SectionWrapper.scss';

interface Props {
  title: string;
  titlePropertyName: string;
  className?: string;
  children?: React.ReactNode;
}

const SectionWrapper: FunctionComponent<Props> = (props) => {
  return (
    <section
      aria-label={props.title}
      className={`section-wrapper-react ${props.className ?? ''}`}
    >
      <header>
        <h2
          className="section-wrapper-react__title"
          data-epi-edit={props.titlePropertyName}
        >
          {props.title}
        </h2>
      </header>
      <div>{props.children}</div>
    </section>
  );
};

export default SectionWrapper;
