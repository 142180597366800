import React, { useEffect, useState } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './Videoarkiv.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { VideoarkivProps } from '/Models/Generated/VideoarkivProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import SortableTable, {
  GetSortColumn,
  GetSortDirection,
} from '/Components/Organisms/SortableTable/SortableTable';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import BuildUrlWithParams from '/Shared/Code/UrlBuilder/BuildUrlWithParams';
import VideoarkivClient from './VideoarkivClient';
import { VideoarkivAsyncProps } from '/Models/Generated/VideoarkivAsyncProps';
import { GreyRowWidth } from '/Components/Layout/GreyRow/GreyRow';
import SSQQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import Pagination from '/Components/Atoms/Pagination/Pagination';
import Spinner from '/Components/Atoms/Spinner/Spinner';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import AlertWrapper from '/Components/Atoms/AlertWrapper/AlertWrapper';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const GetSelected = (items) =>
  items?.filter((i) => i.isSelected)[0]?.value ?? items[0]?.value ?? '';

const GetSelectedText = (items) =>
  items?.filter((i) => i.isSelected)[0]?.text ?? items[0]?.text ?? '';

const GetResultTitle = (asyncData: VideoarkivAsyncProps) => {
  if (asyncData.hearingsActive)
    return asyncData.tabs?.buttons[1].isSelected
      ? GetSelectedText(asyncData.committeeDropdown.items)
      : GetSelectedText(asyncData.monthDropdown.items);
  const meetingType = GetSelectedText(asyncData.meetingTypeDropdown.items);
  const session = GetSelectedText(asyncData.sesjonDropdown.items);
  const month = GetSelectedText(asyncData.monthDropdown.items);
  return `${meetingType} - ${session} - ${month}`;
};

const primaryFilterId = 'primaryfilter';
const secondaryFilterId = 'secondaryfilter';
const listId = 'list';

const Videoarkiv = ({
  mainIntro,
  mainBody,
  threeColumnContentAreaModules,
  asyncData,
  filterHeader,
  endpoint,
  pageId,
  language,
}: VideoarkivProps) => {
  const [scrollTarget, setScrollTarget] = useState('');
  const [data, setData] = useState(asyncData);
  const [page, setPage] = useState(
    asyncData.results?.pagination?.currentPage ?? 1
  );
  const [loading, setLoading] = useState(false);
  const [resultTitle, setResultTitle] = useState(GetResultTitle(asyncData));
  const [pushState, setPushState] = useState(true);
  const [pushStateOnSort, setPushStateOnSort] = useState(true);
  const [selectedTab, setSelectedTab] = useState(
    asyncData.tabs?.buttons[1]?.isSelected ? 1 : 0
  );
  const [meetingTypes, setMeetingTypes] = useState(
    asyncData.meetingTypeDropdown?.items
  );
  const [selectedMeetingType, setSelectedMeetingType] = useState(
    GetSelected(meetingTypes)
  );
  const [months, setMonths] = useState(asyncData.monthDropdown?.items);
  const [selectedMonth, setSelectedMonth] = useState(GetSelected(months));
  const [sessions, setSessions] = useState(asyncData.sesjonDropdown?.items);
  const [selectedSession, setSelectedSession] = useState(GetSelected(sessions));
  const [committees, setCommittees] = useState(
    asyncData.committeeDropdown?.items
  );
  const [selectedCommittee, setSelectedCommittee] = useState(
    GetSelected(committees)
  );
  const [rows, setRows] = useState(asyncData?.results?.table?.rows);
  const [columns, setColumns] = useState(asyncData?.results?.table?.columns);
  const [sortColumn, setSortColumn] = useState(GetSortColumn(columns));
  const [sortDirection, setSortDirection] = useState(GetSortDirection(columns));
  const [doSort, setDoSort] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const hasPagination = data.results?.pagination?.totalPages > 1;

  const UpdateUrl = () => {
    if (pushState) {
      const params = {
        pid: selectedSession,
        vt: selectedMeetingType,
        page,
        sb: GetSortColumn(columns) === 0 ? 'ActivityStart' : 'Title',
        sd:
          GetSortDirection(columns) === SortDirectionEnum.Descending
            ? 'Descending'
            : 'Ascending',
      };
      if (!data.hearingsActive || selectedTab === 0)
        params['m'] = selectedMonth;
      if (data.hearingsActive)
        params['tab'] = selectedTab === 0 ? 'Month' : 'Committee';
      if (data.hearingsActive && selectedTab === 1)
        params['coid'] = selectedCommittee;
      const newUrl = BuildUrlWithParams(
        window.location.href,
        [],
        false,
        params
      );
      window.history.pushState([], null, newUrl);
    }
    setPushState(true);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.onpopstate = () => {
      setPushState(false);
      setPushStateOnSort(false);
      const dir =
        SSQQueryStringReader.Read('sd') === 'Descending'
          ? SortDirectionEnum.Descending
          : SortDirectionEnum.Ascending;
      const col = SSQQueryStringReader.Read('sb') === 'Title' ? 1 : 0;
      setSortDirection(dir);
      setSortColumn(col);
      setDoSort(true);
      setSelectedMonth(SSQQueryStringReader.Read('m') ?? 'all');
      setSelectedTab(SSQQueryStringReader.Read('tab') === 'Committee' ? 1 : 0);
      setSelectedCommittee(SSQQueryStringReader.Read('coid') ?? 'all');
      setSelectedSession(
        SSQQueryStringReader.Read('pid') ??
          asyncData.sesjonDropdown.items[0].value
      );
      setSelectedMeetingType(
        SSQQueryStringReader.Read('vt') === 'HOE' ? 'HOE' : 'STM'
      );
      setPage(+SSQQueryStringReader.Read('page') ?? 1);
    };
  }, []);

  useEffect(() => {
    if (!loading) return;
    const m = !data.hearingsActive || selectedTab === 0 ? selectedMonth : null;
    const coid =
      data.hearingsActive && selectedTab === 1 ? selectedCommittee : null;
    const tab = !data.hearingsActive
      ? null
      : selectedTab === 0
      ? 'Month'
      : 'Committee';
    new VideoarkivClient(endpoint, pageId)
      .Get(
        selectedMeetingType,
        selectedSession,
        m,
        coid,
        tab,
        page,
        language,
        ['ActivityStart', 'Title'][sortColumn],
        sortDirection
      )
      .then((res) => {
        setData(res);
        setRows(res?.results?.table?.rows ?? []);
        setColumns(res?.results?.table?.columns ?? []);
        setResultTitle(GetResultTitle(res));
        setCommittees(res.committeeDropdown.items);
        setMeetingTypes(res.meetingTypeDropdown.items);
        setMonths(res.monthDropdown.items);
        setSessions(res.sesjonDropdown.items);
        setLoading(false);
        const cols = res?.results?.table?.columns;
        const sortChanged =
          GetSortColumn(cols) !== sortColumn ||
          GetSortDirection(cols) !== sortDirection;
        if (sortChanged) {
          setSortColumn(GetSortColumn(cols));
          setSortDirection(GetSortDirection(cols));
        } else UpdateUrl();
        if (scrollTarget) scrollToTarget(scrollTarget);
      });
  }, [loading]);

  // --- load data and scroll to various targets ---
  const setLoadAndScrollTarget = (target) => {
    if (!initialized) return;
    setScrollTarget(target);
    setLoading(true);
  };

  useEffect(
    () => setLoadAndScrollTarget(primaryFilterId),
    [selectedMeetingType, selectedSession]
  );

  useEffect(
    () =>
      setLoadAndScrollTarget(
        selectedMeetingType === 'STM' ? primaryFilterId : secondaryFilterId
      ),
    [selectedMonth]
  );

  useEffect(
    () => setLoadAndScrollTarget(secondaryFilterId),
    [selectedCommittee, selectedTab]
  );

  // when sorting columns, reload data if more than one page
  useEffect(() => {
    if (initialized && hasPagination) {
      if (page !== 1) setPage(1); // will trigger data load
      else setLoadAndScrollTarget(primaryFilterId); // as in old solution
    }
  }, [sortColumn, sortDirection]);

  useEffect(() => setLoadAndScrollTarget(listId), [page]);
  // ----------------------------------------------

  useEffect(() => {
    if (!initialized) return;
    if (pushStateOnSort) UpdateUrl();
  }, [sortColumn, sortDirection]);

  // if filter changes, reset pagination
  useEffect(() => {
    if (!initialized) return;
    setPage(1);
  }, [selectedMeetingType, selectedSession, selectedMonth, selectedCommittee]);

  useEffect(() => setInitialized(true), []);

  return (
    <PageWrapper>
      <div className="videoarkiv-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        </Row>
        {HasContent(threeColumnContentAreaModules) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaModules} />
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.indent}>
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
        <Row>
          <ExpandableFilterWrapper
            scrollId={primaryFilterId}
            heading={filterHeader}
            initiallyExpanded={false}
          >
            <div className="videoarkiv-react__filters">
              {meetingTypes && (
                <DropDownFilterWrapper
                  halfwidth={true}
                  label={data.meetingTypeDropdown.labelText}
                  options={data.meetingTypeDropdown.items}
                  selectedValue={selectedMeetingType}
                  setSelected={setSelectedMeetingType}
                  disabled={loading}
                  id="videoarkiv-meetingtype"
                  name="videoarkiv-meetingtype"
                />
              )}
              <div className="videoarkiv-react__filter-row">
                {sessions && (
                  <DropDownFilterWrapper
                    label={data.sesjonDropdown.labelText}
                    options={sessions}
                    selectedValue={selectedSession}
                    setSelected={setSelectedSession}
                    disabled={loading}
                    id="videoarkiv-session"
                    name="videoarkiv-session"
                  />
                )}
                {months && !data?.hearingsActive && (
                  <DropDownFilterWrapper
                    label={data.monthDropdown?.labelText ?? ''}
                    options={months}
                    selectedValue={selectedMonth}
                    setSelected={setSelectedMonth}
                    disabled={loading}
                    id="videoarkiv-month"
                    name="videoarkiv-month"
                  />
                )}
              </div>
              {!!data.tabs?.buttons?.length && (
                <div>
                  <hr />
                  <a id={secondaryFilterId}></a>
                  <TabButtonStrip>
                    {data.tabs.buttons.map((button, idx) => (
                      <TabButton
                        key={idx}
                        {...button}
                        selected={idx === selectedTab}
                        onClick={() => {
                          setSelectedMonth('all');
                          setSelectedCommittee('all');
                          setSelectedTab(idx);
                        }}
                        disabled={loading}
                      />
                    ))}
                  </TabButtonStrip>
                </div>
              )}
              {months && data?.hearingsActive && selectedTab === 0 && (
                <DropDownFilterWrapper
                  halfwidth={true}
                  label={data.monthDropdown?.labelText ?? ''}
                  options={months}
                  selectedValue={selectedMonth}
                  setSelected={setSelectedMonth}
                  disabled={loading}
                  id="videoarkiv-month"
                  name="videoarkiv-month"
                />
              )}
              {committees && data?.hearingsActive && selectedTab === 1 && (
                <DropDownFilterWrapper
                  halfwidth={true}
                  label={data.committeeDropdown?.labelText ?? ''}
                  options={committees}
                  selectedValue={selectedCommittee}
                  setSelected={setSelectedCommittee}
                  disabled={loading}
                  id="videoarkiv-committee"
                  name="videoarkiv-committee"
                />
              )}
            </div>
          </ExpandableFilterWrapper>
        </Row>
        {loading && (
          <Row horizontalPadding={HorizontalPadding.normal}>
            <Spinner />
          </Row>
        )}
        {!loading && !!data.results && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <Row
              verticalPaddingTop={VerticalPadding.large}
              verticalPaddingBottom={
                data?.results?.table?.rows?.length
                  ? VerticalPadding.medium
                  : VerticalPadding.none
              }
            >
              <a id={listId}></a>
              <GreyBar
                text={resultTitle}
                width={GreyRowWidth.indentedWide}
                fullWidthText={true}
              />
              {!data?.results?.table?.rows?.length &&
                !data?.results?.errorHtml &&
                !!data?.results?.table?.emptyDataSetText && (
                  <div className="videoarkiv-react__no-data">
                    {data.results.table.emptyDataSetText}
                  </div>
                )}
              {!!data?.results?.errorHtml && (
                <div className="videoarkiv-react__no-data">
                  <AlertWrapper>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.results.errorHtml,
                        }}
                      ></div>
                    }
                  </AlertWrapper>
                </div>
              )}
            </Row>
            <SortableTable
              {...{
                columns,
                rows,
                setColumns,
                setRows,
                doSort,
                setDoSort,
                sortColumn,
                setSortColumn: (val) => {
                  setPushStateOnSort(true);
                  setSortColumn(val);
                },
                sortDirection,
                setSortDirection: (val) => {
                  setPushStateOnSort(true);
                  setSortDirection(val);
                },
              }}
            />
          </Row>
        )}
        {!loading &&
          !!data.results?.pagination &&
          data.results.pagination.totalPages > 1 && (
            <Row horizontalPadding={HorizontalPadding.indent}>
              <Pagination
                {...data.results.pagination}
                currentPage={page}
                onClickFunction={setPage}
              />
            </Row>
          )}
      </div>
    </PageWrapper>
  );
};

export default Videoarkiv;
