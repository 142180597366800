import { useEffect, useState } from 'react';

const useUrlParam = (paramName, initialValue) => {
  const [value, setValue] = useState(() => {
    if (typeof window === 'undefined') return null;
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamValue = urlParams.get(paramName);
    return urlParamValue !== null ? urlParamValue : initialValue;
  });

  useEffect(() => {
    const handlePopstate = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlParamValue = urlParams.get(paramName);
      setValue(urlParamValue !== null ? urlParamValue : initialValue);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (value !== null && value !== '') {
      url.searchParams.set(paramName, value);
    } else {
      url.searchParams.delete(paramName);
    }

    window.history.replaceState(null, '', url.toString());
  }, []); // [paramName, value]

  const setUrlParam = (newValue) => {
    setValue(newValue);

    const url = new URL(window.location.href);

    if (newValue !== null && newValue !== '') {
      url.searchParams.set(paramName, newValue);
    } else {
      url.searchParams.delete(paramName);
    }

    window.history.pushState(null, '', url.toString());
  };

  return [value, setUrlParam];
};

export default useUrlParam;
