import React from 'react';
import './DictionaryResults.scss';
import { DictionaryResultsProps } from '/Models/Generated/DictionaryResultsProps';

const DictionaryResults = ({
  letter,
  words,
  noHitsText,
}: DictionaryResultsProps) => {
  return (
    <div className="dictionary-results-react">
      <h2 className="dictionary-results-react__letter">{letter}</h2>
      {!words?.length ? (
        <div>{noHitsText}</div>
      ) : (
        <ul className="dictionary-results-react__result-list">
          {words.map((el, index) => (
            <li
              className="dictionary-results-react__result-list__list-item"
              key={index}
            >
              <h3 className="dictionary-results-react__result-list__title">
                {el.word}
              </h3>
              <p className="dictionary-results-react__result-list__description">
                {el.description}
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DictionaryResults;
