import React, { useEffect, useRef, useState } from 'react';
import { JwPlayerProps } from '/Models/Generated/JwPlayerProps';
import { LinkProps } from '/Models/Generated/LinkProps';

interface Props extends JwPlayerProps {
  setPosition: (val: string) => void;
  setPositionSec: (val: string) => void;
  setDownloadDescription: (val: string) => void;
  setDownloadLink: (val: LinkProps) => void;
  setShareUrl: (val: string) => void;
}

const FocusItem = ({
  downloadDescription,
  downloadLink,
  fileName,
  hiddenBack10Sec,
  hiddenBack5Min,
  hiddenForward10Sec,
  hiddenForward5Min,
  hiddenPosition,
  hiddenSetPosition,
  hiddenStartLabel,
  hiddenStopLabel,
  hiddenVolumeDown,
  hiddenVolumeUp,
  positionLabel,
  secondsLabel,
  shareUrl,
  showSecLabel,
  startPosition,
  setPosition,
  setPositionSec,
  setShareUrl,
  setDownloadDescription,
  setDownloadLink,
}: Props) => {
  const [jwFunctions, setJwFunctions] = useState(null);
  const hiddenTextInput = useRef(null);
  const positionRef = useRef(-1);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window['jwplayer'] === 'undefined'
    ) {
      return;
    }

    const jwplayer = window['jwplayer'];
    jwplayer('divJwPlayer').setup({
      file: fileName,
      width: '100%',
      aspectratio: '16:9',
      //cast: {},
      ga: {},
    });

    jwplayer().on('playlistitem', function (item) {
      if (!item || item.index === undefined) {
        return;
      }

      const playListItem = jwplayer().getPlaylistItem(item.index);
      if (!playListItem || !playListItem.file) {
        return;
      }
    });

    jwplayer().setVolume(70);

    const toHHMMSS = function (numberStr: string) {
      const sec_num = parseInt(numberStr, 10); // don't forget the second parm
      let hours = Math.floor(sec_num / 3600) + '';
      let minutes = Math.floor((sec_num - parseInt(hours) * 3600) / 60) + '';
      let seconds =
        sec_num - parseInt(hours) * 3600 - parseInt(minutes) * 60 + '';

      if (parseInt(hours) < 10) {
        hours = '0' + hours;
      }
      if (parseInt(minutes) < 10) {
        minutes = '0' + minutes;
      }
      if (parseInt(seconds) < 10) {
        seconds = '0' + seconds;
      }
      const time = hours + ':' + minutes + ':' + seconds;
      return time;
    };

    const setNewPositionValues = (newPositionInSeconds: string) => {
      setPosition(toHHMMSS(newPositionInSeconds));
      setPositionSec(newPositionInSeconds);
      positionRef.current = parseInt(newPositionInSeconds);
      document.getElementById('lblposition').innerHTML =
        positionLabel + ' (' + toHHMMSS(newPositionInSeconds) + ') ';
    };

    const updateTime = () => {
      const newPositionInSeconds = jwplayer().getPosition().toFixed();
      // update only at whole second
      if (parseInt(newPositionInSeconds) !== positionRef.current) {
        setNewPositionValues(newPositionInSeconds);
      }
    };

    jwplayer().on('time', updateTime);

    jwplayer().on('ready', function () {
      jwplayer().seek(startPosition);
      jwplayer().play();
      const newPositionInSeconds = startPosition ? startPosition : 0;
      setNewPositionValues(newPositionInSeconds + '');
      setShareUrl(shareUrl);
      setDownloadLink(downloadLink);
      setDownloadDescription(downloadDescription);
    });

    function changePosition(deltaPositionInSeconds) {
      let newPositionInSeconds =
        jwplayer().getPosition() + deltaPositionInSeconds;
      if (newPositionInSeconds <= 0) {
        newPositionInSeconds = 1;
      }
      jwplayer().seek(newPositionInSeconds);
    }

    function setPositionInPlayer() {
      let desiredPositionTimeText = hiddenTextInput.current.value;
      try {
        // trying to be nice, accepts '.' separator even if javascript Date does not
        while (desiredPositionTimeText.indexOf('.') >= 0) {
          desiredPositionTimeText = desiredPositionTimeText.replace('.', ':');
        }
        // must add trailing separator if none
        if (desiredPositionTimeText.indexOf(':') < 0) {
          desiredPositionTimeText = desiredPositionTimeText + ':';
        }
        const desiredPositionTime = new Date(
          new Date().toDateString() + ' ' + desiredPositionTimeText
        );
        let desiredPositionInSeconds =
          desiredPositionTime.getHours() * 60 * 60 +
          desiredPositionTime.getMinutes() * 60 +
          desiredPositionTime.getSeconds();
        if (desiredPositionInSeconds <= 0) {
          desiredPositionInSeconds = 1;
        }
        jwplayer().seek(desiredPositionInSeconds);
      } catch (e) {
        // ignore it
      }
    }

    function changeVolume(deltaVolumePercentage) {
      let newVolumePercentage = jwplayer().getVolume() + deltaVolumePercentage;
      if (newVolumePercentage >= 100) {
        newVolumePercentage = 100;
      } else if (newVolumePercentage <= 0) {
        newVolumePercentage = 0;
      }
      jwplayer().setVolume(newVolumePercentage);
    }

    function play() {
      jwplayer().play();
    }

    function pause() {
      jwplayer().pause();
    }

    function showSec() {
      alert(showSecLabel + jwplayer().seek().getPosition() + secondsLabel);
    }

    setJwFunctions({
      play,
      pause,
      showSec,
      changeVolume,
      changePosition,
      setPositionInPlayer,
    });
  }, []);

  return (
    <div className="jwplayer-react">
      <div id="divJwPlayer"></div>
      <div className="accessibility-hidden">
        <label id="lblposition" htmlFor="txtposition">
          {hiddenPosition}
        </label>
        <input
          type="text"
          id="txtposition"
          onKeyDown={(event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
              jwFunctions?.setPositionInPlayer();
              return false;
            }
          }}
          ref={(input) => (hiddenTextInput.current = input)}
        />
        <input
          type="button"
          id="btnsetposition"
          onClick={() => jwFunctions?.setPositionInPlayer()}
          value={hiddenSetPosition}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.play()}
          value={hiddenStartLabel}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.pause()}
          value={hiddenStopLabel}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changePosition(10)}
          value={hiddenForward10Sec}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changePosition(300)}
          value={hiddenForward5Min}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changePosition(-10)}
          value={hiddenBack10Sec}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changePosition(-300)}
          value={hiddenBack5Min}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changeVolume(10)}
          value={hiddenVolumeUp}
        />
        <input
          type="button"
          onClick={() => jwFunctions?.changeVolume(-10)}
          value={hiddenVolumeDown}
        />
      </div>
    </div>
  );
};

export default FocusItem;
