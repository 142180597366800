import React, { useState } from 'react';
import './KortvedtakListShowMore.scss';

interface Props {
  beforeCutoff: string;
  afterCutoff?: string;
  showMoreText: string;
  showLessText: string;
}

const KortvedtakListShowMore = ({
  afterCutoff,
  beforeCutoff,
  showLessText,
  showMoreText,
}: Props) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div className="kortvedtak-list-show-more-react">
      <span className="kortvedtak-list-show-more-react__text">
        {beforeCutoff}
      </span>
      {afterCutoff && (
        <>
          {showMore && (
            <span className="kortvedtak-list-show-more-react__text">
              {afterCutoff}
            </span>
          )}
          {!showMore && (
            <span className="kortvedtak-list-show-more-react__text">...</span>
          )}
          &nbsp;&nbsp;
          <button onClick={() => setShowMore(!showMore)}>
            {showMore ? showLessText : showMoreText}
          </button>
        </>
      )}
    </div>
  );
};

export default KortvedtakListShowMore;
