import React from 'react';

import './AlertMessage.scss';

interface Props {
  children?: React.ReactNode;
}

const AlertMessage = ({ children }: Props) => {
  return <div className="alert-message-react">{children}</div>;
};
export default AlertMessage;
