import React, { FunctionComponent } from 'react';
import './Column.scss';
import cn from 'classnames';

export enum Width {
  oneHalf,
  oneThird,
  twoThirds,
  full,
}

interface Props {
  children?: React.ReactNode;
  customTag?: string;
  desktop?: Width;
  // tablet?: Width;
  // mobile?: Width;
}

const Column: FunctionComponent<Props> = (props) => {
  const Tag = props.customTag ?? 'div';
  return (
    <Tag
      className={cn(
        'column-react',
        {
          'column-react__desktop__full':
            props.desktop === null || props.desktop === Width.full,
        },
        {
          'column-react__desktop__one-third': props.desktop === Width.oneThird,
        },
        {
          'column-react__desktop__one-half': props.desktop === Width.oneHalf,
        },
        {
          'column-react__desktop__two-thirds':
            props.desktop === Width.twoThirds,
        }
      )}
    >
      {props.children}
    </Tag>
  );
};

export default Column;
