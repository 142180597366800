import React, { useEffect, useState } from 'react';
import './TopMenuSearchSectionContent.scss';
import cn from 'classnames';
import { TopMenuSearchProps } from '/Models/Generated/TopMenuSearchProps';
import TopMenuSectionContentCloseButton from './TopMenuSectionContentCloseButton';
import SearchForm from '/Components/Molecules/SearchForm/SearchForm';
import SearchClient from '/Shared/Code/Search/SearchClient';

interface TopMenuSearchPropsExtended extends TopMenuSearchProps {
  toggleSearch: () => void;
  containerId: string;
  isOpen: boolean;
}

const TopMenuSearchSectionContent = (props: TopMenuSearchPropsExtended) => {
  const [div, setDiv] = useState(null as HTMLDivElement);
  const [query, setQuery] = useState('');
  const [triggerSearch, setTriggerSearch] = useState(false);
  const searchClient = new SearchClient(props.searchClient);

  useEffect(() => {
    if (triggerSearch) {
      searchClient.NavigateSearch(query);
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  if (props)
    return (
      <div
        id={props.containerId}
        className={cn('top-menu-search-section-content', {
          'is-open': props.isOpen,
        })}
        style={{ height: props.isOpen ? div.clientHeight : 0 }}
      >
        <div
          className="top-menu-search-section-content-container"
          ref={(divElement) => {
            setDiv(divElement);
          }}
        >
          <div className="input-container-react">
            <SearchForm
              {...props}
              client={searchClient}
              loading={false}
              placeholder={props.placeholder}
              doSearch={() => setTriggerSearch(true)}
              query={query}
              setQuery={setQuery}
              setFocus={props.isOpen}
            />
          </div>
          <TopMenuSectionContentCloseButton
            closeSection={props.toggleSearch}
            closeLabel={props.closeLabel}
          />
        </div>
      </div>
    );
  else return <></>;
};

export default TopMenuSearchSectionContent;
