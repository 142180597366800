import React from 'react';
import cn from 'classnames';
import './ResultPost.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultChilds from './Shared/ResultChilds';
import ResultHeader from './Shared/ResultHeader';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultPost = ({
  url,
  title,
  summary,
  childs,
  highlighting,
  relevance,
  isChild,
  specifics,
}: Props) => {
  return (
    <article
      className={cn('search-result-post-article-react', {
        'is-child-react': isChild,
      })}
    >
      <ResultHeader {...{ url, title, relevance, specifics }} />
      {!isChild && <ResultSummary summary={summary} />}
      <ResultHighlighting highlighting={highlighting} />
      <ResultChilds childs={childs} showChilds={true} />
    </article>
  );
};

export default ResultPost;
