import React from 'react';
import { PresidencyMemberProps } from '/Models/Generated/PresidencyMemberProps';
import './PresidencyBlockType.scss';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import { PresidencyBlockTypeProps } from '/Models/Generated/PresidencyBlockTypeProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import CircleImage, {
  CircleImageSizes,
} from '/Components/Atoms/CircleImage/CircleImage';
import SectionWrapper from '/Components/Layout/SectionWrapper/SectionWrapper';

const PresidencyBlockType = ({
  isInRichTextArea,
  title,
  titlePropertyName,
  mainIntro,
  mainIntroPropertyName,
  linkToPresidencyPage,
  presidencyMembers,
}: PresidencyBlockTypeProps) => {
  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <SectionWrapper
        title={title}
        titlePropertyName={titlePropertyName}
        className="presidency-block-type-react"
      >
        <p
          className="presidency-block-type-react__intro-text"
          data-epi-edit={mainIntroPropertyName}
        >
          {mainIntro}
        </p>
        <div className="presidency-block-type-react__members-container">
          {presidencyMembers &&
            presidencyMembers.map((val: PresidencyMemberProps, idx) => (
              <article
                key={idx}
                className="presidency-block-type-react__members-container__item"
              >
                <CircleImage
                  src={val.largeImageUrl}
                  alt={''}
                  size={CircleImageSizes.medium}
                />
                <a href={val.eposRepresentativePageLink.href}>
                  {val.eposRepresentativePageLink.text}
                </a>
                <div>({val.partyId})</div>
                <div>{val.membershipRole}</div>
                <hr />
              </article>
            ))}
        </div>
        {linkToPresidencyPage && (
          <div className="align-right">
            <Link
              href={linkToPresidencyPage.href}
              text={linkToPresidencyPage.text}
              theme={LinkTheme.blocklink}
            />
          </div>
        )}
      </SectionWrapper>
    </BlockWrapper>
  );
};

export default PresidencyBlockType;
