import React from 'react';
import './EuEosContactText.scss';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { EuEosContactTextProps } from '/Models/Generated/EuEosContactTextProps';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';

const EuEosContactText = ({
  header,
  body,
  withGreyBackground,
}: EuEosContactTextProps) =>
  withGreyBackground ? (
    <ColumnContentWrapper
      title={header}
      titlePropertyName={null}
      customTag="aside"
    >
      <ContentArea {...body} theme={RichTextTheme.ContactInfo} />
    </ColumnContentWrapper>
  ) : (
    <div className="rich-text-contact-react">
      {header && <h2>{header}</h2>}
      <ContentArea {...body} theme={RichTextTheme.ContactInfo} />
    </div>
  );

export default EuEosContactText;
