import React from 'react';
import './PersonCard.scss';
import CardHeader from '../CardSubComponents/CardHeader/CardHeader';
import CardImage from '../CardSubComponents/CardImage/CardImage';
import { GlobalSearchHitProps } from '/Models/Generated/GlobalSearchHitProps';
import CardMetaLinksList from '../CardSubComponents/CardMetaLinksList/CardMetaLinksList';

interface PersonCardProps extends GlobalSearchHitProps {
  expandRelatedLinksButtonText: string;
  collapseRelatedLinksButtonText: string;
  showHitScore: boolean;
}

const PersonCard = ({
  cardHeaderProps,
  cardMetaLinks,
  cardRelatedLinks,
  cardImageProps,
  hitScore,
  showHitScore,
  expandRelatedLinksButtonText,
  collapseRelatedLinksButtonText,
}: PersonCardProps) => {
  return (
    <div
      className="person-card-react"
      data-card-type={cardHeaderProps.hitTypeLabel}
    >
      {showHitScore && hitScore && (
        <b className="person-card-react__hitScore">Søkescore: {hitScore}</b>
      )}
      {cardImageProps && (
        <a
          className="person-card-react__image"
          href={cardHeaderProps.title.href}
          aria-label={cardHeaderProps.title.text}
          tabIndex={-1}
        >
          <CardImage {...cardImageProps} />
        </a>
      )}
      {cardHeaderProps && (
        <div className="person-card-react__header">
          <CardHeader {...cardHeaderProps} />
        </div>
      )}
      {cardMetaLinks && (
        <div className="person-card-react__meta-info">
          <CardMetaLinksList
            cardMetaLinks={cardMetaLinks}
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        </div>
      )}
      {cardRelatedLinks && (
        <div className="person-card-react__links">
          <CardMetaLinksList
            cardMetaLinks={cardRelatedLinks}
            largerGapOnLinks
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        </div>
      )}
    </div>
  );
};

export default PersonCard;
