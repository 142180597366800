import React, { useEffect, useRef, useState } from 'react';
import './FilterExpander.scss';
import cn from 'classnames';
import { randomId } from '/Shared/Code/Helpers/RandomId';
import { focusInCurrentTarget } from '/Shared/Code/Helpers/FocusInCurrentTarget';

interface FilterExpanderProps {
  children: React.ReactNode;
  label?: string;
  onTabSelect?: (...args: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface TabProps {
  children?: React.ReactNode;
  label: string;
}

const Tab = ({ label, children }: TabProps) => (
  <>
    {label}
    {children}
  </>
);

const FilterExpander = ({
  children,
  label,
  onTabSelect,
}: FilterExpanderProps) => {
  const filterRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [contentContainerId, setContentContainerId] = useState('');

  const tabs = React.Children.toArray(children).filter((el) => el.type === Tab);
  const tabContent = tabs?.length
    ? tabs[activeTabIndex]?.props?.children
    : null;

  useEffect(() => {
    setContentContainerId(randomId());
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const handler = (e) => {
        const container = filterRef.current?.parentElement;
        if (
          !focusInCurrentTarget({
            relatedTarget: e.target,
            currentTarget: container,
          })
        )
          setIsOpen(false);
      };
      document.addEventListener('click', handler);
      return () => document.removeEventListener('click', handler);
    }
  }, []);

  return (
    <div className="filter-expander-react" ref={filterRef}>
      <button
        className={cn('filter-expander-react__button', {
          'filter-expander-react__button--is-open': isOpen,
        })}
        aria-controls={contentContainerId}
        aria-expanded={isOpen}
        onClick={() => setIsOpen((current) => !current)}
      >
        <span
          className={cn('filter-expander-react__heading', {
            'filter-expander-react__heading--is-open': isOpen,
          })}
        >
          {label}
        </span>
      </button>

      {isOpen && (
        <div
          id={contentContainerId}
          className="filter-expander-react__container"
        >
          {!!tabs?.length && (
            <div className="filter-expander-react__container__tabs">
              {tabs.map((tab, i) => (
                <React.Fragment key={i}>
                  <button
                    className={cn('filter-expander-react__tab', {
                      'filter-expander-react__tab--active':
                        activeTabIndex === i,
                    })}
                    onClick={(e) => {
                      setActiveTabIndex(i);
                      if (typeof onTabSelect === 'function')
                        onTabSelect({
                          event: e,
                          label: tab.props.label,
                          index: i,
                        });
                    }}
                  >
                    {tab.props.label}
                  </button>
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="filter-expander-react__container__content">
            {tabs?.length ? tabContent : <>{children}</>}
          </div>
        </div>
      )}
    </div>
  );
};

FilterExpander.Tab = Tab;

export default FilterExpander;
