export default {
  // General configs
  configs: {
    isDev: false,
    isLocal: false,
  },
  // General statuses
  status: {
    before: 0,
    active: 1,
    paused: 2,
    finished: 3,
    canceled: 4,
    notDetermined: -1,
  },
  //  MeetingActivityStatusEnum.cs
  meetingStatus: {
    active: 'AKTIV',
    notStarted: 'IKKE_STARTET',
    pause: 'PAUSE',
    voting: 'VOTERING',
  },
  //  MediaStreamingParameters.cs
  //  EnumId: DropDownEnums.LiveTvChannels
  meetingRoom: {
    s: {
      nameId: 'Stortingssalen',
      numberId: 1,
      enumId: 1,
    },
    h1: {
      nameId: 'Hoeringssal1',
      numberId: 2,
      enumId: 2,
    },
    h2: {
      nameId: 'Hoeringssal2',
      numberId: 4,
      enumId: 3,
    },
    n202: {
      nameId: 'N202',
      numberId: 6,
      enumId: 4,
    },
  },
  hearingStatus: {
    active: 'Active',
    notStarted: 'NotStarted',
    finished: 'Finished',
    canceled: 'Canceled',
    postponed: 'Postponed',
  },
};
