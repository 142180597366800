import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { InfoListProps } from '/Models/Generated/InfoListProps';

export default class EuEosInfoListClient {
  constructor(private endpoint: string) {}

  public Get(
    /** The page id of the Episerver EUEOSinformasjonListeside */
    pageId: number,
    /** The page size of the result. This counts the InfoProps elements inside the InfoListProps. */
    pageSize: number,
    /** The page of the result. The result skips page*pageSize and takes pageSize. */
    page: number,
    /** A year, e.g. 2021. 0 means no filtering. */
    year: number,
    /** A month. 1 is January, 2 is Febuary and so on. 0 means no filtering. */
    month: number,
    /** An Episerver category number. 0 means no filtering. */
    category: number
  ): Promise<InfoListProps[]> {
    if (typeof window === 'undefined') return Promise.resolve([]);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(pageSize, page, year, month, category).then(
        (res) => UseProdUrls(res)
      );
    } else {
      return fetch(
        this.endpoint +
          `pageId=${pageId}&pageSize=${pageSize}&page=${page}&year=${year}&month=${month}&category=${category}`,
        { headers: { Accept: 'application/json' } }
      ).then((res) => res.json());
    }
  }

  BuildFilterUrl = (
    year: number,
    month: number,
    category: number,
    showExpandButton: boolean
  ): string => {
    const params = [
      year ? `y=${year}` : null,
      month ? `m=${month}` : null,
      category ? `dtid=${category}` : null,
      showExpandButton ? null : 'exp=false',
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private getMockData(
    pageSize: number,
    page: number,
    year: number,
    month: number,
    category: number
  ): Promise<InfoListProps[]> {
    return fetch('/MockupAssets/MockData/EuEosInfoList.json')
      .then((res) => res.json())
      .then((result: InfoListProps[]) => {
        let simpleList = result.map((ilp) => ilp.infoProps).flat();
        if (year)
          simpleList = simpleList.filter((ip) => ip.year === year.valueOf());
        if (month)
          simpleList = simpleList.filter((ip) => ip.month === month.valueOf());
        if (category)
          simpleList = simpleList.filter(
            (ip) =>
              ip.categories && ip.categories.indexOf(category.valueOf()) >= 0
          );
        if (page > 0) simpleList.splice(0, page * pageSize);
        if (simpleList.length > pageSize)
          simpleList.splice(pageSize, simpleList.length - pageSize.valueOf());
        result.forEach((ilp) => {
          ilp.infoProps = ilp.infoProps.filter(
            (ip) => simpleList.indexOf(ip) >= 0
          );
        });
        return result.filter((ilp) => ilp.infoProps.length > 0);
      });
  }
}
