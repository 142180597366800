import React from 'react';
import './FocusItem3.scss';
import { EposSakslisteResultItemProps } from '/Models/Generated/EposSakslisteResultItemProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';

const FocusItem3 = ({
  title,
  subTitle,
  subscribeToNotificationsForIssueLink,
  abstract,
  departmentLabel,
  departmentText,
  spokespersonLabel,
  firstSpecialSpokespersonText,
  secondSpecialSpokespersonText,
  thirdSpecialSpokespersonLink,
  regularSpokespersonText,
  listDateWithText,
  proceedingType,
  currentWorkflowStepName,
}: EposSakslisteResultItemProps) => {
  return (
    <article className="focus-item-3-react">
      <h3 className="focus-item-3-react__heading">
        <a href={title.href} className="focus-item-3-react__heading__link">
          {title.text}
        </a>
      </h3>
      {subTitle && <h4 className="focus-item-3-react__subtitle">{subTitle}</h4>}
      {subscribeToNotificationsForIssueLink && (
        <div className="focus-item-3-react__notification-link">
          <Link
            {...subscribeToNotificationsForIssueLink}
            theme={LinkTheme.standard}
          />
        </div>
      )}
      {<p>{abstract}</p>}
      {departmentText && (
        <p>
          {departmentLabel} {departmentText}
        </p>
      )}
      {firstSpecialSpokespersonText && (
        <p>
          {spokespersonLabel} {firstSpecialSpokespersonText}
        </p>
      )}
      {secondSpecialSpokespersonText && (
        <p>
          {spokespersonLabel} {secondSpecialSpokespersonText}
        </p>
      )}
      {thirdSpecialSpokespersonLink && (
        <Link {...thirdSpecialSpokespersonLink} theme={LinkTheme.serif} />
      )}
      {regularSpokespersonText && (
        <p>
          {spokespersonLabel} {regularSpokespersonText}
        </p>
      )}
      {listDateWithText && (
        <p dangerouslySetInnerHTML={{ __html: listDateWithText }} />
      )}
      {proceedingType && (
        <div className="focus-item-3-react__proceeding-type">
          <span> {proceedingType}</span>
          {currentWorkflowStepName && (
            <>
              <span> | </span>
              <span>{currentWorkflowStepName}</span>
            </>
          )}
        </div>
      )}
    </article>
  );
};

export default FocusItem3;
