import { SkjemaAkkrediteringApplicationAdminTableProps } from '/Models/Generated/SkjemaAkkrediteringApplicationAdminTableProps';
import React, { useEffect, useState } from 'react';
import './SkjemaAkkrediteringApplicationAdminTable.scss';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import Button, { ButtonTheme } from '/Components/Atoms/Button/Button';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';

const SkjemaAkkrediteringApplicationAdminTable = (
  adminTable: SkjemaAkkrediteringApplicationAdminTableProps
) => {
  const [rows, setRows] = useState(adminTable?.sortableTable?.rows);
  const [columns, setColumns] = useState(adminTable?.sortableTable?.columns);
  const [doSort, setDoSort] = useState(false);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.None);

  useEffect(() => {
    setRows(adminTable?.sortableTable?.rows);
    setColumns(adminTable?.sortableTable?.columns);
    setDoSort(true);
  }, [adminTable]);

  if (!adminTable?.sortableTable) return <></>;
  return (
    <div className="skjema-akkreditering-application-admin-table-react">
      <h2>{adminTable.heading}</h2>
      {adminTable.sortableTable && (
        <SortableTable
          {...{
            rows,
            setRows,
            columns,
            setColumns,
            doSort,
            setDoSort,
            sortColumn,
            setSortColumn,
            sortDirection,
            setSortDirection,
          }}
          visuallyHiddenCaption=""
          emptyDataSetText=""
        />
      )}

      {adminTable.exportTableToExcel && (
        <form method="POST" action={adminTable.exportTableToExcel.endpointUrl}>
          <Button
            text={adminTable.exportTableToExcel.buttonText}
            theme={ButtonTheme.Grey}
            onClick={() => {}} //eslint-disable-line @typescript-eslint/no-empty-function
          />
        </form>
      )}
    </div>
  );
};

export default SkjemaAkkrediteringApplicationAdminTable;
