import React, { useEffect, useRef } from 'react';
import './ProceedingVotingDetailsPageType.scss';
import cn from 'classnames';
import { ProceedingVotingDetailsPageTypeProps } from '/Models/Generated/ProceedingVotingDetailsPageTypeProps';
import Link from '/Components/Atoms/Link/Link';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import {
  fixBigdocTableScrollers,
  fixLargeTables,
} from '/Components/Organisms/RichText/RichTextHelpers';
import { RichTextTableExpander } from '/Components/Organisms/RichText/RichText';

const PartListItem = (listItem) => {
  const parentElementRef = useRef(null);
  const tableExpander = RichTextTableExpander.BigDocument;

  // partial code copied from RichText.tsx
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        fixBigdocTableScrollers(parentElementRef);
      };
      window.addEventListener('resize', handleResize);
      fixLargeTables(parentElementRef, tableExpander);
      fixBigdocTableScrollers(parentElementRef);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <li>
      <h3 id={listItem.id}>{listItem.header}</h3>
      {/* The page-publication and bigdoc-content classes come from the old frontend */}
      <div className="page-publication">
        <div
          ref={parentElementRef}
          dangerouslySetInnerHTML={{ __html: listItem.text }}
          className="proceeding-voting-details-page-type-react__list-item bigdoc-content "
        />
      </div>
    </li>
  );
};

const Part = (header, headerPropertyName, list) =>
  !!list?.length ? (
    <>
      {(header || headerPropertyName) && (
        <h2
          className={cn(
            'proceeding-voting-details-page-type-react__list-header',
            {
              'epi-editContainer': headerPropertyName,
            }
          )}
          data-epi-property-name={headerPropertyName}
        >
          {header}
        </h2>
      )}
      {list && (
        <ul className="proceeding-voting-details-page-type-react__list">
          {list.map((val, idx) => (
            <React.Fragment key={idx}>
              <PartListItem {...val} />
            </React.Fragment>
          ))}
        </ul>
      )}
    </>
  ) : (
    <></>
  );

const ProceedingVotingDetailsPageType = ({
  parentLink,
  pageTitle,
  pageTitlePropertyName,
  proposalLinks,
  recommendationHeader,
  recommendationHeaderPropertyName,
  recommendationList,
  minorityListHeader,
  minorityListHeaderPropertyName,
  minorityList,
  looseListHeader,
  looseListHeaderPropertyName,
  looseList,
}: ProceedingVotingDetailsPageTypeProps) => (
  <PageWrapper>
    <Row horizontalPadding={HorizontalPadding.normal}>
      <div className="proceeding-voting-details-page-type-react">
        <Link {...parentLink} />
        {(pageTitle || pageTitlePropertyName) && (
          <h1
            className={cn({
              'epi-editContainer': pageTitlePropertyName,
            })}
            data-epi-property-name={pageTitlePropertyName}
          >
            {pageTitle}
          </h1>
        )}
        {proposalLinks && (
          <ul className="proceeding-voting-details-page-type-react__toc-list">
            {proposalLinks.map((val, idx) => (
              <li key={idx}>
                <Link {...val} />
              </li>
            ))}
          </ul>
        )}
        {Part(
          recommendationHeader,
          recommendationHeaderPropertyName,
          recommendationList
        )}
        {Part(minorityListHeader, minorityListHeaderPropertyName, minorityList)}
        {Part(looseListHeader, looseListHeaderPropertyName, looseList)}
        <Link {...parentLink} />
      </div>
    </Row>
  </PageWrapper>
);
export default ProceedingVotingDetailsPageType;
