import React from 'react';
import './LiveMeetingInformationBlockTypeTop.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import LiveMeetingInformationBlockTypeTopImageBox from './LiveMeetingInformationBlockTypeTopImageBox';
import LiveMeetingInformationBlockTypeTopTextBox from './LiveMeetingInformationBlockTypeTopTextBox';
import classNames from 'classnames';

const LiveMeetingInformationBlockTypeTop = () => {
  const { cmsData, errorNoData } = useSelector((s: RootState) => s.LiveBlock);
  const hasData = !errorNoData;
  const showError = cmsData.mainBlock.hearingErrorTop;
  const showImageBox = cmsData.mainBlock.hasImage;
  const errorTitle = cmsData.mainBlock.hearingsUnavailableTitle;
  const errorText = cmsData.mainBlock.hearingsUnavailableMessage;
  const errorImage = cmsData.mainBlock.hearingsUnavailableImage;

  return (
    <div className="live-meeting-information-block-type-top-react">
      {hasData && !showError && (
        <div className="live-meeting-information-block-type-top-react__main">
          {showImageBox && (
            <div className="live-meeting-information-block-type-top-react__main__left">
              <LiveMeetingInformationBlockTypeTopImageBox />
            </div>
          )}
          <div className="live-meeting-information-block-type-top-react__main__right">
            <LiveMeetingInformationBlockTypeTopTextBox />
          </div>
        </div>
      )}
      {!(hasData && !showError) && (
        <div className="live-meeting-information-block-type-top-react__main">
          {errorImage && (
            <div className="live-meeting-information-block-type-top-react__main__left">
              <div className="live-meeting-information-block-type-top-react__main__error-image">
                <img src={errorImage} alt="Hearings not available" />
              </div>
            </div>
          )}
          <div
            className={classNames(
              'live-meeting-information-block-type-top-react__alert',
              {
                'live-meeting-information-block-type-top-react__alert--no-image':
                  !errorImage,
              }
            )}
          >
            <div className="block-main block-live-main live-meeting-information-block-type-top-react__main__right">
              <div className="live-meeting-information-block-type-top-react__error">
                <div>
                  <div className="live-meeting-information-block-type-top-react__error__bar"></div>
                  <h2 className="live-meeting-information-block-type-top-react__error__title">
                    {errorTitle}
                  </h2>
                  <p
                    className="live-meeting-information-block-type-top-react__error__text"
                    dangerouslySetInnerHTML={{ __html: errorText }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTop />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopWrapper;
