import React from 'react';
import './BlockButton.scss';
import { BlockButtonProps } from '/Models/Generated/BlockButtonProps';
import { BlockButtonTheme } from '/Models/Generated/BlockButtonTheme';
import Button from '/Components/Atoms/Button/Button';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';

const BlockButton = ({
  theme,
  text,
  href,
  isInRichTextArea,
}: BlockButtonProps) => {
  if (href && text) {
    if (theme === BlockButtonTheme.ThemeBlueBackgroundWhiteText)
      return (
        <BlockWrapper isInRichTextArea={isInRichTextArea}>
          <Button href={href} text={text} />
        </BlockWrapper>
      );
    return (
      <BlockWrapper isInRichTextArea={isInRichTextArea}>
        <h2 className="block-button">
          <a className="block-button--right-aligned-arrow" href={href}>
            {text}
          </a>
        </h2>
      </BlockWrapper>
    );
  } else return <></>;
};

export default BlockButton;
