import React, { useEffect, useRef, useState } from 'react';
import './CardMetaLinksList.scss';
import CardRelatedLinks from '../CardRelatedLinks/CardRelatedLinks';
import cn from 'classnames';
import { SearchHitMetadataProps } from '/Models/Generated/SearchHitMetadataProps';

interface CardMetaLinksListProps {
  cardMetaLinks: SearchHitMetadataProps;
  largerGapOnLinks?: boolean;
  expandRelatedLinksButtonText: string;
  collapseRelatedLinksButtonText: string;
}

const CardMetaLinksList = ({
  cardMetaLinks,
  largerGapOnLinks,
  expandRelatedLinksButtonText,
  collapseRelatedLinksButtonText,
}: CardMetaLinksListProps) => {
  const [labelAvailable, setLabelAvailable] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardMetaLinks && cardMetaLinks.linkLists.length) {
      setLabelAvailable(false);
      for (let i = 0; i < cardMetaLinks.linkLists.length; i++) {
        if (cardMetaLinks.linkLists[i].label) {
          setLabelAvailable(true);
          break;
        }
      }
    }
    if (
      largerGapOnLinks &&
      listContainerRef.current &&
      listContainerRef.current.scrollHeight > 450
    ) {
      setHasOverflow(true);
    }
  }, [cardMetaLinks]);

  return (
    <div className="card-meta-links-list">
      {cardMetaLinks && cardMetaLinks.title && (
        <h3 className="card-meta-links-list__title">{cardMetaLinks.title}</h3>
      )}
      <div
        className={cn('card-meta-links-list__items', {
          'link-only': !labelAvailable,
          'larger-gap': largerGapOnLinks,
          expanded: isExpanded,
          'has-overflow': hasOverflow,
        })}
        ref={listContainerRef}
      >
        {cardMetaLinks &&
          cardMetaLinks.linkLists.map((val, idx) => (
            <CardRelatedLinks key={idx} {...val} />
          ))}
      </div>
      {hasOverflow && (
        <div
          className={cn('expand-button', {
            expanded: isExpanded,
          })}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded
            ? collapseRelatedLinksButtonText
            : expandRelatedLinksButtonText}
        </div>
      )}
    </div>
  );
};

export default CardMetaLinksList;
