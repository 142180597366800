import React, { useEffect, useState } from 'react';
import './Komite.scss';
import { KomiteProps } from '/Models/Generated/KomiteProps';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import useUrlParam from '/Shared/Code/Hooks/UseUrlParam';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import PortraitsCarousel from '/Components/Molecules/PortraitsCarousel/PortraitsCarousel';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import GreyRow, {
  GreyRowVerticalMarginBottom,
} from '/Components/Layout/GreyRow/GreyRow';
import FocusItem4 from '/Components/Organisms/FocusItem4/FocusItem4';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import Radiobuttons from '/Components/Atoms/Radiobuttons/Radiobuttons';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import FocusItemList from '/Components/Organisms/FocusItemList/FocusItemList';
import { FocusItemProps } from '/Components/../Models/Generated/FocusItemProps';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import Link from '/Components/Atoms/Link/Link';
import { Alignment } from '/Models/Generated/Alignment';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';

const Komite = ({
  pageTitle,
  mainIntro,
  mainBody,
  actualTitle,
  threeColumnContentAreaModules,
  threeColumnContentAreaTop,
  removeCommitteeAndHearingInfo,
  hideTodaysCompound,
  dontShowAutomaticMemberList,
  errorMessage,
  caption,
  mainImageOld,
  isEosCommittee,
  requestParameters,
  focusItems,
  committeeMembersList,
  committeeContentContainer,
}: KomiteProps) => {
  const [vervRows, setVervRows] = useState(
    committeeContentContainer.committeeMembers.rows
  );
  const [vervColumns, setVervColumns] = useState(
    committeeContentContainer.committeeMembers.columns
  );

  const isMounted = useComponentDidMount();

  const [vervSortColumn, setVervSortColumn] = useState(0);
  const [vervSortDirection, setVervSortDirection] = useState(
    SortDirectionEnum.None
  );

  const [checked, setChecked] = useUrlParam(
    requestParameters.requestParameterNames.forceLevel,
    (window &&
      SSRQueryStringReader.Read(
        requestParameters.requestParameterNames.forceLevel
      )) ??
      null
  );
  const radioButtonsOptions = [
    {
      label:
        committeeContentContainer.committeeMembersViewChoice
          .todaysCompoundLabel,
      value: 'todaysCompound',
      checked:
        SSRQueryStringReader.Read(
          requestParameters.requestParameterNames.forceLevel
        ) === 'true' || !checked
          ? true
          : false,
      name: '',
    },
    {
      label:
        committeeContentContainer.committeeMembersViewChoice
          .chooseParliamentPeriodLabel,
      value: 'parliamentPeriod',
      checked:
        SSRQueryStringReader.Read(
          requestParameters.requestParameterNames.forceLevel
        ) === 'false'
          ? true
          : false,
      name: '',
    },
  ];

  const [checkedView, setCheckedView] = useState(radioButtonsOptions);

  const [pid, setPid] = useUrlParam(
    requestParameters.requestParameterNames.parliamentPeriodId,
    (window &&
      SSRQueryStringReader.Read(
        requestParameters.requestParameterNames.parliamentPeriodId
      )) ??
      null
  );
  const [tab, setTab] = useUrlParam(
    requestParameters.requestParameterNames.tab,
    (window &&
      SSRQueryStringReader.Read(requestParameters.requestParameterNames.tab)) ??
      null
  );

  useEffect(() => {
    if (!isMounted) return;
    setChecked(checkedView.find((n) => n.checked)?.value === 'todaysCompound');
    window.location.reload();
  }, [pid, vervColumns, checkedView]);

  useEffect(() => {
    if (
      window.location.hash === '#primaryfilter' &&
      !removeCommitteeAndHearingInfo
    )
      document.getElementById('primaryfilter').scrollIntoView();
  }, [tab, checkedView, pid]);

  useEffect(() => {
    if (!isMounted) return;
    if (!removeCommitteeAndHearingInfo) window.location.hash = 'primaryfilter';
  }, [tab, checkedView, pid]);

  return (
    <div className="komite-react">
      {committeeMembersList && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.xlarge}
        >
          <PortraitsCarousel committeeMembersList={committeeMembersList} />
        </Row>
      )}
      <Row
        horizontalPadding={HorizontalPadding.indent}
        verticalPaddingBottom={VerticalPadding.large}
      >
        {mainImageOld && (
          <ImageWithCaption
            {...mainImageOld}
            maxWidthTablet={true}
            fullSize
            alignment={Alignment.None}
          />
        )}
        <h1>{pageTitle}</h1>
        <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        <ContentArea {...mainBody} />
      </Row>
      {threeColumnContentAreaModules.items.length > 0 && (
        <Row
          lineAbove
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.medium}
          lineBelow={threeColumnContentAreaTop.items.length ? false : true}
        >
          <ContentArea {...threeColumnContentAreaModules} />
        </Row>
      )}
      {threeColumnContentAreaTop.items.length > 0 && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
          lineAbove={threeColumnContentAreaModules ? false : true}
          lineBelow
        >
          <ContentArea {...threeColumnContentAreaTop} />
        </Row>
      )}
      {focusItems?.length > 0 && (
        <>
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.medium}
          >
            <GreyBar text={actualTitle} />
          </Row>
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.small}
          >
            <FocusItemList
              items={focusItems.filter(
                (val: FocusItemProps) => val?.href !== null
              )}
            />
          </Row>
        </>
      )}
      {!removeCommitteeAndHearingInfo && (
        <Row verticalPaddingBottom={VerticalPadding.large}>
          <GreyRow>
            <span id="primaryfilter"></span>
            <div className="komite-react__filters">
              {!isEosCommittee && (
                <>
                  <div className="komite-react__filters__tabs">
                    <TabButtonStrip>
                      <TabButton
                        text={
                          committeeContentContainer.committeeTabs
                            .proceedingTabItem.text
                        }
                        selected={
                          tab ===
                            committeeContentContainer.committeeTabs
                              .proceedingTabItem.parameterValue || !tab
                        }
                        onClick={() =>
                          setTab(
                            committeeContentContainer.committeeTabs
                              .proceedingTabItem.parameterValue
                          )
                        }
                      />
                      {!dontShowAutomaticMemberList &&
                        committeeContentContainer.committeeTabs
                          .committeeMembersTabItem && (
                          <TabButton
                            text={
                              committeeContentContainer.committeeTabs
                                .committeeMembersTabItem.text
                            }
                            selected={
                              tab ===
                              committeeContentContainer.committeeTabs
                                .committeeMembersTabItem.parameterValue
                            }
                            onClick={() =>
                              setTab(
                                committeeContentContainer.committeeTabs
                                  .committeeMembersTabItem.parameterValue
                              )
                            }
                          />
                        )}
                      {committeeContentContainer.committeeDocuments
                        .showCommitteeDocuments && (
                        <TabButton
                          text={
                            committeeContentContainer.committeeTabs
                              .committeeDocumentsTabItem.text
                          }
                          selected={
                            tab ===
                            committeeContentContainer.committeeTabs
                              .committeeDocumentsTabItem.parameterValue
                          }
                          onClick={() =>
                            setTab(
                              committeeContentContainer.committeeTabs
                                .committeeDocumentsTabItem.parameterValue
                            )
                          }
                        />
                      )}
                    </TabButtonStrip>
                  </div>
                  <div className="komite-react__filters__dropdown">
                    <DropDownFilterWrapper
                      halfwidth={true}
                      label=""
                      options={
                        committeeContentContainer.committeeTabs
                          .dropDownForMobile
                      }
                      selectedValue={
                        !tab
                          ? committeeContentContainer.committeeTabs
                              .dropDownForMobile[0].value
                          : tab
                      }
                      setSelected={setTab}
                      id={
                        committeeContentContainer.committeeMembersViewChoice
                          .parliamentPeriodDropDown.labelText
                      }
                      name={
                        committeeContentContainer.committeeMembersViewChoice
                          .parliamentPeriodDropDown.labelText
                      }
                    />
                  </div>
                </>
              )}

              {(tab ===
                committeeContentContainer.committeeTabs.committeeMembersTabItem
                  .parameterValue ||
                (!tab && isEosCommittee)) && (
                <>
                  {isEosCommittee && <h2>Utvalgets medlemmer</h2>}
                  {!hideTodaysCompound && (
                    <Radiobuttons
                      id={caption}
                      options={checkedView}
                      setOptions={setCheckedView}
                      label={
                        committeeContentContainer.committeeMembersViewChoice
                          .chooseToSeeLabel
                      }
                    />
                  )}

                  <DropDownFilterWrapper
                    halfwidth={true}
                    disableIfOnlyOneOption={
                      hideTodaysCompound
                        ? checkedView[1].checked
                        : checkedView[0].checked
                    }
                    label={
                      committeeContentContainer.committeeMembersViewChoice
                        .parliamentPeriodDropDown.labelText
                    }
                    options={
                      committeeContentContainer.committeeMembersViewChoice
                        .parliamentPeriodDropDown.items
                    }
                    selectedValue={
                      pid
                        ? pid
                        : committeeContentContainer.committeeMembersViewChoice
                            .parliamentPeriodDropDown.items[0].value
                    }
                    disabled={
                      hideTodaysCompound
                        ? checkedView[1].checked
                        : checkedView[0].checked
                    }
                    setSelected={setPid}
                    id={
                      committeeContentContainer.committeeMembersViewChoice
                        .parliamentPeriodDropDown.labelText
                    }
                    name={
                      committeeContentContainer.committeeMembersViewChoice
                        .parliamentPeriodDropDown.labelText
                    }
                  />
                </>
              )}
            </div>
          </GreyRow>
        </Row>
      )}
      <Row horizontalPadding={HorizontalPadding.indent}>
        {(tab ===
          committeeContentContainer.committeeTabs.proceedingTabItem
            .parameterValue ||
          !tab ||
          removeCommitteeAndHearingInfo) &&
          !isEosCommittee && (
            <Row>
              {!committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview
                .committeeProceedingOverviewInProcessList &&
              !committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview
                .committeeProceedingOverviewProcessedList &&
              committeeContentContainer.committeeProceedingProps
                .proceedingInCommitteeMissingText ? (
                committeeContentContainer.committeeProceedingProps
                  .proceedingInCommitteeMissingText
              ) : (
                <>
                  <GreyBar
                    verticalMarginBottom={GreyRowVerticalMarginBottom.medium}
                    text={
                      committeeContentContainer.committeeProceedingProps
                        .handledTitle
                    }
                  />

                  <Link
                    className="komite-react__processed-link"
                    href={
                      committeeContentContainer.committeeProceedingProps
                        .committeeProceedingOverview.linkToProceedingsProcessed
                        .href
                    }
                    text={
                      committeeContentContainer.committeeProceedingProps
                        .committeeProceedingOverview.linkToProceedingsProcessed
                        .text
                    }
                    langAttribute={
                      committeeContentContainer.committeeProceedingProps
                        .committeeProceedingOverview.linkToProceedingsProcessed
                        .langAttribute
                    }
                  />
                </>
              )}
            </Row>
          )}
        {(tab ===
          committeeContentContainer.committeeTabs.proceedingTabItem
            .parameterValue ||
          !tab ||
          removeCommitteeAndHearingInfo) &&
          !isEosCommittee &&
          committeeContentContainer.committeeProceedingProps
            .committeeProceedingOverview
            .committeeProceedingOverviewInProcessList &&
          committeeContentContainer.committeeProceedingProps
            .committeeProceedingOverview
            .committeeProceedingOverviewProcessedList && (
            <GreyBar
              verticalMarginBottom={
                committeeContentContainer.committeeProceedingProps
                  .showCommitteeSubscriptionLink
                  ? GreyRowVerticalMarginBottom.small
                  : GreyRowVerticalMarginBottom.medium
              }
              text={
                committeeContentContainer.committeeProceedingProps
                  .toProcessTitle
              }
            />
          )}

        {(tab ===
          committeeContentContainer.committeeTabs.proceedingTabItem
            .parameterValue ||
          !tab ||
          removeCommitteeAndHearingInfo) && (
          <>
            {committeeContentContainer.committeeProceedingProps
              .showCommitteeSubscriptionLink &&
              committeeContentContainer.committeeProceedingProps
                .committeeSubscriptionLink?.href &&
              !isEosCommittee && (
                <IconLinkGeneric
                  iconString="notification"
                  href={
                    committeeContentContainer.committeeProceedingProps
                      .committeeSubscriptionLink.href
                  }
                  text={
                    committeeContentContainer.committeeProceedingProps
                      .committeeSubscriptionLink.text
                  }
                  iconOnRight
                  className="komite-react__subscription-link"
                />
              )}
            {committeeContentContainer.committeeProceedingProps
              .committeeProceedingOverview
              .committeeProceedingOverviewInProcessList?.length > 0 ? (
              <ul className="komite-react__proceeding-list">
                {committeeContentContainer.committeeProceedingProps.committeeProceedingOverview.committeeProceedingOverviewInProcessList.map(
                  (items, itemId) => (
                    <FocusItem4
                      key={itemId}
                      proccedingLink={items.proccedingLink}
                      formalTitle={items.formalTitle}
                      abstract={items.abstract}
                      department={items.department}
                      shortDateString={items.shortDateString}
                      beforeDateString={items.beforeDateString}
                      proceedingTypeName={items.proceedingTypeName}
                    />
                  )
                )}
              </ul>
            ) : (
              !isEosCommittee &&
              committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview
                .committeeProceedingOverviewInProcessList?.length > 0 &&
              committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview
                .committeeProceedingOverviewProcessedList?.length > 0 &&
              committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview.emptyListMessageInprocess
            )}
            {!isEosCommittee &&
              committeeContentContainer.committeeProceedingProps
                .committeeProceedingOverview
                .committeeProceedingOverviewInProcessList?.length > 0 && (
                <Link
                  href={
                    committeeContentContainer.committeeProceedingProps
                      .committeeProceedingOverview.linkToProceedingsInProcess
                      .href
                  }
                  text={
                    committeeContentContainer.committeeProceedingProps
                      .committeeProceedingOverview.linkToProceedingsInProcess
                      .text
                  }
                  langAttribute={
                    committeeContentContainer.committeeProceedingProps
                      .committeeProceedingOverview.linkToProceedingsInProcess
                      .langAttribute
                  }
                />
              )}
          </>
        )}

        {(tab ===
          committeeContentContainer.committeeTabs.committeeMembersTabItem
            .parameterValue ||
          isEosCommittee) &&
          !removeCommitteeAndHearingInfo &&
          (committeeContentContainer.committeeMembers ? (
            <SortableTable
              {...committeeContentContainer.committeeMembers}
              setColumns={setVervColumns}
              setRows={setVervRows}
              rows={vervRows}
              columns={vervColumns}
              setSortDirection={setVervSortDirection}
              sortDirection={vervSortDirection}
              setSortColumn={setVervSortColumn}
              sortColumn={vervSortColumn}
            />
          ) : (
            errorMessage
          ))}

        {tab ===
          committeeContentContainer.committeeTabs.committeeDocumentsTabItem
            .parameterValue &&
          committeeContentContainer.committeeDocuments.showCommitteeDocuments &&
          !removeCommitteeAndHearingInfo && (
            <div className="komite-react__document-list">
              <GreyBar
                verticalMarginBottom={GreyRowVerticalMarginBottom.medium}
                text={
                  committeeContentContainer.committeeDocuments
                    .committeeDocumentsTitle
                }
                fullWidthText
              />
              <ul>
                {committeeContentContainer.committeeDocuments.documentLinkProps.map(
                  (val, idx) => (
                    <li key={idx}>
                      <Link
                        href={val.href}
                        text={val.text}
                        langAttribute={val.langAttribute}
                      />
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
      </Row>
    </div>
  );
};

export default Komite;
