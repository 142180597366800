import React from 'react';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import './EPOSSakBudsjettkapitler.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { EPOSSakBudsjettkapitlerProps } from '/Models/Generated/EPOSSakBudsjettkapitlerProps';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const EPOSSakBudsjettkapitler = ({
  mainIntro,
  belongsToProceeding,
  mainBody,
  formalTitle,
  table,
}: EPOSSakBudsjettkapitlerProps) => {
  return (
    <PageWrapper>
      <div className="epos-sak-budsjettkapitler-react">
        {HasContent(mainIntro) && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </Row>
        )}
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ContentArea {...belongsToProceeding} />
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <ContentArea {...mainBody} />
          <h2>{formalTitle}</h2>
          <SortableTable
            {...table}
            doSort={null}
            setDoSort={null}
            setColumns={null}
            setRows={null}
            setSortColumn={null}
            setSortDirection={null}
            sortColumn={0}
            sortDirection={SortDirectionEnum.Disabled}
          />
        </Row>
      </div>
    </PageWrapper>
  );
};

export default EPOSSakBudsjettkapitler;
