import React, { ReactNode } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import './ArticlePreview.scss';
import { ArticlePreviewProps } from '/Models/Generated/ArticlePreviewProps';
import classnames from 'classnames';

const WithLink = ({ url, children }: { url: string; children: ReactNode }) =>
  url ? (
    <a href={url} className="article-preview-react__header-link">
      {children}
    </a>
  ) : (
    <>{children}</>
  );

const ArticlePreview = ({
  title,
  titlePropertyName,
  mainBody,
  mainBodyPropertyName,
  imageUrl,
  imageUrlPropertyName,
  imageAltText,
  imageLink,
  videoUrl,
  videoUrlPropertyName,
  mainFocusCollection,
  langAttribute,
}: ArticlePreviewProps) => {
  const hasText = () => HasContent(mainBody) || mainFocusCollection?.length > 0;

  const hasHeader = () => title || titlePropertyName;

  const getHeader = () => {
    if (!hasHeader()) return <></>;
    if (imageLink)
      return (
        <WithLink url={imageLink}>
          <h2 data-epi-edit={titlePropertyName}>
            <span>{title}</span>
          </h2>
        </WithLink>
      );
    return (
      <h2 data-epi-edit={titlePropertyName}>
        <span>{title}</span>
      </h2>
    );
  };

  return (
    <article className="article-preview-react" lang={langAttribute || null}>
      {videoUrl && (
        <div
          data-epi-edit={videoUrlPropertyName}
          className={classnames('article-preview-react__video-wrapper', {
            'article-preview-react__video-wrapper--with-content':
              hasHeader() || hasText(),
          })}
        >
          <iframe
            src={videoUrl}
            title={imageAltText}
            allowFullScreen={true}
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      )}
      {!videoUrl && imageUrl && (
        <div
          className={classnames('article-preview-react__img-wrapper', {
            'article-preview-react__img-wrapper--with-content':
              hasHeader() || hasText(),
          })}
        >
          <WithLink url={imageLink}>
            <img
              src={imageUrl}
              alt={imageAltText ?? ''}
              data-epi-edit={imageUrlPropertyName}
            />
            {(title || titlePropertyName) && (
              <h2 data-epi-edit={titlePropertyName}>
                <span>{title}</span>
              </h2>
            )}
          </WithLink>
        </div>
      )}
      {(!imageUrl || videoUrl) && getHeader()}

      {hasText() && (
        <div className="article-preview-react__content-wrapper">
          <ContentArea
            {...mainBody}
            propertyName={mainBodyPropertyName}
            theme={RichTextTheme.ArticlePreview}
          />
          {mainFocusCollection?.length > 0 && (
            <ul className="article-preview-react__main-focus-collection">
              {mainFocusCollection.map((val, idx) => (
                <li key={idx}>
                  <a href={val.href}>{val.text}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </article>
  );
};

export default ArticlePreview;
