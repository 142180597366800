import React, { useEffect, useState, useRef } from 'react';
import './GlobalSearchPage.scss';
import CardResults from './Cards/CardResults/CardResults';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import cn from 'classnames';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { GlobalSearchPageProps } from '/Models/Generated/GlobalSearchPageProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Button from '/Components/Atoms/Button/Button';
import SearchInput from '/Components/Atoms/SearchInput/SearchInput';
import Pagination from '/Components/Atoms/Pagination/Pagination';
import SearchFilter from './Filter/SearchFilter';
import Spinner from '/Components/Atoms/Spinner/Spinner';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import InformationCard from './Cards/InformationCard/InformationCard';
import { GlobalSearchFacetResultProps } from '/Models/Generated/GlobalSearchFacetResultProps';
import SearchUrlUtilities from './GlobalSearchUrlUtilities';
import 'url-search-params-polyfill';

type LastUpdatedPropType = '' | 'page' | 'searchTerm' | 'filter' | 'sorting';
const GlobalSearchPage = ({
  mainIntro,
  results,
  showMoreLabelMobile,
  showLessLabelMobile,
  expandRelatedLinksButtonText,
  collapseRelatedLinksButtonText,
  recommendedHitLabel,
  noResultTitle,
  noResultIngress,
  errorTitle,
  errorIngress,
  sortProps,
  query,
  pageId,
  pageTitle,
  searchButtonText,
  searchInputPlaceholder,
  language,
  todayLabel,
}: GlobalSearchPageProps) => {
  const [searchResults, setSearchResults] = useState(results);
  const [searchFacetResults, setSearchFacetResults] =
    useState<GlobalSearchFacetResultProps>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const isMounted = useComponentDidMount();
  const [showHitScore] = useState(
    new URLSearchParams(
      typeof window === 'undefined'
        ? results.requestQueryString
        : window.location.search
    ).get('score') === 'true'
  );

  const [sortBy, setSortBy] = useState(
    (
      sortProps?.items
        .filter((val) => val.isSelected === true)
        .map((val) => val.value) || ''
    ).toString()
  );
  const [page, setPage] = useState(results?.pagination?.currentPage);
  const [doSearch, setDoSearch] = useState(false);
  const [lastUpdatedProp, setLastUpdatedProp] =
    useState<LastUpdatedPropType>('');
  const [searchQuery, setSearchQuery] = useState(query || '');
  const [searchInput, setSearchInput] = useState(searchQuery || '');

  const [selectedDates, setSelectedDates] = useState(
    SearchUrlUtilities.GetDateFiltersFromUrlParams(
      searchResults,
      results.requestQueryString
    )
  );
  const [dateQuery, setDateQuery] = useState(
    SearchUrlUtilities.GetDateFilterQueryFromDateFilters(selectedDates)
  );
  const [selectedFilters, setSelectedFilters] = useState(
    SearchUrlUtilities.GetFacetFiltersFromUrlParams(
      searchResults,
      results.requestQueryString
    )
  );
  const [filterQuery, setFilterQuery] = useState(
    SearchUrlUtilities.GetFacetFilterQueryFromFacetFilters(selectedFilters)
  );
  const isPopstate = useRef(false);

  useEffect(() => {
    if (
      document.getElementById('global-search-page-filter-wrapper') &&
      window.innerWidth > 1024 &&
      (searchQuery || filterQuery || dateQuery)
    ) {
      document
        .getElementById('global-search-page-filter-wrapper')
        .scrollIntoView({
          behavior: 'smooth',
        });
    }
  }, [searchQuery, filterQuery, dateQuery, sortBy, doSearch]);

  const fetchData = async (
    pageNumber: number,
    lastUpdatedProp: LastUpdatedPropType
  ) => {
    const searchUrl = SearchUrlUtilities.CreateSearchQueryUrl(
      pageId,
      searchQuery,
      language,
      sortBy,
      pageNumber,
      filterQuery,
      dateQuery,
      showHitScore
    );
    try {
      setLoading(true);
      const response = await fetch(searchUrl);
      const data = await response.json();
      setSearchResults(data);

      if (lastUpdatedProp === 'filter' || lastUpdatedProp === 'searchTerm') {
        if (typeof window === 'undefined') {
          return null;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'internal_search',
          newSearchTerm: lastUpdatedProp === 'searchTerm',
          updatedProp: lastUpdatedProp,
          term: searchQuery,
          filter: filterQuery
            .split(/[&=]/)
            .filter((element, idx) => element && idx % 2 === 0),
          results: data?.pagination.totalItems.toString(),
        });
      }
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setSearchResults(null);
      setError(true);
      console.error('Error fetching data:', error); // eslint-disable-line
    }
  };

  const fetchFacetData = async () => {
    const facetsUrl = SearchUrlUtilities.CreateFacetQueryUrl(
      pageId,
      searchQuery,
      language,
      filterQuery,
      dateQuery
    );

    try {
      const response = await fetch(facetsUrl);
      const data = await response.json();
      setSearchFacetResults(data);

      //setFacetError(false); TODO
    } catch (error) {
      setSearchFacetResults(null);
      //setFacetError(false); TODO
      console.error('Error fetching facet data:', error); // eslint-disable-line
    }
  };

  const performSearch = (resetPage: boolean) => {
    if (isMounted) {
      let fetchPage = page;
      if (resetPage) {
        fetchPage = 1;
        setPage(1);
      }

      fetchData(fetchPage, lastUpdatedProp);
    }
    fetchFacetData();
  };

  useEffect(() => {
    if (isMounted) {
      setLastUpdatedProp('searchTerm');
      setDoSearch(!doSearch);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (isMounted) {
      setLastUpdatedProp('filter');
      setDoSearch(!doSearch);
    }
  }, [filterQuery, dateQuery]);

  useEffect(() => {
    if (isMounted) {
      setLastUpdatedProp('sorting');
      setDoSearch(!doSearch);
    }
  }, [sortBy]);

  useEffect(() => {
    if (isMounted) {
      performSearch(true);
    }
  }, [doSearch]);

  useEffect(() => {
    if (isMounted) {
      setLastUpdatedProp('page');
      performSearch(false);
    }
  }, [page]);

  useEffect(() => {
    //fetch initialFacetData
    fetchFacetData();
  }, []);

  const setQueryValuesFromUrl = () => {
    const queryParameters = new URLSearchParams(
      typeof window === 'undefined'
        ? results.requestQueryString
        : window.location.search
    );

    setSearchQuery(queryParameters.get('query'));
    setSearchInput(queryParameters.get('query'));
    setPage(Number(queryParameters.get('page')));
    setSortBy(queryParameters.get('sort'));

    setSelectedFilters(
      SearchUrlUtilities.GetFacetFiltersFromUrlParams(
        searchResults,
        results.requestQueryString
      )
    );
    setSelectedDates(
      SearchUrlUtilities.GetDateFiltersFromUrlParams(
        searchResults,
        results.requestQueryString
      )
    );
  };

  useEffect(() => {
    if (!isPopstate.current) {
      const url = new URL(window.location.origin + window.location.pathname);
      url.searchParams.set('query', searchQuery);
      url.searchParams.set('page', '' + page);
      url.searchParams.set('sort', sortBy);
      if (showHitScore) {
        url.searchParams.set('score', 'true');
      }
      window.history.pushState(
        {},
        '',
        window.location.pathname + url.search + filterQuery + dateQuery
      );
    }
    isPopstate.current = false;
  }, [searchQuery, page, filterQuery, sortBy, dateQuery]);

  useEffect(() => {
    const handlePopState = () => {
      isPopstate.current = true;
      setQueryValuesFromUrl();
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (searchResults && searchFacetResults) {
      //Gets all parameters
      const urlParameters = new URLSearchParams(window.location.search);
      //Gets a list of all filter names
      const allowedFilters = searchFacetResults.categories.map(
        (filter) => filter.title
      );

      //Creates a list of params that are not filters
      const keysToDelete = [];
      urlParameters.forEach((value, key) => {
        if (!allowedFilters.includes(key)) {
          keysToDelete.push(key);
        }
      });
      //Deletes all params that are not filters
      keysToDelete.forEach((key) => urlParameters.delete(key));

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'internal_search',
        newSearchTerm: true,
        updatedProp: 'searchTerm',
        term: searchQuery,
        filter: urlParameters //Creating comma separated list of filter values
          .toString()
          .split(/[&=]/)
          .filter((element, idx) => element && idx % 2 === 0),
        results: searchResults.pagination.totalItems.toString(),
      });
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      const links = document.querySelectorAll(
        '.global-search-page__card-results a'
      );

      links.forEach(function (link) {
        link.addEventListener('click', function (event: MouseEvent) {
          event.preventDefault();

          function findParentWithAttribute(elem, attr) {
            while (elem) {
              if (elem.hasAttribute(attr)) {
                return elem.getAttribute(attr);
              }
              elem = elem.parentElement;
            }
            return '';
          }
          const cardType = findParentWithAttribute(link, 'data-card-type');
          const href = this.href;
          const target = this.target;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'select_result',
            result_click_type: cardType.toLowerCase(),
            term: searchQuery,
            filter: filterQuery
              .split(/[&=]/)
              .filter((element, idx) => element && idx % 2 === 0),
          });
          setTimeout(function () {
            if (event.metaKey || event.ctrlKey) {
              window.open(href, '_blank');
            } else if (event.shiftKey) {
              window.open(href, '_blank', 'noopener,noreferrer');
            } else if (target === '_blank' || target === '_new') {
              window.open(href, target);
            } else {
              window.location.href = href;
            }
          }, 100);
        });
      });
    }
  }, [isMounted]);

  return (
    <PageWrapper>
      <div dangerouslySetInnerHTML={{ __html: '<!-- /NOINDEX -->' }}></div>
      <div className="global-search-page">
        {mainIntro && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            className={cn('global-search-page__intro', {
              'mobile-search-open': mobileSearchOpen,
            })}
          >
            <div className=" global-search-page__intro-wrapper">
              <h1>{pageTitle}</h1>
              <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
            </div>
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.normal}>
          <div
            className="global-search-page__filter-wrapper"
            id="global-search-page-filter-wrapper"
          >
            <div
              className={cn('global-search-page__input-wrapper', {
                'mobile-search-open': mobileSearchOpen,
              })}
            >
              <SearchInput
                doSearch={() => {
                  setSearchQuery(searchInput);
                  if (searchInput === searchQuery) {
                    setLastUpdatedProp('');
                    setDoSearch(!doSearch);
                  }
                }}
                placeholder={searchInputPlaceholder}
                setQuery={(text) => {
                  setSearchInput(text);
                }}
                query={searchInput}
                loading={false}
                fillContainer
                enableClear
                searchIcon
                ariaLabelClearButton={
                  language === 'no' ? 'Nullstill søk' : 'Clear-search'
                }
              />
              <Button
                text={searchButtonText}
                onClick={() => {
                  setSearchQuery(searchInput);
                  if (searchInput === searchQuery) {
                    setLastUpdatedProp('');
                    setDoSearch(!doSearch);
                  }
                }}
              />
            </div>

            {searchResults &&
              searchResults?.filters &&
              (searchFacetResults?.categories ??
                searchResults?.filters?.categories) && (
                <SearchFilter
                  todayLabel={todayLabel}
                  language={language}
                  {...searchResults?.filters}
                  setFilterQuery={setFilterQuery}
                  setDateQuery={setDateQuery}
                  setMobileSearchOpen={setMobileSearchOpen}
                  mobileSearchOpen={mobileSearchOpen}
                  totalItems={searchResults.pagination.totalItems}
                  categories={
                    searchFacetResults?.categories ??
                    searchResults?.filters?.categories
                  }
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                  setSelectedDates={setSelectedDates}
                  selectedDates={selectedDates}
                />
              )}
          </div>
        </Row>
        {!loading && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
          >
            <div className="global-search-page__results-wrapper">
              {searchResults?.hits.length > 0 &&
                searchResults &&
                searchResults.numberOfHitsLabel && (
                  <div className="global-search-page__number-of-results">
                    <span>{searchResults.numberOfHitsLabel}</span>
                    {sortProps && (
                      <DropDownFilterWrapper
                        className="search-result-sort"
                        label={sortProps.labelText}
                        labeledBy={'sortBy'}
                        id={'sortBy'}
                        name={'sortBy'}
                        selectedValue={sortBy}
                        options={sortProps.items}
                        setSelected={(sortValue) => {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            event: 'sort_by',
                            sort: sortValue,
                          });
                          setSortBy(sortValue);
                        }}
                      />
                    )}
                  </div>
                )}
              <div className="global-search-page__card-results">
                {error && (
                  <Row
                    horizontalPadding={HorizontalPadding.normal}
                    verticalPadding={VerticalPadding.normal}
                  >
                    <InformationCard
                      hitType={'error'}
                      cardHeaderProps={{
                        title: {
                          text: errorTitle || 'Beklager, noe gikk galt!',
                          href: '',
                          langAttribute: '',
                        },
                        hitTypeLabel: '',
                        recommendedHit: false,
                        lastUpdated: '',
                      }}
                      cardTextProps={{
                        text: errorIngress,
                      }}
                      cardImageProps={undefined}
                      cardMetaLinks={undefined}
                      cardRelatedLinks={undefined}
                      cardRelatedTopicLinks={undefined}
                      hitScore={0}
                    />
                  </Row>
                )}
                {searchResults?.hits.length > 0 && searchResults && (
                  <CardResults
                    {...searchResults}
                    showHitScore={showHitScore}
                    showMoreLabelMobile={showMoreLabelMobile}
                    showLessLabelMobile={showLessLabelMobile}
                    expandRelatedLinksButtonText={expandRelatedLinksButtonText}
                    collapseRelatedLinksButtonText={
                      collapseRelatedLinksButtonText
                    }
                    recommendedHitLabel={recommendedHitLabel}
                  />
                )}
                {searchResults?.hits.length === 0 && (
                  <InformationCard
                    hitType={'NoResult'}
                    cardHeaderProps={{
                      title: {
                        text: noResultTitle || 'Søket ditt ga 0 treff',
                        href: '',
                        langAttribute: '',
                      },
                      hitTypeLabel: '',
                      recommendedHit: false,
                      lastUpdated: '',
                    }}
                    cardTextProps={{
                      text: noResultIngress,
                    }}
                    cardImageProps={undefined}
                    cardMetaLinks={undefined}
                    cardRelatedLinks={undefined}
                    cardRelatedTopicLinks={undefined}
                    hitScore={0}
                  />
                )}
              </div>
              {searchResults?.hits.length > 0 &&
                searchResults &&
                searchResults.numberOfHitsLabel && (
                  <div className="global-search-page__number-of-results">
                    <span>{searchResults.numberOfHitsLabel}</span>
                  </div>
                )}
              {searchResults?.pagination &&
                searchResults?.pagination.totalPages > 1 && (
                  <Pagination
                    {...searchResults.pagination}
                    currentPage={page}
                    onClickFunction={(pageNumber: number) => {
                      if (typeof window === 'undefined') {
                        return null;
                      }
                      window.scrollTo(0, 0);
                      setPage(pageNumber);
                    }}
                  />
                )}
            </div>
          </Row>
        )}
        {loading && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPadding={VerticalPadding.normal}
          >
            <Spinner />
          </Row>
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: '<!-- /NOINDEX -->' }}></div>
    </PageWrapper>
  );
};

export default GlobalSearchPage;
