/* eslint-disable no-console */
import React, { useRef } from 'react';
import './Form.scss';
import cn from 'classnames';
import Button from '/Components/Atoms/Button/Button';

export interface EncTypes {
  default: 'application/x-www-form-urlencoded';
  multiPartFormData: 'multipart/form-data'; // use if form contains input type=file
  debug: 'text/plain';
}

export interface FormProps {
  action: string;
  method?: 'GET' | 'POST';
  isLoading: boolean;
  enctype?: string;
  buttonText?: string;
  children?: React.ReactNode;
  ajaxHandler?: (e: React.SyntheticEvent, formElement: HTMLFormElement) => void;
  hideButton?: boolean;
  formRef?: React.MutableRefObject<HTMLFormElement>;
}

const Form = ({
  action,
  method = 'POST',
  enctype = 'application/x-www-form-urlencoded',
  buttonText = 'Send',
  isLoading,
  children,
  ajaxHandler,
  hideButton,
  formRef,
}: FormProps) => {
  const inputEl = formRef ?? useRef<HTMLFormElement>(null);

  const handleSubmit = (e: React.SyntheticEvent) => {
    if (ajaxHandler) ajaxHandler(e, inputEl.current);
    else inputEl.current.submit();
  };
  return (
    <form
      className={cn('form-react', { 'form-react--loading': isLoading })}
      ref={inputEl}
      action={action}
      method={method}
      encType={enctype}
      onSubmit={handleSubmit}
    >
      {children}
      {!hideButton && (
        <div className="form-react__submit">
          <Button text={buttonText} type="submit" />
        </div>
      )}
    </form>
  );
};

export default Form;
