import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './UrlToHearingsOfCommittee.scss';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { UrlToHearingsOfCommitteeProps } from '/Models/Generated/UrlToHearingsOfCommitteeProps';
import Link from '/Components/Atoms/Link/Link';

const UrlToHearingsOfCommittee = ({
  moduleTextHeader,
  moduleTextHeaderPropertyName,
  moduleText,
  moduleTextPropertyName,
  linkToHearing,
}: UrlToHearingsOfCommitteeProps) => {
  return (
    <ColumnContentWrapper
      title={moduleTextHeader}
      customTag="article"
      titlePropertyName={moduleTextHeaderPropertyName}
    >
      <ContentArea
        {...moduleText}
        propertyName={moduleTextPropertyName}
        theme={RichTextTheme.ArticlePreview}
      />
      <Link
        className="url-to-hearings-of-committee-react-link"
        href={linkToHearing.href}
        langAttribute={linkToHearing.langAttribute}
        text={linkToHearing.text}
      />
    </ColumnContentWrapper>
  );
};

export default UrlToHearingsOfCommittee;
