import React from 'react';
import './IconButton.scss';
import cn from 'classnames';

export enum IconButtonTheme {
  none,
  serif,
  sans,
}

export enum Icon {
  Close,
}

export interface Props {
  theme?: IconButtonTheme;
  icon: Icon;
  className?: string;
  text: string;
  onClick?: (e) => void;
}

const IconButton = ({ onClick, icon, text, className, theme }: Props) => {
  return onClick && text ? (
    <button
      className={cn(
        'icon-button-react',
        { 'icon-button-serif': theme === IconButtonTheme.serif },
        { 'icon-button-sans': theme === IconButtonTheme.sans },
        { 'icon-button-icon-close': icon === Icon.Close },
        className
      )}
      onClick={onClick}
    >
      {text}
    </button>
  ) : (
    <></>
  );
};

export default IconButton;
