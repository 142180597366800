import React from 'react';
import './ResultDebate.scss';
import cn from 'classnames';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultKeywords from './Shared/ResultKeywords';
import ResultPublished from './Shared/ResultPublished';
import ResultStatus from './Shared/ResultStatus';
import ResultHeader from './Shared/ResultHeader';
import { Props } from '/Models/FrontendOnly/Search/Results';
import MoreResults from '/Components/Atoms/MoreResults/MoreResults';

const ResultDebate = (props: Props) => {
  const {
    title,
    summary,
    url,
    published,
    specifics,
    relevance,
    keywords,
    highlighting,
    isChild,
  } = props;
  return (
    <article
      className={cn('search-result-debate-article-react', {
        'is-child-react': isChild,
      })}
    >
      <ResultHeader url={url} title={title} relevance={relevance} />
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      {!isChild && <ResultSummary summary={summary} />}
      <ResultHighlighting highlighting={highlighting} />
      <ResultKeywords keywords={keywords} />
      <MoreResults {...props} />
    </article>
  );
};

export default ResultDebate;
