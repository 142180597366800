import React, { useState } from 'react';
import './TopMenuLanguageSwitcher.scss';
import { HierarchyNodeProps } from '/Models/Generated/HierarchyNodeProps';
import cn from 'classnames';

const TopMenuLanguageSwitcher = (props: HierarchyNodeProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ariaControls = 'top-menu-language-switcher-content';
  if (props)
    return (
      <nav className="top-menu-language-switcher-react">
        <div>
          <button
            className={cn('top-menu-language-switcher-button', {
              'is-open-react': isOpen,
            })}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
            aria-controls={ariaControls}
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-pressed={isOpen ? 'true' : 'false'}
          >
            {props.text}
          </button>
          <ul
            className={cn('top-menu-language-switcher-container', {
              'top-menu-language-switcher-container-is-open': isOpen,
            })}
            id={ariaControls}
          >
            {props.childNodes &&
              props.childNodes.map((node, idx) => (
                <li key={idx}>
                  <a
                    href={node.url}
                    target={node.target}
                    className={cn({
                      'top-menu-language-selected': node.isActive,
                    })}
                  >
                    {node.text}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </nav>
    );
  else return <></>;
};

export default TopMenuLanguageSwitcher;
