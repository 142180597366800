import React, { useEffect } from 'react';
import './XmlImportInnstilling.scss';
import Panel from '/Components/Atoms/Panel/Panel';
import Link from '/Components/Atoms/Link/Link';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { XmlImportInnstillingProps } from '/Models/Generated/XmlImportInnstillingProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import XmlImportInnstillingHeader from '/Components/Organisms/XmlImportInnstillingHeader/XmlImportInnstillingHeader';
import XmlImportNavigation from '/Components/Organisms/XmlImportNavigation/XmlImportNavigation';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';

const XmlImportInnstilling = ({
  showFrontpage,
  showContent,
  showAll,
  isOnePageSpecialCase,
  articleTitle,
  headerProps,
  navigationProps,
  showSearchNavigation,
  searchNavigationProps,
  incompletePanelProps,
  innholdLabel,
  tocItems,
  firstChapterLink,
  recipient,
  signatureAsTable,
  chapterHeaderId,
  chapterHeader,
  content,
  previousLink,
  nextLink,
}: XmlImportInnstillingProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);
  return (
    <PageWrapper>
      <div className="xmlimportinnstilling-react">
        {showFrontpage && (
          <>
            <Row horizontalPadding={HorizontalPadding.indent}>
              <h1>{articleTitle}</h1>
            </Row>
            <Row
              horizontalPadding={HorizontalPadding.normal}
              verticalPaddingBottom={VerticalPadding.large}
            >
              <XmlImportInnstillingHeader {...headerProps} />
            </Row>
            <Row horizontalPadding={HorizontalPadding.normal} noPaddingOnMobile>
              <XmlImportNavigation
                {...navigationProps}
                showSearchNavigation={showSearchNavigation}
                searchNavigationProps={searchNavigationProps}
              />
            </Row>
            <Row horizontalPadding={HorizontalPadding.normal}>
              {incompletePanelProps && (
                <Panel title={incompletePanelProps.title}>
                  <p>{incompletePanelProps.text}</p>
                </Panel>
              )}
            </Row>
            {!isOnePageSpecialCase && (
              <>
                <Row
                  horizontalPadding={HorizontalPadding.indent}
                  verticalPaddingTop={VerticalPadding.xlarge}
                >
                  <h2 className="xmlimportinnstilling-react__heading-large">
                    {innholdLabel}
                  </h2>
                  {tocItems?.length > 0 && (
                    <ul className="xmlimportinnstilling-react__toc-list">
                      <>
                        {tocItems.map((item, idx) => (
                          <li key={idx}>
                            <Link href={item.href} text={item.text} />
                          </li>
                        ))}
                      </>
                    </ul>
                  )}
                </Row>
                <Row horizontalPadding={HorizontalPadding.normal} noPadding>
                  {firstChapterLink && !showAll && (
                    <MinutesPrevNext nextLink={firstChapterLink} />
                  )}
                </Row>
              </>
            )}
            <Row
              horizontalPadding={HorizontalPadding.indent}
              verticalPaddingTop={VerticalPadding.xlarge}
            >
              <div className="page page-publication">
                <div className="bigdoc-content">
                  <p>{recipient}</p>
                  {!isOnePageSpecialCase && !showAll && (
                    <div
                      dangerouslySetInnerHTML={{ __html: signatureAsTable }}
                    ></div>
                  )}
                </div>
              </div>
            </Row>
          </>
        )}
        {isOnePageSpecialCase && content && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <div
              className="xmlimportinnstilling-react__text-block"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </Row>
        )}
        {showContent && (
          <Row horizontalPadding={HorizontalPadding.normal}>
            {!showAll && (
              <XmlImportNavigation
                {...navigationProps}
                searchNavigationProps={searchNavigationProps}
                showSearchNavigation={showSearchNavigation}
              />
            )}
            {incompletePanelProps && (
              <Panel title={incompletePanelProps.title}>
                <p>{incompletePanelProps.text}</p>
              </Panel>
            )}
            <Row horizontalPadding={HorizontalPadding.indent}>
              <div className="page page-publication">
                <div className="bigdoc-content">
                  {!showAll && (
                    <div id={chapterHeaderId}>
                      <h1>{chapterHeader}</h1>
                    </div>
                  )}
                  {content && (
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                  )}
                </div>
              </div>
            </Row>
            <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
          </Row>
        )}
        {(isOnePageSpecialCase || showAll) && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingTop={VerticalPadding.xlarge}
          >
            <div
              className="page-publication"
              dangerouslySetInnerHTML={{ __html: signatureAsTable }}
            ></div>
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default XmlImportInnstilling;
