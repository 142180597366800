import useScrollOnDrag from 'react-scroll-ondrag';

export default (ref) =>
  useScrollOnDrag(ref, {
    runScroll: ({ dx }) => {
      const maxHorizontalScroll = (dom) => dom.scrollWidth - dom.clientWidth;
      const offsetX = Math.min(
        maxHorizontalScroll(ref.current),
        ref.current.scrollLeft + dx
      );
      ref.current.scrollLeft = offsetX; // eslint-disable-line no-param-reassign
    },
  });
