export const scrollToTarget = (
  id: string,
  offset: number | null = null,
  addIdHashToUrl = false
) => {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(id);
    const elementPosition = element?.getBoundingClientRect().top;
    const headerOffset = document.querySelector(
      '.top-menu-mobile-react'
    )?.clientHeight;

    const offsetToUse = typeof offset === 'number' ? offset : headerOffset;

    const offsetPosition = elementPosition + window.scrollY - offsetToUse;

    if (typeof offsetPosition === 'number') {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }

    if (addIdHashToUrl) {
      // (window.location.hash = id; cancels smooth scroll)
      const url = new URL(window.location.href);
      url.hash = id;
      history.pushState({}, '', url);
    }
  }
};
