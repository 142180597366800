import React from 'react';
import './IconLinkGeneric.scss';
import cn from 'classnames';

interface IconLinkGenericProps {
  iconString: string;
  iconHoverString?: string;
  href: string;
  text: string;
  className?: string;
  iconOnRight?: boolean;
}

const IconLinkGeneric = ({
  iconString,
  iconHoverString = iconString + '-hover',
  href,
  text,
  className,
  iconOnRight,
}: IconLinkGenericProps) => {
  const style = {
    '--icon': `var(--icon-${iconString})`,
    '--icon-hover': `var(--icon-${iconHoverString})`,
  } as React.CSSProperties;
  return (
    <a
      className={cn(
        'icon-link-generic-react',
        {
          'icon-link-generic-react__right': iconOnRight,
        },
        className
      )}
      style={style}
      href={href}
    >
      <span className="icon-link-generic-react__text">{text}</span>
    </a>
  );
};
export default IconLinkGeneric;
