import React, { useRef, useState } from 'react';
import './XmlImportNavigation.scss';
import { XmlImportNavigationProps } from '/Models/Generated/XmlImportNavigationProps';
import { XmlImportSearchNavigationProps } from '/Models/Generated/XmlImportSearchNavigationProps';

import ExpandableFilterWrapperForLongDocument from '/Components/Molecules/ExpandableFilterWrapperForLongDocument/ExpandableFilterWrapperForLongDocument';
import GreyRow, {
  GreyRowWidth,
  GreyRowTheme,
} from '/Components/Layout/GreyRow/GreyRow';

import cn from 'classnames';
import TableOfContentDropDown from './TableOfContentDropDown';
import XmlImportNavigationSearchNavigation from './XmlImportNavigationSearchNavigation';
import useUrlHash from '/Shared/Code/Hooks/UseUrlHash';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';
import useIsIntersectingViewport from '/Shared/Code/Hooks/UseIsIntersectingViewport';
import useElementDimension from '/Shared/Code/Hooks/UseElementDimension';

type Props = XmlImportNavigationProps & {
  searchNavigationProps?: XmlImportSearchNavigationProps;
  showSearchNavigation?: boolean;
};

const matchPrefix = 'match_';

const XmlImportNavigation = ({
  navigationId,
  innholdSokToggleLabel,
  tilToppenLabel,
  documentTitleLabel,
  currentTitle,
  currentSearchQuery,
  sokIDokumentetLabel,
  sokFeilmeldingLabel,
  sokLabel,
  innholdLabel,
  menuItems,
  thirdColumnLinks,

  searchNavigationProps,
  showSearchNavigation,
}: Props) => {
  const navigationRef = useRef(null);
  const navigationInnerRef = useRef(null);
  const navIsSticking = useIsIntersectingViewport(navigationRef);
  const navWidth = useElementDimension(navigationRef, 'width');
  const navHeight = useElementDimension(navigationInnerRef, 'height');
  const [searchQuery, setSearchQuery] = useState(currentSearchQuery);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [hash, setHash] = useUrlHash('');
  const currentMatch =
    parseInt(hash?.match(new RegExp(matchPrefix + '(\\d+)'))?.[1], 10) || -1;

  const containsSpecialChars = (str: string) =>
    /[?&^$#@!()+-,:;<>’\'-_*]/.test(str);

  const performSearch = () => {
    if (searchQuery?.length < 3 || containsSpecialChars(searchQuery))
      setShowErrorMessage(true);
    else {
      //  TODO: Implementer søk
      window.location.href = `?s=${searchQuery}&m=0&y=true`;
    }
  };

  const navigateToMatch = (index: number) => {
    if (typeof index !== 'number') return;
    if (index < 1 || index > searchNavigationProps.searchMatchCount) return;

    const onPageMatch = document.querySelector(`[id="${matchPrefix}${index}"]`);

    if (onPageMatch) {
      // Take filter height into account when scrolling to match
      const xmlNavigationHeight = document.querySelector(
        '[data-bigdoc-navigation]'
      )?.clientHeight;
      scrollToTarget(onPageMatch.id, xmlNavigationHeight);
      setHash(onPageMatch.id);
    } else {
      const currentUrl = new URL(window.location.href);
      const nextMatchUrl = searchNavigationProps.searchMatchesUrls[index - 1]; // is missing origin and search query parameter 's'
      const goToUrl = new URL(currentUrl.origin + nextMatchUrl);
      goToUrl.searchParams.set('s', currentUrl.searchParams.get('s'));
      window.location.href = goToUrl.toString();
    }
  };

  const handleAbortClick = () => {
    const currentUrl = new URL(window.location.href);
    const page = currentUrl.searchParams.get('m');
    const showComments = currentUrl.searchParams.get('c');
    const goToUrl = new URL(currentUrl.origin + currentUrl.pathname);

    if (parseInt(page) > -1) {
      goToUrl.searchParams.set('m', page);
      goToUrl.searchParams.set('c', showComments);
    }

    window.location.href = goToUrl.toString();
  };

  return (
    <div
      className="xmlimport-navigation-react"
      style={{ height: navHeight }}
      ref={navigationRef}
      data-bigdoc-navigation // used in js to get height of navigation for scroll offset
    >
      <div
        className={cn('xmlimport-navigation-react__inner', {
          'xmlimport-navigation-react__inner--sticky': navIsSticking,
        })}
        style={{ width: navWidth }}
        ref={navigationInnerRef}
      >
        <GreyRow
          width={GreyRowWidth.indentedWide}
          theme={GreyRowTheme.darkGreyWithLeftBorder}
          fullWidthText
        >
          <ExpandableFilterWrapperForLongDocument
            heading={innholdSokToggleLabel}
            showToTopButton={navIsSticking}
            toTopButtonText={tilToppenLabel}
            initiallyExpanded={true}
          >
            <div className="xmlimport-navigation-react__content-container">
              <div
                id={navigationId}
                className="xmlimport-navigation-react__content-container__toc-container"
              >
                {/* kun noen dokumenttyper */}
                {/* <h2>{innholdLabel}</h2> */}

                <TableOfContentDropDown
                  {...{
                    documentTitleLabel,
                    currentTitle,
                    innholdLabel,
                    menuItems,
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="document_search"
                  className={cn(
                    'xmlimport-navigation-react__content-container__search-label',
                    { 'sr-only': !documentTitleLabel } // for aligning TableOfContentDropDown with(out) label
                  )}
                >
                  {sokIDokumentetLabel}{' '}
                </label>
                <div className="xmlimport-navigation-react__search-container">
                  <button
                    className="xmlimport-navigation-react__search-container__btn-search"
                    onClick={performSearch}
                  >
                    <span className="sr-only">{sokLabel}</span>
                  </button>
                  <input
                    id="document_search"
                    className="xmlimport-navigation-react__search-container__search-input"
                    placeholder={sokIDokumentetLabel}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    aria-invalid={showErrorMessage}
                    aria-describedby="search_error_message"
                  />
                  <div
                    id="search_error_message"
                    className="xmlimport-navigation-react__search-container__error-message"
                    hidden={!showErrorMessage}
                  >
                    {showErrorMessage && sokFeilmeldingLabel}
                  </div>
                </div>
              </div>
              {thirdColumnLinks?.length > 0 && (
                <ul className="xmlimport-navigation-react__third-column-links-list">
                  {thirdColumnLinks.map((link, i) => (
                    <li key={i}>
                      <IconLinkGeneric {...link} iconString="document" />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ExpandableFilterWrapperForLongDocument>
          {showSearchNavigation && searchNavigationProps && (
            <XmlImportNavigationSearchNavigation
              {...searchNavigationProps}
              currentMatch={currentMatch}
              handlePrevClick={() => navigateToMatch(currentMatch - 1)}
              handleNextClick={() => navigateToMatch(currentMatch + 1)}
              handleAbortClick={handleAbortClick}
            />
          )}
        </GreyRow>
      </div>
    </div>
  );
};

export default XmlImportNavigation;
