import React from 'react';
import { BlockButtonTheme } from '/Models/Generated/BlockButtonTheme';
import { FindRepresentativesProps } from '/Models/Generated/FindRepresentativesProps';
import BlockButton from '/Components/Blocks/BlockButton/BlockButton';
import './FindRepresentative.scss';

const FindRepresentative = (findRepresentative: FindRepresentativesProps) => {
  return (
    <div className="find-representative-react">
      <BlockButton
        href={findRepresentative.findRepresentativeUrl}
        text={findRepresentative.findRepresantativeText}
        theme={BlockButtonTheme.ThemeGreyBackgorundWithArrow}
        isInRichTextArea={true}
        epiFullRefreshProperties={null}
      />
      <a
        className="find-representative-react__image"
        href={findRepresentative.representativePlacingUrl}
      >
        <img
          src={require('/Assets/Images/Icons/seat-location-link.svg')}
          alt=""
        />
        <span>{findRepresentative.representativePlacingText}</span>
      </a>
    </div>
  );
};

export default FindRepresentative;
