import React from 'react';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import './ReferatSporsmal.scss';
import { ReferatSporsmalProps } from '/Models/Generated/ReferatSporsmalProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import MinutesNavigationBar from '/Components/Organisms/MinutesNavigationBar/MinutesNavigationBar';
import MinutesHeader from '/Components/Organisms/MinutesHeader/MinutesHeader';
import Link from '/Components/Atoms/Link/Link';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const ReferatSporsmal = ({
  minutesNavigationBar,
  minutesHeader,
  title,
  mainBody,
  backToSporretimeLink,
  previousLink,
  nextLink,
}: ReferatSporsmalProps) => {
  return (
    <PageWrapper>
      <div className="referat-sporsmal-page-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <MinutesHeader {...minutesHeader} />
            <MinutesNavigationBar {...minutesNavigationBar} />
          </MinutesHeaderContainer>
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          className="referat-sporsmal-page-react__row-containing-content-row"
        >
          <Row horizontalPadding={HorizontalPadding.indent}>
            {backToSporretimeLink && (
              <Link
                href={backToSporretimeLink.href}
                text={backToSporretimeLink.text}
              />
            )}
            <div className="page-publication">
              <div className="bigdoc-content">
                {title && <h2>{title}</h2>}
                {mainBody && (
                  <ContentArea {...mainBody} theme={RichTextTheme.BigDoc} />
                )}
              </div>
            </div>
          </Row>
          <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
        </Row>
      </div>
    </PageWrapper>
  );
};

export default ReferatSporsmal;
