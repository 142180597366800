import React from 'react';
import './Seksjonsside.scss';
import { SeksjonssideProps } from '/Models/Generated/SeksjonssideProps';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import FlickrSlideshow from '/Components/Molecules/FlickrSlideshow/FlickrSlideshow';
import Link from '/Components/Atoms/Link/Link';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const Seksjonsside = ({
  mainIntro,
  mainBody,
  flickrSlideShow,
  oneColumnContentArea,
  fourColumnContentArea,
  threeColumnContentArea,
  twoColumnContentArea,
  showFourColumnAtBottom,
  flickrSlideShowPlacement,
}: SeksjonssideProps) => {
  const renderFlickrSlideShow = () => (
    <Row
      verticalPaddingBottom={VerticalPadding.xlarge}
      horizontalPadding={HorizontalPadding.normal}
    >
      <FlickrSlideshow {...flickrSlideShow} />
      {flickrSlideShow.flickrLinkText && (
        <Link
          href={flickrSlideShow.flickrUrl}
          text={flickrSlideShow.flickrLinkText}
        />
      )}
    </Row>
  );

  const contentAreas = [
    oneColumnContentArea,
    showFourColumnAtBottom ? null : fourColumnContentArea,
    threeColumnContentArea,
    twoColumnContentArea,
    showFourColumnAtBottom ? fourColumnContentArea : null,
  ].filter((ca) => ca !== null && HasContent(ca));

  return (
    <PageWrapper>
      <div className="seksjonsside--react">
        {flickrSlideShowPlacement === 1 && renderFlickrSlideShow()}
        {HasContent(mainIntro) && (
          <Row horizontalPadding={HorizontalPadding.indentFromRight}>
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </Row>
        )}
        {flickrSlideShowPlacement === 2 && renderFlickrSlideShow()}
        {HasContent(mainBody) && (
          <Row horizontalPadding={HorizontalPadding.indentFromRight}>
            <ContentArea {...mainBody} />
          </Row>
        )}
        {flickrSlideShowPlacement === 3 && renderFlickrSlideShow()}
        {contentAreas.length !== 0 &&
          contentAreas.map((ca, idx) => (
            <Row
              key={idx}
              verticalPaddingBottom={
                idx === contentAreas.length - 1
                  ? VerticalPadding.none
                  : VerticalPadding.xlarge
              }
              horizontalPadding={HorizontalPadding.normal}
              lineBelow={idx !== contentAreas.length - 1}
            >
              <ContentArea {...ca} />
            </Row>
          ))}
      </div>
    </PageWrapper>
  );
};

export default Seksjonsside;
