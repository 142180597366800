import React, { useState } from 'react';
import cn from 'classnames';
import './ResultSummary.scss';
import { Provider, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import LinkButton from '/Components/Atoms/LinkButton/LinkButton';

interface Props {
  summary?: string;
}

const ResultSummary = ({ summary }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const hasLongVersion = (summary ?? '').includes('<span class="last hidden">');
  const labels = useSelector((state: RootState) => state.Search.labels);
  if (!summary) return <></>;
  return (
    <p className="search-result-summary">
      <span
        className={cn({ 'short-version': !showAll })}
        dangerouslySetInnerHTML={{ __html: summary }}
      ></span>
      {hasLongVersion && showAll && (
        <LinkButton onClick={() => setShowAll(false)} text={labels.seeLess} />
      )}
      {hasLongVersion && !showAll && (
        <LinkButton onClick={() => setShowAll(true)} text={labels.seeMore} />
      )}
    </p>
  );
};

const ResultSummaryWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultSummary {...props} />
  </Provider>
);

export default ResultSummaryWrapper;
