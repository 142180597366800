import React from 'react';
import { Link } from '../../../App.Components';
import { LinkTheme } from '../../Atoms/Link/Link';
import ColumnContentWrapper from '../../Layout/ColumnContentWrapper/ColumnContentWrapper';
import './RelatedProceedings.scss';
import { RelatedProceedingsProps } from '/Models/Generated/RelatedProceedingsProps';

const RelatedProceedings = ({
  title,
  toProceedingsUrl,
  toProceedingsText,
  proceedingLinks,
}: RelatedProceedingsProps) => {
  return (
    <ColumnContentWrapper title={title}>
      {proceedingLinks &&
        proceedingLinks.map((val, idx) => (
          <div className="related-proceedings-links-react" key={idx}>
            <Link href={val.href} text={val.text} theme={LinkTheme.standard} />
          </div>
        ))}
      <div className="related-proceedings-button-link-react">
        <Link
          href={toProceedingsUrl}
          text={toProceedingsText}
          theme={LinkTheme.blocklink}
        />
      </div>
    </ColumnContentWrapper>
  );
};

export default RelatedProceedings;
