import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import './GreyRow.scss';

export enum GreyRowWidth {
  indentedWide,
  indented,
  narrowIndented,
  wide,
  normal,
}

export enum GreyRowVerticalPadding {
  none,
  normal,
  large,
}

export enum GreyRowVerticalMarginBottom {
  small = 'small',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
}

export enum GreyRowTheme {
  default,
  lightGrey,
  blue,
  darkGreyWithLeftBorder,
}

interface Props {
  children?: React.ReactNode;
  width?: GreyRowWidth;
  theme?: GreyRowTheme;
  verticalPadding?: GreyRowVerticalPadding;
  verticalMarginBottom?: GreyRowVerticalMarginBottom;
  fullWidthText?: boolean;
  className?: string;
}

const GreyRow: FunctionComponent<Props> = (props) => (
  <div
    className={cn(
      'grey-row-react',
      {
        'grey-row-react--narrow-indented':
          props.width === GreyRowWidth.narrowIndented,
        'grey-row-react--normal': props.width === GreyRowWidth.normal,
        'grey-row-react--indented-wide':
          props.width === GreyRowWidth.indentedWide,
        'grey-row-react--wide': props.width === GreyRowWidth.wide,
        'grey-row-react--theme-light-grey':
          props.theme === GreyRowTheme.lightGrey,
        'grey-row-react--theme-blue': props.theme === GreyRowTheme.blue,
        'grey-row-react--theme-dark-grey-left-border':
          props.theme === GreyRowTheme.darkGreyWithLeftBorder,
        'grey-row-react--vertical-padding-normal':
          props.verticalPadding === GreyRowVerticalPadding.normal,
        'grey-row-react--vertical-padding-large':
          props.verticalPadding === GreyRowVerticalPadding.large,
        'grey-row-react--vertical-margin-bottom-small':
          props.verticalMarginBottom === GreyRowVerticalMarginBottom.small,
        'grey-row-react--vertical-margin-bottom-normal':
          props.verticalMarginBottom === GreyRowVerticalMarginBottom.normal,
        'grey-row-react--vertical-margin-bottom-medium':
          props.verticalMarginBottom === GreyRowVerticalMarginBottom.medium,
        'grey-row-react--vertical-margin-bottom-large':
          props.verticalMarginBottom === GreyRowVerticalMarginBottom.large,
      },
      props.className
    )}
  >
    <div
      className={cn('grey-row-react__filter-container', {
        'grey-row-react__filter-container--full-width': props.fullWidthText,
      })}
    >
      {props.children}
    </div>
  </div>
);

export default GreyRow;
