import React from 'react';
import './ImageWithTextForXhtmlBlockType.scss';
import { ImageWithTextForXhtmlBlockTypeProps } from '/Models/Generated/ImageWithTextForXhtmlBlockTypeProps';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import { Alignment } from '/Models/Generated/Alignment';

const ImageWithTextForXhtmlBlockType = (
  props: ImageWithTextForXhtmlBlockTypeProps
) => (
  <ImageWithCaption
    {...props}
    maxWidthTablet={props.isInRichTextArea}
    supportImageZoom={props.isInRichTextArea}
    alignment={props.isInRichTextArea ? props.alignment : Alignment.None}
  />
);

export default ImageWithTextForXhtmlBlockType;
