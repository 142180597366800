import { SearchClientProps } from '/Models/Generated/SearchClientProps';
import { IsValidEmail } from '/Shared/Code/Validation/IsValidEmail';

export default class SearchClient {
  public static readonly sortingRelevance: string = 'relevance';
  public static readonly sortingDate: string = 'date';
  public static readonly groupPosts: string = 'G761';
  public static readonly groupQuestions: string = 'G762';
  public static readonly groupSuggestions: string = 'G763';

  constructor(private urls: SearchClientProps) {}

  public Autocomplete = (term: string, limit: number): Promise<string[]> =>
    fetch(this.urls.autoComplete + '?limit=' + limit + '&term=' + term)
      .then((res) => res?.json())
      .then((json) => json?.data?.map((i) => i.value) ?? []);

  private BuildParameter = (name: string, value?: string) =>
    value ? `${name}=${encodeURIComponent(value)}` : '';

  BuildSearchUrl = (
    query: string,
    sorting: string,
    page?: number,
    filter?: string,
    categoryId?: number
  ): string => {
    const params = [
      query ? `query=${query}` : null,
      sorting ? `sorting=${sorting}` : null,
      page ? `page=${Math.max(page, 1)}` : null,
      filter ? this.BuildParameter('filter', filter) : null,
      categoryId ? `categoryid=${categoryId}` : null,
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params ? '?' + params.join('&') : '')
    );
  };

  static IsLanguageOrGroupFilter = (filter: string): boolean => {
    const languages = Object.values(this.languageLookup);
    return (
      languages.includes(filter) ||
      [
        SearchClient.groupPosts,
        SearchClient.groupQuestions,
        SearchClient.groupSuggestions,
      ].includes(filter)
    );
  };

  static RemoveGeneralFilters = (filters: string): string =>
    (filters?.split('+') ?? []).filter(this.IsLanguageOrGroupFilter).join('+');

  private static languageLookup = {
    no: 'BA1043',
    nn: 'BA1043', // BA1120 is the correct group id, but we've decided to use the same as bokmål. See Jira issue: STOR-318
    se: 'BA1045',
    pl: 'BA1048',
    en: 'BA1047',
    dk: 'BA1046',
    de: 'BA1049',
    lt: 'BA1044',
  };

  public LookupLanguage = (language?: string): string =>
    SearchClient.languageLookup[language] ?? 'BA1043';

  public HasActiveFilters = (filters: string): boolean => {
    const activeFilters = (filters?.split('+') ?? []).filter(
      (f) => !SearchClient.IsLanguageOrGroupFilter(f) && f !== ''
    );
    return activeFilters.length > 0;
  };

  public SubSearch(
    query: string,
    filter: string,
    language: string,
    replaceFrom?: string,
    replaceTo?: string
  ): Promise<object> {
    return fetch(
      this.urls.subSearch +
        '?query=' +
        encodeURI(query) +
        '&filter=' +
        filter +
        '&groups=' +
        this.LookupLanguage(language)
    )
      .then((res) => res.json())
      .then((obj) => {
        if (replaceFrom && replaceTo) {
          let jsonTmp = JSON.stringify(obj);
          jsonTmp = jsonTmp.replaceAll(replaceFrom, replaceTo);
          return JSON.parse(jsonTmp);
        }
        return obj;
      });
  }

  private AddLanguage(language: string, filter: string): string {
    const languageFilter = this.LookupLanguage(language);
    return filter.includes(languageFilter)
      ? filter
      : [filter, languageFilter]
          .filter((f) => f !== null && f !== '')
          .join('+');
  }

  public Subscribe(
    email: string,
    title: string,
    postsId?: number,
    suggestionsId?: number,
    questionsId?: number
  ): Promise<boolean> {
    if (!postsId && !suggestionsId && !questionsId)
      return Promise.resolve(false);
    if (!IsValidEmail(email)) return Promise.resolve(false);
    const sections = [
      postsId ? `${postsId}|G761` : null,
      suggestionsId ? `${suggestionsId}|G763` : null,
      questionsId ? `${suggestionsId}|G762` : null,
    ]
      .filter((s) => s !== null)
      .join(',');
    return fetch(
      `${this.urls.subscription}?email=${email}&sections[]=${sections}&title=${title}`
    )
      .then((res) => res.json())
      .then((res) => {
        const error = res?.error ?? true;
        return !error;
      })
      .catch(() => {
        return false;
      });
  }

  public Search = (
    query?: string,
    sorting?: string,
    page?: number,
    filter?: string,
    categoryId?: number,
    language?: string,
    replaceFrom?: string,
    replaceTo?: string
  ): Promise<[]> => {
    const filterWithLanguage = this.AddLanguage(language, filter);
    const params = [
      query ? `query=${query}` : null,
      sorting ? `sorting=${sorting ?? SearchClient.sortingRelevance}` : null,
      page ? `page=${Math.max(page ?? 1, 1)}` : null,
      filterWithLanguage ? `filters=${filterWithLanguage}` : null,
      categoryId ? `categoryId=${categoryId}` : null,
      language ? `language=${language}` : null,
    ]
      .filter((p) => p !== null)
      .join('&');
    const url = this.urls.search + (params ? '?' + params : '');
    return fetch(url)
      .then((res) => res.json())
      .then((obj) => {
        if (replaceFrom && replaceTo) {
          let jsonTmp = JSON.stringify(obj);
          jsonTmp = jsonTmp.replaceAll(replaceFrom, replaceTo);
          return JSON.parse(jsonTmp);
        }
        return obj;
      });
  };

  public NavigateSearch(query: string) {
    window.location.href = this.urls.searchPageUrl + query;
  }

  public SearchCustomerCode = (
    filter?: string,
    customerCode?: string,
    language?: string,
    replaceFrom?: string,
    replaceTo?: string
  ): Promise<object> => {
    const filterWithLanguage = this.AddLanguage(language, filter);
    const params = [
      filterWithLanguage ? `filters=${filterWithLanguage}` : null,
      customerCode ? `customercode=${customerCode}` : null,
    ]
      .filter((p) => p !== null)
      .join('&');
    const url = this.urls.customerCodeSearch + (params ? '?' + params : '');
    return fetch(url)
      .then((res) => res.json())
      .then((obj) => {
        if (replaceFrom && replaceTo) {
          let jsonTmp = JSON.stringify(obj);
          jsonTmp = jsonTmp.replaceAll(replaceFrom, replaceTo);
          return JSON.parse(jsonTmp);
        }
        return obj;
      });
  };
}
