import React, { useEffect, useState } from 'react';
import { PartyItemProps } from '/Models/Generated/PartyItemProps';
import './PartyPagePartyOverviewBlockType.scss';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import SectionWrapper from '/Components/Layout/SectionWrapper/SectionWrapper';
import { PartyPagePartyOverviewBlockTypeProps } from '/Models/Generated/PartyPagePartyOverviewBlockTypeProps';
import { PartyProps } from '/Models/Generated/PartyProps';
import PartyMembers from '/Components/Organisms/PartyMembers/PartyMembers';
import SSQQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { EventNames } from '/Shared/Code/PushEvent/PushEvent';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';

enum RepType {
  Storting,
  Odelsting,
  Lagting,
}

const GetRepCount = (party: PartyProps, repType: RepType): number =>
  repType === RepType.Storting
    ? party.numberOfStortingMembers
    : repType === RepType.Odelsting
    ? party.numberOfOdelstingMembers
    : repType === RepType.Lagting
    ? party.numberOfLagtingMembers
    : 0;

const AsPartyItemProps = (
  party: PartyProps,
  reptype: RepType
): PartyItemProps => ({
  url: party.url,
  name: party.name,
  membersCount: GetRepCount(party, reptype),
});

const PartyOverviewBlockType = ({
  title,
  titlePropertyName,
  isInRichTextArea,
  parties,
  allMembersOfStortingetSuffix,
  stortingButtonText,
  odelstingButtonText,
  lagtingButtonText,
  blockId,
  endpoint,
  parliamentPeriod,
  language,
}: PartyPagePartyOverviewBlockTypeProps) => {
  const [periodIdState, setPeriodIdState] = useState(parliamentPeriod);
  const [partiesState, setPartiesState] = useState(parties);
  const [titleState, setTitleState] = useState(title);
  const [initialized, setInitialized] = useState(false);

  const showButtons = (parties) =>
    parties?.filter(
      (p) => p.numberOfLagtingMembers || p.numberOfOdelstingMembers
    ).length > 0;

  const [repType, setRepType] = useState(RepType.Storting);
  const members = partiesState?.map((p) => AsPartyItemProps(p, repType));
  const allMembersOfStortingetCount = members.reduce(
    (acc, curr) => acc + curr.membersCount,
    0
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updatePeriodId = () => {
        const pid = SSQQueryStringReader.Read('pid');
        const newPid = pid ? pid : parliamentPeriod;
        setPeriodIdState(newPid);
      };
      const events = [EventNames.pushstate, 'popstate'];
      events.forEach((e) => window.addEventListener(e, updatePeriodId));
      return () =>
        events.forEach((e) => window.removeEventListener(e, updatePeriodId));
    }
  }, []);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    fetch(
      endpoint +
        '?blockId=' +
        blockId +
        '&parliamentPeriod=' +
        periodIdState +
        '&language=' +
        language
    )
      .then((res) => res.json())
      .then((res) => {
        setTitleState(res.title);
        setPartiesState(res.parties);
        if (!showButtons(res.parties)) setRepType(RepType.Storting);
      });
  }, [periodIdState]);

  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <div className="party-page-party-overview-block-type-react">
        <SectionWrapper
          title={titleState}
          titlePropertyName={titlePropertyName}
        >
          {showButtons(partiesState) && (
            <div className="button-bar-react">
              <TabButtonStrip>
                <TabButton
                  text={stortingButtonText}
                  onClick={() => setRepType(RepType.Storting)}
                  selected={repType === RepType.Storting}
                />
                <TabButton
                  text={odelstingButtonText}
                  onClick={() => setRepType(RepType.Odelsting)}
                  selected={repType === RepType.Odelsting}
                />
                <TabButton
                  text={lagtingButtonText}
                  onClick={() => setRepType(RepType.Lagting)}
                  selected={repType === RepType.Lagting}
                />
              </TabButtonStrip>
            </div>
          )}
          {partiesState && (
            <PartyMembers
              {...{
                members,
                allMembersOfStortingetCount,
                allMembersOfStortingetSuffix,
              }}
            />
          )}
        </SectionWrapper>
      </div>
    </BlockWrapper>
  );
};

export default PartyOverviewBlockType;
