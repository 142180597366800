import React, { useEffect, useState } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './ListesideAntattEOSrelevanterettsakter.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { ListesideAntattEOSrelevanterettsakterProps } from '/Models/Generated/ListesideAntattEOSrelevanterettsakterProps';
import Link from '/Components/Atoms/Link/Link';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import cn from 'classnames';
import ListesideAntattEOSrelevanterettsakterFilter from './ListesideAntattEOSrelevanterettsakterFilter';
import AntattEOSRelevanteRettsakterClient from '/Shared/Code/AntattEOSRelevanteRettsakter/AntattEOSRelevanteRettsakterClient';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const primaryFilterId = 'primaryfilter';

const ListesideAntattEOSrelevanterettsakter = ({
  mainIntro,
  mainBody,
  eosRelatedActsList,
  eosRelatedActDropDownContainer,
  threeColumnContentArea,
  threeColumnContentAreaInTop,
  threeColumnContentAreaBottom,
  apiEndpoint,
  pageId,
}: ListesideAntattEOSrelevanterettsakterProps) => {
  const [sortBy, setSortBy] = useState(
    AntattEOSRelevanteRettsakterClient.LookupSortBy(eosRelatedActsList.sortBy)
  );
  const [sortAscending, setSortAscending] = useState(
    eosRelatedActsList.sortAscending
  );
  const [items, setItems] = useState(eosRelatedActsList.tableItems);

  const sortHeaders = [
    { text: eosRelatedActsList.tableHeadingDateText, sortKey: 'startPublish' },
    { text: eosRelatedActsList.tableHeadingTitleText, sortKey: 'link' },
    { text: eosRelatedActsList.tableHeadingKomText, sortKey: 'komNumbers' },
  ];

  const setSort = (key) => {
    // Old sorting alternated between ascending/descending regardless of which
    // category was clicked. The customer wanted to keep this behavior.
    setSortBy(key);
    setSortAscending((current) => !current);

    // Use this instead if you want to start descending on new category.
    // if (sortBy !== key) {
    //   setSortBy(key);
    //   setSortAscending(false);
    // } else setSortAscending((current) => !current);
  };

  const sortedRows = (items) => {
    const rows = [...items];
    if (!Array.isArray(rows)) return [];
    if (!sortBy) return rows;

    rows.sort((a, b) => {
      let aa = a[sortBy].text || a[sortBy];
      let bb = b[sortBy].text || b[sortBy];

      // if sort by date (dd.mm.yyyy), change to 'sortable date' yyyymmdd
      if (sortBy === 'startPublish') {
        aa = aa.split('.').reverse().join('');
        bb = bb.split('.').reverse().join('');
      }

      if (aa < bb) {
        return sortAscending ? -1 : 1;
      }
      if (aa > bb) {
        return sortAscending ? 1 : -1;
      }
      return 0;
    });

    return rows;
  };

  const selectedYear =
    eosRelatedActDropDownContainer.childPageDropDown.pageLink.filter(
      (pl) => pl.isSelected
    );

  const [year, setYear] = useState(
    selectedYear.length > 0
      ? selectedYear[0].value
      : eosRelatedActDropDownContainer.childPageDropDown.pageLink[0].value
  );

  const selectedMonth =
    eosRelatedActDropDownContainer.monthDropDown.items.filter(
      (m) => m.isSelected
    );

  const [month, setMonth] = useState(
    selectedMonth.length > 0
      ? selectedMonth[0].value
      : eosRelatedActDropDownContainer.monthDropDown.items[0].value
  );

  const [komNumber, setKomNumber] = useState(
    eosRelatedActDropDownContainer.komNumberTextBox.text
  );

  const [loading, setLoading] = useState(false);

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!loading) return;
    const client = new AntattEOSRelevanteRettsakterClient(apiEndpoint, pageId);
    client.BuildUrl(+year, +month, komNumber, sortBy, sortAscending);
    client.Get(+year, +month, komNumber).then((res) => {
      setItems(res);
      setLoading(false);
      if (!reload)
        window.history.pushState(
          {},
          '',
          client.BuildUrl(+year, +month, komNumber, sortBy, sortAscending)
        );
      setReload(false);
      scrollToTarget(primaryFilterId);
    });
  }, [loading]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onpopstate = () => {
        setReload(true);
      };
    }
  });

  useEffect(() => {
    if (!reload) return;
    const fallbackYear =
      +eosRelatedActDropDownContainer.childPageDropDown.pageLink[0].value;
    setYear(
      AntattEOSRelevanteRettsakterClient.GetYearFromQuery(fallbackYear) + ''
    );
    const fallbackMonth =
      eosRelatedActDropDownContainer.monthDropDown.items[0].value;
    setMonth(
      AntattEOSRelevanteRettsakterClient.GetMonthFromQuery() ?? fallbackMonth
    );
    setKomNumber(AntattEOSRelevanteRettsakterClient.GetKomNumberFromQuery());
    setSortBy(
      AntattEOSRelevanteRettsakterClient.LookupSortBy(
        AntattEOSRelevanteRettsakterClient.GetSortByFromQuery()
      )
    );
    setSortAscending(
      AntattEOSRelevanteRettsakterClient.GetSortAscendingFromQuery()
    );

    setLoading(true);
  }, [reload]);

  return (
    <div className="listeside-antatt-eos-relevante-rettsakter-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <div className="listeside-antatt-eos-relevante-rettsakter-react__main-intro">
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        </div>
      </Row>
      {(HasContent(threeColumnContentAreaInTop) ||
        threeColumnContentAreaInTop.propertyName) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
          verticalPaddingTop={VerticalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaInTop} />
        </Row>
      )}
      {(HasContent(threeColumnContentAreaBottom) ||
        threeColumnContentAreaBottom.propertyName) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingBottom={VerticalPadding.large}
          verticalPaddingTop={VerticalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaBottom} />
        </Row>
      )}
      <Row horizontalPadding={HorizontalPadding.indent}>
        <ContentArea {...mainBody} />
      </Row>
      <Row verticalPaddingBottom={VerticalPadding.large}>
        <ListesideAntattEOSrelevanterettsakterFilter
          {...eosRelatedActDropDownContainer}
          filterId={primaryFilterId}
          selectedMonth={month}
          setSelectedMonth={setMonth}
          selectedYear={year}
          setSelectedYear={setYear}
          komNumberText={komNumber}
          setKomNumberText={setKomNumber}
          loading={loading}
          submit={() => setLoading(true)}
        />
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {eosRelatedActsList ? (
          <>
            <table className="listeside-antatt-eos-relevante-rettsakter-react__table">
              <caption hidden>{eosRelatedActsList.tableCaption}</caption>
              <tbody>
                <tr>
                  {sortHeaders.map((el, index) => (
                    <th
                      key={index}
                      aria-sort={
                        sortBy === el.sortKey
                          ? sortAscending
                            ? 'ascending'
                            : 'descending'
                          : null
                      }
                    >
                      <button
                        onClick={() => setSort(el.sortKey)}
                        className={cn(
                          { 'active-react': sortBy === el.sortKey },
                          { 'is-sort-ascending-react': sortAscending }
                        )}
                      >
                        {el.text}
                      </button>
                    </th>
                  ))}
                </tr>
                {items &&
                  sortedRows(items).map((val, idx) => (
                    <tr key={idx}>
                      <td>{val.startPublish}</td>
                      <td style={{ width: '100%' }}>
                        <Link {...val.link} />
                      </td>
                      <td>
                        {val.komNumbers &&
                          val.komNumbers.map((kn, knidx) => (
                            <div key={knidx}>
                              <Link {...kn} />
                              <br />
                            </div>
                          ))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        ) : (
          <>
            {eosRelatedActsList.noResultsErrorMessage && (
              <span>{eosRelatedActsList.noResultsErrorMessage}</span>
            )}
          </>
        )}
        <ContentArea {...threeColumnContentArea} />
      </Row>
    </div>
  );
};

export default ListesideAntattEOSrelevanterettsakter;
