import React, { useState } from 'react';
import './TopMenuMobile.scss';
import { TopMenuProps } from '/Models/Generated/TopMenuProps';
import TopMenuMobileToggler from './TopMenuMobileToggler';
import cn from 'classnames';
import TopMenuMobileLinks from './TopMenuMobileLinks';
import TopMenuGlobalSearchSection from './TopMenuGlobalSearchSection';
import TopMenuSearchSectionContent from './TopMenuSearchSectionContent';
const noOpenSection = -1;
const searchSection = -2;
const menuSection = -3;

const TopMenuMobile = (props: TopMenuProps) => {
  const [openSection, setOpenSection] = useState(noOpenSection);

  const toggleOpenSection = (id: number) => {
    const shouldClose = openSection === id;
    setOpenSection(shouldClose ? noOpenSection : id);
    document.body.style.maxHeight = shouldClose ? '' : '100vh';
    document.body.style.overflow = shouldClose ? '' : 'hidden';
  };

  const topMenuMobileMainId = 'top-menu-mobile-main';
  const topMenuMobileSearchId = 'top-menu-mobile-search';

  if (props)
    return (
      <div className="top-menu-mobile-react">
        <div
          className={cn('top-menu-mobile-react-container', {
            'is-open-react': openSection !== noOpenSection,
          })}
        >
          <div className="top-menu-mobile-react-bar">
            <div className="logo-react">
              <a href={props.logoUrl}>
                {props.isGriefMode ? (
                  <img
                    src={require('/Assets/Images/logo-black.svg')}
                    alt={props.logoAltText}
                  />
                ) : (
                  <img
                    src={require('/Assets/Images/logo.svg')}
                    alt={props.logoAltText}
                  />
                )}
              </a>
            </div>
            <div className="top-menu-mobile-react-buttons">
              <TopMenuMobileToggler
                {...props.search}
                openLabel={props.search.buttonLabel}
                closeLabel={props.closeMenuLabel}
                isOpen={openSection === searchSection}
                toggle={() => toggleOpenSection(searchSection)}
                ariaControls={topMenuMobileSearchId}
              />
              <TopMenuMobileToggler
                {...props.search}
                openLabel={props.openMenuLabel}
                closeLabel={props.closeMenuLabel}
                isOpen={openSection === menuSection}
                toggle={() => toggleOpenSection(menuSection)}
                ariaControls={topMenuMobileMainId}
              />
            </div>
          </div>
          <div
            className={cn('top-menu-mobile-react-content', {
              'is-open-react': openSection !== noOpenSection,
            })}
          >
            <div className="overlay-react">
              {props.search.featureToggleNewSearch ? (
                <TopMenuGlobalSearchSection
                  {...props.search}
                  toggleSearch={() => toggleOpenSection(-1)}
                  isOpen={openSection === searchSection}
                  containerId={topMenuMobileSearchId}
                />
              ) : (
                <TopMenuSearchSectionContent
                  {...props.search}
                  toggleSearch={() => toggleOpenSection(-1)}
                  isOpen={openSection === searchSection}
                  containerId={topMenuMobileSearchId}
                />
              )}
              {openSection === menuSection && (
                <TopMenuMobileLinks
                  {...props}
                  containerId={topMenuMobileMainId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  else return <></>;
};

export default TopMenuMobile;
