import React, { useEffect, useState } from 'react';
import './LiveMeetingInformationBlockTypeTopImageBoxContent.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import mixins from './mixins';
import lang from './lang';
import classnames from 'classnames';

interface Props {
  isActive?: boolean;
  isCanceled?: boolean;
  isPostponed?: boolean;
}

const LiveMeetingInformationBlockTypeTopImageBoxContent = ({
  isActive,
  isCanceled,
  isPostponed,
}: Props) => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);
  const imageAlt = cmsData.mainBlock.imageAltText;
  const [imageSrc, setImageSrc] = useState('');

  const time = () => isActive && mixins().isPreLive(cmsData, liveData);
  const live = () =>
    isActive &&
    mixins().isVotingOrActiveWithActiveProceedingOrActiveHearing(
      cmsData,
      liveData
    );
  const rec = () => isActive && false;
  const timeValue = () =>
    `${lang.clock[cmsData.lang]} ${mixins().meetingTime(cmsData, liveData)}`;

  useEffect(() => {
    if (live()) {
      if (!!cmsData.mainBlock.imageSnapshot)
        fetch(cmsData.mainBlock.imageSnapshot)
          .then((res) => {
            if (res.status === 200)
              setImageSrc(cmsData.mainBlock.imageSnapshot);
            else setImageSrc(cmsData.mainBlock.fallback);
          })
          .catch(() => setImageSrc(cmsData.mainBlock.fallback));
      else setImageSrc(cmsData.mainBlock.fallback);
    } else setImageSrc(cmsData.mainBlock.image);
  }, []);

  return (
    <div className="live-meeting-information-block-type-top-image-box-content-react">
      {isCanceled && (
        <div
          className={classnames(
            'live-meeting-information-block-type-top-image-box-content-react--meta',
            'live-meeting-information-block-type-top-image-box-content-react--meta-rec',
            'live-meeting-information-block-type-top-image-box-content-react--meta-active'
          )}
        >
          Avlyst!
        </div>
      )}
      {isPostponed && (
        <div
          className={classnames(
            'live-meeting-information-block-type-top-image-box-content-react--meta',
            'live-meeting-information-block-type-top-image-box-content-react--meta-rec',
            'live-meeting-information-block-type-top-image-box-content-react--meta-active'
          )}
        >
          Utsatt!
        </div>
      )}
      {time() && (
        <div
          className={classnames(
            'live-meeting-information-block-type-top-image-box-content-react--meta',
            'live-meeting-information-block-type-top-image-box-content-react--meta-time',
            'live-meeting-information-block-type-top-image-box-content-react--meta-active'
          )}
        >
          {timeValue()}
        </div>
      )}
      {live() && (
        <div
          className={classnames(
            'live-meeting-information-block-type-top-image-box-content-react--meta',
            'live-meeting-information-block-type-top-image-box-content-react--meta-live',
            'live-meeting-information-block-type-top-image-box-content-react--meta-active'
          )}
        >
          Direkte
        </div>
      )}
      {rec() && (
        <div
          className={classnames(
            'live-meeting-information-block-type-top-image-box-content-react--meta',
            'live-meeting-information-block-type-top-image-box-content-react--meta-rec',
            'live-meeting-information-block-type-top-image-box-content-react--meta-active'
          )}
        >
          Avsluttet
        </div>
      )}
      <img src={imageSrc} alt={imageAlt} title={imageAlt} />
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopImageBoxContentWrapper = (
  props: Props
) => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopImageBoxContent {...props} />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopImageBoxContentWrapper;
