import React from 'react';
import './Link.scss';
import { LinkProps } from '/Models/Generated/LinkProps';
import cn from 'classnames';

export enum LinkTheme {
  none,
  standard,
  serif,
  sans,
  blocklink,
  dark,
  langAttribute,
  darkSerif,
}

interface Props extends LinkProps {
  theme?: LinkTheme;
  className?: string;
  whiteFocusOutline?: boolean;
  title?: string;
  blankTarget?: boolean;
  ariaLabel?: string;
}

const Link = ({
  href,
  text,
  theme,
  className,
  whiteFocusOutline,
  title,
  blankTarget,
  langAttribute,
  ariaLabel,
}: Props) => {
  const setBlankTarget = blankTarget
    ? { target: '_blank', rel: 'noreferrer' }
    : {};
  if (href && text)
    return (
      <a
        aria-label={ariaLabel}
        href={href}
        className={cn(
          'link-react',
          { 'link-react__standard': theme === LinkTheme.standard },
          { 'link-react__serif': theme === LinkTheme.serif },
          { 'link-react__sans': theme === LinkTheme.sans },
          { 'link-react__block': theme === LinkTheme.blocklink },
          { 'link-react__dark': theme === LinkTheme.dark },
          { 'link-react__dark-serif': theme === LinkTheme.darkSerif },
          { 'link-react__white-focus-outline': whiteFocusOutline },
          className
        )}
        title={title}
        lang={langAttribute || null}
        {...setBlankTarget}
      >
        {text}
      </a>
    );
  else return <></>;
};

export default Link;
