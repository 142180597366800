const UseProdUrls = (props) =>
  JSON.parse(
    JSON.stringify(props)
      .replaceAll(/"http:\/\/dev.stortinget.no/g, '"https://www.stortinget.no')
      .replaceAll(/"\/no\//g, '"https://www.stortinget.no/no/')
      .replaceAll(/"\/nn\//g, '"https://www.stortinget.no/nn/')
      .replaceAll(/"\/en\//g, '"https://www.stortinget.no/en/')
      .replaceAll(/"\/link\//g, '"https://www.stortinget.no/link/')
      .replaceAll(/"\/api\//g, '"https://www.stortinget.no/api/')
      .replaceAll(
        /"\/Personimages\//g,
        '"https://www.stortinget.no/Personimages/'
      )
      .replaceAll(
        /"\/contentassets\//g,
        '"https://www.stortinget.no/contentassets/'
      )
      .replaceAll(
        /"\/globalassets\//g,
        '"https://www.stortinget.no/globalassets/'
      )
      .replaceAll(
        /"\/Login\/CMS\/Content\/globalassets\/no/g,
        '"https://www.stortinget.no/globalassets'
      )
      .replaceAll(
        /"\/login\/CMS\/Content\/globalassets\/no/g,
        '"https://www.stortinget.no/globalassets'
      )
      .replaceAll(
        /"\/login\/CMS\/Content\/contentassets\/no/g,
        '"https://www.stortinget.no/contentassets'
      )
      .replaceAll(/,,\d*\?epieditmode[^"]*/g, '')
  );

export default UseProdUrls;
