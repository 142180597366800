/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import './FileInput.scss';
import { ExtendedFormInputProps } from '/Models/FrontendOnly/FormInputs/ExtendedFormInputProps';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';
import Button, { ButtonTheme } from '/Components/Atoms/Button/Button';

const FileInput = (props: ExtendedFormInputProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const onChange = (e) => {
    const files = e.target?.files;
    const errorMessages = GetFormInputErrorMessages({
      ...props,
      value: inputEl.current.value,
      files,
    });

    if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
    else props.setErrorMessage('');
    typeof props.onChange === 'function' && props.onChange(e); // call props.onChange if provided
  };

  const onClick = (e) => {
    e.preventDefault();
    inputEl.current.click();
  };

  const labelText =
    inputEl.current?.files && inputEl.current?.files.length
      ? inputEl.current?.files[0]?.name
      : props.fileInputLeadText;

  return (
    <div className="file-input-react">
      <input
        type="file"
        className="file-input-react__file-input"
        ref={inputEl}
        id={props.id}
        name={props.name}
        defaultValue={props.defaultValue}
        accept={props.acceptedFileTypes?.join(', ')}
        disabled={props.disabled}
        onChange={onChange}
      />
      <div className="file-input-react__fake-input">
        <div className="file-input-react__fake-input__label-text">
          {labelText}
        </div>
        <Button
          text={props.selectFileButtonText}
          theme={ButtonTheme.Blue}
          disabled={props.disabled}
          tabIndex={-1}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default FileInput;
