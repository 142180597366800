import React from 'react';
import { HierarchyNodeProps } from '/Models/Generated/HierarchyNodeProps';
import { TopMenuProps } from '/Models/Generated/TopMenuProps';
import { TopMenuSectionProps } from '/Models/Generated/TopMenuSectionProps';
import PageActions from '/Components/Layout/PageActions/PageActions';
import TopMenuMobileLink from './TopMenuMobileLink';
import './TopMenuMobileLinks.scss';

interface Props extends TopMenuProps {
  containerId: string;
}

const TopMenuMobileLinks = (props: Props) => {
  const flattenLinks = (section: TopMenuSectionProps): HierarchyNodeProps => {
    const top = { text: section.title } as HierarchyNodeProps;
    top.childNodes = (section.mainLink ? [section.mainLink] : [])
      .concat(section.col1 ?? [])
      .concat(section.col2 ?? [])
      .concat(section.col3 ?? [])
      .concat(section.col4 ?? [])
      .concat(section.col5 ?? []);
    return top;
  };

  if (props)
    return (
      <div id={props.containerId} className="top-menu-mobile-links-react">
        {props.languageNodes && (
          <TopMenuMobileLink
            {...props.languageNodes}
            isTopLevel={true}
            ariaControls="langMenu"
          />
        )}
        {props.sectionsContainer?.sections &&
          props.sectionsContainer?.sections.map((section, idx) => (
            <TopMenuMobileLink
              {...flattenLinks(section)}
              key={idx}
              isTopLevel={true}
              ariaControls={'subMenu' + idx}
            />
          ))}
        <div className="page-actions-wrapper">
          {props.pageActions && <PageActions {...props.pageActions} />}
        </div>
      </div>
    );
  else return <></>;
};

export default TopMenuMobileLinks;
