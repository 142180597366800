// TODO: Move out of folder if it is to be used elsewhere
import React from 'react';
import './RepresentativesListing.scss';
import { CountyRepresentativeProps } from '/Models/Generated/CountyRepresentativeProps';
import Link from '/Components/Atoms/Link/Link';

interface RepresentativesListingProps {
  representatives: CountyRepresentativeProps[];
}

const RepresentativesListing = ({
  representatives,
}: RepresentativesListingProps) => {
  return (
    <ul className="representatives-listing-react">
      {representatives.map((item, itemId) => (
        <li className="representatives-listing-react__item" key={itemId}>
          <img
            src={item.smallImageUrl}
            alt={item.representativePageLink?.text}
          />
          <div>
            <Link {...item.representativePageLink} />{' '}
            <span>({item.partyId})</span>
            <p>
              {[
                item.countyRepRoleText,
                item.countyRepNumber,
                item.prepFor,
                item.countyName,
                item.parlimentPeriod,
              ].join(' ')}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};
export default RepresentativesListing;
