import { EPOSSakAvgivelsesfristerApiProps } from '/Models/Generated/EPOSSakAvgivelsesfristerApiProps';
import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { EPOSSakAvgivelsesfristerAsyncDataProps } from '/Models/Generated/EPOSSakAvgivelsesfristerAsyncDataProps';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default class EPOSSakAvgivelsesfristerClient {
  constructor(private endpoint: string) {}

  public Get(
    /** Week of year */
    yw: string,
    /** Committee id */
    coid: string
  ): Promise<EPOSSakAvgivelsesfristerApiProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(yw, coid).then((res) => UseProdUrls(res));
    } else {
      return fetch(this.endpoint + `?yw=${yw}&coid=${coid}`, {
        headers: { Accept: 'application/json' },
      }).then((res) => res.json());
    }
  }

  BuildFilterUrl = (
    yw: string,
    coid: string,
    sb: string,
    sd: string
  ): string => {
    const params = [
      yw ? `yw=${yw}` : null,
      coid ? `coid=${coid}` : null,
      sb ? `sb=${sb}` : null,
      sd ? `sd=${sd}` : null,
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private getMockData(
    yw: string,
    coid: string
  ): Promise<EPOSSakAvgivelsesfristerAsyncDataProps> {
    return fetch(
      '/MockupAssets/MockData/EPOSSakAvgivelsesfristerAsyncData.json'
    )
      .then((res) => res.json())
      .then((result: EPOSSakAvgivelsesfristerAsyncDataProps) => {
        if (yw && yw.includes('W')) {
          const yearWeek = yw.split('W');
          const filteredRows = result.tableData.rows.filter(
            (row) =>
              row.cells[0].value.includes(yearWeek[0]) &&
              row.cells[1].component?.link.text.includes(yearWeek[1])
          );
          result.tableData.rows = filteredRows;
        }
        /*
        // coid not included in data
        if (coid) {
          const filteredRows = result.tableData.rows.filter(
            (row) =>

              row.cells[1].component?.link.text.includes(coid)
          );
          result.tableData.rows = filteredRows;
        } */

        return result;
      });
  }
}
