import React from 'react';
import PersonCard from '../PersonCard/PersonCard';
import GenericCard from '../GenericCard/GenericCard';
import { GlobalSearchResultProps } from '/Models/Generated/GlobalSearchResultProps';
import InformationCard from '../InformationCard/InformationCard';
interface Props extends GlobalSearchResultProps {
  showMoreLabelMobile: string;
  showLessLabelMobile: string;
  recommendedHitLabel: string;
  expandRelatedLinksButtonText: string;
  collapseRelatedLinksButtonText: string;
  showHitScore: boolean;
}

const CardResults = ({
  hits,
  showMoreLabelMobile,
  showLessLabelMobile,
  expandRelatedLinksButtonText,
  collapseRelatedLinksButtonText,
  recommendedHitLabel,
  showHitScore,
}: Props) => {
  const renderCard = (card, idx) => {
    const useMobileMenu = card.cardRelatedLinks?.linkLists
      ? true
      : card.cardRelatedTopicLinks?.linkLists[0].metadataLinks.length > 3;

    switch (card.hitType) {
      case 'generic':
        return (
          <GenericCard
            key={idx}
            {...card}
            showHitScore={showHitScore}
            useMobileMenu={useMobileMenu}
            showMoreLabelMobile={showMoreLabelMobile}
            showLessLabelMobile={showLessLabelMobile}
            recommendedHitLabel={recommendedHitLabel}
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        );
      case 'person':
        return (
          <PersonCard
            key={idx}
            {...card}
            showHitScore={showHitScore}
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        );
      case 'ordbok':
        return (
          <InformationCard showHitScore={showHitScore} key={idx} {...card} />
        );
      default:
        return (
          <GenericCard
            key={idx}
            {...card}
            showHitScore={showHitScore}
            useMobileMenu={useMobileMenu}
            showMoreLabelMobile={showMoreLabelMobile}
            showLessLabelMobile={showLessLabelMobile}
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        );
    }
  };

  if (hits) {
    return <>{hits.map((card, idx) => renderCard(card, idx))}</>;
  }
};

export default CardResults;
