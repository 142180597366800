import React, { useEffect } from 'react';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import EuEosContactText from '/Components/Organisms/EuEosContactText/EuEosContactText';
import './VanligNettside.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { VanligNettsideProps } from '/Models/Generated/VanligNettsideProps';
import PageCategories from '/Components/Organisms/PageCategories/PageCategories';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import PageInfo from '/Components/Organisms/PageInfo/PageInfo';
import ParentPageLink from '/Components/Organisms/ParentPageLink/ParentPageLink';
import ContentTargetGroup from '/Components/Organisms/ContentTargetGroup/ContentTargetGroup';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import MainImage from '/Components/Organisms/MainImage/MainImage';
import classNames from 'classnames';

const VanligNettside = ({
  mainImage,
  pageTitle,
  pageTitlePropertyName,
  parentPageLink,
  pageCategories,
  emailConfirmationHtml,
  mainIntro,
  threeColumnContentAreaInTop,
  mainImageOld,
  mainBody,
  newsletterContactInformationHeader,
  newsletterContactInformation,
  euEosContactText,
  threeColumnContentArea,
  threeColumnContentAreaBottom,
  pageInfo,
  contentTargetGroup,
}: VanligNettsideProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);
  return (
    <PageWrapper>
      <div className="vanlig-nettside-react">
        {contentTargetGroup && (
          <Row horizontalPadding={HorizontalPadding.normal}>
            <ContentTargetGroup {...contentTargetGroup} />
          </Row>
        )}
        {mainImage && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            noPaddingOnMobile={true}
          >
            <MainImage {...mainImage} />
          </Row>
        )}
        <Row horizontalPadding={HorizontalPadding.indent}>
          <h1
            data-epi-property-name={pageTitlePropertyName}
            className={classNames({
              'epi-editContainer': pageTitlePropertyName,
            })}
          >
            {pageTitle}
          </h1>
          {parentPageLink && <ParentPageLink {...parentPageLink} />}
          {pageCategories && <PageCategories {...pageCategories} />}
          <div className="vanlig-nettside-react__main-intro">
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </div>
        </Row>
        {HasContent(threeColumnContentAreaInTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
            verticalPaddingTop={VerticalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaInTop} />
          </Row>
        )}
        <Row
          className="vanlig-nettside-react__last-content-row"
          horizontalPadding={HorizontalPadding.indent}
        >
          {mainImageOld && (
            <ImageWithCaption {...mainImageOld} maxWidthTablet={true} />
          )}
          {mainBody && <ContentArea {...mainBody} />}
          <ContentArea {...emailConfirmationHtml} />
          {HasContent(newsletterContactInformation) && (
            <>
              <h2>{newsletterContactInformationHeader}</h2>
              <ContentArea {...newsletterContactInformation} />
            </>
          )}
          {euEosContactText && <EuEosContactText {...euEosContactText} />}
          {pageInfo && (
            <div className="vanlig-nettside-react__page-info-wrapper">
              <PageInfo {...pageInfo} />
            </div>
          )}
        </Row>
        {HasContent(threeColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentArea} />
          </Row>
        )}
        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea {...threeColumnContentAreaBottom} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default VanligNettside;
