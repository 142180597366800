import React from 'react';
import './PartyMembers.scss';
import { PartyItemProps } from '/Models/Generated/PartyItemProps';

interface PartyMembersProps {
  members: PartyItemProps[];
  allMembersOfStortingetCount: number;
  allMembersOfStortingetSuffix: string;
}

const PartyOverviewBlockType = ({
  members,
  allMembersOfStortingetCount,
  allMembersOfStortingetSuffix,
}: PartyMembersProps) => {
  return (
    <div className="party-members-react">
      {members && (
        <ul>
          {members.map((val, idx) => (
            <li key={idx}>
              <a href={val.url} className="party-members-react__split-line">
                <div className="party-members-react__split-line__left">
                  <div className="party-members-react__split-line__left__left">
                    <span>{val.name}</span>
                  </div>
                  <span className="party-members-react__split-line__left__right">
                    {val.membersCount}
                  </span>
                </div>
                <div className="party-members-react__split-line__right">
                  <div className="bar-wrapper">
                    <div
                      className="bar"
                      style={{
                        width:
                          100 *
                            (val.membersCount /
                              Math.max(...members.map((x) => x.membersCount))) +
                          '%',
                      }}
                    ></div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      )}
      <div className="party-members-react__split-line">
        <div className="party-members-react__split-line__left">
          <span className="party-members-react__split-line__left__full">
            {allMembersOfStortingetCount}
            <span className="party-members-react__split-line__left__full__mobile-only">
              {' ' + allMembersOfStortingetSuffix}
            </span>
          </span>
        </div>
        <span className="party-members-react__split-line__right">
          {allMembersOfStortingetSuffix}
        </span>
      </div>
    </div>
  );
};

export default PartyOverviewBlockType;
