export class EmailValidator {
  static badTlds = ['test', 'example', 'invalid', 'localhost'];
  static reservedDomains = ['example.com', 'example.net', 'example.org'];

  static IsValidEmail(email: string) {
    if (!email) return false;

    const match = /^(.+)@((.+)\.(.+))$/.exec(email);

    if (!match) return false;

    const localPart = match[1];
    const domain = match[2].toLowerCase();
    const tld = match[4].toLowerCase();

    if (
      EmailValidator.badTlds.includes(tld) ||
      EmailValidator.reservedDomains.includes(domain)
    )
      return false;

    if (/\.\./.test(localPart)) return false; // no double-dot is allowed

    if (!/^[A-Za-z0-9!#$%&*+-/=?^_{|}~\.]+$/.test(localPart)) return false;

    if (localPart.length > 64) return false;
    if (domain.length > 255) return false;

    if (!/^[A-Za-z0-9\.-]+$/.test(domain)) return false;
    if (domain.startsWith('-') || domain.endsWith('-')) return false;
    if (!/^[a-z\.]+$/.test(tld)) return false;

    return true;
  }
}
