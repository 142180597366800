import React from 'react';
import './CardText.scss';
import { CardTextProps } from '/Models/Generated/CardTextProps';

const CardText = ({ text }: CardTextProps) => {
  return (
    <div
      className="card-text-react"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default CardText;
