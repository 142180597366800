import React, { useEffect, useState } from 'react';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import './CardHeader.scss';
import { CardHeaderProps } from 'Models/Generated/CardHeaderProps';

const CardHeader = ({ title, hitTypeLabel, lastUpdated }: CardHeaderProps) => {
  const [isExternal, setIsExternal] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && title.href) {
      setIsExternal(!title.href.includes(window.location.host));
    }
  }, [title]);

  return (
    <div className="card-header-react">
      {title.href ? (
        <h2>
          <Link
            className="card-header-react__title"
            {...title}
            theme={LinkTheme.dark}
            blankTarget={isExternal}
          />
        </h2>
      ) : (
        <h2 className="card-header-react__title">{title.text}</h2>
      )}
      {(hitTypeLabel || lastUpdated) && (
        <div className="card-header-react__label-wrapper">
          {hitTypeLabel && (
            <div className="card-header-react__label">{hitTypeLabel}</div>
          )}
          {lastUpdated && (
            <div className="card-header-react__label">{lastUpdated}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardHeader;
