import { DictionaryLookupProps } from '/Models/Generated/DictionaryLookupProps';

const mockResult: DictionaryLookupProps = {
  id: 128,
  description:
    'statens makt utgår fra folket. Folket (det vil si den voksne del av befolkningen, velgerne, ved lik og allmenn stemmerett) har gitt staten myndighet til å vedta lover som setter grenser for den enkelte borgers frihet. Folkesuvereniteten blir i vårt representative system utøvd av Stortinget, som dermed styrer på vegne av folket. Regjeringen kan fjernes av stortingsflertallet.',
  word: 'folkesuverenitet',
};

export default class DictionaryClient {
  private lookup = new Map<number, DictionaryLookupProps>();
  constructor(private endpoint: string) {}

  private getURLParameter = (url, name) => {
    return parseInt(
      (RegExp(name + '=' + '(.+?)(&|$)').exec(url) || [, null])[1]
    );
  };

  public Lookup = (href: string): Promise<DictionaryLookupProps> => {
    if (!href.includes('/Ordbok/') || !href.includes('wid=')) return;
    const wordId = this.getURLParameter(href, 'wid');
    if (isNaN(wordId)) return Promise.reject();
    if (this.lookup.has(wordId)) return Promise.resolve(this.lookup[wordId]);
    if (
      typeof window !== 'undefined' &&
      window.location.host === 'localhost:8080'
    ) {
      return Promise.resolve(mockResult);
    } else {
      return fetch(this.endpoint + wordId)
        .then((res) => res.json())
        .then((res) => {
          this.lookup[wordId] = res;
          return res;
        });
    }
    return Promise.reject();
  };
}
