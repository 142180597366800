import React from 'react';
import SocialSharing from '/Components/Organisms/SocialSharing/SocialSharing';
import './PageActions.scss';
import { PageActionProps } from '/Models/Generated/PageActionProps';

const PageActions = (props: PageActionProps) => {
  const print = () => {
    setTimeout(function () {
      window.print();
    }, 0);
  };

  if (props)
    return (
      <ul className="page-actions-react">
        {props.socialSharing && <SocialSharing {...props.socialSharing} />}
        <li>
          <button
            onClick={(e) => {
              e.preventDefault();
              print();
            }}
            role="button"
            className="print-button-react"
          >
            {props.printerFriendlyLinkLabel}
          </button>
        </li>
        <li className="error-link-react">
          <a href={props.errorReportLink.href}>
            {props.errorReportLink.linkText}
          </a>
        </li>
      </ul>
    );
  else return <></>;
};

export default PageActions;
