import React from 'react';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import './CardRelatedLinks.scss';
import { SearchHitMetadataLinkListProps } from '../../../../../../Models/Generated/SearchHitMetadataLinkListProps';
import { Icon } from '../../../../../../Models/Generated/Icon';
import cn from 'classnames';

const CardRelatedLinks = ({
  label,
  metadataLinks,
}: SearchHitMetadataLinkListProps) => {
  if (
    //Do not render if no links has text or linkText
    metadataLinks.filter((element) => element.text || element.linkText).length >
    0
  ) {
    return (
      <>
        {label && (
          <div className="card-related-links-with-label-react__links__label">
            {label}:
          </div>
        )}
        <div
          className={cn('card-related-links-with-label-react__links__list', {})}
        >
          {metadataLinks &&
            metadataLinks.map((link, idx) => {
              if (link.linkHref || link.text) {
                return (
                  <div
                    key={idx}
                    className="card-related-links-with-label-react__links-wrapper"
                  >
                    {link.linkHref && (
                      <span className="card-related-links-with-label-react__links-wrapper__icon">
                        <Link
                          ariaLabel={
                            label ? label + ': ' + link.linkText : link.linkText
                          }
                          className={cn(
                            'card-related-links-with-label-react__links__list__value-link',
                            {
                              external: link.linkIcon === Icon.External,
                              video: link.linkIcon === Icon.Video,
                            }
                          )}
                          href={link.linkHref}
                          text={link.linkText}
                          blankTarget={link.linkBlankTarget}
                          theme={LinkTheme.standard}
                        />
                      </span>
                    )}
                    {link.text && (
                      <span>
                        {link.linkHref && link.linkText ? ' ' : ''}
                        {link.text}
                      </span>
                    )}
                  </div>
                );
              }
            })}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default CardRelatedLinks;
