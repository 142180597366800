import React from 'react';
import './ValidationError.scss';

export interface Props {
  id?: string; // for use by aria-describedBy (accessbility)
  text: string;
}

const ValidationError = ({ id, text }: Props) => {
  return (
    <div id={id} className="ValidationError">
      {text}
    </div>
  );
};

export default ValidationError;
