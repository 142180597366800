import React, { useEffect, useState } from 'react';
import './Referatsak.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { ReferatsakProps } from '/Models/Generated/ReferatsakProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import MinutesNavigationBar from '/Components/Organisms/MinutesNavigationBar/MinutesNavigationBar';
import MinutesHeader from '/Components/Organisms/MinutesHeader/MinutesHeader';
import SpeakerList from '/Components/Organisms/SpeakerList/SpeakerList';
import Link from '/Components/Atoms/Link/Link';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';
import ReferatsakClient from '/Shared/Code/Referatsak/ReferatsakClient';

const Referatsak = ({
  minutesNavigationBar,
  minutesHeader,
  proceedingNo,
  title,
  speakerList,
  mainBody,
  referatsakVotering,
  sporsmalLinks,
  previousLink,
  nextLink,
  threeColumnContentAreaBottom,
}: ReferatsakProps) => {
  const [formattedMainBody, setFormattedMainBody] = useState(null);

  const client = new ReferatsakClient(
    minutesNavigationBar.hiddenVideoLinks?.map((el) => el.href)
  );

  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }

    if (mainBody?.items?.[0]?.component?.html) {
      mainBody.items[0].component.html = client.FormatTextWithLinks(
        mainBody.items[0].component.html
      );
    }

    setFormattedMainBody(mainBody);
  }, []);

  return (
    <PageWrapper>
      <div className="referatsak-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <MinutesHeader {...minutesHeader} />
            <MinutesNavigationBar {...minutesNavigationBar} />
          </MinutesHeaderContainer>
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          {proceedingNo && (
            <div className="referatsak-react__proceeding-no">
              <h2>{proceedingNo}</h2>
            </div>
          )}
          {title && <ContentArea theme={RichTextTheme.MainIntro} {...title} />}
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          className="referatsak-react__row-containing-content-row"
        >
          <Row
            horizontalPadding={HorizontalPadding.indent}
            className="referatsak-react__content-container"
          >
            {speakerList && <SpeakerList {...speakerList} />}
            {formattedMainBody && (
              <div className="page-publication">
                <div className="bigdoc-content">
                  <ContentArea
                    theme={RichTextTheme.BigDoc}
                    {...formattedMainBody}
                  />
                </div>
              </div>
            )}
            {!!referatsakVotering && referatsakVotering.length && (
              <>
                <a id="votering"></a>
                {referatsakVotering.map((votering, idx) => (
                  <div key={idx}>
                    <h2>{votering.tittel}</h2>
                    {votering.mainBody && (
                      <div className="page-publication">
                        <div className="bigdoc-content">
                          <ContentArea
                            theme={RichTextTheme.BigDoc}
                            {...votering.mainBody}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
            {sporsmalLinks?.length > 0 && (
              <>
                {sporsmalLinks.map((spm, idx) => (
                  <h3 key={idx}>
                    <Link href={spm.href} text={spm.text} />
                  </h3>
                ))}
              </>
            )}
          </Row>
          <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
        </Row>
      </div>
      {HasContent(threeColumnContentAreaBottom) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaBottom} />
        </Row>
      )}
    </PageWrapper>
  );
};

export default Referatsak;
