import React from 'react';
import './XmlImportReferatNavigationBar.scss';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';
import { XmlImportReferatNavigationBarProps } from '/Models/Generated/XmlImportReferatNavigationBarProps';

const XmlImportReferatNavigationBar = ({
  agendaLabel,
  frontpageLink,
  pdfVersionLabel,
  pdfLink,
  hiddenVideoLinks,
  videoLinks,
  wholePageLink,
}: XmlImportReferatNavigationBarProps) => {
  return (
    <div className="xmlimportreferat-navigationbar-react">
      {frontpageLink && (
        <IconLinkGeneric
          iconString="document"
          href={frontpageLink}
          text={agendaLabel}
        />
      )}
      {pdfLink && (
        <IconLinkGeneric
          iconString="download"
          href={pdfLink}
          text={pdfVersionLabel}
        />
      )}
      {hiddenVideoLinks?.length > 0 &&
        hiddenVideoLinks.map((video, idx) => (
          <IconLinkGeneric
            key={idx}
            iconString="video"
            href={video.href}
            text={video.text}
          />
        ))}
      {videoLinks?.length > 0 &&
        videoLinks.map((video, idx) => (
          <IconLinkGeneric
            key={idx}
            iconString="video"
            href={video.href}
            text={video.text}
          />
        ))}
      {wholePageLink && (
        <IconLinkGeneric
          iconString="document"
          href={wholePageLink.href}
          text={wholePageLink.text}
        />
      )}
    </div>
  );
};

export default XmlImportReferatNavigationBar;
