import React, { useState } from 'react';
import './SpeakerList.scss';
import { SpeakerListProps } from '/Models/Generated/SpeakerListProps';
import SpeakerListItemWrapper from './SpeakerListItemWrapper';
import Link from '/Components/Atoms/Link/Link';
import LinkButton from '/Components/Atoms/LinkButton/LinkButton';
import ExpandButtonPlusMinus from '/Components/Atoms/ExpandButtonPlusMinus/ExpandButtonPlusMinus';

import cn from 'classnames';

const SpeakerList = ({
  speakersLabel,
  hovedinnleggLabel,
  replikkLabel,
  apneLukkeLabel,
  showAllRepliesLabel,
  hideAllRepliesLabel,
  hasReplikker,
  talere,
  showAllSpeakersLabel,
  hideAllSpeakersLabel,
  // minimizeList,
  voteringLink,
  voteringText,
}: SpeakerListProps) => {
  const [isButtonShowAllComments, setIsButtonShowAllComments] = useState(true);
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);
  const [truncateListOnMobile, setTruncateListOnMobile] = useState(true);

  const toggleOpenAll = () => {
    if (isButtonShowAllComments) {
      setOpenIndexes([...Array(talere.length).keys()]);
      setIsButtonShowAllComments(false);
    } else {
      setOpenIndexes([]);
      setIsButtonShowAllComments(true);
    }
  };

  const toggleComments = (speakerListIdx) => {
    setOpenIndexes((current) => {
      return current.includes(speakerListIdx)
        ? current.filter((el) => el !== speakerListIdx)
        : [...current, speakerListIdx];
    });
  };

  const toggleTruncateListOnMobile = () =>
    setTruncateListOnMobile((current) => !current);

  const isOpen = (idx) => openIndexes.includes(idx);

  return (
    <div className="speakerlist-react">
      {!!talere && talere.length && (
        <>
          {speakersLabel && <h3>{speakersLabel}</h3>}

          {hasReplikker && (
            <div className="speakerlist-react__btn-container-toggle-all">
              <LinkButton
                className="speakerlist-react__link-button"
                onClick={toggleOpenAll}
                text={
                  isButtonShowAllComments
                    ? showAllRepliesLabel
                    : hideAllRepliesLabel
                }
              />
            </div>
          )}

          <ul
            className={cn('speakerlist-react__speaker-list', {
              'speakerlist-react__speaker-list--truncate-on-mobile':
                truncateListOnMobile,
            })}
          >
            {talere.map((taler, idx) => (
              <li
                key={idx}
                className={cn('speakerlist-react__speaker-list__item', {
                  'speakerlist-react__speaker-list__item--open':
                    isOpen(idx) && taler?.replikker?.length > 0,
                })}
              >
                {taler?.replikker?.length > 0 && (
                  <ExpandButtonPlusMinus
                    className="speakerlist-react__btn-expand-comments"
                    onClick={() => toggleComments(idx)}
                    ariaLabel={apneLukkeLabel}
                    ariaControls={'list_id_' + idx}
                    isOpen={isOpen(idx)}
                  />
                )}
                <SpeakerListItemWrapper
                  person={taler}
                  label={hovedinnleggLabel}
                />
                {taler?.replikker?.length > 0 && (
                  <>
                    {isOpen(idx) && (
                      <ul
                        id={'list_id_' + idx}
                        className="speakerlist-react__comments-list"
                      >
                        {taler.replikker.map((replikk, idx2) => (
                          <li
                            key={idx2}
                            className="speakerlist-react__comments-list__item"
                          >
                            <SpeakerListItemWrapper
                              person={replikk}
                              label={replikkLabel}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
          <div>
            <LinkButton
              className="speakerlist-react__btn-truncate-list speakerlist-react__link-button"
              onClick={toggleTruncateListOnMobile}
              text={
                truncateListOnMobile
                  ? showAllSpeakersLabel
                  : hideAllSpeakersLabel
              }
            />
          </div>
          {voteringText && (
            <Link
              className="speakerlist-react__votes-link"
              href={voteringLink}
              text={voteringText}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SpeakerList;
