import React from 'react';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import './ReferatVotering.scss';
import { ReferatVoteringProps } from '/Models/Generated/ReferatVoteringProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import MinutesNavigationBar from '/Components/Organisms/MinutesNavigationBar/MinutesNavigationBar';
import MinutesHeader from '/Components/Organisms/MinutesHeader/MinutesHeader';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const ReferatVotering = ({
  minutesNavigationBar,
  minutesHeader,
  mainBody,
}: ReferatVoteringProps) => {
  return (
    <PageWrapper>
      <div className="referat-votering-page-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <MinutesHeader {...minutesHeader} />
            <MinutesNavigationBar {...minutesNavigationBar} />
          </MinutesHeaderContainer>
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <div className="page-publication">
            <div className="bigdoc-content">
              {mainBody && (
                <ContentArea {...mainBody} theme={RichTextTheme.BigDoc} />
              )}
            </div>
          </div>
        </Row>
      </div>
    </PageWrapper>
  );
};

export default ReferatVotering;
