import { KortvedtakListApiResponseProps } from '/Models/Generated/KortvedtakListApiResponseProps';
import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import BuildUrlWithParams from '../../../Shared/Code/UrlBuilder/BuildUrlWithParams';
import SSQQueryStringReader from '../../../Shared/Code/SSR/SSRQueryStringReader';

export default class KortvedtakClient {
  constructor(private endpoint: string) {}

  public Get(
    /** Language code */
    languageCode: string,
    /** Page number */
    pageNumber: number,
    /** Committee id */
    coid: string,
    /** Year, e.g. 2023 */
    y: string,
    /** Month, or all. 1 is January */
    m: string
  ): Promise<KortvedtakListApiResponseProps> {
    //m=all&coid=all&y=2023
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData().then((res) => UseProdUrls(res));
    } else {
      return fetch(
        this.endpoint +
          `?languageCode=${languageCode}&pageNumber=${pageNumber}&coid=${coid}&y=${y}&m=${m}`,
        {
          headers: { Accept: 'application/json' },
        }
      ).then((res) => res.json());
    }
  }

  GetStateFromUrl = () => {
    const coid = SSQQueryStringReader.Read('coid');
    const m = SSQQueryStringReader.Read('m');
    const y = SSQQueryStringReader.Read('y');
    const p = SSQQueryStringReader.ReadInt('p', 1);
    return { coid, m, y, p };
  };

  BuildFilterUrl = (
    /** Committee id */
    coid: string,
    /** Year, e.g. 2023 */
    y: string,
    /** Month, or all. 1 is January */
    m: string,
    /** Page number, starting at 1 */
    p: number
  ): string =>
    BuildUrlWithParams(window.location.href, null, false, {
      coid,
      y,
      m,
      p,
    });

  private getMockData(): Promise<KortvedtakListApiResponseProps> {
    return fetch('/MockupAssets/MockData/EPOSDagsordenlisteAsyncData.json') //TODO: Add proper mock data
      .then((res) => res.json())
      .then((result: KortvedtakListApiResponseProps) => {
        return result;
      });
  }
}
