import React from 'react';
import './WebcruiterBlock.scss';
import { WebcruiterBlockProps } from '/Models/Generated/WebcruiterBlockProps';
import { FeedItemProps } from '/Models/Generated/FeedItemProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';

const WebcruiterBlock = ({
  feedItems,
  feedItemHeadings,
  isInRichTextArea,
  noVacanciesText,
}: WebcruiterBlockProps) => {
  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <article className="webcruiter-block-react">
        {!(feedItems?.length > 0) && <div>{noVacanciesText}</div>}
        {feedItems &&
          feedItems.map((val: FeedItemProps, idx) => (
            <section className="webcruiter-block-react__position" key={idx}>
              <div>
                <strong className="webcruiter-block-react__heading">
                  {feedItemHeadings.title}
                </strong>

                <Link
                  href={val.link}
                  text={val.title}
                  theme={LinkTheme.standard}
                />
              </div>
              <div>
                <strong className="webcruiter-block-react__heading">
                  {feedItemHeadings.department}
                </strong>
                {val.companyOrgName}
              </div>
              <div>
                <strong className="webcruiter-block-react__heading">
                  {feedItemHeadings.workplacEpostaddress}
                </strong>
                {val.workplacePostaddress}
              </div>
              <div>
                <strong className="webcruiter-block-react__heading">
                  {feedItemHeadings.applicationDeadline}
                </strong>
                {val.applyWithinDate}
              </div>
            </section>
          ))}
      </article>
    </BlockWrapper>
  );
};

export default WebcruiterBlock;
