import React from 'react';
import cn from 'classnames';
import './ResultUnspecified.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultChilds from './Shared/ResultChilds';
import ResultStatus from './Shared/ResultStatus';
import ResultPublished from './Shared/ResultPublished';
import ResultHeader from './Shared/ResultHeader';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultUnspecified = ({
  url,
  title,
  published,
  summary,
  childs,
  highlighting,
  relevance,
  specifics,
  isChild,
}: Props) => {
  return (
    <article
      className={cn('search-result-unspecified-article-react', {
        'is-child-react': isChild,
      })}
    >
      <ResultHeader url={url} title={title} relevance={relevance} />
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      <ResultSummary summary={summary} />
      <ResultHighlighting highlighting={highlighting} />
      <ResultChilds childs={childs} showChilds={true} />
    </article>
  );
};

export default ResultUnspecified;
