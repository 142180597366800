import React, { useEffect, useState } from 'react';
import './EPOSHvaskjerLangtidsprogmoter.scss';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import { EPOSHvaskjerLangtidsprogmoterProps } from '/Models/Generated/EPOSHvaskjerLangtidsprogmoterProps';
import { EPOSHvaskjerLangtidsprogmoterAsyncDataProps } from '/Models/Generated/EPOSHvaskjerLangtidsprogmoterAsyncDataProps';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import EPOSHvaskjerLangtidsprogmoterClient from './EPOSHvaskjerLangtidsprogmoterClient';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import Link from '/Components/Atoms/Link/Link';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';

export interface UrlState {
  yw: string; // year week
  ptid: string; // activity type
}

const getInitialState = (): UrlState => ({
  yw: SSRQueryStringReader.Read('yw') ?? '',
  ptid: SSRQueryStringReader.Read('ptid') ?? '',
});

const initialState: UrlState = getInitialState();

const EPOSHvaskjerLangtidsprogmoter = ({
  filterHeader,
  mainIntro,
  mainBody,
  threeColumnContentArea,
  threeColumnContentAreaBottom,
  threeColumnContentAreaInTop,
  asyncData,
  apiEndpoint,
}: EPOSHvaskjerLangtidsprogmoterProps) => {
  const isMounted = useComponentDidMount();

  const [data, setData] =
    useState<EPOSHvaskjerLangtidsprogmoterAsyncDataProps>(asyncData);

  const [yearWeek, setYearWeek] = useState(
    initialState.yw ||
      asyncData.weekOfYearDropdown?.items?.find((item) => item.isSelected)
        ?.value ||
      'all'
  );

  const [activityType, setActivityType] = useState(
    initialState.ptid ||
      asyncData.activityDropdown?.items?.find((item) => item.isSelected)
        ?.value ||
      'all'
  );

  const [shouldPushState, setShouldPushState] = useState<boolean>(true);

  const client = new EPOSHvaskjerLangtidsprogmoterClient(apiEndpoint);

  const buildUrl = () => {
    const url = client.BuildFilterUrl(yearWeek, activityType);
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (!isMounted) return;

    client.Get(yearWeek, activityType).then((res) => {
      setData(res);
      if (shouldPushState) buildUrl();
      setShouldPushState(true);
    });
  }, [yearWeek, activityType]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const popstateHandler = () => {
      const state = getInitialState();
      setShouldPushState(false);
      setYearWeek(state.yw || 'all');
      setActivityType(state.ptid || 'all');
    };
    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, []);

  return (
    <PageWrapper>
      <div className="epos-hva-skjer-langtidsprogmoter-react">
        {mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          </Row>
        )}
        {HasContent(threeColumnContentAreaInTop) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.normal}
            verticalPaddingBottom={
              HasContent(threeColumnContentAreaBottom)
                ? VerticalPadding.none
                : VerticalPadding.large
            }
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea
              {...threeColumnContentAreaInTop}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}

        {HasContent(threeColumnContentAreaBottom) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={
              HasContent(threeColumnContentAreaInTop)
                ? VerticalPadding.none
                : VerticalPadding.normal
            }
            verticalPaddingBottom={VerticalPadding.large}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea
              {...threeColumnContentAreaBottom}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}

        {mainBody && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.small}
          >
            <ContentArea {...mainBody} theme={RichTextTheme.Standard} />
          </Row>
        )}
        <a id="primaryfilter"></a>
        <Row
          horizontalPadding={HorizontalPadding.none}
          verticalPaddingBottom={VerticalPadding.large}
        >
          <ExpandableFilterWrapper heading={filterHeader}>
            <div className="epos-hva-skjer-langtidsprogmoter-react__filter">
              <DropDownFilterWrapper
                label={data.weekOfYearDropdown.labelText}
                id="yearweek"
                name="yearweek"
                options={data.weekOfYearDropdown.items}
                selectedValue={yearWeek}
                setSelected={setYearWeek}
              />

              <div>
                <DropDownFilterWrapper
                  label={data.activityDropdown.labelText}
                  id="activitytype"
                  name="activitytype"
                  options={data.activityDropdown.items}
                  selectedValue={activityType}
                  setSelected={setActivityType}
                />
              </div>
            </div>
          </ExpandableFilterWrapper>
        </Row>
        {data?.weeks && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ul className="epos-hva-skjer-langtidsprogmoter-react__weeks">
              {data.weeks.map((week, weekIdx) => (
                <li key={weekIdx}>
                  <GreyBar text={week.header} />
                  {week.days && (
                    <ul className="epos-hva-skjer-langtidsprogmoter-react__days">
                      {week.days.map((day, dayIdx) => (
                        <li key={dayIdx}>
                          <h3>{day.header}</h3>
                          {day.parliamentMeetings?.length && (
                            <>
                              <h4>{day.parliamentMeetingHeader}</h4>
                              <ul className="epos-hva-skjer-langtidsprogmoter-react__meetings">
                                {day.parliamentMeetings.map((pm, pmIdx) => (
                                  <li key={pmIdx}>
                                    <div>{pm.meetingHeaderText}</div>
                                    <Link
                                      {...pm.meetingAgendaLink}
                                      className="epos-hva-skjer-langtidsprogmoter-react__meetings__agenda-link"
                                    />
                                    {!!pm.meetingAgendaList?.length &&
                                      pm.meetingAgendaList.map(
                                        (pmma, pmmaIdx) => (
                                          <div key={pmmaIdx}>
                                            {pmma.titleWithLink && (
                                              <Link {...pmma.titleWithLink} />
                                            )}
                                            {pmma.title} {pmma.text}
                                          </div>
                                        )
                                      )}
                                    {!!pm.meetingProceedingList?.length &&
                                      pm.meetingProceedingList.map(
                                        (pmp, pmpIdx) => (
                                          <div key={pmpIdx}>
                                            {pmp.titleWithLink && (
                                              <Link {...pmp.titleWithLink} />
                                            )}
                                            {pmp.title}
                                          </div>
                                        )
                                      )}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                          {day.meetingRemarkHeader && day.meetingRemarkText && (
                            <h4>{day.meetingRemarkHeader}</h4>
                          )}
                          {day.meetingRemarkText && (
                            <div
                              className="epos-hva-skjer-langtidsprogmoter-react__meeting-remark-text"
                              dangerouslySetInnerHTML={{
                                __html: day.meetingRemarkText,
                              }}
                            />
                          )}
                          {!!day.committeeTravels?.length && (
                            <div className="epos-hva-skjer-langtidsprogmoter-react__committee-travels">
                              <div className="epos-hva-skjer-langtidsprogmoter-react__committee-travels__heading">
                                {day.committeeTravelHeader}
                              </div>
                              <div className="epos-hva-skjer-langtidsprogmoter-react__committee-travels__content">
                                {day.committeeTravels.map((ct, ctIdx) => (
                                  <React.Fragment key={ctIdx}>
                                    {ct.link && <Link {...ct.link} />} {ct.text}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          )}
                          {/*
                            delegationTravels is apparently not in use in backend.
                            Styling same as committeeTravels to be on the safe side.
                          */}
                          {!!day.delegationTravels?.length && (
                            <div className="epos-hva-skjer-langtidsprogmoter-react__delegation-travels">
                              <div className="epos-hva-skjer-langtidsprogmoter-react__delegation-travels__heading">
                                {day.delegationTravelHeader}
                              </div>
                              <div className="epos-hva-skjer-langtidsprogmoter-react__delegation-travels__content">
                                {day.delegationTravels.map((dt, dtIdx) => (
                                  <React.Fragment key={dtIdx}>
                                    {dt.link && <Link {...dt.link} />} {dt.text}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          )}
                          {day.generalRemarkHeader && day.generalRemarkText && (
                            <h4>{day.generalRemarkHeader}</h4>
                          )}
                          {day.generalRemarkText && (
                            <div>{day.generalRemarkText}</div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Row>
        )}
        {HasContent(threeColumnContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineAbove={true}
            lineBelow={true}
          >
            <ContentArea
              {...threeColumnContentArea}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSHvaskjerLangtidsprogmoter;
