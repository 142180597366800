import React from 'react';
import './Committee.scss';
import CircleImage, {
  CircleImageSizes,
} from '/Components/Atoms/CircleImage/CircleImage';
import { CommitteeListItemProps } from '../../../Models/Generated/CommitteeListItemProps';

const Committee = (props: CommitteeListItemProps) => {
  return (
    <article className="committee-react">
      {props.frontpageImageUrl && (
        <CircleImage
          src={props.frontpageImageUrl}
          size={CircleImageSizes.small}
          alt=""
        />
      )}
      <div>
        <h3 className="committee-react__title">
          <a href={props.url} className="committee-react__title-link">
            {props.title}
          </a>
        </h3>
        <p className="committee-react__sub-links-container">
          <a href={props.membersUrl} className="committee-react__sub-link">
            {props.membersText}
          </a>
          <span> - </span>
          <a href={props.hearingsUrl} className="committee-react__sub-link">
            {props.hearingText}
          </a>
        </p>
      </div>
    </article>
  );
};

export default Committee;
