import React from 'react';
import './ProceedingOverviewWrapper.scss';

interface ProceedingOverviewWrapperProps {
  children?: React.ReactNode;
  heading?: string;
}

interface ChildrenProps {
  children?: React.ReactNode;
}

const Left = ({ children }: ChildrenProps) => <>{children}</>;
const Right = ({ children }: ChildrenProps) => <>{children}</>;

const ProceedingOverviewWrapper = ({
  children,
  heading,
}: ProceedingOverviewWrapperProps) => {
  const left = children.find((el) => el.type === Left);
  const right = children.find((el) => el.type === Right);
  return (
    <div className="proceeding-overview-wrapper-react">
      <div className="proceeding-overview-wrapper-react__left">
        {heading && (
          <h2 className="proceeding-overview-wrapper-react__left__heading">
            {heading}
          </h2>
        )}
        <div className="proceeding-overview-wrapper-react__left__content">
          {left ? left.props.children : null}
        </div>
      </div>
      <div className="proceeding-overview-wrapper-react__right">
        {right ? right.props.children : null}
      </div>
    </div>
  );
};

ProceedingOverviewWrapper.Left = Left;
ProceedingOverviewWrapper.Right = Right;

export default ProceedingOverviewWrapper;
