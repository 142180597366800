import React from 'react';
import './CaseOverview.scss';

import { EposSakOverviewProps } from '/Models/Generated/EposSakOverviewProps';

const CaseOverview = ({
  status,
  statusHeading,
  steps,
  stepsHeading,
}: EposSakOverviewProps) => {
  return (
    <div className="case-overview-react">
      <h2 className="case-overview-react__heading">{stepsHeading}</h2>
      <ol className="case-overview-react__steps-list">
        {steps.map((item, index) => (
          <li className="case-overview-react__steps-list__li" key={index}>
            <a href={'#' + item.domId} className="case-overview-react__link">
              <div className="case-overview-react__steps-list__content">
                <span
                  className={
                    'case-overview-react__steps-list__number ' + item.cssClass
                  }
                >
                  {item.number}
                </span>
                <span className="case-overview-react__steps-list__title">
                  {item.title}
                </span>
              </div>
            </a>
          </li>
        ))}
      </ol>

      {status && (
        <div className="case-overview-react__status">
          <h3 className="case-overview-react__status__title">
            {statusHeading}
          </h3>
          <div className="case-overview-react__status__content">
            {status.fromText && (
              <span className="case-overview-react__status__text">
                {status.fromText}
              </span>
            )}
            {status.committeeText && (
              <span className="case-overview-react__status__text">
                {status.committeeText}
              </span>
            )}
            {status.parenthesisText && (
              <span className="case-overview-react__status__text">
                {status.parenthesisText}
              </span>
            )}
            {status.decisionInShortLink && (
              <div className="case-overview-react__status__internal-link">
                <a
                  href={status.decisionInShortLink.href}
                  className="case-overview-react__status__internal-link__a"
                >
                  {status.decisionInShortLink.text}
                </a>
              </div>
            )}
            {status.awaitsVotingText && (
              <span className="case-overview-react__status__text">
                {status.awaitsVotingText}
              </span>
            )}

            {(status.recommendationText || status.recommendationLink) && (
              <>
                <span className="case-overview-react__status__date">
                  {status.recommendationGivenDateText}
                </span>
                {status.recommendationText && (
                  <span className="inline">{status.recommendationText}</span>
                )}
                {status.recommendationLink && (
                  <a
                    href={status.recommendationLink.href}
                    className="case-overview-react__status__link icon icon-left icon-exclamation"
                  >
                    <span className="inline">
                      {status.recommendationLink.text}
                    </span>
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CaseOverview;
