import React from 'react';
import './InformationCard.scss';
import CardHeader from '../CardSubComponents/CardHeader/CardHeader';
import CardText from '../CardSubComponents/CardText/CardText';
import { GlobalSearchHitProps } from '/Models/Generated/GlobalSearchHitProps';
import cn from 'classnames';

interface InformationCardProps extends GlobalSearchHitProps {
  showHitScore?: boolean;
}
const InformationCard = ({
  hitType,
  cardHeaderProps,
  cardTextProps,
  cardImageProps,
  showHitScore,
  hitScore,
}: InformationCardProps) => {
  return (
    <div
      className={cn('information-card-react', hitType)}
      data-card-type={cardHeaderProps.hitTypeLabel}
    >
      {showHitScore && hitScore && (
        <b className="information-card-react__hitScore">
          Søkescore: {hitScore}
        </b>
      )}
      <div className="information-card-react__logo">
        <img
          className="information-card-react__logo__img"
          src={
            hitType === 'error'
              ? require('/Assets/Images/Icons/alert-icon.svg')
              : require('/Assets/Images/Icons/information-icon.svg')
          }
          alt={cardImageProps?.altText || ''}
        ></img>
      </div>
      {cardHeaderProps && (
        <div className="information-card-react__header">
          <CardHeader {...cardHeaderProps} />
        </div>
      )}

      {cardTextProps && (
        <div className="information-card-react__content">
          <CardText {...cardTextProps} />
        </div>
      )}
    </div>
  );
};

export default InformationCard;
