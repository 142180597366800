import React, { useState } from 'react';
import BlockWrapper, {
  BlockWrapperAlignment,
} from '/Components/Layout/BlockWrapper/BlockWrapper';
import './ImageWithCaption.scss';
import { ImageWithCaptionProps } from '/Models/Generated/ImageWithCaptionProps';
import { Alignment } from '/Models/Generated/Alignment';
import cn from 'classnames';

interface Props extends ImageWithCaptionProps {
  maxWidthTablet?: boolean;
  supportImageZoom?: boolean;
}

const ImageWithCaption = ({
  imageSrc,
  imageSrcPropertyName,
  altText,
  imageText,
  imageTextPropertyName,
  imageCredits,
  imageCreditsPropertyName,
  alignment,
  isInRichTextArea,
  fullSize,
  maxWidthTablet,
  supportImageZoom,
}: Props) => {
  const blockAlignment =
    alignment === Alignment.Right
      ? BlockWrapperAlignment.Right
      : alignment === Alignment.Left
      ? BlockWrapperAlignment.Left
      : alignment === Alignment.LeftWithin
      ? BlockWrapperAlignment.LeftWithin
      : alignment === Alignment.RightWithin
      ? BlockWrapperAlignment.RightWithin
      : BlockWrapperAlignment.None;

  const [isZoomed, setIsZoomed] = useState(false);

  return imageSrc ||
    imageSrcPropertyName ||
    imageText ||
    imageTextPropertyName ? (
    <BlockWrapper
      isInRichTextArea={isInRichTextArea}
      alignment={blockAlignment}
      noSizeLimit={fullSize}
      isZoomed={isZoomed}
    >
      <figure
        className={cn('image-with-caption-react', {
          'image-with-caption-react__max-width-tablet': maxWidthTablet,
        })}
      >
        <img
          src={imageSrc}
          alt={altText ?? ''}
          data-epi-edit={imageSrcPropertyName}
          onClick={() => {
            if (supportImageZoom) {
              setIsZoomed((current) => !current);
            }
          }}
        />
        {(imageText ||
          imageCredits ||
          imageTextPropertyName ||
          imageCreditsPropertyName) && (
          <figcaption className="image-with-caption-react__caption">
            {imageText && (
              <p
                className="image-with-caption-react__caption__text"
                data-epi-edit={imageTextPropertyName}
              >
                {imageText}
              </p>
            )}
            {imageCredits && (
              <span
                className="image-with-caption-react__caption__credits"
                data-epi-edit={imageCreditsPropertyName}
              >
                {imageCredits}
              </span>
            )}
          </figcaption>
        )}
      </figure>
    </BlockWrapper>
  ) : (
    <></>
  );
};

export default ImageWithCaption;
