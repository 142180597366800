import { VideoarkivAsyncProps } from '/Models/Generated/VideoarkivAsyncProps';
import UseProdUrls from '/Mockup/Helpers/UseProdUrls';

export default class EPOSDagsordenlisteClient {
  constructor(private endpoint: string, private pageId: number) {}

  public Get(
    /** videotype (HOE|STM) */
    vt: string,
    /** Session (2020-2021) */
    pid: string,
    /** Month number (January is 1) */
    m: string,
    /** Committee id */
    coid: string,
    /** Selected tab */
    tab: string,
    /** Selected page, the first page is 1 */
    page: number,
    /** Language, to get correct translations */
    language: string,
    /** Sort term/column 'ActivityStart'/'Title' */
    sb: string,
    /** Sort direction 'Ascending'/'Descending'*/
    sd: string
  ): Promise<VideoarkivAsyncProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(vt).then((res) => UseProdUrls(res));
    } else {
      return fetch(
        this.endpoint +
          `?pageId=${this.pageId}&` +
          this.BuildFilterUrl(vt, pid, m, coid, tab, page, language) +
          `&sb=${sb}&sd=${sd}`,
        {
          headers: { Accept: 'application/json' },
        }
      ).then((res) => res.json());
    }
  }

  BuildFilterUrl = (
    /** videotype (HOE|STM) */
    vt: string,
    /** Session (2020-2021) */
    pid: string,
    /** Month number (January is 1) */
    m: string,
    /** Committee id */
    coid: string,
    /** Selected tab */
    tab: string,
    /** Selected page, the first page is 1 */
    page: number,
    /** Language */
    language: string
  ): string => {
    const params = [
      vt ? `vt=${vt}` : null,
      pid ? `pid=${pid}` : null,
      m ? `m=${m}` : null,
      coid ? `coid=${coid}` : null,
      tab ? `tab=${tab}` : null,
      page ? `page=${page}` : null,
      language ? `language=${language}` : null,
    ].filter((p) => p !== null);
    return params.join('&');
  };

  private getMockData(vt: string): Promise<VideoarkivAsyncProps> {
    return fetch(
      vt === 'HOE'
        ? '/MockupAssets/MockData/VideoarkivAsyncDataHearing.json'
        : '/MockupAssets/MockData/VideoarkivAsyncDataMeeting.json'
    ).then((res) => res.json());
  }
}
