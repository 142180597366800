export const emphasize = (text: string, toEmphasize: string, tag = 'b') => {
  if (toEmphasize && text) {
    let result = '';
    let rest = text;
    while (true) {
      const idx = rest.toLowerCase().indexOf(toEmphasize?.toLowerCase());
      if (idx === -1) break;
      result += rest.substring(0, idx);
      rest = rest.substring(idx);
      result += `<${tag}>${rest.substring(0, toEmphasize.length)}</${tag}>`;
      rest = rest.substring(toEmphasize.length);
    }
    return result + rest;
  }
  return text;
};
