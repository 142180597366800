import React from 'react';
import './PortalsideHvaskjer.scss';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { PortalsideHvaskjerProps } from '/Models/Generated/PortalsideHvaskjerProps';
import AlertMessage from '/Components/Atoms/AlertMessage/AlertMessage';
import PortalsideHvaSkjerCalendar from './PortalsideHvaskjerCalendar';

const PortalsideHvaSkjer = ({
  topContentArea,
  hoeringerError,
  additionalLinks,
  bottomContentArea,
  calendar,
}: PortalsideHvaskjerProps) => {
  return (
    <PageWrapper>
      <div className="portalsidehvaskjer-react">
        {HasContent(topContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            noPaddingOnMobile={true}
          >
            <ContentArea {...topContentArea} theme={RichTextTheme.Standard} />
          </Row>
        )}
        {HasContent(hoeringerError) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.large}
          >
            <AlertMessage>
              <ContentArea {...hoeringerError} theme={RichTextTheme.Standard} />
            </AlertMessage>
          </Row>
        )}
        <PortalsideHvaSkjerCalendar {...calendar} />
        {HasContent(additionalLinks) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.large}
          >
            <ContentArea {...additionalLinks} theme={RichTextTheme.Standard} />
          </Row>
        )}
        {HasContent(bottomContentArea) && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingTop={VerticalPadding.large}
          >
            <ContentArea
              {...bottomContentArea}
              theme={RichTextTheme.Standard}
            />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default PortalsideHvaSkjer;
