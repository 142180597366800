import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
// import { InfoListProps } from '/Models/Generated/InfoListProps';
import { EPOSDagsordenlisteAsyncDataProps } from '/Models/Generated/EPOSDagsordenlisteAsyncDataProps';

export default class EPOSDagsordenlisteClient {
  constructor(private endpoint: string) {}

  public Get(
    /** Period */
    pid: string,
    /** Meeting type */
    chid: string
  ): Promise<EPOSDagsordenlisteAsyncDataProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(pid, chid).then((res) => UseProdUrls(res));
    } else {
      return fetch(this.endpoint + `?pid=${pid}&chid=${chid}`, {
        headers: { Accept: 'application/json' },
      }).then((res) => res.json());
    }
  }

  BuildFilterUrl = (pid: string, chid: string): string => {
    const params = [
      pid ? `pid=${pid}` : null,
      chid ? `chid=${chid}` : null,
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private getMockData(
    pid: string,
    chid: string
  ): Promise<EPOSDagsordenlisteAsyncDataProps> {
    return fetch('/MockupAssets/MockData/EPOSDagsordenlisteAsyncData.json')
      .then((res) => res.json())
      .then((result: EPOSDagsordenlisteAsyncDataProps) => {
        if (pid) {
          const years = pid.split('-');
          if (years[1].length === 2) years[1] = '19' + years[1];

          const filteredRows = result.tableData.rows.filter(
            (row) =>
              row.cells[1].value.includes(years[0]) ||
              row.cells[1].value.includes(years[1])
          );

          result.tableData.rows = filteredRows;
          result.showMeetingTypesDropDown = +years[0] < 2009;
          result.tableHeading = pid;
          if (chid)
            result.tableHeading += ` - ${
              { all: 'Alle', L: 'Lagting', O: 'Odelsting', S: 'Storting' }[chid]
            }`;
        }

        return result;
      });
  }
}
