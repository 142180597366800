import React from 'react';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import './OrgChart.scss';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { OrgChartProps } from '/Models/Generated/OrgChartProps';

const OrgChart = ({ mainIntro, orgChart }: OrgChartProps) => {
  return (
    <div className="orgchart-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <div className="orgchart-react__main-intro">
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        </div>
      </Row>
      {orgChart && (
        <div>
          {orgChart.header && <h2>{orgChart.header}</h2>}
          <div className="orgchart-react__container">
            {orgChart.board && (
              <figure className="orgchart-react__board">
                <ul className="orgchart-react__board__list">
                  {orgChart.board.map((val, idx) => (
                    <li key={idx} className="orgchart-react__board__list-item">
                      {val.link?.href ? (
                        <a
                          href={val.link.href}
                          className="orgchart-react__board__li-content"
                        >
                          {val.link.text}
                        </a>
                      ) : (
                        <span className="orgchart-react__board__li-content">
                          {val.link?.text}
                        </span>
                      )}
                      {val.units && (
                        <ul className="orgchart-react__board__sub-list">
                          {val.units.map((unit, idx) => (
                            <li
                              key={idx}
                              className="orgchart-react__board__sub-list-item"
                            >
                              {unit.href ? (
                                <a
                                  href={unit.href}
                                  className="orgchart-react__board__li-content"
                                >
                                  {unit.text}
                                </a>
                              ) : (
                                <span className="orgchart-react__board__li-content">
                                  {unit.text}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </figure>
            )}
            {orgChart.departments && (
              <figure className="orgchart-react__depts">
                <ul className="orgchart-react__depts__list">
                  {orgChart.departments.map((dep, idx) => (
                    <li key={idx} className="orgchart-react__depts__list-item">
                      {dep.link?.href ? (
                        <a
                          href={dep.link.href}
                          className="orgchart-react__depts__li-content"
                        >
                          {dep.link.text}
                        </a>
                      ) : (
                        <span className="orgchart-react__depts__li-content">
                          {dep.link.text}
                        </span>
                      )}
                      {dep.units && (
                        <ul className="orgchart-react__depts__sub-list">
                          {dep.units.map((unit, idx) => (
                            <li
                              key={idx}
                              className="orgchart-react__depts__sub-list-item"
                            >
                              {unit.href ? (
                                <a
                                  href={unit.href}
                                  className="orgchart-react__depts__li-content"
                                >
                                  {unit.text}
                                </a>
                              ) : (
                                <span className="orgchart-react__depts__li-content">
                                  {unit.text}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </figure>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrgChart;
