import './CallToActionTopBlockType.scss';
import { CallToActionTopBlockTypeProps } from '/Models/Generated/CallToActionTopBlockTypeProps';
import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

// slide order varies on desktop and mobile if 6 blocks are displayed
const slideOrderDesktopSixBlocks = [0, 4, 2, 3, 1, 5];
const slideOrderMobileSixBlocks = [0, 3, 1, 4, 2, 5];
const slideOrderThreeBlocks = [0, 2, 1];

const CallToActionTopBlockType = ({
  header,
  headerPropertyName,
  numberOfBlocks,
  blockItems,
}: CallToActionTopBlockTypeProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [scrollingTo, setScrollingTo] = useState(-1);
  const scrollingToRef = useRef(-1);
  scrollingToRef.current = scrollingTo;

  const trackContainerRef = useRef(null);
  const trackRef = useRef(null);
  let observer;

  useEffect(() => {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((el) => {
          if (el.isIntersecting) {
            const slideIndex = el.target.dataset['slideIndex'];
            if (scrollingToRef.current !== -1) {
              if (scrollingToRef.current === +slideIndex) {
                setScrollingTo(-1);
                setCurrentSlide(+slideIndex);
              }
              return;
            }
            if (slideIndex) setCurrentSlide(+slideIndex);
          }
        });
      },
      {
        root: trackContainerRef.current,
        threshold: 0.7,
      }
    );
    const slides = [...trackRef?.current?.children];
    slides.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  const getCurrentSlide = () => {
    const slides = [...trackRef?.current?.children];
    if (!slides) return null;
    const scroller = trackRef.current.parentElement;
    const measurePoint = scroller.scrollLeft + scroller.clientWidth / 2;
    let totalOffset = 0;
    for (let i = 0; i < slides.length; ++i) {
      if (
        totalOffset < measurePoint &&
        totalOffset + slides[i].clientWidth > measurePoint
      )
        return slides[i];
      totalOffset += slides[i].clientWidth;
    }
  };

  const isCurrentSlide = (slide) => getCurrentSlide() === slide;

  const scrollToIndex = (idx: number) => {
    const slides = [...trackRef?.current?.children];
    const targetSlide = slides.find((el) => +el.dataset['slideIndex'] === idx);
    if (targetSlide && !isCurrentSlide(targetSlide))
      targetSlide.parentElement.parentElement.scrollTo(
        targetSlide.offsetLeft,
        0
      );
  };

  useEffect(() => {
    if (scrollingTo !== -1) scrollToIndex(scrollingTo);
  }, [scrollingTo]);

  useEffect(() => {
    if (scrollingTo === -1) scrollToIndex(currentSlide);
  }, [currentSlide]);

  const slideOrderMobile = () => {
    if (numberOfBlocks === 3) return slideOrderThreeBlocks;
    if (numberOfBlocks === 6) return slideOrderMobileSixBlocks;
    return [];
  };

  const slideOrderDesktop = () => {
    if (numberOfBlocks === 3) return slideOrderThreeBlocks;
    if (numberOfBlocks === 6) return slideOrderDesktopSixBlocks;
    return [];
  };

  return (
    <div className="call-to-action-top-block-type-react__container">
      <div className="call-to-action-top-block-type-react__header">
        <hr />
        <h2
          className="call-to-action-top-block-type-react__header__text"
          data-epi-edit={headerPropertyName}
        >
          {header}
        </h2>
        <hr />
      </div>
      {/* MOBILE VIEW */}
      <div className="call-to-action-top-block-type-react call-to-action-top-block-type-react__slider-view">
        <div
          ref={trackContainerRef}
          className="call-to-action-top-block-type-react__track-container"
        >
          <div
            ref={trackRef}
            className="call-to-action-top-block-type-react__track"
          >
            {blockItems &&
              slideOrderMobile().map((index) => (
                <div
                  className={`call-to-action-top-block-type-react__slide call-to-action-top-block-type-react__content-box content-box-${index}`}
                  key={'idx-' + index}
                  data-slide-index={index}
                  style={{
                    backgroundImage: `url(${blockItems[index].blockImage})`
                      ? blockItems[index].blockImage
                      : null,
                  }}
                >
                  {blockItems[index].blockImage && (
                    <div
                      className="call-to-action-top-block-type-react__bg-zoom-img"
                      style={{
                        backgroundImage: `url(${blockItems[index].blockImage})`,
                      }}
                    ></div>
                  )}
                  <a href={blockItems[index].blockUrl}>
                    <h2>{blockItems[index].blockTitle}</h2>
                  </a>
                </div>
              ))}
          </div>
        </div>
        <div className="call-to-action-top-block-type-react__slider-nav">
          {slideOrderMobile().map((index, sequenceIndex) => (
            <button
              key={index}
              onClick={() => {
                setScrollingTo(index);
              }}
              className={cn('call-to-action-top-block-type-react__nav-btn', {
                'active-btn':
                  index === scrollingTo ||
                  (scrollingTo === -1 && index === currentSlide),
              })}
              aria-label={'Slide ' + (sequenceIndex + 1)}
              aria-current={
                index === scrollingTo ||
                (scrollingTo === -1 && index === currentSlide)
              }
            ></button>
          ))}
        </div>
      </div>
      {/* DESKTOP VIEW */}
      <div className="call-to-action-top-block-type-react call-to-action-top-block-type-react__grid-view">
        {blockItems &&
          slideOrderDesktop().map((index) => (
            <div
              className={`call-to-action-top-block-type-react__grid-item call-to-action-top-block-type-react__content-box content-box-${index} number-of-blocks-${numberOfBlocks}`}
              key={'idx-' + index}
            >
              {blockItems[index].blockImage && (
                <div
                  className="call-to-action-top-block-type-react__bg-zoom-img"
                  style={{
                    backgroundImage: `url(${blockItems[index].blockImage})`,
                  }}
                ></div>
              )}
              <a href={blockItems[index].blockUrl}>
                <h2>{blockItems[index].blockTitle}</h2>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};
export default CallToActionTopBlockType;
