import React from 'react';
import { PaginationProps } from '/Models/Generated/PaginationProps';
import './Pagination.scss';

export interface Props extends PaginationProps {
  currentPage: number;
  totalPages: number;
  buildLinkFunction?: (num: number) => string;
  onClickFunction?: (num: number) => void;
}

const buildLink = (text, index, classNames = '') => {
  return { text, index, classNames };
};

const Pagination = ({
  firstLabel,
  previousLabel,
  nextLabel,
  lastLabel,
  currentPage,
  totalPages,
  buildLinkFunction,
  onClickFunction,
}: Props) => {
  const links = () => {
    const arr = [];
    if (currentPage !== 1) arr.push(buildLink(firstLabel, 1));
    if (currentPage !== 1) arr.push(buildLink(previousLabel, currentPage - 1));
    for (
      let i = Math.max(currentPage - 5, 1);
      i <= Math.min(currentPage + 5, totalPages);
      ++i
    ) {
      if (totalPages > 1)
        arr.push(buildLink(i, i, 'pagination-react__link--number'));
    }
    if (currentPage !== totalPages)
      arr.push(buildLink(nextLabel, currentPage + 1));
    if (currentPage !== totalPages) arr.push(buildLink(lastLabel, totalPages));
    return arr;
  };

  return (
    <nav className="pagination-react" aria-label="Pagination">
      <ul className="pagination-react__list">
        {links().map((linkProps) => (
          <li
            key={linkProps.text}
            className={`pagination-react__link ${linkProps.classNames}`}
          >
            {linkProps.index === currentPage && (
              <span aria-current="page">{linkProps.text}</span>
            )}
            {linkProps.index !== currentPage && !!buildLinkFunction && (
              <a href={buildLinkFunction(linkProps.index)}>{linkProps.text}</a>
            )}

            {linkProps.index !== currentPage && !!onClickFunction && (
              <button
                className="pagination-react__link"
                onClick={() => onClickFunction(linkProps.index)}
              >
                {linkProps.text}
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
