import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LiveMeetingInformationBlockTypeProps } from '/Models/Generated/LiveMeetingInformationBlockTypeProps';
import { StortingetLiveOverviewProps } from '/Models/Generated/StortingetLiveOverviewProps';

export interface LiveBlockState {
  cmsData: LiveMeetingInformationBlockTypeProps;
  liveData: StortingetLiveOverviewProps;
  shouldShowSlider: boolean;
  shouldShowTop: boolean;
  errorNoData: boolean;
}

const getInitialState = (): LiveBlockState => ({
  cmsData: null,
  liveData: null,
  shouldShowSlider: false,
  shouldShowTop: false,
  errorNoData: false,
});

const initialState: LiveBlockState = getInitialState();

export const LiveBlockSlice = createSlice({
  name: 'liveBlockSlice',
  initialState,
  reducers: {
    setCmsData(
      state,
      action: PayloadAction<LiveMeetingInformationBlockTypeProps>
    ) {
      state.cmsData = action.payload;
    },
    setLiveData(state, action: PayloadAction<StortingetLiveOverviewProps>) {
      state.liveData = action.payload;
    },
    setShouldShowSlider(state, action: PayloadAction<boolean>) {
      state.shouldShowSlider = action.payload;
    },
    setShouldShowTop(state, action: PayloadAction<boolean>) {
      state.shouldShowTop = action.payload;
    },
    setErrorNoData(state, action: PayloadAction<boolean>) {
      state.errorNoData = action.payload;
    },
  },
});

export const {
  setCmsData,
  setLiveData,
  setShouldShowSlider,
  setShouldShowTop,
  setErrorNoData,
} = LiveBlockSlice.actions;
export default LiveBlockSlice.reducer;
