import React, { FunctionComponent, useState } from 'react';
import GreyRow, {
  GreyRowVerticalPadding,
  GreyRowWidth,
} from '/Components/Layout/GreyRow/GreyRow';
import Row from '/Components/Layout/Row/Row';
import './FilterContainer.scss';
import classNames from 'classnames';

interface Props {
  header: string;
  children?: React.ReactNode;
  expanderId: string;
  initiallyExpanded?: boolean;
  width?: GreyRowWidth;
}

const FilterContainer: FunctionComponent<Props> = ({
  header,
  children,
  expanderId,
  initiallyExpanded,
  width,
}) => {
  const [isOpen, setIsOpen] = useState(initiallyExpanded ?? false);
  return (
    <Row>
      <GreyRow
        width={width ?? GreyRowWidth.indented}
        verticalPadding={GreyRowVerticalPadding.normal}
        fullWidthText={width === GreyRowWidth.wide}
      >
        <div className="filter-container-react">
          <div className="filter-container-react__header-container">
            <h2 className="filter-container-react__heading">{header}</h2>
            <button
              className={classNames('filter-container-react__expander-toggle', {
                'filter-container-react__expander-toggle--expanded': isOpen,
              })}
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-controls={expanderId}
            >
              <span className="filter-container-react__sr-only">{header}</span>
            </button>
          </div>

          <div
            className={classNames('filter-container-react__expander', {
              'filter-container-react__expander--expanded': isOpen,
            })}
            id={expanderId}
          >
            {children}
          </div>
        </div>
      </GreyRow>
    </Row>
  );
};
export default FilterContainer;
