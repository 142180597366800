import React from 'react';
import { FocusItemProps } from '/Models/Generated/FocusItemProps';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import './KursListeside.scss';
import { KursListesideProps } from '/Models/Generated/KursListesideProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import FocusItemList from '../../Organisms/FocusItemList/FocusItemList';

const KursListeside = ({
  mainRegion,
  rightRegion,
  wrapContentInNoIndexTags,
}: KursListesideProps) => {
  return (
    <article className="kurs-listeside-react">
      {mainRegion && (
        <Row horizontalPadding={HorizontalPadding.indent}>
          {wrapContentInNoIndexTags && (
            <div dangerouslySetInnerHTML={{ __html: '<!-- NOINDEX -->' }}></div>
          )}
          {mainRegion.mainIntro && (
            <ContentArea
              {...mainRegion.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          )}
          {mainRegion.mainBody && <ContentArea {...mainRegion.mainBody} />}
          {mainRegion.focusItems?.length > 0 && (
            <FocusItemList
              items={mainRegion.focusItems.filter(
                (val: FocusItemProps) => val?.href !== null
              )}
            />
          )}
          {mainRegion.eventListings && (
            <>
              {mainRegion.eventListings.showAll &&
                mainRegion.eventListings.eventListingsShort && (
                  <FocusItemList
                    items={mainRegion.eventListings.eventListingsShort.filter(
                      (val: FocusItemProps) => val?.href !== null
                    )}
                  />
                )}
              {!mainRegion.eventListings.showAll &&
                mainRegion.eventListings.eventListingsDetailed && (
                  <FocusItemList
                    items={mainRegion.eventListings.eventListingsDetailed.filter(
                      (val: FocusItemProps) => val?.href !== null
                    )}
                  />
                )}
              {mainRegion.eventListings?.showAllLink?.href && (
                <a
                  href={mainRegion.eventListings.showAllLink.href}
                  className="kurs-listeside-react__show-all-link"
                >
                  {mainRegion.eventListings.showAllLink.text}
                </a>
              )}
            </>
          )}
          {wrapContentInNoIndexTags && (
            <div
              dangerouslySetInnerHTML={{ __html: '<!-- /NOINDEX -->' }}
            ></div>
          )}
        </Row>
      )}

      {HasContent(rightRegion) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          {wrapContentInNoIndexTags && (
            <div dangerouslySetInnerHTML={{ __html: '<!-- NOINDEX -->' }}></div>
          )}
          <ContentArea {...rightRegion} />
          {wrapContentInNoIndexTags && (
            <div
              dangerouslySetInnerHTML={{ __html: '<!-- /NOINDEX -->' }}
            ></div>
          )}
        </Row>
      )}
    </article>
  );
};

export default KursListeside;
