import React from 'react';
import './SearchPagination.scss';
import { SearchPaginationProps } from '/Models/Generated/SearchPaginationProps';
import LinkButton, {
  Props as LinkButtonProps,
} from '/Components/Atoms/LinkButton/LinkButton';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { setPage } from '/Shared/Code/Search/SearchSlice';

const SearchPagePaging = (props: SearchPaginationProps) => {
  const results = useSelector((s: RootState) => s.Search.results);
  const lastPage = results.GetPageCount();
  const currentPage = results.GetPage();
  const dispatch = useDispatch();

  const buildLink = (text: string, page?: number) => ({
    text,
    onClick: page === null ? null : () => dispatch(setPage(page)),
    href: '',
  });

  const links: Array<LinkButtonProps> = [];
  if (currentPage !== 1) links.push(buildLink(props.first, 1));
  if (currentPage !== 1) links.push(buildLink(props.previous, currentPage - 1));
  for (let i = Math.max(currentPage - 5, 1); i < currentPage; ++i)
    links.push(buildLink(`${i}`, i));
  links.push(buildLink(`${currentPage}`, null));
  for (let i = currentPage + 1; i <= Math.min(currentPage + 5, lastPage); ++i)
    links.push(buildLink(`${i}`, i));
  if (currentPage < lastPage)
    links.push(buildLink(props.next, currentPage + 1));
  if (currentPage < lastPage) links.push(buildLink(props.last, lastPage));
  return (
    <div className="search-pagination">
      <ul>
        {links.map((linkProps, idx) => (
          <li key={idx} className="pagination-links">
            {linkProps.onClick !== null && <LinkButton {...linkProps} />}
            {linkProps.onClick === null && <span>{linkProps.text}</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SearchPagePagingWrapper = (props: SearchPaginationProps) => (
  <Provider store={SearchStore}>
    <SearchPagePaging {...props} />
  </Provider>
);

export default SearchPagePagingWrapper;
