import React from 'react';
import './DictionaryLetterTabs.scss';
import { DictionaryTabItemProps } from '/Models/Generated/DictionaryTabItemProps';
import cn from 'classnames';

interface Props {
  ariaLabelledBy?: string;
  tabItems: DictionaryTabItemProps[];
}

const DictionaryTabStrip = ({ ariaLabelledBy, tabItems }: Props) => {
  return (
    <nav
      className="dictionary-letter-tabs-react"
      aria-labelledby={ariaLabelledBy}
    >
      <ol className="dictionary-letter-tabs-react__list">
        {tabItems.length &&
          tabItems.map((val, idx) => {
            const ariaCurrent = val.isSelected
              ? { 'aria-current': 'page' }
              : {};

            return (
              <li key={idx} className="dictionary-letter-tabs-react__list-item">
                {val.hasWords ? (
                  <a
                    {...ariaCurrent}
                    href={val.url}
                    className={cn(
                      'dictionary-letter-tabs-react__link dictionary-letter-tabs-react__link--has-words',
                      {
                        'dictionary-letter-tabs-react__link--is-selected':
                          val.isSelected,
                      }
                    )}
                  >
                    {val.text}
                  </a>
                ) : (
                  <span className="dictionary-letter-tabs-react__link dictionary-letter-tabs-react__link--has-no-words">
                    {val.text}
                  </span>
                )}
              </li>
            );
          })}
      </ol>
    </nav>
  );
};

export default DictionaryTabStrip;
