import React, { useEffect, useRef, useState } from 'react';
import './CalendarWrapper.scss';
import CalendarComponent from './CalendarComponent';
import cn from 'classnames';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';

interface CalenderProps {
  setValue: (e) => void;
  value: string;
  disabled?: boolean;
  className?: string;
  todayLabel: string;
  minDate: string;
  maxDate?: string;
  label: string;
  id: string;
  language: string;
}

const CalendarWrapper = ({
  value,
  setValue,
  disabled,
  className,
  todayLabel,
  minDate,
  maxDate,
  label,
  id,
  language,
}: CalenderProps) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [displayedValue, setDisplayedValue] = useState(todayLabel ?? 'I dag');

  const ref = useRef(null);
  const isMounted = useComponentDidMount();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarOpen &&
        ref.current &&
        (!ref.current.contains(event.target) || ref.current === event.target)
      ) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, calendarOpen]);

  useEffect(() => {
    if (isMounted && value) {
      setCalendarOpen(false);
      if (isToday(new Date(value))) {
        setDisplayedValue(todayLabel);
      } else {
        setDisplayedValue(
          new Date(value).toLocaleDateString('nb-NO', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        );
      }
    }
  }, [value, isMounted]);

  return (
    <div className={cn('calendar-wrapper', className)}>
      <label className="dropdown-filter-wrapper-react__label" htmlFor={id}>
        {label}
      </label>
      <div ref={ref}>
        <button
          aria-label={
            language === 'no'
              ? `Åpne "${label}" kalender`
              : `Open "${label}" calendar`
          }
          aria-haspopup="dialog"
          aria-expanded={calendarOpen}
          id={id}
          className="calendar-toggle-react"
          onClick={(e) => {
            setCalendarOpen(!calendarOpen);
            e.stopPropagation();
          }}
          disabled={disabled}
        >
          <span className="calendar-react__toggle-text">{displayedValue}</span>
        </button>
        {calendarOpen && (
          <CalendarComponent
            language={language}
            minDate={minDate}
            maxDate={maxDate}
            setValue={setValue}
            value={value}
          />
        )}
      </div>
    </div>
  );
};

function isToday(dateInput: Date): boolean {
  const today = new Date(); // Get the current date and time
  const inputDate = new Date(dateInput); // Ensure the input is treated as a Date object

  return (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  );
}
export default CalendarWrapper;
