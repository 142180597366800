import React from 'react';
import './LiveMeetingInformationBlockTypeTopTextBox.scss';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useSelector } from 'react-redux';
import mixins from './mixins';
import LiveMeetingInformationBlockTypeTopTextBoxMainUrl from './LiveMeetingInformationBlockTypeTopTextBoxMainUrl';
import LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees from './LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees';
import LiveMeetingInformationBlockTypeTopTextBoxLinkList from './LiveMeetingInformationBlockTypeTopTextBoxLinkList';
import LiveMeetingInformationBlockTypeTopTextBoxBottomButtons from './LiveMeetingInformationBlockTypeTopTextBoxBottomButtons';

const LiveMeetingInformationBlockTypeTopTextBox = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);

  const dateTitle = mixins().currentDateTitle(cmsData, liveData);
  const mainTitle = mixins().currentMainTitle(cmsData, liveData);
  const mainText = mixins().mainText(cmsData, liveData);
  const hearingLingerText = mixins().hearingLingerText(cmsData, liveData);
  const showDate = () => dateTitle !== undefined;
  const showMainText = () => mainText !== undefined;
  const showMainUrl = () => mixins().shouldShowMainUrl(cmsData, liveData);
  const showMeetingAttendees = () =>
    mixins().shouldShowMeetingAttendees(cmsData, liveData);
  const showHearingLingerText = () => hearingLingerText !== undefined;
  const showLinkList = () => mixins().shouldShowLinkList(cmsData, liveData);

  return (
    <div className="live-meeting-information-block-type-top-text-box-react">
      <div className="live-meeting-information-block-type-top-text-box-react__text">
        <div className="live-meeting-information-block-type-top-text-box-react__text__inner">
          {showDate() && (
            <p className="live-meeting-information-block-type-top-text-box-react__text__date">
              {dateTitle}
            </p>
          )}
          <h2 className="live-meeting-information-block-type-top-text-box-react__text__header">
            {mainTitle}
          </h2>
          {showMainUrl() && (
            <LiveMeetingInformationBlockTypeTopTextBoxMainUrl />
          )}
          {showMeetingAttendees() && (
            <LiveMeetingInformationBlockTypeTopTextBoxGovernmentAttendees />
          )}
          {showMainText() && (
            <div
              className="live-meeting-information-block-type-top-text-box-react__text__main-text"
              dangerouslySetInnerHTML={{ __html: mainText }}
            />
          )}
          {showHearingLingerText() && <p>{hearingLingerText}</p>}
          {showLinkList() && (
            <LiveMeetingInformationBlockTypeTopTextBoxLinkList />
          )}
        </div>
      </div>
      <LiveMeetingInformationBlockTypeTopTextBoxBottomButtons />
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopTextBoxWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopTextBox />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopTextBoxWrapper;
