import React, { useEffect } from 'react';
import './XmlImportForretningsorden.scss';
import Panel from '/Components/Atoms/Panel/Panel';
import Link from '/Components/Atoms/Link/Link';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { XmlImportForretningsordenProps } from '/Models/Generated/XmlImportForretningsordenProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import XmlImportForretningsordenHeader from '/Components/Organisms/XmlImportForretningsordenHeader/XmlImportForretningsordenHeader';
import XmlImportNavigation from '/Components/Organisms/XmlImportNavigation/XmlImportNavigation';
import MinutesPrevNext from '/Components/Atoms/MinutesPrevNext/MinutesPrevNext';

const XmlImportForretningsorden = ({
  showFrontpage,
  showContent,
  showAll,
  articleTitle,
  headerProps,
  navigationProps,
  showSearchNavigation,
  searchNavigationProps,
  incompletePanelProps,
  contentsLabel,
  tocItems,
  firstChapterLink,
  chapterHeaderId,
  chapterHeader,
  content,
  previousLink,
  nextLink,
}: XmlImportForretningsordenProps) => {
  const handleStaticContentExpanderClick = (e) => {
    e.preventDefault();
    const trigger = e.target.closest('a.block-extra-facts-trigger'); // in case of click on child element
    trigger.classList.toggle('active');
    trigger.ariaExpanded = trigger.classList.contains('active');

    const relatedExpander = document.querySelector(trigger.dataset.toggle);
    relatedExpander.classList.toggle(
      'open',
      trigger.classList.contains('active')
    );
  };

  const openStaticContentExpandersWithSearchMatches = () => {
    document.querySelectorAll('.strtngt_kommentar').forEach((el) => {
      if (el.querySelector('.bigdoc-highlight')) {
        el.querySelector('.block-extra-facts-trigger').classList.add('active');
        el.querySelector('.block-extra-facts-trigger').ariaExpanded = 'true';
        el.querySelector('.block-extra-facts-content').classList.add('open');
      }
    });
  };

  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }

    openStaticContentExpandersWithSearchMatches();

    // make expanders in static html work
    document
      .querySelectorAll('a.block-extra-facts-trigger')
      .forEach((el) =>
        el.addEventListener('click', handleStaticContentExpanderClick)
      );

    return () =>
      document
        .querySelectorAll('a.block-extra-facts-trigger')
        .forEach((el) =>
          el.removeEventListener('click', handleStaticContentExpanderClick)
        );
  }, []);

  return (
    <PageWrapper>
      <div className="xmlimportforretningsorden-react">
        {showFrontpage && (
          <Row horizontalPadding={HorizontalPadding.normal} noPadding>
            <Row horizontalPadding={HorizontalPadding.indent}>
              <h1>{articleTitle}</h1>
            </Row>
            <Row
              horizontalPadding={HorizontalPadding.normal}
              verticalPaddingBottom={VerticalPadding.large}
            >
              <XmlImportForretningsordenHeader {...headerProps} />
            </Row>
            <Row horizontalPadding={HorizontalPadding.normal}>
              <XmlImportNavigation
                {...navigationProps}
                showSearchNavigation={showSearchNavigation}
                searchNavigationProps={searchNavigationProps}
              />
            </Row>
            <Row horizontalPadding={HorizontalPadding.normal}>
              {incompletePanelProps && (
                <Panel title={incompletePanelProps.title}>
                  <p>{incompletePanelProps.text}</p>
                </Panel>
              )}
            </Row>
            <Row
              horizontalPadding={HorizontalPadding.indent}
              verticalPaddingTop={VerticalPadding.xlarge}
            >
              <h2 className="xmlimportforretningsorden-react__page-title">
                {contentsLabel}
              </h2>
              {tocItems?.length > 0 && (
                <ul className="xmlimportforretningsorden-react__toc-list">
                  <>
                    {tocItems.map((item, idx) => (
                      <li key={idx}>
                        <Link href={item.href} text={item.text} />
                      </li>
                    ))}
                  </>
                </ul>
              )}
            </Row>
            {firstChapterLink && !showAll && (
              <MinutesPrevNext nextLink={firstChapterLink} />
            )}
          </Row>
        )}
        {showContent && (
          <Row horizontalPadding={HorizontalPadding.normal} noPadding>
            {!showAll && (
              <Row horizontalPadding={HorizontalPadding.normal}>
                <XmlImportNavigation
                  {...navigationProps}
                  showSearchNavigation={showSearchNavigation}
                  searchNavigationProps={searchNavigationProps}
                />
              </Row>
            )}
            <Row horizontalPadding={HorizontalPadding.normal}>
              {incompletePanelProps && (
                <Panel title={incompletePanelProps.title}>
                  <p>{incompletePanelProps.text}</p>
                </Panel>
              )}
            </Row>
            <Row
              horizontalPadding={HorizontalPadding.indent}
              verticalPaddingTop={VerticalPadding.xlarge}
            >
              {!showAll && (
                <div id={chapterHeaderId}>
                  <h1>{chapterHeader}</h1>
                </div>
              )}
              {content && (
                <div className="page-publication">
                  <div
                    className="bigdoc-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              )}
            </Row>
            <MinutesPrevNext previousLink={previousLink} nextLink={nextLink} />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default XmlImportForretningsorden;
