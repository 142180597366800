import React from 'react';
import './Relevance.scss';

interface Props {
  percent: number;
  label: string;
}

const Relevance = (props: Props) => (
  <div className="relevance-react">
    <b>{props.label}</b>
    <div className="relevance-background">
      <div style={{ width: 100 - props.percent + '%' }}></div>
    </div>
  </div>
);

export default Relevance;
