import React from 'react';
import './SpeakerListItemWrapper.scss';
import { MinutesInnleggProps } from '/Models/Generated/MinutesInnleggProps';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';
import Link, { LinkTheme } from '../../Atoms/Link/Link';

interface SpeakerListItemWrapperProps {
  label: string;
  person: MinutesInnleggProps;
}

const SpeakerListItemWrapper = ({
  person,
  label,
}: SpeakerListItemWrapperProps) => {
  return (
    <div className="speakerlist-item-wrapper-react">
      <div>
        <Link
          href={'#' + person.anchorId}
          text={person.navn}
          theme={LinkTheme.dark}
        />
        <span className="speakerlist-item-wrapper-react__party speakerlist-item-wrapper-react__party--mobile">
          {!!person.parti && person.parti}
        </span>
      </div>
      <span className="speakerlist-item-wrapper-react__party speakerlist-item-wrapper-react__party--desktop">
        {!!person.parti && person.parti}
      </span>
      {person.videoLink && (
        <IconLinkGeneric
          href={person.videoLink}
          text={person.timestamp}
          iconString="video"
          className="speakerlist-item-wrapper-react__video-link"
        />
      )}
      <span className="speakerlist-item-wrapper-react__label">{label}</span>
    </div>
  );
};
export default SpeakerListItemWrapper;
