import React, { useEffect, useRef } from 'react';
import './EPOSSakslisteFilter.scss';

import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import { DropDownProps } from '/Models/Generated/DropDownProps';

import Button from '/Components/Atoms/Button/Button';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';

import { EposSakslisteAsyncDataProps } from '/Models/Generated/EposSakslisteAsyncDataProps';

interface DropdownWrapperProps extends DropDownProps {
  selectedValue: string;
  setSelected: (value: string) => void;
  id: string;
}

interface TabsData {
  value: string;
  text: string;
  isSelected: boolean;
}

interface EPOSSakslisteFilterProps {
  id?: string;
  secondaryFilterId?: string;
  limitText: string;
  stortingSesjonFromDropDown: DropDownProps;
  fromPeriod: string;
  handleFromPeriodChange: (value: string) => void;
  stortingSesjonToDropDown: DropDownProps;
  toPeriod: string;
  handleToPeriodChange: (value: string) => void;
  sakStatusDropDown: DropDownProps;
  caseStatus: string;
  setCaseStatus: (value: string) => void;
  saksTypeDropDown: DropDownProps;
  caseType: string;
  setCaseType: (value: string) => void;
  publicationSaksGrunnlagDropDown: DropDownProps;
  publication;
  handlePublicationChange: (value: string) => void;
  setWithPushState: (value: string, fn: (value: string) => void) => void;
  documentNumberTextBoxProps;
  publicationNumber: string;
  secondaryPublicationNumber: string;
  handlePubNumEntry: (value: string) => void;
  submitButtonText: string;
  asyncState: EposSakslisteAsyncDataProps;
  tabsData: () => TabsData[];
  isTabsLoading: boolean;
  tab: string;
  setTab: (value: string) => void;
  month: string;
  setMonth: (value: string) => void;
  topic: string;
  setTopic: (value: string) => void;
  subTopic: string;
  setSubTopic: (value: string) => void;
  committee: string;
  setCommittee: (value: string) => void;
  party: string;
  setParty: (value: string) => void;
  district: string;
  setDistrict: (value: string) => void;
  representative: string;
  setRepresentative: (value: string) => void;
}

const DropdownWrapper = ({
  labelText,
  items,
  selectedValue,
  setSelected,
  enabled,
  id,
}: DropdownWrapperProps) => {
  return (
    <>
      {labelText && <label htmlFor={id}>{labelText}</label>}
      <Dropdown
        id={id}
        name={id}
        options={items}
        selectedValue={selectedValue}
        setSelected={setSelected}
        disabled={!enabled}
      />
    </>
  );
};

const EPOSSakslisteFilter = ({
  id,
  secondaryFilterId,
  limitText,
  stortingSesjonFromDropDown,
  fromPeriod,
  handleFromPeriodChange,
  stortingSesjonToDropDown,
  toPeriod,
  handleToPeriodChange,
  sakStatusDropDown,
  caseStatus,
  setCaseStatus,
  saksTypeDropDown,
  caseType,
  setCaseType,
  publicationSaksGrunnlagDropDown,
  publication,
  handlePublicationChange,
  setWithPushState,
  documentNumberTextBoxProps,
  publicationNumber,
  secondaryPublicationNumber,
  handlePubNumEntry,
  submitButtonText,
  asyncState,
  tabsData,
  isTabsLoading,
  tab,
  setTab,
  month,
  setMonth,
  topic,
  setTopic,
  subTopic,
  setSubTopic,
  committee,
  setCommittee,
  party,
  setParty,
  district,
  setDistrict,
  representative,
  setRepresentative,
}: EPOSSakslisteFilterProps) => {
  const pubNumInputRef = useRef(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const pubNum =
      !!publicationNumber && !!secondaryPublicationNumber
        ? `${publicationNumber}:${secondaryPublicationNumber}`
        : publicationNumber || secondaryPublicationNumber || '';
    if (pubNumInputRef.current) pubNumInputRef.current.value = pubNum || '';
  }, [publicationNumber, secondaryPublicationNumber]);

  return (
    <div className="epos-saksliste-filter-react" id={id}>
      <h2 className="epos-saksliste-filter-react__heading epos-saksliste-filter-react__hide-mobile">
        {limitText}
      </h2>
      <div className="epos-saksliste-filter-react__grid epos-saksliste-filter-react__grid--primary">
        <div>
          <DropdownWrapper
            id="from_period"
            {...stortingSesjonFromDropDown}
            selectedValue={fromPeriod}
            setSelected={(val) => handleFromPeriodChange(val)}
          />
        </div>
        <div>
          <DropdownWrapper
            id="to_period"
            {...stortingSesjonToDropDown}
            selectedValue={toPeriod}
            setSelected={(val) => handleToPeriodChange(val)}
          />
        </div>
        {[
          {
            ddProps: sakStatusDropDown,
            selectedValue: caseStatus,
            setSelected: setCaseStatus,
          },

          {
            ddProps: saksTypeDropDown,
            selectedValue: caseType,
            setSelected: setCaseType,
          },
        ].map((el, i) => (
          <div key={el.ddProps.labelText}>
            <DropdownWrapper
              id={'id_b' + i}
              {...el.ddProps}
              selectedValue={el.selectedValue}
              setSelected={(val) => setWithPushState(val, el.setSelected)}
            />
          </div>
        ))}
        <div key={publicationSaksGrunnlagDropDown.labelText}>
          <DropdownWrapper
            id={'id_publication'}
            {...publicationSaksGrunnlagDropDown}
            selectedValue={publication}
            setSelected={handlePublicationChange}
          />
        </div>
        <div>
          <label htmlFor="pub_num_input">
            {documentNumberTextBoxProps.labelText}
          </label>
          <div className="epos-saksliste-filter-react__form-group">
            <input
              type="text"
              id="pub_num_input"
              className="epos-saksliste-filter-react__text-input"
              ref={pubNumInputRef}
              defaultValue={publicationNumber}
              onKeyUp={(e) => {
                if (e.key === 'Enter')
                  handlePubNumEntry(pubNumInputRef.current.value);
              }}
            />
            <Button
              onClick={() => handlePubNumEntry(pubNumInputRef.current.value)}
              text={submitButtonText}
            />
          </div>
        </div>
      </div>
      <hr className="epos-saksliste-filter-react__divider" />
      <div className="epos-saksliste-filter-react__sub-filter">
        <h2>{asyncState.findSakByTabProps.heading}</h2>
        <div id={secondaryFilterId}></div>
        <div className="epos-saksliste-filter-react__hide-mobile">
          <TabButtonStrip>
            {tabsData().map((el) => (
              <TabButton
                key={el.value}
                selected={el.isSelected}
                onClick={() => {
                  if (el.value !== tab) setWithPushState(el.value, setTab);
                }}
                text={el.text}
              />
            ))}
          </TabButtonStrip>
        </div>
        <div className="epos-saksliste-filter-react__grid epos-saksliste-filter-react__hide-desktop">
          <Dropdown
            id={'tab_dropdown_on_mobile'}
            name={'tab_dropdown_on_mobile'}
            options={tabsData()}
            selectedValue={tab}
            setSelected={(val) => setWithPushState(val, setTab)}
          />
        </div>
        {!isTabsLoading && (
          <div className="epos-saksliste-filter-react__grid epos-saksliste-filter-react__grid--secondary">
            {asyncState.monthDropDown && (
              <div>
                {asyncState.monthDropDown.items && (
                  <DropdownWrapper
                    id="b_1"
                    {...asyncState.monthDropDown}
                    selectedValue={month}
                    setSelected={(val) => setWithPushState(val, setMonth)}
                  />
                )}
              </div>
            )}

            {asyncState.topicSelection &&
              asyncState.topicSelection.mainTopicDropDown && (
                <div>
                  <DropdownWrapper
                    id="b_2"
                    {...asyncState.topicSelection.mainTopicDropDown}
                    selectedValue={topic}
                    setSelected={(val) => setWithPushState(val, setTopic)}
                  />
                </div>
              )}

            {asyncState.topicSelection &&
              asyncState.topicSelection.subTopicDropDown && (
                <div>
                  <DropdownWrapper
                    id="b_3"
                    {...asyncState.topicSelection.subTopicDropDown}
                    selectedValue={subTopic}
                    setSelected={(val) => setWithPushState(val, setSubTopic)}
                    enabled={asyncState.topicSelection.subTopicDropDown.enabled}
                  />
                </div>
              )}

            {asyncState.committeeDropDown && (
              <div>
                <DropdownWrapper
                  id="b_4"
                  {...asyncState.committeeDropDown}
                  selectedValue={committee}
                  setSelected={(val) => setWithPushState(val, setCommittee)}
                />
              </div>
            )}

            {asyncState.spokespersonSelection &&
              asyncState.spokespersonSelection.politicalPartyDropDown && (
                <div>
                  <DropdownWrapper
                    id="b_5"
                    {...asyncState.spokespersonSelection.politicalPartyDropDown}
                    selectedValue={party}
                    setSelected={setParty}
                  />
                </div>
              )}

            {asyncState.spokespersonSelection &&
              asyncState.spokespersonSelection.districtDropDown && (
                <div>
                  <DropdownWrapper
                    id="b_6"
                    {...asyncState.spokespersonSelection.districtDropDown}
                    selectedValue={district}
                    setSelected={(val) => setWithPushState(val, setDistrict)}
                  />
                </div>
              )}

            {asyncState.spokespersonSelection &&
              asyncState.spokespersonSelection.representativeDropDown && (
                <div>
                  <DropdownWrapper
                    id="b_7"
                    {...asyncState.spokespersonSelection.representativeDropDown}
                    selectedValue={representative}
                    setSelected={(val) =>
                      setWithPushState(val, setRepresentative)
                    }
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
export default EPOSSakslisteFilter;
