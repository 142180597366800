import React from 'react';
import './PageInfo.scss';
import { PageInfoProps } from '/Models/Generated/PageInfoProps';

const PageInfo = ({ updatePrefix, updateDate }: PageInfoProps) => (
  <div className="page-info-last-updated-react">
    {updateDate && (
      <span>
        {updatePrefix}&nbsp;
        {updateDate}
      </span>
    )}
  </div>
);

export default PageInfo;
