import React, { useEffect, useState } from 'react';
import './Partypage.scss';
import { PartypageProps } from '/Models/Generated/PartypageProps';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import MainImage from '/Components/Organisms/MainImage/MainImage';
import ContentArea, {
  HasContent,
} from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import useUrlParam from '/Shared/Code/Hooks/UseUrlParam';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import Radiobuttons from '/Components/Atoms/Radiobuttons/Radiobuttons';
import GreyRow from '/Components/Layout/GreyRow/GreyRow';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const Partypage = ({
  mainIntro,
  mainImageNew,
  mainImage,
  mainBody,
  threeColumnContentAreaInTop,
  membersViewTitle,
  membersViewOptionsTitle,
  membersViewOptions,
  parliamentPeriodDropDown,
  membersTable,
  emptyMembersListText,
  requestParameterNames,
  requestParameterValues,
  isHistorical,
}: PartypageProps) => {
  const filterIdFromProps = requestParameterValues.pageAnchorPrimaryFilter;
  const [hasMounted, setHasMounted] = useState(false);

  const [checked, setChecked] = useUrlParam(
    requestParameterNames.forceLevel,
    (window &&
      SSRQueryStringReader.Read(
        isHistorical ? '' : requestParameterNames.forceLevel
      )) ??
      null
  );

  const [pid, setPid] = useUrlParam(
    requestParameterNames.parliamentPeriodId,
    (window &&
      SSRQueryStringReader.Read(requestParameterNames.parliamentPeriodId)) ??
      null
  );

  const radioButtonsOptions = [
    {
      label: membersViewOptions.options[0].name,
      value: membersViewOptions.options[0].value,
      checked:
        SSRQueryStringReader.Read(requestParameterNames.forceLevel) ===
          'true' || !checked
          ? true
          : false,
      name: '',
    },
    {
      label: membersViewOptions.options[1].name,
      value: membersViewOptions.options[1].value,
      checked:
        SSRQueryStringReader.Read(requestParameterNames.forceLevel) === 'false'
          ? true
          : false,
      name: '',
    },
  ];

  const [checkedView, setCheckedView] = useState(radioButtonsOptions);

  useEffect(() => {
    if (!hasMounted) {
      const urlHashId = window?.location.hash?.substring(1);

      if (urlHashId && document.getElementById(urlHashId)) {
        document
          .getElementById(urlHashId)
          .closest('div')
          .querySelector('input')
          .focus();
        scrollToTarget(urlHashId);
      }

      return;
    }

    if (!isHistorical) {
      setChecked(
        checkedView.find((n) => n.checked)?.value ===
          membersViewOptions.options[0].value
      );
      checkedView.find((n) => n.checked)?.value ===
        membersViewOptions.options[1].value &&
        pid === null &&
        setPid(parliamentPeriodDropDown.items[0].value);
    }
    window.location.hash = filterIdFromProps;

    window.location.reload();
  }, [checkedView, pid]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <PageWrapper>
      <Row horizontalPadding={HorizontalPadding.indent}>
        {mainIntro && (
          <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        )}
      </Row>
      <div className="party-react__intro">
        {(mainImage || mainImageNew) && (
          <Row horizontalPadding={HorizontalPadding.normal}>
            {mainImage && <MainImage {...mainImage} />}
            {mainImageNew && <MainImage {...mainImageNew} />}
          </Row>
        )}
        <Row
          className="party-react__intro__content"
          horizontalPadding={HorizontalPadding.indent}
        >
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
      </div>
      {HasContent(threeColumnContentAreaInTop) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          verticalPaddingTop={VerticalPadding.normal}
          verticalPaddingBottom={VerticalPadding.xlarge}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...threeColumnContentAreaInTop} />
        </Row>
      )}
      <Row verticalPaddingBottom={VerticalPadding.large}>
        <GreyRow>
          <span id={filterIdFromProps}></span>
          <div className="party-react__filters">
            <h2>{membersViewTitle}</h2>
            {!isHistorical && (
              <Radiobuttons
                id={membersViewTitle}
                options={checkedView}
                setOptions={setCheckedView}
                label={membersViewOptionsTitle}
              />
            )}

            <DropDownFilterWrapper
              halfwidth={true}
              disableIfOnlyOneOption={
                isHistorical ? false : checkedView[0].checked
              }
              options={parliamentPeriodDropDown.items}
              selectedValue={
                pid ? pid : parliamentPeriodDropDown.items[0].value
              }
              disabled={isHistorical ? false : checkedView[0].checked}
              setSelected={setPid}
              id={parliamentPeriodDropDown.labelText}
              name={parliamentPeriodDropDown.labelText}
            />
          </div>
        </GreyRow>
      </Row>

      <Row horizontalPadding={HorizontalPadding.indent}>
        {membersTable ? (
          <SortableTable
            {...membersTable}
            doSort={null}
            setDoSort={null}
            setColumns={null}
            setRows={null}
            setSortColumn={null}
            setSortDirection={null}
            sortColumn={0}
            sortDirection={SortDirectionEnum.Disabled}
          />
        ) : (
          emptyMembersListText
        )}
      </Row>
    </PageWrapper>
  );
};

export default Partypage;
