import React, { FunctionComponent } from 'react';
import './PageWrapper.scss';

interface Props {
  children?: React.ReactNode;
}

const PageWrapper: FunctionComponent<Props> = (props) => {
  return <div className="page-wrapper-react">{props.children}</div>;
};

export default PageWrapper;
