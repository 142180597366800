import React from 'react';
import './ExternalUrls.scss';
import { ExternalUrlsProps } from '/Models/Generated/ExternalUrlsProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { IconLink } from '../../../App.Components';
import ColumnContentWrapper from '../../Layout/ColumnContentWrapper/ColumnContentWrapper';

const ExternalUrls = ({
  heading,
  headingPropertyName,
  externalUrls,
}: ExternalUrlsProps) => (
  <div>
    {externalUrls.length ? (
      <ColumnContentWrapper
        title={heading}
        titlePropertyName={headingPropertyName}
        customTag="aside"
      >
        {externalUrls &&
          externalUrls.map((val, idx) => (
            <div className="external-urls-list-react" key={idx}>
              {val.showRssIcon ? (
                <IconLink href={val.href} text={val.text} icon="rss-color" />
              ) : (
                <Link
                  href={val.href}
                  text={val.text}
                  theme={LinkTheme.standard}
                />
              )}
            </div>
          ))}
      </ColumnContentWrapper>
    ) : (
      <></>
    )}
  </div>
);

export default ExternalUrls;
