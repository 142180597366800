import React from 'react';
import './SiteFooter.scss';
import { SiteFooterProps } from '/Models/Generated/SiteFooterProps';
import Link from '/Components/Atoms/Link/Link';
import cn from 'classnames';

import IconLink from '/Components/Atoms/IconLink/IconLink';

const SiteFooter = (props: SiteFooterProps) => {
  return (
    <footer className="site-footer-react">
      <div className="site-footer-react__print">
        {props.address && (
          <div dangerouslySetInnerHTML={{ __html: props.address }}></div>
        )}
        {props.editorText && (
          <div
            className="contact-info-editor"
            dangerouslySetInnerHTML={{ __html: props.editorText }}
          ></div>
        )}
      </div>
      <div
        className={cn('site-footer-react-content', {
          'grief-mode': props.isGriefMode,
        })}
      >
        <h2 className="hidden-about-header">{props.hiddenAboutHeader}</h2>
        <div className="logo">
          <a className="logo-link" href={props.logoLink}>
            <img
              src={require('/Assets/Images/logo-white.svg')}
              alt={props.logoAltText}
            ></img>
          </a>
        </div>

        <div className="contact-info">
          {props.address && (
            <div dangerouslySetInnerHTML={{ __html: props.address }}></div>
          )}
          {props.contact && <Link {...props.contact} />}
          {props.editorText && (
            <>
              <br />
              <br />
              <div
                className="contact-info-editor"
                dangerouslySetInnerHTML={{ __html: props.editorText }}
              ></div>
            </>
          )}
        </div>

        <div className="site-links">
          {props.links && (
            <ul>
              {props.links.map((linkProps, idx) => (
                <li key={idx}>
                  <Link {...linkProps} whiteFocusOutline={true} />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="other-links">
          <ul className="social-links-footer">
            <li>
              <IconLink {...props.facebook} />
            </li>
            <li>
              <IconLink {...props.twitter} />
            </li>
            <li>
              <IconLink {...props.instagram} />
            </li>
            <li>
              <IconLink {...props.flickr} />
            </li>
          </ul>
          <ul className="calendar-links-footer">
            <li>
              <IconLink {...props.rss} />
            </li>
            <li>
              <IconLink {...props.calendar} />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
