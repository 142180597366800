export interface FlickrImage {
  flickrUrl: string;
  imgSrc: string;
  title: string;
  description?: string;
  id: string;
  secret: string;
  preloadedImage?: HTMLImageElement;
}

export default class FlickrClient {
  constructor(private apiKey: string) {}

  public Get(flickrSet: string, count: number): Promise<FlickrImage[]> {
    const url =
      'https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=' +
      this.apiKey +
      '&photoset_id=' +
      flickrSet +
      '&per_page=' +
      count +
      '&format=json&nojsoncallback=1';
    return fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const photos: FlickrImage[] = data.photoset?.photo?.map((item) => ({
          flickrUrl: `https://www.flickr.com/photos/stortinget/${item.id}/`,
          imgSrc: `https://farm${item.farm}.static.flickr.com/${item.server}/${item.id}_${item.secret}_z.jpg`,
          id: item.id,
          secret: item.secret,
          title: item.title,
        }));
        const promises = [];
        photos.forEach((item) => {
          const url = `https://api.flickr.com/services/rest/?&method=flickr.photos.getInfo&api_key=${this.apiKey}&photo_id=${item.id}&secret=${item.secret}&format=json&nojsoncallback=1`;
          promises.push(fetch(url).then((item) => item.json()));
        });
        return Promise.all(promises).then((photosDetails) => {
          photos.forEach((item) => {
            item.description = photosDetails.find(
              (detail) => detail.photo.id === item.id
            )?.photo?.description?._content;
          });
          return photos;
        });
      })
      .catch((err) => err);
  }
}
