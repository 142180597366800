import React from 'react';
import './QuestionListItem.scss';
import { QuestionListItemProps } from '/Models/Generated/QuestionListItemProps';

const QuestionListItem = ({
  answeredText,
  dated,
  documentTitle,
  pageLink,
  questionTitle,
  questioneerOnBehalfOf,
  rightfulAnswerer,
}: QuestionListItemProps) => (
  <article className="question-list-item-react">
    <h3 className="question-list-item-react__heading">
      <a
        href={pageLink.href}
        className="question-list-item-react__heading__link"
      >
        {pageLink.text}
      </a>
    </h3>
    {documentTitle && (
      <h4 className="question-list-item-react__subtitle">{documentTitle}</h4>
    )}
    {dated && <h4 className="question-list-item-react__subtitle">{dated}</h4>}
    {answeredText && (
      <h4 className="question-list-item-react__subtitle">{answeredText}</h4>
    )}
    {questioneerOnBehalfOf && (
      <h4 className="question-list-item-react__subtitle haha">
        {questioneerOnBehalfOf}
      </h4>
    )}
    {rightfulAnswerer && (
      <h4 className="question-list-item-react__subtitle">{rightfulAnswerer}</h4>
    )}
    {questionTitle && <p>{questionTitle}</p>}
  </article>
);

export default QuestionListItem;
