import React from 'react';
import './DetailsStatusTemplate.scss';
import Link from '/Components/Atoms/Link/Link';

import { EposSakDetailsStatusProps } from '/Models/Generated/EposSakDetailsStatusProps';
import SocialSharing from '/Components/Organisms/SocialSharing/SocialSharing';

const DetailsStatusTemplate = ({
  decisionInShort,
  handlingAndDecision,
  voting,
  handlingAndRecommendation,
}: EposSakDetailsStatusProps) => {
  // return <div className="details-status-template-react"></div>;
  return (
    <ul className="details-status-template-react">
      {decisionInShort && (
        <li>
          {decisionInShort.title && <h4>{decisionInShort.title}</h4>}
          <div className="details-status-template-react__content">
            <p>{decisionInShort.text}</p>
          </div>
          {decisionInShort.socialSharing && (
            <div>
              <SocialSharing
                tag="div"
                asLink
                useLargerLabel
                {...decisionInShort.socialSharing}
              />
            </div>
          )}
        </li>
      )}
      {handlingAndDecision && (
        <li>
          {handlingAndDecision.title && <h4>{handlingAndDecision.title}</h4>}
          <div className="details-status-template-react__content">
            {handlingAndDecision.handlingFinishedText && (
              <p>{handlingAndDecision.handlingFinishedText}</p>
            )}
            {handlingAndDecision.decisionStatusText && (
              <p>{handlingAndDecision.decisionStatusText}</p>
            )}
            {handlingAndDecision.decisionsLink && (
              <p>
                <Link {...handlingAndDecision.decisionsLink} />
              </p>
            )}
          </div>
        </li>
      )}
      {voting && (
        <li>
          <h4>{voting.heading}</h4>
          <div className="details-status-template-react__content">
            <p>
              {voting.proceedingVotingPageLink1Prefix}
              {voting.proceedingVotingPageLink1Prefix && <br />}
              <Link
                href={voting.proceedingVotingPageLink1.href}
                text={voting.proceedingVotingPageLink1.text}
                className="icon icon-vote icon-left"
              />
            </p>
            {voting.proceedingVotingPageLink2 && (
              <p>
                {voting.proceedingVotingPageLink2Prefix}
                {voting.proceedingVotingPageLink2Prefix && <br />}
                <Link
                  href={voting.proceedingVotingPageLink2.href}
                  text={voting.proceedingVotingPageLink2.text}
                  className="icon icon-vote icon-left"
                />
              </p>
            )}
          </div>
        </li>
      )}
      {handlingAndRecommendation && (
        <li>
          {handlingAndRecommendation.title && (
            <>
              <h4>{handlingAndRecommendation.title}</h4>
              <div className="details-status-template-react__content">
                {handlingAndRecommendation.recommendationGivenDateText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        handlingAndRecommendation.recommendationGivenDateText,
                    }}
                  />
                )}
                {handlingAndRecommendation.stortingHandlingText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: handlingAndRecommendation.stortingHandlingText,
                    }}
                  />
                )}
                {handlingAndRecommendation.odelstingHandlingText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: handlingAndRecommendation.odelstingHandlingText,
                    }}
                  />
                )}
                {handlingAndRecommendation.lagtingHandlingText && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: handlingAndRecommendation.lagtingHandlingText,
                    }}
                  />
                )}
              </div>
            </>
          )}
        </li>
      )}
    </ul>
  );
};
export default DetailsStatusTemplate;
