import React from 'react';
import Relevance from '/Components/Atoms/Relevance/Relevance';
import { Provider, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import './ResultHeader.scss';
import { Specifics } from '/Models/FrontendOnly/Search/Results';

interface Props {
  title?: string;
  prefix?: string;
  url?: string;
  relevance?: string;
  specifics?: Specifics;
}

const ResultHeader = ({ url, relevance, title, prefix, specifics }: Props) => {
  const labels = useSelector((state: RootState) => state.Search.labels);
  return (
    <div className="header-wrapper-react">
      <div className="header-wrapper-left">
        {title && url && (
          <h2>
            {prefix && <span>{prefix}</span>}
            <a href={url}>
              <span dangerouslySetInnerHTML={{ __html: title }}></span>
            </a>
          </h2>
        )}
        {specifics?.video_url && (
          <a className="video" href={specifics.video_url}>
            <img alt="video" />
          </a>
        )}
      </div>
      {Number.isInteger(relevance) && (
        <div className="relevance-wrapper-react">
          <Relevance
            label={labels.relevance}
            percent={Number.parseInt(relevance)}
          />
        </div>
      )}
    </div>
  );
};

const ResultHeaderWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultHeader {...props} />
  </Provider>
);

export default ResultHeaderWrapper;
