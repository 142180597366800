import React, { useEffect, useState } from 'react';
import './EPOSSakAvgivelsesfrister.scss';
import { EPOSSakAvgivelsesfristerProps } from '/Models/Generated/EPOSSakAvgivelsesfristerProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { PageWrapper } from '/App.Components';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import EPOSSakAvgivelsesfristerClient from '/Shared/Code/EPOSSakAvgivelsesfrister/EPOSSakAvgivelsesfristerClient';
import EPOSSakAvgivelsesfristerFilter from '/Components/Molecules/EPOSSakAvgivelsesfristerFilter/EPOSSakAvgivelsesfristerFilter';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const sortBys = ['RecommendationDueDate', 'Title', 'TentativeHandlingDate'];

const getSortByIndex = (sortBy: string) => sortBys.indexOf(sortBy);

const getSortByName = (idx: number) => {
  if (idx < 0 || idx >= sortBys.length) return null;
  return sortBys[idx];
};

export interface EPOSSakAvgivelsesfristerState {
  weekOfYear: string;
  committeeId: string;
  sortBy: string;
  sortByColumnIndex: number;
  sortDirection: string; // Decending/Ascending
  sortDirectionEnum: SortDirectionEnum;
  shouldSort: boolean;
}

const filterId = 'primaryfilter';

const getStateFromUrl = (): EPOSSakAvgivelsesfristerState => {
  const sb = SSRQueryStringReader.Read('sb') ?? '';
  const sbIdx = getSortByIndex(sb);
  const sd = SSRQueryStringReader.Read('sd') ?? '';
  const sdEnum = SortDirectionEnum[sd] ?? SortDirectionEnum.None;
  const shouldSort =
    sbIdx >= 0 &&
    (sdEnum === SortDirectionEnum.Ascending ||
      sdEnum === SortDirectionEnum.Descending);
  return {
    weekOfYear: SSRQueryStringReader.Read('yw') ?? '',
    committeeId: SSRQueryStringReader.Read('coid') ?? '',
    sortBy: sb,
    sortByColumnIndex: sbIdx,
    sortDirection: sd,
    sortDirectionEnum: sdEnum,
    shouldSort,
  };
};

const EPOSSakAvgivelsesfrister = ({
  subFwEposListProps,
  weekOfYearDropDownProps,
  committeeDropDownProps,
  asyncData,
  apiEndpoint,
  emptyDataSetText,
}: EPOSSakAvgivelsesfristerProps) => {
  const stateFromUrl = getStateFromUrl();
  const [weekOfYear, setWeekOfYear] = useState(
    weekOfYearDropDownProps.items?.filter((i) => i.isSelected)[0]?.value ??
      stateFromUrl.weekOfYear
  );
  const [weekDropDown, setWeekDropDown] = useState(weekOfYearDropDownProps);
  const [committeeDropDown, setCommitteeDropDown] = useState(
    committeeDropDownProps
  );
  const [committee, setCommittee] = useState(
    stateFromUrl.committeeId ||
      committeeDropDownProps?.items?.find((item) => item.isSelected)?.value ||
      committeeDropDownProps?.items[0]?.value
  );

  const [sortColumn, setSortColumn] = useState(stateFromUrl.sortByColumnIndex);
  const [sortDirection, setSortDirection] = useState(
    stateFromUrl.sortDirectionEnum
  );
  const [shouldPushState, setShouldPushState] = useState<boolean>(true);
  const [rows, setRows] = useState(asyncData?.tableData?.rows ?? []);
  const [columns, setColumns] = useState(asyncData?.tableData?.columns);
  const [doSort, setDoSort] = useState(stateFromUrl.shouldSort);
  const [inited, setInited] = useState(false);
  const client = new EPOSSakAvgivelsesfristerClient(apiEndpoint);

  const buildUrl = () => {
    const url = client.BuildFilterUrl(
      weekOfYear,
      committee,
      getSortByName(sortColumn),
      sortDirection
    );
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    if (!inited) {
      setInited(true);
      return;
    }
    if (shouldPushState) buildUrl();
    setShouldPushState(true);
  }, [sortColumn, sortDirection]);

  useEffect(() => {
    if (!inited) {
      setInited(true);
      return;
    }
    client.Get(weekOfYear, committee).then((res) => {
      setRows(res.items.tableData?.rows ?? []);
      setColumns(res.items.tableData?.columns ?? []);
      setWeekOfYear(
        res.weekOfYear.items.filter((i) => i.isSelected)[0]?.value ??
          res.weekOfYear.items[0].value
      );
      setWeekDropDown(res.weekOfYear);
      setCommittee(
        res.committees.items.filter((i) => i.isSelected)[0]?.value ??
          res.committees.items[0].value
      );
      setCommitteeDropDown(res.committees);
      if (shouldPushState) buildUrl();
      setShouldPushState(true);
      setDoSort(true);
      scrollToTarget(filterId);
    });
  }, [weekOfYear, committee]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const popstateHandler = () => {
      const state = getStateFromUrl();
      setShouldPushState(false);
      const newWeekOfYear =
        state.weekOfYear || weekOfYearDropDownProps?.items[0]?.value;
      const newCommitte =
        state.committeeId || committeeDropDownProps?.items[0]?.value;
      setWeekOfYear(newWeekOfYear);
      setCommittee(newCommitte);
      setSortColumn(state.sortByColumnIndex);
      setSortDirection(state.sortDirectionEnum);
      if (newCommitte === committee && newWeekOfYear === weekOfYear)
        setDoSort(true);
    };
    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, []);

  return (
    <PageWrapper>
      <div className="epos-sak-avgivelsesfrister-react">
        {subFwEposListProps.mainIntro && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <ContentArea
              {...subFwEposListProps.mainIntro}
              theme={RichTextTheme.MainIntro}
            />
          </Row>
        )}

        <div className="epos-sak-avgivelsesfrister-react__filter-container">
          {weekOfYearDropDownProps && (
            <Row
              horizontalPadding={HorizontalPadding.none}
              verticalPaddingBottom={VerticalPadding.large}
            >
              <EPOSSakAvgivelsesfristerFilter
                filterTitle={subFwEposListProps.limitText}
                filterId={filterId}
                weekOfYearDropDownProps={weekDropDown}
                committeeDropDownProps={committeeDropDown}
                useStateWeekOfYear={[weekOfYear, setWeekOfYear]}
                useStateCommittee={[committee, setCommittee]}
              />
            </Row>
          )}
        </div>

        <Row horizontalPadding={HorizontalPadding.indent}>
          {rows.length > 0 && (
            <SortableTable
              {...{
                rows,
                setRows,
                columns,
                setColumns,
                doSort,
                setDoSort,
                sortColumn,
                setSortColumn,
                sortDirection,
                setSortDirection,
              }}
            />
          )}
          {rows.length === 0 && !!emptyDataSetText && (
            <div className="epos-sak-avgivelsesfrister-react__no-results">
              {emptyDataSetText}
            </div>
          )}
        </Row>
      </div>
    </PageWrapper>
  );
};

export default EPOSSakAvgivelsesfrister;
