import React, { useEffect, useRef, useState } from 'react';
import './LiveMeetingInformationBlockTypeSliderContent.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import mixins from './mixins';
import consts from './consts';
import LiveMeetingInformationBlockTypeSliderItem from './LiveMeetingInformationBlockTypeSliderItem';
import LiveMeetingInformationBlockTypeSliderItemLink from './LiveMeetingInformationBlockTypeSliderItemLink';
import UseHorizontalScroll from '/Shared/Code/Hooks/UseHorizontalScroll';
import lang from './lang';
import classnames from 'classnames';

const LiveMeetingInformationBlockTypeSliderContent = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);
  const [scrollTo, setScrollTo] = useState(null as number);
  const [scrollable, setScrollable] = useState(false);
  const scrollLeftText = lang.scrollLeft[cmsData.lang];
  const scrollRightText = lang.scrollRight[cmsData.lang];
  const scrollableRef = useRef<HTMLElement>();
  const scrollStart = useRef(0);
  const scrollDistance = useRef(0);
  const allowClick = useRef(false);
  const { events } = UseHorizontalScroll(scrollableRef);
  const items = mixins().sliderItems(cmsData, liveData);
  const numberOfFinished = mixins().numberOfFinished(cmsData, liveData);

  const itemHasLink = (item) => item.videoLink && item.videoLink.length > 0;
  const itemIsCanceled = (item) => item.status === consts.status.canceled;

  const renderItem = (item, idx) => {
    if (itemIsCanceled(item)) {
      return <LiveMeetingInformationBlockTypeSliderItem {...item} key={idx} />;
    } else if (itemHasLink(item)) {
      return (
        <LiveMeetingInformationBlockTypeSliderItemLink {...item} key={idx} />
      );
    }
    return <LiveMeetingInformationBlockTypeSliderItem {...item} key={idx} />;
  };

  const scroller = () => scrollableRef.current;

  const smoothScroll = (scrollLeft: number) =>
    scroller().scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });

  useEffect(() => smoothScroll(400 * scrollTo), [scrollTo]);

  const configureScroll = () =>
    setScrollable(scroller().scrollWidth > scroller().clientWidth);

  useEffect(() => {
    const items = mixins().sliderItems(cmsData, liveData);
    if (numberOfFinished > 0 && numberOfFinished < items.length)
      setScrollTo(numberOfFinished);
    configureScroll();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', configureScroll);
    return () => window.removeEventListener('resize', configureScroll);
  }, []);

  const getScrollDistance = (scrollPos1: number, scrollPos2: number) =>
    Math.abs(scrollPos2 - scrollPos1);

  const scroll = (left: boolean) => {
    const scrollPosition = scroller().scrollLeft;
    const maxScroll = scroller().scrollWidth - scroller().clientWidth;
    let leftStop = Math.floor(scrollPosition / 400) * 400;
    let rightStop = Math.min(leftStop + 400, maxScroll);
    if (getScrollDistance(leftStop, scrollPosition) < 20) leftStop -= 400;
    if (getScrollDistance(rightStop, scrollPosition) < 20) rightStop += 400;
    const result = left ? leftStop : rightStop;
    smoothScroll(result);
  };

  useEffect(() => {
    const mouseUp = (e) => {
      if (scrollDistance.current < 10) {
        allowClick.current = true;
        const element = document.elementFromPoint(e.screenX, e.screenY);
        if (
          element &&
          element.closest(
            '.live-meeting-information-block-type-slider-content-react__tiles'
          )
        )
          element?.click();
      }
    };
    document.body.addEventListener('mouseup', mouseUp);
    return () => document.body.removeEventListener('mouseup', mouseUp);
  }, []);

  return (
    <div
      className={classnames(
        'live-meeting-information-block-type-slider-content-react',
        {
          'live-meeting-information-block-type-slider-content-react--scrollable':
            scrollable,
        }
      )}
    >
      <button
        className="live-meeting-information-block-type-slider-content-react__left-button"
        onClick={(e) => {
          e.preventDefault();
          scroll(true);
        }}
      >
        <span>{scrollLeftText}</span>
      </button>
      <ul
        className="live-meeting-information-block-type-slider-content-react__tiles"
        ref={scrollableRef}
        {...events}
        onScroll={() => {
          scrollDistance.current += Math.abs(
            scrollStart.current - scroller().scrollLeft
          );
          scrollStart.current = scroller().scrollLeft;
        }}
        onClick={(e) => {
          if (!allowClick.current) e.preventDefault();
          allowClick.current = false;
        }}
        onMouseDown={(e) => {
          scrollStart.current = scroller().scrollLeft;
          scrollDistance.current = 0;
          if (events.onMouseDown) events.onMouseDown(e);
        }}
      >
        {items && items.map((item, idx) => renderItem(item, idx))}
      </ul>
      <button
        className="live-meeting-information-block-type-slider-content-react__right-button"
        onClick={(e) => {
          e.preventDefault();
          scroll(false);
        }}
      >
        <span>{scrollRightText}</span>
      </button>
    </div>
  );
};

const LiveMeetingInformationBlockTypeSliderContentWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeSliderContent />
  </Provider>
);

export default LiveMeetingInformationBlockTypeSliderContentWrapper;
