import React from 'react';
import './EPOSVedtak.scss';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { PageWrapper } from '/App.Components';
import { EPOSVedtakProps } from '/Models/Generated/EPOSVedtakProps';
import cn from 'classnames';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';

const EPOSVedtak = ({
  proceedingTitle,
  proceedingFormalTitle,
  belongsToProceeding,
  hasH8Content,
  decisions,
}: EPOSVedtakProps) => {
  return (
    <PageWrapper>
      <div className="epos-vedtak-react">
        <Row horizontalPadding={HorizontalPadding.indent}>
          <h1>{proceedingTitle}</h1>
          <p className="epos-vedtak-react__formal-title">
            {proceedingFormalTitle}
          </p>
        </Row>
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove={true}
          lineBelow={true}
        >
          <ContentArea {...belongsToProceeding} />
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          {/* These two divs use classes from the old frontend */}
          <div
            className={cn({
              'epos-vedtak-react__bigdoc-content': hasH8Content,
              'page page-publication': hasH8Content,
              'epos-vedtak-react__article-content': !hasH8Content,
              article: !hasH8Content,
            })}
          >
            <div
              className={cn({
                'bigdoc-content': hasH8Content,
                'article-content': !hasH8Content,
              })}
            >
              {decisions &&
                decisions.map((val, idx) => (
                  <div key={idx}>
                    <h2>{val.heading}</h2>
                    <div dangerouslySetInnerHTML={{ __html: val.html }} />
                  </div>
                ))}
            </div>
          </div>
        </Row>
      </div>
    </PageWrapper>
  );
};

export default EPOSVedtak;
