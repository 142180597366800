import React from 'react';
import './ResultQuestion.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultKeywords from './Shared/ResultKeywords';
import ResultStatus from './Shared/ResultStatus';
import ResultPublished from './Shared/ResultPublished';
import ResultHeader from './Shared/ResultHeader';
import { Props } from '/Models/FrontendOnly/Search/Results';
import MoreResults from '/Components/Atoms/MoreResults/MoreResults';

const ResultQuestion = (props: Props) => {
  const {
    summary,
    relevance,
    url,
    published,
    specifics,
    highlighting,
    title,
    keywords,
  } = props;
  return (
    <article className="search-result-question-article-react">
      <ResultHeader url={url} title={title} relevance={relevance} />
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      <ResultSummary summary={summary} />
      <ResultHighlighting highlighting={highlighting} />
      <ResultKeywords keywords={keywords} />
      <MoreResults {...props} />
    </article>
  );
};

export default ResultQuestion;
