import React from 'react';
import './BlockWithTextForXhtml.scss';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { BlockWithTextForXhtmlProps } from '/Models/Generated/BlockWithTextForXhtmlProps';
import BlockWrapper, {
  BlockWrapperAlignment,
} from '/Components/Layout/BlockWrapper/BlockWrapper';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const BlockWithTextForXhtml = ({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  isInRichTextArea,
  title,
  titlePropertyName,
  mainBody,
  mainBodyPropertyName,
  alignment,
}: BlockWithTextForXhtmlProps) => {
  const blockAlignment = !isInRichTextArea
    ? BlockWrapperAlignment.None
    : alignment === 'Right'
    ? BlockWrapperAlignment.Right
    : BlockWrapperAlignment.Left;
  if (title || mainBody) {
    return (
      <BlockWrapper
        isInRichTextArea={isInRichTextArea}
        alignment={blockAlignment}
      >
        <div className={`block-with-text-for-xhtml-react `}>
          <h2
            className="block-with-text-for-xhtml-react__title"
            data-epi-edit={titlePropertyName}
          >
            {title}
          </h2>
          <ContentArea
            {...mainBody}
            propertyName={mainBodyPropertyName}
            theme={RichTextTheme.InfoBox}
          />
        </div>
      </BlockWrapper>
    );
  } else return <></>;
};

export default BlockWithTextForXhtml;
