import React, { useEffect, useState } from 'react';
import EuEosInfoListClient from '/Shared/Code/EuEosInfoList/EuEosInfoListClient';
import './EuEosInformasjonListeside.scss';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { EuEosInformasjonListesideProps } from '/Models/Generated/EUEOSinformasjonListesideProps';
import EuEosNewsFilter from '/Components/Molecules/EuEosNewsFilter/EuEosNewsFilter';
import EuEosNewsList from '/Components/Molecules/EuEosNewsList/EuEosNewsList';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import {
  setIsLoading,
  setList,
  setShouldPushState,
  setShowExpandButton,
  reloadState,
} from '/Shared/Code/EuEosNews/EuEosNewsSlice';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const primaryFilterId = 'primaryfilter';

const EuEosInformasjonListeside = ({
  mainIntro,
  mainBody,
  threeColumnContentAreaInTop,
  threeColumnContentAreaBottom,
  euEosInfoListProps,
  ddlYear,
  ddlYearTitle,
  ddlMonth,
  ddlMonthTitle,
  ddlCategory,
  ddlCategoryTitle,
  filterTitle,
  labelDisplaying,
  labelResults,
  labelResult,
  labelShowMore,
}: EuEosInformasjonListesideProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const {
    filterYear,
    filterMonth,
    filterCategory,
    showExpandButton,
    shouldPushState,
  } = useSelector((s: RootState) => s.EuEosNewsList);

  const dispatch = useDispatch();
  const client = new EuEosInfoListClient(euEosInfoListProps.endpoint);
  const defaultPageSize = 999999;
  const defaultPageNumber = 0; // not really in use?

  const buildUrl = (allowShowExpandButton = true) => {
    const url = client.BuildFilterUrl(
      filterYear,
      filterMonth,
      filterCategory,
      allowShowExpandButton && showExpandButton
    );

    if (shouldPushState) window.history.pushState({}, '', url);
    else dispatch(setShouldPushState(true));
  };

  const showAllFunction = () => {
    dispatch(setShowExpandButton(false));
    buildUrl(false);
  };

  useEffect(() => {
    if (!isMounted) return;
    dispatch(setIsLoading(true));
    client
      .Get(
        euEosInfoListProps.pageId,
        defaultPageSize,
        defaultPageNumber,
        +filterYear,
        +filterMonth,
        +filterCategory
      )
      .then((res) => {
        dispatch(setList(res));
        buildUrl(true);
        scrollToTarget(primaryFilterId);
      })
      .finally(() => dispatch(setIsLoading(false)));
  }, [filterYear, filterMonth, filterCategory]);

  useEffect(() => {
    if (euEosInfoListProps.infoList)
      dispatch(setList(euEosInfoListProps.infoList));
  }, []);

  useEffect(() => {
    window.onpopstate = () => dispatch(reloadState());
    setIsMounted(true);
  }, []);

  return (
    <div className="eu-eos-informasjon-listeside-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <ContentArea theme={RichTextTheme.MainIntro} {...mainIntro} />
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        <ContentArea {...mainBody} />
      </Row>
      <Row
        verticalPaddingBottom={VerticalPadding.large}
        horizontalPadding={HorizontalPadding.none}
      >
        <EuEosNewsFilter
          {...{
            ddlYear,
            ddlYearTitle,
            ddlMonth,
            ddlMonthTitle,
            ddlCategory,
            ddlCategoryTitle,
            filterTitle,
          }}
          filterId={primaryFilterId}
        />
      </Row>
      <Row
        horizontalPadding={HorizontalPadding.indent}
        verticalPaddingBottom={VerticalPadding.large}
      >
        <EuEosNewsList
          {...{
            labelDisplaying,
            labelResult,
            labelShowMore,
            labelResults,
            showAllFunction,
          }}
        />
      </Row>
      <Row horizontalPadding={HorizontalPadding.normal}>
        <ContentArea {...threeColumnContentAreaInTop} />
      </Row>
      <Row horizontalPadding={HorizontalPadding.normal} lineAbove={true}>
        <ContentArea {...threeColumnContentAreaBottom} />
      </Row>
    </div>
  );
};

const EuEosInformasjonListesideWrapper = (
  props: EuEosInformasjonListesideProps
) => (
  <Provider store={SearchStore}>
    <EuEosInformasjonListeside {...props} />
  </Provider>
);

export default EuEosInformasjonListesideWrapper;
