import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import './ColumnContentWrapper.scss';

interface Props {
  title?: string;
  titlePropertyName?: string;
  children?: React.ReactNode;
  customTag?: string;
}

const ColumnContentWrapper: FunctionComponent<Props> = (props) => {
  const Tag = props.customTag ?? 'div';
  return (
    <Tag className="column-content-wrapper-react">
      {props.title && (
        <h2
          data-epi-property-name={props.titlePropertyName}
          className={classNames({
            'epi-editContainer': props.titlePropertyName,
          })}
        >
          {props.title}
        </h2>
      )}
      {props.children}
    </Tag>
  );
};

export default ColumnContentWrapper;
