import React from 'react';
import './ResultCase.scss';
import ResultSummary from './Shared/ResultSummary';
import ResultHighlighting from './Shared/ResultHighlighting';
import ResultChilds from './Shared/ResultChilds';
import ResultKeywords from './Shared/ResultKeywords';
import ResultPublished from './Shared/ResultPublished';
import ResultStatus from './Shared/ResultStatus';
import ResultHeader from './Shared/ResultHeader';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultCase = ({
  summary,
  relevance,
  childs,
  highlighting,
  url,
  published,
  specifics,
  keywords,
  title,
}: Props) => {
  return (
    <article className="search-result-case-article-react">
      <ResultHeader url={url} title={title} relevance={relevance} />
      <ResultPublished published={published} />
      <ResultStatus status={specifics?.status} />
      <ResultSummary summary={summary} />
      <ResultHighlighting highlighting={highlighting} />
      <ResultKeywords keywords={keywords} />
      <ResultChilds childs={childs} showChilds={true} />
    </article>
  );
};

export default ResultCase;
