import React from 'react';
import './StatusOverviewTemplate.scss';
import { EposSakOverviewStatusProps } from '/Models/Generated/EposSakOverviewStatusProps';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const StatusOverviewTemplate = ({
  awaitsVotingText,
  committeeText,
  decisionInShortLink,
  fromText,
  parenthesisText,
  recommendationGivenDateText,
  recommendationLink,
  recommendationText,
}: EposSakOverviewStatusProps) => {
  const handleDecicionInShortLinkHandler = (e) => {
    const linkHash = e.target?.hash;
    if (!linkHash || !linkHash?.startsWith('#')) return;
    e.preventDefault();
    scrollToTarget(linkHash.slice(1));
  };

  return (
    <div className="status-overview-template-react">
      {fromText && (
        <span className="status-overview-template-react__text">{fromText}</span>
      )}
      {committeeText && (
        <span className="status-overview-template-react__text">
          {committeeText}
        </span>
      )}
      {parenthesisText && (
        <span className="status-overview-template-react__text">
          {parenthesisText}
        </span>
      )}
      {decisionInShortLink && (
        <div className="status-overview-template-react__internal-link">
          <a
            href={decisionInShortLink.href}
            className="status-overview-template-react__internal-link__a"
            onClick={handleDecicionInShortLinkHandler}
          >
            {decisionInShortLink.text}
          </a>
        </div>
      )}
      {awaitsVotingText && (
        <span className="status-overview-template-react__text">
          {awaitsVotingText}
        </span>
      )}

      {(recommendationText || recommendationLink) && (
        <>
          <span className="status-overview-template-react__date">
            {recommendationGivenDateText}
          </span>
          {recommendationText && (
            <span className="inline">{recommendationText}</span>
          )}
          {recommendationLink && (
            <a
              href={recommendationLink.href}
              className="status-overview-template-react__link icon icon-left icon-exclamation"
            >
              <span className="inline">{recommendationLink.text}</span>
            </a>
          )}
        </>
      )}
    </div>
  );
};
export default StatusOverviewTemplate;
