import React, { useState } from 'react';
import './ContentTargetGroup.scss';
import { ContentTargetGroupProps } from '/Models/Generated/ContentTargetGroupProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { focusInCurrentTarget } from '/Shared/Code/Helpers/FocusInCurrentTarget';
import cn from 'classnames';

const ContentTargetGroup = ({
  dropdownlistContentTargetGroupsLeadText,
  chosenTargetGroup,
  contentTargetGroups,
}: ContentTargetGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {chosenTargetGroup && (
        <div className="content-target-group-react">
          <img src={chosenTargetGroup.iconUrl} alt={''} />
          <div className="content-target-group-react__wrapper">
            <div className="content-target-group-react__wrapper__link">
              <Link
                href={chosenTargetGroup.pageUrl}
                text={chosenTargetGroup.leadText}
                theme={LinkTheme.standard}
              />
            </div>
            <div
              onBlur={(e) => {
                if (isOpen && !focusInCurrentTarget(e)) setIsOpen(false);
              }}
            >
              <button
                className={cn('content-target-group-button', {
                  'is-open-react': isOpen,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                }}
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-pressed={isOpen ? 'true' : 'false'}
                aria-controls="content-target-group-container"
              >
                <span>{dropdownlistContentTargetGroupsLeadText}</span>
              </button>
              <div
                id="content-target-group-container"
                className={cn('content-target-group-container', {
                  'content-target-group-container__is-open': isOpen,
                })}
              >
                <ul>
                  {contentTargetGroups &&
                    contentTargetGroups.map((val, idx) => (
                      <li key={idx}>
                        <a
                          href={val.pageUrl}
                          className="content-target-group-list-items"
                        >
                          {val.leadText}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentTargetGroup;
