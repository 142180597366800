import React, { useEffect } from 'react';
import './Referatforside.scss';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import { ReferatforsideProps } from '/Models/Generated/ReferatforsideProps';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import MinutesHeaderContainer from '/Components/Organisms/MinutesHeaderContainer/MinutesHeaderContainer';
import MinutesNavigationBar from '/Components/Organisms/MinutesNavigationBar/MinutesNavigationBar';
import MinutesHeader from '/Components/Organisms/MinutesHeader/MinutesHeader';
import DocumentContentList from '/Components/Organisms/DocumentContentList/DocumentContentList';

const Referatforside = ({
  minutesNavigationBar,
  minutesHeader,
  minutesProceedingList,
  mainBody,
}: ReferatforsideProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('pt=true') > -1) {
      setTimeout(function () {
        window.print();
      }, 0);
    }
  }, []);
  return (
    <PageWrapper>
      <div className="referat-forside-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <MinutesHeaderContainer>
            <MinutesHeader {...minutesHeader} />
            <MinutesNavigationBar {...minutesNavigationBar} />
          </MinutesHeaderContainer>
        </Row>
        <Row horizontalPadding={HorizontalPadding.indent}>
          <DocumentContentList {...minutesProceedingList} />
          {mainBody && <ContentArea {...mainBody} />}
        </Row>
      </div>
    </PageWrapper>
  );
};

export default Referatforside;
