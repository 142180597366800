import React, { useEffect, useState } from 'react';
import './ContentArea.scss';
import { ContentAreaProps } from '/Models/Generated/ContentAreaProps';
import * as Components from '/App.Components';
import cn from 'classnames';
import { ContentAreaType } from '/Models/Generated/ContentAreaType';
import Masonry from 'react-responsive-masonry';
import {
  RichTextTableExpander,
  RichTextTheme,
} from '/Components/Organisms/RichText/RichText';

interface Props extends ContentAreaProps {
  theme?: RichTextTheme;
  tableExpander?: RichTextTableExpander;
}

// propertyName only exists in on-page-editing mode in the CMS, and content areas must be visible in on-page-editing regardless of content
export const HasContent = (contentAreaProps: ContentAreaProps): boolean =>
  contentAreaProps?.items?.length > 0 ||
  contentAreaProps?.propertyName?.length > 0;

//TODO: When all blocks are implemented, finish the styling and replace ContentArea in WebForms with this component
const ContentArea = (props: Props) => {
  const [masonryCols, setMasonryCols] = useState(3);
  const UpdateMasonryCols = () => {
    if (window.matchMedia('print').matches) setMasonryCols(1);
    else if (window.innerWidth < 641) setMasonryCols(1);
    else if (window.innerWidth < 1024) setMasonryCols(2);
    else setMasonryCols(3);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', UpdateMasonryCols);
      UpdateMasonryCols();
    }
  }, []);

  const { propertyName, contentAreaType, items } = props;
  if (!HasContent(props)) return <></>;
  return (
    <div
      className={cn(
        'content-area-react',
        {
          'content-area-react__flex': [
            ContentAreaType.FullWidth,
            ContentAreaType.FourColumns,
            ContentAreaType.ThreeColumns,
            ContentAreaType.ThreeColumnsSameHeight,
            ContentAreaType.ThreeColumnsMasonry,
            ContentAreaType.TwoColumns,
          ].includes(contentAreaType),
        },
        {
          'content-area-react__full-width': [
            ContentAreaType.FullWidth,
          ].includes(contentAreaType),
        },
        { 'content-area-react__edit-placeholder': propertyName }
      )}
      data-epi-edit={propertyName}
      data-layout={
        contentAreaType === ContentAreaType.ThreeColumnsMasonry
          ? 'masonry'
          : null
      }
    >
      {contentAreaType === ContentAreaType.ThreeColumnsMasonry && items && (
        <Masonry columnsCount={masonryCols} gutter="40px">
          {items.map((item, idx) => {
            const Comp = Components[item.name];
            if (!Comp) return <div key={idx + item.name}>{item.name}</div>;
            if (item.name !== 'RichText')
              return <Comp key={idx} {...item.component} />;
            if (item.name === 'RichText')
              return (
                <Comp
                  theme={props.theme}
                  tableExpander={props.tableExpander}
                  {...item.component}
                  key={idx}
                />
              );
          })}
        </Masonry>
      )}
      {contentAreaType !== ContentAreaType.ThreeColumnsMasonry &&
        items &&
        items.map((item, idx) => {
          const Comp = Components[item.name];
          // if (!Comp) return <></>;
          if (!Comp) return <div key={idx + item.name}>{item.name}</div>;
          return (
            <div
              key={idx + item.name}
              className={cn(
                {
                  'one-fourth-width-react':
                    contentAreaType === ContentAreaType.FourColumns,
                },
                {
                  'one-third-width-react':
                    contentAreaType === ContentAreaType.ThreeColumns ||
                    contentAreaType === ContentAreaType.ThreeColumnsSameHeight,
                },
                {
                  'full-width-react':
                    contentAreaType === ContentAreaType.FullWidth,
                },
                {
                  'same-height-react':
                    contentAreaType === ContentAreaType.ThreeColumnsSameHeight,
                },
                {
                  'one-half-width-react':
                    contentAreaType === ContentAreaType.TwoColumns,
                }
              )}
            >
              {item.name === 'RichText' && (
                <Comp
                  theme={props.theme}
                  tableExpander={props.tableExpander}
                  {...item.component}
                />
              )}
              {item.name !== 'RichText' && <Comp {...item.component} />}
            </div>
          );
        })}
    </div>
  );
};

export default ContentArea;
