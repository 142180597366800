import React from 'react';
import './CardImage.scss';
import { CardImageProps } from '/Models/Generated/CardImageProps';

const CardImage = ({ src, altText }: CardImageProps) => {
  return (
    <figure className="card-image-react">
      <img src={src} alt={altText} className="card-image-react__img" />
    </figure>
  );
};

export default CardImage;
