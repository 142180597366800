import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import './ResultWrapper.scss';
import GreyRow, { GreyRowTheme } from '/Components/Layout/GreyRow/GreyRow';

export enum ResultTheme {
  normal,
  darkgrey,
}

interface Props {
  theme?: ResultTheme;
}

const ResultWrapper: FunctionComponent<Props> = (props) => (
  <GreyRow
    theme={
      props.theme === ResultTheme.darkgrey
        ? GreyRowTheme.default
        : GreyRowTheme.lightGrey
    }
  >
    <div className={cn('search-result-wrapper-react')}>{props.children}</div>
  </GreyRow>
);

export default ResultWrapper;
