import React from 'react';
import './LinkWithIcon.scss';
import { LinkProps } from '/Models/Generated/LinkProps';
import cn from 'classnames';

export enum Icon {
  Video,
  Download,
}

interface Props extends LinkProps {
  icon: Icon;
}

const LinkWithIcon = ({ text, href, icon }: Props) =>
  !!text ? (
    <div className="link-with-icon-react">
      <div
        className={cn('link-with-icon-react__container', {
          'link-with-icon-react__container--link': !!href,
          'link-with-icon-react__container--video': icon === Icon.Video,
          'link-with-icon-react__container--download': icon === Icon.Download,
        })}
      >
        {href && text && <a href={href}>{text}</a>}
        {!href && text && <span>{text}</span>}
      </div>
    </div>
  ) : (
    <></>
  );

export default LinkWithIcon;
