import React, { useState } from 'react';
import './EPOSRepresentant.scss';
import { EPOSRepresentantProps } from '/Models/Generated/EPOSRepresentantProps';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import GreyRow from '/Components/Layout/GreyRow/GreyRow';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import EPOSRepresentantContactHeader from './EPOSRepresentantContactHeader';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';
import TabButtonStrip from '/Components/Molecules/TabButtonStrip/TabButtonStrip';
import TabButton from '/Components/Atoms/TabButton/TabButton';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import cn from 'classnames';
import LinkButton, {
  LinkButtonTheme,
} from '/Components/Atoms/LinkButton/LinkButton';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const idUsedInUrlHashForScrollToFilter = 'secondaryfilter';

const EPOSRepresentant = ({
  //overrideHeadTitle, //TODO Set in <title></title>
  shortInfo,
  contactInfoAndBelongs,
  tabs,
  biographyContent,
  proceedingsAndPublicationsContent,
  // representantVarsling,
  englishInfo,
}: EPOSRepresentantProps) => {
  const [limitPropositionsList, setLimitPropositionsList] = useState(true);

  return (
    <PageWrapper>
      <div className="epos-representant-react">
        {/* Short Info */}
        {shortInfo && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <EPOSRepresentantContactHeader
              {...shortInfo}
              // representantVarsling={representantVarsling}
            />
          </Row>
        )}
        {/* Contact Info and Belongs */}
        {(contactInfoAndBelongs?.contactInfo ||
          contactInfoAndBelongs?.belongs) && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingBottom={VerticalPadding.medium}
          >
            <div className="epos-representant-react__contact-and-belongs">
              {/* Contact info */}
              {contactInfoAndBelongs.contactInfo && (
                <div>
                  <h2 className="epos-representant-react__contact-and-belongs__content-block">
                    {contactInfoAndBelongs.contactInfo.contactTitle}
                  </h2>
                  {contactInfoAndBelongs.contactInfo.email && (
                    <div className="epos-representant-react__contact-and-belongs__content-block">
                      <IconLinkGeneric
                        {...contactInfoAndBelongs.contactInfo.email}
                        iconString="email"
                      />
                    </div>
                  )}
                  <ContentArea
                    theme={RichTextTheme.ArticlePreview}
                    {...contactInfoAndBelongs.contactInfo.contactInfo}
                  />
                </div>
              )}
              {/* Belongs to committees, county, chamber etc */}
              {contactInfoAndBelongs.belongs && (
                <div>
                  <h2 className="epos-representant-react__contact-and-belongs__content-block">
                    {contactInfoAndBelongs.belongs.header}
                  </h2>
                  {contactInfoAndBelongs.belongs.items && (
                    <>
                      {contactInfoAndBelongs.belongs.items.map((item, key) => (
                        <div
                          className="epos-representant-react__contact-and-belongs__content-block"
                          key={key}
                        >
                          {item.groupLink && (
                            <>
                              {item.groupLink.isLink && (
                                <a href={item.groupLink.href}>
                                  {item.groupLink.text}
                                </a>
                              )}
                              {!item.groupLink.isLink && (
                                <div>{item.groupLink.text}</div>
                              )}
                            </>
                          )}
                          <p>
                            {item.personGroupMembershipRole}
                            <br />
                            {item.startDate}
                            {item.dateSpacer}
                            {item.endDate}
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </Row>
        )}
        {/* Tabs */}
        {tabs && (
          <Row>
            <GreyRow>
              <div
                id={idUsedInUrlHashForScrollToFilter}
                className="epos-representant-react__tabs-container"
              >
                <h2>{tabs.heading}</h2>
                <div className="epos-representant-react__tabs-container__inner">
                  {tabs.tabStrip?.tabItems?.length > 0 && (
                    <TabButtonStrip wrap>
                      {tabs.tabStrip.tabItems.map((item, index) => (
                        <TabButton
                          key={index}
                          selected={item.isSelected}
                          text={item.text}
                          href={item.url}
                        />
                      ))}
                    </TabButtonStrip>
                  )}
                  {tabs.linkToSearchPage && <Link {...tabs.linkToSearchPage} />}
                </div>
              </div>
            </GreyRow>
          </Row>
        )}
        {/* Biography content */}
        {biographyContent && (
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingTop={VerticalPadding.large}
          >
            <h2>{biographyContent.heading}</h2>
            {/* Personalia */}
            {biographyContent.personalia && (
              <>
                <h3>{biographyContent.personalia.heading}</h3>
                <ul className="epos-representant-react__plain-list">
                  {biographyContent.personalia.birthInfo && (
                    <li>{biographyContent.personalia.birthInfo}</li>
                  )}
                  {biographyContent.personalia.deathInfo && (
                    <li>{biographyContent.personalia.deathInfo}</li>
                  )}
                  {biographyContent.personalia.parentsInfo && (
                    <li>{biographyContent.personalia.parentsInfo}</li>
                  )}
                </ul>
              </>
            )}
            {/* Parliament periods */}
            {biographyContent.parliamentPeriods && (
              <>
                <h3>{biographyContent.parliamentPeriods.heading}</h3>
                <ul className="epos-representant-react__plain-list">
                  {biographyContent.parliamentPeriods.htmlTexts.map(
                    (item, index) => (
                      // Note: item could be html
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              </>
            )}
            {/* Parliament periods absent */}
            {biographyContent.parliamentPeriodsAbsent && (
              <>
                <h3>{biographyContent.parliamentPeriodsAbsent.heading}</h3>
                <ul className="epos-representant-react__plain-list">
                  {biographyContent.parliamentPeriodsAbsent.htmlTexts.map(
                    (item, index) => (
                      // Note: item could be html
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              </>
            )}
            {/* Memberships */}
            {/* Note: room for improvement here - the inner <ul></ul> seems to always have just one <li></li>
            in Prod. Could probably be restructured */}
            {biographyContent.memberships &&
              biographyContent.memberships.groups.map((group, index_1) => (
                <React.Fragment key={index_1}>
                  {group.heading && <h3>{group.heading}</h3>}
                  <ul className="epos-representant-react__plain-list">
                    {group.memberships.map((item, index_2) => (
                      <li key={index_2}>
                        {item.heading && (
                          <h4 className="epos-representant-react__h4">
                            {item.heading}
                          </h4>
                        )}
                        {item.htmlTexts.map((text, index_3) => (
                          <React.Fragment key={index_3}>
                            {/* Note: text could be html */}
                            {text}
                            {/* Note: <br/> moved from BE to FE */}
                            <br />
                          </React.Fragment>
                        ))}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))}
            {/* Education and Employment */}
            {biographyContent.educationAndEmployment && (
              <>
                <h3>{biographyContent.educationAndEmployment.heading}</h3>
                {biographyContent.educationAndEmployment.memberships.map(
                  (group, index) => (
                    <React.Fragment key={index}>
                      {group.heading && (
                        <h4 className="epos-representant-react__h4">
                          {group.heading}
                        </h4>
                      )}
                      <ul className="epos-representant-react__plain-list">
                        {group.htmlTexts.map((text, key2) => (
                          <li key={key2}>
                            {/* Note: text could be html */}
                            {text}
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  )
                )}
              </>
            )}
            {/* Verv */}
            {biographyContent.commissions && (
              <>
                <h3>{biographyContent.commissions.heading}</h3>
                {biographyContent.commissions.memberships.map(
                  (group, index) => (
                    <React.Fragment key={index}>
                      {group.heading && (
                        <h4 className=" epos-representant-react__h4">
                          {group.heading}
                        </h4>
                      )}
                      <ul className="epos-representant-react__plain-list">
                        {group.htmlTexts.map((text, key2) => (
                          <li
                            key={key2}
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        ))}
                      </ul>
                    </React.Fragment>
                  )
                )}
              </>
            )}
            {/* Literary Works*/}
            {biographyContent.literaryWorks && (
              <>
                <h3>{biographyContent.literaryWorks.heading}</h3>
                <ul className="epos-representant-react__plain-list">
                  {biographyContent.literaryWorks.htmlTexts.map(
                    (item, index) => (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    )
                  )}
                </ul>
              </>
            )}
          </Row>
        )}
        {/* Proceedings and publications */}
        {/* Note: There is inline script and style in this part */}
        {proceedingsAndPublicationsContent && (
          <>
            {proceedingsAndPublicationsContent?.propositions?.items?.length >
              0 && (
              <Row horizontalPadding={HorizontalPadding.indent}>
                <div className="epos-representant-react__list-heading-wrapper">
                  <GreyBar
                    text={proceedingsAndPublicationsContent.propositions.title}
                  />
                </div>
                <ul
                  id="representativepropositionslist"
                  className={cn('epos-representant-react__large-link-list', {
                    'epos-representant-react__large-link-list--limited':
                      limitPropositionsList,
                  })}
                >
                  {proceedingsAndPublicationsContent.propositions.items.map(
                    (item, key) => (
                      <li key={key}>
                        <Link {...item} theme={LinkTheme.dark} />
                      </li>
                    )
                  )}
                </ul>
                <p id="representativepropositionslistbutton">
                  {!limitPropositionsList ? (
                    <LinkButton
                      id="representativepropositionsshowless"
                      theme={LinkButtonTheme.sans}
                      text={
                        proceedingsAndPublicationsContent.propositions
                          .showLessLabel
                      }
                      onClick={() => {
                        setLimitPropositionsList(true);
                        scrollToTarget('representativepropositionslist');
                      }}
                    />
                  ) : (
                    <LinkButton
                      id="representativepropositionsshowall"
                      theme={LinkButtonTheme.sans}
                      text={
                        proceedingsAndPublicationsContent.propositions
                          .showAllLabel
                      }
                      onClick={() => {
                        setLimitPropositionsList(false);
                      }}
                    />
                  )}
                </p>
                {/* Note: empty text is never shown in Prod */}
                {/* {!proceedingsAndPublicationsContent.representativePropositions.items && (
                  <p>{proceedingsAndPublicationsContent.representativePropositions.emptyText}</p>
                )}
                {proceedingsAndPublicationsContent.representativePropositions.items.length === 0 && (
                  <p>{proceedingsAndPublicationsContent.representativePropositions.emptyText}</p>
                )} */}
              </Row>
            )}
            {proceedingsAndPublicationsContent.questions?.items?.length > 0 && (
              <Row horizontalPadding={HorizontalPadding.indent}>
                <div className="epos-representant-react__list-heading-wrapper">
                  <GreyBar
                    text={proceedingsAndPublicationsContent.questions.title}
                  />
                </div>
                <ul className="epos-representant-react__large-link-list">
                  {proceedingsAndPublicationsContent.questions.items.map(
                    (item, key) => (
                      <li key={key}>
                        <Link {...item} theme={LinkTheme.dark} />
                      </li>
                    )
                  )}
                </ul>
                <p>
                  <span style={{ display: 'block' }}>
                    <Link
                      {...proceedingsAndPublicationsContent.questions
                        .linkQuestionTimeQuestion}
                    />
                  </span>
                  <span style={{ display: 'block' }}>
                    <Link
                      {...proceedingsAndPublicationsContent.questions
                        .linkQuestionInterpellation}
                    />
                  </span>
                  <Link
                    {...proceedingsAndPublicationsContent.questions
                      .linkWrittenQuestion}
                  />
                </p>
              </Row>
            )}
            {proceedingsAndPublicationsContent.speakerForProceedings?.items
              ?.length > 0 && (
              <Row horizontalPadding={HorizontalPadding.indent}>
                <div className="epos-representant-react__list-heading-wrapper">
                  <GreyBar
                    text={
                      proceedingsAndPublicationsContent.speakerForProceedings
                        .title
                    }
                  />
                </div>
                <ul className="epos-representant-react__large-link-list">
                  {proceedingsAndPublicationsContent.speakerForProceedings.items.map(
                    (item, key) => (
                      <li key={key}>
                        <Link {...item.link} theme={LinkTheme.dark} />
                        <div className="epos-representant-react__large-link-list__listitem-type">
                          {item.infoText}
                        </div>
                      </li>
                    )
                  )}
                </ul>
                <p>
                  <Link
                    {...proceedingsAndPublicationsContent.speakerForProceedings
                      .linkSeeAll}
                  />
                </p>
              </Row>
            )}
          </>
        )}
        {/* English Bio */}
        {englishInfo && (
          <Row horizontalPadding={HorizontalPadding.indent}>
            <h2>{englishInfo.title}</h2>
            {englishInfo.biography && (
              <>
                <ContentArea {...englishInfo.biography} />
              </>
            )}
            {!englishInfo.biography && <>{englishInfo.noBioText}</>}
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSRepresentant;
