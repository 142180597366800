import React, { useEffect, useState } from 'react';
import './ArkivTVSideSocialSharing.scss';
import { ArkivTVSideSocialSharingProps } from '/Models/Generated/ArkivTVSideSocialSharingProps';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import Checkbox from '/Components/Atoms/Checkbox/Checkbox';
import { LinkProps } from '/Models/Generated/LinkProps';
import TextInput from '/Components/Atoms/TextInput/TextInput';
import BuildUrlWithParams from '/Shared/Code/UrlBuilder/BuildUrlWithParams';
import useInput from '/Shared/Code/Hooks/UseInput';
import InputWrapper, {
  InputWrapperProps,
} from '/Components/Molecules/InputWrapper/InputWrapper';
import GetErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';
import Button from '/Components/Atoms/Button/Button';
import LinkWithIcon, {
  Icon,
} from '/Components/Atoms/LinkWithIcon/LinkWithIcon';
import SSQQueryStringReader from '../../../Shared/Code/SSR/SSRQueryStringReader';

interface Props extends ArkivTVSideSocialSharingProps {
  shareUrl?: string;
  downloadLink?: LinkProps;
  downloadDescription?: string;
  position: string;
  positionSec: string;
  videoClipLink: LinkProps;
}

const buildWrapper = (
  inputWrapperProps: InputWrapperProps,
  value
): InputWrapperProps => {
  if (inputWrapperProps === null) return null;
  const backendErrorMessages = GetErrorMessages(
    { ...inputWrapperProps.inputProps, value: inputWrapperProps.value },
    false
  );
  const backendErrorMessage = backendErrorMessages.length
    ? backendErrorMessages[0]
    : null;
  return { ...inputWrapperProps, backendErrorMessage, value };
};

const ArkivTVSide = ({
  currentTimeLabel,
  emailShareLabel,
  facebookBaseUrl,
  facebookShareLabel,
  twitterBaseUrl,
  twitterShareLabel,
  header,
  shareUrl,
  downloadLink,
  downloadDescription,
  position,
  positionSec,
  asyncData,
  endpoint,
  submitText,
  pageId,
  pageLanguage,
  videoClipLink,
}: Props) => {
  const [startAtChecked, setStartAtChecked] = useState(false);
  const [localShareUrl, setLocalShareUrl] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [fromEmail, setFromEmail, setFromEmailVal] = useInput(
    asyncData.fromField
  );
  const [toEmail, setToEmail, setToEmailVal] = useInput(asyncData.toField);

  const Submit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append(fromEmail.inputProps.name, fromEmail.value);
    formData.append(toEmail.inputProps.name, toEmail.value);
    formData.append('url', localShareUrl);
    formData.append('pageId', pageId + '');
    formData.append('Language', pageLanguage);
    fetch(endpoint, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFromEmail(buildWrapper(result.fromField, fromEmail.value));
        setToEmail(buildWrapper(result.toField, toEmail.value));
        setSubmitDisabled(true);
        setStatusMessage(
          result.isSuccess ? result.successMessage : result.errorMessage
        );
      });
  };

  useEffect(
    () => setLocalShareUrl(BuildUrlWithParams(shareUrl, [], true, {})),
    [shareUrl]
  );

  useEffect(() => {
    if (startAtChecked) return;
    const params = {};
    const msidFromUrl = SSQQueryStringReader.Read('msid');
    if (!!msidFromUrl) params['msid'] = msidFromUrl;
    const newLocalShareUrl = BuildUrlWithParams(shareUrl, [], true, params);
    setLocalShareUrl(newLocalShareUrl);
  }, [startAtChecked]);

  useEffect(() => {
    if (!startAtChecked) return;
    if (!localShareUrl) return;
    const newLocalShareUrl = BuildUrlWithParams(localShareUrl, [], true, {
      msid: positionSec,
    });
    setLocalShareUrl(newLocalShareUrl);
  }, [positionSec, startAtChecked]);

  return (
    <article className="arkiv-tv-side-social-sharing-react">
      <Row horizontalPadding={HorizontalPadding.indent}>
        <h2>{header}</h2>
      </Row>
      <Row
        horizontalPadding={HorizontalPadding.none}
        verticalPaddingBottom={VerticalPadding.normal}
      >
        <div className="arkiv-tv-side-social-sharing-react__container">
          <div className="arkiv-tv-side-social-sharing-react__left">
            <textarea
              value={localShareUrl ?? ''}
              readOnly={true}
              aria-label="url"
              className="arkiv-tv-side-social-sharing-react__share-url"
            ></textarea>
            <div className="arkiv-tv-side-social-sharing-react__start-at">
              <Checkbox
                checked={startAtChecked}
                setChecked={(val) => {
                  setStartAtChecked(val);
                  setSubmitDisabled(false);
                }}
                text={currentTimeLabel}
              />
              <label
                className="arkiv-tv-side-social-sharing-react__start-at-label"
                htmlFor="arkiv-tv-side-social-sharing-react__position-box"
              >
                {currentTimeLabel}
              </label>
              <TextInput
                value={position}
                text={position}
                id="arkiv-tv-side-social-sharing-react__position-box"
                readonly={true}
              />
            </div>
          </div>
          <div className="arkiv-tv-side-social-sharing-react__right">
            <a
              className="arkiv-tv-side-social-sharing-react__icon arkiv-tv-side-social-sharing-react__twitter"
              aria-label={twitterShareLabel}
              href={twitterBaseUrl + encodeURIComponent(localShareUrl)}
            ></a>
            <a
              className="arkiv-tv-side-social-sharing-react__icon arkiv-tv-side-social-sharing-react__facebook"
              aria-label={facebookShareLabel}
              href={facebookBaseUrl + encodeURIComponent(localShareUrl)}
            ></a>
            <button
              className="arkiv-tv-side-social-sharing-react__icon arkiv-tv-side-social-sharing-react__mail"
              aria-label={emailShareLabel}
              onClick={() => setShowForm(!showForm)}
            ></button>
          </div>
        </div>
      </Row>
      {showForm && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.normal}
        >
          <div
            className="arkiv-tv-side-social-sharing-react__email-form"
            aria-live="polite"
          >
            <InputWrapper
              {...fromEmail}
              setValue={(val) => {
                setFromEmailVal(val);
                setSubmitDisabled(false);
              }}
            />
            <InputWrapper
              {...toEmail}
              setValue={(val) => {
                setToEmailVal(val);
                setSubmitDisabled(false);
              }}
            />
          </div>
          <div className="arkiv-tv-side-social-sharing-react__submit-row">
            <Button
              onClick={Submit}
              text={submitText}
              disabled={submitDisabled}
            />
            {!!statusMessage && <div>{statusMessage}</div>}
          </div>
        </Row>
      )}
      {(!!downloadLink || !!videoClipLink) && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.normal}
        >
          {downloadLink && (
            <div className="arkiv-tv-side-social-sharing-react__download">
              <LinkWithIcon {...downloadLink} icon={Icon.Download} />
              {downloadDescription}
            </div>
          )}
          {!!videoClipLink && (
            <LinkWithIcon {...videoClipLink} icon={Icon.Download} />
          )}
        </Row>
      )}
    </article>
  );
};

export default ArkivTVSide;
