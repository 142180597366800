import React from 'react';
import FocusItem from '/Components/Organisms/FocusItem/FocusItem';
import './FocusItemList.scss';
import { FocusItemProps } from '/Models/Generated/FocusItemProps';

interface Props {
  items?: FocusItemProps[];
  headerTag?: undefined | null | 'h2' | 'h3';
}

const FocusItemList = ({ items, headerTag }: Props) =>
  items ? (
    <ul className="focus-item-list-react">
      {items.map((item, idx) => (
        <li key={idx}>
          <FocusItem {...item} headerTag={headerTag} />
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

export default FocusItemList;
