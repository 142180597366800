import React from 'react';
import './PageTabsList.scss';
import cn from 'classnames';

interface PageTabsListProps {
  children: React.ReactNode;
}

const PageTabsList = ({ children }: PageTabsListProps) => {
  return <ul className="page-tabs-list-react">{children}</ul>;
};

export default PageTabsList;

interface PageTabProps {
  href: string;
  text: string;
  active?: boolean;
}

export const PageTab = ({ href, text, active }: PageTabProps) => {
  const id = href.substring(0, 1) === '#' ? href.substring(1) : null;
  return (
    <li
      id={id}
      className={cn('page-tabs-list-react__item', {
        'page-tabs-list-react__item--active': active,
      })}
    >
      <a href={href} className="page-tabs-list-react__link">
        {text}
      </a>
    </li>
  );
};
