import React from 'react';
import './Checkbox.scss';
import cn from 'classnames';

export interface Props {
  setChecked: (checked: boolean) => void;
  checked: boolean;
  text?: string;
  onBlur?: (e) => void;
  disabled?: boolean;
  greyedOut?: boolean;
  id?: string;
}

const Checkbox = ({
  setChecked,
  checked,
  text,
  onBlur,
  disabled,
  greyedOut,
  id,
}: Props) => {
  return text ? (
    <label className={cn('checkbox-label-react', { 'greyed-out': greyedOut })}>
      <input
        id={id}
        className="checkbox-react"
        type="checkbox"
        onChange={() => {
          setChecked && setChecked(!checked);
        }}
        checked={checked}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span
        className="checkbox-label-react__text"
        onBlur={onBlur}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </label>
  ) : (
    <input
      className="checkbox-react"
      type="checkbox"
      onChange={() => {
        setChecked && setChecked(!checked);
      }}
      checked={checked}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default Checkbox;
