import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { FocusItem2Props } from './FocusItem2Props';

export default class EPOSVedtaklisteClient {
  constructor(
    private endpoint: string,
    private pageId: number,
    private pageSize: number
  ) {}

  public Get(
    /** A period. E.g. "2022-2023". */
    period: string,
    /** A month. 1 is January, 2 is Febuary and so on. 0 means no filtering. */
    month: string,
    /** A decision type. Empty string means no filtering. */
    decisionType: string,
    /** The page of the result. The result skips page*pageSize and takes pageSize. */
    page: number
  ): Promise<FocusItem2Props> {
    if (typeof window === 'undefined') return Promise.resolve([]);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(period, month, decisionType, +page).then((res) =>
        UseProdUrls(res)
      );
    } else {
      return fetch(
        this.endpoint +
          `pageId=${this.pageId}&pageSize=${this.pageSize}&page=${page}&pid=${period}&m=${month}&decisionType=${decisionType}`,
        { headers: { Accept: 'application/json' } }
      ).then((res) => res.json());
    }
  }

  BuildFilterUrl = (
    period: string,
    month: number,
    decisionType: string,
    page: number
  ): string => {
    const params = [
      period ? 'pid=' + period : null,
      month ? 'm=' + month : null,
      decisionType ? 'dtid=' + decisionType : null,
      page ? 'page=' + page : null,
    ]
      .filter((val) => val !== null)
      .join('&');
    return window.location.href.replace(/\?.*/, '') + '?' + params;
  };

  private getMockData(
    period: string,
    month: string,
    decisionType: string,
    page: number
  ): Promise<FocusItem2Props> {
    const periodString = ['2020-2021', '2021-2022', '2022-2023'].includes(
      period
    )
      ? period
      : 'NotFound';

    return fetch(`/MockupAssets/MockData/EPOSVedtakliste${periodString}.json`)
      .then((res) => res.json())
      .then((result) => {
        const decisionTypes = [
          { text: 'Alle', value: 'all' },
          {
            text: 'Allminnelige vedtak',
            value: 'ANNET',
          },
          {
            text: 'Anmodninger',
            value: 'ANMOD',
          },
          {
            text: 'Budsjettvedtak',
            value: 'BUDSJETT',
          },
          {
            text: 'Grunnlovsvedtak',
            value: 'GRUNNLOV',
          },
          {
            text: 'Henstillinger',
            value: 'RO',
          },
        ];
        const res = result;

        if (month && month !== 'all')
          res.results = res.results.filter(
            (el) => +el.date.split('.')[1] === +month
          );

        if (decisionType && decisionType !== 'all')
          res.results = res.results.filter(
            (el) =>
              el.dateSuffix ===
              decisionTypes.find((el) => el.value === decisionType)?.text
          );

        const totalResultsCount = res.results.length;

        if (totalResultsCount > this.pageSize) {
          if (!page) res.results = res.results.slice(0, this.pageSize);
          else
            res.results = res.results.slice(
              (page - 1) * this.pageSize,
              page * this.pageSize
            );
        }

        res.totalPages = Math.ceil(totalResultsCount / this.pageSize);
        res.page = page;

        return res;
      })
      .catch(
        (err) => console.log('feil', err) // eslint-disable-line no-console
      );
  }
}
