import React from 'react';
import './EPOSDagsordenlisteFilter.scss';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import { DropDownProps } from '/Models/Generated/DropDownProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';

export interface EPOSDagsordenlisteFilterProps extends DropDownProps {
  filterTitle?: string;
  selectedSessionValue: string;
  selectedMeetingTypeValue: string;
  selectedSessionFunction: (string) => void;
  selectedMeetingTypeFunction: (string) => void;
  showMeetingTypesDropDown?: boolean;
  meetingTypesDropDown?: DropDownProps;
}

const EPOSDagsordenlisteFilter = ({
  items,
  labelText,
  filterTitle,
  selectedSessionValue,
  selectedMeetingTypeValue,
  selectedSessionFunction,
  selectedMeetingTypeFunction,
  showMeetingTypesDropDown,
  meetingTypesDropDown,
}: EPOSDagsordenlisteFilterProps) => {
  return (
    <ExpandableFilterWrapper heading={filterTitle}>
      <div className="epos-dagsordenliste-filter-react">
        <div>
          <p>{labelText}</p>
          <Dropdown
            id="epos_dagsordenliste_filter_dropdown_1"
            name="epos_dagsordenliste_filter_dropdown_1"
            options={items}
            selectedValue={selectedSessionValue}
            setSelected={(val) => selectedSessionFunction(val)}
          />
        </div>
        {showMeetingTypesDropDown && (
          <div>
            <p>{meetingTypesDropDown.labelText}</p>
            <Dropdown
              id="epos_dagsordenliste_filter_dropdown_2"
              name="epos_dagsordenliste_filter_dropdown_2"
              options={meetingTypesDropDown.items}
              selectedValue={selectedMeetingTypeValue}
              setSelected={(val) => selectedMeetingTypeFunction(val)}
            />
          </div>
        )}
      </div>
    </ExpandableFilterWrapper>
  );
};

export default EPOSDagsordenlisteFilter;
