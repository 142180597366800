import React, { useEffect, useState } from 'react';
import './PartyOverviewBlockType.scss';
import { PartyOverviewBlockTypeProps } from '/Models/Generated/PartyOverviewBlockTypeProps';
import BlockWrapper from '/Components/Layout/BlockWrapper/BlockWrapper';
import SectionWrapper from '/Components/Layout/SectionWrapper/SectionWrapper';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import PartyMembers from '/Components/Organisms/PartyMembers/PartyMembers';
import SSQQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import { EventNames } from '/Shared/Code/PushEvent/PushEvent';

const PartyOverviewBlockType = (props: PartyOverviewBlockTypeProps) => {
  const { isInRichTextArea, currentParliamentPeriod } = props;
  const [state, setState] = useState(props);
  const [periodIdState, setPeriodIdState] = useState(currentParliamentPeriod);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updatePeriodId = () => {
        const pid = SSQQueryStringReader.Read('pid');
        const newPid = pid ? pid : currentParliamentPeriod;
        setPeriodIdState(newPid);
      };
      const events = [EventNames.pushstate, 'popstate'];
      events.forEach((e) => window.addEventListener(e, updatePeriodId));
      return () =>
        events.forEach((e) => window.removeEventListener(e, updatePeriodId));
    }
  }, []);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    fetch(
      state.endpoint +
        '?blockId=' +
        state.blockId +
        '&parliamentPeriod=' +
        periodIdState +
        '&language=' +
        state.language
    )
      .then((res) => res.json())
      .then((res: PartyOverviewBlockTypeProps) => setState(res));
  }, [periodIdState]);

  return (
    <BlockWrapper isInRichTextArea={isInRichTextArea}>
      <SectionWrapper
        title={`${state.title} ${periodIdState}`}
        titlePropertyName={state.titlePropertyName}
      >
        <div className="party-overview-block-type-react">
          <PartyMembers {...state} />
          <div className="party-overview-block-type-react__link">
            {state.partyOverview && (
              <Link {...state.partyOverview} theme={LinkTheme.blocklink} />
            )}
          </div>
        </div>
      </SectionWrapper>
    </BlockWrapper>
  );
};

export default PartyOverviewBlockType;
