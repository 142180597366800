import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { PublikasjonerReferaterlisteAsyncDataProps } from 'Models/Generated/PublikasjonerReferaterlisteAsyncDataProps';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default class PublikasjonerReferaterlisteClient {
  constructor(private endpoint: string, private pageId: number) {}

  public Get(
    /**  period id */
    pid: string,
    /**  month */
    m: string,
    /**  minute type / "referattype" */
    mt: string
  ): Promise<PublikasjonerReferaterlisteAsyncDataProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(pid, m, mt).then((res) => UseProdUrls(res));
    } else {
      return fetch(
        this.endpoint + `?pid=${pid}&m=${m}&mt=${mt}&pageId=${this.pageId}`,
        {
          headers: { Accept: 'application/json' },
        }
      ).then((res) => res.json());
    }
  }

  BuildFilterUrl = (pid: string, m: string, mt: string): string => {
    const params = [
      pid ? `pid=${pid}` : null,
      m ? `m=${m}` : null,
      mt ? `mt=${mt}` : null,
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private getMockData(
    pid: string,
    m: string,
    mt: string
  ): Promise<PublikasjonerReferaterlisteAsyncDataProps> {
    return fetch(
      '/MockupAssets/MockData/PublikasjonerReferaterlisteAsyncData.json'
    )
      .then((res) => res.json())
      .then((result: PublikasjonerReferaterlisteAsyncDataProps) => {
        if (mt && mt !== 'all') {
          result.data.linkLists = result.data.linkLists.filter(
            (l) => l.title === mt
          );
        }

        if (pid && pid.includes('-')) {
          const period = pid.split('-');

          result.data.linkLists.forEach((linkList) => {
            const filteredLinkList = linkList.items.filter(
              (link) =>
                link.text.includes(period[0]) || link.text.includes(period[1])
            );

            linkList.items = filteredLinkList;
          });
        }

        if (m && m !== 'all') {
          const monthName = [
            'januar',
            'februar',
            'mars',
            'april',
            'mai',
            'juni',
            'juli',
            'august',
            'september',
            'oktober',
            'november',
            'desember',
          ][m - 1];

          result.data.linkLists.forEach((linkList) => {
            const filteredLinkList = linkList.items.filter((link) =>
              link.text.toLowerCase().includes(monthName)
            );

            linkList.items = filteredLinkList;
          });
        }

        result.data.linkLists = result.data.linkLists.filter(
          (linkList) => linkList.items.length > 0
        );
        return result;
      });
  }
}
