import React from 'react';
import ResultLookup from '../ResultLookup';
import { Props } from '/Models/FrontendOnly/Search/Results';

interface ResultChildsProps {
  childs: Array<Props>;
  showChilds?: boolean;
}

const ResultChilds = ({ childs, showChilds }: ResultChildsProps) => {
  if (showChilds && childs.length) {
    return (
      <ul>
        <li>
          {childs.map((child, index) => (
            <ResultLookup key={index} isChild={true} {...child} />
          ))}
        </li>
      </ul>
    );
  } else return <></>;
};

export default ResultChilds;
