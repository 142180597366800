import React from 'react';
import './TopMenuSectionContentCloseButton.scss';

interface TopMenuSectionContentCloseButtonProps {
  closeSection: () => void;
  closeLabel: string;
}

const TopMenuSectionContentCloseButton = (
  props: TopMenuSectionContentCloseButtonProps
) => {
  if (props)
    return (
      <div className="top-menu-section-content-close-button">
        <button
          onClick={(e) => {
            e.preventDefault();
            props.closeSection();
          }}
        >
          {props.closeLabel}
        </button>
      </div>
    );
  else return <></>;
};

export default TopMenuSectionContentCloseButton;
