import React, { useRef, useState } from 'react';
import './ResultSubscription.scss';
import { Provider, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { Props } from '/Models/FrontendOnly/Search/Results';
import SearchClient from '/Shared/Code/Search/SearchClient';
import StatusMessage, {
  StatusMessageTheme,
} from '/Components/Atoms/StatusMessage/StatusMessage';
import Button from '/Components/Atoms/Button/Button';
import { EmailValidator } from '../../../../../Shared/Code/Validation/EmailValidator';
import ValidationError from '../../../../Atoms/ValidationError/ValidationError';

const ResultSubscription = ({ specifics, title }: Props) => {
  const labels = useSelector((state: RootState) => state.Search.labels);
  const clientProps = useSelector(
    (state: RootState) => state.Search.searchClientProps
  );
  const [failed, setFailed] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const emailInput = useRef();
  const postsCheckbox = useRef();
  const suggestionsCheckbox = useRef();
  const questionsCheckbox = useRef();
  const client = new SearchClient(clientProps);
  return specifics ? (
    <>
      <form className="subscription-form-react">
        {succeeded && (
          <StatusMessage
            text={labels.subscriptionDone}
            theme={StatusMessageTheme.success}
          />
        )}
        {failed && (
          <StatusMessage
            text={labels.subscriptionFail}
            theme={StatusMessageTheme.warning}
          />
        )}
        <fieldset>
          <legend
            dangerouslySetInnerHTML={{
              __html: labels.subscriptionHeading + title,
            }}
          ></legend>
          <label>
            <span>{labels.subscriptionEmailLabel}</span>
            <input
              className="email-input-react"
              type="email"
              ref={emailInput}
              autoComplete="email"
            ></input>
            {!emailIsValid && (
              <ValidationError text={labels.subscriptionInvalidEmailMessage} />
            )}
          </label>
        </fieldset>
        <fieldset>
          <legend>{labels.subscriptionHeadingOptions}</legend>
          <label className="for-checkbox-react">
            <input
              type="checkbox"
              defaultChecked={true}
              aria-label={labels.postsLink}
              ref={postsCheckbox}
            ></input>
            <strong>{labels.postsLink}</strong>
          </label>
          <label className="for-checkbox-react">
            <input
              type="checkbox"
              defaultChecked={true}
              aria-label={labels.suggestionsLink}
              ref={suggestionsCheckbox}
            ></input>
            <strong>{labels.suggestionsLink}</strong>
          </label>
          <label className="for-checkbox-react">
            <input
              type="checkbox"
              defaultChecked={true}
              aria-label={labels.questionsLink}
              ref={questionsCheckbox}
            ></input>
            <strong>{labels.questionsLink}</strong>
          </label>
        </fieldset>
        <Button
          text={labels.subscriptionButton}
          onClick={(e) => {
            e.preventDefault();

            if (!EmailValidator.IsValidEmail(emailInput.current.value)) {
              setEmailIsValid(false);
              return;
            } else setEmailIsValid(true);

            const sections = specifics.sections;
            const email = emailInput.current.value;
            const postsId = postsCheckbox.current.checked
              ? sections.posts
              : null;
            const suggestionsId = suggestionsCheckbox.current.checked
              ? sections.suggestions
              : null;
            const questionsId = questionsCheckbox.current.checked
              ? sections.questions
              : null;
            client
              .Subscribe(email, title, postsId, suggestionsId, questionsId)
              .then((isSuccess) => {
                setSucceeded(isSuccess);
                setFailed(!isSuccess);
              });
          }}
        />
        <p dangerouslySetInnerHTML={{ __html: labels.subscriptionLegals }}></p>
      </form>
    </>
  ) : (
    <></>
  );
};

const ResultSubscriptionWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultSubscription {...props} />
  </Provider>
);

export default ResultSubscriptionWrapper;
