import Link from '/Components/Atoms/Link/Link';
import './VideoarkivHearingCellInfoContainer.scss';
import { VideoarkivHearingCellInfoContainerProps } from '/Models/Generated/VideoarkivHearingCellInfoContainerProps';
import React from 'react';

const VideoarkivHearingCellInfoContainer = ({
  cellInfos,
  title,
}: VideoarkivHearingCellInfoContainerProps) =>
  !!cellInfos?.length || !!title ? (
    <div className="videoarkiv-hearing-cell-info-container">
      {title}
      {!!cellInfos?.length &&
        cellInfos.map((info, idx) => (
          <div key={idx}>
            {info.videoLinkForHearing && <Link {...info.videoLinkForHearing} />}
            {info.noVideoLinkForHearingTitle}
            {!!info.noVideoLinkForHearingText && (
              <div>{info.noVideoLinkForHearingText}</div>
            )}
            {!!info.locationText && <div>{info.locationText}</div>}
          </div>
        ))}
    </div>
  ) : (
    <></>
  );

export default VideoarkivHearingCellInfoContainer;
