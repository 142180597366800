// (This component is very similar to XmlImportInnstillingHeader)
import React from 'react';
import './XmlImportForretningsordenHeader.scss';
import RelatedLinksBox from '/Components/Organisms/RelatedLinksBox/RelatedLinksBox';
import { XmlImportForretningsordenHeaderProps } from '/Models/Generated/XmlImportForretningsordenHeaderProps';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';

const XmlImportForretningsordenHeader = ({
  detteDokumentLabel,
  documentTitle,
  documentDateLabel,
  documentDate,
  publisherLabel,
  publisher,
  pdfLabel,
  pdf,
  pdfWithRemarksLabel,
  pdfWithRemarks,
  relatedProceeding,
  relatedTopics,
}: XmlImportForretningsordenHeaderProps) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    return timestamp.substr(0, 10).split('-').reverse().join('.');
  };
  return (
    <div className="xmlimportforretningsorden-header-react">
      <div className="xmlimportforretningsorden-header-react__inner">
        <ColumnContentWrapper title={detteDokumentLabel}>
          <div>{documentTitle}</div>
          {(documentDate || publisher) && (
            <ul className="xmlimportforretningsorden-header-react__document-list">
              <li>
                <b>{documentDateLabel}: </b>
                <time dateTime={formatDate(documentDate)}>
                  {formatDate(documentDate)}
                </time>
              </li>
              <li>
                <b>{publisherLabel}: </b>
                {publisher}
              </li>
            </ul>
          )}
          {(pdf || pdfWithRemarks) && (
            <ul className="xmlimportforretningsorden-header-react__document-list">
              {pdf && (
                <li>
                  <IconLinkGeneric
                    iconString="download"
                    href={pdf}
                    text={pdfLabel}
                  />
                </li>
              )}
              {pdfWithRemarks && (
                <li>
                  <IconLinkGeneric
                    iconString="download"
                    href={pdfWithRemarks}
                    text={pdfWithRemarksLabel}
                  />
                </li>
              )}
            </ul>
          )}
        </ColumnContentWrapper>
        <RelatedLinksBox {...relatedProceeding} />
        <RelatedLinksBox {...relatedTopics} />
      </div>
    </div>
  );
};

export default XmlImportForretningsordenHeader;
