import React from 'react';
import './SelectedFilters.scss';
import cn from 'classnames';
import { SearchCategoryProps } from '/Models/Generated/SearchCategoryProps';

interface SelectedFiltersProps {
  setActiveTab: (e) => void;
  setMobileSearchOpen: (e) => void;
  setSelectedFilters: (e) => void;
  selectedFilters: object;
  setSelectedDates: (e) => void;
  selectedDates: object;
  categories: SearchCategoryProps[];
  noChosenFiltersLabel: string;
  chosenFiltersLabel: string;
  clearFiltersLabel: string;
}

const SelectedFilters = ({
  setActiveTab,
  setMobileSearchOpen,
  setSelectedFilters,
  selectedFilters,
  setSelectedDates,
  selectedDates,
  categories,
  noChosenFiltersLabel,
  chosenFiltersLabel,
  clearFiltersLabel,
}: SelectedFiltersProps) => {
  const noFiltersChosen =
    Object.values(selectedFilters).every(
      (array) => Array.isArray(array) && array.length === 0
    ) &&
    selectedDates &&
    Object.values(selectedDates).every((value) => !value);

  return (
    <div className="selected-filters">
      <div className="selected-filters__label">
        {noFiltersChosen ? noChosenFiltersLabel : <b>{chosenFiltersLabel}</b>}
      </div>
      <div
        className={cn('selected-filters__buttons', {
          active: !noFiltersChosen,
        })}
      >
        {selectedFilters &&
          Object.keys(selectedFilters).map((category) =>
            selectedFilters[category].map((value) => (
              <button
                className="selected-filters__buttons__selected"
                key={value}
                onClick={() =>
                  setSelectedFilters((prevFilters) => ({
                    ...prevFilters,
                    [category]: prevFilters[category].filter(
                      (filter) => filter !== value
                    ),
                  }))
                }
              >
                {categories.reduce((foundLabel, category) => {
                  if (foundLabel) {
                    return foundLabel;
                  }
                  const choice = category.items
                    .filter((item) => item.choices)
                    .flatMap((item) => item.choices)
                    .find((choice) => choice.value === value);

                  return choice ? (
                    <>
                      {choice.label}{' '}
                      {choice.count >= 0 ? `(${choice.count})` : ''}
                      <span className="selected-filters__buttons__selected__icon"></span>
                    </>
                  ) : (
                    foundLabel
                  );
                }, '')}
              </button>
            ))
          )}
        {selectedDates &&
          selectedDates[categories[0].queryKeyFromDate] &&
          selectedDates[categories[0].queryKeyToDate] && (
            <button
              className="selected-filters__buttons__selected"
              key={'value'}
              onClick={() =>
                setSelectedDates({
                  [categories[0].queryKeyFromDate]: null,
                  [categories[0].queryKeyToDate]: null,
                })
              }
            >
              {selectedDates[categories[0].queryKeyFromDate].toString()} -{' '}
              {selectedDates[categories[0].queryKeyToDate].toString()}
              <span className="selected-filters__buttons__selected__icon"></span>
            </button>
          )}
        {selectedDates &&
          selectedDates[categories[0].queryKeyFromPeriod] &&
          selectedDates[categories[0].queryKeyToPeriod] && (
            <button
              className="selected-filters__buttons__selected"
              key={'value'}
              onClick={() =>
                setSelectedDates({
                  [categories[0].queryKeyFromPeriod]: null,
                  [categories[0].queryKeyToPeriod]: null,
                })
              }
            >
              {selectedDates[categories[0].queryKeyFromPeriod].toString()} -{' '}
              {selectedDates[categories[0].queryKeyToPeriod].toString()}
              <span className="selected-filters__buttons__selected__icon"></span>
            </button>
          )}
        {!noFiltersChosen && (
          <button
            className="selected-filters__buttons__remove"
            onClick={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'reset_filters',
              });
              if (setActiveTab) {
                setActiveTab('');
              }
              if (setMobileSearchOpen) {
                setMobileSearchOpen(false);
              }
              setSelectedFilters(
                Object.fromEntries(
                  Object.entries(selectedFilters).map(([key]) => [key, []])
                )
              );
              setSelectedDates(
                Object.fromEntries(
                  Object.entries(selectedDates).map(([key]) => [key, null])
                )
              );
            }}
          >
            {clearFiltersLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectedFilters;
