import React from 'react';
import './LiveMeetingInformationBlockTypeTopImageBox.scss';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import { Provider, useSelector } from 'react-redux';
import mixins from './mixins';
import LiveMeetingInformationBlockTypeTopImageBoxContent from './LiveMeetingInformationBlockTypeTopImageBoxContent';

const LiveMeetingInformationBlockTypeTopImageBox = () => {
  const { cmsData, liveData } = useSelector((s: RootState) => s.LiveBlock);
  const showLink = () => mixins().shouldShowImageLink(cmsData, liveData);
  const canceledHearing = () => mixins().canceledHearing(cmsData, liveData);
  const postponedHearing = () => mixins().postponedHearing(cmsData, liveData);

  return (
    <div className="live-meeting-information-block-type-top-image-box-react">
      {showLink() && (
        <a
          href={cmsData.mainBlock.imageLink}
          className="live-meeting-information-block-type-top-image-box-react__video-play-button"
        >
          <LiveMeetingInformationBlockTypeTopImageBoxContent
            isActive={true}
            isCanceled={canceledHearing()}
            isPostponed={postponedHearing()}
          />
        </a>
      )}
      {!showLink() && (
        <div className="live-meeting-information-block-type-top-image-box-react__no-link">
          <LiveMeetingInformationBlockTypeTopImageBoxContent
            isActive={false}
            isCanceled={canceledHearing()}
            isPostponed={postponedHearing()}
          />
        </div>
      )}
    </div>
  );
};

const LiveMeetingInformationBlockTypeTopImageBoxWrapper = () => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeTopImageBox />
  </Provider>
);

export default LiveMeetingInformationBlockTypeTopImageBoxWrapper;
