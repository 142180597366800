import React from 'react';
import Button, { ButtonTheme } from '/Components/Atoms/Button/Button';
import { SkjemaAkkrediteringAdminMetaDataProps } from '/Models/Generated/SkjemaAkkrediteringAdminMetaDataProps';

interface Props extends SkjemaAkkrediteringAdminMetaDataProps {
  setDone: (done: boolean) => void;
}

const SkjemaAkkrediteringDeleteApplication = ({
  applicationId,
  deleteProps,
  setDone,
}: Props) => {
  return (
    <div className="skjema-akkreditering-delete-application-react">
      <Button
        onClick={(e) => {
          e.preventDefault();
          if (!confirm(deleteProps.deleteConfirmationText)) return;
          fetch(deleteProps.postUrl, {
            method: 'POST',
            body: JSON.stringify({
              PageId: deleteProps.pageId + '',
              ApplicationId: applicationId,
              Language: deleteProps.language,
            }),
          }).then(() => setDone(true));
        }}
        text={deleteProps.buttonText}
        theme={ButtonTheme.Grey}
      />
    </div>
  );
};

export default SkjemaAkkrediteringDeleteApplication;
