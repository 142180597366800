import React from 'react';
import './EPOSDagsorden.scss';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import { EPOSDagsordenProps } from '/Models/Generated/EPOSDagsordenProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import cn from 'classnames';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import { ContentAreaType } from '/Models/Generated/ContentAreaType';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';

const EPOSDagsorden = ({
  threeColumnContentArea,
  eposCommentTitle,
  eposCommentText,
  meetingTitleText,
  meetingFootNoteText,
  meetingAgendaItemListResults,
  mainBody,
  mainIntro,
  pageName,
}: EPOSDagsordenProps) => {
  return (
    <PageWrapper>
      <div className="epos-dagsorden-react">
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={
            threeColumnContentArea.items.length !== 0
              ? VerticalPadding.small
              : VerticalPadding.none
          }
        >
          {pageName && <h1>{pageName}</h1>}
          {mainIntro && (
            <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
          )}
          {mainBody && <ContentArea {...mainBody} />}
          {meetingTitleText && <h2>{meetingTitleText}</h2>}
          {meetingFootNoteText && <h3>{meetingFootNoteText}</h3>}
          {eposCommentText && (
            <>
              <h3>{eposCommentTitle}</h3>
              <h3>{eposCommentText}</h3>
            </>
          )}
          <ul className="epos-dagsorden-react__results">
            {meetingAgendaItemListResults &&
              meetingAgendaItemListResults.meetingAgendaItemListResults.map(
                (val, idx) => (
                  <li
                    key={idx}
                    className="epos-dagsorden-react__results__items"
                  >
                    {val.presentationTitleText.href ? (
                      <Link
                        className="epos-dagsorden-react__results__items__link"
                        theme={LinkTheme.serif}
                        href={val.presentationTitleText.href}
                        text={val.presentationTitleText.text}
                      />
                    ) : (
                      <span className="epos-dagsorden-react__results__items__text">
                        {val.presentationTitleText.text}
                      </span>
                    )}
                    {val.presentationReferenceText && (
                      <p className="epos-dagsorden-react__results__items__text">
                        {val.presentationReferenceText}
                      </p>
                    )}
                    {val.suggestionsItemLink?.href && (
                      <div
                        className={cn(
                          'epos-dagsorden-react__results__items__wrapper',
                          {
                            'margin-big': !val.presentationReferenceText,
                          }
                        )}
                      >
                        <Link
                          className="epos-dagsorden-react__results__items__link"
                          theme={LinkTheme.serif}
                          href={val.suggestionsItemLink?.href}
                          text={val.suggestionsItemLink?.text}
                        />
                      </div>
                    )}
                  </li>
                )
              )}
          </ul>
        </Row>
        {threeColumnContentArea.items.length !== 0 && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            lineBelow={true}
            lineAbove={true}
            verticalPaddingTop={VerticalPadding.small}
          >
            <ContentArea
              {...threeColumnContentArea}
              contentAreaType={ContentAreaType.ThreeColumns}
            />
          </Row>
        )}
      </div>
    </PageWrapper>
  );
};

export default EPOSDagsorden;
