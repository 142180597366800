import React from 'react';
import './SkjemaAkkrediteringChangedBy.scss';
import { SkjemaAkkrediteringAdminMetaDataProps } from '/Models/Generated/SkjemaAkkrediteringAdminMetaDataProps';

const SkjemaAkkrediteringApplicationAdminTable = ({
  lastChangedByAuthor,
  lastChangedByLeadText,
  lastChangedDateTimeFormatted,
  registrationConfirmationSentLeadText,
  registrationConfirmationSentDateTimeFormatted,
}: SkjemaAkkrediteringAdminMetaDataProps) => {
  return (
    <div className="skjema-akkreditering-changed-by-react">
      <p className="skjema-akkreditering-changed-by-react__header">
        {lastChangedByLeadText}
      </p>
      <p>
        {lastChangedByAuthor}
        <br />
        {lastChangedDateTimeFormatted}
      </p>
      <p className="skjema-akkreditering-changed-by-react__header">
        {registrationConfirmationSentLeadText}
      </p>
      <p>{registrationConfirmationSentDateTimeFormatted}</p>
    </div>
  );
};

export default SkjemaAkkrediteringApplicationAdminTable;
