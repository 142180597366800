import React from 'react';
import './BreadCrumbs.scss';
import { BreadCrumbsProps } from '/Models/Generated/BreadCrumbsProps';
import BreadCrumb from './BreadCrumb';

const BreadCrumbs = (props: BreadCrumbsProps) => {
  if (props)
    return (
      <nav>
        <ul className="bread-crumbs-react">
          <li className="bread-crumbs-react__intro-text">{props.introText}:</li>

          {props.breadCrumbs &&
            props.breadCrumbs.map((breadCrumb, idx) => (
              <BreadCrumb key={idx} {...breadCrumb} />
            ))}
        </ul>
      </nav>
    );
  else return <></>;
};

export default BreadCrumbs;
