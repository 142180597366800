import React from 'react';
import './ProceedingStatusWrapper.scss';
import cn from 'classnames';

export enum ProceedingStatusWrapperTheme {
  normal = 'normal',
  standalone = 'standalone',
}

interface ProceedingStatusWrapperProps {
  children?: React.ReactNode;
  heading: string;
  id?: string;
  theme?: ProceedingStatusWrapperTheme;
  headingTag?: 'h2' | 'h3';
}

const ProceedingStatusWrapper = ({
  children,
  heading,
  id,
  theme,
  headingTag,
}: ProceedingStatusWrapperProps) => {
  // TODO: Consider choosing heading tag based on theme (if standalone use h2)
  // (Statebudget page has h3 in its standalone ProceedingStatusWrapper but it could arguably be an h2)
  const HTag = headingTag || 'h3';

  return (
    <div
      id={id}
      className={cn('proceeding-status-wrapper-react', {
        'proceeding-status-wrapper-react--theme-standalone':
          theme === ProceedingStatusWrapperTheme.standalone,
      })}
    >
      <HTag
        className="proceeding-status-wrapper-react__heading"
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      />
      <div className="proceeding-status-wrapper-react__content">{children}</div>
    </div>
  );
};

export default ProceedingStatusWrapper;
