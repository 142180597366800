import React from 'react';
import { RepresentativeSearchPersonProps } from '/Models/Generated/RepresentativeSearchPersonProps';
import './RepresentativeSearchApiResult.scss';
import { RepresentativeSearchResponseProps } from '/Models/Generated/RepresentativeSearchResponseProps';

const RepresentativeSearchApiResult = ({
  searchText,
  statusCode,
  statusText,
  numberOfHits,
  limit,
  index,
  showHistorical,
  resultList,
}: RepresentativeSearchResponseProps) => {
  return (
    <div className="representative-search-api-result-react">
      {searchText}
      <br />
      {statusCode}
      <br />
      {statusText}
      <br />
      {numberOfHits}
      <br />
      {limit}
      <br />
      {index}
      <br />
      showHistorical: {showHistorical}
      <hr />
      {resultList &&
        resultList.map((val: RepresentativeSearchPersonProps, idx) => (
          <div key={idx}>
            <img src={val.image} alt={val.name} />
            <br />
            {val.id}
            <br />
            {val.name}
            <br />
            {val.title}
            <br />
            {val.party}
            <br />
            {val.partyId}
            <br />
            {val.county}
            <br />
            {val.countyId}
            <br />
            {val.committeeMembership}
            <br />
            {val.committeeMembershipId}
            <br />
            {val.parliamentPeriods}
            <br />
            {val.seatingNumber}
            <br />
            isGovernmentMember: {val.isGovermentMember}
            <hr />
          </div>
        ))}
    </div>
  );
};

export default RepresentativeSearchApiResult;
