import React, { useState } from 'react';
import './GenericCard.scss';
import CardHeader from '../CardSubComponents/CardHeader/CardHeader';
import CardText from '../CardSubComponents/CardText/CardText';
import { GlobalSearchHitProps } from '/Models/Generated/GlobalSearchHitProps';
import ExpandButton from '../../../../Atoms/ExpandButton/ExpandButton';
import CardMetaLinksList from '../CardSubComponents/CardMetaLinksList/CardMetaLinksList';
import cn from 'classnames';
import CardRelatedTopics from '../CardSubComponents/CardRelatedTopics/CardRelatedTopics';
import CardRecommendendHit from '../CardSubComponents/CardRecommendendHit/CardRecommendendHit';

interface GenericCardProps extends GlobalSearchHitProps {
  useMobileMenu: boolean;
  showMoreLabelMobile: string;
  showLessLabelMobile: string;
  recommendedHitLabel: string;
  expandRelatedLinksButtonText: string;
  collapseRelatedLinksButtonText: string;
  showHitScore: boolean;
}

const GenericCard = ({
  cardHeaderProps,
  cardMetaLinks,
  cardTextProps,
  cardRelatedLinks,
  cardRelatedTopicLinks,
  hitScore,
  showHitScore,
  useMobileMenu,
  showLessLabelMobile,
  showMoreLabelMobile,
  expandRelatedLinksButtonText,
  collapseRelatedLinksButtonText,
  recommendedHitLabel,
}: GenericCardProps) => {
  const [buttonText, setButtonText] = useState(showMoreLabelMobile);
  const [mobileShown, setMobileShown] = useState(false);
  const [isHiddenAfterTransition, setIsHiddenAfterTransition] = useState(true);

  const onTransitionEnd = () => {
    if (!isHiddenAfterTransition) setIsHiddenAfterTransition(true);
  };

  return (
    <div
      data-card-type={cardHeaderProps.hitTypeLabel}
      className={cn('generic-card-react', {
        'mobile-open': mobileShown && useMobileMenu,
      })}
    >
      {showHitScore && hitScore && (
        <b className="generic-card-react__hitScore">Søkescore: {hitScore}</b>
      )}
      {cardHeaderProps.recommendedHit && recommendedHitLabel && (
        <div className="generic-card-react__reccomended">
          <CardRecommendendHit text={recommendedHitLabel} />
        </div>
      )}
      {cardHeaderProps && (
        <div className="generic-card-react__header">
          <CardHeader {...cardHeaderProps} />
        </div>
      )}
      {cardMetaLinks && (
        <div className="generic-card-react__meta-info">
          <CardMetaLinksList
            cardMetaLinks={cardMetaLinks}
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        </div>
      )}
      {cardTextProps && (
        <div className="generic-card-react__content">
          <CardText {...cardTextProps} />
        </div>
      )}
      {cardRelatedLinks && (
        <div
          className={cn('generic-card-react__links', {
            'hide-links': isHiddenAfterTransition && !mobileShown,
            'show-links': isHiddenAfterTransition && mobileShown,
          })}
          onTransitionEnd={
            (cardRelatedLinks && !cardRelatedTopicLinks && onTransitionEnd) ||
            (cardRelatedLinks && cardRelatedTopicLinks && onTransitionEnd)
          }
        >
          <CardMetaLinksList
            cardMetaLinks={cardRelatedLinks}
            largerGapOnLinks
            expandRelatedLinksButtonText={expandRelatedLinksButtonText}
            collapseRelatedLinksButtonText={collapseRelatedLinksButtonText}
          />
        </div>
      )}
      {cardRelatedTopicLinks && (
        <div
          className={cn('generic-card-react__related-topics', {
            'hide-links':
              isHiddenAfterTransition && !mobileShown && useMobileMenu,
            'show-links':
              isHiddenAfterTransition && mobileShown && useMobileMenu,
          })}
          onTransitionEnd={
            (cardRelatedTopicLinks && cardRelatedLinks && onTransitionEnd) ||
            (cardRelatedTopicLinks && !cardRelatedLinks && onTransitionEnd)
          }
        >
          <CardRelatedTopics
            relatedTopicsList={cardRelatedTopicLinks}
            useMobileMenu={useMobileMenu}
          />
        </div>
      )}
      {useMobileMenu && (
        <ExpandButton
          className="generic-card-react__mobile-links"
          type="SearchPage"
          text={buttonText}
          ariaControls={buttonText}
          isOpen={mobileShown}
          onClick={() => {
            if (mobileShown) {
              setButtonText(showMoreLabelMobile);
              setMobileShown(false);
            } else {
              setButtonText(showLessLabelMobile);
              setMobileShown(true);
            }
            if (isHiddenAfterTransition) {
              setIsHiddenAfterTransition(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default GenericCard;
