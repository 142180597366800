import React from 'react';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import './TopMenuLogoPrint.scss';
import Logo from '/Assets/Images/logo.svg';

const TopMenuLogoPrint = () => (
  <Row
    horizontalPadding={HorizontalPadding.normal}
    className="top-menu-logo-print-react"
  >
    <img src={Logo} alt="logo" />
  </Row>
);

export default TopMenuLogoPrint;
