import React from 'react';
import './RightLinks.scss';
import { RightLinksProps } from '/Models/Generated/RightLinksProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import ColumnContentWrapper from '../../Layout/ColumnContentWrapper/ColumnContentWrapper';

const RightLinks = ({ heading, links, linksPropertyName }: RightLinksProps) => {
  return (
    <ColumnContentWrapper title={heading}>
      {links && (
        <div data-epi-edit={linksPropertyName}>
          {links.map((val, idx) => (
            <div className="right-links-list-react" key={idx}>
              <Link
                href={val.href}
                text={val.text}
                theme={LinkTheme.standard}
              />
            </div>
          ))}
        </div>
      )}
    </ColumnContentWrapper>
  );
};

export default RightLinks;
