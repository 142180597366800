import React from 'react';
import './EPOSHvaskjerSporretime.scss';
import { EPOSHvaskjerSporretimeProps } from '../../../Models/Generated/EPOSHvaskjerSporretimeProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';
import { RichTextTheme } from '/Components/Organisms/RichText/RichText';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import CircleImage from '/Components/Atoms/CircleImage/CircleImage';

const EPOSHvaskjerSporretime = ({
  mainIntro,
  mainBody,
  mainImage,
  questionTimeMeetingControls,
  pageName,
}: EPOSHvaskjerSporretimeProps) => {
  return (
    <div className="epos-hva-skjer-sporretime-react">
      <Row
        horizontalPadding={HorizontalPadding.indent}
        noPaddingOnMobile={true}
      >
        {mainImage && (
          <div className="epos-hva-skjer-sporretime-react__main-image">
            <ImageWithCaption {...mainImage} />
          </div>
        )}
      </Row>
      <Row horizontalPadding={HorizontalPadding.indent}>
        <h1>{pageName}</h1>
        <ContentArea {...mainIntro} theme={RichTextTheme.MainIntro} />
        <ContentArea {...mainBody} theme={RichTextTheme.Standard} />

        {questionTimeMeetingControls.hasMeeting && (
          <h2 className="epos-hva-skjer-sporretime-react__title">
            <>
              {questionTimeMeetingControls.presentationQuestionTimeTitleText}{' '}
              {questionTimeMeetingControls.meetingTimeInfoText}
            </>
          </h2>
        )}
        {!questionTimeMeetingControls.hasMeeting ? (
          <div className="epos-hva-skjer-sporretime-react__no-sporretime">
            {questionTimeMeetingControls.noQuestionTimeMeetingText}
          </div>
        ) : (
          <>
            <div className="epos-hva-skjer-sporretime-react__link">
              <Link
                {...questionTimeMeetingControls.minutesLink}
                theme={LinkTheme.serif}
              />
            </div>
            <div className="epos-hva-skjer-sporretime-react__oral-meeting">
              <h3 className="epos-hva-skjer-sporretime-react__oral-meeting__title">
                {questionTimeMeetingControls.oralAskingHourTitle}
              </h3>
              {!questionTimeMeetingControls.oralQuestionTimeMeetingInfo && (
                <div className="epos-hva-skjer-sporretime-react__oral-meeting__list-title">
                  {
                    questionTimeMeetingControls.oralAskingHourUnknownParticipantsInformationText
                  }
                </div>
              )}
              {questionTimeMeetingControls.oralQuestionTimeMeetingInfo && (
                <>
                  <div className="epos-hva-skjer-sporretime-react__oral-meeting__list-title">
                    {
                      questionTimeMeetingControls.oralQuestionTimeMeetingInfo
                        .governmentMembersPresent
                    }
                  </div>
                  <ul className="epos-hva-skjer-sporretime-react__oral-meeting__list">
                    {questionTimeMeetingControls.oralQuestionTimeMeetingInfo.participants.map(
                      (items, itemId) => (
                        <li
                          key={itemId}
                          className="epos-hva-skjer-sporretime-react__oral-meeting__list__items"
                        >
                          <CircleImage
                            className="epos-hva-skjer-sporretime-react__oral-meeting__list__items__img"
                            src={items.smallImageUrl}
                            alt={items.smallImageAltText}
                            fixedWidth="50px"
                          />
                          <div className="epos-hva-skjer-sporretime-react__oral-meeting__list__items__person">
                            <div className="epos-hva-skjer-sporretime-react__oral-meeting__list__items__person__name">
                              {items.fullNameSurNameLast} ({items.partyId})
                            </div>
                            <div className="epos-hva-skjer-sporretime-react__oral-meeting__list__items__person__role">
                              {items.participantGovernementRole}
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </>
              )}
            </div>

            {questionTimeMeetingControls.ordinaryQuestionTimeMeetingInfo
              .ordinaryQuestionInfo.length !== 0 && (
              <div className="epos-hva-skjer-sporretime-react__ordinary-meeting">
                <h3 className="epos-hva-skjer-sporretime-react__ordinary-meeting__title">
                  {
                    questionTimeMeetingControls.ordinaryAskingHourInformationTitle
                  }
                </h3>
                <div className="epos-hva-skjer-sporretime-react__ordinary-meeting__info">
                  {
                    questionTimeMeetingControls.ordinaryAskingHourInformationText
                  }
                </div>
                <div className="epos-hva-skjer-sporretime-react__ordinary-meeting__questions">
                  {questionTimeMeetingControls.ordinaryQuestionTimeMeetingInfo
                    .deliveredQuestions + ':'}
                </div>
                <ul className="epos-hva-skjer-sporretime-react__ordinary-meeting__question-list">
                  {questionTimeMeetingControls.ordinaryQuestionTimeMeetingInfo.ordinaryQuestionInfo.map(
                    (items, itemId) => (
                      <li
                        key={itemId}
                        className="epos-hva-skjer-sporretime-react__ordinary-meeting__question-list__items"
                      >
                        <h3 className="epos-hva-skjer-sporretime-react__ordinary-meeting__question-list__items__title">
                          {items.presentationTitleText}
                        </h3>
                        <div
                          className="epos-hva-skjer-sporretime-react__ordinary-meeting__question-list__items__link"
                          dangerouslySetInnerHTML={{
                            __html: items.statusText,
                          }}
                        />
                        <div className="epos-hva-skjer-sporretime-react__ordinary-meeting__question-list__items__text">
                          {items.presentationQuestionTitleText}
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default EPOSHvaskjerSporretime;
