export default class ReferatsakClient {
  /*
    (Some code copied from old script.)
    Links in mainBody text with [data-action-start-video] need to be defined in frontend
    based on time offset(s) found in "hidden links".
  */
  private hiddenVideoUrls = [];
  private startTimes = [];
  constructor(private hiddenVideoHrefs: string[] = []) {
    if (typeof window === 'undefined') return;
    this.hiddenVideoUrls = hiddenVideoHrefs;
    hiddenVideoHrefs.forEach((href) => {
      const startTime = new URLSearchParams(href)?.get('rtid');
      if (startTime) this.startTimes.push(startTime);
    });
  }

  public GetTimeInSeconds(timeString: string): number {
    const hour = parseInt(timeString.substring(0, 2));
    const minute = parseInt(timeString.substring(2, 4));
    const second = parseInt(timeString.substring(4, 6));

    return 3600 * hour + 60 * minute + second;
  }

  public GetTimeOffset(startTime, newStartTime) {
    const start = this.GetTimeInSeconds(startTime);
    const newStart = this.GetTimeInSeconds(newStartTime);

    return newStart - start;
  }

  public TransformLink(link) {
    let secondCount, videoUrl;

    if (this.startTimes.length < 1) {
      link.classList.remove('icon', 'icon-left', 'icon-video');
      link.classList.add('ref-innlegg-video-disabled'); // defined in old css
    } else {
      const newStartTime = link
        .querySelector('span')
        ?.textContent?.match(/\d+/g)
        ?.join('');

      if (newStartTime) {
        for (let i = this.startTimes.length - 1; i >= 0; i--) {
          const offset = this.GetTimeOffset(this.startTimes[i], newStartTime);

          if (offset > 0) {
            secondCount = offset;
            videoUrl = this.hiddenVideoUrls[i];
            break;
          }
        }
      }
    }

    if (videoUrl) {
      const newUrl = new URL(videoUrl, 'https://localhost');
      newUrl.searchParams.delete('rtid');
      newUrl.searchParams.set('msid', secondCount);
      link.href = newUrl.pathname + newUrl.search;
    }

    return link;
  }

  public FormatTextWithLinks(htmlString: string) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    const actionElements: NodeListOf<HTMLAnchorElement> = div.querySelectorAll(
      '[data-action-start-video]'
    );
    actionElements.forEach((link) => this.TransformLink(link));

    return div.innerHTML;
  }
}
