import classNames from 'classnames';
import React from 'react';
import './TabButton.scss';

export interface Props {
  text: string;
  onClick?: (e) => void;
  href?: string;
  disabled?: boolean;
  selected?: boolean;
}

const Button = ({ onClick, text, href, disabled, selected }: Props) => {
  if (!text) return <></>;
  if (href)
    return (
      <a
        className={classNames('tab-button-react', {
          'tab-button-react--selected': selected,
        })}
        href={href}
      >
        {text}
      </a>
    );
  if (onClick)
    return (
      <button
        className={classNames('tab-button-react', {
          'tab-button-react--selected': selected,
        })}
        onClick={onClick}
        disabled={disabled}
        aria-selected={selected}
      >
        {text}
      </button>
    );
  return <></>;
};

export default Button;
