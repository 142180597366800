import { PortalsideHvaskjerApiCalendarPageProps } from '/Models/Generated/PortalsideHvaskjerApiCalendarPageProps';

export default class PortalsideHvaskjerCalendarClient {
  constructor(private endpoint: string, private language: string) {}

  public GetEvents = (
    /* week, month or future */
    pid: string,
    eventTypeFlags?: string[]
  ): Promise<PortalsideHvaskjerApiCalendarPageProps> => {
    const interval = !pid
      ? 'future'
      : pid.indexOf('W') > 0
      ? 'week'
      : pid.indexOf('M') > 0
      ? 'month'
      : 'future';
    const params = [
      'languageCode=' + this.language,
      'pid=' + (pid ?? 'all'),
      'interval=' + interval,
    ];
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.GetMockEvents(interval);
    }
    if (!!eventTypeFlags?.length)
      params.push('eventTypeFlags=' + eventTypeFlags.join(','));
    return fetch(this.endpoint + '?' + params.join('&')).then((res) =>
      res.json()
    );
  };

  public GetMockEvents = (
    interval: string
  ): Promise<PortalsideHvaskjerApiCalendarPageProps> =>
    fetch(
      `/MockupAssets/MockData/PortalsideHvaSkjerCalendarAsyncData${interval}.json`
    ).then((res) => res.json());
}
