import React from 'react';
import { BlockButtonTheme } from '../../../Models/Generated/BlockButtonTheme';
import { DecisionsInShortProps } from '../../../Models/Generated/DecisionsInShortProps';
import Link, { LinkTheme } from '../../Atoms/Link/Link';
import BlockButton from '../../Blocks/BlockButton/BlockButton';
import './DecisionsInShort.scss';

const DecisionsInShort = (decisionsInShort: DecisionsInShortProps) => {
  return (
    <div className="decisions-in-short">
      {decisionsInShort.titleLink && (
        <BlockButton
          href={decisionsInShort.titleLink.href}
          text={decisionsInShort.titleLink.text}
          theme={BlockButtonTheme.ThemeGreyBackgorundWithArrow}
          isInRichTextArea={true}
          epiFullRefreshProperties={null}
        />
      )}
      <ul>
        {decisionsInShort.decisionInShortLinkItems &&
          decisionsInShort.decisionInShortLinkItems.map((val, idx) => (
            <li key={idx}>
              <span>{val.status}</span>
              <Link
                href={val.href}
                text={val.text}
                theme={LinkTheme.standard}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DecisionsInShort;
