/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import './DateInput.scss';
import { ExtendedFormInputProps } from '/Models/FrontendOnly/FormInputs/ExtendedFormInputProps';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';

const DateInput = (props: ExtendedFormInputProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const onBlur = () => {
    const errorMessages = GetFormInputErrorMessages({
      ...props,
      value: inputEl.current.value,
    });

    if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
    else props.setErrorMessage('');
  };
  return (
    <input
      className="date-input-react"
      ref={inputEl}
      type="date"
      id={props.id}
      value={props.value}
      defaultValue={props.defaultValue}
      min={props.minDate}
      max={props.maxDate}
      onChange={props.onChange}
      disabled={props.disabled}
      onBlur={onBlur}
    />
  );
};

export default DateInput;
