import React from 'react';
import './EPOSSakVotering.scss';
import { EPOSSakVoteringProps } from '/Models/Generated/EPOSSakVoteringProps';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import EPOSSakVoteringResult from './EPOSSakVoteringResult';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import Panel from '/Components/Atoms/Panel/Panel';
import ProceedingStatusWrapper, {
  ProceedingStatusWrapperTheme,
} from '/Components/Molecules/ProceedingStatusWrapper/ProceedingStatusWrapper';

const EPOSSakVotering = ({
  votingHeader,
  infoPageHeader,
  votingInfoLinks,
  votingResultsHeader,
  votingResultsList,
  statusHeader,
  decisionInShortTitle,
  decisionInShortText,
  handlingAndDecisionTitle,
  handlingFinishedText,
  decisionStatusText,
  decisionLink,
}: EPOSSakVoteringProps) => {
  return (
    <PageWrapper>
      <Row horizontalPadding={HorizontalPadding.normal}>
        <div className="epos-sak-votering-react">
          {votingHeader && (
            <div className="epos-sak-votering-react__header">
              {votingHeader.proceedingTitleText && (
                <p className="epos-sak-votering-react__header__title-wrapper">
                  <span>{votingHeader.proceedingTitleText}</span>
                  <br></br>
                  <Link {...votingHeader.proceedingPageLink} />
                </p>
              )}

              <ul className="epos-sak-votering-react__header__list">
                {!!votingHeader.presidentList?.length && (
                  <li>
                    {votingHeader.presidentListPrefix}{' '}
                    {votingHeader.presidentList.map((val, idx) => (
                      <Link key={idx} {...val} />
                    ))}
                  </li>
                )}
                {(!!votingHeader.recommendationLink ||
                  !!votingHeader.proceedingLink) && (
                  <li>
                    {votingHeader.documentsText}
                    {votingHeader.recommendationLink && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: votingHeader.recommendationLink,
                        }}
                      />
                    )}
                    {votingHeader.recommendationAndProceedingLinkSeparator ??
                      ''}
                    {votingHeader.proceedingLink && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: votingHeader.proceedingLink,
                        }}
                      />
                    )}
                  </li>
                )}
                {!!votingHeader.minutesLinks?.length && (
                  <li>
                    {votingHeader.minutesPrefix}{' '}
                    {votingHeader.minutesLinks.map((val, idx) => (
                      <Link key={idx} {...val} />
                    ))}
                  </li>
                )}
              </ul>
              {votingHeader.eposCommentHeader && (
                <h3>{votingHeader.eposCommentHeader}</h3>
              )}
              {votingHeader.eposCommentText && (
                <div>{votingHeader.eposCommentText}</div>
              )}
            </div>
          )}

          {/* This box has toggle functionality in mobile and tablet */}
          {infoPageHeader && votingInfoLinks && (
            <Row
              verticalPaddingBottom={VerticalPadding.large}
              verticalPaddingTop={VerticalPadding.large}
            >
              <Panel title={infoPageHeader} toggleOnMobile={true}>
                <ul className="epos-sak-votering-react__vote-help">
                  {votingInfoLinks.map((val, idx) => (
                    <li key={idx}>
                      <Link theme={LinkTheme.darkSerif} key={idx} {...val} />
                    </li>
                  ))}
                </ul>
              </Panel>
            </Row>
          )}

          {!!votingResultsList?.length && (
            <>
              {votingResultsHeader && (
                <h2 className="epos-sak-votering-react__result-list-header">
                  {votingResultsHeader}
                </h2>
              )}
              <ol className="epos-sak-votering-react__results-list">
                {votingResultsList.map((val, idx) => (
                  // This listing looks like the listing in StateBudget, but differs enough to deserve a separate component
                  <li key={idx}>
                    <EPOSSakVoteringResult {...val} />
                  </li>
                ))}
              </ol>
            </>
          )}
          <ProceedingStatusWrapper
            heading={statusHeader}
            headingTag="h2"
            theme={ProceedingStatusWrapperTheme.standalone}
          >
            {/* <DetailsStatusTemplate {...details.status} /> */}
            {decisionInShortText && (
              <div>
                <h2>{decisionInShortTitle}</h2>
                <p>{decisionInShortText}</p>
              </div>
            )}
            {handlingAndDecisionTitle && (
              <div>
                <h2>{handlingAndDecisionTitle}</h2>
                {handlingFinishedText && <p>{handlingFinishedText}</p>}
                {decisionStatusText && <p>{decisionStatusText}</p>}
                {decisionLink && <Link {...decisionLink} />}
              </div>
            )}
          </ProceedingStatusWrapper>
        </div>
      </Row>
    </PageWrapper>
  );
};

export default EPOSSakVotering;
