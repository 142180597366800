import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '/Shared/Code/Search/SearchStore';
import './SearchResultCount.scss';
import { SearchResultsProps } from '/Models/Generated/SearchResultsProps';

const SearchResultCount = (props: SearchResultsProps) => {
  const getCountString = (from, to, count, props) =>
    count === 1
      ? `${props.showing} ${count} ${props.total}`
      : count && count <= 20
      ? `${props.showing} ${count} ${props.totals}`
      : from && to && count
      ? `${props.showing} ${from}-${to} ${props.of} ${count} ${props.totals}`
      : null;

  const results = useSelector((state: RootState) => state.Search.results);
  const from = results?.GetFrom();
  const to = results?.GetTo();
  const count = results?.GetTotalCount();
  const countString = getCountString(from, to, count, props);
  return countString ? (
    <div className="search-page-result-count">{countString}</div>
  ) : (
    <></>
  );
};

export default SearchResultCount;
