import React, { FunctionComponent, useEffect, useState } from 'react';
import './ExpandableFilterWrapperForLongDocument.scss';
import LinkButton from '/Components/Atoms/LinkButton/LinkButton';
import cn from 'classnames';

interface Props {
  heading: string;
  scrollId?: string;
  children?: React.ReactNode;
  initiallyExpanded?: boolean;
  showToTopButton: boolean;
  toTopButtonText: string;
}

const ExpandableFilterWrapperForLongDocument: FunctionComponent<Props> = ({
  heading,
  scrollId,
  children,
  initiallyExpanded,
  showToTopButton,
  toTopButtonText,
}) => {
  const [isOpen, setIsOpen] = useState(initiallyExpanded ?? false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    // Default behavior: closed, unless filter values are set.
    // Can be overridden by: initiallyExpanded
    // Should be the case on all pages with filter:
    //  - there are url param only when filter values are set
    if (typeof initiallyExpanded === 'boolean') return; // already set in useState
    const urlHasParams = /\?.*=/.test(window.location.search);
    setIsOpen(urlHasParams);
  }, []);

  return (
    <div className="expandable-filter-wrapper-for-long-document-react">
      <div className="expandable-filter-wrapper-for-long-document-react__heading-container">
        {scrollId && <a id={scrollId}></a>}
        <h2 className="expandable-filter-wrapper-for-long-document-react__heading">
          <button
            className="expandable-filter-wrapper-for-long-document-react__btn-expand"
            onClick={() => setIsOpen((current) => !current)}
            aria-controls="expandable-filter-wrapper-for-long-document-react__expander"
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <span
              className={cn(
                'expandable-filter-wrapper-for-long-document-react__btn-expand__text',
                {
                  'expandable-filter-wrapper-for-long-document-react__btn-expand__text--open':
                    isOpen,
                }
              )}
            >
              {heading}
            </span>
          </button>
        </h2>

        {showToTopButton && (
          <LinkButton
            text={toTopButtonText}
            onClick={() =>
              scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          />
        )}
      </div>
      <div
        id="expandable-filter-wrapper-for-long-document-react__expander"
        className={cn(
          'expandable-filter-wrapper-for-long-document-react__expander',
          {
            'expandable-filter-wrapper-for-long-document-react__expander--open':
              isOpen,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableFilterWrapperForLongDocument;
