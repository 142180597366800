export default class SSQQueryStringReader {
  public static ReadBool = (param: string, fallback: boolean): boolean =>
    this.Read(param)?.toLowerCase() === 'true'
      ? true
      : this.Read(param)?.toLowerCase() === 'false'
      ? false
      : fallback;

  public static Read = (param: string): string =>
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search).get(param)
      : null;

  public static ReadInt = (param: string, fallback: number) => {
    const val = Number.parseInt(this.Read(param));
    return Number.isNaN(val) ? fallback : val;
  };
}
