import React from 'react';
import './Spinner.scss';
import image from '/Assets/Images/loader.gif';

const Spinner = () => {
  return (
    <div className="spinner-react">
      <img src={image} />
    </div>
  );
};

export default Spinner;
