import React from 'react';
import './ResultHighlighting.scss';

interface Props {
  highlighting?: string;
}

const ResultHighlighting = ({ highlighting }: Props) => {
  return highlighting ? (
    <p className="result-highlighting">
      <q dangerouslySetInnerHTML={{ __html: highlighting }}></q>
    </p>
  ) : (
    <></>
  );
};

export default ResultHighlighting;
