import React from 'react';
import './TopMenuColumn.scss';
import { HierarchyNodeProps } from '/Models/Generated/HierarchyNodeProps';

const TopMenuColumn = (props: HierarchyNodeProps[]) => {
  if (props)
    return (
      <div className="top-menu-col">
        {Object.values(props).map((hierarchy, idx) => (
          <div key={idx} className="top-menu-col-link-group">
            {hierarchy.url && (
              <a
                href={hierarchy.url}
                target={hierarchy.target}
                className="top-menu-col-icon-link"
                lang={hierarchy.langAttribute || null}
              >
                {hierarchy.text}
              </a>
            )}
            {!hierarchy.url && <span>{hierarchy.text}</span>}
            {hierarchy.childNodes && (
              <ul>
                {hierarchy.childNodes.map((node, idx) => (
                  <li key={idx}>
                    <a
                      href={node.url}
                      target={node.target}
                      lang={node.langAttribute || null}
                    >
                      {node.text}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    );
  else return <></>;
};

export default TopMenuColumn;
