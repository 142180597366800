import React from 'react';
import './CircleImage.scss';

// uncomment to add sizes
// (and make corresponding changes in CircleImage.scss)
export enum CircleImageSizes {
  small = 'small',
  medium = 'medium',
  // large ='large',
  full = 'full',
}

export interface Props {
  size?: CircleImageSizes;
  fixedWidth?: string; // for use if you really don't want to use "size"
  src: string;
  alt: string;
  className?: string;
}

const CircleImage = ({
  size = CircleImageSizes.full,
  fixedWidth,
  src,
  alt,
  className,
}: Props) => {
  if (!src) return <></>;

  return (
    <img
      style={fixedWidth ? { width: fixedWidth, height: fixedWidth } : {}}
      src={src}
      alt={alt}
      className={`
      circle-image-react
      circle-image-react--size-${size}
      ${className || ''}`}
    />
  );
};

export default CircleImage;
