import React, { useEffect } from 'react';
import './EPOSSak.scss';
import Link from '/Components/Atoms/Link/Link';
import PageWrapper from '/Components/Layout/PageWrapper/PageWrapper';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import Column, { Width } from '/Components/Layout/Column/Column';
import { EPOSSakProps } from '/Models/Generated/EPOSSakProps';
import StatusOverviewTemplate from './StatusOverviewTemplate/StatusOverviewTemplate';
import ProceedingFlowList from '/Components/Atoms/ProceedingFlowList/ProceedingFlowList';
import EPOSSakFlowListContentTemplate from './EPOSSakFlowListContentTemplate/EPOSSakFlowListContentTemplate';
import DetailsStatusTemplate from './DetailsStatusTemplate/DetailsStatusTemplate';
import ProceedingOverviewWrapper from '/Components/Molecules/ProceedingOverviewWrapper/ProceedingOverviewWrapper';
import ProceedingStatusWrapper, {
  ProceedingStatusWrapperTheme,
} from '/Components/Molecules/ProceedingStatusWrapper/ProceedingStatusWrapper';
import ProceedingStatusNumber from '/Components/Atoms/ProceedingStatusNumber/ProceedingStatusNumber';
import Panel from '/Components/Atoms/Panel/Panel';
import RelatedBlock from '/Components/Organisms/RelatedBlock/RelatedBlock';
import RelatedBlockList from '/Components/Organisms/RelatedBlockList/RelatedBlockList';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

const caseStatusId = 'case-status';

const EPOSSak = ({
  proceedingTitle,
  formalTitle,
  subscribeToNotificationsForIssueLink,
  comment,
  overview,
  details,
  relatedProceedings,
  relatedNouPublications,
  relatedTopics,
}: // replacementTextScriptProps,
EPOSSakProps) => {
  useEffect(() => {
    // adapted from old solution code
    const queryString = location.search;
    if (queryString && queryString.indexOf(`target=${caseStatusId}`) > -1) {
      scrollToTarget(caseStatusId);
    }
  }, []);

  return (
    // Note: Page Title, MainIntro and MainBody not in use
    <PageWrapper>
      <div className="epos-sak-react">
        <Row horizontalPadding={HorizontalPadding.normal}>
          <Column desktop={Width.twoThirds}>
            <h1>{proceedingTitle}</h1>
            <p className="epos-sak-react__notification">
              {formalTitle && (
                <span className="epos-sak-react__notification__text">
                  {formalTitle}
                </span>
              )}
              {subscribeToNotificationsForIssueLink && (
                <div className="epos-sak-react__notification__link">
                  <Link {...subscribeToNotificationsForIssueLink} />
                </div>
              )}
            </p>
          </Column>
        </Row>
        {comment && (
          <Row
            horizontalPadding={HorizontalPadding.normal}
            verticalPaddingBottom={VerticalPadding.large}
          >
            <Panel title={comment.title}>
              <p className="epos-sak-react__panel-text">{comment.text}</p>
            </Panel>
          </Row>
        )}
        <div className="page page-cases proceeding-page">
          {overview && (
            <Row
              horizontalPadding={HorizontalPadding.normal}
              verticalPaddingBottom={VerticalPadding.large}
            >
              <ProceedingOverviewWrapper heading={overview.stepsHeading}>
                <ProceedingOverviewWrapper.Left>
                  <ProceedingStatusNumber list={overview.steps} />
                </ProceedingOverviewWrapper.Left>
                <ProceedingOverviewWrapper.Right>
                  <ProceedingStatusWrapper heading={overview.statusHeading}>
                    <StatusOverviewTemplate {...overview.status} />
                  </ProceedingStatusWrapper>
                </ProceedingOverviewWrapper.Right>
              </ProceedingOverviewWrapper>
            </Row>
          )}

          {details && (
            <Row
              horizontalPadding={HorizontalPadding.normal}
              verticalPaddingBottom={VerticalPadding.xlarge}
            >
              <ProceedingFlowList
                heading={details.heading}
                list={details.items}
                contentTemplate={EPOSSakFlowListContentTemplate}
              />
              {details.status && (
                <ProceedingStatusWrapper
                  id={caseStatusId}
                  heading={details.status.heading}
                  theme={ProceedingStatusWrapperTheme.standalone}
                >
                  <DetailsStatusTemplate {...details.status} />
                </ProceedingStatusWrapper>
              )}
            </Row>
          )}
          <Row horizontalPadding={HorizontalPadding.normal}>
            <div className="related-proceedings-list-wrapper">
              {relatedProceedings && (
                <RelatedBlock heading={relatedProceedings.heading}>
                  {[
                    relatedProceedings.relatertDiv,
                    relatedProceedings.sakgrunnlag,
                    relatedProceedings.fellesInnst,
                    relatedProceedings.sammeKilde,
                    relatedProceedings.oversendtFor,
                    relatedProceedings.oversendtFra,
                    relatedProceedings.tilbakemeld,
                    relatedProceedings.tilbakeProp,
                    relatedProceedings.fremmetIgjen,
                    relatedProceedings.tidlFremmet,
                  ].map(
                    (val, idx) =>
                      val && (
                        <React.Fragment key={idx}>
                          <RelatedBlockList
                            heading={val?.heading}
                            list={val?.topicLinks}
                          />
                        </React.Fragment>
                      )
                  )}
                </RelatedBlock>
              )}
              {relatedNouPublications && (
                <RelatedBlock
                  heading={relatedNouPublications.heading}
                  list={relatedNouPublications.items}
                />
              )}
              {relatedTopics && (
                <RelatedBlock
                  heading={relatedTopics.heading}
                  list={relatedTopics.topicLinks}
                />
              )}
            </div>
          </Row>
        </div>
      </div>

      {/* <script type="text/javascript">
        $('.page-cases a[href*="{replacementTextScriptProps.href}"]').each(function() {
            var thisUrl = $(this).attr('href');

            var element = $(this);
            var replacementText = "{replacementTextScriptProps.replacementText}";
            $.ajax({
                url: thisUrl,
                type: 'get',
                method: 'get',
                error: function() {
                  {replacementTextScriptProps.shouldAppendText && (
                        element.text(element.text() + " " + replacementText);
                        )}
                        {!replacementTextScriptProps.shouldAppendText && (
                        element.text(replacementText);
                        )}
                    element.contents().unwrap();
                },
                success: function() {}
            });
        });
    </script>*/}
    </PageWrapper>
  );
};

export default EPOSSak;
