import React, { useEffect } from 'react';
import './KomiteAnnetellerHistorisk.scss';
import { KomiteAnnetellerHistoriskProps } from '/Models/Generated/KomiteAnnetellerHistoriskProps';
import SortableTable from '/Components/Organisms/SortableTable/SortableTable';
import { SortDirectionEnum } from '/Models/Generated/SortDirectionEnum';
import { FocusItemProps } from '/Models/Generated/FocusItemProps';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import ImageWithCaption from '/Components/Organisms/ImageWithCaption/ImageWithCaption';
import Row, { HorizontalPadding, VerticalPadding } from '../../Layout/Row/Row';
import FocusItemList from '/Components/Organisms/FocusItemList/FocusItemList';
import GreyBar from '/Components/Layout/GreyBar/GreyBar';
import DropDownFilterWrapper from '/Components/Molecules/DropDownFilterWrapper/DropDownFilterWrapper';
import useUrlParam from '/Shared/Code/Hooks/UseUrlParam';
import SSRQueryStringReader from '/Shared/Code/SSR/SSRQueryStringReader';
import GreyRow, { GreyRowVerticalPadding } from '../../Layout/GreyRow/GreyRow';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';
import Link from '/Components/Atoms/Link/Link';

const KomiteAnnetellerHistorisk = ({
  pageTitle,
  mainIntro,
  mainBody,
  actualNewsHeader,
  focusItems,
  threeColumnContentAreaModules,
  mainImageOld,
  mainImage,
  committeeMembersListTitle,
  committeeMembers,
  committeeMembersViewChoices,
  requestParameters,
  newsArchiveLink,
}: KomiteAnnetellerHistoriskProps) => {
  const [pid, setPid] = useUrlParam(
    requestParameters.requestParameterNames.parliamentPeriodId,
    (window &&
      SSRQueryStringReader.Read(
        requestParameters.requestParameterNames.parliamentPeriodId
      )) ??
      null
  );

  const [, setChecked] = useUrlParam(
    'lvl',
    (window && SSRQueryStringReader.Read('lvl')) ?? null
  );

  const isMounted = useComponentDidMount();

  useEffect(() => {
    if (!isMounted) return;
    window.location.hash = 'primaryfilter';
    setChecked(
      pid ===
        committeeMembersViewChoices.parliamentPeriodDropDown.items[0].value
        ? null
        : false
    );
    window.location.reload();
  }, [pid]);

  useEffect(() => {
    if (window.location.hash === '#primaryfilter')
      document.getElementById('primaryfilter').scrollIntoView();
  }, [pid]);

  return (
    <div className="komite-historisk-react">
      {(mainImage || mainImageOld) && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.medium}
        >
          {mainImageOld && (
            <ImageWithCaption {...mainImageOld} maxWidthTablet={true} />
          )}

          {mainImage && (
            <ImageWithCaption {...mainImage} maxWidthTablet={true} />
          )}
        </Row>
      )}
      <Row horizontalPadding={HorizontalPadding.indent}>
        <h1>{pageTitle}</h1>
        {mainIntro && <ContentArea {...mainIntro} />}
        {mainBody && <ContentArea {...mainBody} />}
      </Row>
      {threeColumnContentAreaModules.items.some(
        (item) => item.component?.text.items.length !== 0
      ) && (
        <Row
          horizontalPadding={HorizontalPadding.normal}
          lineAbove
          lineBelow
          verticalPaddingBottom={VerticalPadding.xlarge}
        >
          <ContentArea
            {...threeColumnContentAreaModules}
            contentAreaType={threeColumnContentAreaModules.contentAreaType}
          />
        </Row>
      )}
      {focusItems?.length > 0 && (
        <Row
          horizontalPadding={HorizontalPadding.indent}
          verticalPaddingBottom={VerticalPadding.medium}
          className="komite-historisk-react__focus-items"
        >
          <div className="komite-historisk-react__focus-items__top">
            <GreyBar text={actualNewsHeader} />
            <FocusItemList
              items={focusItems.filter(
                (val: FocusItemProps) => val?.href !== null
              )}
            />
          </div>
          <Link
            href={newsArchiveLink.href}
            text={newsArchiveLink.text}
            langAttribute={newsArchiveLink.langAttribute}
          />
        </Row>
      )}
      <Row verticalPaddingBottom={VerticalPadding.large}>
        <GreyRow verticalPadding={GreyRowVerticalPadding.large}>
          <span id="primaryfilter"></span>
          <DropDownFilterWrapper
            halfwidth
            label={
              committeeMembersViewChoices.parliamentPeriodDropDown.labelText
            }
            options={committeeMembersViewChoices.parliamentPeriodDropDown.items}
            selectedValue={
              pid
                ? pid
                : committeeMembersViewChoices.parliamentPeriodDropDown.items[0]
                    .value
            }
            setSelected={setPid}
            id={committeeMembersViewChoices.parliamentPeriodDropDown.labelText}
            name={
              committeeMembersViewChoices.parliamentPeriodDropDown.labelText
            }
          />
        </GreyRow>
      </Row>
      <Row
        horizontalPadding={HorizontalPadding.indent}
        className="komite-historisk-react__table"
      >
        <GreyBar text={committeeMembersListTitle} />
        <SortableTable
          {...committeeMembers}
          doSort={null}
          setDoSort={null}
          setColumns={null}
          setRows={null}
          setSortColumn={null}
          setSortDirection={null}
          sortColumn={0}
          sortDirection={SortDirectionEnum.Disabled}
        />
      </Row>
    </div>
  );
};

export default KomiteAnnetellerHistorisk;
