import React, { useState } from 'react';
import './SearchPageFiltersMobile.scss';
import { SearchFilterProps } from '/Models/Generated/SearchFilterProps';
import { Row, SearchPageFilter } from '/App.Components';
import { HorizontalPadding } from '/Components/Layout/Row/Row';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { setFilter } from '/Shared/Code/Search/SearchSlice';
import cn from 'classnames';

const SearchPageFiltersMobile = (props: SearchFilterProps) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const results = useSelector((s: RootState) => s.Search.results);
  const isEnglish = useSelector((s: RootState) => s.Search.language) === 'en';
  return (
    <Row
      horizontalPadding={HorizontalPadding.normal}
      className="search-page-filters-mobile-react"
    >
      <button
        className={cn('search-page-filters-mobile-heading-react', {
          'is-open-react': isOpen,
        })}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? props.toggleOff : props.heading}
      </button>
      <div
        className={cn('search-page-filters-container', {
          'is-open-react': isOpen,
        })}
      >
        <h2>{props.heading}</h2>
        <div className="search-page-filters-column">
          <SearchPageFilter
            {...props.filterTime}
            id="filter-time-mobile"
            tab1Vals={results.GetTimeFilters(props.all)}
            setFilter={(filter: string) => dispatch(setFilter(filter))}
          />
        </div>
        <div className="search-page-filters-column">
          <SearchPageFilter
            {...props.filterContent}
            id="filter-content-mobile"
            tab1Vals={results.GetContentPublicationsFilters(props.all)}
            tab2Vals={results.GetContentEditorialFilters(props.all)}
            setFilter={(filter: string) => dispatch(setFilter(filter))}
          />
        </div>
        {!isEnglish && (
          <>
            <div className="search-page-filters-column">
              <SearchPageFilter
                {...props.filterCase}
                id="filter-case-mobile"
                tab1Vals={results.GetCaseFilters(props.all)}
                setFilter={(filter: string) => dispatch(setFilter(filter))}
              />
            </div>
            <div className="search-page-filters-column">
              <SearchPageFilter
                {...props.filterTopic}
                id="filter-topic-mobile"
                tab1Vals={results.GetTemaFilters(props.all)}
                tab1SubVals={results.GetSubtemaFilters(props.all)}
                setFilter={(filter: string) => dispatch(setFilter(filter))}
              />
            </div>
          </>
        )}
      </div>
    </Row>
  );
};

const SearchPageFiltersMobileWrapper = (props: SearchFilterProps) => (
  <Provider store={SearchStore}>
    <SearchPageFiltersMobile {...props} />
  </Provider>
);

export default SearchPageFiltersMobileWrapper;
