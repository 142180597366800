import { LiveMeetingInformationBlockTypeProps } from '/Models/Generated/LiveMeetingInformationBlockTypeProps';
import { StortingetLiveOverviewProps } from '/Models/Generated/StortingetLiveOverviewProps';
import consts from './consts';
import lang from './lang';
import { StortingetLiveHearingActivityProps } from '/Models/Generated/StortingetLiveHearingActivityProps';
import { LiveMeetingRoomBlockProps } from '/Models/Generated/LiveMeetingRoomBlockProps';
import { StortingetLiveAgendaItemProps } from '/Models/Generated/StortingetLiveAgendaItemProps';

export default function () {
  return {
    currentDateTitle: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): string {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return undefined;
        if (
          this.hearingIsActive(hearing) ||
          this.hearingIsNotStarted(hearing) ||
          this.hearingIsCanceled(hearing) ||
          this.hearingIsPostponed(hearing)
        ) {
          return hearing.hearingProgramStartDate;
        }
      } else if (
        this.isMeetingActivePausedVoting(live) ||
        this.shouldShowMeetingStartTime(live)
      ) {
        return live.meetingActivity.meetingCurrentDate;
      }
      return undefined;
    },
    currentMainTitle: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): string {
      const currentTitle = cms.mainBlock.title;
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return currentTitle;
        if (this.hearingIsCanceled(hearing))
          return `${lang.timeOfHearing[cms.lang]} ${
            hearing.hearingProgramStartTime
          } ${lang.canceledHearing[cms.lang]}`;
        if (this.hearingIsPostponed(hearing))
          return `${lang.timeOfHearing[cms.lang]} ${
            hearing.hearingProgramStartTime
          } ${lang.postponedHearing[cms.lang]}`;
        if (this.hearingIsActive(hearing))
          return lang.liveFromHearing[cms.lang];
        if (this.hearingIsNotStarted(hearing))
          return (
            lang.hearingStartsAt[cms.lang] + hearing.hearingProgramStartTime
          );
      } else {
        if (!live.meetingActivity) return currentTitle;
        if (this.isPausedMeeting(live)) return cms.mainBlock.meetingPausedTitle;
        if (this.isActiveMeeting(live) || this.isVotingInMeeting(live))
          return lang.liveFromMeeting[cms.lang];
        if (this.shouldShowMeetingStartTime(live))
          return (
            lang.meetingStartsAt[cms.lang] +
            live.meetingActivity.meetingNextStartTime
          );
      }
      return currentTitle;
    },
    mainText: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): string {
      const text = cms.mainBlock.mainText;
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (this.hearingIsCanceled(hearing) || this.hearingIsPostponed(hearing))
          return `${hearing.committeeName} ${
            lang.hasHearingInCase[cms.lang]
          }: <a href=${hearing.proceedingLinkUrl} title=${
            hearing.proceedingTitle
          }'>${hearing.proceedingTitle}</a>`;
        if (!hearing) return text;
      } else {
        if (this.isVotingInMeeting(live))
          return cms.mainBlock.votingInMeetingText;
        if (this.isPausedMeeting(live)) return cms.mainBlock.meetingPausedText;
        if (this.isActiveMeetingWithoutActiveProceeding(live))
          return cms.mainBlock.noActiveProceedingsInMeetingText;
        if (
          !this.isActiveMeeting(live) &&
          !this.shouldShowMeetingStartTime(live)
        )
          return text;
      }
      return undefined;
    },
    hearingLingerText: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): string {
      if (!this.mainBlockIsHearing(cms)) return undefined;
      const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
        cms,
        live
      );
      if (!hearing) return undefined;
      if (this.hearingIsFinished(hearing))
        return cms.mainBlock.hearingFinishedText;
      return undefined;
    },
    shouldShowImageLink: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (!cms.mainBlock.imageLink) return false;
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return false;
        return (
          this.hearingIsActive(hearing) || this.hearingIsNotStarted(hearing)
        );
      } else {
        return (
          this.isMeetingActivePausedVoting(live) ||
          this.shouldShowMeetingStartTime(live)
        );
      }
    },
    canceledHearing: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
        cms,
        live
      );
      if (
        hearing &&
        hearing.hearingActivityStatus === consts.hearingStatus.canceled
      )
        return true;
    },
    postponedHearing: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
        cms,
        live
      );
      if (
        hearing &&
        hearing.hearingActivityStatus === consts.hearingStatus.postponed
      )
        return true;
    },
    shouldShowMeetingAttendees: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) return false;
      if (!this.isActiveMeeting(live) && !this.shouldShowMeetingStartTime(live))
        return false;
      return this.hasMeetingAttendees(live);
    },
    shouldShowMainUrl: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return false;
        return (
          this.hearingIsActive(hearing) || this.hearingIsNotStarted(hearing)
        );
      } else {
        if (this.isActiveMeetingWithoutActiveProceeding(live)) return false;
        return this.isActiveMeeting(live) && this.hasAgendaItem(live);
      }
    },
    shouldShowMeetingButtons: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) return false;
      if (
        this.isMeetingActivePausedVoting(live) ||
        this.shouldShowMeetingStartTime(live)
      )
        return true;
      return false;
    },
    shouldShowHearingButtons: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (!this.mainBlockIsHearing(cms)) return false;
      const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
        cms,
        live
      );
      return !!hearing;
    },
    shouldShowLinkList: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return true;
      } else {
        if (
          !this.isActiveMeeting(live) &&
          !this.shouldShowMeetingStartTime(live)
        )
          return true;
      }
      return false;
    },
    shouldShowSlider: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): boolean {
      if (this.mainBlockIsHearing(cms)) {
        if (this.isActiveMeeting(live) || this.shouldShowMeetingStartTime(live))
          return true;
        if (!live.hearingActivityList) return false;
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing && live.hearingActivityList.length > 0) return true;
        if (this.hearingIsFinished(hearing)) return true;
        const hearings = live.hearingActivityList.filter(
          (h) => h.hearingId !== hearing.hearingId
        );
        if (hearings.length > 0) return true;
        return false;
      } else {
        return (
          (live.hearingActivityList && live.hearingActivityList.length > 0) ||
          !!live.finishedMeeting
        );
      }
    },
    isVotingOrActiveWithActiveProceedingOrActiveHearing: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return false;
        return this.hearingIsActive(hearing);
      } else {
        return (
          (this.isActiveMeeting(live) &&
            this.meetingHasActiveProceeding(live)) ||
          this.isVotingInMeeting(live)
        );
      }
    },
    isPreLive: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return false;
        return this.hearingIsNotStarted(hearing);
      } else {
        return this.shouldShowMeetingStartTime(live);
      }
    },
    isActiveMeetingWithoutActiveProceeding: function (
      live: StortingetLiveOverviewProps
    ) {
      // An order number of 0 means that there are no active
      // proceedings in the meeting.
      return (
        this.isMeetingActivePausedVoting(live) &&
        !this.meetingHasActiveProceeding(live)
      );
    },
    meetingHasActiveProceeding: function (live: StortingetLiveOverviewProps) {
      return this.agendaItem(live).orderNumber > 0;
    },
    isActiveMeeting: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity &&
        live.meetingActivity.meetingActivityStatus ===
          consts.meetingStatus.active
      );
    },
    isMeetingActivePausedVoting: function (live: StortingetLiveOverviewProps) {
      return (
        this.isActiveMeeting(live) ||
        this.isPausedMeeting(live) ||
        this.isVotingInMeeting(live)
      );
    },
    isVotingInMeeting: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity &&
        live.meetingActivity.meetingActivityStatus ===
          consts.meetingStatus.voting
      );
    },
    isPausedMeeting: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity &&
        live.meetingActivity.meetingActivityStatus ===
          consts.meetingStatus.pause
      );
    },
    shouldShowMeetingStartTime: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity &&
        live.meetingActivity.meetingShouldShowMeetingStartTime
      );
    },
    mainBlockIsHearing: function (cms: LiveMeetingInformationBlockTypeProps) {
      return cms.mainBlock.type !== consts.meetingRoom.s.enumId;
    },
    hearingIsActive: function (hearing: StortingetLiveHearingActivityProps) {
      return hearing.hearingActivityStatus === consts.hearingStatus.active;
    },
    hearingIsCanceled: function (hearing: StortingetLiveHearingActivityProps) {
      return hearing.hearingActivityStatus === consts.hearingStatus.canceled;
    },
    hearingIsPostponed: function (hearing: StortingetLiveHearingActivityProps) {
      return hearing.hearingActivityStatus === consts.hearingStatus.postponed;
    },
    hearingIsNotStarted: function (
      hearing: StortingetLiveHearingActivityProps
    ) {
      return hearing.hearingActivityStatus === consts.hearingStatus.notStarted;
    },
    hearingIsFinished: function (hearing: StortingetLiveHearingActivityProps) {
      return hearing.hearingActivityStatus === consts.hearingStatus.finished;
    },
    activeHearing: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ): StortingetLiveHearingActivityProps {
      if (!live.hearingActivityList) return undefined;
      const hearings = live.hearingActivityList.filter(
        (hearing) => hearing.hearingId === cms.mainBlock.activeHearingId
      );
      if (hearings.length > 0) return hearings[0];
      return undefined;
    },
    meetingRoomIdFromEnumId: function (enumId) {
      const room = consts.meetingRoom.filter((room) => room.enumId === enumId);
      return room ? room.nameId : undefined;
    },
    hasMeetingAttendees: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity.participatingGovernmentMemberList &&
        live.meetingActivity.participatingGovernmentMemberList.length > 0
      );
    },
    hasAgendaItem: function (live: StortingetLiveOverviewProps) {
      return (
        live.meetingActivity.currentHandlingAgendaItemList &&
        live.meetingActivity.currentHandlingAgendaItemList.length > 0
      );
    },
    hasLinkList: function (cms: LiveMeetingInformationBlockTypeProps) {
      return cms.mainBlock.linkList && cms.mainBlock.linkList.length > 0;
    },
    agendaItem: function (
      live: StortingetLiveOverviewProps
    ): StortingetLiveAgendaItemProps {
      return live.meetingActivity.currentHandlingAgendaItemList[0];
    },
    agendaUrl: function (live: StortingetLiveOverviewProps) {
      return live.meetingActivity.meetingAgendaLinkUrl;
    },
    hearingProgramUrl: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
        cms,
        live
      );
      return hearing.hearingProgramLinkUrl;
    },
    meetingTime: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      if (this.mainBlockIsHearing(cms)) {
        const hearing: StortingetLiveHearingActivityProps = this.activeHearing(
          cms,
          live
        );
        if (!hearing) return undefined;
        return hearing.hearingProgramStartTime;
      } else {
        if (!live.meetingActivity) return undefined;
        return live.meetingActivity.meetingNextStartTime;
      }
    },
    statusFromMeetingStatus: function (status) {
      switch (status) {
        case consts.meetingStatus.active:
          return consts.status.active;
        case consts.meetingStatus.notStarted:
          return consts.status.before;
        case consts.meetingStatus.pause:
          return consts.status.paused;
        default:
          return consts.status.notDetermined;
      }
    },
    statusFromHearingStatus: function (status) {
      switch (status) {
        case consts.hearingStatus.active:
          return consts.status.active;
        case consts.hearingStatus.notStarted:
          return consts.status.before;
        case consts.hearingStatus.finished:
          return consts.status.finished;
        case consts.hearingStatus.canceled:
          return consts.status.canceled;
        case consts.hearingStatus.postponed:
          return consts.status.postponed;
        default:
          return consts.status.notDetermined;
      }
    },
    sliderItems: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      const list = [];
      if (
        this.mainBlockIsHearing(cms) &&
        live.meetingActivity &&
        (cms.simulateSameDay ||
          this.isSameDay(live.meetingActivity.meetingStartDateAndTimeIsoFormat))
      ) {
        const stortingMeeting = {
          startTime: live.meetingActivity.meetingStartTime,
          status: this.statusFromMeetingStatus(
            live.meetingActivity.meetingActivityStatus
          ),
          id: lang.stortinget[cms.lang],
          textLink: live.meetingActivity.meetingAgendaLinkUrl,
          title: live.meetingActivity.meetingAgendaLinkText,
          videoLink: cms.main.link,
          image: cms.main.image,
          snapshot: cms.main.snapshot,
          fallback: cms.main.fallback,
          altText: cms.main.altText,
          isHearing: false,
        };
        list.push(stortingMeeting);
      }
      if (live.finishedMeeting) {
        const stortingMeeting = {
          startTime: live.finishedMeeting.meetingStartTime,
          videoLink: live.finishedMeeting.meetingVideoUrl,
          status: consts.status.finished,
          id: lang.stortinget[cms.lang],
          textLink: live.finishedMeeting.agendaLink,
          title: live.finishedMeeting.agendaText,
          image: cms.main.image,
          fallback: cms.main.fallback,
          altText: cms.main.altText,
          isHearing: false,
        };
        list.push(stortingMeeting);
      }

      const hearings = live.hearingActivityList;
      if (hearings && hearings.length > 0) {
        for (const hearing of hearings) {
          if (
            hearing.hearingId === cms.mainBlock.activeHearingId &&
            !this.hearingIsFinished(hearing)
          )
            continue;
          let hearingCmsData;
          switch (hearing.hearingRoomId) {
            case consts.meetingRoom.h1.nameId:
              hearingCmsData = cms.h1;
              break;
            case consts.meetingRoom.h2.nameId:
              hearingCmsData = cms.h2;
              break;
            case consts.meetingRoom.n202.nameId:
              hearingCmsData = cms.h3;
              break;
            default:
              continue;
          }
          const sliderItem = this.createHearingSliderItem(
            hearing,
            hearingCmsData
          );
          list.push(sliderItem);
        }
      }
      if (list.length > 0)
        list.sort(
          (a, b) =>
            this.compare(b.status, a.status) ||
            this.compare(a.startTime, b.startTime)
        );
      return list;
    },
    createHearingSliderItem: function (
      hearing: StortingetLiveHearingActivityProps,
      hearingCmsData: LiveMeetingRoomBlockProps
    ) {
      const status = this.statusFromHearingStatus(
        hearing.hearingActivityStatus
      );
      return {
        startTime: hearing.hearingProgramStartTime,
        status: status,
        id: hearing.hearingId,
        textLink: hearing.proceedingLinkUrl,
        title: hearing.proceedingTitle,
        videoLink:
          status === consts.status.finished
            ? hearing.hearingVideoUrl
            : hearingCmsData.link,
        image: hearingCmsData.image,
        snapshot: hearingCmsData.snapshot,
        fallback: hearingCmsData.fallback,
        altText: hearingCmsData.altText,
        isHearing: true,
      };
    },
    isSameDay: function (meetingDateString) {
      const meetingDate = new Date(
        meetingDateString.substr(0, meetingDateString.indexOf(' '))
      );
      const now = new Date();
      return now.toDateString() === meetingDate.toDateString();
    },
    numberOfFinished: function (
      cms: LiveMeetingInformationBlockTypeProps,
      live: StortingetLiveOverviewProps
    ) {
      let counter = 0;
      if (live.hearingActivityList) {
        const hearings = live.hearingActivityList.filter(
          (hearing) =>
            hearing.hearingActivityStatus === consts.hearingStatus.finished
        );
        counter = hearings.length;
      }
      if (live.finishedMeeting) counter++;
      return counter;
    },
    compare: function (a, b) {
      if (a > b) return +1;
      if (a < b) return -1;
      return 0;
    },
    blobToImageSrc: function (blob) {
      const urlCreator = window.URL || window.webkitURL;
      const binaryData = [];
      binaryData.push(blob);
      return urlCreator.createObjectURL(
        new Blob(binaryData, { type: 'application/zip' })
      );
    },
  };
}
