export class Filter {
  name: string;
  num_docs?: number;
  refine_groups: string;
  selected?: boolean;
}

export default class SearchResult {
  constructor(private result) {}

  public GetQuery = (): string => this.GetResponse()?.query;

  public GetPage = (): number => this.GetResponse()?.page;

  public GetPageSize = (): number => 20;

  public GetTo = (): number =>
    Math.min(this.GetTotalCount(), this.GetPageSize() * this.GetPage());

  public GetFrom = (): number => this.GetPageSize() * (this.GetPage() - 1) + 1;

  public GetPageCount = (): number =>
    Math.ceil(this.GetTotalCount() / this.GetPageSize());

  public GetTotalCount = (): number => this.GetResponse()?.totalResults;

  public GetCurrentCount = (): number => this.GetResponse()?.currentResults;

  private GetResponse = () => this.result?.data?.response;

  public GetResults = (): [] => this.result?.data?.results;

  public GetDistribution = () => this.result?.data?.distribution;

  public GetCaseFilters = (all: string): Array<Filter> =>
    this.GetFilters(all, this.GetDistribution()?.case?.childs);

  public GetCaseStatusFilters = (all: string): Array<Filter> =>
    this.GetFilters(all, this.GetDistribution()?.caseStatus?.childs);

  private RemoveRefineGroup = (filter: string, toRemove: string): string =>
    filter
      ?.split('+')
      .filter((f) => f !== toRemove)
      .join('+');

  private RemoveGroupFromAll = (
    all: string,
    filters: Array<Filter>,
    toRemove: string
  ): Array<Filter> =>
    filters.map((f) =>
      f.name === all
        ? {
            ...f,
            refine_groups: this.RemoveRefineGroup(f.refine_groups, toRemove),
          }
        : f
    );

  public GetContentPublicationsFilters = (all: string): Array<Filter> => {
    const filters = this.GetFilters(
      all,
      this.GetDistribution()?.content?.publications?.childs
    );
    return this.RemoveGroupFromAll(all, filters, 'G733');
  };

  public GetContentEditorialFilters = (all: string): Array<Filter> => {
    const filters = this.GetFilters(
      all,
      this.GetDistribution()?.content?.editorial?.childs
    );
    return this.RemoveGroupFromAll(all, filters, 'G731');
  };

  public GetSubtemaFilters = (all: string): Array<Filter> => {
    const tema_refine_groups = this.GetTemaFilters(all).filter(
      (f) => f.selected
    )?.[0]?.refine_groups;
    if (!tema_refine_groups)
      return [{ refine_groups: '', name: all, selected: true }];
    return this.GetDistribution()?.subtema?.childs?.map((f) =>
      f.refine_groups === tema_refine_groups
        ? {
            refine_groups: tema_refine_groups,
            name: all,
            selected: f.selected,
            syntetic: true,
          }
        : f
    );
  };

  public GetTemaFilters = (all: string): Array<Filter> =>
    this.GetFilters(all, this.GetDistribution()?.tema?.childs);

  public GetTimeFilters = (all: string): Array<Filter> =>
    this.GetFilters(all, this.GetDistribution()?.time?.childs);

  private GetFilters = (all: string, items: Array<Filter>): Array<Filter> => {
    const anySelected = (items ?? []).filter((i) => i.selected).length > 0;
    if (anySelected) {
      return items.map((i) => (i.id || i.selected ? i : { ...i, name: all }));
    }
    return [
      {
        refine_groups: '',
        name: all,
        selected: true,
      },
      ...(items ?? []),
    ];
  };
}
