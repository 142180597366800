import React, { useState } from 'react';
import { SkjemaAkkrediteringUnsubscribeModeProps } from '/Models/Generated/SkjemaAkkrediteringUnsubscribeModeProps';
import './SkjemaAkkrediteringUnsubscribe.scss';
import Row, {
  HorizontalPadding,
  VerticalPadding,
} from '/Components/Layout/Row/Row';
import GreyRow, {
  GreyRowVerticalPadding,
} from '/Components/Layout/GreyRow/GreyRow';
import Link from '/Components/Atoms/Link/Link';
import Button from '/Components/Atoms/Button/Button';

const SkjemaAkkrediteringUnsubscribe = ({
  form,
  notFoundSection,
}: SkjemaAkkrediteringUnsubscribeModeProps) => {
  const [unsubscribeResult, setUnsubscribeResult] = useState(
    form?.unsubscribeResultProps
  );

  return (
    <Row
      horizontalPadding={HorizontalPadding.none}
      verticalPaddingBottom={VerticalPadding.normal}
    >
      <GreyRow verticalPadding={GreyRowVerticalPadding.large}>
        {notFoundSection && (
          <div className="skjema-akkreditering-unsubscribe-react">
            <h2>{notFoundSection.heading}</h2>
            <Link {...notFoundSection.unsubscribeBack} />
          </div>
        )}
        {form && !unsubscribeResult && (
          <div className="skjema-akkreditering-unsubscribe-react">
            <h2>{form.heading}</h2>
            <Button
              text={form.buttonText}
              onClick={() => {
                const body = {
                  pageId: form.pageId,
                  unsubscribeId: form.unsubscribeId,
                  language: form.language,
                };
                fetch(form.postUrl, {
                  method: 'POST',
                  body: JSON.stringify(body),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUnsubscribeResult(res.unsubscribeResultProps);
                  });
              }}
            />
          </div>
        )}
        {unsubscribeResult && (
          <div className="skjema-akkreditering-unsubscribe-react">
            <h2>{form.heading}</h2>
            <p
              className="skjema-akkreditering-unsubscribe-react__unsubscribed"
              aria-live="polite"
            >
              {unsubscribeResult.messageAfterPost}
            </p>
            <Link {...unsubscribeResult.back} />
          </div>
        )}
      </GreyRow>
    </Row>
  );
};

export default SkjemaAkkrediteringUnsubscribe;
