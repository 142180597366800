export const EventNames = {
  pushstate: 'pushstate',
};

export const Subscribe = (eventName: string, handler: (e) => void) => {
  if (typeof window !== 'undefined')
    window.addEventListener(eventName, handler);
};

export const Emit = (eventName: string, payload: object) => {
  if (typeof window !== 'undefined')
    window.dispatchEvent(new CustomEvent(eventName, payload));
};
