import React from 'react';
import './ProceedingFlowList.scss';
import classNames from 'classnames';
import { ProcessStepTheme } from '/Models/Generated/ProcessStepTheme';

interface ProceedingFlowListProps {
  heading?: string;
  subHeading?: string;
  list: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  // list: (StateBudgetStepProps | EposSakDetailsProps)[];
  contentTemplate: React.FunctionComponent;
  contentKey?: string;
}

const ProceedingFlowList = ({
  heading,
  subHeading,
  list,
  contentTemplate,
  contentKey,
}: ProceedingFlowListProps) => {
  const Comp = contentTemplate;
  return (
    <div className="proceeding-flow-list-react">
      {heading && (
        <h2 className="proceeding-flow-list-react__heading">{heading}</h2>
      )}
      {subHeading && (
        <h3 className="proceeding-flow-list-react__sub-heading">
          {subHeading}
        </h3>
      )}
      <ol className="proceeding-flow-list-react__list">
        {list.map((val, idx) => (
          <li
            className="proceeding-flow-list-react__list__item"
            key={idx}
            id={val.domId}
          >
            <div className="proceeding-flow-list-react__step">
              <h3 className="proceeding-flow-list-react__step__heading">
                <span
                  className={classNames(
                    'proceeding-flow-list-react__step__number',
                    {
                      'proceeding-flow-list-react__step__number--past':
                        val.theme === ProcessStepTheme.Past,
                      'proceeding-flow-list-react__step__number--current':
                        val.theme === ProcessStepTheme.Current,
                      'proceeding-flow-list-react__step__number--future':
                        val.theme === ProcessStepTheme.Future,
                    }
                  )}
                >
                  {val.number}
                </span>
                <span>{val.title}</span>
              </h3>
            </div>
            <div className="proceeding-flow-list-react__content-wrapper">
              {!!contentKey && <Comp {...val[contentKey]} />}
              {!contentKey && <Comp {...val} />}
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};
export default ProceedingFlowList;
