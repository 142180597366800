import React, { useEffect, useState } from 'react';
import './LiveMeetingInformationBlockTypeSliderItemLink.scss';
import { Provider, useSelector } from 'react-redux';
import { RootState, SearchStore } from '/Shared/Code/Search/SearchStore';
import consts from './consts';
import lang from './lang';
import LiveMeetingInformationBlockTypeSliderItemInformation from './LiveMeetingInformationBlockTypeSliderItemInformation';
import classNames from 'classnames';

const LiveMeetingInformationBlockTypeSliderItem = (item: SliderItem) => {
  const { cmsData } = useSelector((s: RootState) => s.LiveBlock);

  const textClock = lang.clock[cmsData.lang];
  const textLive = lang.live[cmsData.lang];
  const textRecording = lang.recording[cmsData.lang];
  const textPostponed = lang.postponed[cmsData.lang];
  const [imageSrc, setImageSrc] = useState('');

  const isBefore = () => item.status === consts.status.before;

  const isActive = () => item.status === consts.status.active;

  const isPostponed = () => item.status === consts.status.postponed;

  useEffect(() => {
    if (isActive() && item.snapshot) {
      const snapshotUrl =
        item.snapshot.indexOf('?') > -1
          ? `${item.snapshot}&size=small`
          : `${item.snapshot}?size=small`;
      fetch(snapshotUrl).then(
        (res) => {
          if (res.ok) setImageSrc(snapshotUrl);
          else setImageSrc(item.fallback);
        },
        (response) => {
          // eslint-disable-next-line no-console
          console.log(
            'Failed to get snapshot for slider item ' + item.id + ': ',
            response
          );
          setImageSrc(item.fallback);
        }
      );
    } else {
      setImageSrc(item.fallback);
    }
  }, []);
  return (
    <li className="live-meeting-information-block-type-slider-item-link-react">
      <div className="live-meeting-information-block-type-slider-item-link-react__split">
        <LiveMeetingInformationBlockTypeSliderItemInformation {...item} />
        <div className="live-meeting-information-block-type-slider-item-link-react__video-container">
          {isPostponed() && (
            <span>
              <div
                className={classNames(
                  'live-meeting-information-block-type-slider-item-link-react__video-meta',
                  'live-meeting-information-block-type-slider-item-link-react__video-meta--rec',
                  'live-meeting-information-block-type-slider-item-link-react__video-meta--active'
                )}
              >
                {textPostponed}
              </div>
              <img src={imageSrc} alt={item.altText} draggable={false} />
            </span>
          )}
          {!isPostponed() && (
            <a
              href={item.videoLink}
              className="live-meeting-information-block-type-slider-item-link-react__play-button"
              draggable={false}
            >
              {isBefore() && (
                <div
                  className={classNames(
                    'live-meeting-information-block-type-slider-item-link-react__video-meta',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--time',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--active'
                  )}
                >
                  {textClock} {item.startTime}
                </div>
              )}
              {!isBefore() && isActive() && (
                <div
                  className={classNames(
                    'live-meeting-information-block-type-slider-item-link-react__video-meta',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--live',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--active'
                  )}
                >
                  {textLive}
                </div>
              )}
              {!isBefore() && !isActive() && (
                <div
                  className={classNames(
                    'live-meeting-information-block-type-slider-item-link-react__video-meta',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--rec',
                    'live-meeting-information-block-type-slider-item-link-react__video-meta--active'
                  )}
                >
                  {textRecording}
                </div>
              )}
              <img src={imageSrc} alt={item.altText} draggable={false} />
            </a>
          )}
        </div>
      </div>
      <div className="live-meeting-information-block-type-slider-item-link-react__gradient"></div>
    </li>
  );
};

const LiveMeetingInformationBlockTypeSliderItemWrapper = (
  props: SliderItem
) => (
  <Provider store={SearchStore}>
    <LiveMeetingInformationBlockTypeSliderItem {...props} />
  </Provider>
);

export default LiveMeetingInformationBlockTypeSliderItemWrapper;
