import UseProdUrls from '/Mockup/Helpers/UseProdUrls';
import { EPOSHvaskjerLangtidsprogmoterAsyncDataProps } from '/Models/Generated/EPOSHvaskjerLangtidsprogmoterAsyncDataProps';

export default class EPOSHvaskjerLangtidsprogmoterClient {
  constructor(private endpoint: string) {}

  public Get(
    /**  year week */
    yw: string,
    /**  activity type */
    ptid: string
  ): Promise<EPOSHvaskjerLangtidsprogmoterAsyncDataProps> {
    if (typeof window === 'undefined') return Promise.resolve(null);
    if (window.location.host === 'localhost:8080') {
      console.log('Local mockdata'); // eslint-disable-line no-console
      return this.getMockData(yw).then((res) => UseProdUrls(res));
    } else {
      return fetch(this.endpoint + `?yw=${yw}&ptid=${ptid}`, {
        headers: { Accept: 'application/json' },
      }).then((res) => res.json());
    }
  }

  BuildFilterUrl = (yw: string, ptid: string): string => {
    const params = [
      yw ? `yw=${yw}` : null,
      ptid ? `ptid=${ptid}` : null,
    ].filter((p) => p !== null);
    return (
      window.location.href.replace(/\?.*/, '') +
      (params.length ? '?' + params.join('&') : '')
    );
  };

  private getMockData(
    yw: string
  ): Promise<EPOSHvaskjerLangtidsprogmoterAsyncDataProps> {
    return fetch(
      '/MockupAssets/MockData/EPOSHvaskjerLangtidsprogmoterAsyncData.json'
    )
      .then((res) => res.json())
      .then((result: EPOSHvaskjerLangtidsprogmoterAsyncDataProps) => {
        if (yw && yw !== 'all') {
          result.weeks = [
            result.weeks.filter(
              (w) => w.header.split(' ')[1] === yw.substring(5)
            )[0],
          ];
        }

        return result;
      });
  }
}
