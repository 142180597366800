import React from 'react';
import './PortalsideHvaskjerCalendarDay.scss';
import { PortalsideHvaskjerApiDayProps } from '/Models/Generated/PortalsideHvaskjerApiDayProps';
import GreyRow, { GreyRowTheme } from '/Components/Layout/GreyRow/GreyRow';
import cn from 'classnames';
import Row, { HorizontalPadding } from '/Components/Layout/Row/Row';

const PortalsideHvaSkjerCalendar = ({
  day,
  events,
  isCurrent,
  weekNumber,
}: PortalsideHvaskjerApiDayProps) => {
  return (
    <div className="portalsidehvaskjer-calendar-day-react">
      <Row horizontalPadding={HorizontalPadding.none}>
        <GreyRow
          theme={isCurrent ? GreyRowTheme.blue : GreyRowTheme.default}
          fullWidthText={true}
        >
          <div
            className={cn('portalsidehvaskjer-calendar-day-react__header', {
              'portalsidehvaskjer-calendar-day-react__header--current':
                isCurrent,
            })}
          >
            <h2>{day}</h2>
            {weekNumber && <h2>{weekNumber}</h2>}
          </div>
        </GreyRow>
      </Row>
      <Row horizontalPadding={HorizontalPadding.normal}>
        {!!events?.length && (
          <ul>
            {events.map((event, idx) => (
              <li
                key={idx}
                className="portalsidehvaskjer-calendar-day-react__event-item"
              >
                <div className="portalsidehvaskjer-calendar-day-react__event-item-left">
                  {event.title}
                </div>
                <div className="portalsidehvaskjer-calendar-day-react__event-item-right">
                  {!!event.eventItems?.length && (
                    <ul>
                      {event.eventItems.map((evItem, evItemIdx) => (
                        <li key={evItemIdx}>
                          {!!evItem.textLines?.length &&
                            evItem.textLines.map((text, textIdx) => (
                              <div
                                key={textIdx}
                                dangerouslySetInnerHTML={{ __html: text }}
                              ></div>
                            ))}

                          {evItem.image && evItem.caption && (
                            <figure>
                              <img src={evItem.image} alt={evItem.imageAlt} />
                              <div className="caption">
                                <figcaption
                                  className="speech-bubble"
                                  dangerouslySetInnerHTML={{
                                    __html: evItem.caption,
                                  }}
                                ></figcaption>
                              </div>
                            </figure>
                          )}
                          {evItem.caption && !evItem.image && (
                            <div>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: evItem.caption,
                                }}
                              />
                            </div>
                          )}

                          {!!evItem.additionalTextLines?.length &&
                            evItem.additionalTextLines.map(
                              (additionalText, additionalTextIdx) => (
                                <div key={additionalTextIdx}>
                                  {additionalText}
                                </div>
                              )
                            )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </Row>
    </div>
  );
};

export default PortalsideHvaSkjerCalendar;
