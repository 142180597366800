import React from 'react';
import './XmlImportInnstillingHeader.scss';
import RelatedLinksBox from '/Components/Organisms/RelatedLinksBox/RelatedLinksBox';
import { XmlImportInnstillingHeaderProps } from '/Models/Generated/XmlImportInnstillingHeaderProps';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';
import IconLinkGeneric from '/Components/Atoms/IconLinkGeneric/IconLinkGeneric';

const XmlImportInnstillingHeader = ({
  detteDokumentetLabel,
  sourcedocLabel,
  dateLabel,
  publisherLabel,
  fromLabel,
  pagecountLabel,
  pdfLabel,
  publication,
  documentReferenceShouldBeVisible,
  sourceDocumentReference,
  documentDate,
  publisherOverride,
  signature,
  pageCount,
  pdfLink,
  relatedProceeding,
  relatedTopics,
}: XmlImportInnstillingHeaderProps) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    return timestamp.substr(0, 10).split('-').reverse().join('.');
  };
  return (
    <div className="xmlimportinnstilling-header-react">
      <div className="xmlimportinnstilling-header-react__inner">
        <ColumnContentWrapper title={detteDokumentetLabel}>
          <ul className="xmlimportinnstilling-header-react__document-list">
            <li>{publication}</li>
            {documentReferenceShouldBeVisible && (
              <li>
                <b>{sourcedocLabel}: </b>
                {sourceDocumentReference}
              </li>
            )}
            <li>
              <b>{dateLabel}: </b>
              <time dateTime={formatDate(documentDate)}>
                {formatDate(documentDate)}
              </time>
            </li>
            {publisherOverride && (
              <li>
                <b>{publisherLabel}</b>: {publisherOverride}
              </li>
            )}
            {signature && (
              <li>
                <b>{fromLabel}: </b>
                {signature}
              </li>
            )}
            {pageCount > 0 && (
              <li>
                <b>{pagecountLabel}: </b>
                {pageCount}
              </li>
            )}
            {pdfLink && (
              <li className="xmlimportinnstilling-header-react__document-list__pdf-link">
                <IconLinkGeneric
                  iconString="download"
                  href={pdfLink}
                  text={pdfLabel}
                />
              </li>
            )}
          </ul>
        </ColumnContentWrapper>
        <RelatedLinksBox {...relatedProceeding} />
        <RelatedLinksBox {...relatedTopics} />
      </div>
    </div>
  );
};

export default XmlImportInnstillingHeader;
