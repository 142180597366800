import React, { useEffect, useState } from 'react';
import './SearchFilter.scss';
import { GlobalSearchFilterProps } from '/Models/Generated/GlobalSearchFilterProps';
import SearchFilterDesktop from './SearchFilterDesktop';
import SearchFilterMobile from './SearchFilterMobile';
import useComponentDidMount from '/Shared/Code/Hooks/UseComponentDidMount';
import { SearchCategoryProps } from '/Models/Generated/SearchCategoryProps';
import SearchUrlUtilities from '../GlobalSearchUrlUtilities';

interface Props extends GlobalSearchFilterProps {
  setFilterQuery: (e) => void;
  setDateQuery: (e) => void;
  setMobileSearchOpen: (e) => void;
  mobileSearchOpen: boolean;
  totalItems: number;
  language: string;
  todayLabel: string;
  categories: SearchCategoryProps[];
  setSelectedFilters: (e) => void;
  selectedFilters: object;
  setSelectedDates: (e) => void;
  selectedDates: object;
}

const SearchFilter = ({
  label,
  categories,
  setFilterQuery,
  setDateQuery,
  setMobileSearchOpen,
  mobileSearchOpen,
  noChosenFiltersLabel,
  chosenFiltersLabel,
  clearFiltersLabel,
  backToSearchLabel,
  showResultsLabel,
  totalItems,
  language,
  todayLabel,
  searchTipLink,
  selectedFilters,
  setSelectedFilters,
  selectedDates,
  setSelectedDates,
}: Props) => {
  const tidsrom = categories ? categories[0] : null;
  const [isHiddenAfterTransition, setIsHiddenAfterTransition] = useState(true);
  const isMounted = useComponentDidMount();
  const [activeTab, setActiveTab] = useState('');

  const handleTabChange = (title: string) => {
    setActiveTab(title === activeTab ? null : title);
  };

  const getDateString = (item) => {
    const date = new Date(item);
    const formattedDate = `${date.getFullYear()}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return formattedDate;
  };

  const [fromSesjon, setFromSesjon] = useState(
    selectedDates && selectedDates[tidsrom.queryKeyFromPeriod]
      ? selectedDates[tidsrom.queryKeyFromPeriod]
      : tidsrom.items[0].choicesDropDown
          .filter((val) => val.isSelected === true)
          .map((val) => val.value)
          .toString()
  );
  const [toSesjon, setToSesjon] = useState(
    selectedDates && selectedDates[tidsrom.queryKeyToPeriod]
      ? selectedDates[tidsrom.queryKeyToPeriod]
      : tidsrom.items[1].choicesDropDown
          .filter((val) => val.isSelected === true)
          .map((val) => val.value)
          .toString()
  );

  const [fromDate, setFromDate] = useState(
    selectedDates && selectedDates[tidsrom.queryKeyFromDate]
      ? selectedDates[tidsrom.queryKeyFromDate]
      : tidsrom.items[0].choicesDropDown[
          tidsrom.items[0].choicesDropDown.length - 1
        ].value
  );
  const [toDate, setToDate] = useState(
    selectedDates && selectedDates[tidsrom.queryKeyToDate]
      ? selectedDates[tidsrom.queryKeyToDate]
      : getDateString(new Date())
  );

  useEffect(() => {
    let updatedParam = '';
    //If all date filters are null
    if (Object.values(selectedDates).every((value) => !value)) {
      //Sets date and sesjon to default values
      setFromDate(
        tidsrom.items[0].choicesDropDown[
          tidsrom.items[0].choicesDropDown.length - 1
        ].value
      );
      setToDate(getDateString(new Date()));

      setFromSesjon(
        tidsrom.items[0].choicesDropDown[
          tidsrom.items[0].choicesDropDown.length - 1
        ].value.toString()
      );
      setToSesjon(tidsrom.items[1].choicesDropDown[0].value.toString());
      setDateQuery('');
    } else {
      Object.entries(selectedDates).forEach(([key, value]) => {
        if (value) {
          updatedParam += '&' + key.toLowerCase() + '=' + value;
        }
        setDateQuery(updatedParam);
      });
    }
  }, [selectedDates]);

  useEffect(() => {
    setFilterQuery(
      SearchUrlUtilities.GetFacetFilterQueryFromFacetFilters(selectedFilters)
    );
  }, [selectedFilters]);

  const isToday = (dateInput: Date): boolean => {
    const today = new Date(); // Get the current date and time
    const inputDate = new Date(dateInput); // Ensure the input is treated as a Date object

    return (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    );
  };
  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (
      //Does not update selectedDates if selectedDates were reset
      !selectedDates['fraDato'] &&
      !selectedDates['tilDato'] &&
      isToday(new Date(toDate)) &&
      fromDate ===
        tidsrom.items[0].choicesDropDown[
          tidsrom.items[0].choicesDropDown.length - 1
        ].value
    ) {
      return;
    }
    setFromSesjon(
      tidsrom.items[0].choicesDropDown[
        tidsrom.items[0].choicesDropDown.length - 1
      ].value.toString()
    );
    setToSesjon(tidsrom.items[1].choicesDropDown[0].value.toString());

    setSelectedDates({
      [tidsrom.queryKeyFromPeriod]: null,
      [tidsrom.queryKeyToPeriod]: null,
      [tidsrom.queryKeyFromDate]: fromDate,
      [tidsrom.queryKeyToDate]: toDate,
    });
  }, [toDate, fromDate]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    if (
      //Does not update selectedDates if selectedDates were reset
      !selectedDates['fraSesjon'] &&
      !selectedDates['tilSesjon'] &&
      fromSesjon ===
        tidsrom.items[0].choicesDropDown[
          tidsrom.items[0].choicesDropDown.length - 1
        ].value &&
      toSesjon === tidsrom.items[1].choicesDropDown[0].value
    ) {
      return;
    }
    setFromDate(
      tidsrom.items[0].choicesDropDown[
        tidsrom.items[0].choicesDropDown.length - 1
      ].value
    );
    setToDate(getDateString(new Date()));
    setSelectedDates({
      [tidsrom.queryKeyFromPeriod]: fromSesjon,
      [tidsrom.queryKeyToPeriod]: toSesjon,
      [tidsrom.queryKeyFromDate]: null,
      [tidsrom.queryKeyToDate]: null,
    });
  }, [fromSesjon, toSesjon]);

  const radioButtonsOptions = [
    {
      label: 'Sesjon',
      value: 'sesjon',
      checked:
        language === 'no' &&
        ((selectedDates[tidsrom.queryKeyFromPeriod] !== null &&
          selectedDates[tidsrom.queryKeyToPeriod] !== null) ||
          Object.values(selectedDates).every((value) => !value)),
      name: 'sesjon',
    },
    {
      label: 'Dato',
      value: 'dato',
      checked:
        (selectedDates[tidsrom.queryKeyToDate] !== null &&
          selectedDates[tidsrom.queryKeyFromDate] !== null) ||
        language !== 'no',
      name: 'dato',
    },
  ];

  const [checkedView, setCheckedView] = useState(radioButtonsOptions);
  return (
    <div className="global-search-filter">
      <SearchFilterDesktop
        todayLabel={todayLabel}
        label={label}
        categories={categories}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleTabChange={handleTabChange}
        setMobileSearchOpen={setMobileSearchOpen}
        setIsHiddenAfterTransition={setIsHiddenAfterTransition}
        noChosenFiltersLabel={noChosenFiltersLabel}
        chosenFiltersLabel={chosenFiltersLabel}
        clearFiltersLabel={clearFiltersLabel}
        backToSearchLabel={backToSearchLabel}
        showResultsLabel={showResultsLabel}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        toSesjon={toSesjon}
        setToSesjon={setToSesjon}
        fromSesjon={fromSesjon}
        setFromSesjon={setFromSesjon}
        checkedView={checkedView}
        setCheckedView={setCheckedView}
        language={language}
        searchTipLink={searchTipLink}
        filterQueryKeys={[]}
        dateFilterQueryKeys={[]}
      />
      <SearchFilterMobile
        todayLabel={todayLabel}
        label={label}
        categories={categories}
        selectedFilters={selectedFilters}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setSelectedFilters={setSelectedFilters}
        setSelectedDates={setSelectedDates}
        selectedDates={selectedDates}
        handleTabChange={handleTabChange}
        setMobileSearchOpen={setMobileSearchOpen}
        mobileSearchOpen={mobileSearchOpen}
        isHiddenAfterTransition={isHiddenAfterTransition}
        setIsHiddenAfterTransition={setIsHiddenAfterTransition}
        noChosenFiltersLabel={noChosenFiltersLabel}
        chosenFiltersLabel={chosenFiltersLabel}
        clearFiltersLabel={clearFiltersLabel}
        backToSearchLabel={backToSearchLabel}
        showResultsLabel={showResultsLabel}
        totalItems={totalItems}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        toSesjon={toSesjon}
        setToSesjon={setToSesjon}
        fromSesjon={fromSesjon}
        setFromSesjon={setFromSesjon}
        checkedView={checkedView}
        setCheckedView={setCheckedView}
        language={language}
        searchTipLink={searchTipLink}
        filterQueryKeys={[]}
        dateFilterQueryKeys={[]}
      />
    </div>
  );
};

export default SearchFilter;
