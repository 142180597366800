import React, { useEffect, useState } from 'react';
import './CardRelatedTopics.scss';
import { SearchHitMetadataProps } from '../../../../../../Models/Generated/SearchHitMetadataProps';
import Link, { LinkTheme } from '../../../../../Atoms/Link/Link';
import cn from 'classnames';

interface RelatedTopicsListProps {
  relatedTopicsList: SearchHitMetadataProps;
  useMobileMenu: boolean;
}

const RelatedTopics = ({
  relatedTopicsList,
  useMobileMenu,
}: RelatedTopicsListProps) => {
  const [, setLabelAvailable] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [useShowMore, setUseShowMore] = useState(false);
  const maxLinksToShow = 3;

  useEffect(() => {
    if (relatedTopicsList && relatedTopicsList.linkLists.length) {
      setLabelAvailable(false);
      for (let i = 0; i < relatedTopicsList.linkLists.length; i++) {
        if (relatedTopicsList.linkLists[i].label) {
          setLabelAvailable(true);
          break;
        }
      }
    }
    if (
      relatedTopicsList.linkLists[0].metadataLinks.length > maxLinksToShow &&
      relatedTopicsList.linkLists[0].metadataLinks.length !== maxLinksToShow + 1
    ) {
      setUseShowMore(true);
    }
  }, [relatedTopicsList]);

  const makeButton = () => {
    if (
      relatedTopicsList.linkLists[0].metadataLinks.length > maxLinksToShow &&
      relatedTopicsList.linkLists[0].metadataLinks.length !== maxLinksToShow + 1
    ) {
      return (
        <button
          className={cn('card-related-topics__list__show-more-button', {
            open: showMore,
          })}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Vis færre temaer' : 'Vis flere temaer'}
        </button>
      );
    }
  };

  return (
    <div
      className={cn('card-related-topics', {
        'use-mobile-menu': useMobileMenu,
      })}
    >
      {relatedTopicsList &&
        relatedTopicsList.linkLists.map((val, idx) => (
          <React.Fragment key={idx}>
            {val.label && (
              <div className="card-related-topics__label">{val.label}:</div>
            )}
            <div className="card-related-topics__list">
              {val.metadataLinks &&
                val.metadataLinks.map((link, idx) => {
                  if (link.linkHref && link.text) {
                    return (
                      <div
                        key={idx}
                        style={{
                          display: 'flex',
                          gap: '5px',
                        }}
                      >
                        <Link
                          theme={LinkTheme.none}
                          key={idx}
                          ariaLabel={
                            val.label
                              ? val.label + ': ' + link.linkText
                              : link.linkText
                          }
                          className={cn(
                            'card-related-topics__list__value-link',
                            {
                              hide: idx > 2 && useShowMore && !showMore,
                            }
                          )}
                          href={link.linkHref}
                          text={link.linkText}
                          blankTarget={link.linkBlankTarget}
                        />
                        {link.text && <span>{link.text}</span>}
                      </div>
                    );
                  } else if (link.linkHref) {
                    return (
                      <Link
                        key={idx}
                        className={cn('card-related-topics__list__value-link', {
                          hide: idx > 2 && useShowMore && !showMore,
                        })}
                        href={link.linkHref}
                        text={link.linkText}
                        blankTarget={link.linkBlankTarget}
                      />
                    );
                  }
                })}
              {makeButton()}
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

export default RelatedTopics;
