import React from 'react';
import Link from '/Components/Atoms/Link/Link';
import { LinkProps } from '/Models/Generated/LinkProps';
import './RelatedBlockList.scss';

interface RelatedBlockListProps {
  list?: LinkProps[];
  heading?: string;
}
const RelatedBlockList = ({ list, heading }: RelatedBlockListProps) => {
  return (
    <div className="related-block-wrapper-list-item-react">
      <strong>{heading}</strong>
      <ul>
        {list.map((item, idx) => (
          <li key={idx}>
            <Link {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RelatedBlockList;
