import React from 'react';
import './ResultDictionary.scss';
import ResultSummary from './Shared/ResultSummary';
import { Provider, useSelector } from 'react-redux';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultDictionary = ({ title, summary }: Props) => {
  const labels = useSelector((state: RootState) => state.Search.labels);
  return (
    <article className="search-result-dictionary-article-react">
      <h2 dangerouslySetInnerHTML={{ __html: labels.dictionary + title }}></h2>
      <ResultSummary summary={summary} />
    </article>
  );
};

const ResultDictionaryWrapper = (props: Props) => (
  <Provider store={SearchStore}>
    <ResultDictionary {...props} />
  </Provider>
);

export default ResultDictionaryWrapper;
