import React from 'react';
import { WhatsUpProps } from '/Models/Generated/WhatsUpProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import './WhatsUp.scss';

const WhatsUp = ({
  calendarLink,
  hoeringerErrorHtml,
  moreLinks,
  whatsUpList,
  whatsUpTitle,
}: WhatsUpProps) => {
  return (
    <div className="whatsup-react">
      <div className="whatsup-react__wrapper">
        <h2>{whatsUpTitle}</h2>
        <ul className="whatsup-react__wrapper__list">
          {whatsUpList &&
            whatsUpList.map((val, idx) => (
              <li key={idx} className="whatsup-react__wrapper__list__item">
                <div className="whatsup-react__event">
                  <time dateTime={val.eventDate}>
                    <span className="whatsup-react__event-day">
                      {val.eventDay}
                    </span>
                    <span className="whatsup-react__event-number">
                      {val.eventNumber}
                    </span>
                  </time>
                </div>
                <div className="whatsup-react__event-list">
                  <div>
                    {val.noActivity && (
                      <span
                        className="whatsup-react__event-list__no-activity-text"
                        dangerouslySetInnerHTML={{
                          __html: val.noActivityHtml,
                        }}
                      ></span>
                    )}
                  </div>
                  {val.meetingList &&
                    val.meetingList.map((valMeeting, idx) => (
                      <div key={idx} className="whatsup-react__event-label">
                        {valMeeting.presentationMeetingTimeInfoText &&
                        valMeeting.presentationTodaysMeetingTitle &&
                        valMeeting.eposMeetingAgendaPageLinkUrl ? (
                          <div className="whatsup-react__event-link">
                            <a href={valMeeting.eposMeetingAgendaPageLinkUrl}>
                              <span className="sr-only">{val.eventDate}</span>
                              {valMeeting.presentationTodaysMeetingTitle} {}
                              {valMeeting.presentationMeetingTimeInfoText}
                            </a>
                          </div>
                        ) : (
                          <span>
                            <span className="sr-only">{val.eventDate}</span>
                            {valMeeting.presentationTodaysMeetingTitle}{' '}
                            {valMeeting.presentationMeetingTimeInfoText}
                          </span>
                        )}
                        {valMeeting.showMeetingRemarkText && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: valMeeting.meetingRemarkText,
                            }}
                          />
                        )}
                      </div>
                    ))}
                  {val.activeHoeringer && (
                    <Link
                      text={val.hoeringLinkWithText.text}
                      href={val.hoeringLinkWithText.href}
                      theme={LinkTheme.standard}
                    />
                  )}

                  {val.newsList &&
                    val.newsList.map((valNews, idx) => (
                      <div key={idx} className="whatsup-react__news-list">
                        {valNews.newsArticleImageUrl && (
                          <figure>
                            <img
                              src={valNews.newsArticleImageUrl}
                              alt={valNews.newsArticleImageAltText}
                            />
                          </figure>
                        )}
                        {valNews.newsArticleLinkWithText !== undefined && (
                          <div className="whatsup-react__news-list-item">
                            {valNews.newsArticleLinkWithText.href && (
                              <a href={valNews.newsArticleLinkWithText.href}>
                                {valNews.newsArticleLinkWithText.text}
                              </a>
                            )}
                            {!valNews.newsArticleLinkWithText.href && (
                              <span>
                                {valNews.newsArticleLinkWithText.text}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </li>
            ))}
        </ul>
        <Link
          href={calendarLink.href}
          text={calendarLink.text}
          theme={LinkTheme.blocklink}
        />
        {hoeringerErrorHtml && (
          <div
            className="whatsup-react__error-block"
            dangerouslySetInnerHTML={{
              __html: hoeringerErrorHtml,
            }}
          />
        )}

        <ul className="whatsup-react__more-results">
          {moreLinks &&
            moreLinks.map((val, idx) => (
              <li key={idx}>
                <Link
                  href={val.href}
                  text={val.text}
                  theme={LinkTheme.standard}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default WhatsUp;
