import React from 'react';
import './BreadCrumb.scss';
import { BreadCrumbProps } from '/Models/Generated/BreadCrumbProps';
import cn from 'classnames';

const BreadCrumb = (props: BreadCrumbProps) => {
  if (props)
    return props.isCurrent || props.isSectional ? (
      <li
        className={cn('bread-crumb-react', 'bread-crumb-react-no-dash', {
          'bread-crumb-react-is-current': props.isCurrent,
        })}
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></li>
    ) : (
      <li className="bread-crumb-react">
        <a
          href={props.href}
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></a>
      </li>
    );
  else return <></>;
};

export default BreadCrumb;
