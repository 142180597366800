import React from 'react';
import './PublikasjonerReferaterlisteFilter.scss';
import { ChildPageDropDownProps } from '/Models/Generated/ChildPageDropDownProps';
import { DropDownProps } from '/Models/Generated/DropDownProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
export interface PublikasjonerReferaterlisteFilterProps extends DropDownProps {
  filterTitle?: string;
  filterId?: string;

  stortingPeriodsDropDown: ChildPageDropDownProps;
  monthsDropDown: DropDownProps;
  minutesTypeDropDown: ChildPageDropDownProps;

  usePeriod: [string, React.Dispatch<React.SetStateAction<string>>];
  useMonth: [string, React.Dispatch<React.SetStateAction<string>>];
  useMinute: [string, React.Dispatch<React.SetStateAction<string>>];
}

const PublikasjonerReferaterlisteFilter = ({
  filterTitle,
  filterId,
  stortingPeriodsDropDown,
  monthsDropDown,
  minutesTypeDropDown,
  usePeriod,
  useMonth,
  useMinute,
}: PublikasjonerReferaterlisteFilterProps) => {
  const [stortingPeriod, setStortingPeriod] = usePeriod;
  const [month, setMonth] = useMonth;
  const [minute, setMinute] = useMinute;

  return (
    <ExpandableFilterWrapper scrollId={filterId} heading={filterTitle}>
      <div className="publikasjoner-referaterliste-filter-react">
        <div>
          <p>{stortingPeriodsDropDown.labelText}</p>
          <Dropdown
            id="publikasjoner_referaterliste_dropdown_1"
            name="publikasjoner_referaterliste_dropdown_1"
            options={stortingPeriodsDropDown.pageLink}
            selectedValue={stortingPeriod}
            setSelected={setStortingPeriod}
          />
        </div>

        <div>
          <p>{monthsDropDown.labelText}</p>
          <Dropdown
            id="publikasjoner_referaterliste_dropdown_2"
            name="publikasjoner_referaterliste_dropdown_2"
            options={monthsDropDown.items}
            selectedValue={month}
            setSelected={setMonth}
          />
        </div>

        <div>
          <p>{minutesTypeDropDown.labelText}</p>
          <Dropdown
            id="publikasjoner_referaterliste_dropdown_3"
            name="publikasjoner_referaterliste_dropdown_3"
            options={minutesTypeDropDown.pageLink}
            selectedValue={minute}
            setSelected={setMinute}
          />
        </div>
      </div>
    </ExpandableFilterWrapper>
  );
};

export default PublikasjonerReferaterlisteFilter;
