import React from 'react';
import './XmlImportReferatHeader.scss';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import { XmlImportReferatHeaderProps } from '/Models/Generated/XmlImportReferatHeaderProps';

const XmlImportReferatHeader = ({
  referatTittel,
  datoLabel,
  moteDato,
  presidentLabel,
  president,
  motelederLabel,
  moteleder,
  dokumenterLabel,
  proceedingDoc,
}: XmlImportReferatHeaderProps) => {
  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    return timestamp.substr(0, 10).split('-').reverse().join('.');
  };
  return (
    <div className="xmlimportreferat-header-react">
      {referatTittel && <h1>{referatTittel}</h1>}
      <div>
        {moteDato && (
          <>
            <strong>
              {datoLabel}
              {': '}
            </strong>
            <time dateTime={formatDate(moteDato)}>{formatDate(moteDato)}</time>
            <br />
          </>
        )}
        {president && (
          <>
            <strong>
              {presidentLabel}
              {': '}
            </strong>
            {president}
            <br />
          </>
        )}
        {moteleder && (
          <>
            <strong>
              {motelederLabel}
              {': '}
            </strong>
            {moteleder}
            <br />
          </>
        )}
        {proceedingDoc && (
          <>
            <br />
            <strong className="minutes-header-react__documents-label">
              {dokumenterLabel}
              {':'}
              &nbsp;
            </strong>
            <ContentArea {...proceedingDoc} />
          </>
        )}
      </div>
    </div>
  );
};

export default XmlImportReferatHeader;
