import React from 'react';
import ResultCase from './ResultCase';
import ResultPage from './ResultPage';
import ResultDictionary from './ResultDictionary';
import ResultDebate from './ResultDebate';
import ResultBiography from './ResultBiography';
import ResultQuestion from './ResultQuestion';
import ResultPost from './ResultPost';
import ResultReply from './ResultReply';
import ResultReport from './ResultReport';
import ResultUnspecified from './ResultUnspecified';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultLookup = (props: Props) => {
  const Comp =
    {
      question: ResultQuestion,
      biography: ResultBiography,
      case: ResultCase,
      page: ResultPage,
      dictionary: ResultDictionary,
      debate: ResultDebate,
      post: ResultPost,
      reply: ResultReply,
      report: ResultReport,
    }[props.kind] ?? ResultUnspecified;
  return <Comp {...props} />;
};

export default ResultLookup;
