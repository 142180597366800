import React from 'react';
import './ExpandButton.scss';
import cn from 'classnames';

interface ExpandButtonProps {
  ariaControls: string;
  isOpen: boolean;
  onClick: () => void;
  text: string;
  className?: string;
  type?: string;
}

const ExpandButton = ({
  ariaControls,
  isOpen,
  onClick,
  text,
  className,
  type,
}: ExpandButtonProps) => {
  return (
    <button
      className={cn('expand-button-react', className, {
        'expand-button-react--open': isOpen,
        'search-page-button': type === 'SearchPage',
      })}
      onClick={onClick}
      aria-controls={ariaControls}
      aria-expanded={isOpen ? 'true' : 'false'}
    >
      <div className="expand-button-react__inner">{text}</div>
    </button>
  );
};

export default ExpandButton;
