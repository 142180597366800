/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import './EmailInput.scss';
import { ExtendedFormInputProps } from '/Models/FrontendOnly/FormInputs/ExtendedFormInputProps';
import GetFormInputErrorMessages from '/Shared/Code/Validation/GetFormInputErrorMessages';

const EmailInput = (props: ExtendedFormInputProps) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const onBlur = () => {
    const errorMessages = GetFormInputErrorMessages({
      ...props,
      value: inputEl.current.value,
      emailErrorMessage:
        props.emailErrorMessage || 'Not a valid e-mail address',
    });

    if (errorMessages.length) props.setErrorMessage(errorMessages[0]);
    else props.setErrorMessage('');
  };

  const getElement = () => (
    <input
      className="text-input-react"
      ref={inputEl}
      type="text"
      id={props.id}
      name={props.name}
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={(e: React.SyntheticEvent) => props.onChange(e.target.value)}
      disabled={props.disabled}
      onBlur={onBlur}
    />
  );

  return (
    <>
      {props.disabled && getElement()}
      {!props.disabled && getElement()}
    </>
  );
};

export default EmailInput;
