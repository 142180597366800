import React from 'react';
import { SearchStore, RootState } from '/Shared/Code/Search/SearchStore';
import './SearchPageList.scss';
import { Provider, useSelector } from 'react-redux';
import ResultWrapper, { ResultTheme } from './SearchResults/ResultWrapper';
import ResultLookup from './SearchResults/ResultLookup';
import { Props } from '/Models/FrontendOnly/Search/Results';

const SearchPageList = () => {
  const searchResults = useSelector((state: RootState) =>
    state.Search.results.GetResults()
  );

  function ResolveTheme(result) {
    return result.kind === 'biography' && result?.specifics?.current
      ? ResultTheme.darkgrey
      : ResultTheme.normal;
  }

  return searchResults ? (
    searchResults.map((result: Props, idx) => {
      const theme = ResolveTheme(result);
      return (
        <ResultWrapper key={idx} theme={theme}>
          <ResultLookup {...result} />
        </ResultWrapper>
      );
    })
  ) : (
    <></>
  );
};

const SearchPageListWrapped = () => (
  <Provider store={SearchStore}>
    <SearchPageList />
  </Provider>
);

export default SearchPageListWrapped;
