import React, { useState } from 'react';
import cn from 'classnames';

import './Panel.scss';

interface Props {
  title?: string;
  children?: React.ReactNode;
  toggleOnMobile?: boolean;
  isSmallVersion?: boolean; // consider theme
}

const Panel = ({ title, toggleOnMobile, isSmallVersion, children }: Props) => {
  const [hide, setHide] = useState(true);
  const TitleTag = isSmallVersion ? 'h3' : 'h2'; // Todo: consider making this a prop

  return toggleOnMobile ? (
    <>
      <div className="panel-react panel-react--toggle-on-mobile">
        <h2 className="panel-react__title-desktop">{title}</h2>
        <button
          onClick={() => setHide(!hide)}
          className="panel-react__title-mobile"
        >
          <span>{title}</span>
        </button>
        <div
          className={cn(
            'panel-react__content',

            { 'panel-react__content--hidden': hide }
          )}
        >
          {children}
        </div>
      </div>
    </>
  ) : (
    <div
      className={cn('panel-react', 'panel-react--static', {
        'panel-react--small': isSmallVersion,
      })}
    >
      <TitleTag className="panel-react__title-generic">{title}</TitleTag>
      {children}
    </div>
  );
};
export default Panel;
