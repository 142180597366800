import React from 'react';
import './Recaptcha.scss';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

interface Props {
  tokenRef?: React.MutableRefObject<string>;
  terms?: string;
}

const Recaptcha = ({ tokenRef, terms }: Props) => {
  return (
    <div className="recaptcha-form-element-react">
      <GoogleReCaptcha
        onVerify={(token: string) => {
          tokenRef.current = token;
        }}
        action="form"
      />
      <p
        dangerouslySetInnerHTML={{
          __html: terms,
        }}
      />
      <input type="hidden" name="captcha" value={tokenRef.current} />
    </div>
  );
};

export default Recaptcha;
