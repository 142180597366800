import React, { useRef, useState } from 'react';
import './TableOfContentDropDown.scss';
import cn from 'classnames';

import { XmlImportNavigationProps } from '/Models/Generated/XmlImportNavigationProps';
import { XmlImportNavigationItemProps } from '/Models/Generated/XmlImportNavigationItemProps';

import ExpandButtonPlusMinus from '/Components/Atoms/ExpandButtonPlusMinus/ExpandButtonPlusMinus';

import useChapterInView from './UseChapterInView';
import useOnClickOutside from '/Shared/Code/Hooks/UseOnClickOutside';
import { scrollToTarget } from '/Shared/Code/Helpers/ScrollToTarget';

type TableOfContentDropDownProps = Pick<
  XmlImportNavigationProps,
  'documentTitleLabel' | 'currentTitle' | 'innholdLabel' | 'menuItems'
>;

type SubListProps = XmlImportNavigationItemProps & {
  id: string;
  activeChapterId?: string;
  setOpenParentFn: (boolean) => void;
};

type SearchHitsLinkProps = Pick<
  XmlImportNavigationItemProps,
  'searchHits' | 'hasSearchMatch' | 'menuListHref'
>;

const SearchHitsLink = ({
  searchHits,
  hasSearchMatch,
  menuListHref,
}: SearchHitsLinkProps) => {
  if (!hasSearchMatch) return null;

  return (
    <div className="search-hits-link-react">
      <div className="search-hits-link-react__number">{searchHits}</div>
      <a href={menuListHref} className="search-hits-link-react__link">
        <span className="sr-only">Gå til søkeresultater</span>
      </a>
    </div>
  );
};

const SubList = ({
  id,
  activeChapterId,
  name,
  setOpenParentFn,
  href,
  hash,
  // levelIdentifier,
  // isFirstLevel,
  searchHits,
  hasSearchMatch,
  // firstMatch,
  menuListHref,
  // menuListTitle,
  isCurrentChapter,
  subItems,
}: SubListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isActiveChapter = hash === activeChapterId;

  const navigateToChapter = (e) => {
    e.preventDefault();
    const id = e.target.dataset.bigdocHash;

    const onPageId = document.getElementById(id);

    if (onPageId) {
      const xmlNavigationHeight = document.querySelector(
        '[data-bigdoc-navigation]'
      )?.clientHeight;
      scrollToTarget(id, xmlNavigationHeight);
      setOpenParentFn(false);
    } else {
      window.location.href = e.target.href;
    }
  };

  if (Array.isArray(subItems) && subItems?.length > 0)
    return (
      <>
        <div className="table-of-content-drop-down-react__padded-item">
          <div className="table-of-content-drop-down-react__padded-item__btn-expand">
            <ExpandButtonPlusMinus
              isOpen={isOpen || isActiveChapter}
              ariaControls={id}
              ariaLabel="Åpne dette nivå"
              onClick={() => setIsOpen((current) => !current)}
            />
          </div>
          <a
            href={href}
            className={cn({
              'table-of-content-drop-down-react__active-chapter':
                isActiveChapter,
            })}
            data-bigdoc-hash={hash}
            onClick={navigateToChapter}
          >
            {name}
          </a>
          <SearchHitsLink {...{ searchHits, hasSearchMatch, menuListHref }} />
        </div>
        <ul
          id={id}
          className={cn('table-of-content-drop-down-react__list', {
            'table-of-content-drop-down-react__list--open':
              isOpen || isActiveChapter,
          })}
        >
          {subItems?.map((subItem, i) => (
            <li
              key={i}
              className="table-of-content-drop-down-react__list__item"
            >
              <SubList
                {...subItem}
                setOpenParentFn={setOpenParentFn}
                id={id + '_' + i}
              />
            </li>
          ))}
        </ul>
      </>
    );
  else
    return isCurrentChapter ? (
      <h2>{name}</h2>
    ) : (
      <div className="table-of-content-drop-down-react__padded-item">
        <a
          href={href}
          className={cn({
            'table-of-content-drop-down-react__active-chapter': isActiveChapter,
          })}
        >
          {name}
        </a>
        <SearchHitsLink {...{ searchHits, hasSearchMatch, menuListHref }} />
      </div>
    );
};

const TableOfContentDropDown = ({
  documentTitleLabel,
  currentTitle,
  innholdLabel,
  menuItems,
}: TableOfContentDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const chapterInViewId = useChapterInView();
  const tocRef = useRef(null);
  useOnClickOutside(tocRef, () => setIsOpen(false));

  return (
    <div ref={tocRef}>
      <label
        className="table-of-content-drop-down-react__label"
        htmlFor="table_of_content_toggle_button"
      >
        {documentTitleLabel}
      </label>
      <div
        className={cn('table-of-content-drop-down-react', {
          'table-of-content-drop-down-react--open': isOpen,
        })}
      >
        <button
          id="table_of_content_toggle_button"
          className="table-of-content-drop-down-react__btn-toggle"
          onClick={() => setIsOpen((current) => !current)}
          aria-controls="table_of_content_list"
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          <span className="table-of-content-drop-down-react__btn-toggle__text">
            {currentTitle}
          </span>
        </button>

        <div
          id="table_of_content_list"
          className="table-of-content-drop-down-react__list-container"
        >
          <strong className="table-of-content-drop-down-react__list-container__heading">
            {innholdLabel}
          </strong>
          {menuItems?.map((menuItem, i) => (
            <React.Fragment key={i}>
              <SubList
                setOpenParentFn={setIsOpen}
                {...menuItem}
                activeChapterId={chapterInViewId}
                id={'list_' + i}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
export default TableOfContentDropDown;
