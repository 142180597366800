import React from 'react';
import './ResultBiography.scss';
import ResultBiographyGoverment from './ResultBiographyGoverment';
import ResultBiographyRepresentative from './ResultBiographyRepresentative';
import { Props } from '/Models/FrontendOnly/Search/Results';

const ResultBiography = (props: Props) => {
  return props.specifics.current ? (
    <ResultBiographyGoverment {...props} />
  ) : (
    <ResultBiographyRepresentative {...props} />
  );
};

export default ResultBiography;
