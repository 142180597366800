import React from 'react';
import './EPOSSakAvgivelsesfristerFilter.scss';
import Dropdown from '/Components/Atoms/Dropdown/Dropdown';
import { DropDownProps } from '/Models/Generated/DropDownProps';
import ExpandableFilterWrapper from '/Components/Molecules/ExpandableFilterWrapper/ExpandableFilterWrapper';

export interface EPOSSakAvgivelsesfristerFilterProps extends DropDownProps {
  filterTitle?: string;
  filterId?: string;
  weekOfYearDropDownProps?: DropDownProps;
  committeeDropDownProps?: DropDownProps;
  useStateWeekOfYear: [string, React.Dispatch<React.SetStateAction<string>>];
  useStateCommittee: [string, React.Dispatch<React.SetStateAction<string>>];
}

const EPOSSakAvgivelsesfristerFilter = ({
  filterTitle,
  filterId,
  weekOfYearDropDownProps,
  committeeDropDownProps,
  useStateWeekOfYear,
  useStateCommittee,
}: EPOSSakAvgivelsesfristerFilterProps) => {
  const [weekOfYear, setWeekOfYear] = useStateWeekOfYear;
  const [committee, setCommittee] = useStateCommittee;

  return (
    <ExpandableFilterWrapper scrollId={filterId} heading={filterTitle}>
      <div className="epos-sak-avgivelsesfrister-filter-react">
        <div>
          <p>{weekOfYearDropDownProps.labelText}</p>
          <Dropdown
            id="epos_sak_avgivelsesfrister_dropdown_1"
            name="epos_sak_avgivelsesfrister_dropdown_1"
            options={weekOfYearDropDownProps.items}
            selectedValue={weekOfYear}
            setSelected={setWeekOfYear}
          />
        </div>

        <div>
          <p>{committeeDropDownProps.labelText}</p>
          <Dropdown
            id="epos_sak_avgivelsesfrister_dropdown_2"
            name="epos_sak_avgivelsesfrister_dropdown_2"
            options={committeeDropDownProps.items}
            selectedValue={committee}
            setSelected={setCommittee}
          />
        </div>
      </div>
    </ExpandableFilterWrapper>
  );
};

export default EPOSSakAvgivelsesfristerFilter;
