import React, { useState } from 'react';
import './TopMenuGlobalSearchSection.scss';
import cn from 'classnames';
import TopMenuSectionContentCloseButton from './TopMenuSectionContentCloseButton';
import SearchInput from '../../Atoms/SearchInput/SearchInput';
import Button from '../../Atoms/Button/Button';
import ContentArea from '/Components/Organisms/ContentArea/ContentArea';
import Row, { HorizontalPadding, VerticalPadding } from '../Row/Row';
import { RichTextTheme } from '../../Organisms/RichText/RichText';
import { TopMenuSearchProps } from '/Models/Generated/TopMenuSearchProps';
import GreyRow from '../GreyRow/GreyRow';

interface TopMenuGlobalSearchSectionProps extends TopMenuSearchProps {
  containerId: string;
  isOpen: boolean;
  toggleSearch: () => void;
}

const TopMenuGlobalSearchSection = (props: TopMenuGlobalSearchSectionProps) => {
  const [div, setDiv] = useState(null as HTMLDivElement);
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    location.href = props.searchPageNewUrl + '?&query=' + query;
  };

  if (props)
    return (
      <div
        id={props.containerId}
        className={cn('top-menu-global-search-section-content', {
          'is-open': props.isOpen,
        })}
        style={{ height: props.isOpen ? div.clientHeight : 0 }}
      >
        <div
          className="top-menu-global-search-section-content__container"
          ref={(divElement) => {
            setDiv(divElement);
          }}
        >
          <Row
            horizontalPadding={HorizontalPadding.indent}
            verticalPaddingTop={VerticalPadding.xlarge}
            className={cn(
              'top-menu-global-search-section-content__container__wrapper',
              {
                'is-open': props.isOpen,
              }
            )}
          >
            <h1
              aria-hidden={props.isOpen ? 'false' : 'true'}
              className={props.isOpen ? '' : 'visuallyhidden'}
            >
              {props.menuDrawerTitle}
            </h1>
            <ContentArea
              {...props.menuDrawerMainIntro}
              theme={RichTextTheme.Standard}
            />
          </Row>
          <Row
            className={cn(
              'top-menu-global-search-section-content__container__row',
              {
                'is-open': props.isOpen,
              }
            )}
          >
            <GreyRow>
              <div className="input-container-react">
                <SearchInput
                  doSearch={() => {
                    handleSearch();
                  }}
                  placeholder={props.placeholder}
                  setQuery={(text) => {
                    setQuery(text);
                  }}
                  query={query}
                  loading={false}
                  fillContainer
                  enableClear
                  searchIcon
                  setFocus={props.isOpen}
                />
                <Button
                  text={props.buttonLabel}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                />
              </div>
            </GreyRow>
          </Row>
          <TopMenuSectionContentCloseButton
            closeSection={props.toggleSearch}
            closeLabel={props.closeLabel}
          />
        </div>
      </div>
    );
  else return <></>;
};

export default TopMenuGlobalSearchSection;
