import React from 'react';
import './StateBudgetNewsList.scss';
import { StateBudgetNewsListProps } from '/Models/Generated/StateBudgetNewsListProps';
import Link, { LinkTheme } from '/Components/Atoms/Link/Link';
import ColumnContentWrapper from '/Components/Layout/ColumnContentWrapper/ColumnContentWrapper';

const StateBudgetNewsList = ({
  heading,
  headingPropertyName,
  items,
}: StateBudgetNewsListProps) => (
  <div>
    {items.length ? (
      <ColumnContentWrapper
        title={heading}
        titlePropertyName={headingPropertyName}
        customTag="aside"
      >
        {items &&
          items.map((val, idx) => (
            <div className="statebudget-news-list-react" key={idx}>
              <Link
                href={val.href}
                text={val.text}
                theme={LinkTheme.standard}
              />
              <br />
              {val.subText && <span>({val.subText})</span>}
            </div>
          ))}
      </ColumnContentWrapper>
    ) : (
      <></>
    )}
  </div>
);

export default StateBudgetNewsList;
